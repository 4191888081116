// Package Imports
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

// Other Imports
import { getAppToken, getIdToken, logoutAppUser, getSession, setAppToken } from "../../utility/appUtil";
import { getAPIBaseUrl } from "../../environment/api";
import { PortalModule } from "../../environment/apiEnvironment";

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}
enum ErrorMessage {
  InvalidPassword = "Invalid password"
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
};

// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    setAppToken(process.env.REACT_APP_TOKEN);
    const token = getAppToken();
    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  } catch (error) {
    throw new Error(error);
  }
};

// const injectAccessToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
//   try {
//     const token = getAccessToken();
//     if (token != null) {
//       config.headers['access-token'] = token;
//     }
//     return config;
//   } catch (error) {
//     throw new Error(error);
//   }
// };

const injectIdToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    const idToken = getIdToken();
    if (idToken != null) {
      config.headers['id-token'] = idToken;
    }
    return config;
  } catch (error) {
    throw new Error(error);
  }
};

// const injectRefreshToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
//   try {
//     const refreshToken = getRefreshToken();
//     if (refreshToken != null) {
//       config.headers['refresh-token'] = refreshToken;
//     }
//     return config;
//   } catch (error) {
//     throw new Error(error);
//   }
// };

const injectSession = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    const session = getSession();
    if (session != null) {
      config.headers['session'] = session;
    }
    return config;
  } catch (error) {
    throw new Error(error);
  }
};

export default abstract class BaseService {
  constructor(private baseURL: string) {
  }

  private instance: AxiosInstance | null = null;

  protected async request<T = any, R = AxiosResponse<T>>(
    config: AxiosRequestConfig
  ): Promise<R> {
    return await this.http.request(config);
  }

  protected async get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return await this.http.get<T, R>(url, config);
  }

  protected async post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return await this.http.post<T, R>(url, data, config);
  }

  protected async put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return await this.http.put<T, R>(url, data, config);
  }

  protected async patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return await this.http.patch<T, R>(url, data, config);
  }

  protected async delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return await this.http.delete<T, R>(url, config);
  }

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  private initHttp(): AxiosInstance {
    const http = axios.create({
      baseURL: this.baseURL,
      headers,
      //  withCredentials: true,
    });

    http.interceptors.request.use(injectToken, (error) =>
      Promise.reject(error)
    );

    // use access token
    // http.interceptors.request.use(injectAccessToken, (error) =>
    //   Promise.reject(error)
    // );

    http.interceptors.request.use(injectIdToken, (error) =>
      Promise.reject(error)
    );

    // http.interceptors.request.use(injectRefreshToken, (error) =>
    //   Promise.reject(error)
    // );

    http.interceptors.request.use(injectSession, (error) =>
      Promise.reject(error)
    );

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        return this.handleError(response);
      }
    );

    this.instance = http;
    return http;
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  private handleError(error: any) {
    const status = error?.status;
    console.log("Error",status)
    switch (status) {
      case StatusCode.Unauthorized: {
        console.log("unauthorized",status,error)
        // Handle Unauthorized
        if(error?.data?.error?.message === ErrorMessage.InvalidPassword){
          return error;
        }if(window.localStorage.getItem('id-token')){
          this.post(getAPIBaseUrl(PortalModule.AUTH)+`user/ssoLogout`).then((resp:any)=>{
            logoutAppUser()
            window.localStorage.clear();
            window.location.href = resp.data.result;
          })
        }else{
          logoutAppUser()
          window.localStorage.clear();
          window.location.href = `${process.env.REACT_APP_SELF_URL}/api/v1/user/login`
        }

        break;
      }
      default: {
        return error;
      }
    }
    return Promise.reject(error);
  }
}
