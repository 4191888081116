import styled from "styled-components";

export const H1 = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.h1};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  color: ${(props) => props.theme.colors.text.primary};
  margin: 0;
`;

export const H2 = styled.h2`
  font-size: ${(props) => props.theme.fontSizes.h2};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  color: ${(props) => props.theme.colors.text.primary};
  margin: 0;
`;

export const H3 = styled.h3`
  font-size: ${(props) => props.theme.fontSizes.h3};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  color: ${(props) => props.theme.colors.text.primary};
  margin: 0;
`;

export const H4 = styled.h4`
  font-size: ${(props) => props.theme.fontSizes.h4};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  color: ${(props) => props.theme.colors.text.primary};
  margin: 0;
`;

export const H5 = styled.h5`
  font-size: ${(props) => props.theme.fontSizes.h5};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  color: ${(props) => props.theme.colors.text.primary};
  margin: 0;
`;

export const Body = styled.p`
  font-size: ${(props) => props.theme.fontSizes.body};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  color: ${(props) => props.theme.colors.text.primary};
  margin: 0;
`;

export const SmallBody = styled.p`
  font-size: ${(props) => props.theme.fontSizes.smallBody};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  color: ${(props) => props.theme.colors.text.primary};
  margin: 0;
`;

export const Footnote = styled.p`
  font-size: ${(props) => props.theme.fontSizes.footnote};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  color: ${(props) => props.theme.colors.text.primary};
  margin: 0;
`;

export const Caption = styled.p`
  font-size: ${(props) => props.theme.fontSizes.caption};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  color: ${(props) => props.theme.colors.text.quaternary};
  line-height: 1;
  margin: 0;
`;
