import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { Role } from "../../models/common/role";
import { RolePermission } from "../../models/role/rolePermission";
import { RoleRolePermissions } from "../../models/role/roleRolePermissions";
import {RolesUrl} from '../../constants/apiConstants'
import { AddRole } from "../../models/role/addRole";
import { APIResponseError } from "../../constants/enums";
import { replaceAll } from "../../../utility/appUtil";
import { Messages } from "../../constants/messages";


export default class RoleService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.ROLE));
  }

  // fetch all roles
  async fetchRoleList(organizationId?: string): Promise<Array<Role>> {
    //debugger;
    let endpoint = `${RolesUrl.ROLE}?sortOrder=asc`;
    if (organizationId && organizationId !=='') {
      endpoint = `${endpoint}&organisationId=${organizationId}`;
    }
    const response = await this.get(endpoint);
    if (
      response &&
      response.data &&
      response.data.result &&
      response.data.result.rows &&
      response.data.result.rows.length
    ) {
      return response.data.result.rows.map((x: any) => { 
        const role: Role = {
          id: x.id,
          name: x.role,
          editable: x.editable,
          description: x.description,
          createdBy: x.createdBy,
          deletedBy: x.deletedBy,
          updatedBy: x.updatedBy,
        }
        return role;
      });
    }
    return new Array<Role>();
  }

  // fetch role by role id
  async fetchRoleById(roleId: string): Promise<RoleRolePermissions | null> {
    const response = await this.get(`${RolesUrl.ROLE}/${roleId}`);
    //debugger;
    if (
      response &&
      response.data &&
      response.data.result
    ) {
      const resp = response.data.result;
      const roleDetails: RoleRolePermissions = {
        role: {
          id: resp.id,
          name: resp.role,
          editable: resp.editable,
          description: resp.description,
          createdBy: resp.createdBy,
          deletedBy: resp.deletedBy,
          updatedBy: resp.updatedBy,
        },
        permissions: resp.permissions.map((x: any) => { 
          const permission: RolePermission = {
            roleId: resp.id,
            permissionId: x.id,
            permission: x.name, 
            permissionType: x.action,
            permissionDescription: x.description,
            createdBy: x.createdBy,
            deletedBy: x.deletedBy,
            updatedBy: x.updatedBy,
          }
          return permission;
        })
      }
      return roleDetails;
    }
    return null;
  }

    // fetch transformed role detail by role id
    async fetchTransformedRoleDetail(roleId: string): Promise<RoleRolePermissions | null> {
      const response = await this.get(`roles/details/${roleId}`);
      //debugger;
      if (
        response &&
        response.data &&
        response.data.result &&
        response.data.result.message
      ) {
        const resp = response.data.result.message;
        const roleDetails: RoleRolePermissions = {
          role: {
            id: resp.id,
            name: resp.role,
            editable: resp.editable,
            description: resp.description,
          },
          permissions: resp.permission.map((x: any) => { 
            const permission: RolePermission = {
              roleId: resp.id,
              permission: x.name, 
              isEdit: x?.isEdit || false,
              isView: x?.isView || false,
              permissionDescription: x.description,
            }
            return permission;
          })
        }
        return roleDetails;
      }
      return null;
    }

  // fetch all permissions
  async fetchPermissions(): Promise<RolePermission[] | null> {
    const response = await this.get(`permissions/service`);
    if (
      response &&
      response.data &&
      response.data.result &&
      response.data.result.message
    ) {
      return response.data.result.message.rows.map((x: any) => {
        const permission: RolePermission = {
          permissionId: x.id,
          permissionType: x.action,
          permission: x.name,
          permissionDescription: x.description
        }
        return permission;
      });
    }
    return null;
  }

  // create role
  async createRole(role: AddRole): Promise<Role | null> {
    const response = await this.post<AddRole>(RolesUrl.ROLE, role);
    if (response) {
      const data: any = response.data;
      if (data && data.result && data.result.message) {
        return data.result.message as Role;
      }
      else if(data?.error){
        const errorType: any = data?.error?.errorType;
        let errorMsg = "";
        if (errorType) {
          switch(errorType) {
            case APIResponseError.ALREADY_EXISTS:
              errorMsg = replaceAll(/\{0\}/gi, (role.t(Messages.ROLE_ALREADY_EXISTS)), role?.role);
              data.display = errorMsg;
              return data
          }
        }
      }
    }
    return null;
  }

  // update role
  async updateRole(role: AddRole, id:string): Promise<Role | null> {
    const response = await this.put<AddRole>(`${RolesUrl.ROLE}/${id}`, role);
    if (response) {
      const data: any = response.data;
      if (data && data.result) {
        return data.result
      }
      else if(data?.error){
        const errorType: any = data?.error?.errorType;
        let errorMsg = "";
        if (errorType) {
          switch(errorType) {
            case APIResponseError.ALREADY_EXISTS:
              errorMsg = replaceAll(/\{0\}/gi, Messages.ROLE_ALREADY_EXISTS, role?.role);
              data.display = errorMsg;
              return data
          }
        }
      }
    }
    return null;
  }

  // delete role
  async deleteRole(toBeDeleteRoleId:string, replacementRoleId?: string): Promise<any | null> {
    const toBeReplace = {replacementRoleId:replacementRoleId}
    const response = await this.delete<any>(`${RolesUrl.ROLE}/${toBeDeleteRoleId}`, replacementRoleId?{data:toBeReplace}:{});
    if (response) {
      const data: any = response.data;
      if (data && data.result) {
        return data.result
      }
    }
    return null;
  }

  // fetch roles list by organisation id
  async fetchRoleListByOrgId(orgId: string): Promise<Array<Role>> {
    const response = await this.get(`${RolesUrl.ROLE}?organisationId=${orgId}`);
    if (
      response &&
      response.data &&
      response.data.result &&
      response.data.result.rows &&
      response.data.result.rows.length
    ) {
      return response.data.result.rows.map((x: any) => {
        const role: Role = {
          id: x.id,
          name: x.role,
          editable: x.editable,
          description: x.description,
          organisationId: x.organizationId,
          createdBy: x.createdBy,
          deletedBy: x.deletedBy,
          updatedBy: x.updatedBy,
        }
        return role;
      });
    }
    return new Array<Role>();
  }

  // roleList transformation
  async fetchTransformedRoleList(orgId: string): Promise<Array<Role>> {
    const response = await this.get(`roles/list?organisationId=${orgId}`);
    if (
      response &&
      response.data &&
      response.data.result &&
      response.data.result.rows &&
      response.data.result.rows.length
    ) {
      return response.data.result.rows.map((x: any) => {
        const role: Role = {
          id: x.id,
          name: x.role,
          description: x.description,
        }
        return role;
      });
    }
    return new Array<Role>();
  }

  // get users count for role
async getUsersCountForRole(roleId:string): Promise<number | 0> {
  const response = await this.get<any>(`user?role=${roleId}`);
  if (response && response.data) {
    const data = response.data;
    return data?.result?.rows?.length;
  }
  return 0;
}
  
}
