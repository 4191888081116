/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import { deleteRole} from '../../../../../redux/actions/role/roleAction';
import {Dropdown} from "../../../../../bit_components/common/dropdown"
import {
  Primarytext,
  Secondarytext,
  DeleteButton,
  Discardbutton,
} from "./modal.Style";
import "./modal.less";
import { Role } from "../../../../models/common/role";
import { roleListSelector, clearRoleDeleteFormState } from "../../../../../redux/reducers/role/roleListReducer";
import { AppRoutes } from "../../../../constants/enums";
import { AppService } from "../../../../services/ui/appService";
import { urlModification } from "../../../../../utility/appUtil";
import {Modal} from '../../../../../bit_components/ui/modal';
import { jsonData } from "../../../../constants/constants";
import { useTranslation } from 'react-i18next';

export interface ConfirmModalProps {
  isModalVisible:boolean;
  setModalVisible: any;
  selectedRole: Role;
  roles: Role[];
  roleUsers: number;
}

var tempBtn = false;

const ConfirmModal = (props: ConfirmModalProps) => {
  const { t } = useTranslation()
  const { isModalVisible, setModalVisible, roles, selectedRole, roleUsers } = props;
  const {roleDeleted, roleDeleteFormState} = useSelector(roleListSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const [replacementId, setReplacementId] = useState('');

  const roleArr: any[] = 
    roles.filter((x: any) => x?.id !== selectedRole.id).map((x: any) => {
      return { text: x.name, value: x.id };
    })

    useEffect(()=>{
      setReplacementId(roleArr[0].value);
    },[])
  
    useEffect(() => {
      if (roleDeleted && roleDeleteFormState.isSuccess && tempBtn) {
         history.push(urlModification(AppRoutes.ROLES_LIST));
         AppService.showToast(t(jsonData.RoleDeletedSuccessfully));
         dispatch(clearRoleDeleteFormState());
     }
}, [roleDeleted, roleDeleteFormState.isSuccess, tempBtn]);

  const handleOk = () => { 
     tempBtn = true;
    dispatch(deleteRole(
      {
      replacementRoleId: replacementId,
      toBeDeleteRoleId: selectedRole.id
    }))
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleRoleSelect = (selectedId: string)=>{
    setReplacementId(selectedId)  
  }
  return (

    <Modal
    handleCancel={handleCancel}
    handleOk={handleOk}
    okText='Reassign & Delete'
    cancelText='Cancel'
    isVisible={isModalVisible}
    >
    <div>
      <Primarytext className="roleModelWarning">This role is currently assigned to {roleUsers} users </Primarytext>
      <Secondarytext>You need to reassign them before deleting it.</Secondarytext>
      <Dropdown className="modal-dropdown" optionValue={roleArr} onChange={handleRoleSelect}/>
    </div>
  </Modal>          
  );
};

export default ConfirmModal;
