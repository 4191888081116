import React from "react";
import { Image } from "antd";
import Avatar from "react-avatar";
import { concatNames } from "../../../../../../utility/appUtil";
import "./profilePicture.less";

export const ProfilePicture = (props: any) => {
  const { src, firstName, lastName, color, size } = props;
  return src ? (
    <Image
      src={src}
      className="user-profile-pic profile-picture"
      preview={true}
    />
  ) : (
    <Avatar
      name={concatNames(firstName, lastName)}
      size={size}
      round={true}
      color={color}
      className='avtaar-cls'
    />
  );
};
