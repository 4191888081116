import React, { useState, useEffect } from "react";
import { UpDownImg } from "../../../../../images";
import "./multiSelectButton.less";
import {
  MultiSelectCheckBox,
  MultiSelectLabel,
  MultiSelectMainLabel,
  MultiSelectWrapper,
} from "./multiSelectButton.style";
import { Checkbox } from "../../../../../../bit_components/common/checkbox";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import { jsonData } from "../../../../../constants/constants";
import { Input, Menu, Tooltip } from "antd";

interface MultiSelectButtonProps {
  allSelectedText: string;
  name: string;
  placeholder: string;
  optionList: any[];
  onApply: Function;
  optionListClass: string;
  wrapperClassName: string;
  selectedItems: any;
  disabled?: boolean;
  tabIndex?: any;
  ariaLabel?:any,
  role?:any,
  allowSearch?: boolean
}

const MultiSelectButton = (props: MultiSelectButtonProps) => {
  const {
    allSelectedText,
    placeholder,
    name,
    optionList,
    onApply,
    optionListClass,
    wrapperClassName,
    selectedItems,
    disabled,
    tabIndex,
    ariaLabel,
    role,
    allowSearch
  } = props;

  const [tempList, setTempList] = useState([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [showDropDown, SetShowDropDown] = useState<boolean>(false);
  const [resetClicked, setResetClicked] = useState<boolean>(false);
  const [searchText, setSearchText] = useState(""); 
  const [filteredItems, setFilteredItems] = useState(optionList); 
  const [focusClass,setFocusClass] = useState<string>("")

  const { t } = useTranslation();

  const customValueRenderer = (selected, _options) => {
    return selected?.length === _options?.length && selected?.length > 0
      ? allSelectedText
      : selected?.length
      ? `${selected?.length} ${t(name)}`
      : placeholder;
  };

  const onSelect = (value: any) => {
    if (!value.target.checked) {
      setTempList(
        tempList?.filter(
          (x: any) => x?.toString() !== value.target.value?.toString()
        )
      );
    } else {
      setTempList([
        ...tempList,
        ...optionList
          ?.filter(
            (x: any) => x.value?.toString() === value.target.value?.toString()
          )
          .map((item) => {
            return item.value;
          }),
      ]);
    }
  };
  const onSelectAll = () => {
    if (selectAll !== null) {
      setSelectAll(!selectAll);
      if (!selectAll === true) {
        setTempList(optionList.map((x: any) => x?.value));
      } else {
        setTempList([]);
      }
    } else {
      setSelectAll(true);
      setTempList(optionList.map((x: any) => x?.value));
    }
  };

  useEffect(() => {
    if (tempList?.length === optionList?.length && optionList?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(null);
    }
  }, [tempList]);

  const onBack = () => {
    setTempList(selectedItems);
  };

  useEffect(() => {
    setTempList(selectedItems);
  }, [selectedItems]);

  const removeClass = (event: any) => {
    let dropdown = document.querySelector<HTMLElement>(`.${name}`);
    let dropdownHeading = document.querySelector<HTMLElement>(
      `.heading-${name}`
    );
    if (
      !dropdown?.contains(event.target) ||
      ((event.target.classList.contains("applyFilter") ||
        event.target.classList.contains("resetFilter")) &&
        !event.target.classList.contains("disableFilter")) ||
      dropdownHeading?.contains(event.target)
    ) {
      let checkboxes = document.querySelector<HTMLElement>(
        `.${optionListClass}`
      );
      if (checkboxes?.classList.contains("show-multiSelect-dropdown")) {
        document.body.removeEventListener("mouseup", removeClass);
        SetShowDropDown(false);
        !dropdownHeading?.contains(event.target) &&
          checkboxes.classList.remove("show-multiSelect-dropdown");
        if (!dropdown?.contains(event.target)) {
          onBack();
        }
      }
    }
  };
  const showDropdown = () => {
    let checkboxes = document.querySelector<HTMLElement>(`.${optionListClass}`);
    if (!checkboxes?.classList.contains("show-multiSelect-dropdown")) {
      checkboxes.classList.add("show-multiSelect-dropdown");
      document.body.addEventListener("mouseup", removeClass);
      SetShowDropDown(true);
    } else {
      checkboxes.classList.remove("show-multiSelect-dropdown");
      SetShowDropDown(false);
      onBack();
    }
  };

  const hideDropDown =()=>{
    let checkboxes = document.querySelector<HTMLElement>(
        `.${optionListClass}`
      );
      let multiSelect = document.querySelector<HTMLElement>(`.${optionListClass}12`);
      if (checkboxes) {
        checkboxes.classList.remove("show-multiSelect-dropdown");
        SetShowDropDown(false);
        onBack();
        multiSelect?.focus()
       
      }
  }

  const onEnterShowDropDown = (e: any) => {
    if(e?.keyCode === 27){
      hideDropDown();
    }
    if (e?.keyCode === 13 && !showDropDown && !resetClicked) {
      showDropdown();
    }
    setResetClicked(false);
  };

  const onEnterSelect = (e: any, byClass: string) => {
    if (e?.keyCode === 13) {
      if (byClass === "dropDownMenuList-all") {
        onSelectAll();
      } else {
        const menu = document?.querySelector<HTMLElement>(`.${optionListClass} .${byClass}`);
        if (menu) {
          menu.click();
        }
      }
    } else if (e?.keyCode === 9) {
      const menu = document?.querySelector<HTMLElement>(
        `.${optionListClass} .multiselect-footer .resetFilter`
      );
      if (menu) {
        menu.focus();
      }
    } else if (e.keyCode === 27) {
        hideDropDown();
    }
  };

  const jumpToBtns =(e:any)=>{
    if (e?.keyCode === 9) {
        const menu = document?.querySelector<HTMLElement>(
          `.${optionListClass} .multiselect-footer .resetFilter`
        );
        if (menu) {
          menu.focus();
        }
      }
  }

  // const jumpToNext =(e:any)=>{
  //   if(e?.keyCode === 9){
  //       const menu = document?.querySelector<HTMLElement>(
  //           `.${optionListClass} .multiselect-footer .applyFilter`
  //         );
  //         if (menu) {
  //           menu.focus();
  //         }
  //   }
  // }

  const closeAll =(e:any)=>{
    // 27 -> esc and 13 -> enter and 9 -> tab  
    if(!e?.shiftKey && (e?.keyCode === 27 || e?.keyCode === 9 || e?.keyCode === 13)){
    hideDropDown();
    if(e?.keyCode === 13){
      onApply(tempList)
    }
   }
  }

  const reSet =()=>{
    onApply([])
    // jumpToNext({e:{keyCode:9}})
    setResetClicked(true);
    hideDropDown();
  }

  let showAllOption =
  optionList?.length > 1 &&
  (!searchText ||
    t(jsonData.All).toLowerCase().includes(searchText?.toLowerCase()));

  const handleClick = (e: any, clearIcon: any) => {
    if (e?.keyCode === 13) {
      clearIcon.click();
    }
    clearIcon.removeEventListener("keyup", (e) => handleClick);
  }

  useEffect(() => {
    const clearIcons = document.querySelectorAll(
      '[role="button"][aria-label="close-circle"]'
    );
    clearIcons?.forEach((clearIcon) => {
      clearIcon?.setAttribute("tabindex", "0");
      clearIcon?.setAttribute("aria-label", "clear");
      clearIcon?.addEventListener("keyup", (e) => handleClick(e, clearIcon));
    });
  }, []);


  useEffect(() => {
    if (!showDropDown) {
      setTimeout(() => {
        setSearchText("");
        setFilteredItems(optionList);
      }, 100);
    }
  }, [showDropDown]);

  useEffect(() => {
    const searchFromFilter = (inputText: any) => {
      const res = optionList?.filter((option) =>
        option?.label?.toLowerCase().includes(inputText?.toLowerCase())
      );
      setFilteredItems(res);
    };
    
    searchFromFilter(searchText);
  }, [searchText, optionList]);

  return (
    <div className="multi-select-button-dropdown">
    <fieldset className={`${optionListClass}12`} tabIndex={tabIndex}  aria-label={ariaLabel} role={role} onKeyDown={onEnterShowDropDown}>
      <MultiSelectWrapper className={wrapperClassName}>
        <div className={`multiselectButton ${name}`}>
          <div className="dropdown-container">
            <div
              className={`dropdown-heading heading-${name} ${
                disabled ? "disable-pointer" : ""
              }`}
              onClick={showDropdown}
            >
              <div className="dropdown-heading-value">
              <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={placeholder} mouseLeaveDelay={0}>
                <MultiSelectMainLabel>
                  {customValueRenderer(tempList, optionList)}
                </MultiSelectMainLabel>
              </Tooltip>
                <img src={UpDownImg} alt="" />
              </div>
            </div>
            <div className={`dropdown-content ${optionListClass}`}>
              <div className="panel-content">
                {allowSearch && (
                  <>
                    <div className="filter-search-container">
                      <Input
                        name="search"
                        className={`${focusClass} filter-search-box`}
                        value={searchText}
                        onChange={(e: any) => setSearchText(e.target.value)}
                        tabIndex={0}
                        aria-label={""}
                        role={""}
                        placeholder={t(jsonData.inputSearchText)}
                        onFocus={() => setFocusClass("eventSearchBar")}
                        onBlur={() => setFocusClass("")}
                        allowClear
                      />
                    </div>
                    {searchText && !filteredItems?.length && !showAllOption && (
                      <p
                        className="not-found-text"
                      >
                        {t(jsonData.NoDataFound)}
                      </p>
                    )}
                  </>
                )}
                <div className="select-panel">
                  <Menu
                    onKeyUp={jumpToBtns}
                    className={`multiselect-options ${
                      optionList?.length
                        ? "multiselect-options"
                        : "noContentFound"
                    } focusTheDropDown`}
                    tabIndex={-1}
                  >
                    {showAllOption  ? (
                      <Menu.Item
                        onKeyUp={(e: any) =>
                          onEnterSelect(e, `dropDownMenuList-all`)
                        }
                        className="options"
                      >
                        <label className="select-item">
                          <div className="item-renderer">
                            <MultiSelectCheckBox>
                              <Checkbox
                                value={"all"}
                                name={"All"}
                                onChange={onSelectAll}
                                checked={selectAll}
                                id={`chkLocation-${name}`}
                                key={`chkLocation-${name}`}
                              />
                            </MultiSelectCheckBox>
                            <MultiSelectLabel className="checkboxLabel">
                              {t(jsonData.All)}
                            </MultiSelectLabel>
                          </div>
                        </label>
                      </Menu.Item>
                    ) : null}
                    {filteredItems?.length ? (
                      filteredItems.map((option: any, index: number) => {
                        return (
                          <Menu.Item
                            onKeyUp={(e: any) =>
                              onEnterSelect(e, `dropDownMenuList-${index}`)
                            }
                            className="options"
                            key={index}
                          >
                            <label className="select-item">
                              <div className="item-renderer">
                                <MultiSelectCheckBox>
                                  <Checkbox
                                    value={option.value}
                                    name={option.label}
                                    onChange={onSelect}
                                    checked={
                                      tempList?.filter(
                                        (x: any) =>
                                          x?.toString() ===
                                          option.value?.toString()
                                      ).length
                                        ? true
                                        : false
                                    }
                                    id={`chkLocation-${option.value}`}
                                    key={`chkLocation-${option.value}`}
                                    className={`dropDownMenuList-${index}`}
                                  />
                                </MultiSelectCheckBox>
                                <MultiSelectLabel className="checkboxLabel">
                                  {option.label}
                                </MultiSelectLabel>
                              </div>
                            </label>
                          </Menu.Item>
                        );
                      })
                    ) : (
                      <>
                        {!searchText && (
                          <span>{t(jsonData.NoDataFound)}</span>
                        )}
                      </>
                    )}
                    <Menu.Item
                      tabIndex={0}
                      aria-label={""} 
                      role={""}
                      className="custom-multiselect-footer">
                       <Button
                         type={`primary resetFilter ${
                           tempList?.length ? "multiselect-btn" : "disableFilter"
                         }`}
                         htmlType="submit"
                         disabled={!tempList?.length}
                         onClick={reSet}
                        //  onKeyUp={jumpToNext}
                         tabIndex={0}
                        //  onKeyPress={reSet}
                         aria-label={""} 
                         role={""}
                       >
                         {t(jsonData.Reset)}
                       </Button>
                       <Button
                         type={"primary applyFilter multiselect-btn"}
                         htmlType="submit"
                        //  onKeyUp={closeAll}
                         // disabled={!tempList?.length}
                         onClick={() => onApply(tempList)}
                         tabIndex={0}
                         aria-label={""}
                         role={""}
                         onKeyDown={closeAll}
                       >
                         {t(jsonData.Ok)}
                       </Button>
                     </Menu.Item>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MultiSelectWrapper>
    </fieldset>
    </div>
  );
};

export default MultiSelectButton;
