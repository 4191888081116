import { createAsyncThunk } from "@reduxjs/toolkit";
import { Location } from "../../../web/models/common/location";
import { Role } from "../../../web/models/common/role";
import User from "../../../web/models/user/user";
import { UserSearch } from "../../../web/models/user/userSearch";
import UserService from "../../../web/services/user/userService";

const userListSrv = new UserService();

export const fetchUserList = createAsyncThunk(
  "userList/fetchUserList",
  async (organizationId: string, thunkAPI) => {
    try {
      const data = await userListSrv.fetchUserList(organizationId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// fetch transformeed user list
export const fetchTransformUserList = createAsyncThunk(
  "userList/fetchTransformUserList",
  async (organizationId: string, thunkAPI) => {
    try {
      const data = await userListSrv.fetchTransformUserList(organizationId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const setUserListViewType = createAsyncThunk(
  "userList/setUserListViewType",
  async (viewType: string, thunkAPI) => {
    try {
      if (viewType) {
        return viewType;
      } else {
        return thunkAPI.rejectWithValue(viewType);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const filterUserList = createAsyncThunk(
  "userList/filterUserList",
  async (_args: { search: UserSearch, organizationId: string }, thunkAPI) => {
    try {
      //debugger;
      const data = await userListSrv.filterUserList(_args.search, _args.organizationId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// fetch filter transformeed user list
export const filterTransformUserList = createAsyncThunk(
  "userList/filterTransformUserList",
  async (_args: { search: UserSearch, organizationId: string }, thunkAPI) => {
    try {
      //debugger;
      const data = await userListSrv.filterTransformUserList(_args.search, _args.organizationId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const setBranchFilter = createAsyncThunk(
  "userList/setBranchFilter",
  async (branch: Location, thunkAPI) => {
    try {
      if (branch) {
        return branch;
      } else {
        return thunkAPI.rejectWithValue(branch);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const setRoleFilter = createAsyncThunk(
  "userList/setRoleFilter",
  async (role: Role, thunkAPI) => {
    try {
      if (role) {
        return role;
      } else {
        return thunkAPI.rejectWithValue(role);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const setSearchTermFilter = createAsyncThunk(
  "userList/setSearchTermFilter",
  async (searchTerm: string, thunkAPI) => {
    try {
      if (searchTerm) {
        return searchTerm;
      } else {
        return thunkAPI.rejectWithValue(searchTerm);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const selectUser = createAsyncThunk(
  "userList/selectUser",
  async (user: User, thunkAPI) => {
    try {
      if (user && user.id !=='') {
        return user;
      } else {
        return thunkAPI.rejectWithValue(user);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
