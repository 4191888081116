/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import { ResetPwdForm } from "../../../stateless/user/auth/resetPwdForm";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { UserAuthInput } from "../../../../models/user/userAuthInput";
import { resetPwd } from "../../../../../redux/actions/user/forgotPwdAction";

export const ChangePwd = (props: any) => {
  const dispatch = useDispatch();
  const { location } = props;
  const [user, setUser] = useState();
  const [form] = Form.useForm();
  const { formState } = useSelector(userStateSelector);
  const layout = {
    labelCol: {
      span: 24,
      // offset: 2,
    },
    wrapperCol: {
      span: 24,
    },
  };

  useEffect(() => {
    if (location && location.state) {
      if (location.state.user) {
        setUser(location.state.user);
      }
    }
  }, [location?.state]);

  const onResetPwdCallback = (userAuth: UserAuthInput) => {
    //debugger;
    //dispatch(resetPwd(userAuth));
  };

  return (
    <ResetPwdForm
      isChangePwd={true}
      user={user}
      form={form}
      formLayout={layout}
      location={location}
      errorMsg={
        formState?.errorStack && formState?.errorStack.message
          ? formState?.errorStack.message
          : ""
      }
      resetPwdCallback={onResetPwdCallback}
    />
  );
};
