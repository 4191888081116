import styled from "styled-components";

export const LoginContainer = styled.div`
    border-radius: 10px
`;

export const LoginBackButton = styled.span`
    padding-left: 8px;
    margin-bottom: 10px;
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-size: ${(props) => props.theme.fontSizes.footnote};
    line-height: 20px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.text.primary};
    cursor: pointer;
`;

export const AllignCenter = styled.div`
    display: flex;
    align-items: center;
`;

export const ForgotNote = styled.p`
    font-family: IBM Plex Sans;
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: 18px;
    color: ${(props) => props.theme.colors.palette.darkestGray};
    margin-top: 7.945%;
    margin-bottom: 9%;
`