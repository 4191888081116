import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { UserCardItem } from "../userCardItem";
import { NewUserCard } from "../newUserCard";
import { AppRoutes, HeaderType } from "../../../../../constants/enums";
import '../userCardItem/userCardItem.less';
import { getAppHeaderType, urlModification } from "../../../../../../utility/appUtil";
import { NoUserCard } from "../noUsersCard";

export const UserCards = (props: any) => {
  const { appUser, users = [], avatarColorList, blockUserCallback, resetPwdCallback, removeUserCallback, goToProfileCallback, unBlockUserCallback,resendInviteCallback ,setSaveUnblockUser} = props;
  const goToAddUser = () => {
    if (appUser && getAppHeaderType(appUser) === HeaderType.BIBLIOTHECA_PORTAL){
      return AppRoutes.ORGANISATION_ADD_NEW_USER
    }
    else{
      return AppRoutes.ADD_NEW_USER
    }
  }

  return (
    <div>
      <Row id="component-area" className="gutter-row pl-vw" gutter={[20, 20]}>
        {users.length? users?.map((user: any, index: number) => {
          return (
            <Col xs={12} sm={12} md={12} lg={8} xl={6}
              key={`UserCardItemCol-${user.id}`}
            >
              <UserCardItem appUser={appUser} key={`UserCardItem-${user.id}`} user={user} avatarColorList={avatarColorList} blockUserCallback={blockUserCallback} resetPwdCallback={resetPwdCallback} removeUserCallback={removeUserCallback} goToProfileCallback={goToProfileCallback} unBlockUserCallback={unBlockUserCallback} resendInviteCallback={resendInviteCallback} setSaveUnblockUser={setSaveUnblockUser} />
            </Col>
          );
        }):
        <Col xs={12} sm={12} md={12} lg={8} xl={6}>
        <NoUserCard/>
        </Col> }
        {/* {appUser && getAppHeaderType(appUser) === HeaderType.BIBLIOTHECA_PORTAL && appUser.locations[0].role.name === "Bibliotheca User" ? null:
        <Col xs={12} sm={12} md={12} lg={8} xl={6}>
          <Link to={urlModification(goToAddUser())}>
            <NewUserCard />
          </Link>
        </Col>
        } */}
      </Row>
    </div>
  );
};
