import styled from "styled-components";

export const OkButton = styled.button`
  width: 150px;
  height: 30px;
  background: ${(props) => props.theme.colors.button.primary};
  font-size: ${(props) => props.theme.fontSizes.smallBody};
  border: 2px;
  color: ${(props) => props.theme.colors.text.penta};
  box-sizing: border-box;
  border-radius: 60px;
  cursor: pointer;
  outline: none;
  box-shadow: 0px 94px 138px rgba(0, 0, 0, 0.2),
    0px 47.0459px 69.0674px rgba(0, 0, 0, 0.152067),
    0px 28.3382px 41.603px rgba(0, 0, 0, 0.130318),
    0px 18.1606px 26.6614px rgba(0, 0, 0, 0.114179),
    0px 11.7703px 17.2797px rgba(0, 0, 0, 0.1),
    0px 7.40924px 10.8774px rgba(0, 0, 0, 0.0858211),
    0px 4.25707px 6.24974px rgba(0, 0, 0, 0.0696822),
    0px 1.87365px 2.75067px rgba(0, 0, 0, 0.0479335);
`;

export const Primarytext = styled.div`
  font-size: ${(props) => props.theme.fontSizes.h4};
  color: ${(props) => props.theme.colors.text.primary};
  text-align: center;
  line-height: 20px;
  font-weight: ${(props) => props.theme.fontWeights.normal};
`;
