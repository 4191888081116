import BaseService from '../../../core/services/baseService'
import { getAPIBaseUrl } from '../../../environment/api'
import { PortalModule } from '../../../environment/apiEnvironment'
import { InsightsUrl } from '../../constants/apiConstants'
import { Report, ReportData } from '../../models/insights/reports'
import { ReportsResponse } from '../../models/insights/reports'
import qs from 'qs'
import { convert_DateWithTimezone_To_UTC, getOrganizationDateAndTime, getClientTimeZoneOffset, getOrganizationDateIndex } from '../../../utility/appUtil'
import { KioskUsageReport } from '../../constants/constants'
import secureLocalStorage from 'react-secure-storage'

export default class InsightsService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.INSIGHTS))
  }

  // fetch all reports for user
  async fetchReportsForUser(
    organisationId: string,
    userId: string,
  ): Promise<ReportsResponse> {
    let resp: ReportsResponse = { totalCount: 0, reports: new Array<Report>() }
    let endpoint = `${InsightsUrl.REPORT}?sortOrder=asc`
    if (organisationId && organisationId !== '') {
      endpoint = `${endpoint}&organisationId=${organisationId}`
    }
    if (userId && userId !== '') {
      endpoint = `${endpoint}&userId=${userId}`
    }

    let localeInfo:any = secureLocalStorage.getItem('locale')

    endpoint = `${endpoint}&locale=` + localeInfo.culture

    const response = await this.get(endpoint)
    if (
      response &&
      response.data &&
      response.data.result &&
      response.data.result.message &&
      response.data.result.message.rows &&
      response.data.result.message.rows.length
    ) {
      resp.totalCount = response.data.result.message.count
      resp.reports = response.data.result.message.rows
      return resp
    }
    return resp
  }

  // fetch report data for report id
  async fetchReportDataByReportId(_args: any): Promise<ReportData> {
    let resp: ReportData
    let params: any = this.getReportDataParams(_args)
    let endPoint = `${InsightsUrl.REPORTDETAILS}/${_args.reportId}`

    // const response = await this.get(endPoint, {
    //   params: params,
    //   paramsSerializer: (params) => {
    //     return qs.stringify(params)
    //   },
    // })

    const response = await this.post(endPoint, params)
    
    if (
      response &&
      response.data &&
      response.data.result && !response.data.result.msg
      
    ) {
      let result = this.getInsights(response.data.result, _args.groupByValue, _args?.calculateKioskUsage)
      return result
    } else if(response.data.result.msg) {
      return response.data.result
    }
    return resp
  }

  private getInsights(data: any, groupByValue: boolean, calculateKioskUsage?: boolean) {
    const response = {
      columns: data?.columns,
      interval: data?.interval,
      locale: data?.locale,
      rows: []
    }
    if (calculateKioskUsage) {
      const columns = data?.columns.filter((column: any) =>
        KioskUsageReport.Columns.includes(column?.id)
      )

      const rows = data?.rows.map((row: any) => {
        let obj = {
          data: 0,
          date: getOrganizationDateAndTime(row?.date),
        }
        for (let x of columns) {
          obj.data = obj.data + row?.data[x?.id]
        }
        return obj
      })
      response.rows = rows
    }
    else {
      response.rows = data?.rows
      if (groupByValue) {  
        const dateArray = data?.rows;
        let sortedArray = [];
        if (dateArray) {
          for (let i = 0; i < dateArray.length; i++) {
            const dateString = dateArray[i].date;
            const dayIndex = getOrganizationDateIndex(dateString, response.interval)
            const newDateObject = {
              ...dateArray[i],
              dayIndex: dayIndex
            };
            sortedArray.push(newDateObject)
          }
        }  
        sortedArray.sort((a,b)=> a.dayIndex - b.dayIndex)
        response.rows = sortedArray
      }          
    }
    return response
  }

  async saveMyReport(_args: any): Promise<any> {
   
    let params = this.getMyInsightParams(_args)
    let endpoint = `${InsightsUrl.MYREPORT}`
    const response: any = await this.post(endpoint, params)
    if (response?.data?.result)
      return response?.data?.result
    else
      return null
  }

  async updateMyReport(_args: any): Promise<any> {
    let endpoint = `${InsightsUrl.MYREPORT}/${_args.id}`
    let params = this.getMyInsightParams(_args)
    const response: any = await this.put(endpoint, params)
    if (response?.data?.result)
      return response?.data?.result
    else
      return null
  }

  async renameMyReport(parameters): Promise<any> {
    let endpoint = `${InsightsUrl.MYREPORT}/${parameters?.id}`
    let params :any = {}
    if (parameters.name) {
      params.name = parameters.name
    }
    if (parameters.updatedBy) {
      params.updatedBy = parameters.updatedBy
    }
    const response: any = await this.put(endpoint, params)
    if(response?.data?.result)
      return response?.data?.result
    else 
      return null
  }

  async deleteMyReport(id): Promise<any> {
    let endpoint = `${InsightsUrl.MYREPORT}/${id}`;
    const response: any = await this.delete(endpoint)
    if(response?.data?.result)
      return response?.data?.result;
    else 
      return null
  }

  async fetchMyReport(organisationId: string, userId: string,): Promise<any> {
    let endpoint = `${InsightsUrl.MYREPORT}?sortOrder=asc`;
    let resp: ReportsResponse = { totalCount: 0, reports: new Array<Report>() }
    if (organisationId && organisationId !== '') {
      endpoint = `${endpoint}&organisationId=${organisationId}`
    }
    if (userId && userId !== '') {
      endpoint = `${endpoint}&userId=${userId}`
    }
    const response: any = await this.get(endpoint)
    if (response?.data?.result){
    resp.totalCount = response.data.result?.length
    resp.reports = response.data.result 
      return resp
    }
    else
      return null
  }

  async fetchMyReportById(parameters): Promise<any> {
    let endpoint = `${InsightsUrl.MYREPORT}/${parameters.id}`;
    let params: any = {}
    params.sendEmail = parameters.sendEmail
    const response: any = await this.get(endpoint,{
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })
    if (response?.data?.result)
      return response?.data?.result;
    else
      return null
  }

  async postMyScheduleReport(_args): Promise<any> {
    let endpoint = `${InsightsUrl.SCHEDULE}`;
    // let params: any = this.getMyInsightParams(_args)
    // const response: any = await this.put(endpoint, params)

    let params: any = {}

    // for schedule
    if (_args.distributionList) {
      params.distributionList = _args.distributionList
    }
    if (_args.frequency) {
      params.frequency = _args.frequency
    }  

    if (_args.deliveryDate) {
      params.deliveryDate = _args.deliveryDate
    }
    
    if (_args.time) {
      params.time = _args.time
    }
    if (_args.createdBy) {
      params.createdBy = _args.createdBy
    }
    if(_args.exportFormat) {
      params.exportFormat = _args.exportFormat
    }
    params.myReportId = _args.myReportId
    params.format = _args.format
    params.isActive = _args.isActive
    const response: any = await this.post(endpoint, params)
    if (response?.data?.result) {
      return response?.data?.result
    }
    else
      return null
  }

  

  async updateMyScheduleReport(_args: any): Promise<any> {
    let endpoint = `${InsightsUrl.SCHEDULE}/${_args.id}`;
    // let params: any = this.getMyInsightParams(id)
    let params: any = {}

    // for schedule
    if (_args.distributionList) {
      params.distributionList = _args.distributionList
    }
    if (_args.frequency) {
      params.frequency = _args.frequency
    }
    if (_args.deliveryDate) {
      params.deliveryDate = _args.deliveryDate
    }   
    if (_args.time) {
      params.time = _args.time
    }
    if (_args.updatedBy) {
      params.updatedBy = _args.updatedBy
    }
    if(_args.exportFormat) {
      params.exportFormat = _args.exportFormat
    }
    params.myReportId = _args.myReportId
    params.format = _args.format
    params.isActive = _args.isActive
    const response: any = await this.put(endpoint, params)
    if (response?.data?.result) {
      return response?.data?.result
    }
    else
      return null
  }
  
  async deleteSchedule(id):Promise<any> {
    let endpoint = `${InsightsUrl.SCHEDULE}/${id}`;
    const response: any = await this.delete(endpoint)
    if(response?.data?.result)
      return response?.data?.result;
    else 
      return null
  }
  


  private getMyInsightParams = (_args) => {
    let params: any = {}
    let localeInfo:any = secureLocalStorage.getItem('locale')

    if (_args.reportId) {
      params.reportId = _args.reportId
    }
    if (_args.name) {
      params.name = _args.name
    }
    if (_args.createdBy) {
      params.createdBy = _args.createdBy
    }
    if (_args.updatedBy) {
      params.updatedBy = _args.updatedBy
    }
    if (_args.isActive) {
      params.isActive = _args.isActive
    }

    if (_args.filters) {
      let filters = _args.filters
      params.filters = {}
      let paramFilters = params.filters

      let offset = getClientTimeZoneOffset(_args.startDate)

      paramFilters.clientTimeZoneOffset = offset
      paramFilters.locale = localeInfo.culture
      if (filters.interval) {
        paramFilters.interval = filters.interval
      }
      if (filters.activeRepresentation) {
        paramFilters.dataRepresentation = filters.activeRepresentation
      }
      if (filters.locations?.length) {
        paramFilters.locations = filters.locations
      }
      if (filters.clientdevices?.length) {
        paramFilters.clientdevices = filters.clientdevices
      }
      if (filters.groupByValue !== undefined) {
        paramFilters.groupByValue = filters.groupByValue
      }
      if (filters.dateRange !== undefined) {
        paramFilters.dateRange = filters.dateRange
      }

      if (filters.organisationId && filters.organisationId !== '') {
        paramFilters.organisationId = filters.organisationId
      }
      if (filters.onlyCloudCheck !== undefined) {
        paramFilters.onlyCloudCheck = filters.onlyCloudCheck
      }
    }
   
   

    // // for schedule
    // if (_args.distributionList) {
    //   params.distributionList = _args.distributionList
    // }
    // if (_args.frequency) {
    //   params.frequency = _args.frequency
    // }
    // if (_args.deliveryDate) {
    //   params.deliveryDate = _args.deliveryDate
    // }
    // if (_args.time) {
    //   params.time = _args.time
    // } 
    

    return params
  }

async fetchExportReportData(_args: any): Promise<ReportData> {
  // let resp: ReportData
  let params: any = this.getReportDataParams(_args, true)
  let endPoint = `${InsightsUrl.REPORTDETAILS}/${_args.reportId}`

  const response = await this.post(endPoint, params, {responseType: 'blob'})
  if (
    response &&
    response.data
    
  ) {
    return response.data
  }
  return null
}

private getReportDataParams(_args: any, exportData?: boolean) {
  let params: any = {}
  let localeInfo:any = secureLocalStorage.getItem('locale')
  if (exportData) {
    params.isExport = true
  }
  if (_args.organisationId && _args.organisationId !== '') {
    params.organisationId = _args.organisationId
  }
  let offset = getClientTimeZoneOffset(_args.startDate)
  if (_args.startDate) {
    params.startDate = convert_DateWithTimezone_To_UTC(_args.startDate, {offset: offset, time: 'start'})
  }
  if (_args.endDate) {
    params.endDate = convert_DateWithTimezone_To_UTC(_args.endDate, {offset: offset, time: 'end'})
  }
  params.clientTimeZoneOffset = offset
  if (_args.interval) {
    params.interval = _args.interval
  }
  if (_args.startTime) {
    params.startTime = _args.startTime
  }
  if (_args.endTime) {
    params.endTime = _args.endTime
  }
  if (_args.locations) {
    params.locations = _args.locations
  }
  if (_args.clientdevices) {
    params.clientdevices = _args.clientdevices
  }
  if (_args.groupByValue !== undefined) {
    params.groupByValue = _args.groupByValue
  }
  if (_args.onlyCloudCheck !== undefined) {
    params.onlyCloudCheck = _args.onlyCloudCheck
  }
  if(_args?.exportFormat !== undefined) {
    params.exportFormat = _args.exportFormat
  }
  params.locale = localeInfo.culture
  return params

}
}