/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { MainLayoutService } from "../../../../services/ui/mainLayoutService";
import { HeaderType } from "../../../../constants/enums";
import { useDispatch, useSelector } from "react-redux";
import { onPage, sidebarNavigate } from "../../../../../redux/actions/app/appAction";
import { SideBarItems } from "../../../../constants/sideBarConstants";
import { Pages, jsonData } from "../../../../constants/constants";
import { DeviceHealthHeader } from "./deviceHealthHeader";
import {Row, Col} from 'antd'
import { DeviceHealthCard } from "./deviceHealthCard";
import { DeviceHealthContainer } from "./index.styles";
import { appSelector, displayDeviceMenu } from "../../../../../redux/reducers/app/appReducers";
import { clearDevicesHealth, DeviceSelector, setDeviceHealthParams, clearDevicesHealthFormState } from "../../../../../redux/reducers/device/deviceReducer";
import { fetchDevicesHealthByOrgId, fetchDeviceStates, fetchDeviceTypeList } from "../../../../../redux/actions/device/deviceAction";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { cameFromOrganisation, getHeight } from "../../../../../utility/appUtil";
import { fetchTransformedLocationList } from "../../../../../redux/actions/role/locationAction";
import Loader from "../../../stateless/common/spinner";
import { CompWrapper } from "../../../stateless/common/compWrapper/compWrapper";
import { Empty } from 'antd'
import { EmptyDevice } from "../deviceComponents/index.styles";
import { useTranslation } from 'react-i18next';
import AntdPagination from "../../../stateless/common/pagination/pagination";

export const DeviceHealth = (props: any) => {
  const dispatch = useDispatch();
  const {appUser} = useSelector(userStateSelector)
  const {selectedOrganisation} = useSelector(appSelector)
  const {devicesHealth, devicesHealthFormstate, deviceHealthParams, devicesHealthCount} = useSelector(DeviceSelector)
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()
  
  const screenHeight = document.body.clientHeight;
  const [bottomGap, setBottomGap] = useState(60);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPagination, setShowPagination] = useState<boolean>(true)

  const getOrgId = () => {
    let orgId = appUser?.organization.id as string;
    if (cameFromOrganisation()) {
      orgId = selectedOrganisation?.id;
    }
    return orgId;
  }

  let DeviceTypeListParams = {
    organizationId: getOrgId(),
    isOrgFilter : true
  }

  useEffect(()=>{
    dispatch(displayDeviceMenu(false))
    dispatch(fetchDeviceTypeList(DeviceTypeListParams))
    dispatch(fetchDeviceStates())
    dispatch(fetchTransformedLocationList(getOrgId()))
    setLoading(true)
    dispatch(fetchDevicesHealthByOrgId({...deviceHealthParams, organizationId: getOrgId()}))
    dispatch(onPage({onComponent : Pages.JUNTO_PORTAL}));
    MainLayoutService.renderHeader(HeaderType.JUNTO_PORTAL);
    dispatch(sidebarNavigate(SideBarItems.DEVICE_HEALTH));
    return () => {
      dispatch(clearDevicesHealth())
      dispatch(clearDevicesHealthFormState())
    }
  }, [])

  const onChange = (fields: any) => {
    let params = {
      organizationId: getOrgId(),
    }
    params = {...params, ...fields}
    dispatch(setDeviceHealthParams(params))
    setLoading(true)
    dispatch(fetchDevicesHealthByOrgId(params))
    setCurrentPage(1)
  }

  useEffect(() => {
    if(devicesHealthFormstate?.isSuccess){
      setLoading(false)
    }
    else if(devicesHealthFormstate?.isError){
      setLoading(false)
    }
  }, [devicesHealthFormstate])

  useEffect(() => {
    const compContentHeight = getHeight('.ant-layout-content')
    const downGap = compContentHeight - (screenHeight * 0.75)
    setBottomGap(downGap)
  }, [screenHeight,devicesHealthFormstate])

  const onPageChange = (page:any) => {
    setCurrentPage(page)
    dispatch(fetchDevicesHealthByOrgId({...deviceHealthParams, organizationId: getOrgId(), page:page}))
    setLoading(true)
  }

  useEffect(() => {
    if(currentPage === 1 && devicesHealth?.length < 250) {
      setShowPagination(false)
    } else {
      setShowPagination(true)
    } 
  },[currentPage,devicesHealthFormstate])

  return(
    <>
      <DeviceHealthHeader onChange={onChange}/>
      <Loader loading={loading}>
        <CompWrapper exclude={bottomGap}>
          {devicesHealthFormstate?.isError ?
          // doubt
          <EmptyDevice description='Error Occurred'/>
          :
          <DeviceHealthContainer>
            {Array.isArray(devicesHealth) && devicesHealth?.length ?
            <Row gutter={[20,20]} >
              {devicesHealth?.map((item:any, index: number)=>{
                return <Col xs={12} sm={12} md={8} lg={8} xl={6} key={index}>
                  <DeviceHealthCard item={item}/>
                </Col>
              })}
            </Row>
            :
            <EmptyDevice description={t(jsonData.NoDeviceFound)}/>
            }
          </DeviceHealthContainer>
          }
        </CompWrapper>
        {
           devicesHealthCount > 250 && showPagination &&
          <AntdPagination className='audit-pagination' total={devicesHealthCount}  current={currentPage} defaultPageSize={250} onChange={(page: any, pageSize: any) => onPageChange(page)}/>
        }
      </Loader>
    </>
  )
};
