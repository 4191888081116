import React from 'react'
import './alertCheckbox.less'

interface CheckboxProps {
    onChange: any
    className: any
    isChecked: boolean
    checkBoxName?: string
}

function SquareCheckbox(props: CheckboxProps) {
    const { onChange, className, isChecked, checkBoxName  } = props

    const handleCheckboxNavigation = (event, props: CheckboxProps) => {
        if(event.key === 'Enter' || event.key === ' '){
            props?.onChange();
        }
    }

    return (
        <div role='checkbox' tabIndex={0} aria-label={checkBoxName ? `${checkBoxName}` : ''} onKeyPress={(event) => handleCheckboxNavigation(event, props)} onClick={onChange} className={`custom-checkboxOuter ${className}`}>
            {isChecked && <span className='custom-checkboxInner'/>}
        </div>
    )
}

export default SquareCheckbox;