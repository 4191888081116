/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AlertConfigSelector } from '../../../../../redux/reducers/alertConfig/alertConfigReducer';

import './alertCheckbox.less'
import SquareCheckbox from './checkBox';

interface CheckboxProps {
    onChange: any;
    initialValue: any;
    name: string
    data: any
    onRevert: Function
    selectAll?: any
    onSelectCheckbox: Function
    checkboxValue?: string
}

function AdminCheckbox(props: CheckboxProps) {
    const { data, name, checkboxValue } = props
    const [isChecked, setIsChecked] = useState<boolean>(false)
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const { alertConfigChanges } = useSelector(AlertConfigSelector)

    useEffect(() => {
        if(alertConfigChanges[data?.eventId] && alertConfigChanges[data?.eventId][name] !== null && alertConfigChanges[data?.eventId][name] !== undefined){
            setIsChecked(alertConfigChanges[data?.eventId][name])
        }
        else{
            setIsChecked(props.initialValue)
        }
    }, [alertConfigChanges, props.initialValue])


    const onChange = () => {
        let value = !isChecked
        if(props?.initialValue === value){
            props.onRevert(data?.eventId, name)
        }
        else{
            props.onChange(data, { [name]: value})
        }
        // handle onSelectCheckbox
        props.onSelectCheckbox(data?.eventId, value)
        setIsChecked(value)
    }

    useEffect(() => {
        
        if(alertConfigChanges[data?.eventId]?.isActive === false){
            setIsDisabled(true)
        }
        else if(alertConfigChanges[data?.eventId]?.isActive === true){
            setIsDisabled(false)
        }
        else if(!data?.isActive && !isDisabled){
            setIsDisabled(true)
        }
        else if(data?.isActive && isDisabled){
            setIsDisabled(false)
        }
    }, [alertConfigChanges])

    return (
        <SquareCheckbox checkBoxName={`${checkboxValue}`} onChange={isDisabled ? ()=>{} : onChange} className={isDisabled ? 'custom-checkboxDisable': ''} isChecked={isChecked ?? false}/>
    )
}

export default AdminCheckbox;