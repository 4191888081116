// Base Imports
import React from "react";

// less file Imports
import "./Spinner.less";
import { jsonData } from "../../../../constants/constants";
import { useTranslation } from 'react-i18next';

// Other Imports

const Loader = (props: any) => {
  const { t } = useTranslation()
  if (props.loading) {
    return <div className="loaderBody"><span className="spinner"></span><span className="loadingText">{t(jsonData.Loading)}.</span></div>;
  }

  return <>{props.children}</>;
};

export default Loader;
