import React from "react";
import Avatar from "react-avatar";
import { concatNames } from "../../../../../../utility/appUtil";
import '../../list/userCardItem/userCardItem.less';
import { ThumbNailImage } from "./profileThumbNailPicture.styles";

export const ProfileThumbnailPicture = (props: any) => {
  const { src, firstName, lastName, color, round, size = "35", preview = false } = props;

  return src ? (
    <ThumbNailImage
      src={src}
      preview={preview}
    />
  ) : (
    <Avatar
      className="imgContaineravt"
      name={concatNames(firstName, lastName)}
      size={size}
      round={round}
      color={color}
    />
  );
};
