/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import axios from 'axios'
import { MainLayoutService } from "../../../../services/ui/mainLayoutService";
import { HeaderType } from "../../../../constants/enums";
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { onPage, sidebarNavigate } from "../../../../../redux/actions/app/appAction";
import { SideBarItems } from "../../../../constants/sideBarConstants";
import { Pages } from "../../../../constants/constants";
import { DeviceComponentHeader } from "./deviceComponentHeader";
import { DeviceComponentCard } from "./deviceComponentCard";
import { DeviceHealthContainer, DeviceComponentDiv, DeviceCompDiv, EmptyDevice } from "./index.styles";
import { CompWrapper } from "../../../stateless/common/compWrapper/compWrapper";
import { fetchDeviceComponents } from "../../../../../redux/actions/device/deviceAction";
import Loader from "../../../stateless/common/spinner";
import { clearDeviceComponents, DeviceSelector,setCancelToken } from "../../../../../redux/reducers/device/deviceReducer";
import { appSelector, storeSelectedDevice } from "../../../../../redux/reducers/app/appReducers";

export const DeviceComponents = (props: any) => {
  const dispatch = useDispatch();
  const location = useLocation()
  const { deviceComponents, deviceComponentsFormstate,deviceComponentParams,cancel} = useSelector(DeviceSelector)
  const { selectedDevice } = useSelector(appSelector)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    let token = axios.CancelToken.source()
          dispatch(setCancelToken({cancelToken:token,cancel:false}))
    dispatch(onPage({ onComponent: Pages.DEVICES }));
    MainLayoutService.renderHeader(HeaderType.JUNTO_PORTAL);
    dispatch(sidebarNavigate(SideBarItems.DEVICE_COMPONENTS));
    return () => {
      if (token) {
        dispatch(setCancelToken({cancelToken:null,cancel:true}))
        token.cancel("Operation canceled due to new request.")
      }
      dispatch(clearDeviceComponents())
    }
  }, [])

  // useEffect(() => {
  //   if (location?.state) {
  //     let device: any = location?.state
  //     dispatch(fetchDeviceComponents(device?.deviceId))
  //   }
  // }, [location?.state])

  useEffect(() => {
    if (selectedDevice?.deviceId ) {
      dispatch(clearDeviceComponents())
      setLoading(true)
      dispatch(fetchDeviceComponents({...deviceComponentParams, id:selectedDevice?.deviceId}))
    }
  }, [])

  useEffect(() => {
    if (deviceComponents) {
      dispatch(storeSelectedDevice(deviceComponents))
    }
  }, [deviceComponents])

  useEffect(() => {
    if(deviceComponentsFormstate?.isSuccess){
      setLoading(false)
    }
    else if(deviceComponentsFormstate?.isError && !cancel){
      setLoading(false)
    }
  }, [deviceComponentsFormstate])

  return (
    <>
      <DeviceComponentHeader />
      <Loader loading={loading}>
        <CompWrapper otherClasses="deviceComponents">
          {deviceComponentsFormstate?.isError ?
          <EmptyDevice  description='Error Occurred'/>
          :
          <DeviceHealthContainer>
            {deviceComponents?.components?.map((component: any, index: number) => {
              return <DeviceComponentDiv key={index}>
                <DeviceCompDiv>
                  <DeviceComponentCard component={component} deviceName={deviceComponents?.deviceName} deviceId={deviceComponents?.deviceId} />
                </DeviceCompDiv>
              </DeviceComponentDiv>
            })}
          </DeviceHealthContainer>
          }
        </CompWrapper>
      </Loader>
    </>
  )
};
