import { APIEnvironment } from "./apiEnvironment";
import * as environments from "./environment.json";

export const apiEnv: APIEnvironment = APIEnvironment.DEV;
export const getAPIBaseUrl = (module: string): string => {
  // const runningEnv = environments.runningEnv;
  const runningEnv = process.env.REACT_APP_ENV
  if (runningEnv) {
    const environment = environments.environments.find(
      (x) => x.type === runningEnv
    );
    if (environment) {
      // eslint-disable-next-line
      const baseUri = eval(`environment.baseAPIUrls.modules.${module}`);
      return baseUri;
    }
  }
  return "";
};
