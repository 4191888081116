import React from "react"
import { Pagination } from "antd"
import "./pagination.less"
const AntdPagination = (props: any) => {
    return (
        <>
            <Pagination
                total={props.total}
                current={props.current}
                defaultPageSize={props.defaultPageSize}
                onChange={props.onChange}
                className={`pagination-style ${props?.className}`}
            />
        </>
    )
}

export default AntdPagination;