import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import { UserRowItem } from "../userRowItem";
import { NewUserRow } from "../newUserRow";
import { AppRoutes, HeaderType } from "../../../../../constants/enums";
import { NoUserRow } from "../noUserRow";
import { getAppHeaderType, urlModification } from "../../../../../../utility/appUtil";

export const UserRows = (props: any) => {
  const { appUser, users = [], avatarColorList, blockUserCallback, resetPwdCallback, removeUserCallback, goToProfileCallback, unBlockUserCallback, resendInviteCallback,setSaveUnblockUser } = props;
  const goToAddUser = () => {
    if (appUser && getAppHeaderType(appUser) === HeaderType.BIBLIOTHECA_PORTAL){
      return AppRoutes.ORGANISATION_ADD_NEW_USER
    }
    else{
      return AppRoutes.ADD_NEW_USER
    }
  }
  
  return (
    <div>
      <Row id="user-rows" gutter={[15, 3]} className="gutterRow pl-vw">
        {users.length? users?.map((user: any) => {
          return (
            <Col key={`UserRowItemCol-${user.id}`} span={24}>
              <UserRowItem appUser={appUser} avatarColorList={avatarColorList} key={`UserRowItem-${user.id}`} user={user} blockUserCallback={blockUserCallback} resetPwdCallback={resetPwdCallback} removeUserCallback={removeUserCallback} goToProfileCallback={goToProfileCallback} unBlockUserCallback={unBlockUserCallback} resendInviteCallback={resendInviteCallback} setSaveUnblockUser={setSaveUnblockUser}/>
            </Col>
          );
        }):
        <Col span={24}>
        <NoUserRow/>
        </Col>}
        {/* {appUser && getAppHeaderType(appUser) === HeaderType.BIBLIOTHECA_PORTAL && appUser.locations[0].role.name === "Bibliotheca User" ? null:
        <Col key={"UserRowItemCol-newUserRow"} span={24}>
          <Link to={urlModification(goToAddUser())}>
            <NewUserRow />
          </Link>
        </Col>
        } */}
      </Row>
    </div>
  );
};
