import React from "react";
import styled from "styled-components";
import { Layout, Row } from 'antd';
const { Content, Sider } = Layout;

export const StyledLayout = styled(Layout)`
    flex-direction: column !important;
    padding: 0px 40px 0px 40px;

`;

export const StyledSider = styled(Sider)`
    width:100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    height: 80vh !important;
    border-radius: 10px !important;
    box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02), 0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067), 0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318), 0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179), 0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01), 0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821), 0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822), 0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335) !important;
`;

export const StyledContent = styled(Content)`
    height: 100% !important;
    position: absolute !important;
    top: 0; bottom: 0; left: 0; right: 0 !important;
    padding-left: 1.5vw !important;
`;

export const SiderRow = styled(Row)`
    padding-top: 5px !important;
    height:100% !important;
`;

export const MainContentLayout = styled(Content)`
    height: 80vh;
    paddingLeft: 3vw;
    overflow: hidden;
`;

export const MainHrEventLayout = styled((props: any) => {
    const { children, ...rest } = props;
    return <hr {...rest}>{children}</hr>;
  })`
  
    ${({ hrmainstyle  }) =>
    hrmainstyle && `
    style: ${hrmainstyle} !important;
    `
    }
  `;
