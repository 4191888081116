import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import styled from "styled-components";
import React from "react";


export const MenuEx = styled(Menu)`
  background: #FFFFFF;
  box-shadow: 0px 54px 29px rgba(0, 0, 0, 0.02), 0px 27.0264px 14.5142px rgba(0, 0, 0, 0.0152067), 0px 16.2794px 8.74265px rgba(0, 0, 0, 0.0130318), 0px 10.4327px 5.60275px rgba(0, 0, 0, 0.0114179), 0px 6.76164px 3.63125px rgba(0, 0, 0, 0.01), 0px 4.25637px 2.28583px rgba(0, 0, 0, 0.0085821), 0px 2.44555px 1.31335px rgba(0, 0, 0, 0.00696822), 0px 1.07635px 0.57804px rgba(0, 0, 0, 0.00479335);
`;

export const AppSider = styled(Sider)`
  overflow: "auto";
  height: "100vh";
  position: "sticky";
  top: 0;
  left: 0;
  background: linear-gradient(180deg,  #8FB8E3  0%, #053771 100%);
`;

export const SideBarTitle = styled.div`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // &:hover {
  //   curser:pointer
  // }
  
  color: #F3F3F3;
  margin: 10px 0px 0px;
  padding-right: 10px;


`

export const DeviceMenuStatus = styled.div`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: #FFFFFF;
  margin: 10px 0px 0px;
`

export const SideNavLocationName = styled.p`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${(props) => props.theme.fontSizes.smallBody};
  line-height: 20px;

  display: flex;
  align-items: center;
  padding-right: 10px;
  word-break: break-word;
  margin: 0;
  color: ${(props) => props.theme.colors.palette.white};
`;

export const SideNavDate = styled.p`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${(props) => props.theme.fontSizes.smallBody};
  line-height: 18px;

  margin: 0px 0px 20px;
  padding-right: 10px;
  color: ${(props) => props.theme.colors.palette.white};
`;


  

export const StyledSideMenu = styled((props: any) => {
  const { children, ...rest } = props;
  return <Menu  {...rest}>{children}</Menu>;

})`

  ${({ color  }) =>
  color && `
    color: ${color} !important;
  `
  }

${({ background  }) =>
  background && `
    background: ${background} !important;
  `
  }
`;

export const SideBarHeader = styled((props: any) => {
  const { children, ...rest } = props;
  return <div {...rest}>{children}</div>;
})`

  ${({ bgcolor  }) =>
  bgcolor && `
  background: ${bgcolor} !important;
  `
  }
`;

export const SvgSideBarHeader = styled((props: any) => {
  const { children, ...rest } = props;
  return <svg {...rest}>{children}</svg>;
})`

  ${({ fill  }) =>
  fill && `
    fill: ${fill} !important;
  `
  }
`;

