/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Form, Input, Tooltip } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hierarchySelected } from "../../../../../redux/actions/role/locationAction";
import {
  DeleteIcon,
  EditIcon,
  VectorDarkIcon,
  VectorRedIcon,
} from "../../../../images";
import { locationListSelector } from "../../../../../redux/reducers/location/locationListReducer";
import {
  updateLocation,
} from "../../../../../redux/actions/organisation/fetchDepth";
import {
  organisationDepthSelector,
  clearDeleted,
  clearUpdated,
  addClearState,
} from "../../../../../redux/reducers/organisation/organisationDepthReducer";
import { LocationModal } from "./locationModal/locationModal";
import { fetchDevicesAtHierarchy } from "../../../../../redux/actions/device/deviceAction";
import { LocationEditing } from "./OrganisationLocations.styles";
import { jsonData } from "../../../../constants/constants";
import { useTranslation } from 'react-i18next';

export const LocationRow = (props: any) => {
  const { t } = useTranslation()
  const {
    isSelected,
    hasChildren,
    rowName,
    selectedIndex,
    gridIndex,
    rowId,
    rowType,
    isEditingAllowed,
    selectLocation,
    selectLocation2,
    parentID,
    index
  } = props;
  const dispatch = useDispatch();
  let { selectedIndexArray } = useSelector(locationListSelector);
  const [showInput, setShowInput] = useState(true);
  const { deleted, updated, addFormState } = useSelector(organisationDepthSelector);
  const [warn, setWarn] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [locationName, setLocationName] = useState(rowName);
  const [intialText, setIntialText] = useState(rowName);
  const [hideCol, setHideCol] = useState<boolean>();
  const inputElement:any = useRef();

  useEffect(() => {
    return () => {
      dispatch(clearUpdated());
    };
  }, [updated]);

  useEffect(() => {
    if(addFormState?.isError){
      setLocationName(rowName);
    }
  }, [addFormState])

  useEffect(() => {
    return () => {
      dispatch(addClearState());
    };
  }, [isSelected]);

  useEffect(() => {
    setLocationName(rowName);
  }, [rowName]);

  useEffect(() => {
    if (deleted) {
      OnNextClick(gridIndex, 0);
    }
    return () => {
      dispatch(clearDeleted());
    };
  }, [deleted]);

  const OnEditClick = (rowId: number) => {
    setShowInput(!showInput);
    setShowForm(!showForm);
    selectLocation2();
    setTimeout(() => {
      inputElement?.current?.focus()
    }, 300);
  };

  const OnDeleteClick = () => {
    dispatch(fetchDevicesAtHierarchy(rowId));
    setWarn(true);
  };

  const OnNextClick = (gridIndex: number, selectedIndex: number) => {
    let localArray = { ...selectedIndexArray };
    localArray[gridIndex] = selectedIndex;
    dispatch(hierarchySelected(localArray));
  };

  const onFinish = (values: any) => {
    if (values.locationName !== "" && values.locationName !== intialText) {
      setShowInput(true);
      dispatch(
        updateLocation({ organisationID: rowId, name: values.locationName,parentID :parentID ,  type : index === 0 ? 'LOC' : 'SLOC' })
      );
      setShowForm(!showForm);
      selectLocation(false);
    } else {
      setShowInput(true);
      setShowForm(!showForm);
      selectLocation(false);
    }
  };

  const locationSave = (e: any) => {
    if (e.target.value !== "") {
      setLocationName(e.target.value);
    }
  };

  const nodeByClassName =
    ".locationScrollStyle .childDiv:last-child .ant-card-body .ant-col";
  const hideNodeByClassName = ".locationScrollStyle .childDiv:last-child";

  const hideNodeByClosestClassName = ".ant-list-split";
  const hideNodeByClosestParentClassName = ".childDiv";

  useEffect(() => {
    const node = document?.querySelector<HTMLElement>(nodeByClassName);
    if (node !== null) {
      setHideCol(false);
    } else {
      setHideCol(true);
    }
  }, [selectedIndex,gridIndex]);

  useEffect(() => {
    if (hideCol) {
      const colDiv = document?.querySelector<HTMLElement>(hideNodeByClassName);
      colDiv.style.display = "none";
      var hideEmptyDiv = document?.querySelectorAll<HTMLElement>('.ant-list-empty-text'); 
      for (var i = hideEmptyDiv.length; i--;)
      //@ts-ignore
      hideEmptyDiv[i].closest(hideNodeByClosestParentClassName).style.display = "none";
    } else {
      const colDiv = document?.querySelector<HTMLElement>(hideNodeByClassName);
      colDiv.style.display = "block";
      var shoeContentDiv = document?.querySelectorAll<HTMLElement>('.ant-list-items'); 
      var shoeButtonDiv = document?.querySelectorAll<HTMLElement>('.addLocation'); 
      for (let i = shoeContentDiv.length; i--;)
      //@ts-ignore
      shoeContentDiv[i].closest(hideNodeByClosestParentClassName).style.display = "block";
      for (let i = shoeButtonDiv.length; i--;)
      //@ts-ignore
      shoeButtonDiv[i].closest(hideNodeByClosestParentClassName).style.display = "block";
    }
  }, [hideCol]);

  return (
    <div  tabIndex={0} onKeyPress={(e: any) => OnNextClick(gridIndex, selectedIndex)}
      className="location-row"
      onClick={(e: any) => OnNextClick(gridIndex, selectedIndex)}
    >
      <Row
        className={`action-items ${isSelected ? "active" : ""} ${
          showForm ? "activeBox" : ""
        }`}
      >
        {showForm ? (
          <Form name="basic" onFinish={onFinish} className="locationShow">
            <Form.Item initialValue={rowName} name="locationName">
              <Input
                name="location"
                value={locationName}
                onChange={locationSave}
                tabIndex={0}
                aria-label={""} 
                role={""}
                ref={inputElement}
                onBlur={() => onFinish({ locationName: locationName })}
              />
            </Form.Item>
          </Form>
        ) : (
          <Col span={15}>
            <Tooltip placement="bottomLeft" title={locationName}>
            <label className="locationNameLabel" htmlFor="">{locationName}</label>
            </Tooltip>
          </Col>
        )}
        {isEditingAllowed ? (
          <Col span={3} className="action">
            {/* eslint-disable-next-line */}
            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={index === 0 ? t(jsonData.editYourLocation) : t(jsonData.editYourSubLocation)}>
              <a
                className={`action-items-a ${showForm ? "locationIcon " : "EditlocationIcon"}`}
                key="list--edit"
                tabIndex={0}
                aria-label={t(jsonData.editYourLocation)} 
                role={"button"}
                onKeyPress={(e: any) => OnEditClick(rowId)}
                onClick={(e: any) => OnEditClick(rowId)}
              >
                <img src={EditIcon} alt="edit" />
              </a>
            </Tooltip>
          </Col>
        ) : null}
        {isEditingAllowed ? (
          <Col span={3} className="action">
            {/* eslint-disable-next-line */}
            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={index === 0 ? t(jsonData.deleteYourLocation) : t(jsonData.deleteYourSubLocation)}>
              <a
                className={`action-items-a ${showForm ? "locationIcon" : "EditlocationIcon"}`}
                key="list--delete"
                tabIndex={0}
                aria-label={t(jsonData.deleteYourLocation)} 
                role={"button"}
                onKeyPress={OnDeleteClick}
                onClick={OnDeleteClick}
              >
                <img src={DeleteIcon} alt="delete" />
              </a>
            </Tooltip>
          </Col>
        ) : null}

        <Col offset={isEditingAllowed ? 0 : 6} span={3}>
          {/* eslint-disable-next-line */}
          <LocationEditing haschildren={hasChildren.toString()}
            key="list--next"
            onClick={(e: any) => OnNextClick(gridIndex, selectedIndex)}
          >
            <img src={isSelected ? VectorRedIcon : VectorDarkIcon} alt="next" />
          </LocationEditing>
        </Col>
      </Row>
      <LocationModal isModalVisible={warn} setModalVisible={setWarn} rowId={rowId} rowName={rowName} rowType={rowType} rowParent={props?.rowParent}/>
    </div>
  );
};
