import { createAsyncThunk } from "@reduxjs/toolkit";
import RoleService from "../../../web/services/role/roleService";

const roleSrv = new RoleService();

// create Role
export const fetchAllPermissions = createAsyncThunk(
  "permission/fetchAllPermissions",
  async (_args, thunkAPI) => {
    try {
      const data = await roleSrv.fetchPermissions();
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
