import React from "react";
import "./button.less";

const Button = (props: any) => {
  return (
      <button
      id={props?.id}
      className={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
      onKeyUp={props.onKeyUp}
      tabIndex={props.tabIndex}
      aria-label={props.ariaLabel} 
      role={props.role}
      onKeyDown={props.onKeyDown}
    >
      {props.children}
    </button> 
  );
};
export default Button;
