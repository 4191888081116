/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url*/
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Tooltip } from "antd";
import Button from "../../common/button";
import { Button as _Button } from 'antd';
import "./summary.less";
import { ActiveImg, BranchIcon, UsersIcon, forwardArrowImg } from "../../../../images";
import DevicesIcon from '../../../../../assets/images/DevicesIcon.svg'
import { organisationDetailsSelector } from "../../../../../redux/reducers/organisation/organisationDetailsReducer";
import { AppRoutes } from "../../../../constants/enums";
import { ProfileThumbnailPicture } from "../../user/profile/profileThumbnailPicture";
import { AvatarColorList, PortalType, PortalTypeKey, jsonData } from "../../../../constants/constants";
import { getUserRoles } from "../../../../../utility/appUtil";
import { UserRole } from "../../../../constants/enums";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { CompWrapper } from "../../common/compWrapper/compWrapper";
import { settingsEntered } from "../../../../../redux/actions/app/appAction";
import { useTranslation } from 'react-i18next';

const OrganisationSummary = (props: any) => {
  const { t } = useTranslation()
  const { organisation, locations, users, devices } = useSelector(organisationDetailsSelector);
  const { onChangeTab } = props;
  const { appUser } = useSelector(userStateSelector);
  const dispatch = useDispatch()

  const editOrganisation = () => {
    onChangeTab("2");
  };
  const editSubscription = () => {
    onChangeTab("3");
  };

  const openNewTab = (loc: string = '') => {
    let url = '';
    switch (loc) {
      case 'locations':
        dispatch(settingsEntered({ path: AppRoutes.USER_DASHBOARD, isSettingState: true }))
        url = `${AppRoutes.ORGANISATION_LOCATIONS}?${PortalTypeKey.CLIENT_PORTAL}=${PortalType.CLIENT_PORTAL}&oid=${organisation?.result?.id}`;
        break;

      case 'users':
        dispatch(settingsEntered({ path: AppRoutes.USER_DASHBOARD, isSettingState: true }))
        url = `${AppRoutes.USER_LIST}?${PortalTypeKey.CLIENT_PORTAL}=${PortalType.CLIENT_PORTAL}&oid=${organisation?.result?.id}`;
        break;

      case 'devices':
        dispatch(settingsEntered({ path: AppRoutes.USER_DASHBOARD, isSettingState: true }))
        url = `${AppRoutes.DEVICE_SETTINGS}?${PortalTypeKey.CLIENT_PORTAL}=${PortalType.CLIENT_PORTAL}&oid=${organisation?.result?.id}`;
        break;  
    }
    const win = window.open(url, "_blank");
    if (win !== null) {
      win.focus();
    }
  };

  const isSubscriptionEditingAllowed = () => {
    let role: any[] = [];
    if (appUser) role = getUserRoles(appUser);
    if (role.includes(UserRole.BIBLIOTHECA_USER)) {
      return false;
    }
    return true;
  }
  
  return (
    <div>
      <CompWrapper otherClasses="ant-row pl-vw scrollSummary">
        <Col span={16}>
          <div className="summary-container">
            <div className="summary-subcontainer">
              <ProfileThumbnailPicture src={organisation?.result?.info?.logo} firstName={organisation?.result?.name?.split(' ')?.[0] || ''} lastName={organisation?.result?.name?.split(' ')?.[1] || ''} colors={AvatarColorList} round={true} size="35" />
            </div>
            <span className="organisation-title">{organisation?.result?.shortName}</span>
            <span className="other-details mb-8">{organisation?.result?.name}</span>
            <p>
              <a tabIndex={-1} className="website" href={(organisation?.result?.info?.website?.includes("http")) ? organisation?.result?.info?.website : `https://${organisation?.result?.info?.website}`} target="_blank">{organisation?.result?.info?.website}</a>
            </p>
            <span className="other-details mt-36">
              {[organisation?.result?.postalAddress?.line1, organisation?.result?.info?.locality, organisation?.result?.postalAddress?.line2, organisation?.result?.postalAddress?.line3, organisation?.result?.postalAddress?.line4].filter(item => item?.trim() !== '').join(', ')}
            </span>
            <span className="other-details">
              {[organisation?.result?.postalAddress?.postcode, organisation?.result?.postalAddress?.county, organisation?.result?.postalAddress?.country].filter(item => item?.trim() !== '').join(', ')}
            </span>
            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Edit)}>
            <div className="edit-button" onKeyPress={editOrganisation}  >
              <Button
                type="smallBlack fs-12 btnCommon"
                onClick={editOrganisation}
                tabIndex={0}
                ariaLabel={""} 
                role={""}
              >
                {t(jsonData.Edit)}
              </Button>
            </div>
              </Tooltip>
          </div>
        </Col>
        <Col span={8}>
          {/* <div className="secondary-container" >
            <h3 className="subscriptionHeading">Subscription</h3>
            <p className="activeSub mb-0">{organisation?.result?.isActive ? <><img src={ActiveImg} alt="" />Active</> : "Inactive"}</p>
            {isSubscriptionEditingAllowed() ?
              <Col className="subscription-edit-button" md={24} lg={12}>
                <Button
                  type="smallBlack fs-12"
                  onClick={editSubscription}
                >
                  Edit
                </Button>
              </Col>
              : null}
          </div> */}
          <div className="secondary-container tertiaryContainer">
            <Row className="orgSummaryInfo">
              <Col  md={15} lg={17} xl={18}>
                <p className="specific-details mb-0"><img className="redi-icons" src={BranchIcon} alt="locations" />
                  <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Locations)}>
                    <span className="basicInfoEllipsis">{t(jsonData.Locations)}</span>
                  </Tooltip>
                </p>
              </Col>
              <Col md={6} lg={4} xl={3}>
                <p className="specific-details-number mb-0">{typeof locations == 'object' ? 0 : locations}</p>
              </Col>
              <Col span={3} onKeyPress={() => { openNewTab('locations') }}>
                {/* <Link to={{ pathname: '/organisation/locations', state: { organisationID: props.id } }} className="redi-button"> */}
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Locations)}>
                  <_Button type="link" tabIndex={0} aria-label={t(jsonData.Locations)} role={"link"} size="small" className="forwardBtn redi-button" onClick={() => { openNewTab('locations') }}>
                    <img className="forwardArrowBtn" src={forwardArrowImg} alt="" />
                  </_Button>
                  </Tooltip>
                {/* </Link> */}
              </Col>
            </Row>
            <Row className="orgSummaryInfor">
              <Col md={15} lg={17} xl={18}>
                <p className="specific-details mb-0"><img className="redi-icons" src={UsersIcon} alt="users" />
                  <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Users)}>
                    <span className="basicInfoEllipsis">{t(jsonData.Users)}</span>
                  </Tooltip>
                </p>
              </Col>
              <Col md={6} lg={4} xl={3}>
                <p className="specific-details-number mb-0">{typeof users == 'object' ? 0 : users}</p>
              </Col>
              <Col span={3} onKeyPress={() => { openNewTab('users') }}>
                {/* <Link to={{ pathname: AppRoutes.USER_LIST }} className="redi-button"> */}
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Users)}>
                  <_Button tabIndex={0} aria-label={t(jsonData.Users)} role={"link"} type="link" size="small" className="forwardBtn redi-button" onClick={() => { openNewTab('users') }}>
                    <img className="forwardArrowBtn" src={forwardArrowImg} alt="" />
                  </_Button>
                  </Tooltip>
                {/* </Link> */}
              </Col>
            </Row>
            <Row>
              <Col md={15} lg={17} xl={18}>
                <p className="specific-details mb-0"><img className="redi-icons" src={DevicesIcon} alt="devices" />
                  <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Devices)}>
                    <span className="basicInfoEllipsis">{t(jsonData.Devices)}</span>
                  </Tooltip>
                </p>
              </Col>
              <Col md={6} lg={4} xl={3}>
                <p className="specific-details-number mb-0">{typeof devices == 'object' ? 0 : devices}</p>
              </Col>
              <Col span={3}  onKeyPress={() => { openNewTab('devices') }}>
                {/* <Link to={{ pathname: '/organisation/devices' }} className="redi-button"> */}
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Devices)}>
                  <_Button tabIndex={0} aria-label={t(jsonData.Devices)} role={"link"} type="link" size="small" className="forwardBtn redi-button" onClick={() => { openNewTab('devices') }}>
                    <img className="forwardArrowBtn" src={forwardArrowImg} alt="" />
                  </_Button>
                  </Tooltip>
                {/* </Link> */}
              </Col>
            </Row>
          </div>
        </Col>
      </CompWrapper>
    </div>
  );
};
export default OrganisationSummary;
