import styled from "styled-components";
import { Empty } from 'antd'

export const DeviceHealthContainer = styled.div`
    padding-left: 3.5%;
    // column-width: 200px;
    // column-gap: 20px;
    // column-count: auto;
`;

export const DeviceComponentDiv = styled.div`
    width: 100%;
    display: inline-flex;
    height: 100%;
    margin-bottom: 5px;
`

export const DeviceCompDiv = styled.div`
    display: block;
    width: 100%;
`

export const EmptyDevice = styled(Empty)`
    display: initial !important;
`