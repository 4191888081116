/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { getHeight } from '../../../../../utility/appUtil';
import { ComponantWrapperStyle } from './compWrapper.styles';

 interface CompWrapperInterface {
    observeOn?: any;
    name?: string;
    exclude?: number;
    children?: any;
    otherClasses?: string;
  }

export const CompWrapper = (props:CompWrapperInterface) => {
    const {observeOn, name, exclude, children, otherClasses } = props
    const [ contentHeight, setContentHeight ] = useState(0);
    const names = name ?? 'scrollContent';

    /*********************
    
    observeOn => classname of that section which
    impacts the scroll on resize  
    
    name => classname on which scroll has to be applied

    exclude => component bottom gap 

    otherClasses => to pass other classes to scroll components
    
    **********************/

    useEffect(()=>{
    const resizeObserver = new ResizeObserver((entries) => {
        setContentHeight(getHeight(`.${names}`))
      });
      resizeObserver.observe(document.body);
      observeOn && resizeObserver.observe(document.querySelector<HTMLElement>(`.${observeOn}`));
      setContentHeight(getHeight(`.${names}`))
    }, []);

    return (
        <ComponantWrapperStyle className={`scrollContent scrollStyle ${otherClasses ?? ''}`} height={contentHeight-(exclude ?? 0)}>
            {children}    
        </ComponantWrapperStyle>
    );
}