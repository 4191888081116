import { createAsyncThunk } from "@reduxjs/toolkit";
import OrganisationService from "../../../web/services/organisation/organisationService";

const orgService = new OrganisationService();

export const settingsEntered = createAsyncThunk(
    "app/settingsEntered",
    async (_args: any, thunkAPI) => {
        try {
            const data = {
                isSettingState: _args.isSettingState,
                returnPath: _args.path,
            }
            return data;
        } catch (e: any) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const settingsExit = createAsyncThunk(
    "app/settingsExit",
    async (
        _args,
        thunkAPI
    ) => {
        return false
    }
);

export const sidebarNavigate = createAsyncThunk(
    "app/sidebarNavigate",
    async (
        _args: string,
        thunkAPI
    ) => {
        try {
            return _args;
        } catch (e: any) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

// When bibliotheca user enters in a client portal
export const getSelectedOrg = createAsyncThunk(
    "app/organisation/id",
    async (
        orgId: string,
        thunkAPI
    ) => {
        try {
            const data = await orgService.fetchOrganisationbyID(orgId);
            if (data) {
              return data;
            } else {
              return thunkAPI.rejectWithValue(data);
            }
        } catch (e: any) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const accountSettingsEntered = createAsyncThunk(
    "app/accountSettingsEntered",
    async (
        _args: any,
        thunkAPI
    ) => {
        try{
            const data = {
            headerType: _args.headerType,
            isAccountSettings: true,
            returnPath: _args.path,
            }
            return data;

        } catch (e: any) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const accountSettingsExit = createAsyncThunk(
    "app/accountSettingsExit",
    async (
        _args: any,
        thunkAPI
    ) => {
        try{
            const data = {
                headerType: _args.headerType,
                isAccountSettings: false,
                
            }
            
            return data;

        } catch (e: any) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const onPage = createAsyncThunk(
    "app/onPage",
    async (
        _args: any,
        thunkAPI
    ) => {
        try{
            const data = {
                onComponent: _args.onComponent                
            }
            
            return data;

        } catch (e: any) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const warnUnsave = createAsyncThunk(
    "app/warnUnsave",
    async (
        _args: any,
        thunkAPI
    ) => {
        try {
            const data = {
                calledBy : _args.calledBy,
                isWarnUnsaveOpen : _args.isWarnUnsaveOpen,
                closeWarn : false,
                applyChanges : false,
            }
            return data;
        } catch (e: any) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const warnConfirm = createAsyncThunk(
    "app/warnConfirm",
    async (
        _args: any,
        thunkAPI
    ) => {
        try {
            const data ={
            goTo : _args.goTo,
            applyChanges : true,
            }
            return data;
        } catch (e: any) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const warnDiscard = createAsyncThunk(
    "app/warnDiscard",
    async (
        _args,
        thunkAPI
    ) => {
        return true
    }
);

export const moveToItem = createAsyncThunk(
    "app/moveToItem",
    async (
        _args: any,
        thunkAPI
    ) => {
        try {
            const data = {
                itemIndex : _args.itemIndex,
                url: _args.url
            }
            return data;
        } catch (e: any) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);
export const getRoutePathName = createAsyncThunk(
    "api/getRoutPathName",
    async (_args:string, thunkAPI) => {
      try {
        const data = _args;
        if (data) { 
          return data;
        }else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );