import styled from "styled-components";

export const RowUserName = styled.div`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: ${(props) => (props.theme.fontWeights.normal)};
    font-size: ${(props) => (props.theme.fontSizes.h4)};
    line-height: 20px;
    color: ${(props) => (props.theme.colors.palette.black)};
    @media screen and (max-width: 992px) {
        font-size: ${(props) => (props.theme.fontSizes.h5)};
    }
`;

export const RowUserRole =styled.span`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: ${(props) => (props.theme.fontSizes.smallBody)};
    line-height: 20px;
    color: ${(props) => (props.theme.colors.palette.red)};
`;

export const RowUserLocation =styled.span`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: ${(props) => (props.theme.fontSizes.smallBody)};
    line-height: 20px;
    color: ${(props) => (props.theme.colors.palette.black)};
`;

export const RowUserDetail =styled.div`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: ${(props) => (props.theme.fontSizes.smallBody)};
    line-height: 20px;
    color: ${(props) => (props.theme.colors.palette.darkestGray)};
`;

export const RowUserInfo =styled.div`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: ${(props) => (props.theme.fontSizes.smallBody)};
    line-height: 20px;
    color: ${(props) => (props.theme.colors.palette.darkestGray)};
`;

export const RowAddUser =styled.span`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: ${(props) => (props.theme.fontSizes.h5)};
    line-height: 20px;
    display: flex !important;
    align-items: center;
    color: ${(props) => (props.theme.colors.palette.darkestGray)};
    margin-bottom: 0px;
`;
  