/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import { Form, Input } from 'antd'
import { useSelector } from 'react-redux'
import { AlertConfigSelector } from '../../../../../redux/reducers/alertConfig/alertConfigReducer'
import './alertInput.less'
import { EditIcon } from '../../../../images'
import { onlyUnique } from '../../../../../utility/utils'
import { jsonData } from '../../../../constants/constants'
import { useTranslation } from 'react-i18next';

interface AlertInputProps {
    onChange: any;
    initialValue: any;
    name: string
    data: any
    onRevert: Function
    activateAll?: any
    save: Function
}

function AlertInput(props: AlertInputProps) {
    const { t } = useTranslation()
    const { data, name, onChange, onRevert, save } = props
    const [clearedBy, setClearedBy] = useState([])
    const [lastValue, setLastValue] = useState(null)
    const [valid,setValid] = useState<boolean>(false)
    const [editInput, setEditInput] = useState<boolean>(false)
    const { alertConfigChanges } = useSelector(AlertConfigSelector)

    useEffect(() => {
        if (alertConfigChanges[data?.eventId] && alertConfigChanges[data?.eventId][name] !== null && alertConfigChanges[data?.eventId][name] !== undefined) {
            setClearedBy(alertConfigChanges[data?.eventId][name])
        }
        else {
            props.initialValue && setClearedBy(props.initialValue)
        }
    }, [alertConfigChanges, props.initialValue])

    const handleInput = (e: any) => {
        let value = e.target.value

        let validInput = new RegExp("^[0-9,|:|;| ]*$")

        setValid(validInput.test(value))
        save(validInput.test(value))
        setLastValue(value)
    }

    const onEditClick = () => {
        setEditInput(!editInput)
    }

    const onBlur = () => {
        setEditInput(false)

        let validInput = new RegExp("^[0-9,|:|;| ]*$")
        save(true)
        if((lastValue && validInput.test(lastValue)) || lastValue?.length === 0){
            //regex for replace comma,semicolon,whitespce
            const regexForStringReplace = /;|,|:| /gi

            const inputval = lastValue.replace(regexForStringReplace, ',').split(",")
            const input = inputval.filter(onlyUnique).map((x)=>parseInt(x))

            if (props?.initialValue && Array.isArray(props?.initialValue) && input.every((x)=>props?.initialValue?.includes(x)) && props?.initialValue?.every((x)=>input.includes(x))) {
                onRevert(data?.eventId, name)
            }
            else {
                onChange(data, { [name]: input })
            }
            setClearedBy(input)
        }
    }

    return (
        <>
            {!editInput ?
                <div tabIndex={0} aria-label={t(jsonData.edit)} onKeyPress={onEditClick} role='button' className='alertConfig-Input'
                onClick={onEditClick}
                >
                    <span>{clearedBy?.length ? clearedBy.toString() : '-'}</span>
                    <span className={`alertConfig-edit`}>
                        <img src={EditIcon} alt="edit" />
                    </span>
                </div>
                :
                <Form onBlur={onBlur} className="clearedBy-form">
                    <Form.Item
                        initialValue={clearedBy.toString()}
                        name="clearedBy"
                        rules={[
                            {
                                pattern:/^[0-9,|:|;| ]*$/,
                                message:`${t(jsonData.PleaseEnterValidIds)} (seperated by  " , ; or space")`
                            },
                            {
                                max: 100,
                                message: t(jsonData.LimitExceeded100characters)
                            },
                        ]}
                        className={`mb-0 labelName clearedBy-Input`}
                    >
                        <Input
                        name={`clearedBy-${data?.eventId}`}
                        value={clearedBy.toString()}
                        onChange={handleInput}
                        autoFocus={true}
                        autoComplete='off'
                        className="customInput"
                        onPressEnter={valid ? onBlur : () => {}}
                        />
                    </Form.Item>
                </Form>
            }
        </>
    )
}

export default AlertInput;
