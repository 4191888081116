/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListContainer } from "./list.Styles";
import ListHeader from '../../../../components/stateless/organisation/listHeader';
import ListBody from '../../../../components/stateless/organisation/listBody';
import { fetchTransformedOrganisationsList } from "../../../../../redux/actions/organisation/fetchOrganisations";
import { organisationListSelector, clearState, setSearchState, setSelectedRegion, setSelectedCountry } from "../../../../../redux/reducers/organisation/organisationListReducer";
import { MainLayoutService } from "../../../../services/ui/mainLayoutService";
import { AppRoutes, HeaderType, UserRole } from "../../../../constants/enums";
import { onPage, sidebarNavigate } from "../../../../../redux/actions/app/appAction";
import { SideBarItems } from "../../../../constants/sideBarConstants";
import { Pages } from "../../../../constants/constants";
import Loader from "../../../stateless/common/spinner";
import "../addOrganization/addOrganisation.less";
import { Redirect } from "react-router-dom"
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { urlModification } from "../../../../../utility/appUtil";

export const OrganisationList = (props: any) => {
  const { formState, organisations = [], search, limit, page, region, country } = useSelector(organisationListSelector);
  const dispatch = useDispatch();
  const { appUser } = useSelector(userStateSelector);
  
  useEffect(()=>{
    dispatch(onPage({onComponent : Pages.BIBLIOTHECA}));
    dispatch(sidebarNavigate(SideBarItems.ORGANISATIONS));
    return () => {
      dispatch(setSelectedCountry(''));
      dispatch(setSelectedRegion(''));
      dispatch(setSearchState(''));
    }
  }, [])

  useEffect(() => {
    MainLayoutService.renderHeader(HeaderType.BIBLIOTHECA_PORTAL);
    // dispatch(fetchOrganisations({search, page, limit, country, region}));
    dispatch(fetchTransformedOrganisationsList({search, page, limit, country, region}));
    
    return () => {
      dispatch(clearState());
    };
  }, [search, page, limit, country, region]);

  return (
    <>
    {(appUser.locations[0].role.name !== UserRole.PLATFORM_ADMIN && appUser.locations[0].role.name !== UserRole.BIBLIOTHECA_USER) ?

      <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} />
      :
      <ListContainer className="orgListView">
        <ListHeader></ListHeader>
        <Loader loading={formState?.loading}>
          <ListBody organisations={organisations}></ListBody>
        </Loader>
      </ListContainer>
    }</>
  );
};
