import React from 'react';
import OrganisationCard from './organisationCard';
import { Organisation } from '../../../models/organisation/organisation';
import { Card, Row, Col } from 'antd';
import './listBody.less';
import { Link } from "react-router-dom";
import { getUserRoles } from '../../../../utility/appUtil';
import { UserRole } from '../../../constants/enums';
import { userStateSelector } from '../../../../redux/reducers/user/userReducer';
import { useSelector } from "react-redux";
import { CompWrapper } from '../common/compWrapper/compWrapper';
import { jsonData } from '../../../constants/constants';
import { useTranslation } from 'react-i18next';

const ListBody = (props: any) => {
    const { t } = useTranslation()
    const organisations: Organisation[] = !!props.organisations.length ? (props.organisations.map((organisation: Organisation) => <OrganisationCard key={organisation.id} organisation={organisation}></OrganisationCard>)) : [];
    const {appUser} = useSelector(userStateSelector);

    const isAddOrgAllowed = () => {
        let role = [];
        if(appUser) role = getUserRoles(appUser);
        if(role.includes(UserRole.BIBLIOTHECA_USER)) return false;
            return true;
    }

    return (
        <CompWrapper observeOn="orgListHeader" otherClasses="orgListContainer pl-vw">
            {organisations.length ? organisations : <Card className="noOrgFound">{t(jsonData.NoOrganisationFound)}</Card>}
            {/* {isAddOrgAllowed() ?
            <Link to="/organisation/add">
            <Card className="organisationListCard addOrgCard">
                <Row>
                    <Col className="addOrgCardInner">
                          <span> + Add an Organisation</span>
                    </Col>
                </Row>
            </Card>
            </Link>
            :
            null} */}
        </CompWrapper>
    );
};

export default ListBody;
