import styled from "styled-components";

export const Discardbutton = styled.button`
  width: 150px;
  height: 30px;
  margin-top: 10px;
  border: 1px solid #1c1c1c;
  box-sizing: border-box;
  border-radius: 60px;
  cursor: pointer;
  outline: none;
`;

export const DeleteButton = styled.button`
  width: 150px;
  height: 30px;
  background: ${(props) => props.theme.colors.button.primary};
  font-size: ${(props) => props.theme.fontSizes.smallBody};
  border: 2px;
  color: ${(props) => props.theme.colors.text.penta};
  box-sizing: border-box;
  border-radius: 60px;
  cursor: pointer;
  outline: none;
`;

export const Primarytext = styled.div`
  font-size: ${(props) => props.theme.fontSizes.h4};
  color: ${(props) => props.theme.colors.text.primary};
  text-align: center;
  line-height: 20px;
  font-weight: ${(props) => props.theme.fontWeights.normal};
`;

export const Secondarytext = styled.div`
  font-size: ${(props) => props.theme.fontSizes.h3};
  color: ${(props) => props.theme.colors.text.primary};
  text-align: center;
  margin-top: 10px;
  line-height: 20px;
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;
