/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Form, Row, Col, Upload, message } from 'antd';
import {Button} from "../../../../../bit_components/common/button";
import { InboxOutlined } from "@ant-design/icons";
import { DeleteIconImg } from "../../../../images";
import {Dropdown} from "../../../../../bit_components/common/dropdown";
import { CountryRegionData } from 'react-country-region-selector';
import countriesTimezones from 'countries-and-timezones';
import { useDispatch } from "react-redux";
import { addOrganisation } from "../../../../../redux/actions/organisation/fetchOrganisationDetails";
import { organisationDetailsSelector, clearState ,clearAddOrganisationState } from "../../../../../redux/reducers/organisation/organisationDetailsReducer";
import { useSelector } from "react-redux";
import {Custominput} from "../../../../../bit_components/common/custominput";
import { Breadcrumbs } from "../../../stateless/common/breadcrumbs";
import { AppRoutes, UserRole } from "../../../../constants/enums";
import { IOrganisation, Info } from "../../../../models/organisation/iOrganisation";
import { AppService } from "../../../../services/ui/appService";
import { useHistory } from "react-router";
import { MainLayoutService } from "../../../../services/ui/mainLayoutService";
import { HeaderType } from "../../../../constants/enums";

import './addOrganisation.less';
import { getAppHeaderType, urlModification } from "../../../../../utility/appUtil";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { SideBarItems } from "../../../../constants/sideBarConstants";
import { onPage, sidebarNavigate } from "../../../../../redux/actions/app/appAction";
import { Pages, jsonData } from "../../../../constants/constants";
import { CompWrapper } from "../../../stateless/common/compWrapper/compWrapper";
import { Messages } from "../../../../constants/messages";
import { AppError } from "../../../stateless/common/appError";
import { Redirect } from "react-router-dom"
import WarnUnsavedChanges from "../../common/warnUnsavedChanges";
import { commonSelector, setIsDirty } from "../../../../../redux/reducers/common/commonReducer";
import { appSelector } from "../../../../../redux/reducers/app/appReducers";
import { useTranslation } from 'react-i18next';

var CountryLanguage = require('country-language');

const AddOrganization = (props: any) => {
  const { t } = useTranslation()
  const { formState, errorMsg , addOrganisationformState } = useSelector(organisationDetailsSelector);
  const { appUser } = useSelector(userStateSelector)
  const history = useHistory();
  // const { appUser } = props;

  const breadcrumbs = [
    {
      text: 'Organisations',
      href: AppRoutes.ORGANISATION_LIST
    }
  ];

  const dispatch = useDispatch();

  const getCountriesList = () => {
    return CountryRegionData.map((country: any) => {
      return {
        text: country[0],
        value: country[0]
      };
    });
  };

  const getRegionsListByCountry = (country: any) => {
    let _country = CountryRegionData.filter((item: any) => item[0] === country);
    if (_country.length) {
      return _country[0][2].split('|').map(region => { return { text: region.split('~')[0], value: region.split('~')[0] }; });
    }
    return [];
  };

  const getTimezonesListByCountry = (country: any) => {
    let _country = CountryRegionData.filter((item: any) => item[0] === country).shift()?.[1];
    if (_country) {
      return [...new Set(countriesTimezones.getTimezonesForCountry(_country)?.filter((tz: any) => tz.aliasOf === null)
        ),
      ]?.map((tz: any) => {
        return {
          text: `${tz.name}`,
          value: `${tz.name}`,
        };
      });
    }
    return [];
  };

  const checkIfCountryExist = (target: any) => {
    return CountryRegionData.filter((country: any) => country[0] === target).shift()?.[0];
  };

  const checkIfCountyExist = (target: any, parent: any) => {
    if (checkIfCountryExist(parent)) {
      return getRegionsListByCountry(parent).filter((item: any) => item.value === target)?.[0]?.value;
    }
    return '';
  };

  const checkIfTimezoneExist = (target: any, parent: any) => {
    return getTimezonesListByCountry(parent).filter((tz: any) => tz?.name === target).shift()?.value;
  };

  const initBasicInfoForm = () => {
    /**
     * object to be returned
     */
    return {
      id: '',
      name: '',
      shortName: '',
      logo: '',
      pa_line1: '',
      postcode: '',
      locality: '',
      country: '',
      county: '',
      website: '',
      email: '',
      supportURL: '',
      language: '',
      timezone: '',
      culture: '',
    };
  };

  const [countriesList, setCountriesList] = useState<any>(getCountriesList());
  const [regionsList, setRegionsList] = useState<any>(getRegionsListByCountry(checkIfCountryExist('')));
  const [timezonesList, settimezonesList] = useState<any>(getTimezonesListByCountry(checkIfCountryExist('')));
  const [languagesList, setLanguagesList] = useState<any>([]);
  const [culturesList, setCulturesList] = useState<any>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>(checkIfCountryExist('') || '');
  const [selectedCounty, setSelectedCounty] = useState<any>(checkIfCountyExist('', '') || '');
  const [form] = Form.useForm();
  const [basicInfoForm, setBasicInfoForm] = useState<any>(initBasicInfoForm());
  const [orgError, setOrgError] = useState(null);

  useEffect(() => {
    dispatch(onPage({onComponent : Pages.BIBLIOTHECA}));
    dispatch(sidebarNavigate(SideBarItems.ORGANISATIONS));
    MainLayoutService.renderHeader(HeaderType.BIBLIOTHECA_PORTAL);
  }, []);

useEffect(() => {
    if (addOrganisationformState.isSuccess) {
      AppService.showToast(`Organisation added successfully.`);
      dispatch(clearState());
      dispatch(clearAddOrganisationState())
      history.push(urlModification(AppRoutes.ORGANISATION_LIST));
    }
    else if (addOrganisationformState.isError){
      if(!errorMsg){
        AppService.showToast(Messages.ERROR_OCCURRED)
        dispatch(clearState());
        dispatch(clearAddOrganisationState())
      }
    }
  }, [addOrganisationformState]);

  useEffect(() => {
    CountryLanguage.getCountryMsLocales(CountryRegionData.filter((item: any) => item[0] === selectedCountry).shift()?.[1], function (err: any, locales: any) {
      if (err) {
        console.log(err);
      } else {
        var tempCulture: string[] = [];
        var tempLanguage: string[] = [];
        if (locales) {
          locales.forEach(function (locale: any) {
            tempCulture.push(locale.langCultureName);
            tempLanguage.push(locale.displayName.split('-')[0].trim())
          });
          setCulturesList(tempCulture.map((culture: any) => {
            return { text: culture, value: culture };
          }));
          setLanguagesList(tempLanguage.map((language: any) => {
            return { text: language, value: language };
          }));

          if (tempLanguage.indexOf(basicInfoForm.language) === -1) {
            setBasicInfoForm({ ...basicInfoForm, language: '' })
          }
          if (tempCulture.indexOf(basicInfoForm.culture) === -1) {
            setBasicInfoForm({ ...basicInfoForm, culture: '' })
          }
        }
      }
    });
  }, [selectedCountry]);

  const onValueChange = (e: any) => {
    setBasicInfoForm({ ...basicInfoForm, [e.target.name]: e.target.value });
  };

  const onCountryChange = (e: any) => {
    setBasicInfoForm({ ...basicInfoForm, country: e });
    setSelectedCountry(e);

    setRegionsList(getRegionsListByCountry(e));
    setBasicInfoForm({ ...basicInfoForm, county: '' });
    setSelectedCounty('');

    settimezonesList(getTimezonesListByCountry(e));
    setBasicInfoForm({ ...basicInfoForm, timezone: '' });
    setBasicInfoForm({ ...basicInfoForm, language: '' });
    setBasicInfoForm({ ...basicInfoForm, culture: '' });
    form.setFieldsValue({county: null, timezone: null , language:null,culture:null})
  };

  const onRegionChange = (e: any) => {
    setBasicInfoForm({ ...basicInfoForm, county: e });
  };

  const onLanguageChange = (e: any) => {
    setBasicInfoForm({ ...basicInfoForm, language: e });
  };

  const onTimezoneChange = (e: any) => {
    setBasicInfoForm({ ...basicInfoForm, timezone: e });
  };

  const onCultureChange = (e: any) => {
    setBasicInfoForm({ ...basicInfoForm, culture: e });
  };

  const getPayload: any = () => {
    return {
      name: "",
      shortName: "",
      description: "",
      info: {
        countryCode: "",
        culture: "",
        email: "",
        supportURL: "",
        website: "",
        timezone: "",
        logo: "",
        locality: "",
        language: ""
      },
      hierarchyTypeCode: "ORG",
      parentId: null,
      isActive: true,
      pa_line1: "",
      pa_line2: "",
      pa_line3: "",
      pa_line4: "",
      pa_landmark: "",
      pa_postcode: "",
      pa_city: "",
      pa_county: "",
      pa_country: "",
      pa_isActive: true,
      ba_sameAs_pa: true,
      ba_line1: "",
      ba_line2: "",
      ba_line3: "",
      ba_line4: "",
      ba_landmark: "",
      ba_postcode: "",
      ba_city: "",
      ba_county: "",
      ba_country: "",
      ba_isActive: true
    };
  };

  const onFinish: any = (values: any) => {
    let payload: IOrganisation = getPayload();
    let fields = { ...basicInfoForm, country: values.country };

    payload['name'] = fields.name;
    payload['shortName'] = fields.shortName;
    payload['info']['culture'] = fields.culture;
    payload['info']['email'] = fields.email;
    payload['info']['supportURL'] = fields.supportURL;
    payload['info']['website'] = fields.website;
    payload['info']['timezone'] = fields.timezone;
    payload['info']['logo'] = fields.logo;
    payload['info']['locality'] = fields.locality;
    payload['info']['language'] = fields.language;
    payload['pa_line1'] = fields.pa_line1;
    payload['pa_postcode'] = fields.postcode;
    payload['pa_country'] = fields.country;
    payload['pa_county'] = fields.county;
    dispatch(addOrganisation(payload));
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const handleClose = (e: any) => {
    setBasicInfoForm({ ...basicInfoForm, logo: '' })
  };

  const handleChange = async ({ fileList }: any) => {
    setBasicInfoForm({ ...basicInfoForm, logo: await getBase64(fileList?.[0]?.originFileObj) });
  };

  const uploadButton: any = (
    <div>
      <p className="ant-upload-drag-icon draggerIcon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Upload Logo</p>
      <p className="ant-upload-hint customHint">{jsonData.ValidImageFormats}</p>
      <p className="ant-upload-hint customHint">{jsonData.MaxResolution}</p>
      <p className="ant-upload-hint customHint">{jsonData.MaxFileSize}</p>
    </div>
  );

  const uploadImage: any = (
    <div >
      <img src={basicInfoForm.logo} className="logoPreview" alt=""/>
      <img src={DeleteIconImg} onClick={handleClose} alt="delete" className="addOrgDeleteImg"/>
    </div>
  );

  const handleBeforeUpload = async (file: any, fileList: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    if (!isJpgOrPng) {
      message.error(jsonData.UploadImageFormatMsg);
      return Upload.LIST_IGNORE
    }
    const isLt2M = file.size / 1024 / 1024 < 0.8;
    if (!isLt2M) {
      message.error(jsonData.ImageSizeExceedsLimit);
      return Upload.LIST_IGNORE
    }
   const isValid = (await checkImageWH(file, 500, 500))
    return isValid ?true:Upload.LIST_IGNORE;
  };

  const checkImageWH = (file: any, width: number, height: number) => {
    return new Promise<boolean>(function (resolve, reject) {
        let filereader = new FileReader();
        filereader.onload = e => {
            let src = e.target.result;
            const image: any = new Image();
            image.onload = function () {
                if ((this.width && this.width > width) || (this.height && this.height > height)) {
                    message.error(
                      jsonData.UploadImageSizeLimit + width + " * "+  height
                    )
                    return false;
                } else {
                    resolve(true);
                }
            };
            image.onerror = reject;
            image.src = src;
        };
        filereader.readAsDataURL(file);
    });
}

  return (
    <>
      {appUser.locations[0].role.name !== UserRole.PLATFORM_ADMIN ?
      <>
      {appUser && getAppHeaderType(appUser) === HeaderType.JUNTO_PORTAL ?
      <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} /> 
      :
      <Redirect to={AppRoutes.ORGANISATION_OVERVIEW} />
      }</>
      :
      <div>
        <div className={addOrganisationformState?.isError ? "addOrgErrorContainer" :"addOrganisationContainer"}>
          <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
          <h1 className="addOrganisationHeading">Add an Organisation</h1>
          <div className="addOrgErrorMsgStyle">
        <AppError show={addOrganisationformState?.isError} errorMsg={errorMsg}/>
      </div>
        </div>
        <CompWrapper observeOn="addOrganisationContainer">
          <Form layout="vertical" form = {form} name="addOrganisation" requiredMark={false} onFinish={onFinish}>
            <Row >
              <Col xs={24} sm={24} md={7} lg={7} xl={7} className="addOrgCol">
                
                <Custominput customLabelClass='labelName' labelSubName={t(jsonData.Organisation)} labelName="name" initialValue={basicInfoForm.name} rules={[{ required: true, message: t(jsonData.OrganisationNameRequired) }]} placeholder={t(jsonData.Name)} name="name" value={basicInfoForm.name} onChange={onValueChange}/>
                
                <Custominput customLabelClass='labelName formControl mt-20' labelSubName={t(jsonData.DisplayName)} labelName="shortName" initialValue={basicInfoForm.shortName} rules={[{ required: true, message: t(jsonData.DisplaynameRequired)}]} placeholder={t(jsonData.DisplayName)} name="shortName" value={basicInfoForm.shortName} onChange={onValueChange} />
                
                {/* </Col> */}
                {/* </Row> */}
              </Col>

              <Col xs={24} sm={24} md={{span: 7, offset: 1}} lg={{span: 7, offset: 1}} xl={{span: 7, offset: 1}}>
                {/* logo */}
                <Form.Item label={t(jsonData.OrganisationLogo)} className="orgLogo">
                  <>
                    <div>
                      <Upload.Dragger
                        maxCount={1}
                        openFileDialogOnClick={(basicInfoForm.logo !== '') ? false : true}
                        onChange={handleChange}
                        showUploadList={false}
                        beforeUpload={handleBeforeUpload}
                        className="uploadDragger"
                      >
                        {(basicInfoForm.logo !== '') ? uploadImage : uploadButton}

                      </Upload.Dragger>
                    </div>
                  </>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={24} md={7} lg={7} xl={7} className="addOrgCol">
                {/* pa_line1 */}
                <Custominput customLabelClass='labelName formControl' labelSubName={t(jsonData.StreetAddress)} labelName="pa_line1" initialValue={basicInfoForm.pa_line1} rules={[{ required: true, message: t(jsonData.StreetAddressRequired) }]} placeholder={t(jsonData.StreetAddress)} name="pa_line1" value={basicInfoForm.pa_line1} onChange={onValueChange}/>
                
              </Col>
            </Row>

            <Row >
              <Col xs={24} sm={24} md={7} lg={7} xl={7} className="addOrgCol">
                {/* locality */}
                <Custominput customLabelClass='labelName formControl mt-20' labelSubName={jsonData.Locality} labelName="locality" initialValue={basicInfoForm.locality} rules={[{ required: true, message: t(jsonData.LocationIsRequired) }]} placeholder={jsonData.Locality} name="locality" value={basicInfoForm.locality} onChange={onValueChange}/>
                
              </Col>

              <Col xs={24} sm={24} md={{span: 7, offset: 1}} lg={{span: 7, offset: 1}} xl={{span: 7, offset: 1}} className="addOrgCol">
                {/* postcode */}
                <Custominput customLabelClass='labelName formControl mt-20' labelSubName={t(jsonData.PostalCode)} labelName="postcode" initialValue={basicInfoForm.postcode} rules={[{ required: true, message: t(jsonData.PostalcodeRequired) }]} placeholder={t(jsonData.PostalCode)} name="postcode" value={basicInfoForm.postcode} onChange={onValueChange}/>
                
              </Col>
            </Row>

            <Row >
              <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                {/* country */}
                <Form.Item className="dropDownLabel formControl mt-20" label={t(jsonData.Country)} name="country" initialValue={selectedCountry} rules={[{ required: true, message: t(jsonData.CountryRequired) }]}>
                  <Dropdown className="dropDownBorder" placeholder={t(jsonData.Country)} value={selectedCountry} optionValue={countriesList} onChange={onCountryChange}></Dropdown>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={{span: 7, offset: 1}} lg={{span: 7, offset: 1}} xl={{span: 7, offset: 1}}>
                {/* county */}
                <Form.Item className="dropDownLabel formControl mt-20" label={t(jsonData.Region)} name="county" initialValue={selectedCounty} rules={[{ required: true, message: t(jsonData.RegionRequired) }]}>
                  <Dropdown className="dropDownBorder" placeholder={t(jsonData.Region)} value={selectedCounty} optionValue={regionsList} onChange={onRegionChange}></Dropdown>
                </Form.Item>
              </Col>
            </Row>


            <Row>
              <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                {/* language */}
                <Form.Item className="dropDownLabel formControl" label={t(jsonData.Language)} name="language" initialValue={basicInfoForm.language} rules={[{ required: true, message:t(jsonData.LanguageRequired) }]}>
                  <Dropdown className="dropDownBorder" placeholder={t(jsonData.Language)} value={basicInfoForm.language} optionValue={languagesList} onChange={onLanguageChange}></Dropdown>
                </Form.Item>
                {/* <span className="orgErrorMsg">
                  {orgError}
                </span> */}
              </Col>
              <Col xs={24} sm={24} md={{span: 7, offset: 1}} lg={{span: 7, offset: 1}} xl={{span: 7, offset: 1}}>
                {/* timezone */}
                <Form.Item className="dropDownLabel formControl" label={t(jsonData.Timezone)} name="timezone" initialValue={basicInfoForm.timezone} rules={[{ required: true, message: t(jsonData.TimezoneRequired)}]}>
                  <Dropdown className="dropDownBorder" placeholder={t(jsonData.Timezone)} value={basicInfoForm.timezone} optionValue={timezonesList} onChange={onTimezoneChange}></Dropdown>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={{span: 7, offset: 1}} lg={{span: 7, offset: 1}} xl={{span: 7, offset: 1}}>
                {/* culture */}
                <Form.Item className="dropDownLabel formControl" label={t(jsonData.Culture)} name="culture" initialValue={basicInfoForm.culture} rules={[{ required: true, message:t(jsonData.CultureRequired)}]}>
                  <Dropdown className="dropDownBorder" placeholder={t(jsonData.Culture)} value={basicInfoForm.culture} optionValue={culturesList} onChange={onCultureChange}></Dropdown>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={24} md={7} lg={7} xl={7} className="addOrgCol">
                {/* website */}
                <Custominput customLabelClass='labelName formControl' labelSubName={t(jsonData.OrganisationWebsite)} labelName="website" initialValue={basicInfoForm.website} placeholder={t(jsonData.OrganisationWebsite)} name="website" value={basicInfoForm.website} onChange={onValueChange}/>
                
              </Col>
            </Row>

            <Row >
              <Col xs={24} sm={24} md={7} lg={7} xl={7} className="addOrgCol">
                 {/* email */}
              <Form.Item
                  label={t(jsonData.SupportEmail)}
                  name="email"
                  initialValue={basicInfoForm.email}
                  rules={[
                    {
                     type:'email',
                      message: t(jsonData.InvalidEmailAddress), 
                    },


                  ]}
                  className='labelName formControl mt-20'
                >
                <Custominput placeholder={t(jsonData.SupportEmail)} name="email" value={basicInfoForm.email} onChange={onValueChange}/>
              </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={{span: 7, offset: 1}} lg={{span: 7, offset: 1}} xl={{span: 7, offset: 1}} className="addOrgCol">
                {/* supportURL */}
                <Custominput customLabelClass='labelName formControl mt-20' labelSubName={t(jsonData.SupportURL)} labelName={t(jsonData.SupportURL)} initialValue={basicInfoForm.supportURL} placeholder={t(jsonData.SupportURL)} name="supportURL" value={basicInfoForm.supportURL} onChange={onValueChange}/>
                
              </Col>
            </Row>

            <AppError show={addOrganisationformState?.isError && orgError !== null} errorMsg={orgError} />
            <Row className="formControlRow">
              <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                <Form.Item className="formControl AddOrgButton">
                  <Button type="btnPrimary" htmlType="submit">
                    {t(jsonData.CreateOrganisation)}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </CompWrapper>
      </div>
      }
    </>
  );

};

export default AddOrganization;
