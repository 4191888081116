import { HeaderType, UserRole } from "../web/constants/enums";
import { Location } from "../web/models/common/location";
import User from "../web/models/user/user";
import { AppRoutes } from "../web/constants/enums";
import { AppStateStores } from "../web/services/storage/appStateService";
import { PortalTypeKey, PortalType, DefaultTime, TimePattern, languageStaticOptions} from "../web/constants/constants";
import moment from 'moment-timezone'
import { customFormatDate } from "./alertUtils";
import {parsePhoneNumber,AsYouType} from 'libphonenumber-js';
import parseFullNumber from 'libphonenumber-js/max';
import { DurationDropDownConstants } from '../web/constants/constants'
import secureLocalStorage from "react-secure-storage";

enum StorageKeys {
  USER = "@@storageKeys/USER",
  ACCESS_TOKEN = "@@storageKeys/ACCESS_TOKEN",
}

export const loggedInRedirectRoute = (appUser: User): string => {
  //debugger;
  const roles = getUserRoles(appUser);
  if (roles.includes(UserRole.LOCATION_ADMIN) || roles.includes(UserRole.ORGANISATION_ADMIN) || roles.includes(UserRole.LIBRARY_FRONT_DESK)) {
    return AppRoutes.USER_DASHBOARD;
  }
  return AppRoutes.ORGANISATION_LIST;
};

export const isLoggedInAppUser = (): boolean => {
  let loggedIn = false;
  const user = localStorage.getItem(StorageKeys.USER);
  if (user) {
    const objUser = JSON.parse(user) as User;
    loggedIn = objUser && objUser.id !=='';
  }
  return loggedIn;
};

export const setAppUser = (data: string): void => {
};

export const logoutAppUser = (): void => {
  localStorage.removeItem(AppStateStores.APP_STATE);
};

export const getAppToken = (): string | null => {
  const bearerToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
  return bearerToken;
};

export const setAppToken = (data: string): void => {
  localStorage.setItem(StorageKeys.ACCESS_TOKEN, data);
};

export const getUserRoles = (user: User) => {
  let roles: any[] = [];
  if (user && user.locations && user.locations.length) {
    user.locations.forEach((location: Location) => {
      if (!roles.includes(location.role?.name)) {
        roles.push(location.role?.name);
      }
    });
  }
  return roles;
};

export const getUserRoleIds = (user: User) => {
  let roles: any[] = [];
  if (user.locations && user.locations.length) {
    user.locations.forEach((location: Location) => {
      if (!roles.includes(location.role?.id)) {
        roles.push(location.role?.id);
      }
    });
  }
  return roles;
};

export const getUserLocationIds = (user: User) => {
  let locations: any[] = [];
  if (user.locations && user.locations.length) {
    user.locations.forEach((location: Location) => {
      if (!locations.includes(location.id)) {
        locations.push(location.id);
      }
    });
  }
  return locations;
};

export const concatNames = (firstName: string = "", lastName: string = "", middleName?: string, title?: string) => {
  let name = "";
  if(title){
    name = title.trim();
    if (firstName) {
      name = `${name} ${firstName}`.trim();
    }
    if (middleName) {
      name = `${name} ${middleName}`.trim();
    }
    if (lastName) {
      name = `${name} ${lastName}`.trim();
    }
  }
  else if (firstName) {
    name = firstName.trim();

    if (middleName) {
      name = `${name} ${middleName}`.trim();
    }

    if (lastName) {
      name = `${name} ${lastName}`.trim();
    }
  } else if (middleName) {
    name = middleName.trim();

    if (lastName) {
      name = `${name} ${lastName}`.trim();
    }
  } else if (lastName) {
    name = lastName.trim();
  }
  return name.trim();
};

export const replaceAll = (
  searchRegExp: RegExp,
  baseText: string,
  replacement: string
): string => {

  const result = baseText.replace(searchRegExp, replacement);
  return result;
};


export const getAppHeaderType = (appUser: User) => {
  let headerType = HeaderType.JUNTO_PORTAL;
  if(cameFromOrganisation()) {
    headerType = HeaderType.JUNTO_PORTAL
  } else {
    const roles = getUserRoles(appUser);
    if ((roles.includes(UserRole.PLATFORM_ADMIN))
    || (roles.includes(UserRole.PROJECT_MANAGER))
    || (roles.includes(UserRole.SITE_ENGINEER))
    || (roles.includes(UserRole.SUPPORT))
    || (roles.includes(UserRole.BIBLIOTHECA_USER))) {
      headerType = HeaderType.BIBLIOTHECA_PORTAL;
    
    }
  }
  return headerType;
};

export const cameFromOrganisation = () => {
  if(window.location.search.includes(`${PortalTypeKey.CLIENT_PORTAL}`)) return true;
  else return false;
}

export const urlModification = (url: string, ) =>
{

  let modifiedUrl = url;
  if(cameFromOrganisation()) {
    if(url && url.includes('?')) {
      modifiedUrl = `${url}&${PortalTypeKey.CLIENT_PORTAL}=${PortalType.CLIENT_PORTAL}`;
    } else {
      modifiedUrl = `${url}?${PortalTypeKey.CLIENT_PORTAL}=${PortalType.CLIENT_PORTAL}`;
    }
  }

  return modifiedUrl;
}

// export const getAccessToken = () => {
//   const token = window.localStorage.getItem('access-token')
//   return token;
// }

export const getIdToken = () => {
  const token = window.localStorage.getItem('id-token')
  return token;
}

// export const getRefreshToken = () => {
//   const token = window.localStorage.getItem('refresh-token')
//   return token;
// }

export const getSession = () => {
  const token = window.localStorage.getItem('session')
  return token;
}

export const getHeight = (classname : string) => {
  const node = document.querySelector<HTMLElement>(classname);
  if( node !== null|| node !== undefined)
  {
    const screenHeight = document.body?.clientHeight;
    const remainingHeight = screenHeight-node?.getBoundingClientRect().y;
    return remainingHeight
  }
  return 0;
}

export const convert_DateWithTimezone_To_UTC = (date : string, options ?:{ offset?: number, time : 'start' | 'end'}) => {

  let actual = options?.offset ?? getClientTimeZoneOffset(date)
  let orgDate = new Date(date)
  let copyOrgDate = new Date(orgDate)
  copyOrgDate.setTime(orgDate.getTime() - orgDate.getTimezoneOffset()*60*1000 - actual*60*1000)
  
  switch(options?.time){
    case 'start' :
      copyOrgDate.setSeconds(0,0)
      break
    case 'end' :
      copyOrgDate.setSeconds(59,999)
  }
  return copyOrgDate.toISOString()


}

export const getClientTimeZoneOffset = (date?:string) =>{
  let localeInfo:any = secureLocalStorage.getItem('locale')
  let originalDate = date ? new Date(date) : new Date()
  let organisationDate = 
    date ? 
      new Date(new Date(date).toLocaleString("en-US", {timeZone: localeInfo.timezone})) 
    : 
      new Date(new Date().toLocaleString("en-US", {timeZone: localeInfo.timezone}))
  let organisationDateCopy = new Date(organisationDate)
  organisationDateCopy.setTime(organisationDate.getTime()-(organisationDate.getTimezoneOffset()*60*1000))
  let diff = (organisationDateCopy.getTime() - originalDate.getTime())/(1000*60)
  let actualOffset = Math.round(diff)

  return actualOffset
}

export const getFormattedNumber = (num: number) => {
  switch (true) {
    case num > 999 && num <= 999999:
      return (num / 1000).toFixed(0) + 'K';
    case num >= 1000000 && num <= 99999999:
      return (num / 1000000).toFixed(0) + 'M';
    case num >= 1000000000:
      return (num / 1000000000).toFixed(0) + 'B';
    default:
      return num;
  }
}

export const getOrganizationDateAndTime = (date: string, format?: string) => {

  // converting date with timezone  as well as formatting
  let formattedDate
  let localeInfo:any = secureLocalStorage.getItem('locale')
  moment.tz.setDefault(localeInfo.timezone)
  
  // format date pattern
  if(format){
    formattedDate = moment(date).format(format)
  }
  else{
    formattedDate = moment(date).format('YYYY-MM-DD' + ' ' + TimePattern.HH_MM)
  }
  return formattedDate
}

export const getOrganizationDateIndex = (date: string, interval: string) => {
   // converting date with timezone  as well as formatting
   let localeInfo:any = secureLocalStorage.getItem('locale')
   moment.tz.setDefault(localeInfo.timezone)
   
   // format date pattern
   switch(interval) {
     case "hour":
     return moment(date).hour()
     case "day":
     return moment(date).day()
     default:
     return moment(date).month()
   }    
 }

export const getFormattedDate = (date: string, format: string) => {
  // only formatting the date
  return moment.utc(date).format(format)
}

export const getElapsedTime = (date: string, options? : {elapsedFrom?: string, text?: 'short' | 'long'}) => {

  // date and elaspedFrom should be in UTC

  let formattedDate = date
  let formattedElapsedFrom = options?.elapsedFrom ?? moment().utc().format()
    // time passed away
    switch(options?.text){
      case 'long':
        // for example : 5 hours ago
        formattedDate = moment.utc(formattedDate).from(formattedElapsedFrom)
        return formattedDate
    
      default:
        // for example : 5h
        formattedDate = customFormatDate(formattedDate, formattedElapsedFrom)
        return formattedDate
    }
}

export const isValidTimeZone = (tz) => {
  if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
      throw new Error('Time zones are not available in this environment');
  }

  try {
      Intl.DateTimeFormat(undefined, {timeZone: tz});
      return true;
  }
  catch (ex) {
      return false;
  }
}

export const convertPhoneNumberToUserFormat=(phoneNumber:string)=>{
  const userPhoneNumber = parsePhoneNumber(phoneNumber)
    return userPhoneNumber.formatInternational()
  }
 

export const convertUserFormatToPhoneNumber=(phoneNumber:any)=>{
  try{
    let localeInfo:any = secureLocalStorage.getItem('locale')
    let countryCode:any
      if(localeInfo.culture.includes('-')){
        countryCode=localeInfo.culture.split('-')[1].toUpperCase()
      }
  else{
        countryCode='US'
      }

    let asYouType = new AsYouType(countryCode)
    let input = asYouType.input(phoneNumber)
    let numberObj = parseFullNumber(input,countryCode)
    const formatNumber = numberObj.format('E.164')
    let isValidNumber = numberObj.isValid()

   return {isValidNumber,formatNumber}
  } catch (error) {
    console.log('error in phone number validation')
    return {}
  }
}

  export const getDuration = (selectedDuration : string) => {
  
  let durationObj : any = {}
  let localeInfo:any = secureLocalStorage.getItem('locale')
  let datePattern = 'YYYY-MM-DD' 
  moment.tz.setDefault(localeInfo.timezone)
  
  switch(selectedDuration){
    case DurationDropDownConstants.TODAY_VALUE:
      durationObj.startDate = moment().format(datePattern)
      durationObj.endDate = moment().format(datePattern)
      break
    
    case DurationDropDownConstants.YESTERDAY_VALUE:
      durationObj.startDate = moment().subtract(1, 'd').format(datePattern)
      durationObj.endDate = moment().subtract(1, 'd').format(datePattern)
      break
    
    case DurationDropDownConstants.THIS_WEEK_VALUE:
      durationObj.startDate = moment().startOf('isoWeek').format(datePattern)
      durationObj.endDate = moment().endOf('isoWeek').format(datePattern)
      break
      
    case DurationDropDownConstants.LAST_WEEK_VALUE:
      durationObj.endDate = moment().startOf('isoWeek').subtract(1, 'd').format(datePattern)
      durationObj.startDate = moment().startOf('isoWeek').subtract(1, 'w').format(datePattern)
      break
    
    case DurationDropDownConstants.THIS_MONTH_VALUE:
      durationObj.startDate = moment().startOf('M').format(datePattern)
      durationObj.endDate = moment().endOf('M').format(datePattern)
      break
  
    case DurationDropDownConstants.LAST_MONTH_VALUE:
      durationObj.endDate = moment().startOf('M').subtract(1, 'd').format(datePattern)
      durationObj.startDate = moment().startOf('M').subtract(1, 'M').format(datePattern)
      break
  
    case DurationDropDownConstants.THIS_YEAR_VALUE:
      durationObj.startDate = moment().startOf('year').format(datePattern)
      durationObj.endDate = moment().endOf('year').format(datePattern)
      break
  
    case DurationDropDownConstants.LAST_YEAR_VALUE:
      durationObj.endDate = moment().startOf('year').subtract(1, 'd').format(datePattern)
      durationObj.startDate = moment().startOf('year').subtract(1, 'y').format(datePattern)
      break
  
  }
  
  return durationObj
}
  
export const getCustomDuration = (start: any, end: any) => {
  let localeInfo:any = secureLocalStorage.getItem('locale')
  let datePattern = 'YYYY-MM-DD' 
  moment.tz.setDefault(localeInfo.timezone)
  
    return {
    customEndDate: moment(start).format(datePattern),
    customStartDate: moment(end).format(datePattern),
    customStartTime: DefaultTime.START,
    customEndTime: DefaultTime.END,
  }
}

export const languagesCode=(lang:string)=>{
  var selectedLang = languageStaticOptions?.filter((x:any)=>{
    return lang.toLowerCase() === (x?.value).toLowerCase()
  })
  return selectedLang?selectedLang[0]?.code:'en'
}

export const translateOptions = (data: any, translatefunc: any, key: any) => {
  //data = options data array, translate = i18next translate Hook t(), key = translate the desire key
  const translateDropDownOption = data?.map((x: any) => {
    return { ...x, [key]: translatefunc(`${x[key]}`) };
  });
  return translateDropDownOption;
};

export const translateTaleColum = (data: any, t: any, key: string) => {
  return data?.map((item: any) => {
    let newItem: any = item;
    if (item[key] && typeof item[key] === "string") {
      newItem = {
        ...item,
        [key]: t(`${item[key]}`),
      };
    }
    if (newItem.children && newItem.children.length > 0) {
      newItem.children = translateTaleColum(newItem.children, t, key);
    }
    return newItem;
  });
};

export const translateGraphText = (data: any[], t: (key: string) => string, key: string, keyB: string): any[] => {
  return data.map(item => ({
    ...item,
    [key]: item[key]?.split(":").length === 2
      ? `${t(item[key].split(":")[0].trim())} : ${item[key].split(":")[1]}`
      : item[key],
    data: item.data.map(nestedItem => ({
      ...nestedItem,
      [keyB]: t(nestedItem[keyB])
    }))
  }));
};

export const translateGraphTextDashbord = (data: any[], t: (key: string) => string, key: string): any[] => {
  var x = data.map(item => ({
   ...item,
   [key]:t(item[key])
 }));
 return x
};

/**
 * Retrieves the organization ID based on the provided app user and selected organization.
 * @param appUser The current user of the application.
 * @param selectedOrganisation The selected organization.
 * @returns The organization ID.
 */
export const getOrganisationId = (appUser: User, selectedOrganisation: any) => {
  let orgId = appUser?.organization.id as string;

  if (cameFromOrganisation()) {
    orgId = selectedOrganisation?.id;
  }
  return orgId;
};