import React from "react";
import { Row, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { jsonData } from "../../../../../constants/constants";

export const NoUserRow = () => {
  const { t } = useTranslation()
    return (
        <Card className="newUserList">
            <Row className="newUserListRow">
              <span>{t(jsonData.Nosuchuserfound)}</span>
          </Row>
        </Card>
      );
    };