import { createAsyncThunk } from "@reduxjs/toolkit";
import EventLogService from "../../../web/services/eventLogs/eventLogService";

const eventLogService = new EventLogService();

export const fetchEventLogList = createAsyncThunk(
  "eventLogList/fetchEventLogList",
  async (getEvents: any | null, thunkAPI) => {
    try {
      const data = await eventLogService.fetchEventLogList(getEvents);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchUnstructuredData = createAsyncThunk(
  "eventUnstructuredData/fetchUnstructuredData",
  async (id: any, thunkAPI) => {
    try {
      const data = await eventLogService.fetchEventLogById(id);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);


export const fetchEventsInfo = createAsyncThunk(
  "eventsInfo/fetchEventsInfo",
  async (_args:any|null , thunkAPI) => {
    try {
      const data = await eventLogService.fetchEventsInfo(_args)
      if (data) {
        return data
      }
      else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e)
    }
  }
);
