import styled from "styled-components";
import { getDeviceStateColor } from "../../../../../../utility/deviceUtils";

export const ProductName = styled.div`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${(props) => props.theme.fontSizes.h4};
  line-height: 20px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin: 0;
  color: ${(props) => props.theme.colors.palette.black};
`;

export const HealthLastUpdate = styled.div`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${(props) => props.theme.fontSizes.caption};
  line-height: 20px;
  margin-left: auto;

  color: ${(props) => props.theme.colors.palette.darkGray};
`;

export const DeviceInfo = styled.p`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${(props) => props.theme.fontSizes.caption};
  line-height: 20px;

  margin: 0;
  color: ${(props) => props.theme.colors.palette.darkGray};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
`;

export const LocationInfo = styled.p`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${(props) => props.theme.fontSizes.h5};
  line-height: 20px;

  margin: 0;
  color: ${(props) => props.theme.colors.palette.darkGray};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
`;



export const DevicePopUpHeading = styled.p`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${(props) => props.theme.fontSizes.h5};
  line-height: 20px;
  color: ${(props) => props.theme.colors.palette.black};
  display: flex;
  align-items: center;
  margin: 0;
`;

export const DevicePopUpDescription = styled.p`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${(props) => props.theme.fontSizes.smallBody};
  line-height: 16px;

  display: flex;
  align-items: center;
  margin: 0;
  padding-top: 6px;
  color: ${(props) => props.theme.colors.palette.darkestGray};
`;

export const DevicePopUpOccurred = styled.p`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${(props) => props.theme.fontSizes.footnote};
  line-height: 16px;

  display: flex;
  align-items: center;
  text-align: right;
  margin: 0;  
  color: ${(props) => props.theme.colors.palette.darkGray};
`;

interface DeviceHealthContProps {
  component : any
}
export const DeviceHealthStateContainer = styled.div<DeviceHealthContProps>`
   background: ${(props) => getDeviceStateColor(props.component)};
`