/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React ,{useState}from "react";
import { Row, Col, Tooltip } from "antd";
import { useHistory } from "react-router";
import { Links, jsonData } from "../../../../constants/constants";
import {
  CloudLibrary,
  ForwardVector,
  Portal,
  Bibliotheca,
  unifi,
} from "../../../../images";
import "./servicesMenu.less";
import { urlModification, cameFromOrganisation } from "../../../../../utility/appUtil";
import { useTranslation } from 'react-i18next';
import { keyBy } from "lodash";
const ServicesMenu = (props: any) => {
  const {t} = useTranslation();
  const { redirectUrl,role } = props
  const [focusedItemIndex, setFocusedItemIndex] = useState(-1);
  const history = useHistory();
  //TODO uncomment these post MVP
  const BibliothecaPortalNav = () => {
    // props.setDisplay(false);
    // history.push(urlModification(AppRoutes.ORGANISATION_OVERVIEW));
  };
  const juntoHandler = () => {
    // props.setDisplay(false);
  }
  const unifiHandler = () => {
    // props.setDisplay(false);
  }
  const cloudLibraryHandler = () => {
    window.open(urlModification(Links.CLOUD_LIBRARY));
    props.setDisplay(false);
  }
  const redirecturlhandle = (key) => {
    const paredUrl = redirectUrl
    const finalRedirectURL = paredUrl[key]
    window.open(urlModification(finalRedirectURL));
    props.setDisplay(false);
  }
  const handleKeyDown = (e:any,items:any) => {
    if (e.key === 'ArrowUp') {
    e.preventDefault();
    setFocusedItemIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (e.key === 'ArrowDown') {
    e.preventDefault();
    setFocusedItemIndex((prevIndex) =>
    Math.min(prevIndex + 1, items.length - 1)
    );
    }else if(e.keyCode == 27) {
      props.setDisplay(false);
    } else if (e.keyCode == 13) {
      redirecturlhandle(items[`${focusedItemIndex}`])
    }
    };
    const handleKeyUp = (e) => {
      if(e.keyCode === 9) {
        props.setDisplay(false);
      }
      return null
    }
  return (
    // <Popover placement="bottomLeft" title={text} content={content} trigger="click">
    <div className="main mainScroll scrollStyle">
      <h3 className="serviceHeading">{t(jsonData.Switchto)}</h3>
      {Object?.keys(redirectUrl)?.map((key, index) => {
        if(key === "libraryConnect Administration") {
          if(role === 'User')
            return (
              <Row style={{
                cursor: 'not-allowed',
                backgroundColor: 'transparent',
                }} tabIndex={0} aria-label={`${t(jsonData.switchTo)} ${key}`} role={""} onBlur={() => setFocusedItemIndex(-1)} className="subMenuItem notAllowedMenu" key={index}>
                <Col span={15}>
                  <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(`${key}`)}>
                    <p className="redirectKey notAllowedMenu">{t(`${key}`)}</p>
                  </Tooltip>
                </Col>
                <Col className="subMenuHed subMenuTop">
                  <button tabIndex={-1} className="forwardButton notAllowedMenu">
                    <img src={ForwardVector} className="forwardArrowIcon" alt="forwardvector" />
                  </button>
                </Col>
              </Row>
            );
          else{
            return (
              <Row style={{
                cursor: 'pointer',
                backgroundColor:
                index === focusedItemIndex ? '#e6f7ff' : 'transparent',
                }} tabIndex={0} aria-label={`${t(jsonData.switchTo)} ${key}`} role={"link"} onKeyPress={() => redirecturlhandle(key)} onBlur={() => setFocusedItemIndex(-1)}   onClick={() => redirecturlhandle(key)} className="subMenuItem" key={index}>
                <Col span={15}>
                  <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(`${key}`)}>
                    <p className="redirectKey">{t(`${key}`)}</p>
                  </Tooltip>
                </Col>
                <Col className="subMenuHed subMenuTop" onClick={() => redirecturlhandle(key)}>
                  <button tabIndex={-1} className="forwardButton">
                    <img src={ForwardVector} className="forwardArrowIcon" alt="forwardvector" />
                  </button>
                </Col>
              </Row>
            );
          }
        }
        else {        
        return (
          <Row  style={{
            cursor: 'pointer',
            backgroundColor:
            index === focusedItemIndex ? '#e6f7ff' : 'transparent',
            }} tabIndex={0} aria-label={`${t(jsonData.switchTo)} ${key}`} role={"link"} onBlur={() => setFocusedItemIndex(-1)} onKeyPress={() => redirecturlhandle(key)}
            onClick={() => redirecturlhandle(key)} className="subMenuItem" key={index}>
            <Col span={15}>
            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(`${key}`)}>
                  <p className="redirectKey">{t(`${key}`)}</p>
              </Tooltip>
            </Col>
            <Col className="subMenuHed subMenuTop" onClick={() => redirecturlhandle(key)}>
              <button tabIndex={-1} className="forwardButton">
                <img src={ForwardVector} className="forwardArrowIcon" alt="forwardvector" />
              </button>
            </Col>
          </Row>
        );
        }
      })}
      {/* {
        cameFromOrganisation() || props.showPortalLink ?
          <Row className="portalNav" onClick={BibliothecaPortalNav}>
            <Col className="subMenuHed">
              <img className="gotoImg" src={Portal} alt="portalImage" />
            </Col>
            <Col className="subMenuHed">
              <span className="portalText">Go to Bibliotheca Portal</span>
            </Col>
          </Row>
          : null} */}
    </div>
    // </Popover>
  );
};
export default ServicesMenu;
