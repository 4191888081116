import React from 'react'
import { jsonData } from '../../../constants/constants';
import CustomFilter from '../../stateless/common/customTableFilter';
 //dropdownvalues
export const optionValues = [
    {
      text: "Day",
      value: "day",
    },
    {
      text: "Week",
      value: "week",
    },
    {
      text: "Month",
      value: "month",
    },
    {
      text: "Quarter",
      value: "quarter",
    },
    {
      text: "Year",
      value: "year",
    },
    {
      text: "Custom",
      value: "custom",
    },
  ];

 //csv keys
 export const headers = [
  { header: "Search Text Filters", key: "searchFilter" },
  { header: "", key: "" },
  { header: "Severity", key: "extratype" },
  { header: "Date & Time", key: "dateAndTime" },
  { header: "Location", key: "location" },
  { header: "Device", key: "device" },
  { header: "EventId", key: "eventId" },
  { header: "Category", key: "category" },
  { header: "Event", key: "event" },
  { header: "Tracelogs", key: "tracelogForExcel",width: 30 },
];

export const tablecolumn=(
  filterTable: any,
  typeitem:any,
  locationitem:any,
  devicetitem:any,
  eventitem:any,
  eventtitem:any,
  categoryitem:any,
  filteredInfo:any,
  sortedInfo:any,
  t:any,
  selectedDeviceStateUpdate: any,
  selectedDeviceStates: any,
  partNameItems:any
  )=>{

  return[  
      // {
      //   title: "",
      //   dataIndex: "extratype",
      // },
      // {
      //   title: "",
      //   dataIndex: "descriptionText",
      // },
      // {
      //   title: "",
      //   dataIndex: "tracelog",
      // },
      {
        title: <div className="tableHeadingFixed" onClick={(e)=>e.stopPropagation()}>{t(jsonData.Severity)}
         <CustomFilter 
            optionList={typeitem} 
            selectedData={selectedDeviceStateUpdate}
            selectedItems={selectedDeviceStates?.typetext??[]}
            allSelectedText={t(jsonData.AllStates)}
            placeholder={t(jsonData.SelectState)}
            optionListClass="state-optionList-severity-e-logs-11"
            wrapperClassName="state-multiselect"
            coulmnName="typetext"
            filterName={t(jsonData.severityFilter)}
            />
        </div>,
        dataIndex: "severity",
        key: "typetext",
        ellipsis:true,
        // filters: typeitem,
        filteredValue: filteredInfo?.typetext || null,
        sortOrder: sortedInfo?.columnKey === 'typetext' && sortedInfo?.order,
        // onFilter: (value: any, record: any) => record?.typetext?.includes(value),
        sorter: (a: any, b: any) => null,
      },
      {
        title: <div className="tableHeadingFixed" onClick={(e)=>e.stopPropagation()}>{t(jsonData.DateTime)}</div>,
        dataIndex: "dateAndTime",
        key: "dateTimeStamp",
        ellipsis:true,
        sortOrder: sortedInfo?.columnKey === 'dateTimeStamp' && sortedInfo?.order,
        sorter: (a: any, b: any) => null,
      },
      {
        title: <div className="tableHeadingFixed" onClick={(e)=>e.stopPropagation()}>{t(jsonData.Location)}
         <CustomFilter 
            optionList={locationitem} 
            selectedData={selectedDeviceStateUpdate}
            selectedItems={selectedDeviceStates?.location??[]}
            allSelectedText={t(jsonData.AllStates)}
            placeholder={t(jsonData.SelectState)}
            optionListClass="state-optionList-severity-e-logs-12"
            wrapperClassName="state-multiselect"
            coulmnName="location"
            filterName={t(jsonData.locationFilter)}
            allowSearch={true}
            />
        </div>,
        dataIndex: "location",
        key: "location",
        ellipsis:true,
        // filters: locationitem,
        filteredValue: filteredInfo?.location || null,
        sortOrder: sortedInfo?.columnKey === 'location' && sortedInfo?.order,
        sorter: (a: any, b: any) => null,
        // onFilter: (value: any, record: any) => record.location? record?.location?.includes(value): null,
      },
      {
        title: <div className="tableHeadingFixed" onClick={(e)=>e.stopPropagation()}>{t(jsonData.Device)} 
         <CustomFilter 
            optionList={devicetitem} 
            selectedData={selectedDeviceStateUpdate}
            selectedItems={selectedDeviceStates?.deviceName??[]}
            allSelectedText={t(jsonData.AllStates)}
            placeholder={t(jsonData.SelectState)}
            optionListClass="state-optionList-severity-e-logs-13"
            wrapperClassName="state-multiselect"
            coulmnName="deviceName"
            filterName={t(jsonData.deviceFilter)}
            allowSearch={true}
            />
        </div>,
        dataIndex: "device",
        key: "deviceName",
        ellipsis:true,
        // filters: devicetitem,
        filteredValue: filteredInfo?.deviceName || null,
        sortOrder: sortedInfo?.columnKey === 'deviceName' && sortedInfo?.order,
        // onFilter: (value: any, record: any) => record?.device?.includes(value),
        sorter: (a: any, b: any) => null,
      },
      {
        title: <div className="tableHeadingFixed" onClick={(e)=>e.stopPropagation()}>{t(jsonData.EventId)}
         <CustomFilter 
            optionList={eventitem} 
            selectedData={selectedDeviceStateUpdate}
            selectedItems={selectedDeviceStates?.eventId??[]}
            allSelectedText={t(jsonData.AllStates)}
            placeholder={t(jsonData.SelectState)}
            optionListClass="state-optionList-severity-e-logs-14"
            wrapperClassName="state-multiselect"
            coulmnName="eventId"
            filterName={t(jsonData.eventIdFilter)}
            />
        </div>,
        dataIndex: "eventId",
        key: "eventId",
        ellipsis:true,
        // filters: eventitem,
        filteredValue: filteredInfo?.eventId || null,
        sortOrder: sortedInfo?.columnKey === 'eventId' && sortedInfo?.order,
        // sorter: (a: any, b: any) => a?.userID?.localeCompare(b?.userID),
        sorter: (a: any, b: any) => null,
        // onFilter: (value: any, record: any) => record?.eventIdSearch?.includes(value),
      },
      {
        title: <div className="tableHeadingFixed" onClick={(e)=>e.stopPropagation()}>{t(jsonData.Category)}
         <CustomFilter 
            optionList={categoryitem} 
            selectedData={selectedDeviceStateUpdate}
            selectedItems={selectedDeviceStates?.category??[]}
            allSelectedText={t(jsonData.AllStates)}
            placeholder={t(jsonData.SelectState)}
            optionListClass="state-optionList-severity-e-logs-15"
            wrapperClassName="state-multiselect"
            coulmnName="category"
            filterName={t(jsonData.categoryFilter)}
            />
        </div>,
        dataIndex: "category",
        ellipsis:true,
        key: "category",
        // filters: categoryitem,
        filteredValue: filteredInfo?.category || null,
        sortOrder: sortedInfo?.columnKey === 'category' && sortedInfo?.order,
        sorter: (a: any, b: any) => null,
        // onFilter: (value: any, record: any) => record?.category?.includes(value),
      },
      {
        title: <div className="tableHeadingFixed" onClick={(e)=>e.stopPropagation()}>{t(jsonData.Component)}
           <CustomFilter 
            optionList={partNameItems} 
            selectedData={selectedDeviceStateUpdate}
            selectedItems={selectedDeviceStates?.partName??[]}
            allSelectedText={t(jsonData.AllStates)}
            placeholder={t(jsonData.SelectState)}
            optionListClass="state-optionList-severity-e-logs-15"
            wrapperClassName="state-multiselect"
            coulmnName="partName"
            />
        </div>,
        dataIndex: "partName",
        ellipsis:true,
        key: "partName",
        // filters: partNameItems,
        filteredValue: filteredInfo?.partName || null,
        sortOrder: sortedInfo?.columnKey === 'partName' && sortedInfo?.order,
        sorter: (a: any, b: any) => null,
        // onFilter: (value: any, record: any) => record?.category?.includes(value),
      },
      {
        title: <div className="tableHeadingFixed" onClick={(e)=>e.stopPropagation()}>{t(jsonData.Event)}
         <CustomFilter 
            optionList={eventtitem} 
            selectedData={selectedDeviceStateUpdate}
            selectedItems={selectedDeviceStates?.event??[]}
            allSelectedText={t(jsonData.AllStates)}
            placeholder={t(jsonData.SelectState)}
            optionListClass="state-optionList-severity-e-logs-16"
            wrapperClassName="state-multiselect"
            coulmnName="event"
            filterName={t(jsonData.eventFilter)}
            allowSearch={true}
            />
        </div>,
        dataIndex: "event",
        ellipsis:true,
        key: "event",
        // filters: eventtitem,
        filteredValue: filteredInfo?.event || null,
        sortOrder: sortedInfo?.columnKey === 'event' && sortedInfo?.order,
        // onFilter: (value: any, record: any) => record?.event?.includes(value),
        sorter: (a: any, b: any) => null,
      },
      {
        title: "",
        dataIndex: "menu",
        key: "menu"
      },
    ]

}
  