import { createAsyncThunk } from "@reduxjs/toolkit";
import { AddRole } from "../../../web/models/role/addRole";
import RoleService from "../../../web/services/role/roleService";

const roleSrv = new RoleService();

export const fetchRoleList = createAsyncThunk(
  "role/fetchRoleList",
  async (_args: { organizationId?: string }, thunkAPI) => {
    try {
      const data = await roleSrv.fetchRoleList(_args.organizationId);
      //debugger;
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// fetch roles by organization id
export const fetchRoleListByOrgId = createAsyncThunk(
  "role/fetchRoleListByOrgId",
  async (orgId: string, thunkAPI) => {
    try {
      const data = await roleSrv.fetchRoleListByOrgId(orgId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// fetch transformed roles by organization id
export const fetchTransformedRoleList = createAsyncThunk(
  "role/fetchTransformedRoleList",
  async (orgId: string, thunkAPI) => {
    try {
      const data = await roleSrv.fetchTransformedRoleList(orgId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// fetch role deatils by role id
export const fetchRoleById = createAsyncThunk(
  "role/fetchRoleById",
  async (roleId: string, thunkAPI) => {
    try {
      const data = await roleSrv.fetchRoleById(roleId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// fetch transformed role details by role id
export const fetchTransformedRoleDetail = createAsyncThunk(
  "role/fetchTransformedRoleDetail",
  async (roleId: string, thunkAPI) => {
    try {
      const data = await roleSrv.fetchTransformedRoleDetail(roleId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// create Role
export const createRole = createAsyncThunk(
  "role/createRole",
  async (role: AddRole, thunkAPI) => {
    try {
      const data = await roleSrv.createRole(role);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// select role
export const selectRoleId = createAsyncThunk(
  "role/selectRoleId",
  async (id: string, thunkAPI) => {
    try {
      if (id !=='') {
        return id;
      } else {
        return thunkAPI.rejectWithValue(id);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// ACTION - updateRole
export const updateRole = createAsyncThunk(
  "role/updateRole",
  async ({role, id}: {role:AddRole, id:string}, thunkAPI) => {
    try {
      const data = await roleSrv.updateRole(role, id);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// ACTION - deleteRole
export const deleteRole = createAsyncThunk(
  "role/deleteRole",
  async ({toBeDeleteRoleId, replacementRoleId}: {toBeDeleteRoleId:string, replacementRoleId?:string}, thunkAPI) => {
    try {
      const data = await roleSrv.deleteRole(toBeDeleteRoleId, replacementRoleId &&replacementRoleId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// ACTION - getUsersCountForRole
export const getUsersCountForRole = createAsyncThunk(
  "role/getUsersCountForRole",
  async (roleId: string, thunkAPI) => {
    try {
      const data = await roleSrv.getUsersCountForRole(roleId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
