import styled from "styled-components";

export const HeaderLaytout = styled.div`
    min-height: 54px;
    padding-left: 3vw;
    padding-top: 10px;
    margin-bottom: 20px;
`;

export const HeaderDiv = styled.div`
    font-size: ${(props) => props.theme.fontSizes.h1};
    font-weight: ${(props) => props.theme.fontWeights.normal};
    line-height : 20px;
    display: flex;
    align-items: center;
`;

export const ContentLayout = styled.div`
    padding-left: 3vw;
    // height: 100%;
`;

export const NotificationSpinner = styled.div`
    bottom: 40px;
    position: absolute;
    text-align: center;
    height: 200px;
    width: 95%;
`;