import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
} from "../../../utility/stateUtil";
import {
  fetchAuditLogTransformList,
} from "../../actions/auditLog/auditListAction";
import { AuditListState } from "../../states/auditLogs/auditListState";
import { RootState } from "../../store/rootReducer";

const initialState: AuditListState = {
  auditLogList: [],
  currentPage:1,
  totalPages:1,
  formState: resetState(),
  totalAudits: 0
};

export const AuditLogListSlice = createSlice({
  name: "auditLogList",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      return state;
    },
    clearAuditLogs: (state) => {
      return {...state, auditLogList: [], currentPage: 1, totalPages:1, totalAudits:0}
    },
  },
  extraReducers: {
    [fetchAuditLogTransformList.fulfilled.toString()]: (state, { payload }) => {
      state.formState = fulfilledState();
      state.auditLogList = payload?.rows;
      state.totalAudits = payload?.count;
      state.currentPage = payload?.page;
      state.totalPages = payload?.totalPages;
      return state;
    },
    [fetchAuditLogTransformList.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [fetchAuditLogTransformList.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
  },
});

export const { clearState,clearAuditLogs } = AuditLogListSlice.actions;
export const auditLogListSelector = (state: RootState) => state.auditLogs;
export default AuditLogListSlice.reducer;
