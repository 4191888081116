import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
} from "../../../utility/stateUtil";
import { RoleRolePermissions } from "../../../web/models/role/roleRolePermissions";
import { createRole, deleteRole, fetchRoleById, fetchRoleList, fetchRoleListByOrgId, fetchTransformedRoleList, fetchTransformedRoleDetail, getUsersCountForRole, selectRoleId, updateRole } from "../../actions/role/roleAction";
import { RolesState } from "../../states/role/roleListState";
import { RootState } from "../../store/rootReducer";

const initialState: RolesState = {
  roles: [],
  orgRoleList: [],
  selectedRole: {} as RoleRolePermissions,
  selectedRoleId: '',
  selectedRoleUserCount: 0,
  roleCreated: false,
  roleDeleted: false,
  roleUpdated: false,
  formState : resetState(),
  selectedRoleUserCountFormState : resetState(),
  roleDeleteFormState: resetState(),
  roleCreateFormState: resetState(),
  selectedRoleIdFormState: resetState(),
  selectedRoleFormState: resetState(),
  errorMessage: '',
};

export const RolesSlice = createSlice({
  name: "roles",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      return state;
    },
    clearRoleDeleteFormState: (state) => {
      state.roleDeleteFormState = resetState();
      state.roleDeleted = false;
      return state;
    },
    clearRoleCreateFormState: (state) => {
      state.roleCreateFormState = resetState();
      state.roleCreated = false;
      return state;
    },
    clearSelectedRoleUserCountFormState: (state) => {
      state.selectedRoleUserCountFormState = resetState();
      state.selectedRoleUserCount = 0;
      return state;
    },
    clearSelectedRoleIdFormState: (state) => {
      state.selectedRoleIdFormState = resetState();
      state.selectedRoleId = '';
      return state;
    },
    clearSelectedRoleFormState: (state) => {
      state.selectedRoleFormState = resetState();
      state.selectedRole = {} as RoleRolePermissions;
      return state;
    },
    clearErrorMsg: (state) =>{
      state.errorMessage = '';
    }
  },
  extraReducers: {
    // Action - fetchRoleList
    [fetchRoleList.fulfilled.toString()]: (state, { payload }) => {
      state.roles = payload;
      state.formState = fulfilledState();
      return state;
    },
    [fetchRoleList.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [fetchRoleList.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
    // Action - fetchRoleListByOrgId
    [fetchRoleListByOrgId.fulfilled.toString()]: (state, { payload }) => {
      state.orgRoleList = payload;
      state.formState = fulfilledState();
      return state;
    },
    [fetchRoleListByOrgId.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [fetchRoleListByOrgId.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
    // Action - fetchTransformedRoleListByOrgId
    [fetchTransformedRoleList.fulfilled.toString()]: (state, { payload }) => {
      state.orgRoleList = payload;
      state.formState = fulfilledState();
      return state;
    },
    [fetchTransformedRoleList.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [fetchTransformedRoleList.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
    // Action - fetchRoleById
    [fetchRoleById.fulfilled.toString()]: (state, { payload }) => {
      state.selectedRole = payload;
      state.selectedRoleFormState = fulfilledState();
      return state;
    },
    [fetchRoleById.rejected.toString()]: (state, { payload }) => {
      state.selectedRoleFormState = rejectedState(payload?.message);
    },
    [fetchRoleById.pending.toString()]: (state) => {
      state.selectedRoleFormState = pendingState();
    },
    // Action - fetchTransformedRoleById
    [fetchTransformedRoleDetail.fulfilled.toString()]: (state, { payload }) => {
      state.selectedRole = payload;
      state.selectedRoleFormState = fulfilledState();
      return state;
    },
    [fetchTransformedRoleDetail.rejected.toString()]: (state, { payload }) => {
      state.selectedRoleFormState = rejectedState(payload?.message);
    },
    [fetchTransformedRoleDetail.pending.toString()]: (state) => {
      state.selectedRoleFormState = pendingState();
    },
    // Action - createRole
    [createRole.fulfilled.toString()]: (state, { payload }) => {
      if(payload?.error && payload?.display){
        state.roleCreated = false;
        state.errorMessage = payload?.display;
        state.roleCreateFormState = rejectedState(payload?.error?.message);
      }
      else{
        state.roleCreated = true;
        state.roleCreateFormState = fulfilledState();
      }
      return state;
    },
    [createRole.rejected.toString()]: (state, { payload }) => {
      state.roleCreateFormState = rejectedState(payload?.message);
    },
    [createRole.pending.toString()]: (state) => {
      state.roleCreateFormState = pendingState();
    },
    // Action - selectedRoleId
    [selectRoleId.fulfilled.toString()]: (state, { payload }) => {
      state.selectedRoleId = payload;
      state.selectedRoleIdFormState = fulfilledState();
      return state;
    },
    [selectRoleId.rejected.toString()]: (state, { payload }) => {
      state.selectedRoleIdFormState = rejectedState(payload?.message);
    },
    [selectRoleId.pending.toString()]: (state) => {
      state.selectedRoleIdFormState = pendingState();
    },
    // Action - deleteRole
    [deleteRole.fulfilled.toString()]: (state, { payload }) => {
      state.roleDeleted = true;
      state.roleDeleteFormState = fulfilledState();
      return state;
    },
    [deleteRole.rejected.toString()]: (state, { payload }) => {
      state.roleDeleteFormState = rejectedState(payload?.message);
    },
    [deleteRole.pending.toString()]: (state) => {
      state.roleDeleteFormState = pendingState();
    },
    // ACTION - updateRole
    [updateRole.fulfilled.toString()]: (state, { payload }) => {
      if(payload?.error && payload?.display){
        state.roleUpdated = false;
        state.errorMessage = payload?.display;
        state.formState = rejectedState(payload?.error?.message);
      }
      else{
        state.roleUpdated = true;
        state.formState = fulfilledState();
      }
      return state;
    },
    [updateRole.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [updateRole.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
    // Action - getUsersCountForRole
    [getUsersCountForRole.fulfilled.toString()]: (state, { payload }) => {
      state.selectedRoleUserCount = payload;
      state.selectedRoleUserCountFormState = fulfilledState();
      return state;
    },
    [getUsersCountForRole.rejected.toString()]: (state, { payload }) => {
      state.selectedRoleUserCountFormState = rejectedState(payload?.message);
    },
    [getUsersCountForRole.pending.toString()]: (state) => {
      state.selectedRoleUserCountFormState = pendingState();
    },
  },
});

export const { clearState,
  clearRoleDeleteFormState,
  clearRoleCreateFormState,
  clearSelectedRoleUserCountFormState,
  clearSelectedRoleIdFormState,
  clearSelectedRoleFormState,
  clearErrorMsg
  } = RolesSlice.actions;
export const roleListSelector = (state: RootState) => state.roleList;
export const roleFormStateSelector = (state: RootState) =>
  state.roleList.formState;
export default RolesSlice.reducer;
