/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk } from "@reduxjs/toolkit";
import NotificationService from "../../../web/services/notifications/notificationService";
import { getNotification } from "../../../web/models/notification/notifications";

const notificationService = new NotificationService();

// fetch notification
export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async (getNotification: getNotification | null, thunkAPI) => {
    try {
      const data = await notificationService.fetchNotifications(getNotification);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// notification transformation
export const fetchTransformedNotifications = createAsyncThunk(
  "notifications/fetchTransformedNotifications",
  async (getNotification: getNotification | null, thunkAPI) => {
    try {
      const data = await notificationService.fetchTransformedNotifications(getNotification);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// post readed notification
export const postReadNotification = createAsyncThunk(
  "notifications/postReadNotification",
  async (notificationInput: any | null, thunkAPI) => {
    try {
      const data = await notificationService.postAcknowlege(notificationInput);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const postSms = createAsyncThunk(
  "notifications/postSms",
  async (_args:any, thunkAPI) => {
      try {
          const data = await notificationService.sentSms(_args);
          if (data) {
              return data;
            } else {
              return thunkAPI.rejectWithValue(data);
            }
      } catch(e:any) {
        return thunkAPI.rejectWithValue(e);
      }
  }
)


export const postEmail = createAsyncThunk(
  "notifications/postEmail",
  async (_args:any, thunkAPI) => {
      try {
          const data = await notificationService.sentEmail(_args);
          if (data) {
              return data;
            } else {
              return thunkAPI.rejectWithValue(data);
            }
      } catch(e:any) {
        return thunkAPI.rejectWithValue(e);
      }
  }
)