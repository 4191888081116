// Base Imports
import React from "react";
import { Redirect, Route } from "react-router-dom";

// Other Imports


export const PrivateRoute = (props: any) => {
  const { isAuthenticated } = props;

  if (isAuthenticated && isAuthenticated === true) {
    //debugger;
    return (
      <Route {...props} component={props.component} render={undefined} />
    );
  }
  return (
    <Redirect
      to={{ pathname: '/login', state: { from: props.location } }}
    />
  );
};
