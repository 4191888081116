import React, { useEffect } from "react";
import { OverviewContainer } from "./overview.Styles";
import { MainLayoutService } from "../../../../services/ui/mainLayoutService";
import { AppRoutes, HeaderType, UserRole } from "../../../../constants/enums";
import { useDispatch,useSelector } from "react-redux";
import { onPage, sidebarNavigate } from "../../../../../redux/actions/app/appAction";
import { SideBarItems } from "../../../../constants/sideBarConstants";
import { Pages, jsonData } from "../../../../constants/constants";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { Redirect } from "react-router-dom"
import { urlModification } from "../../../../../utility/appUtil";
import { useTranslation } from 'react-i18next';

export const OrganisationOverview = (props: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const { appUser } = useSelector(userStateSelector);
  useEffect(()=>{
    dispatch(onPage({onComponent : Pages.BIBLIOTHECA}));
    MainLayoutService.renderHeader(HeaderType.BIBLIOTHECA_PORTAL);
    dispatch(sidebarNavigate(SideBarItems.OVERVIEW));
  })
  return (
    <>
    {(appUser.locations[0].role.name !== UserRole.PLATFORM_ADMIN && appUser.locations[0].role.name !== UserRole.BIBLIOTHECA_USER) ?

        <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} />
        :
    <OverviewContainer>{t(jsonData.Workinprogress)}</OverviewContainer>
    }</>
  );
};
