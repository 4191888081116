/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Button, Row, Col, DatePicker, TimePicker, Tooltip } from 'antd'
// import Button from '../button'
import moment from 'moment-timezone'
import './customRange.less'
import { AppError } from '../appError'
import { daysDifference } from '../../../../../utility/insightUtils'
import { DefaultTime, PickerFormat, jsonData } from '../../../../constants/constants'
import { Messages } from '../../../../constants/messages'
import { AppRoutes } from '../../../../constants/enums'
import { getFormattedDate } from '../../../../../utility/appUtil'
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage'

interface CustomRangeProps {
  displayHeading: string
  onChange: Function
  customValues?: any
  setCustomValues? : any
  start?: string
  from?: string
  end?: string
  to?: string
  className?: string
  setShowRefreshButton?: Function
}

const CustomRange = (props: CustomRangeProps) => {
  const { displayHeading, customValues, setCustomValues, start, end, from, to } = props
  const [customFromDate, setCustomFromDate] = useState(null)
  const [customToDate, setCustomToDate] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [customFromTime, setCustomFromTime] = useState(null)
  const [customEndTime, setCustomEndTime] = useState(null)
  const [editTimeFrame, setEditTimeFrame] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const customDateFormat = 'D MMMM, YYYY' + " " + PickerFormat.TIME
  const dateFormat = 'D MMMM, YYYY'
  const localeInfo:any = secureLocalStorage.getItem('locale')
  moment.tz.setDefault(localeInfo.timezone)
  const { t } = useTranslation()

  useEffect(() => {
    props?.setShowRefreshButton?.(editTimeFrame)
  },[editTimeFrame])

  //custom date cal.
  const handleChangeStartDate = (evt: any, startdate: any) => {
    setEditTimeFrame(false)
    if(startdate && startdate.length){
      setCustomFromDate(moment.utc(startdate, localeInfo.momentFormat).format(dateFormat));
      setCustomFromTime(moment.utc(startdate, localeInfo.momentDateTimeFormat).format(PickerFormat.TIME)) 
      setFromDate(moment.utc(startdate, localeInfo.momentFormat + "  " + PickerFormat.TIME).format(customDateFormat))
    }
    else{
      setCustomFromDate(null)
      setCustomFromTime(null)
      setFromDate(null)
    }
  }

  const handleChangeEndDate = (evt: any, enddate: any) => {
    setEditTimeFrame(false)
    if(enddate && enddate.length){
      setToDate(moment.utc(enddate, localeInfo.momentFormat + "  " + PickerFormat.TIME).format(customDateFormat));
      setCustomEndTime(moment.utc(enddate, localeInfo.momentDateTimeFormat).format(PickerFormat.TIME)) 
      setCustomToDate(moment.utc(enddate, localeInfo.momentFormat).format(dateFormat));
    }
    else{
      setCustomToDate(null)
      setCustomEndTime(null)
      setToDate(null)
    }
  }

  // const handlestarttimepicker = (evt: any, starttime: any) => {
  //   setEditTimeFrame(false)
  //   starttime?.length ? setCustomFromTime(starttime) : setCustomFromTime(null)
  // }
  // const handleendtimepicker = (evt: any, endtime: any) => {
  //   setEditTimeFrame(false)
  //   endtime?.length ? setCustomEndTime(endtime) : setCustomEndTime(null)
  // }

  const checkInterval = (startDate: string, endDate: string) => {
    let numberOfDays = daysDifference(startDate, endDate)

    switch (true) {
      case numberOfDays < 0:
        setErrorMsg(t(Messages.INVALID_RANGE))
        return false
      case isNaN(numberOfDays):
        customFromDate
          ? setErrorMsg(t(Messages.SELECT_END_DATE))
          : setErrorMsg(t(Messages.SELECT_START_DATE))
        return false
      default:
        if(numberOfDays > 365 && window.location.pathname === AppRoutes.INSIGHTS){
          setErrorMsg(t(Messages.DATA_NOT_SUPPORTED))
          return false
        }
        setErrorMsg(null)
        return true
    }
  }


  //on click timeframe button
  const handletimeframe = () => {
    let startTime = customFromTime ?? DefaultTime.START
    let endTime = customEndTime ?? DefaultTime.END
    let start = customFromDate + ' ' + startTime
    let end = customToDate + ' ' + endTime
    if (checkInterval(start, end)) {
      !customFromTime && setCustomFromTime(DefaultTime.START)
      !customEndTime && setCustomEndTime(DefaultTime.END)
      props.onChange({
        customEndDate: getFormattedDate(customToDate ,'YYYY-MM-DD'),
        customStartDate: getFormattedDate(customFromDate ,'YYYY-MM-DD'),
        customStartTime: startTime,
        customEndTime: endTime,
      })
      setEditTimeFrame(!editTimeFrame)
    }
  }


  useEffect(() => {
    if (customValues) {
      const startDate = start + " " + from
      const endDate = end + " " + to
      setCustomFromDate(getFormattedDate(start ,dateFormat))
      setCustomToDate(getFormattedDate(end ,dateFormat))
      setCustomFromTime(from ?? DefaultTime.START)
      setCustomEndTime(to ?? DefaultTime.END)
      setFromDate(getFormattedDate(startDate , customDateFormat))
      setToDate(getFormattedDate(endDate,customDateFormat))
      setCustomValues(false)
      setEditTimeFrame(true)
    }
  }, [customValues, start])

  return (
    <div className={`customRangeDiv ${props?.className}`}>
      <hr className="customRange-hrBorder" />
      <Row className="customHeader">
        {/* <span className="customRange-Text">From</span> */}
        {/* <Col className="timePickerBox">
          <TimePicker
      
            placeholder={DefaultTime.START}
            onChange={handlestarttimepicker}
            format={PickerFormat.TIME}
            className="timePickerStyl customRange-timePicker"
            value={
              customFromTime ? moment.utc(customFromTime, PickerFormat.TIME) : null
            }
          />
        </Col> */}
        <span className="customRange-Text">{t(jsonData.From)}</span>
        <Col className="datepickerBox">
          <DatePicker
            placeholder={t(jsonData['SelectDate&Time'])}
            showTime={{ format: 'HH:mm' , defaultValue:moment('00:00', 'HH:mm')}}
            format={localeInfo.momentFormat +" " + PickerFormat.TIME}
            onChange={handleChangeStartDate}
            suffixIcon={null}
            className="datepicker customRange-timePicker"
            value={fromDate ? moment.utc(fromDate, customDateFormat) : null}
          />
        </Col>
        <span className="customRange-Text">{t(jsonData.to)}</span>
        {/* <Col className="timePickerBox">
          <TimePicker
            
            onChange={handleendtimepicker}
            placeholder={DefaultTime.END}
            format={PickerFormat.TIME}
            className="timePickerStyl customRange-timePicker"
            value={
              customEndTime ? moment.utc(customEndTime, PickerFormat.TIME) : null
            }
          />
        </Col> */}
        {/* <span className="customRange-Text">on</span> */}
        <Col className="datepickerBox">
          <DatePicker
            placeholder={t(jsonData['SelectDate&Time'])}
            showTime={{ format: 'HH:mm',defaultValue:moment('23:59', 'HH:mm')}}
            format={localeInfo.momentFormat + " " + PickerFormat.TIME}
            suffixIcon={null}
            onChange={handleChangeEndDate}
            className="datepicker customRange-timePicker"
            value={toDate ? moment.utc(toDate, customDateFormat) : null}
          />
        </Col>
        <Col
          
          className={editTimeFrame && 'edittimeframeCol editUpdatetimeFrame'}
        >
          {!editTimeFrame && 
            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.UpdateRange)}>
              <Button
                type="primary"
                key="submit"
                htmlType="submit"
                onClick={handletimeframe}
                className="updateTimeFrameBtn"
              >
                {t(jsonData.UpdateRange)}
              </Button>
            </Tooltip>
          }
        </Col>
      </Row>
      <div className="pl-vw">
        <AppError show={errorMsg !== null} errorMsg={errorMsg} />
      </div>
      <hr className="customRange-hrBorder" />
    </div>
  )
}

export default CustomRange
