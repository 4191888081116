import React from "react";
import { AppRoutes, UserRole } from "../../../../constants/enums";
import { UserList } from "../../user/list/index";
import { Redirect } from "react-router-dom"
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { useSelector } from "react-redux";
import { urlModification } from "../../../../../utility/appUtil";

export const BibliothecaUsers = () => {
    const { appUser } = useSelector(userStateSelector);
    return (
        <>
        {appUser.locations[0].role.name !== UserRole.PLATFORM_ADMIN &&
            appUser.locations[0].role.name !== UserRole.BIBLIOTHECA_USER ?

            <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} />
            :
            <UserList />
        }</>
    )



    
}