/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  selectUser,
  fetchTransformUserList,
  filterTransformUserList,
} from "../../../../../redux/actions/user/userListAction";
import {
  clearUser,
  clearUserActions,
  userStateSelector,
} from "../../../../../redux/reducers/user/userReducer";
import {
  clearIsUserSelected,
  clearSearchFilters,
  clearState,
  userListSelector,
  clearUsers,
  clearListFormState,
} from "../../../../../redux/reducers/user/userListReducer";
import { MainLayoutService } from "../../../../services/ui/mainLayoutService";
import {
  HeaderType,
  LeftMenuType,
  DisplayViewType,
  UserRole,
} from "../../../../constants/enums";
import {
  LibrarySettingsSideBarItem,
  SideBarItems,
} from "../../../../constants/sideBarConstants";
import Loader from "../../../stateless/common/spinner/index";
import { UserCards } from "../../../stateless/user/list/userCards";
import { UserListHeader } from "../../../stateless/user/list/userListHeader";
import { UserRows } from "../../../stateless/user/list/userRows";
import {
  AvatarColorList,
  BlockUserWarnModal,
  RemoveUserWarnModal,
  ResetPwdWarnModal,
  UnBlockUserWarnModal,
  UserActionType,
  ResendInvite,
  Pages,
  jsonData
} from "../../../../constants/constants";
import { fetchRoleList } from "../../../../../redux/actions/role/roleAction";
import { roleListSelector } from "../../../../../redux/reducers/role/roleListReducer";
import { AppRoutes } from "../../../../constants/enums";
import { Location } from "../../../../models/common/location";
import { Role } from "../../../../models/common/role";
import User from "../../../../models/user/user";
import { fetchTransformedLocationList } from "../../../../../redux/actions/role/locationAction";
import { locationListSelector } from "../../../../../redux/reducers/location/locationListReducer";
import { UserSearch } from "../../../../models/user/userSearch";
import { AppService } from "../../../../services/ui/appService";
import WarnModal from "../../../stateless/common/warnModal";
import { UserAuthInput } from "../../../../models/user/userAuthInput";
import {
  blockUser,
  reInviteUser,
  removeUser,
} from "../../../../../redux/actions/user/userAction";
import { Messages } from "../../../../constants/messages";
import {
  cameFromOrganisation,
  concatNames,
  getAppHeaderType,
  replaceAll,
  urlModification,
} from "../../../../../utility/appUtil";
import { appSelector, logout } from "../../../../../redux/reducers/app/appReducers";
import { sendForgotPwdLink } from "../../../../../redux/actions/user/forgotPwdAction";

import "../../../stateless/common/customScrollBar/customScrollStyle.less"
import { onPage, settingsEntered, sidebarNavigate } from "../../../../../redux/actions/app/appAction";
import { CompWrapper } from "../../../stateless/common/compWrapper/compWrapper";
import {
  clearProfileSavedState,
  userProfileSelector,
} from "../../../../../redux/reducers/user/userProfileReducer";
import { Redirect } from "react-router-dom"
import { useTranslation } from 'react-i18next';

export const UserList = (props: any) => {
  const { t } = useTranslation()
  const { location } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    isUserSelected,
    userListViewType,
    formState,
    users = [],
    selectedUser,
    search,
    searchFormState,
    listFormState,
  } = useSelector(userListSelector);
  const { roles = [] } = useSelector(roleListSelector);
  const { locations = [] } = useSelector(locationListSelector);
  const {
    appUser,
    user,
    isUserBlocked,
    isUserRemoved,
    isUserInvited,
    forgotPwdRequest,
    deletedUserEmail,
  } = useSelector(userStateSelector);
  const { isProfileSaved } = useSelector(userProfileSelector);
  const { selectedOrganisation, settingsReturnPath } = useSelector(appSelector);
  const [viewType, setViewType] = useState<string>(userListViewType);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchToggle, setSearchToggle] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState<any>();
  const [selectedRole, setSelectedRole] = useState<any>();
  const [showWarn, setShowWarn] = useState(false);
  const [warnCancelBtn, setWarnCancelBtn] = useState("");
  const [warnConfirmBtn, setWarnConfirmBtn] = useState("");
  const [warnTitle, setWarnTitle] = useState("");
  const [warnContent, setWarnContent] = useState("");
  const [warnConfirmType, setWarnConfirmType] = useState<any>();
  const [userActionValue, setUserActionValue] = useState<any>();
  const [saveUnBlockUser, setSaveUnblockUser] = useState(null)
  const defaultBranchId = selectedBranch ? selectedBranch.id : 0;
  const defaultRoleId = selectedRole ? selectedRole.id : 0;

  window.onbeforeunload = function () {
    dispatch(clearUsers());
  };

  useEffect(() => {
    dispatch(clearUsers());
    dispatch(clearListFormState());
    if(appUser && getAppHeaderType(appUser) === HeaderType.JUNTO_PORTAL){
      dispatch(onPage({onComponent : Pages.SETTINGS}));
      dispatch(sidebarNavigate(SideBarItems.USERS))
    } else {
      dispatch(onPage({onComponent : Pages.BIBLIOTHECA}));
      dispatch(sidebarNavigate(SideBarItems.BIBLIOTHECA_USERS))
    }
    // if (cameFromOrganisation()) {
    //   dispatch(
    //     settingsEntered({ path: window.localStorage.getItem('path') ?? AppRoutes.USER_DASHBOARD, isSettingState: true })
    //   );
    // }

    return () => {
      dispatch(clearSearchFilters());
      dispatch(clearState());
      dispatch(clearUsers());
    };
  }, []);

  const getOrgId = () => {
    let orgId = appUser?.organization.id as string;
    if (cameFromOrganisation()) {
      orgId = selectedOrganisation?.id;
    }
    return orgId;
  };

  const [orgId, setOrgId] = useState(getOrgId());

  useEffect(() => {
    setOrgId(getOrgId());
  }, [selectedOrganisation]);

  useEffect(() => {
    orgId && bindRoles(orgId);
    orgId && bindLocations(orgId);
    orgId && bindUsers(orgId);
  }, [orgId]);

  useEffect(() => {
    isUserBlocked && bindUsers(getOrgId());
  }, [isUserBlocked, selectedOrganisation]);

  useEffect(() => {
    if (formState && formState.isError) {
      dispatch(clearState());
    } else if (formState && formState.isSuccess) {
      dispatch(clearState());
    }
  }, [formState.isError, formState.isSuccess]);

  useEffect(() => {
    let search: UserSearch = {
      role: selectedRole ? selectedRole : { id: '', name: "" },
      branch: selectedBranch ? selectedBranch : { id: '', name: "" },
      searchTerm: !searchToggle ? "" : searchTerm,
      searchToggle: searchToggle,
    };
    // dispatch(filterUserList({ search: search, organizationId: getOrgId() }));
    dispatch(filterTransformUserList({ search: search, organizationId: getOrgId() }));
  }, [
    selectedBranch,
    selectedRole,
    searchTerm,
    searchToggle /*searchFormState?.isSuccess, searchFormState?.isError*/,
  ]);

  useEffect(() => {
    if (
      user &&
      isUserBlocked &&
      warnConfirmType === UserActionType.BLOCK_USER
    ) {
      setShowWarn(false);
      const msg = replaceAll(
        /\{0\}/gi,
        t(Messages.USER_BLOCKED_SUCCESSFULLY),
        user.email
      );
      AppService.showToast(msg);
      dispatch(clearUser());
      dispatch(clearUserActions());
    }
    if (
      user &&
      isUserBlocked &&
      warnConfirmType === UserActionType.UNBLOCK_USER
    ) {
      setShowWarn(false);
      const msg = replaceAll(
        /\{0\}/gi,
       t(Messages.USER_UNBLOCKED_SUCCESSFULLY),
        user.email
      );
      AppService.showToast(msg);
      dispatch(clearUser());
      dispatch(clearUserActions());
    }
  }, [isUserBlocked]);

  useEffect(() => {
    if (user && forgotPwdRequest) {
      setShowWarn(false);
      AppService.showToast(
        user?.errorMsg ? user?.errorMsg : t(jsonData.RUN_NOW)
      );
      dispatch(clearUser());
      dispatch(clearUserActions());
      user?.errorMsg?.includes(appUser?.email) && dispatch(logout());
    }
  }, [forgotPwdRequest]);

  useEffect(() => {
    if (location && location.state) {
      if (location.state.message) {
        AppService.showToast(location.state.message);
      }
    }
  }, [location?.state]);

  const goToUserProfile = () => {
    if (appUser && getAppHeaderType(appUser) === HeaderType.BIBLIOTHECA_PORTAL){
      return AppRoutes.ORGANISATION_USER_PROFILE
    }
    else{
      return AppRoutes.USER_PROFILE
    }
  }

  useEffect(() => {
    if (isUserSelected && selectedUser && selectedUser.id !=='') {
      history.push(urlModification(goToUserProfile()));
      clearState();
    }
  }, [selectedUser]);

  useEffect(() => {
    if (isUserRemoved) {
      setShowWarn(false);
      const msg = replaceAll(
        /\{0\}/gi,
        t(Messages.USER_REMOVED_SUCCESSFULLY),
        deletedUserEmail ? deletedUserEmail : jsonData.User
      );
      AppService.showToast(msg);
      dispatch(clearUser());
      dispatch(clearUserActions());
      bindUsers(getOrgId());
    }
  }, [isUserRemoved, selectedOrganisation]);

  useEffect(() => {
    if (user &&
      isUserInvited &&
      warnConfirmType === UserActionType.RESEND_INVITE
    ) {
      setShowWarn(false);
      const fullName = concatNames(user?.firstName, user?.lastName);
      const msg = replaceAll(/\{0\}/gi, 
        t(Messages.USER_INVITED_SUCCESSFULLY), 
        fullName
      );
      AppService.showToast(msg);
      dispatch(clearUser());
      dispatch(clearUserActions());
    }
  }, [isUserInvited]);

  const setHeaderSideNav = (appUser: any) => {
    MainLayoutService.renderHeader(getAppHeaderType(appUser));
    MainLayoutService.renderSideBar(
      LeftMenuType.JUNTO_NORMAL,
      LibrarySettingsSideBarItem.USERS
    );
  };

  const bindUsers = (organizationId: string) => {
    // dispatch(fetchUserList(organizationId));
    dispatch(fetchTransformUserList(organizationId));
  };

  const bindRoles = (organizationId: string) => {
    dispatch(fetchRoleList({ organizationId: organizationId }));
  };

  const bindLocations = (organizationId: string) => {
    dispatch(fetchTransformedLocationList(organizationId));
  };

  const onViewTypeChangeCallback = (value: string) => {
    if (value) {
      setViewType(value);
    }
  };

  useEffect(() => {
    if (isProfileSaved) {
      const msg = replaceAll(/\{0\}/gi, t(Messages.USER_UPDATED_SUCCESSFULLY), saveUnBlockUser?.email?saveUnBlockUser?.email:'User');
      AppService.showToast(msg);
      if(saveUnBlockUser){
        setWarnConfirmType(UserActionType.UNBLOCK_USER)
        dispatch(blockUser({ userId: saveUnBlockUser?.id, isActive: true }))
        setSaveUnblockUser(null)
      }
    }
    return () => {
      dispatch(clearProfileSavedState());
    };
  }, [isProfileSaved]);

  const onAddUserCallback = () => {
    if (appUser && getAppHeaderType(appUser) === HeaderType.BIBLIOTHECA_PORTAL){
      history.push(urlModification(AppRoutes.ORGANISATION_ADD_NEW_USER))
    }
    else{
      history.push(urlModification(AppRoutes.ADD_NEW_USER))
    }
  };

  const onBranchChangeCallback = (branchId: string) => {
    const branch: Location = {
      id: branchId,
      name: "",
    };
    setSelectedBranch(branch);
  };

  const onRoleChangeCallback = (roleId: string) => {
    const role: Role = {
      id: roleId,
      name: "",
    };
    setSelectedRole(role);
  };

  const onToggleSearchCallback = () => {
    setSearchToggle(!searchToggle);
    if (!searchToggle) {
      setSearchTerm("");
    }
  };

  const onSearchCallback = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const onWarnModalCallback = () => {
    if (warnConfirmType === UserActionType.BLOCK_USER) {
      onBlockUser(false);
      setSearchTerm("")
    } else if (warnConfirmType === UserActionType.CHANGE_PWD) {
      onResetPwd();
    } else if (warnConfirmType === UserActionType.REMOVE_USER) {
      onRemoveUser();
    } else if (warnConfirmType === UserActionType.UNBLOCK_USER) {
      setSearchTerm("")
      onBlockUser(true);
    } else if (warnConfirmType === UserActionType.RESEND_INVITE) {
      if (userActionValue && userActionValue.id && userActionValue.id !=='') {
        var resendInviteParam = {
          deletedBy: null,
          email: userActionValue?.email,
          emailVerifiedAt:userActionValue?.emailVerifiedAt,
          userName: userActionValue?.userName,
          title:userActionValue?.title,
          firstName:userActionValue?.firstName,
          id:userActionValue?.id ,
          info: {},
          isActive:userActionValue?.isActive,
          lastName:userActionValue?.lastName,
          locations: [{id:userActionValue?.locations[0]?.id, name:userActionValue?.locations[0]?.role?.name, shortName:userActionValue?.locations[0]?.shortName,role:{id:userActionValue?.locations[0]?.role?.id, name:userActionValue?.locations[0]?.role?.name, type:userActionValue?.locations[0]?.role?.type}}],
          middleName:userActionValue?.middleName,
          organization: {id:userActionValue?.organization?.id, name:userActionValue?.organization?.name},
          phone:userActionValue?.phone,
          profileUrl: userActionValue?.profileUrl,
          token: null,
          updatedBy: null,
          role:{id:userActionValue?.locations[0]?.role?.id, name:userActionValue?.locations[0]?.role?.name, type:userActionValue?.locations[0]?.role?.type},
          userClaim: userActionValue?.userClaim ? userActionValue?.userClaim : {},
        }
      }
      onResetInvite(resendInviteParam);
    }
  };

  const onBlockUser = (isActive: boolean) => {
    if (userActionValue && userActionValue.id && userActionValue.id !=='') {
      dispatch(blockUser({ userId: userActionValue.id, isActive: isActive }));
    }
  };

  const onResetPwd = () => {
    if (userActionValue && userActionValue.id && userActionValue.id !=='') {
      const data: UserAuthInput = {
        id: '',
        email: userActionValue.email,
        password: "",
      };
      dispatch(sendForgotPwdLink(data));
    }
  };

  const onRemoveUser = () => {
    if (userActionValue && userActionValue.id && userActionValue.id !=='') {
      dispatch(removeUser(userActionValue));
    }
  };

  const onResetInvite = (user: User) => {
      dispatch(reInviteUser(user));
  };

  const onBlockUserCallback = (user: User) => {
    setWarnCancelBtn(t(BlockUserWarnModal.CANCEL_BTN_TEXT));
    setWarnConfirmBtn(t(BlockUserWarnModal.CONFIRM_BTN_TEXT));
    setWarnTitle(t(BlockUserWarnModal.TITLE));
    setWarnContent(t(BlockUserWarnModal.CONTENT));
    setShowWarn(true);
    if (user && user.id && user.id !=='') {
      setUserActionValue(user);
      setWarnConfirmType(UserActionType.BLOCK_USER);
    }
  };

  const onUnBlockUserCallback = (user: User) => {
    setWarnCancelBtn(t(UnBlockUserWarnModal.CANCEL_BTN_TEXT));
    setWarnConfirmBtn(t(UnBlockUserWarnModal.CONFIRM_BTN_TEXT));
    setWarnTitle(t(UnBlockUserWarnModal.TITLE));
    setWarnContent(t(UnBlockUserWarnModal.CONTENT));
    setShowWarn(true);
    if (user && user.id && user.id !=='') {
      setUserActionValue(user);
      setWarnConfirmType(UserActionType.UNBLOCK_USER);
    }
  };

  const onResetPwdCallback = (user: User) => {
    if (user && user.id && user.id !=='') {
      setUserActionValue(user);
      setWarnConfirmType(UserActionType.CHANGE_PWD);
    }
    setWarnCancelBtn(t(ResetPwdWarnModal.CANCEL_BTN_TEXT));
    setWarnConfirmBtn(t(ResetPwdWarnModal.CONFIRM_BTN_TEXT));
    user && user.id === appUser.id
      ? setWarnTitle(t(ResetPwdWarnModal.USER_TITLE))
      : setWarnTitle(t(ResetPwdWarnModal.ADMIN_TITLE));
    user && user.id === appUser.id
      ? setWarnContent(t(ResetPwdWarnModal.USER_CONTENT))
      : setWarnContent(t(ResetPwdWarnModal.ADMIN_CONTENT));
    setShowWarn(true);
  };

  const resendInviteCallback = (user: User) => {
    if (user && user.id && user.id !=='') {
      setUserActionValue(user);
      setWarnConfirmType(UserActionType.RESEND_INVITE);
    }
    user && user.id === appUser.id
      ? setWarnTitle(t(ResendInvite.USER_TITLE))
      : setWarnTitle(t(ResendInvite.ADMIN_TITLE));
    user && user.id === appUser.id
      ? setWarnContent(t(ResendInvite.USER_CONTENT))
      : setWarnContent(t(ResendInvite.ADMIN_CONTENT));
    setWarnCancelBtn(t(ResendInvite.CANCEL_BTN_TEXT));
    setWarnConfirmBtn(t(ResendInvite.CONFIRM_BTN_TEXT));
    setShowWarn(true);
  };

  const onRemoveUserCallback = (user: User) => {
    if (user && user.id && user.id !=='') {
      setUserActionValue(user);
      setWarnConfirmType(UserActionType.REMOVE_USER);
    }
    setWarnCancelBtn(t(RemoveUserWarnModal.CANCEL_BTN_TEXT));
    setWarnConfirmBtn(t(RemoveUserWarnModal.CONFIRM_BTN_TEXT));
    setWarnTitle(t(RemoveUserWarnModal.TITLE));
    setWarnContent(t(RemoveUserWarnModal.CONTENT));
    setShowWarn(true);
  };

  const onGoToProfileCallback = (user: User) => {
    setTimeout(() => {
      dispatch(selectUser(user));
    }, 200);
  };

  return (
    <>
      <WarnModal
        cancelButton={warnCancelBtn}
        confirmButton={warnConfirmBtn}
        primaryText={warnTitle}
        secondaryText={warnContent}
        isModalVisible={showWarn}
        cancelCallback={() => {
          setUserActionValue(null);
          setShowWarn(false);
        }}
        confirmCallback={onWarnModalCallback}
      />
        <UserListHeader
          appUser={appUser}
          viewType={viewType}
          viewTypeChangeCallback={onViewTypeChangeCallback}
          roles={roles}
          locations={locations}
          defaultBranch={defaultBranchId}
          defaultRole={defaultRoleId}
          addUserCallback={onAddUserCallback}
          branchChangeCallback={onBranchChangeCallback}
          roleChangeCallback={onRoleChangeCallback}
          toggleSearchCallback={onToggleSearchCallback}
          searchTerm={searchTerm}
          searchToggle={searchToggle}
          searchCallback={onSearchCallback}
        />
      <Loader loading={listFormState?.loading}>
        <CompWrapper observeOn="userFilterHed">
          <UserFilterList
            appUser={appUser}
            viewType={viewType}
            users={users}
            roles={roles}
            blockUserCallback={onBlockUserCallback}
            resetPwdCallback={onResetPwdCallback}
            removeUserCallback={onRemoveUserCallback}
            goToProfileCallback={onGoToProfileCallback}
            unBlockUserCallback={onUnBlockUserCallback}
            resendInviteCallback={resendInviteCallback}
            setSaveUnblockUser={setSaveUnblockUser}
          ></UserFilterList>
        </CompWrapper>
      </Loader>
    </>
  );
};

const UserFilterList = (props: any) => {
  const dispatch = useDispatch();
  const {
    appUser,
    viewType,
    users,
    roles,
    blockUserCallback,
    resetPwdCallback,
    removeUserCallback,
    goToProfileCallback,
    unBlockUserCallback,
    resendInviteCallback,
    setSaveUnblockUser
  } = props;
  
  useEffect(() => {
    return () => {
      setTimeout(() => {
        dispatch(clearIsUserSelected());
      }, 200);
      dispatch(clearSearchFilters());
      dispatch(clearState());
    };
  }, []);

  const onGoToProfileCallback = (user: User) => {
    if (goToProfileCallback) {
      goToProfileCallback(user);
    }
  };

  if (viewType === DisplayViewType.CARD_VIEW) {
    return (
      <>
      {appUser.locations[0].role.name === UserRole.LIBRARY_FRONT_DESK ?

      <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} />
      :
  
      <UserCards
        appUser={appUser}
        users={users}
        roles={roles}
        avatarColorList={AvatarColorList}
        blockUserCallback={blockUserCallback}
        resetPwdCallback={resetPwdCallback}
        removeUserCallback={removeUserCallback}
        goToProfileCallback={onGoToProfileCallback}
        unBlockUserCallback={unBlockUserCallback}
        resendInviteCallback={resendInviteCallback}
        setSaveUnblockUser={setSaveUnblockUser}
      />
    }</>
    );
  }
  return (
    <>
    {appUser.locations[0].role.name === UserRole.LIBRARY_FRONT_DESK ?

      <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} />
      :
      <UserRows
        appUser={appUser}
        users={users}
        roles={roles}
        avatarColorList={AvatarColorList}
        blockUserCallback={blockUserCallback}
        resetPwdCallback={resetPwdCallback}
        resendInviteCallback={resendInviteCallback}
        removeUserCallback={removeUserCallback}
        goToProfileCallback={onGoToProfileCallback}
        unBlockUserCallback={unBlockUserCallback}
        setSaveUnblockUser={setSaveUnblockUser}
      />
    }
    </>
  );
};
