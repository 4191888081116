import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIResponseError } from "../../../web/constants/enums";
import { Messages } from "../../../web/constants/messages";
import { UserAuthInput } from "../../../web/models/user/userAuthInput";
import { AuthService } from "../../../web/services/user/authService";
import UserService from "../../../web/services/user/userService";

const authSvc = new AuthService();
const userSvc = new UserService();

export const sendForgotPwdLink = createAsyncThunk(
  "forgotPwd/sendForgotPwdLink",
  async (user: UserAuthInput, thunkAPI) => {
    try {
      //debugger;
      const validUser = await authSvc.checkEmail(user);
      if (
        validUser &&
        validUser.organization &&
        validUser.organization.id !==''
      ) {
        const data = await userSvc.sendChangePwdLink(user);
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } else {
        return validUser;
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const resetPwd = createAsyncThunk(
  "forgotPwd/resetPwd",
  async (user: UserAuthInput | null, thunkAPI) => {
    try {
      //debugger;
      const response: any = await userSvc.resetPwd(user);
      if (response && response.data) {
        if (response.data?.error || response.data?.errors) {
          const error = response.data?.error;
          if (error && error.errorType) {
            let errorMsg = "";
            if (error.errorType === APIResponseError.NOT_FOUND) {
              errorMsg = Messages.PWD_RESET_LINK_EXPIRED;
              return errorMsg;
            }
          }
          return thunkAPI.rejectWithValue(response);
        }
        return "";
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

 //get user from reset pwd 
 export const resetPwdUserDetail = createAsyncThunk(
  "forgotPwd/resetPwdUserDetail",
  async (user: UserAuthInput | null, thunkAPI) => {
    try {
      const response: any = await userSvc.resetPwd(user);
      if (response && response.data) {
        if (response.data?.error) {
          const error = response.data?.error;
          if (error && error.errorType){
            let data = {
              errorMsg : ""
            };
            if (error.errorType === APIResponseError.NOT_FOUND) {
              data.errorMsg = Messages.USER_ALREADY_EXISTS;
              return data;
            }
          }
          return thunkAPI.rejectWithValue(response);
        }
        return response?.data?.result;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
