import styled from "styled-components";
import {Col} from 'antd';
import React from 'react';

export const AlertHead = styled.div`
    font-family: ${(props) => props.theme.fonts[0]};
    color: ${(props) => props.theme.colors.text.primary};
    width: 100%;
`;

export const AlertDiv = styled(Col)`
    font-size: ${(props) => props.theme.fontSizes.h1};
    font-weight: ${(props) => props.theme.fontWeights.normal};
    line-height : 20px;
    height: 54px;
    display: flex;
    align-items: center;
    padding-left: 4px;
`;

export const SpanAlert = styled.span`
    margin-right: 10px;
    cursor : pointer;
`;

export const DropDownWrapper = styled.div`
    // padding-left: 23px;
`;
export const AlertHeadBack = styled((props: any) => {
    const { children, span, ...rest } = props;
    return <Col span={span} {...rest}>{children}</Col>;
  })`
  
    ${({ showback }) =>
      showback === "true"?
      `display: initial;`
      :
      `display: none;`
    }
`;
  
export const AlertHeaders = styled(Col)`
    flex: auto;
    display: flex;
    justify-content: flex-end;
`;