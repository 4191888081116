import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { isValidTimeZone, logoutAppUser } from "../../../utility/appUtil";
import { APIResponseError } from "../../constants/enums";
import { Messages } from "../../constants/messages";
import { Location } from "../../models/common/location";
import User from "../../models/user/user";
import { UserAuthInput } from "../../models/user/userAuthInput";
import moment from 'moment-timezone'
import secureLocalStorage from "react-secure-storage";

export class AuthService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.AUTH));
  }

  /**
   * This will return name of Organization in case of valid email
   * @param user
   * @returns
   */
  async checkEmail(userInput: UserAuthInput): Promise<User | null> {
    const response = await this.get(`user/email/${userInput.email}`);
    if (response && response.data) {
      if (response.data?.error) {
        const error = response.data?.error;
        if (
          error &&
          error.errorType &&
          (error.errorType === APIResponseError.NOT_FOUND ||
            error.errorType === APIResponseError.USER_FORBIDDEN
          )
        ) {
          const userData = this.setErrorUserData(
            this.getErrorMessage(error)
          );
          return userData;
        }
      } else if (response.data?.result) {
        const data = response.data?.result;
        userInput.id = data?.id;
        const userData = this.setUserData(data);
        return userData;
      }
    }
    return null;
  }

  /**
   *
   * @param user
   * @returns
   */
  async authenticate(userInput: UserAuthInput | null): Promise<User | null> {
    const response: any = await this.post(`user/login`, userInput);
    if (response && response.data) {
      if (response.data?.error) {
        //debugger;
        const error = response.data?.error;
        if (
          error &&
          error.errorType &&
          error.errorType === APIResponseError.AUTH_FAILURE
        ) {
          const userData = this.setErrorUserData(
            this.getErrorMessage(error)
          );
          return userData;
        }
      } else if (response.data?.result) {
        const data = response.data?.result;
        if (data.id && data.id !== '') {
          const userData = this.setUserData(data);
          // window.localStorage.setItem('access-token', userData?.token)
          window.localStorage.setItem('id-token', userData?.idToken)
          // window.localStorage.setItem('refresh-token', userData?.refreshToken)
          window.localStorage.setItem('session', userData?.session)
          secureLocalStorage.setItem('locale', userData?.locale)
          return userData;
        }
      }
    }
    return await Promise.resolve(null);
  }

  private setUserData(data: any) {
    let locale: any = ''
    if(data?.locale){
      locale = JSON.parse(data?.locale)
      locale.timezone = isValidTimeZone(data?.organizationTimezone) ? data?.organizationTimezone : 'Europe/London'
      moment.tz.setDefault(locale.timezone)
    }
    const user: User = {
      deletedBy: data.deletedBy ?? null,
      email: data.email ?? "",
      emailVerifiedAt: data.emailVerifiedAt ?? "",
      userName: data.userName ?? "",
      title: data.title ?? "",
      firstName: data.firstName ?? "",
      id: data.id ?? '',
      lastName: data.lastName ?? "",
      middleName: data.middleName ?? "",
      locations: data.branches ?? [],
      organization: {
        id: data.organizationId ?? '',
        name: "",
      },
      info: data.info,
      // role: {
      //   id: data.role ? data.role.id : 0,
      //   name: data.role ? data.role.role : "",
      // },
      phone: data.phone ?? "",
      profileUrl: data.imageUrl ?? "",
      updatedBy: data.updatedBy ?? null,
      token: data.accessToken ?? null,
      idToken: data['id-token'] ?? null,
      refreshToken: data.refreshToken ?? null,
      session: data.session ?? null,
      isActive: data && data.isActive ? data.isActive : false,
      locale: JSON.stringify(locale) ?? '',
      userClaim: data.userClaim ?? {}
    };

    if (data.branches) {
      user.locations = [];
      data.branches.forEach((branch: any) => {
        const location: Location = {
          id: branch.branchId ?? '',
          name: branch.branch.name ?? "",
          shortName: branch.branch.shortName ?? "",
          role: {
            id: branch.roleId ?? '',
            name: branch.role.role ?? "",
            type: branch.role.type ?? ""
          }
        };
        user.locations.push(location);
      });
    }
    return user;
  }

  private setErrorUserData(errorMsg: string) {
    const user: User = {
      email: "",
      emailVerifiedAt: "",
      title: "",
      userName: "",
      firstName: "",
      id: '',
      lastName: "",
      middleName: "",
      locations: [],
      organization: {
        id: '',
        name: "",
      },
      isActive: false,
      phone: "",
      profileUrl: "",
      errorMsg: errorMsg,
      userClaim: {}
    };
    return user;
  }

  private getErrorMessage = (err: any) => {
    let error = "";
    switch (err.errorType) {
      case APIResponseError.NOT_FOUND:
        //error = Messages.AUTH_FAILED;
        error = Messages.CHECK_EMAIL_FAILED;
        break;

      case APIResponseError.USER_FORBIDDEN:
        error = err.message
        break;

      case APIResponseError.AUTH_FAILURE:
        error = Messages.AUTH_FAILED;
        break;
    }
    return error;
  };

  // logout
  async logout(): Promise<User | null> {
    let response:any
    console.log("logout called too")
      response = await this.post(`user/logout`);
    if (response && response.data) {
      if (response.data?.error) {
        console.log("Error occurred while logging out!!");
      }
      else {
        console.log("logged out successfully");
        window.localStorage.clear();
      }
    }
    return await Promise.resolve(null);
  }
  async ssoLogout(): Promise<User | null> {
    let response:any
    response = await this.post(`user/ssoLogout`);
    if (response && response.data) {
      if (response.data?.error) {
        console.log("Error occurred while logging out!!");
      }
      else {
        console.log("logged out successfully");
        logoutAppUser()
        window.localStorage.clear();
        window.location.href = response.data.result;
      }
    }
    return await Promise.resolve(null);
  }
  async ssoLogoutTrigger(): Promise<User | null> {
    let response:any
      response = await this.post(`settings/logout`);
    if (response && response.data) {
      if (response.data?.error) {
        console.log("Error occurred while logging out!!");
      }
      else {
        console.log("logged out successfully");
      }
    }
    return await Promise.resolve(null);
  }
}
