/* eslint-disable array-callback-return */
import { PermissionTypes } from "../web/constants/constants";

// get unique permission with editable and viewable
export const getRolesUniquePermissions = async (permissions:any[]): Promise<any[]> =>{
    let filteredPermissionsCopy:any[]= [];
    permissions && permissions.map((element:any) => {
        const elementsIndex = filteredPermissionsCopy.findIndex(
          (item:any) => item?.permission === element?.permission
        );
  
        if (elementsIndex >= 0) {
          if (element.permissionType === PermissionTypes.VIEW || element.permissionType === PermissionTypes.INDEX) {
            filteredPermissionsCopy[elementsIndex] = {
              ...filteredPermissionsCopy[elementsIndex],
              view: true
            };
          } else if(element.permissionType === PermissionTypes.CREATE || element.permissionType === PermissionTypes.EDIT || element.permissionType === PermissionTypes.DELETE){
            filteredPermissionsCopy[elementsIndex] = {
              ...filteredPermissionsCopy[elementsIndex],
              create: true
            };
          }
        } else {
          let modifiedElement:any;
          if (element.permissionType === PermissionTypes.VIEW || element.permissionType === PermissionTypes.INDEX) {
            modifiedElement = {...element, view:true}
            filteredPermissionsCopy.push(modifiedElement);
          } else if(element.permissionType === PermissionTypes.CREATE || element.permissionType === PermissionTypes.EDIT || element.permissionType === PermissionTypes.DELETE) {
            modifiedElement = {...element, create:true}
            filteredPermissionsCopy.push(modifiedElement);
          }
        }
      });
      return filteredPermissionsCopy;
}

// get permission id's array
// export const getIdsForPermissions = async(permissions:any[]): Promise<any[]> =>{
//   var result = permissions.map(function(a) {return a.permissionId;});
//   return result;
// }

// filter permissions on the basis of name and type
export const getFilteredPermissions = (type: string, permissionName: string, permissions: any[]) => {
  return permissions && permissions.filter((element:any) => {
      if (type === PermissionTypes.VIEW) {
          return element.permission === permissionName && (element.permissionType === PermissionTypes.INDEX || element.permissionType === PermissionTypes.VIEW);
      } else {
          return element.permission === permissionName && (element.permissionType === PermissionTypes.CREATE || element.permissionType === PermissionTypes.EDIT || element.permissionType === PermissionTypes.DELETE);
      }
  })
}

// after transformation get permission id's array
export const getPermissionIds = async(selectedPermissions:any[], allPermissions:any[]): Promise<any[]> =>{
  let permissionIdsArray = [];
  selectedPermissions.map(function(x: any) {
    if(x?.isEdit){
      let permissionList = getFilteredPermissions(PermissionTypes.EDIT, x?.permission, allPermissions);
      permissionList.forEach((item:any) => {
        permissionIdsArray.push(item.permissionId);
      });
    }
    if(x?.isView){
      let permissionList = getFilteredPermissions(PermissionTypes.VIEW, x?.permission, allPermissions);
      permissionList.forEach((item:any) => {
        permissionIdsArray.push(item.permissionId);
      });
    }
  })
  return permissionIdsArray;
}

// filter permission to show unique permissions
export const getUniquePermissions = async(permissions:any[]): Promise<any[]> => {
  const uniquePerm: any = permissions.filter((item, index, self) =>
      index === self.findIndex((t) => (
          t.permission === item.permission
      ))
  )
  return uniquePerm;
}

// concatinating selected permissions and unique original permissions
export const getUniquePermissionForEditing = async(origPerm: any[], rolePerm: any[]): Promise<any[]> =>{
  const origUniquePermission = await getUniquePermissions(origPerm);
  const diffResult = origUniquePermission.filter(
    ({ permission: name1 }) => !rolePerm.some(({ permission: name2 }) => name2 === name1)
  );
  var result = rolePerm.concat(diffResult);
  return result;
}

export const allowedPermissionValues = async (permission: any[]) => {
  const permissionVal = permission.map((item:any)=>{
    return {name: item.permission, view: item?.view? true: false, edit: item?.create? true: false}
  })
  return permissionVal;
}

export const getPermissionFor = (temp: any[], name: string) => {
  for(var i=0; i<temp?.length; i++){
    if(temp[i]?.name === name){
        return temp[i]?.edit;
    }
  }
}

// get self permissions
export const getSelfPermissions = async(permissions: any[]) => {
  const selfPerm = permissions.filter((item:any) => item?.permissionType.includes('self')).map((item:any)=>item?.permissionId);
  return selfPerm;
}