/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react"
import { useSelector } from 'react-redux'
import { Select } from "antd"
import { downArrowDropdown } from "../../../../images"
import { getStatusTypeEllipse, onlyUnique, sortList } from "../../../../../utility/utils"
import { eventLogListSelector } from "../../../../../redux/reducers/eventLogs/eventLogListReducer"
import { AlertConfigSelector } from "../../../../../redux/reducers/alertConfig/alertConfigReducer"
import './alertDropdown.less'
import { useTranslation } from 'react-i18next';

function AlertSeverity(props: any) {
    const { data, name } = props
    const { eventsInfo } = useSelector(eventLogListSelector)
    const { alertConfigChanges } = useSelector(AlertConfigSelector)
    const [alertDropdownValue, setAlertDropdownValue] = useState<any>()
    const [severityFilter, setSeverityFilter] = useState([])
    const { t } = useTranslation()
    const downArrow = () => {
        return <img src={downArrowDropdown} alt="" />
    }

    useEffect(() => {
        const typeListItems = eventsInfo?.map((data: any) => data.severity.toString().toLowerCase());
        const typeUniqueLocation = typeListItems?.filter(onlyUnique)?.filter((x:any) => x?.toString()?.toLowerCase() !== 'good')
        setSeverityFilter(getFilterList(typeUniqueLocation))
    }, [eventsInfo])

    const getFilterList = (list: any) => {
        const arrLocation = list?.map((x: any) => {
            return {
                text: x,
                value: x,
            }
        })
        const sortedBylabel = arrLocation.sort(sortList('text'));
        return sortedBylabel
    }

    useEffect(() => {
        if(alertConfigChanges[data?.eventId] && alertConfigChanges[data?.eventId][name] !== null && alertConfigChanges[data?.eventId][name] !== undefined){
            setAlertDropdownValue(alertConfigChanges[data?.eventId][name])
        }
        else{
            setAlertDropdownValue(props.initialValue)
        }
    }, [props.initialValue])

    const onChangeType = (value: string) => {
        if (props?.initialValue?.toString().toLowerCase() === value?.toString().toLowerCase()) {
            props.onRevert(data?.eventId, name)
        }
        else {
            props.onChange(data, { [name]: value })
        }

        setAlertDropdownValue(value);
    }
    return (
        <span className="warnicons">
            <span>
                <img src={getStatusTypeEllipse(alertDropdownValue?.toString().toLowerCase())} alt="" />
            </span>
            <div className='alertDropdown'>
                <Select
                    aria-label={alertDropdownValue}
                    className="alertConfigDropdown"
                    defaultValue={"error"}
                    disabled={false}
                    onChange={onChangeType}
                    value={alertDropdownValue}
                    suffixIcon={downArrow}
                    dropdownClassName="dropdown filterBoxDropdown customAlertDropdown"
                >
                    {severityFilter?.map((option: any, index: any) => {
                        return (
                            <Select.Option className={'options'} key={option.value} value={option.value}>
                                {t(option.text)}
                            </Select.Option>
                        );
                    })}
                </Select>
            </div>
        </span>
    )
}
export default AlertSeverity;