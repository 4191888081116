import { DefaultTheme } from "styled-components";
import { MainTheme } from "./main-theme";

/* We use declaration merging for getting autocompletion for our theme:
 * https://nyxo.app/tips-for-using-typescript-with-styled-components
 */
declare module "styled-components" {
  export interface DefaultTheme {
    colors: {
      background: string;
      palette: {
        black: string;
        darkestGray: string;
        darkGray: string;
        gray: string;
        lightGray: string;
        lightestGray: string;
        white: string;
        red: string;
        lightRed: string;
        rose: string;
      };
      text: {
        primary: string;
        secondary: string;
        tertiary: string;
        quaternary: string;
        penta: string;
        hexa: string;
      };
      menu: {
        background: {
          primary: string;
          secondary: string;
          tertiary: string;
        };
        text: {
          primary: string;
          secondary: string;
          tertiary: string;
        };
      };
      button: {
        primary: string;
        secondary: string;
        destructive: string;
        shadow: {
          primary: string;
          secondary: string;
          destructive: string;
        };
        hover: {
          primary: string;
          secondary: string;
          destructive: string;
        };
        click: {
          primary: string;
          secondary: string;
          destructive: string;
        };
        disabled: {
          primary: string;
          secondary: string;
          destructive: string;
        };
      };
    };
    fonts: string[];
    fontSizes: {
      h1: string;
      h2: string;
      h3: string;
      h4: string;
      h5: string;
      body: string;
      smallBody: string;
      footnote: string;
      caption: string;
    };
    fontWeights: {
      normal: string;
      regular: string;
      light: string;
    };
  }
}

export function GetProjectTheme(): DefaultTheme {
  return MainTheme();
}
