import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,

} from "../../../utility/stateUtil";
import { fetchNotifications,fetchTransformedNotifications,postEmail,postReadNotification, postSms } from "../../actions/notifications/notificationsAction";
import { NotificationState } from "../../states/notifications/notificationState";
import { RootState } from "../../store/rootReducer";

const initialState: NotificationState = {
  readNotification:null,
  notifications:[],
  formState: resetState(),
  notificationsFormState:resetState(),
  postSmsFormState:resetState(),
  postEmailFormState:resetState(),
};

export const NotificationSlice = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      return state;
    },
    clearNotificationsFormState: (state) => {
      state.notificationsFormState = resetState();
      return state;
    },
    clearPostSmsFormState: (state) => {
      state.postSmsFormState = resetState();
      return state;
    },
    clearPostEmailFormState: (state) => {
      state.postEmailFormState = resetState();
      return state;
    },
  },
  extraReducers: {
    // Action - fetchAlertByOrgId
    [fetchNotifications.fulfilled.toString()]: (state, { payload }) => {
      state.notifications = payload?.data;
      state.formState = fulfilledState();
      return state;
    },
    [fetchNotifications.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [fetchNotifications.pending.toString()]: (state) => {
      state.formState = pendingState();
    },

    // Action - notification transformation
    [fetchTransformedNotifications.fulfilled.toString()]: (state, { payload }) => {
      state.notifications = payload?.data;
      state.notificationsFormState = fulfilledState();
      return state;
    },
    [fetchTransformedNotifications.rejected.toString()]: (state, { payload }) => {
      state.notificationsFormState = rejectedState(payload?.message);
    },
    [fetchTransformedNotifications.pending.toString()]: (state) => {
      state.notificationsFormState = pendingState();
    },

   // Action - postReadNotification
    [postReadNotification.fulfilled.toString()]: (state, { payload }) => {
      return {...state,
      readNotification:{...state.readNotification, userId: payload.userId, organisationId: payload.organisationId, readNotificationIds: payload.readNotificationIds}, formState: fulfilledState()
    }},
    [postReadNotification.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.errorMsg);
      return state;
    },
    [postReadNotification.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [postSms.fulfilled.toString()]: (state, { payload }) => {
      state.postSmsFormState = fulfilledState()
      return state
    },
    [postSms.rejected.toString()]: (state, { payload }) => {
      state.postSmsFormState = rejectedState(payload?.message)
    },
    [postSms.pending.toString()]: (state) => {
      state.postSmsFormState = pendingState()
    },
    [postEmail.fulfilled.toString()]: (state, { payload }) => {
      state.postEmailFormState = fulfilledState()
      return state
    },
    [postEmail.rejected.toString()]: (state, { payload }) => {
      state.postEmailFormState = rejectedState(payload?.message)
    },
    [postEmail.pending.toString()]: (state) => {
      state.postEmailFormState = pendingState()
    },
  },
});

export const { clearState,clearNotificationsFormState ,clearPostSmsFormState ,clearPostEmailFormState } = NotificationSlice.actions;
export const NotificationSelector = (state: RootState) => state.notifications;
export default NotificationSlice.reducer;
