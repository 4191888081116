/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col,Tabs, Tooltip } from "antd"
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { fetchGeneralSetting } from "../../../../../redux/actions/organisation/fetchOrganisationDetails";
import {
  organisationDetailsSelector, clearState, clearOrganisation
} from "../../../../../redux/reducers/organisation/organisationDetailsReducer";
import { SubHead } from "../../organisation/details/oganisationDetails.styles";
import BasicInfo from "../../organisation/details/basicInfo/index";
import Subscriptions from "./subscription/subscription";
import { cameFromOrganisation, urlModification } from "../../../../../utility/appUtil";
import { appSelector } from "../../../../../redux/reducers/app/appReducers";
import {Button} from "../../../../../bit_components/common/button";
import { RolesHeaderRight } from "../../roles/rolesList/rolesHeader/rolesHeader.styles";
import { onPage, sidebarNavigate } from "../../../../../redux/actions/app/appAction";
import { SideBarItems } from "../../../../constants/sideBarConstants";
import { Pages, jsonData } from "../../../../constants/constants";
import './subscription/subscriptionHeader.less'
import Loader from "../../../stateless/common/spinner";
import { AppRoutes, UserRole } from "../../../../constants/enums";
import { Redirect } from "react-router-dom"
import { downButtonWhite } from '../../../../images'
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";

export const Settings = () => {
  const { t } = useTranslation()
  const [index, setIndex] = useState<string>("1");
  const { appUser} = useSelector(userStateSelector);
  const dispatch = useDispatch();
  const { formState, organisation } = useSelector(organisationDetailsSelector);
  const { selectedOrganisation } = useSelector(appSelector);

  const changeTab = (activeKey: string) => {
    setIndex(activeKey);
  };

  const { TabPane } = Tabs;
  const history = useHistory();

  const getOrgId = () => {
    let orgId = appUser?.organization.id as string;
      if(cameFromOrganisation()) {
        orgId = selectedOrganisation?.id;
      }      
      return orgId;
}

  const organisationData = {
    result:selectedOrganisation
  }

  useEffect(() => {
    dispatch(onPage({onComponent : Pages.SETTINGS}));
    dispatch(sidebarNavigate(SideBarItems.GENERAL));
  }, []);

  useEffect(() => {
   dispatch(fetchGeneralSetting(getOrgId()))
   return () => {
    dispatch(clearState());
    dispatch(clearOrganisation());
  };
  }, []);

  var administrationClaim:any = secureLocalStorage.getItem('administrationUrl')
  administrationClaim = secureLocalStorage.getItem('LCRole') === 'User' ? null : administrationClaim
  const orgLink = () => {
    window.open(
      `${administrationClaim}/Organization`, "_blank");
  }

  return (
    <>
    {appUser.locations[0].role.name === UserRole.LIBRARY_FRONT_DESK ?

      <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} />
      :
    <div className="generalSettingsDiv">
      <div className="pl-vw orgHeader">
        <div className="orgDetailContainer genSettingHead">
          <Row>
                <Col span={14} className="generalSettingWrapper">
                  <SubHead className="generalSettingCover">{t(jsonData.GeneralSettings)}</SubHead>
                  {
                    administrationClaim?.length > 5 ?
                    <div aria-label={""} role={""} onKeyPress={orgLink}>
                      <Button tabIndex={0} ariaLabel={t(jsonData.manageOrganization)}
                        type="smallBlack fs-12" onClick={orgLink}>
                        <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.ManageOrganisation)}>
                          <span className="generalSettingBtn">{t(jsonData.ManageOrganisation)}</span>
                        </Tooltip>
                        <img className="manageUserImg" src={downButtonWhite} alt="downbuttonImg" />
                      </Button>
                      </div>
                      :
                      null
                  }
                </Col>
            <Col span={10}>
              {
                index !== '2'?null
                :
                <RolesHeaderRight>
                <Button type="smallBlack" disabled htmlType="submit">
                  {t(jsonData.Saved)}
                </Button>
              </RolesHeaderRight>
              }
        </Col>
          </Row>
        </div>
      </div>
      <Row>
        <Col span={24}>
          <Tabs className="orgTabContainer paddLeft" activeKey={index} onChange={changeTab}>
            <TabPane tab={t(jsonData.BasicInfo)} key="1" className="genBasicInfo">
              <Loader loading={formState?.loading}>
                <BasicInfo id={getOrgId()} organisation={organisation} />
              </Loader>
            </TabPane>
            {/* <TabPane tab="Subscription" key="2" className="genSubscription">
            <Loader loading={formState?.loading}>
              <Subscriptions id={getOrgId()} organisation={organisationData} />
            </Loader>
            </TabPane> */}
          </Tabs>
        </Col>
      </Row>
    </div>
    }
    </>
  );
};
