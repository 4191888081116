import { createAsyncThunk } from "@reduxjs/toolkit";
import DeviceService from "../../../web/services/device/deviceService";

const deviceService = new DeviceService();

// fetch device list by organization id
export const fetchDeviceListByOrgId = createAsyncThunk(
    "device/fetchDeviceListByOrgId",
    async ({orgId, locations, deviceTypes,name,page , searchParam }:{orgId: string, locations?: any, deviceTypes?:any, name?:any, page?:any, searchParam?: string }, thunkAPI) => {
      try {
        const data = await deviceService.fetchDeviceList(orgId,locations,deviceTypes,name,page, searchParam);
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const fetchPartNameList = createAsyncThunk(
    "device/fetchPartNameList",
    async (orgId: string , thunkAPI) => {
      try {
        const data = await deviceService.fetchPartNameList(orgId);
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

//   export const fetchDeviceListFilterByOrgId = createAsyncThunk(
//   "device/fetchDeviceListFilterByOrgId",
//   async ({orgId, locations,page}:{orgId: string, locations?: any, page?:any}, thunkAPI) => {
//     try {
//       const data = await deviceService.fetchDeviceListFilter(orgId,locations);
//       if (data) {
//         return data;
//       } else {
//         return thunkAPI.rejectWithValue(data);
//       }
//     } catch (e: any) {
//       return thunkAPI.rejectWithValue(e.response.data);
//     }
//   }
// );

  // fetch device filter by organization id
export const fetchDeviceFilterByOrgId = createAsyncThunk(
  "device/fetchDeviceFilterByOrgId",
  async ({orgId,locations}:{orgId: string,locations?:any }, thunkAPI) => {
    try {
      const data = await deviceService.fetchDeviceFilter(orgId,locations);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchDevicesAtHierarchy = createAsyncThunk(
    "device/fetchDevicesAtHierarchy",
    async (location:string, thunkAPI) => {
      try {
        const data = await deviceService.fetchDevicesAtHierarchy(location);
        if (data) {
          return data;
        } else {
          return 0;
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const fetchDevicesHealthByOrgId = createAsyncThunk(
    "device/fetchDevicesHealthByOrgId",
    async (_args : any, thunkAPI) => {
      try {
        const data = await deviceService.fetchDevicesHealthByOrgId(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const fetchDeviceTypeList = createAsyncThunk(
    "device/fetchDeviceTypeList",
    async (_args:any, thunkAPI) => {
      try {
        const data = await deviceService.fetchDeviceTypeList(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const fetchDeviceStates = createAsyncThunk(
    "device/fetchDeviceStates",
    async (_args, thunkAPI) => {
      try {
        const data = await deviceService.fetchDeviceStates()
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const fetchDeviceComponents = createAsyncThunk(
    "device/fetchDeviceComponents",
    async (_args:any, thunkAPI) => {
      try {
        const data = await deviceService.fetchDeviceComponents(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const fetchDeviceInfo = createAsyncThunk(
    "device/fetchDeviceInfo",
    async (_args:any, thunkAPI) => {
      try {
        const data = await deviceService.fetchDeviceInfo(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const updateDeviceInfo = createAsyncThunk(
    "device/updateDeviceInfo",
    async (_args:any, thunkAPI) => {
      try {
        const data = await deviceService.updateDeviceInfo(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const addDevice = createAsyncThunk(
    "device/addDevice",
    async (_args:any, thunkAPI) => {
      try {
        const data = await deviceService.addDevice(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const deleteDevice = createAsyncThunk(
    "device/deleteDevice",
    async (id:string, thunkAPI) => {
      try {
        const data = await deviceService.deleteDevice(id)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const fetchDeviceConfigViews = createAsyncThunk(
    "device/fetchDeviceConfigViews",
    async (_args:any, thunkAPI) => {
      try {
        const data = await deviceService.fetchDeviceConfigViews(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const fetchConfigNodes = createAsyncThunk(
    "device/fetchConfigNodes",
    async (_args:any, thunkAPI) => {
      try {
        const data = await deviceService.fetchConfigNodes(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const fetchNodeContent = createAsyncThunk(
    "device/fetchNodeContent",
    async (_args:any, thunkAPI) => {
      try {
        const data = await deviceService.fetchNodeContent(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const getConfigState = createAsyncThunk(
    "device/getConfigState",
    async (_args:any, thunkAPI) => {
      try {
        const data = await deviceService.getConfigState(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const getDevicesStatus = createAsyncThunk(
    "device/getDevicesStatus",
    async (_args:any, thunkAPI) => {
      try {
        const data = await deviceService.getDevicesStatus(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const getConfiguration = createAsyncThunk(
    "device/getConfiguration",
    async (_args:any, thunkAPI) => {
      try {
        const data = await deviceService.getConfiguration(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const lockDeviceState = createAsyncThunk(
    "device/lockDeviceState",
    async (_args:any, thunkAPI) => {
      try {
        const data = await deviceService.lockDeviceState(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
  export const unLockDeviceState = createAsyncThunk(
    "device/unLockDeviceState",
    async (_args:any, thunkAPI) => {
      try {
        const data = await deviceService.unLockDeviceState(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const getConfigDevices = createAsyncThunk(
    "device/getConfigDevices",
    async (_args:any, thunkAPI) => {
      try {
        const data = await deviceService.getConfigDevices(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const storeConfiguration = createAsyncThunk(
    "device/storeConfiguration",
    async (_args:any, thunkAPI) => {
      try {
        const data = await deviceService.storeConfiguration(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const copyConfiguration = createAsyncThunk(
    "device/copyConfiguration",
    async (_args:any, thunkAPI) => {
      try {
        const data = await deviceService.copyConfiguration(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );