import React, { useState, useEffect, useRef } from "react";
import { Col, Card, Input, Tooltip } from 'antd';
import {Button} from "../../../../../bit_components/common/button";
import { PlusWhiteIcon } from "../../../../images";
import { useDispatch } from "react-redux";
import { addLocation } from "../../../../../redux/actions/organisation/fetchDepth";
import { KeyboardKeyCharCode, jsonData } from "../../../../constants/constants";
import { NoMoreText } from './OrganisationLocations.styles'
import { useTranslation } from 'react-i18next';

export const NoMoreLocations = (props: any) => {
    const { text1, text2, orgId, index, parentId } = props;
    const dispatch = useDispatch();
    const [showInput, setShowInput] = useState<any>(true);
    const [newLocation, setNewLocation] = useState<string>('');
    const inputRef = useRef(null);
    const { t } = useTranslation()

    useEffect(() => {
        if (!showInput) {
          inputRef.current.focus();
        }
      }, [showInput]);

    const OnAddClick: any = () => {
        if(showInput){
            setShowInput(false);
            }
        else{
            if(newLocation !== ''){
            dispatch(addLocation({ organisationID: orgId,
                name: newLocation,
                type: index === 0 ? 'LOC' : 'SLOC',
                parentID: parentId 
               }));
            setShowInput(true)
            }
            setShowInput(true)
        }
    };

    const handleChange = ( e: any) => {
        setNewLocation(e.target.value)
    }

    // on enter key press
    const handleKeypress = e => {
        //it triggers by pressing the enter key
          if (e.charCode === KeyboardKeyCharCode.ENTER_KEY) {
            OnAddClick();
          }
        };

    return (
        <Col className="card-right-border">
            <Card bordered={false}>
                <NoMoreText>
                    {text1}
                </NoMoreText>
                <div className= "add-form">
                <Input className="addLocation" hidden={showInput} ref={inputRef} name="location" placeholder={index? t(jsonData.SublocationName): t(jsonData.LocationName)} value={newLocation} onChange={handleChange} onBlur={OnAddClick}
                onKeyPress={handleKeypress}
                />
                <div className="add-loc-sloc-button-container">
                    <Button ariaLabel={t(jsonData.addNewSubLocation)} type="add-loc-sloc-button addLocBtn" htmlType="submit" onClick={OnAddClick}>
                        <img src={PlusWhiteIcon} className="noMoreLocation" alt="" />
                        <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={text2}>
                    <span className="addLocText">{text2}</span>
                </Tooltip>
                    </Button>
                </div>
                </div>        
            </Card>
        </Col>
    );
};