/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Row, Col, Button,Tooltip, Input } from "antd";
import { SearchImg,searchRed } from "../../../images";
import {Dropdown} from "../../../../bit_components/common/dropdown";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedCountry,
  setSelectedRegion,
  setSearchState,
  organisationListSelector,
} from "../../../../redux/reducers/organisation/organisationListReducer";
import * as cr from "react-country-region-selector";
import "./listHeader.less";
import { getUserRoles } from "../../../../utility/appUtil";
import { AppRoutes, UserRole } from "../../../constants/enums";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import {Button as CustomButton} from "../../../../bit_components/common/button"
import { downButtonWhite } from '../../../images'
import { useTranslation } from 'react-i18next';
import { jsonData } from "../../../constants/constants";
import secureLocalStorage from "react-secure-storage";

const ListHeader = (props: any) => {
  const { t } = useTranslation()
  const {appUser} = useSelector(userStateSelector);
  const dispatch = useDispatch();
  const {
    search,
    region,
    country,
  } = useSelector(organisationListSelector);

  const [searchBox, setSearchBox] = useState(false);

  const getRegionsByCountry = (country: string) => {
    return cr.CountryRegionData.filter((item) => item[0] === country);
  };

  const regionList = (country: string) => {
    const countryRegion = getRegionsByCountry(country);
    let regions: any = [];
    if (countryRegion.length) {
      regions = countryRegion[0][2].split("|").map((region) => {
        return { text: region.split("~")[0], value: region.split("~")[0] };
      });
    }
    regions.unshift({ text: t(jsonData.AllRegions), value: "All Regions" });
    return regions;
  };

  const [_regions, setRegions] = useState(regionList(country));

  const [_selectedCountry, _setSelectedCountry] = useState(
    country || "All Countries"
  );
  const [_selectedRegion, _setSelectedRegion] = useState(
    region || "All Regions"
  );

  const toggleSearchBox = () => {
    setSearchBox(!searchBox);
  };

  const onRegionChangeHandler = (e: any) => {
    _setSelectedRegion(e);
    dispatch(setSelectedRegion({ id: 0, name: e, shortCode: e }));
  };

  const onCountryChangeHandler = (e: any) => {
    if (e === "All Countries") {
      dispatch(setSelectedCountry({ id: 0, name: "", shortCode: "" }));
    } else {
      dispatch(setSelectedCountry({ id: 0, name: e, shortCode: e }));
    }

    const item = getRegionsByCountry(e);
    let regions: any = [];
    if (item.length) {
      regions = item[0][2].split("|").map((region) => {
        return { text: region.split("~")[0], value: region.split("~")[0] };
      });
    }
    regions.unshift({ text: t(jsonData.AllRegions), value: "All Regions" });
    setRegions(regions);
    _setSelectedCountry(e);
    _setSelectedRegion("All Regions");
    dispatch(setSelectedRegion({ id: 0, name: "", shortCode: "" }));
  };

  const onSearchChangeHander = (e: any) => {
    dispatch(setSearchState(e.target.value));
  };

  const _countries = cr.CountryRegionData.map((country: any) => {
    return { text: country[0], value: country[0] };
  });
  _countries.unshift({ text: t(jsonData.AllCountries), value: "All Countries" });

  const onBlurCheckSearch = (e:any) => {
    if(e.target.value === ''){
      toggleSearchBox()
    }
  }

  const showSearch = () => {
    if (!!searchBox || !!search) {
      return (
        <Input
          type="text"
          value={search}
          onChange={onSearchChangeHander}
          placeholder={t(jsonData.Search)}
          className="orgTableHeaderSearch"
          bordered={false}
          autoFocus onBlur={onBlurCheckSearch}
        />
      );
    }
  };

  const isAddOrgAllowed = () => {
    let role = [];
        if(appUser) role = getUserRoles(appUser);
        if(role.includes(UserRole.BIBLIOTHECA_USER)) return false;
        return true;
  }

  var administrationClaim:any = secureLocalStorage.getItem('administrationUrl')
  const orgLink = () => {
    window.open(
      `${administrationClaim}/Organization`, "_blank");
  }
  
  const searchAccessbility = (e) => {
    if(e.code == "Enter") {
      setSearchBox(true);
    } else if (e.code == "Escape") {
      setSearchBox(false); 
    }
  }

  return (
    <Row className="orgListHeader orgListTableHeader">
      <Col
        className="itemCenter"        
      >
        <span className="organisationHed">{t(jsonData.Organisations)}</span>
      </Col>
    {  appUser.locations[0].role.name === UserRole.BIBLIOTHECA_USER ? null:
      <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.ManageOrganisation)}>
      <Col className="itemCenter" onKeyPress={orgLink}>
        <CustomButton tabIndex={0} ariaLabel={t(jsonData.ManageOrganisation)} role={"button"}
          type="smallBlack fs-12" onClick={orgLink}>
          {t(jsonData.ManageOrganisation)}
          <img className="manageUserImg" src={downButtonWhite} alt="downbuttonImg" />
        </CustomButton>
      </Col>
        </Tooltip>
    }
      {/* {isAddOrgAllowed() ? <Col className="orgAddBtn">
        <Link to={AppRoutes.ADD_ORGANISATION}>
          <CustomButton
            type="addOrgBtn"
          >
              + Add an Organisation
          </CustomButton>
        </Link>
      </Col> : null} */}
      <Col md={24} lg={24} xl={16} className="OrganisationFilter">
          <Row className="userFilter">
            <span className="userFilterTab bibFilterPos">
              <Dropdown className="filterDropdown CountrieswidthFix userLocationDropdown"
                defaultValue={t(jsonData.AllCountries)}
                value={_selectedCountry}
                optionValue={_countries}
                onChange={onCountryChangeHandler}
                bordered={false}
                optionClass="filterBox"
                tabIndex={0}
                ariaLabel={t(_selectedCountry)}
              />
            </span>
            
            <span className="userFilterTab bibFilterPos">
              <Dropdown className="filterDropdown userLocationDropdown"
                defaultValue={t(jsonData.AllRegions)}
                optionValue={_regions}
                value={_selectedRegion}
                onChange={onRegionChangeHandler}
                bordered={false}
                optionClass="filterBox"
                tabIndex={0}
                ariaLabel={t(jsonData.AllRegions)}
              />
            </span>
          
          <Col className="orgSearchBtn orgSearchBox" tabIndex={0} aria-label={t(jsonData.searchMsg)} role={""} onKeyDown={searchAccessbility} >
              <Button
                className="serOrgBtn"
                onClick={toggleSearchBox}
                shape="circle"
                type="link"
               >
                  <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.search)}>
                  <img src={SearchImg} 
                    alt=""
                    onMouseOver={e => (e.currentTarget.src = searchRed)}
                    onMouseOut={e => (e.currentTarget.src = SearchImg)}
                     />
                  </Tooltip>
                </Button>
            {showSearch()}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ListHeader;
