import React from "react";
import styled from "styled-components";

export const ComponantWrapperStyle = styled((props: any) => {
    const {children, ...rest } = props
    return <div {...rest}> {children} </div>     
})`
overflow-x : hidden;
${({height}) => 
    `
    height: ${height}px;
    
    `
}


`