import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
} from "../../../utility/stateUtil";
import { hierarchySelected, fetchTransformedLocationList } from "../../actions/role/locationAction";
import { LocationsState } from "../../states/location/locationListState";
import { RootState } from "../../store/rootReducer";

const initialState: LocationsState = {
  locations: [],
  selectedIndexArray: [0, 0, 0 ,0, 0, 0, 0, 0, 0,0],
  formState: resetState(),
};

export const LocationsSlice = createSlice({
  name: "locations",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.locations = [];
      state.selectedIndexArray = [0, 0, 0 ,0, 0, 0, 0, 0, 0,0];
      state.formState = resetState();
      return state;
    }
  },
  extraReducers: {
    // Action - fetchLocationList
    // [fetchLocationList.fulfilled.toString()]: (state, { payload }) => {
    //   state.locations = payload;
    //   state.formState = fulfilledState();
    //   return state;
    // },
    // [fetchLocationList.rejected.toString()]: (state, { payload }) => {
    //   state.formState = rejectedState(payload?.message);
    // },
    // [fetchLocationList.pending.toString()]: (state) => {
    //   state.formState = pendingState();
    // },

    // Action - fetchTransformedLocationList
    [fetchTransformedLocationList.fulfilled.toString()]: (state, { payload }) => {
      state.locations = payload;
      state.formState = fulfilledState();
      return state;
    },
    [fetchTransformedLocationList.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [fetchTransformedLocationList.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
    [hierarchySelected.fulfilled.toString()]: (state, { payload }) => {
      state.selectedIndexArray = payload;
      return state;
    }
  },
});

export const { clearState } = LocationsSlice.actions;
export const locationListSelector = (state: RootState) => state.locationList;
export const locationListFormStateSelector = (state: RootState) =>state.locationList.formState;
export default LocationsSlice.reducer;
