/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { authenticate } from "../../../../../../redux/actions/user/authAction";
import {
  clearState,
  userStateSelector,
} from "../../../../../../redux/reducers/user/userReducer";
import { urlModification } from "../../../../../../utility/appUtil";
import { UserAuthInput } from "../../../../../models/user/userAuthInput";
import { AppRoutes } from "../../../../../constants/enums";
import { AuthenticatePwdForm } from "../../../../stateless/user/auth/authenticatePwdForm";

export const Login = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formState, authInput, appUser } = useSelector(userStateSelector);
  const layout = {
    labelCol: {
      span: 24,
      // offset: 2,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const onGoBackCallback = () => {
    history.goBack();
  };
  

  useEffect(() => {
      dispatch(clearState());
  }, []);

  useEffect(() => {
    if (formState.isError) {
      //toast.error(errorMessage);
    } else if (formState.isSuccess) {
      dispatch(clearState());
    }
  }, [formState.isError, formState.isSuccess]);


  const onForgotPwdCallback = () => {
    history.push(urlModification(AppRoutes.FORGOT_PASSWORD));
  };

  const onAuthenticatePwdCallback = (userAuthInput: UserAuthInput) => {
    dispatch(authenticate(userAuthInput));
  };

  return (
    <AuthenticatePwdForm formLayout={layout} formState={formState} appUser={appUser} authenticatePwdCallback={onAuthenticatePwdCallback} forgotPwdCallback={onForgotPwdCallback} goBackCallback={onGoBackCallback} />
  );
};
