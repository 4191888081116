/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchTransformedLocations } from "../../../../../redux/actions/organisation/fetchDepth";
import { useLocation } from "react-router";
import {
  organisationDepthSelector,
  clearState,
  addClearState,
  deleteClearState,
} from "../../../../../redux/reducers/organisation/organisationDepthReducer";
import { useSelector } from "react-redux";
import { LocationsGrid } from "./locationGrid";
import "./index.less";
import { locationListSelector } from "../../../../../redux/reducers/location/locationListReducer";
import { hierarchySelected } from "../../../../../redux/actions/role/locationAction";
import "./../../../stateless/common/customScrollBar/customScrollStyle.less";
import {
  cameFromOrganisation,
  getUserLocationIds,
  getUserRoles,
  urlModification,
} from "../../../../../utility/appUtil";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { appSelector } from "../../../../../redux/reducers/app/appReducers";
import {
  onPage,
  settingsEntered,
  sidebarNavigate,
} from "../../../../../redux/actions/app/appAction";
import { AppRoutes, Permissions, UserRole } from "../../../../constants/enums";
import { SideBarItems } from "../../../../constants/sideBarConstants";
import { getPermissionFor } from "../../../../../utility/roleUtils";
import { Pages, jsonData } from "../../../../constants/constants";
import { AppError } from "../../../stateless/common/appError";
import Loader from "../../../stateless/common/spinner";
import { LocationTitle } from "./OrganisationLocations.styles";
import { CompWrapper } from "../../../stateless/common/compWrapper/compWrapper";
import { clearDeviceCount } from "../../../../../redux/reducers/device/deviceReducer";
import { AppService } from "../../../../services/ui/appService";
import { Redirect } from "react-router-dom"
import { useTranslation } from 'react-i18next';

interface LocationRowItem {
  isSelected: boolean;
  hasChildren: boolean;
  rowName: string;
  rowId: string;
  rowTypeCode: string;
  rowParent: object;
}

interface SelectedOrg {
  selectedItemIndex: number; //This shows which index of the hierarchy row list is selected
  selectedItem: any; //This contains the object of the selected Item. May not be required.
  hierarchyRowList: Array<LocationRowItem>; //This contains the list of data objects for each row
  hasList: boolean; //This shows if the Card Object has hierarchy row list or not
  noMoreEntries: boolean; //This is put in the first empty card
  parentId: string|null;
}

export const OrgLocation = (props: any) => {
  const keyArr: Array<SelectedOrg> = [];

  const dispatch = useDispatch();

  const search: any = useLocation().search;
  const params: any = new URLSearchParams(search);
  const _organisationID: any = params.get("organisationID");
  const {
    result,
    addFormState,
    deleteFormState,
    errorMessage,
    formState,
  } = useSelector(organisationDepthSelector);
  const { selectedIndexArray } = useSelector(locationListSelector);
  const { selectedOrganisation, settingsReturnPath } = useSelector(appSelector);
  const { appUser } = useSelector(userStateSelector);
  const { t } = useTranslation()
  
  useEffect(() => {
    // if (cameFromOrganisation()) {
    //   dispatch(
    //     settingsEntered({ path: window.localStorage.getItem('path') ?? AppRoutes.USER_DASHBOARD, isSettingState: true })
    //   );
    // }
    dispatch(fetchTransformedLocations({ orgId: getOrgId() }));
    return () => {
      dispatch(clearState());
      dispatch(clearDeviceCount());
    };
  }, [selectedOrganisation, addFormState.isSuccess, deleteFormState]);

  useEffect(() => {
    if(deleteFormState?.isError){
      AppService.showToast(t(jsonData.SomeErrorOccured));
      dispatch(deleteClearState());
    }
    else if(deleteFormState?.isSuccess){
      AppService.showToast(t(jsonData.LocationDeletedSuccessfully));
      dispatch(deleteClearState());
    }
  } ,[deleteFormState])

  useEffect(() => {
    dispatch(onPage({ onComponent: Pages.SETTINGS }));
    dispatch(sidebarNavigate(SideBarItems.LOCATIONS));
    const defaultArray = selectedIndexArray;
    dispatch(hierarchySelected(defaultArray));
    return () => {
      dispatch(addClearState());
    };
  }, []);

  const getOrgId = () => {
    let orgId = appUser?.organization.id as string;
    if (cameFromOrganisation()) {
      orgId = selectedOrganisation?.id;
    }
    return orgId;
  };
  const isLocationCrudAllowed = () => {
    return getPermissionFor(appUser?.permissions, Permissions.LOCATIONS);
  };

  const getLocationsForRole = () => {
    let role = [];
    if (appUser) role = getUserRoles(appUser);
    if (role.includes(UserRole.LIBRARY_FRONT_DESK)) {
      let modifiedResult = { ...result };
      const locIds = appUser && getUserLocationIds(appUser);
      const child = modifiedResult?.children?.filter((item: any) =>
        locIds?.includes(item.id)
      );
      modifiedResult.children = child;
      return modifiedResult;
    } else {
      return result;
    }
  };

  const organisationID: string = getOrgId();

  let dataTree: any = getLocationsForRole();

  //find deepest child no.
  const childCount=(children)=> {
    return children?.reduce((depth, child) => {
      return Math.max(depth, 1 + childCount(child?.children)); 
    }, 0); 
  }
  var minCount = childCount([dataTree])
  
  const MAXCOLUMNS = 10;
  // eslint-disable-next-line
  const MINCOLUMNS = minCount !== undefined?minCount:!Number.isNaN(minCount)?5:0;

  let columnCount = 0;
  const locationIndexArray: any = selectedIndexArray;
  const init = () => {

    let noMoreEntriesApplied = false;
    for (let i = 0; i < MAXCOLUMNS; i++) {
      const itemObj: SelectedOrg = {
        selectedItemIndex: -1,
        hierarchyRowList: [],
        selectedItem: "",
        hasList: false,
        noMoreEntries: false,
        parentId: '',
      };

      if (!dataTree || dataTree?.children?.length === 0) {
        if (i < MINCOLUMNS) {
          if (!noMoreEntriesApplied) {
            itemObj.parentId = dataTree?.id; //this will be organisation child

            itemObj.noMoreEntries = true;
            noMoreEntriesApplied = true;
          }
          keyArr.push(itemObj);
        } else break;
      } else {
        itemObj.selectedItemIndex = locationIndexArray[i];

        for (let j = 0; j < dataTree?.children?.length; j++) {
          itemObj.parentId = dataTree?.id;
          itemObj.hasList = true;
          let rowSelected: boolean = false;
          if (j === itemObj.selectedItemIndex) rowSelected = true;
          let rowHasChildren = false;
          if (dataTree?.children[j]?.children?.length > 0)
            rowHasChildren = true;
          let rowNameString = dataTree?.children[j]?.shortName;
          let childrenRowId = dataTree?.children[j]?.id;
          let childrenType = dataTree?.children[j]?.hierarchyTypeCode;
          let childrenParent = dataTree?.children[j]?.parent;
          const rowItemObj: LocationRowItem = {
            isSelected: rowSelected,
            hasChildren: rowHasChildren,
            rowName: rowNameString,
            rowId: childrenRowId,
            rowTypeCode: childrenType,
            rowParent : childrenParent,
          };
          itemObj.hierarchyRowList.push(rowItemObj);
        }

        itemObj.selectedItem = dataTree;
        dataTree = dataTree?.children?.length
          ? dataTree?.children[locationIndexArray[i]]
          : {};
        columnCount++;
        keyArr.push(itemObj);
      }
    }
  };

  init();

  let grid: any = [];
  for (let i = 0; i < MAXCOLUMNS; i++) {
    if (i < columnCount) {
      grid.push(
        <LocationsGrid
          index={i}
          item={keyArr[i]}
          orgId={organisationID}
          isEditingAllowed={isLocationCrudAllowed()}
        />
      );
    } else {
      if (i < MINCOLUMNS) {
        grid.push(
          <LocationsGrid
            orgId={organisationID}
            index={i}
            item={keyArr[i]}
            isEditingAllowed={isLocationCrudAllowed()}
          />
        );
      } else break;
    }
  }


  return (
    <>
    {appUser.locations[0].role.name === UserRole.LIBRARY_FRONT_DESK ?

      <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} />
      :
    <div className="pl-vw locationLoading">
      <LocationTitle>{t(jsonData.Locations)}</LocationTitle>
      <Loader loading={formState.loading}>
        <AppError
          show={addFormState.isError ? true : false}
          errorMsg={errorMessage}
        />
        <CompWrapper
          observeOn="locationLoading"
          otherClasses="ant-row locationGrid locationScrollStyle locationScrollLength"
        >
          {grid}
        </CompWrapper>
      </Loader>
    </div>
  }
  </>
  );
};
