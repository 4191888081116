import { createSlice } from "@reduxjs/toolkit";
import { resetState } from "../../../utility/stateUtil";
import { Pages } from "../../../web/constants/constants";
import { AppRoutes, HeaderType } from "../../../web/constants/enums";
import { AuthService } from "../../../web/services/user/authService";
import { accountSettingsEntered, accountSettingsExit, getSelectedOrg, settingsEntered, settingsExit, sidebarNavigate, warnUnsave, warnConfirm, warnDiscard, moveToItem, onPage } from "../../actions/app/appAction";
import { AppState } from "../../states/app/appState";

import { RootState } from "../../store/rootReducer";
import { logoutAppUser } from "../../../utility/appUtil";
const authSvc = new AuthService();

const initialState: AppState = {
  isSettingState: false,
  isJuntoAccountSettings: false,
  isBibliothecaAccountSettings: false,
  settingsReturnPath: '/',
  juntoAccountSettingsReturnPath: '/',
  bibAccountSettingsReturnPath: '/',
  selectedSidebarMenuItem: '',
  isFromBiblothecaPortal: false,
  selectedOrganisation: {},
  formState: resetState(),
  isWarnUnsaveOpen: false,
  closeWarn: false,
  calledBy: '',
  applyChanges: false,
  itemIndex: '',
  backToJuntoAccountSettings: false,
  backToSetting: false,
  goTo: '',
  isDeviceMenu: false,
  selectedDevice: {},
  showBackButton: false,
  alertSeverity: null,
  alertClicked:false,
  showCustomRange:false,
  deviceType: ''
};

export const AppSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.isSettingState = false;
      state.isJuntoAccountSettings = false;
      state.isBibliothecaAccountSettings = false;
      state.settingsReturnPath = '/';
      state.juntoAccountSettingsReturnPath = '/';
      state.bibAccountSettingsReturnPath = '/';
      state.selectedOrganisation = {};
      state.selectedSidebarMenuItem = '';
      state.isFromBiblothecaPortal = false;
      state.selectedOrganisation = {
        id:'',
        name: '', 
        icon: ''
      };
      state.isWarnUnsaveOpen = false;
      state.closeWarn = false;
      state.calledBy = '';
      state.applyChanges = false;
      state.itemIndex = '';
      state.goTo = '';
      state.isDeviceMenu = false;
      state.selectedDevice = {};
      state.showBackButton = false
      state.showCustomRange=false
      state.alertSeverity = null
      state.alertClicked = false
      return state;
    },
    logout: (state) => {
      // But, as we have taken care of this particular "logout" action
      // in rootReducer, we can use it to CLEAR the complete Redux Store's state
        try {
          authSvc.logout();
        } catch (e: any) {
          return e.data;
        }
    },
    ssoLogoutTrigger:(state)=>{
        // authSvc.ssoLogoutTrigger();
        logoutAppUser()
        window.localStorage.clear();
        window.location.href = process.env.REACT_APP_LOGOUT_URL;
    },
    ssoLogout: (state) => {
      try {
        authSvc.ssoLogout();
      } catch (e: any) {
        return e.data;
      }
    },
    displayDeviceMenu: (state, {payload}) => {
      state.isDeviceMenu = payload
      return state
    },
    storeSelectedDevice: (state, {payload}) => {
      state.selectedDevice = payload
      return state
    },
    goToPrevious: (state, { payload }) => {
      state.showBackButton = payload.showBackButton
      state.showCustomRange = payload.showCustomRange
    },
    setAlertSeverity: (state, {payload}) => {
      state.alertSeverity = payload
    },
    setAlertClicked: (state, {payload}) => {
      state.alertClicked = payload
      return state
    },
    storeDeviceType: (state, {payload}) => {
      state.deviceType = payload
      return state
    },
    clearDeviceType: (state) => {
      state.deviceType = ''
      return state
    }
  },

  extraReducers: {
    [settingsEntered.fulfilled.toString()]: (state, { payload }) => {
      //debugger;
      state.isSettingState = payload.isSettingState;
      state.isJuntoAccountSettings = false;
      state.settingsReturnPath = payload.returnPath;
      //state.juntoAccountSettingsReturnPath = '/';
      state.backToSetting = true;
      state.isDeviceMenu = false
      return state;
    },
    [settingsExit.fulfilled.toString()]: (state, { payload }) => {
      // debugger;
      state.isSettingState = false;
      state.backToSetting = false;
      return state;
    },
    [sidebarNavigate.fulfilled.toString()]: (state, { payload }) => {
      // debugger;
      state.selectedSidebarMenuItem = payload;
      return state;
    },
    [getSelectedOrg.fulfilled.toString()]: (state, { payload }) => {
      if(payload && payload.result) {
        state.isFromBiblothecaPortal = true;
        // let returnValue = setSelectedOrg(payload.result);
        state.selectedOrganisation = payload.result;
        return state;
      } else return state;
    }, 
    [accountSettingsEntered.fulfilled.toString()] : (state, { payload }) => {
        if(payload.headerType === HeaderType.JUNTO_PORTAL) {
          state.isJuntoAccountSettings = payload.isAccountSettings;
          state.juntoAccountSettingsReturnPath = payload.returnPath;
          state.backToJuntoAccountSettings = true;
        }
        else {
          state.isBibliothecaAccountSettings = payload.isAccountSettings;
          state.bibAccountSettingsReturnPath = payload.returnPath;
        } 
        state.isDeviceMenu = false
        
        // state.returnPath = payload.returnPath;
        return state;
    },
    [accountSettingsExit.fulfilled.toString()] : (state, {payload}) => {
      if(payload.headerType === HeaderType.JUNTO_PORTAL) {
        state.isJuntoAccountSettings = payload.isAccountSettings;
        state.juntoAccountSettingsReturnPath = AppRoutes.USER_DASHBOARD;
        state.backToJuntoAccountSettings = false;
      }
      else {
        state.isBibliothecaAccountSettings = payload.isAccountSettings;
        state.bibAccountSettingsReturnPath = AppRoutes.ORGANISATION_OVERVIEW;
      }
        return state;
    },
    [warnUnsave.fulfilled.toString()]: (state, { payload }) => {
      state.isWarnUnsaveOpen = payload.isWarnUnsaveOpen;
      state.closeWarn = payload.closeWarn;
      state.calledBy = payload.calledBy;
      state.applyChanges = payload.applyChanges;
      return state;
    },
    [warnConfirm.fulfilled.toString()]: (state, { payload }) => {
      state.applyChanges = payload.applyChanges;
      state.goTo =payload.goTo;
      return state;
    },
    [warnDiscard.fulfilled.toString()]: (state, { payload }) => {
      state.closeWarn = true;
      return state;
    },
    [moveToItem.fulfilled.toString()]: (state, { payload }) => {
      state.itemIndex = payload.itemIndex;
      return state;
    },
    [onPage.fulfilled.toString()]: (state, { payload }) => {
      //debugger;
      if(payload.onComponent === Pages.ACCOUNT_SETTINGS){
        state.isJuntoAccountSettings = true;
        //state.isSettingState = false;
        state.backToJuntoAccountSettings = true;
      }
      else if(payload.onComponent === Pages.SETTINGS){
        state.isSettingState = true;
        state.isJuntoAccountSettings = false;
        state.backToSetting = true;
      }
      else if(payload.onComponent === Pages.JUNTO_PORTAL){
        state.isSettingState = false;
        state.isJuntoAccountSettings = false;
        state.backToJuntoAccountSettings = false;
        state.backToSetting = false;
        state.isWarnUnsaveOpen = false;
        state.isDeviceMenu = false;
      }
      else if(payload.onComponent === Pages.DEVICES){
        state.isSettingState = false;
        state.isJuntoAccountSettings = false;
        state.backToJuntoAccountSettings = false;
        state.backToSetting = false;
        state.isDeviceMenu = true
      }
      else if(payload.onComponent === Pages.BIBLIOTHECA_ACCOUNT_SETTINGS){
        state.isBibliothecaAccountSettings = true;
      }
      else if(payload.onComponent === Pages.BIBLIOTHECA){
        state.isBibliothecaAccountSettings = false;
      }
      return state;
    },
  },

});

export const { clearState, logout, displayDeviceMenu, storeSelectedDevice, goToPrevious, setAlertSeverity ,setAlertClicked ,ssoLogout,ssoLogoutTrigger, storeDeviceType, clearDeviceType} = AppSlice.actions;
export const appSelector = (state: RootState) => state.app;
export default AppSlice.reducer;
