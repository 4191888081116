import { createAsyncThunk } from "@reduxjs/toolkit";
import AuditLogService from "../../../web/services/auditLog/auditLogService";

const auditLogService = new AuditLogService();

export const fetchAuditLogTransformList = createAsyncThunk(
  "auditLogList/fetchAuditLogTransformList",
  async (_args: any, thunkAPI) => {
    try {
      const data = await auditLogService.fetchAuditLogTransformList(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);




