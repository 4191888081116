import React from "react";
import { Modal as AntdModal, Tooltip } from "antd";
import {
  Button,
  ButtonType,
  ButtonSize,
} from "../button";
import { useTranslation } from 'react-i18next';

// Styles
import { ModalFooter } from "./modal.styles";
import "../modal/modal.less";

export type ModalProps = {
  /**
   * a component to be rendered in the component.
   */
  children?: React.ReactNode;
  /**
   * controls the visible state of the modal.
   */
  isVisible: boolean;
  /**
   * the modal title in the header.
   */
  title?: string;
  /**
   * the text on the cancel button.
   */
  cancelText?: string;
  /**
   * the text on the OK button.
   */
  okText?: string;
  /**
   * A callback function that is invoked when a user clicks the OK button.
   */
  handleOk: () => void;
  /**
   * A callback function that is invoked when a user clicks the cancel button.
   */
  handleCancel: () => void;
  /**
   * Controls whether the OK button's style is destructive (red).
   */
  destructive?: boolean;
  /**
   * Controls whether the Main button is disabled.
   */
  primaryButtonDisabled?: boolean;
   /**
   * Controls whether the okText button is hidden or not.
   */
  hideOkText?: boolean;
  /**
   * Controls whether modal has interactive elements or not
  */
  haveInteractiveElement?: boolean;
  /**
   * Controls whether confirm is in progress
  */
  confirming?: boolean;

};

export const Modal: React.FC<ModalProps> = ({
  children,
  isVisible,
  title,
  cancelText = "Cancel",
  okText = "OK",
  handleOk,
  handleCancel,
  destructive = false,
  primaryButtonDisabled = false,
  hideOkText=false,
  haveInteractiveElement,
  confirming = false
}: ModalProps) => {
  const { t } = useTranslation()

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 9 && !haveInteractiveElement) {
      const ele: any = document.querySelector(".modalOKbtn");
      setTimeout(() => {
        if (ele) {
          ele.focus();
        }
      }, 100);
    }
  }
  return (
    <AntdModal
      centered
      title={title}
      closable={false}
      visible={isVisible}
      onOk={handleOk}
      okText={okText}
      onCancel={handleCancel}
      cancelText={cancelText}
      maskClosable={false}
      footer={
        <ModalFooter>
          { !hideOkText ?
          <Button
          className="bitUiModalButton modalOKbtn"
            buttonType={
              destructive ? ButtonType.DESTRUCTIVE : ButtonType.PRIMARY
            }
            size={ButtonSize.SMALL}
            onClick={handleOk}
            disabled={primaryButtonDisabled}
          >
            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(okText)}>
            {t(okText)}
              </Tooltip>
              {confirming && 
                <div className="modal-loader loaderButton">
                  <span className="spinner"></span>
                </div>
              }
          </Button> :
          null
          }
          <Button
            buttonType={ButtonType.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={handleCancel}
            onKeyDown={handleKeyPress}
            className="modalCancelBtn"
          >
            {t(cancelText)}
          </Button>
        </ModalFooter>
      }
    >
      {children}
    </AntdModal>
  );
};
