import React from "react";
import { Card } from 'antd';
import "./noUserCard.less"
import { useTranslation } from 'react-i18next';
import { jsonData } from "../../../../../constants/constants";

export const NoUserCard = () => {
  const { t } = useTranslation()
    return (
        <Card className="noUserCard">
          <p className="newCard">{t(jsonData.Nosuchuserfound)}</p>
        </Card>
      );
    };
