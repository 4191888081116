import { createAsyncThunk } from "@reduxjs/toolkit";
import User from "../../../web/models/user/user";
import UserService from "../../../web/services/user/userService";

const userSrv = new UserService();

export const saveProfile = createAsyncThunk(
  "userProfile/saveProfile",
  async (user: any, thunkAPI) => {
    try {
      const data = await userSrv.updateUser(user);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const saveAccountSettings = createAsyncThunk(
  "user/saveAccountSettings",
  async (user: any, thunkAPI) => {
    try {
      const data = await userSrv.updateUser(user);
      if (data) {
        return data;
      }
      else {
        return thunkAPI.rejectWithValue(data);
      }
    }
    catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAccountSettings = createAsyncThunk(
  "user/getAccountSettings",
  async (user: User, thunkAPI) => {
    try {
      if (user && user.id !=='') {
        const userData = await userSrv.getUserById(user.id);
        return userData;
      } else {
        return thunkAPI.rejectWithValue(user);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getProfile = createAsyncThunk(
  "user/getProfile",
  async (user: User, thunkAPI) => {
    try {
      if (user && user.id !=='') {
        const userData = await userSrv.getUserById(user.id);
        return userData;
      } else {
        return thunkAPI.rejectWithValue(user);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const storeCreatePwd = createAsyncThunk(
  "user/storeCreatePwd",
  async (data: string, thunkAPI) => {
    try {
      if (data !== '') {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const storeCreateEml = createAsyncThunk(
  "user/storeCreateEml",
  async (data: string, thunkAPI) => {
    try {
      if (data !== '') {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const mutateAppUserLanguage = createAsyncThunk(
  "user/mutateAppUserLanguage",
  async (user: string, thunkAPI) => {
    try {
      const data = user
      if (data) {
        return data;
      }
      else {
        return thunkAPI.rejectWithValue(data);
      }
    }
    catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);