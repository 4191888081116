/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk } from "@reduxjs/toolkit";
import OrganisationService from "../../../web/services/organisation/organisationService";
import { IOrganisation } from "../../../web/models/organisation/iOrganisation";

const organisationService = new OrganisationService();
const getPayload: any = () => {
  return {
    name: "",
    shortName: "",
    description: "",
    info: {
      countryCode: "",
      culture: "",
      email: "",
      supportURL: "",
      website: "",
      timezone: "",
      logo: "",
      locality: "",
      language: ""
    },
    hierarchyTypeCode: "ORG",
    parentId: null,
    isActive: true,
    pa_line1: "",
    pa_line2: "",
    pa_line3: "",
    pa_line4: "",
    pa_landmark: "",
    pa_postcode: "",
    pa_city: "",
    pa_county: "",
    pa_country: "",
    pa_isActive: true,
    ba_sameAs_pa: true,
    ba_line1: "",
    ba_line2: "",
    ba_line3: "",
    ba_line4: "",
    ba_landmark: "",
    ba_postcode: "",
    ba_city: "",
    ba_county: "",
    ba_country: "",
    ba_isActive: true
  };
};

export const fetchDepthByOrgID = createAsyncThunk(
  "location/fetchDepth",
  async ({orgId, depth}:{orgId: string, depth?:number},  thunkAPI) => {
  //async (orgId: number, depth: number, thunkAPI) => {
    try {
      const data = await organisationService.fetchDepthByOrgID(orgId, depth);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// fetch transformed location list
export const fetchTransformedLocations = createAsyncThunk(
  "location/fetchTransformedLocations",
  async ({orgId, depth}:{orgId: string, depth?:number},  thunkAPI) => {
    try {
      const data = await organisationService.fetchTransformedLocations(orgId, depth);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const addLocation = createAsyncThunk(
  "location/addLocation",
  async ({organisationID, name, type, parentID, t}:{organisationID: string, name: string, type: string, parentID: string, t?:any}, thunkAPI) => {
    try {
      const response: any = await organisationService.fetchOrganisationbyID(organisationID);
      const organisation = response?.result;
      let payload: IOrganisation = getPayload();
      payload['name'] = name;
      payload['shortName'] = name;
      payload['description'] = organisation.description;

      payload['info']['countryCode'] = organisation.info.countryCode || organisation.postalAddress.country;
      payload['info']['culture'] = organisation.info.culture || 'en-US';
      payload['info']['email'] = organisation.info.email || 'robbert.miller@smartcosmos.com';
      payload['info']['supportURL'] = organisation.info.supportURL || 'http://www.smartcosmos.com';
      payload['info']['website'] = organisation.info.website || 'http://www.smartcosmos.com';
      payload['info']['timezone'] = organisation.info.timezone || 'CEST (GMT +5:30)';
      payload['info']['logo'] = organisation.info.logo || 'no logo';
      payload['info']['locality'] = organisation.info.locality || organisation.postalAddress.pa_landmark || 'Landmark';
      payload['info']['language'] = organisation.info.language || 'English';

      payload['hierarchyTypeCode'] = type;
      payload['parentId'] = parentID;

      payload['pa_line1'] = organisation.postalAddress.pa_line1 || 'Morrision Road';
      payload['pa_line2'] = organisation.postalAddress.pa_line2;
      payload['pa_line3'] = organisation.postalAddress.pa_line3;
      payload['pa_line4'] = organisation.postalAddress.pa_line4;
      payload['pa_landmark'] = organisation.postalAddress.pa_landmark;
      payload['pa_postcode'] = organisation.postalAddress.postcode;
      payload['pa_city'] = organisation.postalAddress.city;
      payload['pa_country'] = organisation.postalAddress.country;
      payload['pa_county'] = organisation.postalAddress.county;
      payload[`t`] = t
      
      const data = await organisationService.addOrganisation(payload);
      if (data) {
        return data;
        // try {
        //   const response = await organisationService.fetchDepthByOrgID(organisationID);
        //   return response;
        // }
        // catch (e: any) {
        //   return thunkAPI.rejectWithValue(data);
        // }
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const deleteOrganisationByID = createAsyncThunk(
  "location/deleteDepth",
  async (org: any, thunkAPI) => {
    try {
      const data = await organisationService.deleteOrganisation(org.id, org.hierarchyTypeCode);
      if (data?.data?.result) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const relocateAndDeleteLocation = createAsyncThunk(
  "location/relocateAndDeleteLocation",
  async (org: any, thunkAPI) => {
    try {
      const data = await organisationService.relocateAndDeleteLocation(org.id, org.replacementHierarchyId);
      if (data?.data?.result) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateLocation = createAsyncThunk(
  "location/updateLocation",
  async ({organisationID, name,parentID,type}:{organisationID: string, name: string , parentID: string,type: string}, thunkAPI) => {
    try {
      const response: any = await organisationService.fetchOrganisationbyID(organisationID);
      const organisation = response?.result;
      let payload: IOrganisation = getPayload();
      payload['name'] = name;
      payload['shortName'] = name;
      payload['parentId'] = parentID;
      payload['hierarchyTypeCode'] = type;
      const data = await organisationService.updateOrganisation(organisationID, payload);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);