import styled from "styled-components";

export const MultiSelectMainLabel = styled.span`
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: 20px;
    color: ${(props) => props.theme.colors.palette.black};
`;

export const MultiSelectCheckBox = styled.div`
    align-items: center;
    display: flex;
`

export const MultiSelectLabel = styled.div`
    padding: 7px 4px 7px 24px;
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${(props) => props.theme.fontSizes.body};
    line-height: 20px;
    color: ${(props) => props.theme.colors.palette.black};
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const MultiSelectWrapper = styled.div`
    max-width: 200px;
    height: 54px;
    display: flex;
    align-items: center;
`;