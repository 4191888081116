import React from "react";
import "./colorPicker.less";

interface ColorPickerInterface {
  value?: string;
  id?: string;
  onChange: any;
  disabled?: boolean;
}

const ColorPicker = (props: ColorPickerInterface) => {
  const { value, id, onChange, disabled } = props;

  let colorChangeHandler: any;

  let val: string;
  function debounceChange(event: any) {
    val = event.target.value;
    clearTimeout(colorChangeHandler);
    colorChangeHandler = setTimeout(() => {
      onChange(val);
    }, 100);
  }

  return (
    <input
      type="color"
      id={id}
      name="color"
      value={value}
      disabled={disabled}
      onChange={(event: any) => debounceChange(event)}
      className="color-picker-input"
    />
  );
};

export default ColorPicker;