import styled from 'styled-components'

export const DeviceInfoTitle = styled.div`
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-size: ${(props) => props.theme.fontSizes.h1};
    line-height: 20px;
    display: flex;
    align-items: center;
    // min-height: 54px;
    color: ${(props) => props.theme.colors.text.primary};
`