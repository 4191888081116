import styled from "styled-components";

export const CardUserName = styled.p`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: ${(props) => (props.theme.fontWeights.normal)};
    font-size: ${(props) => (props.theme.fontSizes.h4)};
    line-height: 20px;
    display: flex;
    align-items: center;
    color: ${(props) => (props.theme.colors.palette.black)};
    padding-top: 15px;
    margin-bottom: 0px;
    @media screen and (max-width: 992px) {
        font-size: ${(props) => (props.theme.fontSizes.h5)};
    }
`

export const CardUserRole = styled.span`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: ${(props) => (props.theme.fontSizes.smallBody)};
    line-height: 20px;
    color: ${(props) => (props.theme.colors.palette.red)};
    padding-top: 2px;
    padding-bottom: 15px;
`

export const CardUserLocation = styled.span`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: ${(props) => (props.theme.fontSizes.smallBody)};
    line-height: 20px;
    color: ${(props) => (props.theme.colors.palette.black)};
    word-break: break-word;
`

export const CardUserDetail = styled.p`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: ${(props) => (props.theme.fontSizes.smallBody)};
    line-height: 20px;
    color: ${(props) => (props.theme.colors.palette.darkestGray)};
    padding-top: 24px;
    margin-bottom: 0px;
`

export const CardUserInfo = styled.p`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: ${(props) => (props.theme.fontSizes.smallBody)};
    line-height: 20px;
    color: ${(props) => (props.theme.colors.palette.darkestGray)};
    padding-top: 2px;
    margin-bottom: 0px;
`

export const CardAddUser =styled.p`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: ${(props) => (props.theme.fontSizes.h5)};
    line-height: 20px;
    display: flex !important;
    align-items: center;
    color: ${(props) => (props.theme.colors.palette.darkestGray)};
    margin-bottom: 0px;
`;

export const CardUserBlocked =styled.span`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: ${(props) => (props.theme.fontSizes.footnote)};
    line-height: 20px;
    color: ${(props) => (props.theme.colors.palette.red)};
    margin-left: 4px;
`;

export const CardUserInvited =styled.span`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: ${(props) => (props.theme.fontSizes.footnote)};
    line-height: 20px;
    color: #55c1f6;
`;