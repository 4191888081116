/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from "react";
import { useHistory } from "react-router";
import {
  ErrorIcon,
  AlertSearchIcon,
  UpDownImg,
  warningIcon,
} from "../../../../images/index";
import "./AlertList.less";
import {
  StyledAlertdiv,
  StyledAlertsearchdiv,
  AlertTextdiv,
  TimeAlertdiv,
  SearchIcondiv,
  SearchTextdiv,
  RecentAlertdiv,
  DropdownIcon,
  AlertsTextdiv,
  AlertIcondiv,
  Locationdiv,
  Devicediv,
  WrapperDivForCards,
  StatusDiv,
  StyledRowAlertList,
  AlertScrollStyle,
  AlertColumnStyle,
  AlertDivider,
} from "./alertList.styles";
import "../../../stateless/common/customScrollBar/customScrollStyle.less";
import { Col, Divider, Row } from "antd";
import { AppRoutes } from "../../../../constants/enums";
import { useDispatch, useSelector } from "react-redux";
import { getElapsedTime, urlModification, getOrganizationDateAndTime, translateOptions } from "../../../../../utility/appUtil";
import { goToPrevious } from "../../../../../redux/reducers/app/appReducers";
import { useTranslation } from 'react-i18next';
import { jsonData } from "../../../../constants/constants";
import { Dropdown } from "../../../../../bit_components/common/dropdown";
import { alertDashBoardSorting } from "../../../../constants/constants";
import Loader from "../../../stateless/common/spinner";
import { AlertSelector } from "../../../../../redux/reducers/alert/alertReducer";

const AlertList = (props: any) => {
  const { alerts, totalAlerts, sortAlert } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const { fetchAlertFormState } = useSelector(AlertSelector);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<any>();

  const onChangeFilter = (value: string) => {
    setSelectedDropdownValue(value)
    sortAlert(value)
  }

  const goToAlertCenter = (e: any, key: string = "", data: any = null) => {
    e.stopPropagation();
    dispatch(goToPrevious({showBackButton: true, showCustomRange: true}))
    if (key) {
      if (key === "location") {
        history.push(urlModification(AppRoutes.ALERT_CENTRE), {
          location: data,
          addCategory: true
        });
      }
      if (key === "device") {
        history.push(urlModification(AppRoutes.ALERT_CENTRE), { device: data, addCategory: true });
      }
      if (key === "alert") {
        history.push(urlModification(AppRoutes.ALERT_CENTRE), { alert: data, removeHierarchy: true, removeDevice: true });
      }
    } else {
      history.push(urlModification(AppRoutes.ALERT_CENTRE));
    }
  };

  const parseData = (data: string) => {
    return JSON.parse(data);
  };

  const alertsList = alerts?.filter((data: any) => {
    return ( data.severity === "error" || data.severity === "warning")
  })

  return (
    <StyledAlertdiv>
      <StyledRowAlertList>
          <AlertTextdiv md={24} lg={5} xl={10}>{t(jsonData.Alerts)}</AlertTextdiv>
        {alertsList?.length > 0 ? (
            <RecentAlertdiv md={24} lg={19} xl={14}>
              <Dropdown
                    dropdownAlign={{ offset: [-125, 4] }}
                    className="filterDropDown alertPageDropdown alertDropValue"
                    defaultValue={t(jsonData.MostRecent)}
                    optionValue={translateOptions(alertDashBoardSorting,t,'text')}
                    onChange={onChangeFilter}
                    bordered={false}
                    value={selectedDropdownValue}
                    optionClass="eventFilterBox"
                    tabIndex={0}
                    ariaLabel={t(jsonData.mostRecent)}
                  />
            </RecentAlertdiv>
        ) : null}
      </StyledRowAlertList>
      <Loader loading={fetchAlertFormState?.loading}>
      <AlertScrollStyle
        className="scrollStyle">
        {alertsList?.length > 0 ? (
          <AlertColumnStyle>
            {" "}
            {alertsList?.map((alert: any, index: number) => {
              return (
                <WrapperDivForCards
                  onKeyPress={(event: any) => goToAlertCenter(event, "alert", alert)}
                  tabIndex={0}
                  aria-label={`${alert?.severity === "error" ? t(jsonData.errorAlert) : t(jsonData.warningAlert)}`} 
                  role={""} 
                  key={index} className="alertBlock">
                  <AlertsTextdiv
                    key={index}
                  >
                    <AlertIcondiv>
                      {alert.severity === "error" ? (
                        <img src={ErrorIcon} alt=""/>
                      ) : (
                        <img src={warningIcon} alt="" />
                      )}
                    </AlertIcondiv>
                    <div className="dashboardAlertCard">
                      {/* {alert.category == ('error') ? <div className="alertMessage">{parseData(alert.data)?.deviceStatus}</div> : <div onClick={(event:any)=>{goToAlertCenter(event, "status", alert)}} className="warningMessage">{parseData(alert.data)?.deviceStatus}</div>} */}
                      <StatusDiv
                        tabIndex={0}
                        aria-label={`${alert?.severity === "error" ? t(jsonData.error) : t(jsonData.warning)} ${alert?.alertName}`}
                        role={"link"} 
                        onKeyPress={(event: any) => {goToAlertCenter(event, "alert", alert)}}
                        className={
                          alert.severity === "error"
                            ? "alertMessage"
                            : "warningMessage"
                        }
                        onClick={(event: any) => {
                          goToAlertCenter(event, "alert", alert);
                        }}
                      >
                         {alert?.alertName}
                      </StatusDiv>
                      <Locationdiv 
                        tabIndex={0}
                        aria-label={`${t(jsonData.Location)} ${alert?.hierarchyName}`} 
                        role={"link"} 
                        onKeyPress={(event: any) => {goToAlertCenter(event, "alert", alert)}}
                        onClick={(event: any) =>
                          goToAlertCenter(
                            event,
                            "location",
                            {location: alert?.hierarchyId, eventTimeStamp: alert.eventTimeStamp, alertName: alert?.alertName}
                          )
                        }
                      >
                        {alert?.hierarchyName}
                      </Locationdiv>
                      <Devicediv 
                        tabIndex={0}
                        aria-label={` ${t(jsonData.Device)} ${alert?.srcName}`} 
                        role={"link"} 
                        onKeyPress={(event: any) => {goToAlertCenter(event, "alert", alert)}}
                        onClick={(event: any) =>
                          goToAlertCenter(event, "device",
                            {device: alert?.isArchived ? null : alert?.src, eventTimeStamp: alert.eventTimeStamp, alertName: alert?.alertName}
                          )
                        }
                      >
                        {alert?.srcName}
                      </Devicediv>
                    </div>
                    <TimeAlertdiv className="alertTime">
                      {getElapsedTime(alert.eventTimeStamp)}
                    </TimeAlertdiv>
                  </AlertsTextdiv>
                  <div className="alertDivider">
                    <AlertDivider
                      key={alert.id}
                    />
                  </div>
                </WrapperDivForCards>
              );
            })}
          </AlertColumnStyle>
        ) : (
          <StyledAlertsearchdiv>
            <div>
              <SearchIcondiv>
                <img
                  src={AlertSearchIcon}
                  className="userAlertIcon"
                  alt="Alert Search"
                />
              </SearchIcondiv>
              <SearchTextdiv>
                <p>
                 {t(jsonData.EverythingsGoingWell)}
                  <br />
                  {t(jsonData.YouveGotNoAlerts)}
                </p>
              </SearchTextdiv>
            </div>
          </StyledAlertsearchdiv>
        )}
      </AlertScrollStyle>
      </Loader>
    </StyledAlertdiv>
  );
};

export default AlertList;