import React, {useState} from 'react';
import MultiSelect from "react-multi-select-component";
import { ISelectProps } from 'react-multi-select-component/dist/lib/interfaces';
import UpDownImg from './upDownArrow.svg';
import './multiSelect.less';
export type MultiselectProps = ISelectProps

const Arrow =()=>{
  return <img src={UpDownImg} alt=''/>
}

export function Multiselect({ ...props }: any) {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  let element = document.querySelectorAll('.select-item');
  let options: any = document.querySelector('.options');
  if (options) {
    options.setAttribute("tabindex", "0");

  }
  element.forEach((value) => {
    if (value) {
      value.setAttribute("tabindex", "0");
    }
  })

  const handleDropdown = (e: any) => {
    let select: any = document.querySelector('.customMultiSelectDrop .options')
    if (e?.keyCode === 13) {
      select.click();
      setShowDropdown(true)
    }
  }
 
  return (
    <div onKeyUp={(e) => handleDropdown(e)} className='customMultiSelectDrop'>
      <MultiSelect
        options={props.options}
        value={props.value}
        onChange={props.onChange}
        disableSearch={true}
        labelledBy={props.labelledBy}
        ArrowRenderer ={Arrow}
        overrideStrings={props?.overrideStrings}
        disabled={props.disabled?props.disabled: false}
        className={props.disabled? `${props.className} multiselectDisable`: props.className}
        valueRenderer={props?.valueRenderer}
      />
    </div>
  );
}
