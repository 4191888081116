/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {Modal} from '../../../../../../bit_components/ui/modal'
import {Modal as AntdModal, TreeSelect} from 'antd';
import { Primarytext, Secondarytext, Bold, DropdownHeading, DropdownSection, LoadingSection, LoadingImg, Loadingtext, ModalDivSection } from "./locationModal.style";
import { organisationDepthSelector } from "../../../../../../redux/reducers/organisation/organisationDepthReducer";
import { deleteOrganisationByID, relocateAndDeleteLocation } from "../../../../../../redux/actions/organisation/fetchDepth";
import {
    Button,
    ButtonType,
    ButtonSize,
  } from "../../../../../../bit_components/ui/button";
import { ModalFooter } from "../../../../../../bit_components/ui/modal/modal.styles";
import './locationModal.less';
import { switcherIcon, UpDownImg } from "../../../../../images";
import { DeviceSelector } from "../../../../../../redux/reducers/device/deviceReducer";
import { jsonData } from "../../../../../constants/constants";
import { useTranslation } from 'react-i18next';

export const LocationModal = (props) =>{
    const { isModalVisible, setModalVisible, rowId, rowName, rowType, rowParent  } = props;
    const dispatch = useDispatch();
    const { result } = useSelector(organisationDepthSelector);
    const [locationList, setLocationList] = useState(result);
    const [locationArray, setLocationArray] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const { deviceCount, formState } = useSelector(DeviceSelector);
    const { t } = useTranslation()

    const treeNodes = (sublocations) => {
        const nodes = sublocations.filter((x: any) => x?.id !== rowId).map((y: any) => {
            if(y?.children?.length > 0){
                return { title: y.shortName, value: y.id, children: treeNodes(y?.children) }
            }
            else{
                return { title: y.shortName, value: y.id }
            }
        }); 
        return nodes
    }

    useEffect(() => {
        setLocationArray(locationList?.children?.filter((x: any) => x?.id !== rowId).map((y: any) => {
            if(y?.children?.length > 0){
                return { title: y.shortName, value: y.id,
                        children: treeNodes(y?.children)
                    }
                }
            else{
                return { title: y.shortName, value: y.id }
            }
        }))
    }, [locationList]);

    useEffect(() => {
       if(locationArray?.length>0){
            if(rowParent?.hierarchyTypeCode !== 'ORG'){
                setSelectedValue(rowParent?.id)
            }
            else{
                setSelectedValue(locationArray[0]?.value)
            }
       }
    }, [locationArray, formState?.loading])

    const handleDelete = () => {
        dispatch(deleteOrganisationByID({ id: rowId, hierarchyTypeCode: rowType }));
        setModalVisible(false);
    }

    const handleRelocate = () => {
        dispatch(relocateAndDeleteLocation({id: rowId, replacementHierarchyId: selectedValue}));
        setModalVisible(false);
    }
    const handleCancel = () => {
        setModalVisible(false);
    }

    const handleLocationSelect = (value) => {
        setSelectedValue(value)
    }

    const loadingModal = () => {
        return(
            <AntdModal
            centered={true}
            visible={isModalVisible}
            closable={false}
            footer={[]}
            >
                <LoadingSection>
                    <LoadingImg className="modalSpinner"></LoadingImg>
                    <Loadingtext className="modalLoadingText">{t(jsonData.Loading)}.</Loadingtext>
                </LoadingSection>
            </AntdModal>  
        );
    }

    const onlyOneLocation = () => {
        return(
            <AntdModal
            centered={true}
            visible={isModalVisible}
            closable={false}
            footer={[
                <ModalFooter>
                    <Button
                        buttonType={ButtonType.SECONDARY}
                        size={ButtonSize.SMALL}
                        onClick={handleCancel}
                        >
                        {t(jsonData.Cancel)}
                    </Button>
                </ModalFooter>
            ]}
            >
                <ModalDivSection>
                    <Primarytext>{t(jsonData.TheOrganisationMustOneLocation)}.</Primarytext>
                    <Secondarytext><Bold>{rowName}</Bold> {t(jsonData.onlyLocationThereforeCanNotBeDeleted)}.</Secondarytext>
                </ModalDivSection>
            </AntdModal>  
        );
    }

    const relocateAndDelete = () => {
        return(
            <Modal
                handleCancel={handleCancel}
                handleOk={handleRelocate}
                destructive
                okText={t(jsonData.RelocateDelete)}
                cancelText={t(jsonData.Cancel)}
                isVisible={isModalVisible}
                >
                    <ModalDivSection>
                        <Primarytext>{rowName} {t(jsonData.currentlyUsedFor)} {deviceCount} {deviceCount===1? t(jsonData.device) : t(jsonData.devices)}.</Primarytext>
                        <Secondarytext>{t(jsonData.RelocateDevicesDeleting)}</Secondarytext>
                        <DropdownSection>
                            <DropdownHeading>{t(jsonData.Relocatedevices)}</DropdownHeading>
                            <TreeSelect
                                className="locationModalDropdown"
                                treeData={locationArray}
                                dropdownClassName="TreeNodeDropdown"
                                suffixIcon={<img src={UpDownImg} alt=""/>}
                                value={selectedValue}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto'  }}
                                onChange={handleLocationSelect}
                                switcherIcon={<img src={switcherIcon} alt=""/>}
                                maxTagTextLength={100}
                            />
                        </DropdownSection>
                    </ModalDivSection>
                </Modal>
        );
    }

    const deleteLocation = () => {
        return(
            <Modal
            handleCancel={handleCancel}
            handleOk={handleDelete}
            destructive
            okText={jsonData.DeleteLocation}
            cancelText='Cancel'
            isVisible={isModalVisible}
            >
                <ModalDivSection>
                    <Primarytext>{t(jsonData.DeleteLocation)}</Primarytext>
                    <Secondarytext>{t(jsonData.WouldYouDelete)}<br/><Bold>{rowName} ?</Bold></Secondarytext>
                </ModalDivSection>
            </Modal>
        );
    }

    return (
        <>
        {formState?.loading ? loadingModal()
        :
        locationArray?.length===0 ?
            onlyOneLocation()
        :
            deviceCount > 0 ?
                relocateAndDelete()
            :
                deleteLocation()
        }
        </>
    );
}