import styled from "styled-components";
import { DisabledIcon } from "../../../web/images";

type ButtonDimensions = { width: number; height: number };

export enum ButtonSize {
  SMALL,
  MEDIUM,
  LARGE,
}

const buttonDimensions: { [key in ButtonSize]: ButtonDimensions } = {
  [ButtonSize.SMALL]: { width: 150, height: 30 },
  [ButtonSize.MEDIUM]: { width: 200, height: 40 },
  [ButtonSize.LARGE]: { width: 310, height: 51 },
};

const Button = styled.button<{ size: ButtonSize }>`
  display: block;
  padding: 0;
  width: ${(props) => buttonDimensions[props.size].width}px;
  height: ${(props) => buttonDimensions[props.size].height}px;
  line-height: ${(props) => buttonDimensions[props.size].height}px;
  box-sizing: border-box;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  font-size: ${(props) =>
    props.size === ButtonSize.SMALL
      ? props.theme.fontSizes.smallBody
      : props.theme.fontSizes.h5};
  font-weight: ${(props) => props.theme.fontWeights.normal};

  &:disabled {
    box-shadow: none;
    cursor: url(${DisabledIcon}), auto;
  }
`;

export const PrimaryButton = styled(Button)<{ size: ButtonSize }>`
  background: ${(props) => props.theme.colors.button.primary};
  color: ${(props) => props.theme.colors.text.penta};
  box-shadow: ${(props) => props.theme.colors.button.shadow.primary};

  &:hover {
    background: ${(props) => props.theme.colors.button.hover.primary};
  }

  &:active {
    background: ${(props) => props.theme.colors.button.click.primary};
  }

  &:disabled {
    background: ${(props) => props.theme.colors.button.disabled.primary};
    color: ${(props) => props.theme.colors.text.primary};
  }
`;

export const SecondaryButton = styled(Button)`
  border: 1px solid ${(props) => props.theme.colors.button.primary};
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.text.primary};
  background: ${(props) => props.theme.colors.button.secondary};

  &:hover {
    background: ${(props) => props.theme.colors.button.hover.secondary};
    border-color: ${(props) => props.theme.colors.button.hover.primary};
    color: ${(props) => props.theme.colors.button.hover.primary};
  }

  &:active {
    background: ${(props) => props.theme.colors.button.click.secondary};
    border-color: ${(props) => props.theme.colors.button.click.primary};
    color: ${(props) => props.theme.colors.button.click.primary};
  }

  &:disabled {
    background: ${(props) => props.theme.colors.button.disabled.secondary};
    border-color: ${(props) => props.theme.colors.button.disabled.primary};
    color: ${(props) => props.theme.colors.button.click.primary};
  }
`;

export const DeleteButton = styled(Button)`
  background: ${(props) => props.theme.colors.button.destructive};
  color: ${(props) => props.theme.colors.text.penta};
  box-shadow: ${(props) => props.theme.colors.button.shadow.destructive};

  &:hover {
    background: ${(props) => props.theme.colors.button.hover.destructive};
  }

  &:active {
    background: ${(props) => props.theme.colors.button.click.destructive};
  }

  &:disabled {
    background: ${(props) => props.theme.colors.button.disabled.destructive};
    color: ${(props) => props.theme.colors.text.primary};
  }
`;
