import { createAsyncThunk } from "@reduxjs/toolkit";
import OrganisationService from "../../../web/services/organisation/organisationService";

const orgSrv = new OrganisationService();

// export const fetchLocationList = createAsyncThunk(
//   "locations/fetchLocationList",
//   async (organizationId: string, thunkAPI) => {
//     try {
//       const data = await orgSrv.fetchLocations(organizationId);
//       if (data) {
//         return data;
//       } else {
//         return thunkAPI.rejectWithValue(data);
//       }
//     } catch (e: any) {
//       return thunkAPI.rejectWithValue(e);
//     }
//   }
// );

export const fetchTransformedLocationList = createAsyncThunk(
  "locations/fetchTransformedLocationList",
  async (organizationId: string, thunkAPI) => {
    try {
      const data = await orgSrv.fetchTransformedLocationList(organizationId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const hierarchySelected = createAsyncThunk(
  "locations/selectedIndexChanged",
  async ( updatedArray: Array<number>, thunkAPI) => {
    try{
      return updatedArray;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    } 
    
  }
    
  
);
