/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react"
import { getStatusTypeEllipse } from "../../../../../../utility/utils";
import { deviceComponentSorting, jsonData, Status_Type } from "../../../../../constants/constants";
import { DeviceHealthBody } from "../../deviceHealth/deviceHealthHeader/index.styles";
import { DeviceHeader } from "./index.styles";
import { useDispatch, useSelector} from "react-redux";
import './style.less'
import { Popover, Row, Col, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { fetchDeviceComponents } from "../../../../../../redux/actions/device/deviceAction";
import { appSelector } from "../../../../../../redux/reducers/app/appReducers";
import { DeviceSelector,setDeviceComponentParams } from "../../../../../../redux/reducers/device/deviceReducer";
import { Dropdown } from "../../../../../../bit_components/common/dropdown";
import { useTranslation } from 'react-i18next';
import { translateOptions } from "../../../../../../utility/appUtil";

export const DeviceComponentHeader = (props: any) => {
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<any>();
  const { selectedDevice } = useSelector(appSelector)
  const { deviceComponentParams } = useSelector(DeviceSelector)
  const dispatch = useDispatch(); 
  const { t } = useTranslation()

  const onChangeFilter = (value: string) => {
    setSelectedDropdownValue(value);
    dispatch(setDeviceComponentParams({id:selectedDevice?.deviceId, sortBy: value}))
    dispatch(fetchDeviceComponents({id:selectedDevice?.deviceId, sortBy: value}))
  }

 
  const getDeviceHealthStatus = (key: any) => {
    return Object.keys(Status_Type).map(function (key: any) {
      return (
        <DeviceHealthBody className="showColourCode">
          <img src={getStatusTypeEllipse(Status_Type[key])} alt="" /> <span >{t(Status_Type[key].toLowerCase())}</span>
        </DeviceHealthBody>
      )
    })
  }

  return (
    <>
      <Row className="device-health-header pl-vw">
        <Col tabIndex={0} >
          <DeviceHeader>{t(jsonData.Components)}</DeviceHeader>
        
          <Popover
            trigger={["click", "focus"]}
            placement="bottomLeft"
            content={getDeviceHealthStatus}
            getPopupContainer={(trigger: any) => trigger.parentNode}
          >
            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.deviceStates)}>
              <InfoCircleOutlined tabIndex={0} className="informationIcon"></InfoCircleOutlined>
            </Tooltip>
          </Popover>
        </Col>

        <Col flex='auto'>
          <Row className="health-dropdown-div">
            <Col>
              <Row className="userFilter">
                <span className="eventFilterTab">
                  <Dropdown
                    className="filterDropDown alertPageDropdown"
                    defaultValue={t(jsonData.SortBy)}
                    optionValue={translateOptions(deviceComponentSorting,t,'text')}
                    onChange={onChangeFilter}
                    bordered={false}
                    value={deviceComponentParams.sortBy}
                    optionClass="eventFilterBox"
                    tabIndex={0}
                    ariaLabel={t(jsonData.SortBy)}
                  />
                </span>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}