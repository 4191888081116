import styled from "styled-components";
import React from "react";

export const ModalFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const AntdTableInputUI = styled((props: any) => {
  const { children, ...rest } = props;
  return <svg {...rest}>{children}</svg>;
})`

  ${({ width  }) =>
  width && `
    width: ${width} !important;
  `
  }
`;