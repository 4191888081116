import styled from "styled-components";

export const ReportHead = styled.div`
    font-family: ${(props) => props.theme.fonts[0]};
    color: ${(props) => props.theme.colors.text.primary};
`;

export const ReportDiv = styled.div`
    font-size: ${(props) => props.theme.fontSizes.h1};
    font-weight: ${(props) => props.theme.fontWeights.normal};
    line-height : 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}
`;

export const SpanReport = styled.span`
    margin-right: 10px;
    cursor : pointer;
`;

export const GroupByWrapper = styled.span`
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: 20px !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${(props) => props.theme.colors.palette.black};
`;



// export const LineGraphRound = styled((props: any) => {
//     const { children, ...rest } = props;
//     return <RightCircleFilled {...rest}>{children}</RightCircleFilled>;
//   })`
  
//     ${({ fill  }) =>
//     fill && `
//       fill: ${fill} !important;
//     `
//     }
//   `;
  