import { createAsyncThunk } from "@reduxjs/toolkit";
import OrganisationService from "../../../web/services/organisation/organisationService";

const organisationService = new OrganisationService();

export const fetchOrganisations = createAsyncThunk(
    "organisation/fetchOrganisations",
    async (_args: any, thunkAPI) => {
        try {
            const data = await organisationService.fetchOrganisations(_args);
            
            if (data) {
                return data;
            }

            return thunkAPI.rejectWithValue(data);
        } catch (e: any) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

// organisation list transformation
export const fetchTransformedOrganisationsList = createAsyncThunk(
    "organisation/fetchTransformedOrganisationsList",
    async (_args: any, thunkAPI) => {
        try {
            const data = await organisationService.fetchTransformedOrganisationsList(_args);
            
            if (data) {
                return data;
            }

            return thunkAPI.rejectWithValue(data);
        } catch (e: any) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);