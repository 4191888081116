import ReactGA from "react-ga4";

export const UseGAEventTracker = (
  category: string,
  action: any,
  label?: any,
  value?: number
) => {
  let event: any = { category: category, action: action };

  if (label) {
    event.label = label;
  }
  if (value && !isNaN(value)) {
    event.value = value;
  }

  return ReactGA.event(event);
};
