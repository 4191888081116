import { createAsyncThunk } from "@reduxjs/toolkit";
import AlertService from "../../../web/services/alerts/alertService";

const alertService = new AlertService();

// fetch alerts by organization id
export const fetchAlertByOrgId = createAsyncThunk(
  "alert/fetchAlertByOrgId",
    async (_args: any, thunkAPI) => {
    try {
      const data = await alertService.fetchAlertList(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// to clear selected alert
export const updateActiveAlert = createAsyncThunk(
  "alert/updateActiveAlert",
  async (_args: any, thunkAPI) => {
    try {
      const data = await alertService.updateActiveAlert(_args?.data, _args?.id);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// set cordinate of selected card in alert chart
export const setSelectedCordinatesCards = createAsyncThunk(
  "alert/setSelectedCordinatesCards",
    async (cards: any, thunkAPI) => {
    try {
        return cards;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// set chart initial cordinate data
export const setChartCordinates = createAsyncThunk(
  "alert/setChartCordinates",
    async (setcards: any, thunkAPI) => {
    try {
        return setcards;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// get the cordinates of points on click inside antd chart
export const setChartTooltipFilter = createAsyncThunk(
  "alert/setChartTooltipFilter",
    async (filtercards: any, thunkAPI) => {
    try {
        return filtercards;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
export const setAlertDuration = createAsyncThunk(
  "alerts/setAlertDuration",
    async (date: any, thunkAPI) => {
    try {
        return date;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)  



// transformation -> fetch alert count in header
export const fetchTransformedAlertCount = createAsyncThunk(
  "alert/fetchTransformedAlertCount",
    async (_args: any, thunkAPI) => {
    try {
      const data = await alertService.fetchTransformedAlertCount(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
