import { DefaultTheme } from "styled-components";

const BLACK = "#1C1C1C";
const DARKEST_GRAY = "#4D4D4D";
const DARK_GRAY = "#737373";
const GRAY = "#CBCBCB";
const LIGHT_GRAY = "#EDEDED";
const LIGHTEST_GRAY = "#FAFAFA";
const WHITE = "#FFFFFF";
const RED = "#EE0000";
const LIGHT_RED = "#FF2424";
const ROSE = "#FF5757";
// const BLUE = "#55C1F6";

export function MainTheme(): DefaultTheme {
  return {
    colors: {
      palette: {
        black: BLACK,
        darkestGray: DARKEST_GRAY,
        darkGray: DARK_GRAY,
        gray: GRAY,
        lightGray: LIGHT_GRAY,
        lightestGray: LIGHTEST_GRAY,
        white: WHITE,
        red: RED,
        lightRed: LIGHT_RED,
        rose: ROSE,
      },
      background: LIGHTEST_GRAY,
      text: {
        primary: BLACK,
        secondary: DARKEST_GRAY,
        tertiary: RED,
        quaternary: DARK_GRAY,
        penta: WHITE,
        hexa: LIGHTEST_GRAY,
      },
      menu: {
        background: {
          primary: WHITE,
          secondary: RED,
          tertiary: BLACK,
        },
        text: {
          primary: BLACK,
          secondary: WHITE,
          tertiary: GRAY,
        },
      },
      button: {
        primary: BLACK,
        secondary: "none",
        destructive: RED,
        shadow: {
          primary:
            "0px 94px 138px rgba(0, 0, 0, 0.2), 0px 47.0459px 69.0674px rgba(0, 0, 0, 0.152067), 0px 28.3382px 41.603px rgba(0, 0, 0, 0.130318), 0px 18.1606px 26.6614px rgba(0, 0, 0, 0.114179), 0px 11.7703px 17.2797px rgba(0, 0, 0, 0.1), 0px 7.40924px 10.8774px rgba(0, 0, 0, 0.0858211), 0px 4.25707px 6.24974px rgba(0, 0, 0, 0.0696822), 0px 1.87365px 2.75067px rgba(0, 0, 0, 0.0479335)",
          secondary: "none",
          destructive:
            "0px 45px 80px rgba(238, 0, 0, 0.14), 0px 22.522px 40.0391px rgba(238, 0, 0, 0.106447), 0px 13.5662px 24.1177px rgba(238, 0, 0, 0.0912224), 0px 8.69392px 15.4559px rgba(238, 0, 0, 0.0799253), 0px 5.6347px 10.0172px rgba(238, 0, 0, 0.07), 0px 3.54698px 6.30574px rgba(238, 0, 0, 0.0600747), 0px 2.03796px 3.62304px rgba(238, 0, 0, 0.0487776), 0px 0.896959px 1.59459px rgba(238, 0, 0, 0.0335534)",
        },
        hover: {
          primary: DARKEST_GRAY,
          secondary: "none",
          destructive: LIGHT_RED,
        },
        click: {
          primary: DARK_GRAY,
          secondary: "none",
          destructive: ROSE,
        },
        disabled: {
          primary: LIGHT_GRAY,
          secondary: "none",
          destructive: LIGHT_GRAY,
        },
      },
    },
    fonts: ["IBM Plex Sans"],
    fontSizes: {
      h1: "1.4375rem",
      h2: "1.25rem",
      h3: "1.125rem",
      h4: "1rem",
      h5: "0.875rem",
      body: "0.8125rem",
      smallBody: "0.75rem",
      footnote: "0.6875rem",
      caption: "0.625rem",
    },
    fontWeights: {
      normal: "500",
      regular: "400",
      light: "300",
    },
  };
}
