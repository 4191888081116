import React from "react";
import { Modal } from "antd";

import { OkButton, Primarytext } from "./permissionModal.Styles";
import "./permissions.less";
import { jsonData } from "../../../../constants/constants";
import { useTranslation } from 'react-i18next';

const PermissionModal = (props: any) => {
  const { isModalVisible, setModalVisible, tabIndex, ariaLabel, role } = props;
  const { t } = useTranslation()
  return (
    <Modal
      centered={true}
      wrapClassName="permissions"
      visible={isModalVisible}
      footer={[<OkButton tabIndex={tabIndex} aria-label={ariaLabel} role={role} onKeyPress={() => setModalVisible(false)} key='ok-btn' onClick={() => setModalVisible(false)}>{t(jsonData.Ok)}</OkButton>]}
    >
      <div className="permissionRoleModal">
        <Primarytext>{t(jsonData.PermissionWarn)}</Primarytext>
      </div>
    </Modal>
  );
};

export default PermissionModal;
