import React, { useState, useEffect } from "react";
import { useDispatch,  } from 'react-redux';
import {
  ssoLogoutTrigger
} from '../../../../../redux/reducers/app/appReducers'

// const dispatch = useDispatch();

export const Logout=(props:any)=>{
  const dispatch = useDispatch();
    dispatch(ssoLogoutTrigger())
    return(<></>)
}