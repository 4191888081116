/* eslint-disable @typescript-eslint/no-unused-vars */
import secureLocalStorage from "react-secure-storage";
import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { isValidTimeZone, replaceAll } from "../../../utility/appUtil";
import { APIResponseError } from "../../constants/enums";
import { Messages } from "../../constants/messages";
import { Location } from "../../models/common/location";
import { Organisation } from "../../models/organisation/organisation";
// import User from "../../models/user/user";
// import { UserAuthInput } from "../../models/user/userAuthInput";

export default class OrganisationService extends BaseService {
    // public _data: any[] = [];

    constructor() {
        super(getAPIBaseUrl(PortalModule.ORGANIZATION));
    }

    async fetchOrganisations(currentState: any): Promise<Array<Organisation>> {
        const params: any = { type: 'ORG' };

        if (currentState.search) {
            params['search'] = currentState.search;
        }
        // if (currentState.limit) {
        //     params['limit'] = currentState.limit;
        // }
        if (currentState.page) {
            params['page'] = currentState.page;
        }
        if (currentState.country) {
            params['country'] = currentState.country;
        }
        if (currentState.region && 'All Regions' !== currentState.region) {
            params['region'] = currentState.region;
        }
        const response = await this.get("api/v1/hierarchy", { params });
        if (response.data.result.rows) {
            return response.data.result.rows.map((organisation: any) => {
                return {
                    id: organisation.id,
                    shortName: organisation.shortName,
                    name: organisation.name,
                    county: organisation.county,
                    country: organisation.country,
                    logo: organisation.info.logo,
                };
            });
        }
        return [];
    }

    // organisation list transformation
    async fetchTransformedOrganisationsList(currentState: any): Promise<Array<Organisation>> {
        const params: any = { type: 'ORG' };

        if (currentState.search) {
            params['search'] = currentState.search;
        }
        if (currentState.page) {
            params['page'] = currentState.page;
        }
        if (currentState.country) {
            params['country'] = currentState.country;
        }
        if (currentState.region && 'All Regions' !== currentState.region) {
            params['region'] = currentState.region;
        }
        const response = await this.get("api/v1/organisation/list", { params });
        if (response.data.result) {
            return response.data.result.map((organisation: any) => {
                return {
                    id: organisation.id,
                    shortName: organisation.shortName,
                    name: organisation.name,
                    county: organisation.county,
                    country: organisation.country,
                    logo: organisation.info.logo,
                };
            });
        }
        return [];
    }

    async fetchOrganisationbyID(id: string): Promise<Organisation> {
        try {
            const response = await this.get(`api/v1/hierarchy/${id}`);
            let locale = JSON.parse(response.data.result.locale)
            let timezone = response.data.result.info?.timezone

            locale.timezone = isValidTimeZone(timezone) ? timezone : 'Europe/London'
            
            secureLocalStorage.setItem('locale', locale)

            return Promise.resolve(response.data);
        }
        catch (e) {
            return Promise.reject(e);
        }
    }

    // organisation summary transformation
    async fetchOrganisationSummary(id: string): Promise<Organisation> {
        try {
            const response = await this.get(`api/v1/organisation/details/${id}`);
            return Promise.resolve(response.data);
        }
        catch (e) {
            return Promise.reject(e);
        }
    }

    // account setting general tab transformation -> fetchOrganisationbyID
    async fetchAccountSetting(id: string): Promise<Organisation> {
        try {
            const response = await this.get(`api/v1/accountSetting/${id}`);
            return Promise.resolve(response.data);
        }
        catch (e) {
            return Promise.reject(e);
        }
    }

    // Settings > general setting transformation -> fetchOrganisationbyID
    async fetchGeneralSetting(id: string): Promise<Organisation> {
        try {
            const response = await this.get(`api/v1/generalSettings/${id}`);
            return Promise.resolve(response.data);
        }
        catch (e) {
            return Promise.reject(e);
        }
    }

    async fetchResourcesCount(id: string): Promise<any> {
        // const response = await this.get(`hierarchy/${id}`);
        // return response.data;
        return {
            locations: 2,
            users: 3,
            devices: 10
        };
    }

    async addOrganisation(payload: any): Promise<any> {
        try {
            const response = await this.post(`api/v1/hierarchy`, payload);
            if (response?.data?.error && response?.data?.error?.errorType) {
                let errorMsg = "";
                switch (response?.data?.error?.errorType) {
                    case APIResponseError.ALREADY_EXISTS:
                        errorMsg = replaceAll(/\{0\}/gi, (payload.t(Messages.HIERARCHY_ALREADY_EXISTS)), payload?.name);
                        response.data.display = errorMsg;
                        return response;
                }
                return Promise.reject(response)
            }
            else if (response?.data?.errors){
                return Promise.reject(response)
            }
            return Promise.resolve(response)
        }
        catch (e) {
            return Promise.reject(e);
        }
    }

    async updateOrganisation(id: string, payload: any): Promise<any> {
        const response = await this.patch(`api/v1/hierarchy/${id}`, payload);
        if (response?.data?.error && response?.data?.error?.errorType) {
            let errorMsg = "";
            switch (response?.data?.error?.errorType) {
                case APIResponseError.ALREADY_EXISTS:
                    errorMsg = replaceAll(/\{0\}/gi, Messages.HIERARCHY_ALREADY_EXISTS, payload?.name);
                    response.data.display = errorMsg;
                    return response;
            }
            return Promise.reject(response)
        }
        else if (response?.data?.errors){
            return Promise.reject(response)
        }
        return response;
    }

    async fetchLocationsCount(id: string): Promise<any> {
        const params: any = { depth: 1 };
        const response = await this.get(`api/v1/hierarchy/${id}`, { params });

        if (response.data.result.children.length) {
            return response.data.result.children.length;
        }

        return 0;
    }

    // public recursive(obj: any): any {
    //     if (!obj.children.length) {
    //         return;
    //     }
    //     this._data.push(obj);
    //     return this.recursive(obj.children[0]);
    //     // return obj.children.length ? this.recursive(obj.children[0]) : obj;
    // }

    async fetchDepthByOrgID(id: string, depth?: number): Promise<any> {

        const params: any = depth ? { depth: depth } : { depth: 0 };
        const response = await this.get(`api/v1/hierarchy/${id}`, { params });
        // if (response.data.result) {
        //     this.recursive(response.data.result);
        // }
        // return this._data;
        return response.data.result;

    }

    // locations transformation 
    async fetchTransformedLocations(id: string, depth?: number): Promise<any> {
        const params: any = depth ? { depth: depth } : { depth: 0 };
        const response = await this.get(`api/v1/location/${id}`, { params });
        return response.data.result;
    }

    //debug
    // async fetchLocations(id: string): Promise<Array<Location> | null> {
    //     const params: any = { depth: 1 };
    //     const response = await this.get(`api/api/v1/hierarchy/${id}`, { params });

    //     if (response.data.result.children) {
    //         if (response.data.result.children.length) {
    //             return response.data.result.children.map((x: any) => {
    //                 const location: Location = {
    //                     id: x.id,
    //                     name: x.name,
    //                     shortName: x.shortName,
    //                 }
    //                 return location;
    //             });
    //         } else {
    //             return [];
    //         }
    //     }

    //     return null;
    // }

    // transformation for hierarchy dropdown
    async fetchTransformedLocationList(id: string): Promise<Array<Location> | null> {
        const params: any = { 
            organisationId: id,
        };
        const response = await this.get(`api/v1/location`, { params });

        if (response.data.result.children) {
            if (response.data.result.children.length) {
                return response.data.result.children.map((x: any) => {
                    const location: Location = {
                        id: x.id,
                        name: x.name,
                    }
                    return location;
                });
            } else {
                return [];
            }
        }

        return null;
    }

    async fetchUsers(id: string): Promise<any> {
        const params: any = { organization: id };
        const response = await this.get(`api/v1/user`, { params });

        if (response.data.result.rows.length) {
            return response.data.result.rows.length;
        }

        return 0;
    }

    async fetchDevices(id: string): Promise<any> {
        const params: any = { organisationId: id };
        const response = await this.get(`v1/device`, { params });

        if (response.data.result.message.rows.length) {
            return response.data.result.message.rows.length;
        }

        return 0;
    }

    async toggleStatus(payload: any): Promise<any> {
        const { id, isActive } = payload;
        const { data } = await this.patch(`api/v1/hierarchy/${id}/deactivate`, payload);
        if (data) {
            return data.result;
        }
    }

    async deleteOrganisation(id: string, hierarchyTypeCode = ''): Promise<any> {
        const params: any = { hierarchyTypeCode };
        // const response = await this.delete(`api/api/v1/hierarchy/${id}`, { params });
        const response = await this.delete(`api/v1/hierarchy/${id}`);
        if (response) {
            return response;
        }
    }

    async relocateAndDeleteLocation(id: string, replacementHierarchyId: string): Promise<any> {
        const response = await this.delete(`api/v1/hierarchy/${id}`, {
            data: {
                replacementHierarchyId: replacementHierarchyId
            }
        });
        if (response) {
            return response;
        }
    }
}
