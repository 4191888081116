//package imports
import React from 'react';
import { Col, Row, Tooltip } from 'antd';

// style imports
import { RolesHeaderLeft } from './rolesHeader.styles';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '../../../../../constants/enums';
import { userStateSelector } from '../../../../../../redux/reducers/user/userReducer';
import { useSelector } from 'react-redux';
import { getUserRoles, urlModification } from '../../../../../../utility/appUtil';
import { UserRole } from '../../../../../constants/enums';
import '../rolesRow/rolesListRow.css'
import { Button } from '../../../../../../bit_components/common/button';
import { useTranslation } from 'react-i18next';
import { jsonData } from '../../../../../constants/constants';

export const RolesListHeader = (props: any) => {
  const history = useHistory();
  const { appUser } = useSelector(userStateSelector);
  let role: any;
  if (appUser) role = getUserRoles(appUser);
  const { t } = useTranslation()

  const isRoleCRUDAllowed = () => {
    if (role.includes(UserRole.ORGANISATION_ADMIN) || role.includes(UserRole.PLATFORM_ADMIN)) return true;
    return false
  }

  const handleCreateRole = () => {
    history.push(urlModification(AppRoutes.ADD_ROLES));
  }

  return (
    <Row className="pl-vw pb-17 pt-2 roleHeader">
      <Col onKeyPress={handleCreateRole}>
        <RolesHeaderLeft>{t(jsonData.Roles)}</RolesHeaderLeft>
        <div className='rolesBtnWrapper'>
        {isRoleCRUDAllowed() ?
        <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.CreateCustomRole)}>
          <div>
          <Button type="actionButton" ariaLabel={`${jsonData.Create} ${jsonData.Custom} ${jsonData.Role}`} onClick={handleCreateRole} >
            + {t(jsonData.CreateCustomRole)}
          </Button>
          </div>
          </Tooltip>
          : null}
        </div>
      </Col>
    </Row>
  );
};