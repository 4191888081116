// Base Imports
import React from "react";
import ReactDOM from "react-dom";

// Package Imports
import "antd/dist/antd.css";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

// Other Imports
import "./index.css";
import App from "./web/app";
import store from "./redux/store";
import * as serviceWorker from "./web/serviceWorker";
// import { GetProjectTheme } from "./web/themes/themes";
import { GetProjectTheme } from "./bit_components/styles/theme";
import "./bit_components/styles/theme/global.less";

// import i18n (needs to be bundled ;)) 
import './i18n';

let persistor = persistStore(store);
ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={GetProjectTheme()}>
        <App store={store} />
      </ThemeProvider>
    </PersistGate>
  </Provider>, // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
