/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal } from "../../../../../bit_components/ui/modal";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Primarytext, SheduleModalDivSection } from "./index.styles";
import {
  Switch,
  Form,
  TimePicker,
  Select,
  Input,
  Space,
  Button,
  Tooltip,
} from "antd";
import { Dropdown } from "../../../../../bit_components/common/dropdown";
import {
  scheduleDeleteWarnModal,
  scheduleFrequency,
  weekSchedule,
  SchedulePickerFormat,
  ScheduleDefaultTime,
  jsonData,
  scheduleExport,
} from "../../../../constants/constants";
import moment from "moment-timezone";
import { DeleteIconImg } from "../../../../images";
import "antd/dist/antd.css";
import { userListSelector } from "../../../../../redux/reducers/user/userListReducer";
import {
  clearDeleteScheduleFormState,
  clearScheduleFormState,
  clearScheduleUpdateFormstate,
  clearViewDataValues,
  insightsSelector,
} from "../../../../../redux/reducers/insights/insightsReducer";
import {
  postMyScheduleReport,
  fetchMyReport,
  updateMyScheduleReport,
  deleteSchedule,
} from "../../../../../redux/actions/insights/insightsAction";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { AppService } from "../../../../services/ui/appService";
import WarnModal from "../../../stateless/common/warnModal";
import {
  cameFromOrganisation,
  convert_DateWithTimezone_To_UTC,
  getOrganizationDateAndTime,
  translateOptions,
} from "../../../../../utility/appUtil";
import { appSelector } from "../../../../../redux/reducers/app/appReducers";
import { PlusOutlined } from "@ant-design/icons";
import { AppError } from "../../../stateless/common/appError";
import { Messages } from "../../../../constants/messages";
import { clearSavedFormValues } from "../../../../../redux/reducers/common/commonReducer";
import { useTranslation } from "react-i18next";
import { UseGAEventTracker } from "../../../../../utility/useGAEventTracker";
import secureLocalStorage from "react-secure-storage";

export const ScheduleModal = (props: any) => {
  const { isModalVisible, setModalVisible, editable, setMenuClicked } = props;
  const { selectedOrganisation } = useSelector(appSelector);
  const {
    viewDataValues,
    scheduleFormState,
    deleteScheduleFormState,
    UpdateScheduleFormState,
  } = useSelector(insightsSelector);
  const { users } = useSelector(userListSelector);
  const { appUser } = useSelector(userStateSelector);
  const [warnCancelBtn, setWarnCancelBtn] = useState("");
  const [warnConfirmBtn, setWarnConfirmBtn] = useState("");
  const [warnTitle, setWarnTitle] = useState("");
  const [warnContent, setWarnContent] = useState<any>("");
  const [showWarn, setShowWarn] = useState(false);
  const [toggle, settoggle] = useState<boolean>(true);
  const [ferquencyValue, setFrequencyValue] = useState<string>();
  const [deliveryDate, setDeliveryDate] = useState<any>();
  const [customTime, setCustomTime] = useState<any>(null);
  const [items, setItems] = useState<any>();
  const [disable, setDisable] = useState<boolean>(true);
  const [email, setEmail] = useState("");
  const [form] = Form.useForm();
  const [addEmailForm] = Form.useForm();
  const [selectedEmail, setSelectedEmail] = useState<string[]>([
    appUser?.email,
  ]);
  const { Option } = Select;
  const [userList, setUserList] = useState<string[]>();
  const [filteredList, setFilteredList] = useState<any>([]);
  const [array, setArray] = useState<any>([]);
  const [updatedParams, setUpdatedParams] = useState<any>();
  const [emailDisable, setEmailDisable] = useState<boolean>(false);
  const [exportFormat, setExportFormat] = useState<any>("grouped");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let localeInfo:any = secureLocalStorage.getItem('locale')
  moment.tz.setDefault(localeInfo.timezone);

  useEffect(() => {
    setArray([...new Set([...filteredList, ...selectedEmail])]);
  }, [isModalVisible, selectedEmail, filteredList]);

  let time = "";
  const getScheduleTime = () => {
    let date = viewDataValues.reportScheduling?.time.split(":");
    time = date[0] + ":" + date[1];
    return time;
  };

  if (viewDataValues.reportScheduling?.time) {
    time = getScheduleTime();
  }

  let yearandmonth, day;
  if (viewDataValues?.reportScheduling?.frequency === "0") {
    yearandmonth = moment().utc().format("YYYY-MM");
    day = parseInt(moment().format("DD"));
  } else if (viewDataValues?.reportScheduling?.frequency === "1") {
    let date = moment()
      .day(viewDataValues?.reportScheduling?.deliveryDate - 1)
      .toString();
    yearandmonth = moment(date).format("YYYY-MM");
    day = parseInt(moment(date).format("DD"));
  } else if (viewDataValues?.reportScheduling?.frequency === "2") {
    yearandmonth = moment().utc().format("YYYY-MM");
    day = viewDataValues?.reportScheduling?.deliveryDate;
  }
  // let dateAndTime = getOrganizationDateAndTime(moment.utc(yearandmonth + "-" + day +" " + viewDataValues?.reportScheduling?.time ).format())
  let dateAndTime;
  if (viewDataValues?.reportScheduling !== null) {
    dateAndTime = getOrganizationDateAndTime(
      moment.utc(viewDataValues?.reportScheduling?.nextRun).format()
    );
  } else {
    dateAndTime = getOrganizationDateAndTime(
      moment
        .utc(
          yearandmonth +
            "-" +
            day +
            " " +
            viewDataValues?.reportScheduling?.time
        )
        .format()
    );
  }
  const timecal = viewDataValues.reportScheduling?.time
    ? moment(
        moment(dateAndTime).format(SchedulePickerFormat.TIME),
        SchedulePickerFormat.TIME
      )
    : null;
  let DeliveryDate;
  if (viewDataValues?.reportScheduling?.frequency === "2") {
    DeliveryDate = parseInt(moment(dateAndTime).format("D"));
  } else if (viewDataValues?.reportScheduling?.frequency === "1") {
    DeliveryDate = moment(dateAndTime).day() + 1;
  } else {
    DeliveryDate = null;
  }

  const isEmailListEqual = (initialEmailList: any, UpdatedEmailList: any) => {
    if (editable) {
      if (initialEmailList?.length !== UpdatedEmailList?.length) {
        return false;
      }
      let newMap = new Map();
      for (let item of initialEmailList) {
        newMap.set(item, true);
      }
      for (let item of UpdatedEmailList) {
        let exist = newMap.get(item);
        if (!exist) {
          return false;
        }
      }
      return true;
    }
  };

  let initialParams = {
    frequency: viewDataValues.reportScheduling?.frequency,
    deliveryDate: DeliveryDate,
    time: viewDataValues.reportScheduling?.time
      ? moment(timecal).format("HH:mm")
      : null,
    isActive: viewDataValues.reportScheduling?.isActive,
  };

  useEffect(() => {
    setUpdatedParams(initialParams);
  }, [viewDataValues]);

  const onSavingSchedule = (e: any) => {
    if (e.target.name === "frequency" && e.target.value === "0") {
      setUpdatedParams({
        ...updatedParams,
        deliveryDate: null,
        [e.target.name]: e.target.value,
      });
    } else {
      setUpdatedParams({ ...updatedParams, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    ((editable &&
      isEmailListEqual(
        viewDataValues.reportScheduling?.distributionList,
        selectedEmail
      )) ||
      ferquencyValue === "" ||
      ferquencyValue === undefined ||
      selectedEmail?.length === 0 ||
      selectedEmail?.length === undefined ||
      customTime === null ||
      customTime === undefined ||
      deliveryDate === "" ||
      (ferquencyValue !== "0" && deliveryDate === null)) &&
    (JSON.stringify(initialParams) === JSON.stringify(updatedParams) ||
      ferquencyValue === "" ||
      ferquencyValue === undefined ||
      selectedEmail?.length === 0 ||
      selectedEmail?.length === undefined ||
      customTime === null ||
      customTime === undefined ||
      deliveryDate === "" ||
      (ferquencyValue !== "0" && deliveryDate === null))
      ? setDisable(true)
      : setDisable(false);
  }, [viewDataValues, selectedEmail, updatedParams]);

  const onSelectEmail = (event: any) => {
    setSelectedEmail(event);
  };
  useEffect(() => {
    if (users) {
      const emailDropdown = users.map((item) => item?.email);
      setUserList(emailDropdown);
      setItems(emailDropdown);
    }
  }, [users]);

  const handleCancel = () => {
    setMenuClicked(false);
    setModalVisible(false);
    setItems(userList);
    addEmailForm.resetFields();
    form.resetFields();
    dispatch(clearSavedFormValues());
    dispatch(clearViewDataValues());
    settoggle(true);
    setCustomTime(null);
    setFrequencyValue(null);
    setSelectedEmail([appUser?.email]);
    setDeliveryDate(null);
  };
  const getOrgId = () => {
    let orgId = appUser?.organization.id as string;
    if (cameFromOrganisation()) {
      orgId = selectedOrganisation?.id;
    }
    return orgId;
  };
  useEffect(() => {
    if (scheduleFormState.isSuccess) {
      AppService.showToast(t(Messages.SCHEDULE_SAVED));
      dispatch(clearScheduleFormState());
      dispatch(clearSavedFormValues());
      dispatch(
        fetchMyReport({ organisationId: getOrgId(), userId: appUser?.id })
      );
    }
  }, [scheduleFormState?.isSuccess]);

  useEffect(() => {
    if (UpdateScheduleFormState?.isSuccess) {
      AppService.showToast(t(Messages.SCHEDULE_UPDATE));
      dispatch(clearScheduleUpdateFormstate());
      dispatch(
        fetchMyReport({ organisationId: getOrgId(), userId: appUser?.id })
      );
    }
  }, [UpdateScheduleFormState?.isSuccess]);

  const getScheduleUtcTime = () => {
    let dateAndTime;
    if (ferquencyValue === "0") {
      dateAndTime = convert_DateWithTimezone_To_UTC(
        moment().format("YYYY-MM-DD") + " " + customTime
      );
    } else if (ferquencyValue === "1") {
      let date = moment(
        moment()
          .day(deliveryDate - 1)
          .toString()
      ).format("YYYY-MM-DD");
      dateAndTime = convert_DateWithTimezone_To_UTC(date + " " + customTime);
    } else {
      let yearandmonth = moment().format("YYYY-MM");
      dateAndTime = convert_DateWithTimezone_To_UTC(
        yearandmonth + "-" + deliveryDate + " " + customTime
      );
    }
    return dateAndTime;
  };

  const onCreateScheduleReport = () => {
    let UTC = getScheduleUtcTime();
    let deliveryDate;
    if (ferquencyValue === "2") {
      deliveryDate = parseInt(moment.utc(UTC).format("DD"));
    } else if (ferquencyValue === "1") {
      deliveryDate = moment.utc(UTC).day() + 1;
    } else {
      deliveryDate = null;
    }
    setMenuClicked(false);
    // email [] =all emails except who create
    let filterArray = [];
    selectedEmail.forEach((item: any) => {
      if (item !== appUser.email) {
        filterArray.push(item);
      }
      return filterArray;
    });
    let scheduleReportData: any = {
      frequency: ferquencyValue,
      distributionList: filterArray,
      deliveryDate: deliveryDate,
      time: moment.utc(UTC).format("HH:mm"),
      createdBy: appUser?.id,
      updatedBy: appUser?.id,
      myReportId: viewDataValues?.id,
      format: "excel",
      isActive: toggle,
      exportFormat: exportFormat,
    };
    if (editable) {
      dispatch(
        updateMyScheduleReport({
          ...scheduleReportData,
          id: viewDataValues?.reportSchedulingId,
          distributionList: selectedEmail,
        })
      );
      UseGAEventTracker(
        "Insights Category",
        "schedule my insights",
        `Updated schedule for my insights report`
      );
    } else {
      dispatch(postMyScheduleReport(scheduleReportData));
      UseGAEventTracker(
        "Insights Category",
        "schedule my insights",
        `Created schedule for my insights report`
      );
    }
    setModalVisible(false);
  };
  const handleToggle = (value: any) => {
    settoggle(value);
    onSavingSchedule({
      target: {
        name: "isActive",
        value: value,
      },
    });
  };
  const onSelectFrequency = (value: any) => {
    setFrequencyValue(value);
    setDeliveryDate(null);
    onSavingSchedule({
      target: {
        name: "frequency",
        value: value,
      },
    });
    form.setFieldsValue({
      DeliveryDate: "",
    });
  };

  const onSelectExport = (value: any) => {
    setExportFormat(value);
    form.setFieldsValue({
      exportFormat: value,
    });
    onSavingSchedule({
      target: {
        name: "exportFormat",
        value: value,
      },
    });
  };

  const onDeliveryDate = (value: any) => {
    setDeliveryDate(value);
    onSavingSchedule({
      target: {
        name: "deliveryDate",
        value: value,
      },
    });
  };

  const handleTimepicker = (evt: any, starttime: any) => {
    starttime?.length ? setCustomTime(starttime) : setCustomTime(null);
    onSavingSchedule({
      target: {
        name: "time",
        value: starttime,
      },
    });
  };

  const DropDownValue = () => {
    if (ferquencyValue === "1") {
      return weekSchedule;
    } else if (ferquencyValue === "2") {
      let list = [];
      for (let i = 1; i <= 31; i++) {
        list.push({
          text: i,
          value: i,
        });
      }
      return list;
    } else return [];
  };

  const onFocus = () => {
    setEmailDisable(true);
  };
  const onBlur = () => {
    setEmailDisable(false);
  };

  const onSelectingEmail = (event: any) => {
    setEmail(event.target.value.toLowerCase());
  };
  const addEmail = () => {
    setItems([...items, email]);
    addEmailForm.resetFields();
  };
  useEffect(() => {
    if (items) {
      var filteredArr = items.filter(function (item, index) {
        if (items.indexOf(item) === index) return item;
      });
      setFilteredList(filteredArr);
    }
  }, [items]);

  const onDeleteScheduleReport = () => {
    setMenuClicked(false);
    setShowWarn(true);
    setWarnCancelBtn(t(scheduleDeleteWarnModal.CANCEL_BTN_TEXT));
    setWarnConfirmBtn(t(scheduleDeleteWarnModal.CONFIRM_BTN_TEXT));
    setWarnTitle(t(scheduleDeleteWarnModal.TITLE));
    setWarnContent(t(scheduleDeleteWarnModal.CONTENT));
  };

  const onWarnModalCallback = () => {
    setShowWarn(false);
    dispatch(deleteSchedule(viewDataValues?.reportSchedulingId));
  };

  useEffect(() => {
    if (deleteScheduleFormState?.isError) {
      AppService.showToast(t(Messages.ERROR_OCCURRED));
    } else if (deleteScheduleFormState?.isSuccess) {
      setModalVisible(false);
      settoggle(true);
      setFilteredList(userList);
      AppService.showToast(t(Messages.SCHEDULE_DELETED));
      dispatch(clearDeleteScheduleFormState());
      dispatch(
        fetchMyReport({ organisationId: getOrgId(), userId: appUser?.id })
      );
    }
  }, [deleteScheduleFormState]);

  useEffect(() => {
    if (editable === true) {
      let yearandmonth, day;
      if (viewDataValues?.reportScheduling?.frequency === "0") {
        yearandmonth = moment().utc().format("YYYY-MM");
        day = parseInt(moment().format("DD"));
      } else if (viewDataValues?.reportScheduling?.frequency === "1") {
        let date = moment()
          .day(viewDataValues?.reportScheduling?.deliveryDate - 1)
          .toString();
        yearandmonth = moment(date).format("YYYY-MM");
        day = parseInt(moment(date).format("DD"));
      } else {
        yearandmonth = moment().utc().format("YYYY-MM");
        day = viewDataValues?.reportScheduling?.deliveryDate;
      }
      // let dateAndTime = getOrganizationDateAndTime(moment.utc(yearandmonth + "-" + day +" " + viewDataValues?.reportScheduling?.time ).format())
      let dateAndTime;
      if (viewDataValues?.reportScheduling !== null) {
        dateAndTime = getOrganizationDateAndTime(
          moment.utc(viewDataValues?.reportScheduling?.nextRun).format()
        );
      } else {
        dateAndTime = getOrganizationDateAndTime(
          moment
            .utc(
              yearandmonth +
                "-" +
                day +
                " " +
                viewDataValues?.reportScheduling?.time
            )
            .format()
        );
      }

      // const timecal = viewDataValues.reportScheduling?.time? moment(moment(dateAndTime).format(SchedulePickerFormat.TIME) , SchedulePickerFormat.TIME) : null
      const timecal = viewDataValues.reportScheduling?.time
        ? moment(moment(dateAndTime).format())
        : null;
      let DeliveryDate;
      if (viewDataValues?.reportScheduling?.frequency === "2") {
        DeliveryDate = moment(dateAndTime).format("D");
      } else if (viewDataValues?.reportScheduling?.frequency === "1") {
        DeliveryDate = moment(dateAndTime).day() + 1;
      } else {
        DeliveryDate = null;
      }
      form.setFieldsValue({
        Enable: viewDataValues?.reportScheduling?.isActive,
        Email: viewDataValues?.reportScheduling?.distributionList,
        Frequency: viewDataValues?.reportScheduling?.frequency,
        ApproxTime: timecal,
        DeliveryDate: DeliveryDate,
        exportFormat: viewDataValues?.filters?.exportFormat
          ? viewDataValues?.filters?.exportFormat
          : "grouped",
      });
      (viewDataValues?.reportScheduling?.isActive == undefined || null) ? settoggle(true) : settoggle(viewDataValues?.reportScheduling?.isActive);
      setCustomTime(moment(timecal).format("HH:mm"));
      setFrequencyValue(viewDataValues?.reportScheduling?.frequency);
      setSelectedEmail(
        viewDataValues?.reportScheduling?.distributionList ?? [appUser?.email]
      );
      setDeliveryDate(
        viewDataValues?.reportScheduling?.frequency !== "0"
          ? DeliveryDate
          : null
      );
      //  exportFormat((viewDataValues?.filters?.exportFormat !== undefined )? viewDataValues?.filters?.exportFormat : 'grouped')
    } else {
      settoggle(true)
      form.resetFields();
    }
  }, [editable, isModalVisible, viewDataValues]);

  const handelDeleByTab = (e: any) => {
    if (e?.keyCode === 13) {
      onDeleteScheduleReport();
    }
  };

  return (
    <>
      <Modal
        handleCancel={handleCancel}
        handleOk={onCreateScheduleReport}
        okText={editable === true ? `${jsonData.Save}` : "Create"}
        primaryButtonDisabled={disable}
        cancelText="Cancel"
        isVisible={isModalVisible}
      >
        <WarnModal
          cancelButton={warnCancelBtn}
          confirmButton={warnConfirmBtn}
          primaryText={warnTitle}
          secondaryText={warnContent}
          isModalVisible={showWarn}
          cancelCallback={() => {
            setShowWarn(false);
          }}
          confirmCallback={onWarnModalCallback}
        />
        <SheduleModalDivSection>
          <Form form={form} onChange={onSavingSchedule}>
            <Primarytext>
              <div className="myinsighttableColNameStyle">
                <Tooltip
                  overlayClassName="dashBoardTooltip"
                  placement="bottom"
                  title={`Schedule${viewDataValues?.name}`}
                >
                  {t(jsonData.Schedule)} {viewDataValues?.name}
                </Tooltip>
              </div>
              <div
                className="tooltipWrapper"
                tabIndex={0}
                onKeyUp={handelDeleByTab}
              >
                <Tooltip
                  placement="bottomRight"
                  title={t(jsonData.DeleteSchedule)}
                >
                  {editable === true ? (
                    <img
                      className="ScheduleDeleteIcon"
                      src={DeleteIconImg}
                      alt=""
                      onClick={() => {
                        onDeleteScheduleReport();
                      }}
                      onMouseOut={(e) => (e.currentTarget.src = DeleteIconImg)}
                    />
                  ) : null}
                </Tooltip>
              </div>
            </Primarytext>
            <Form.Item
              label={t(jsonData.Enabled)}
              name="Enable"
              className="switchScheduleForm"
            >
              <Switch
                className={`${
                  toggle ? "scheduleToggleCheck" : "scheduleToggleOff"
                } ScheduleToggleSwitch `}
                checked={toggle}
                onChange={handleToggle}
              ></Switch>
            </Form.Item>
            <Form.Item
              className="myInsightsDropdown"
              label={t(jsonData.Email)}
              name="Email"
              rules={[{ required: true, message: t(jsonData.EmailRequired) }]}
            >
              <Select
                tabIndex={0}
                getPopupContainer={(trigger) => trigger.parentNode}
                className="scheduleDropdown"
                mode="multiple"
                placeholder={t(jsonData.SelectEmail)}
                notFoundContent={jsonData.NoDataFound}
                value={selectedEmail}
                defaultValue={[appUser?.email]}
                onChange={onSelectEmail}
                dropdownRender={(menu) => (
                  <>
                    {" "}
                    {menu}
                    <Space className="optionMenu">
                      <Form
                        form={addEmailForm}
                        onFinish={addEmail}
                        className="addEmailBtn"
                      >
                        <Form.Item
                          initialValue={""}
                          name={"email"}
                          rules={[
                            {
                              type: "email",
                              message: t(jsonData.PleaseInputValidEmail),
                            },
                          ]}
                        >
                          <Input
                            className="addEmail"
                            placeholder={t(jsonData.Addotheremail)}
                            name="email"
                            value={email}
                            onChange={onSelectingEmail}
                            disabled={false}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            tabIndex={0}
                          />
                        </Form.Item>
                        <Button
                          className="addFormButton"
                          htmlType="submit"
                          icon={<PlusOutlined />}
                          tabIndex={0}
                        />
                      </Form>
                      <AppError show={false} errorMsg={""} />
                    </Space>
                  </>
                )}
              >
                {array?.map((item) => (
                  <Option
                    disabled={item === appUser?.email || emailDisable}
                    value={item}
                    key={item}
                  >
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="schedule_dropDown"
              label={t(jsonData.Frequency)}
              name="Frequency"
              initialValue={null}
              rules={[
                { required: true, message: t(jsonData.FrequencyIsRequired) },
              ]}
            >
              <Dropdown
                tabIndex={0}
                className="frequencyDropdown"
                optionValue={translateOptions(scheduleFrequency, t, "text")}
                onChange={onSelectFrequency}
              ></Dropdown>
            </Form.Item>
            {ferquencyValue !== "0" &&
            ferquencyValue !== undefined &&
            ferquencyValue !== null ? (
              <Form.Item
                className="schedule_dropDown"
                label={t(jsonData.DeliveryDate)}
                name="DeliveryDate"
                initialValue={null}
                rules={[
                  { required: true, message: t(jsonData.DeliveryDateRequired) },
                ]}
              >
                <Dropdown
                  tabIndex={0}
                  value={deliveryDate}
                  optionValue={DropDownValue()}
                  onChange={onDeliveryDate}
                ></Dropdown>
              </Form.Item>
            ) : null}

            <Form.Item
              className="schedule_dropDown"
              label={t(jsonData.Export)}
              name="exportFormat"
              initialValue={null}
              rules={[
                { required: true, message: t(jsonData.FrequencyIsRequired) },
              ]}
            >
              <Dropdown
                tabIndex={0}
                className="exportDropdown"
                optionValue={translateOptions(scheduleExport, t, "text")}
                onChange={onSelectExport}
              ></Dropdown>
            </Form.Item>

            <Form.Item
              className="schedule_dropDown"
              label={t(jsonData.ApproxTime)}
              name="ApproxTime"
              initialValue={null}
              rules={[
                { required: true, message: t(jsonData.ApproxTimeRequired) },
              ]}
            >
              <TimePicker
                placeholder={ScheduleDefaultTime.START}
                onChange={handleTimepicker}
                format={SchedulePickerFormat.TIME}
                className="ScheduletimePickerStyl"
                value={
                  customTime
                    ? moment(customTime, SchedulePickerFormat.TIME)
                    : null
                }
              />
            </Form.Item>
          </Form>
        </SheduleModalDivSection>
      </Modal>
    </>
  );
};
