import React from 'react'
import { Layout, Row, Col } from 'antd'
import { AppHeader } from '../common/appHeader'
import { SideNav } from '../common/sideNav';
import { StyledContent, StyledLayout, StyledSider, SiderRow } from './mainLayout.styles';
const { Header } = Layout

const MainLayout = (props: any) => {
  const { children, logoutCallback } = props;
  return (
    <StyledLayout >
      <Row>
        <Col span={24}>
          <Header >
            <AppHeader {...props} logoutCallback={logoutCallback} />
          </Header>
        </Col>
      </Row>
      <SiderRow>
        <Col span={6} lg={4}>
          <StyledSider>
            {<SideNav {...props} />}
          </StyledSider>
        </Col>
        <Col span={18} lg={20}>
          <StyledContent
          >
            {children}
          </StyledContent>
        </Col>
      </SiderRow>
    </StyledLayout>
  )
}

export default MainLayout
