/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Form } from 'antd'
import { UserAuthInput } from '../../../../../models/user/userAuthInput'
import AuthLayout from '../../../layouts/authLayout'
import { LoginContainer } from './authenticatePwdForm.Styles'
import '../../../../../components/stateless/common/button/button.less'
import './authenticatePwdForm.less'
import { Link } from 'react-router-dom'
import { BackIconImg } from '../../../../../images'
import { AppError } from '../../../../stateless/common/appError'
import CustomPassword from '../../../common/inputField/customPassword'
import {Button} from '../../../../../../bit_components/common/button'
import { useHistory } from "react-router-dom";
import { urlModification } from '../../../../../../utility/appUtil'
import { AppRoutes } from '../../../../../constants/enums'
import { LoginHead, LoginText } from '../emailLoginForm/emailLoginForm.Styles'
import { LoginBackButton, AllignCenter } from './authenticatePwdForm.Styles'

export const AuthenticatePwdForm = (props: any) => {
  const history = useHistory();
  const {
    formLayout,
    formState,
    appUser,
    authenticatePwdCallback,
    forgotPwdCallback,
    goBackCallback,
  } = props

  useEffect(() => {
    const node = document.getElementById('auth_password');
    node?.focus();
  })

  const username = appUser ? appUser.email : ''
  if(appUser?.email === null || appUser?.email === undefined){
    history.push(urlModification(AppRoutes.VALIDATE_EMAIL));
  }
  const onGoBack = () => {
    if (goBackCallback) {
      goBackCallback()
    }
  }

  const onForgotPwd = () => {
    if (forgotPwdCallback) {
      forgotPwdCallback()
    }
  }

  const onSubmit = (data: any) => {
    //debugger;
    if (authenticatePwdCallback) {
      const userAuthInput: UserAuthInput = {
        id: appUser.id,
        email: appUser.email,
        password: data.password,
      }
      authenticatePwdCallback(userAuthInput)
    }
  }

  return (
    <AuthLayout>
      <LoginContainer className='passwordContainer'>
        <AllignCenter onClick={onGoBack}>
          <img className='backImg' src={BackIconImg} alt='back'></img>
          <LoginBackButton>Back</LoginBackButton>
        </AllignCenter>
        <LoginHead>Welcome</LoginHead>
        <LoginText>{username ? username : ''}</LoginText>
        <Form
          {...formLayout}
          name='auth'
          className='login-form'
          initialValues={{
            remember: true,
          }}
          onFinish={onSubmit}
          requiredMark={false}
        >
          <CustomPassword
            label='Password'
            customLabelClass={`loginLabelName passwordWrapper mb-0 ${formState?.isError ?'loginError ant-form-item-has-error':''}`}
            name='password'
            placeholder="Password"
            rules={[
              {
                required: true,
                message: 'Please enter the correct password', // TODO: Handle this while developing validation framework.
              },
            ]}
          />
          <div>
            <AppError
              show={formState?.isError}
              errorMsg={formState.errorStack && formState.errorStack.message}
            />
          </div>
          <Form.Item className='formItemInput mb-0'>
            <Button type='primary loginButton' htmlType='submit'>
              Log In
            </Button>
          </Form.Item>
        </Form>
      </LoginContainer>
      <div className='forgotPasswordContainer'>
        <Link to={AppRoutes.FORGOT_PASSWORD} className='forgotPassword'>
          Forgot Password?
        </Link>
      </div>
    </AuthLayout>
  )
}
