import React from "react";
import { Row } from "antd";
import { Breadcrumbs } from "../../../../stateless/common/breadcrumbs";
import "../../profile/userProfileForm/userProfileForm.less";
import { UserProfileName } from "../../profile/userProfileHeader/index.styles";

export const InviteUserHeader = (props: any) => {
  const { breadcrumbs } = props;

  return (
    <div className="pl-vw inviteHeader">
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>

      <Row className="profileHed">
        <div>
          <UserProfileName>Add a New User</UserProfileName>
        </div>
      </Row>
    </div>
  );
};
