// Package Imports
import styled from "styled-components";

export const CustomCheckBoxContainer = styled.input.attrs((props: any) => ({
    type: "checkbox",
    name: props.name,
    id: props?.id,
    value: props?.value,
    className: props?.className,
    checked: props?.checked
  }))`
  `;

export const CustomLabelContainer = styled.label.attrs((props: any) => ({
  htmlFor: props?.htmlFor
  }))`
  `;