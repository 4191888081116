/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dropdown } from '../../../../../bit_components/common/dropdown'
import { userStateSelector } from '../../../../../redux/reducers/user/userReducer'
import {
  WorkHead,
  WorkDiv,
  DropDownWrapper,
  WorkHeaders,
} from './index.styles'
import { Col, Row, Tooltip } from 'antd'
import { cameFromOrganisation, getFormattedDate, translateOptions } from '../../../../../utility/appUtil'
import { appSelector, goToPrevious } from '../../../../../redux/reducers/app/appReducers'
import './styles.less'
import { AlertDropDownConstants, DefaultTime, filterOptions, jsonData, TimePattern } from '../../../../constants/constants'
import CustomRange from '../../../stateless/common/customRange'
import moment from "moment-timezone";
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage'
import {Button as CustomButton} from "../../../../../bit_components/common/button";

const WorkHeader = (props: any) => {
  let localeInfo:any = secureLocalStorage.getItem('locale')
  moment.tz.setDefault(localeInfo.timezone)
  const datePattern = "D MMMM, YYYY " + TimePattern.HH_MM
  const dispatch = useDispatch()
  const { appUser } = useSelector(userStateSelector)
  const { selectedOrganisation, showCustomRange } = useSelector(appSelector)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [startTime, setStartTime] = useState(DefaultTime.START)
  const [endTime, setEndTime] = useState(DefaultTime.END)
  const [isCustomDate, setIsCustomDate] = useState<boolean>(false)
  const [filterDuration, setFilterDuration] = useState<any | null>(AlertDropDownConstants.YEAR_VALUE)
  const [customValues, setCustomValues] = useState<boolean>(false)
  const [showRefreshButton, setShowRefreshButton] = useState<boolean>(true)

  const { t } = useTranslation()
  
  useEffect(() => {
    return () => {
      dispatch(goToPrevious({showCustomRange: false}))
    }
  }, [])

  useEffect(() => {
    if (!isCustomDate && endDate && startDate) {
      let params = {
        organisationId: getOrgId(),
        startDate: filterDuration !== AlertDropDownConstants.SELECT_DURATION_VALUE ? startDate + ' ' + startTime : null ,
        endDate: filterDuration !== AlertDropDownConstants.SELECT_DURATION_VALUE ? endDate + ' ' + endTime : null,
        hierarchyIdList:[],
        deviceIdList:[],
        status:[],
        workItemName:[],
        userName:[]
      }
      props?.onParamsUpdated(params)
    }
  }, [startDate, endDate, startTime, endTime, isCustomDate])

  const getOrgId = () => {
    let orgId = appUser?.organization.id as string
    if (cameFromOrganisation()) {
      orgId = selectedOrganisation?.id
    }
    return orgId
  }

  /************* Handle Duration Filter ************/
  const onChangeDuration = (value: string) => {
    dispatch(goToPrevious({showCustomRange: false}))
    setFilterDuration(value)
  }

  useEffect(() => {
    if(!customValues && !showCustomRange){
      setIsCustomDate(false)
      filterDuration && updateDates(filterDuration)
    }
    if(filterDuration !== AlertDropDownConstants.CUSTOM_VALUE){
      setShowRefreshButton(true)
    }
  }, [filterDuration])

  const updateDates = (value: any) => {
    let durationObj: any = {}
    if(value !== AlertDropDownConstants.CUSTOM_VALUE) {
      const selectedDate = moment();
      let newEndDate = selectedDate.format(datePattern);
      let newStartDate = selectedDate.subtract(1, value).format(datePattern);
      setDate(newStartDate, newEndDate)
    }
    else if (value === AlertDropDownConstants.CUSTOM_VALUE) {
      setIsCustomDate(true)
    }
  }

  const handleRefresh = () => {
    if(filterDuration !== AlertDropDownConstants.CUSTOM_VALUE) {
      const selectedDate = moment();
      let endDateValue = selectedDate.format(datePattern);
      let startDateValue = selectedDate.subtract(1, filterDuration).format(datePattern)
      
      let startDate = getFormattedDate(startDateValue,"YYYY-MM-DD")
      let endDate = getFormattedDate(endDateValue,"YYYY-MM-DD")
      let startTime = getFormattedDate(startDateValue,TimePattern.HH_MM)
      let endTime = getFormattedDate(endDateValue,TimePattern.HH_MM)
      
      let params = {
        startDate: `${startDate} ${startTime}`,
        endDate: `${endDate} ${endTime}`
      }
      props?.onParamsUpdated(params)
    }
    else if (filterDuration === AlertDropDownConstants.CUSTOM_VALUE) {
      props?.onParamsUpdated({})
    }
  }

  // set date
  const setDate = (startDateValue: string, endDateValue: string) => {
    let startDate = getFormattedDate(startDateValue,"YYYY-MM-DD")
    let endDate = getFormattedDate(endDateValue,"YYYY-MM-DD")
    let startTime = getFormattedDate(startDateValue,TimePattern.HH_MM)
    let endTime = getFormattedDate(endDateValue,TimePattern.HH_MM)
    
    setStartDate(startDate)
    setEndDate(endDate)
    setStartTime(startTime)
    setEndTime(endTime)
  }

  const onCustomRange = (values: any) => {
    setEndDate(values?.customEndDate)
    setStartDate(values?.customStartDate)
    setStartTime(values?.customStartTime)
    setEndTime(values?.customEndTime)
    setIsCustomDate(false)
  }

  return (
    <WorkHead>
      <Row>
        <Col>
          <WorkDiv>{t(jsonData.WorkItems.replace(/\s/g, ''))}</WorkDiv>
        </Col>
        <Col className="refresh-items">
          {showRefreshButton && (
              <Tooltip
                overlayClassName="dashBoardTooltip"
                placement="bottom"
                title={t(jsonData.Refresh)}
              >
                <div>
                  <CustomButton
                    tabIndex={0}
                    type="actionButton"
                    onClick={handleRefresh}
                    aria-label={""}
                    role={""}
                  >
                    {t(jsonData.Refresh)}
                  </CustomButton>
                </div>
              </Tooltip>
          )}
        </Col>
        <WorkHeaders>
          <Row>
            <Col>
              <DropDownWrapper>
                <Dropdown
                  ariaLabel={t(jsonData.Day)}
                  defaultValue={AlertDropDownConstants.DAY_VALUE}
                  className="filterDropDown workPageDropdown"
                  onChange={onChangeDuration}
                  value={filterDuration}
                  bordered={false}
                  optionValue={translateOptions(filterOptions.alertDateRange,t,'text')}
                  tabIndex={0}
                />
              </DropDownWrapper>
            </Col>
          </Row>
        </WorkHeaders>
      </Row>
      {filterDuration === AlertDropDownConstants.CUSTOM_VALUE ?
        <CustomRange onChange={onCustomRange} displayHeading='From' customValues={customValues} setCustomValues={setCustomValues} start={startDate} end={endDate} from={startTime} to={endTime} setShowRefreshButton={setShowRefreshButton}/>
        :
        ''
      }
    </WorkHead>
  )
}

export default WorkHeader;