import React, { useEffect, useRef, useState } from "react";
import DeviceConfigHeader from "./deviceConfigHeader";
import DeviceConfigBody from "./deviceConfigBody";
import Loader from "../../../stateless/common/spinner";
import {
  DeviceConfigDiv,
  DeviceConfigContainer,
  EmptyDevice,
} from "./index.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  onPage,
  sidebarNavigate,
} from "../../../../../redux/actions/app/appAction";
import { SideBarItems } from "../../../../constants/sideBarConstants";
import {
  fetchConfigNodes,
  fetchDeviceConfigViews,
  fetchDeviceFilterByOrgId,
  fetchDeviceListByOrgId,
  fetchDevicesHealthByOrgId,
  fetchNodeContent,
  getConfigDevices,
  getConfigState,
  unLockDeviceState,
} from "../../../../../redux/actions/device/deviceAction";
import { appSelector } from "../../../../../redux/reducers/app/appReducers";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import {
  DeviceSelector,
  clearDeviceConfigStates,
  clearNodeContent
} from "../../../../../redux/reducers/device/deviceReducer";
import { FORBIDDEN_STATUS, Pages } from "../../../../constants/constants";
import { clearCache, getDataFromCache } from "../../../../../utility/utils";
import Axios from "axios";
import { fetchEventsInfo } from "../../../../../redux/actions/eventLogs/eventListAction";
import { fetchTransformedLocationList } from "../../../../../redux/actions/role/locationAction";
import { getOrganisationId } from "../../../../../utility/appUtil";

const DeviceConfiguration = () => {
  const dispatch = useDispatch();
  const sourceToCancel = useRef(null);
  const configStateRef = useRef(null);
  const referenceNodeContent = useRef(null);
  const currentGroupId = useRef(null);

  let searchHandler: string | number | NodeJS.Timeout | undefined;

  const { selectedDevice, selectedOrganisation } = useSelector(appSelector);
  const { appUser } = useSelector(userStateSelector);
  const { deviceConfigViewsFormState, deviceConfigViews, configState, changedGroups } = useSelector(
    DeviceSelector
  );

  const [selectedView, setSelectedView] = useState<any>({});
  const [searchText, setSearchText] = useState<string>();
  const [index, setIndex] = useState<string>("1");
  const [selectedSetting, setSelectedSetting] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(null);
  const [loadingContent, setLoadingContent] = useState(false);
  const [originalOptions, setOriginalOptions] = useState(null);

  const isForbiddenStatus = FORBIDDEN_STATUS.includes(selectedDevice.statusType);
  const orgID = getOrganisationId(appUser, selectedOrganisation);

  const getConfigViews = async () => {
    let dataFromCache = await getDataFromCache(
      "configViews",
      "deviceConfigViews"
    );
    dispatch(
      fetchDeviceConfigViews({
        availableViews: dataFromCache,
        userName: appUser?.userName,
        deviceId: selectedDevice?.lcDeviceId,
      })
    );
  };

  const callConfigNodesApi = async (viewName, viewId) => {
    let dataFromCache = await getDataFromCache("nodeMenus", viewName);
    let source = Axios.CancelToken.source();
    sourceToCancel.current = source;
    if(selectedDevice && appUser && viewName && viewId) {
      dispatch(
        fetchConfigNodes({
          availableNode: dataFromCache,
          userName: appUser?.userName,
          deviceId: selectedDevice?.lcDeviceId,
          viewName: viewName,
          Id: viewId,
          st: searchText,
          signal: source,
        })
      );
    }
  };

  function debounceSearch(inputText: string, source: any) {
    clearTimeout(searchHandler);
    searchHandler = setTimeout(() => {
      setSearchText(inputText);
      dispatch(clearNodeContent());
      setOptions(null);
      dispatch(
        fetchConfigNodes({
          userName: appUser?.userName,
          deviceId: selectedDevice?.lcDeviceId,
          viewName: selectedView?.Name,
          Id: selectedView?.Id,
          st: inputText,
          signal: source,
        })
      );
    });
  }

  const handleSearch = (inputText: string) => {
    if (deviceConfigViews) {
      clearCache("nodeMenus");
      setLoading(true);
      let source = Axios.CancelToken.source();
      if (sourceToCancel.current) {
        sourceToCancel.current.cancel("New request initiated");
        setTimeout(() => {
          setLoading(false);
        }, 200);
      }
      sourceToCancel.current = source;
      debounceSearch(inputText, source);
    }
  };

  const getNodeContent = () => {
    if(selectedSetting?.NodeIds?.length > 0){
      setLoadingContent(true);
      const isExistingGroup = originalOptions?.some(obj => JSON.stringify(obj) === JSON.stringify(selectedSetting));
      dispatch(
        fetchNodeContent({
          userName: appUser?.userName,
          deviceId: selectedDevice?.lcDeviceId,
          viewName: deviceConfigViews[Number(index) - 1]?.Name,
          Id: deviceConfigViews[Number(index) - 1].Id,
          nodeIds: selectedSetting?.NodeIds.join(";"),
          cachedNodeContent : selectedSetting?.IsGroup && !isExistingGroup && changedGroups?.hasOwnProperty(currentGroupId.current) ? referenceNodeContent.current : null
        })
      );
    }
  };

  const callStatusApi = () => {
    dispatch(
      getConfigState({
        userName: appUser?.userName,
        deviceId: selectedDevice?.lcDeviceId,
        prevState : configStateRef.current
      })
    );
  };

  useEffect(() => {
    dispatch(onPage({ onComponent: Pages.DEVICES }));
    dispatch(sidebarNavigate(SideBarItems.DEVICE_CONFIGURATION));
    getConfigViews();
    callStatusApi();

    dispatch(
      getConfigDevices({
        userName: appUser?.userName,
        deviceId: selectedDevice?.lcDeviceId,
      })
    );
    dispatch(
      fetchEventsInfo({})
    );
    dispatch(fetchTransformedLocationList(orgID));
    dispatch(fetchDeviceListByOrgId({ orgId: orgID }));
    dispatch(fetchDeviceFilterByOrgId({ orgId: orgID }));
    dispatch(fetchDevicesHealthByOrgId({ organizationId: orgID }));
    
    window.addEventListener("beforeunload", () => clearCache("nodeMenus"));
    return () => {
      sourceToCancel?.current?.cancel();
      dispatch(
        unLockDeviceState({
          userName: appUser?.userName,
          deviceId: selectedDevice?.lcDeviceId,
        })
      );
      dispatch(clearDeviceConfigStates());
      clearCache("nodeMenus");
      window.removeEventListener("beforeunload", () => clearCache("nodeMenus"));
    };
  }, []);

  useEffect(() => {
    configStateRef.current = configState;

    const intervalTime = (configState?.ProcessingWorkItem ? 5 : 30) * 1000;
    const intervalId = setInterval(callStatusApi, intervalTime);

    return () => clearInterval(intervalId);
  }, [configState]);

  useEffect(() => {
    if(selectedView){
      callConfigNodesApi(selectedView?.Name, selectedView?.Id);
    }
  }, [selectedView])

  return (
    <div className="device-config-main-container">
      <DeviceConfigHeader
        handleSearch={handleSearch}
        selectedSetting={selectedSetting}
        getNodeContent={getNodeContent}
        getConfigViews={getConfigViews}
        isForbiddenStatus={isForbiddenStatus}
        callStatusApi={callStatusApi}
      />
        <Loader loading={deviceConfigViewsFormState?.loading}>
          {deviceConfigViewsFormState?.isError ? (
            <EmptyDevice description="Error Occurred" />
          ) : (
            <DeviceConfigContainer>
              <DeviceConfigDiv>
                <div>
                  {deviceConfigViews?.length > 0 && (
                    <DeviceConfigBody
                      setSelectedView={setSelectedView}
                      index={index}
                      setIndex={setIndex}
                      selectedSetting={selectedSetting}
                      setSelectedSetting={setSelectedSetting}
                      loading={loading}
                      setLoading={setLoading}
                      options={options}
                      setOptions={setOptions}
                      loadingContent={loadingContent}
                      setLoadingContent={setLoadingContent}
                      isForbiddenStatus={isForbiddenStatus}
                      getNodeContent={getNodeContent}
                      originalOptions={originalOptions}
                      setOriginalOptions={setOriginalOptions}
                      referenceNodeContent={referenceNodeContent}
                      currentGroupId={currentGroupId}
                    />
                  )}
                </div>
              </DeviceConfigDiv>
            </DeviceConfigContainer>
          )}
        </Loader>
    </div>
  );
};

export default DeviceConfiguration;
