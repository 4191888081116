import React from 'react'
import { jsonData } from '../../../../constants/constants'

export const insightColumns = (trans:any) =>{
  return [  
  {
    title: "",
    children:[
      {
        width: 50,
        title: "April",
        dataIndex: ['frequency'], 
        ellipsis: true,
      },
    ],
    ellipsis: true,
  },  
  {
    width: 50,
    title: <span>{trans(jsonData.ItemsBorrowed)}</span>,
    children:[
      {
        width: 50,
        title: <span>{trans(jsonData.Successful)}</span>,
        dataIndex: ['borrowSuccessful'], 
        ellipsis: true,
      },
      {
        width: 50,
        title: <span>{trans(jsonData.Failed)}</span>,
        dataIndex: ['borrowFailed'], 
        ellipsis: true,
      }
    ],
    ellipsis: true,
  },  
  {
    width: 50,
    title: <span>{trans(jsonData.ItemsReturned)}</span>,
    children:[
      {
        width: 50,
        title: <span>{trans(jsonData.Successful)}</span>,
        dataIndex: ['returnSuccessful'], 
        ellipsis: true,
      },
      {
        width: 50,
        title: <span>{trans(jsonData.Failed)}</span>,
        dataIndex: ['returnFailed'], 
        ellipsis: true,
      }
    ],
    ellipsis: true,
  },  
  {
    width: 50,
    title: <span>{trans(jsonData.ItemsRenewed)}</span>,
    children:[
      {
        width: 50,
        title: <span>{trans(jsonData.Successful)}</span>,
        dataIndex: ['renewSuccessful'], 
        ellipsis: true,
      },
      {
        width: 50,
        title: <span>{trans(jsonData.Failed)}</span>,
        dataIndex: ['renewFailed'], 
        ellipsis: true,
      }
    ],
    ellipsis: true,
  },
  {
    width: 50,
    title: <span>{trans(jsonData.UserLogin)}</span>,
    children:[
      {
        width: 50,
        title: <span>{trans(jsonData.Successful)}</span>,
        dataIndex: ['loginSuccessful'], 
        ellipsis: true,
      },
      {
        width: 50,
        title: <span>{trans(jsonData.Failed)}</span>,
        dataIndex: ['loginFailed'], 
        ellipsis: true,
      }
    ],
    ellipsis: true,
  },
  {
    width: 50,
    title: "",
    children:[
      {
        width: 50,
        title: <span>{trans(jsonData.LMSoffline)}</span>,
        dataIndex: ['lmsOffline'], 
        ellipsis: true,
      },
    ],
    ellipsis: true,
  },
  {
    width: 50,
    title: <span>{trans(jsonData.Payments)}</span>,
    children:[
      {
        width: 50,
        title: <span>{trans(jsonData.Successful)}</span>,
        dataIndex: ['paymentSuccessful'], 
        ellipsis: true,
      },
      {
        width: 50,
        title: <span>{trans(jsonData.Failed)}</span>,
        dataIndex: ['paymentFailed'], 
        ellipsis: true,
      }
    ],
    ellipsis: true,
  },
  {
    width: 50,
    title: "",
    children:[
      {
        width: 50,
        title: <span>{trans(jsonData.Coinboxempty)}</span>,
        dataIndex: ['coinboxEmpty'], 
        ellipsis: true,
      },
    ],
    ellipsis: true,
  },
  {
    width: 50,
    title: <span>{trans(jsonData.Recommendations)}</span>,
    children:[
      {
        width: 50,
        title: <span>{trans(jsonData.Actions)}</span>,
        dataIndex: ['actions'], 
        ellipsis: true,
      },
    ],
    ellipsis: true,
  },
  {
    width: 50,
    title: <span>{trans(jsonData.Overview)}</span>,
    children:[
      {
        width: 50,
        title: <span>{trans(jsonData.Successful)}</span>,
        dataIndex: ['Successful'], 
        ellipsis: true,
      },
      {
        width: 50,
        title: <span>{trans(jsonData.Failed)}</span>,
        dataIndex: ['Failed'], 
        ellipsis: true,
      },
      {
        width: 50,
        title : <span>{trans(jsonData.Total)}</span>,
        dataIndex : ['total'],
        ellipsis: true,
      }
    ],
    ellipsis: true,
  },
]
}

export const reportData = {
  "interval" : 'day',
  "rows" : [
      {
        date:'4-01-2022',
        data:{
          1:7,
          2:7,
          3:5,
          4:1,
          5:6,
          6:2
        },
        location:[
            {
              id:26,
              locationName: 'New York',
              data:{
                1:7,
                2:7,
                3:5,
                4:1,
                5:6,
                6:2
              },
              device:[
                  {
                    id:32,
                    deviceName: 'Test Device',
                    data:{
                      1:7,
                      2:7,
                      3:5,
                      4:1,
                      5:6,
                      6:2
                    },
                  }
              ]
            }
        ]
      },
      {
        date:'4-02-2022',
        data:{
          1:7,
          2:7,
          3:5,
          4:1,
          5:6,
          6:2
        },
        location:[
            {
              id:26,
              locationName: 'New York',
              data:{
                1:7,
                2:7,
                3:5,
                4:1,
                5:6,
                6:2
              },
              device:[
                  {
                    id:32,
                    deviceName: 'Test Device',
                    data:{
                      1:7,
                      2:7,
                      3:5,
                      4:1,
                      5:6,
                      6:2
                    },
                  }
              ]
            }
        ]
    }
  ],
  "columns" : [
    {
        id: 1,
        header: 'Borrow',
        subHeader: 'Successful',
    },
    {
      id: 2,
      header: 'Borrow',
      subHeader: 'Failed',
    },
    {
      id: 3,
      header: 'Return',
      subHeader: 'Successful',
    },
    {
      id: 4,
      header: 'Return',
      subHeader: 'Failed',
    },
    {
      id: 5,
      header: 'Renewed',
      subHeader: 'Successful',
    },
    {
      id: 6,
      header: 'Renewed',
      subHeader: 'Failed',
    },
  ],
}