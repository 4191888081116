/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { headers, tablecolumn } from "./eventData";
import { Menu, Dropdown , Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./eventHeader/styles.less";
import { clearEventsInfo, eventLogListSelector, setEventLogIsRefresh } from "../../../../redux/reducers/eventLogs/eventLogListReducer";
import { fetchEventLogList, fetchEventsInfo } from "../../../../redux/actions/eventLogs/eventListAction";
import "../eventLogs/eventHeader/CustomAntdStyle.less";
import EventLogsHeader from "../eventLogs/eventHeader/index";
import { deviceDeleteIcon } from "../../../images";
import { onPage, sidebarNavigate } from "../../../../redux/actions/app/appAction";
import { SideBarItems } from "../../../constants/sideBarConstants";
import { clearEventLogs, clearState } from "../../../../redux/reducers/eventLogs/eventLogListReducer";
import { getStatusTypeEllipse, getTableSortedData, sortList } from "../../../../utility/utils";
import { appSelector } from "../../../../redux/reducers/app/appReducers";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import { AntdInfinityTable } from "../../stateless/common/antdTable/antdInfininityTable";
import { cameFromOrganisation, getOrganizationDateAndTime, getHeight, urlModification } from "../../../../utility/appUtil";
import { DEFAULT_LIMIT, DEFAULT_PAGE, EV_LOG_PAGE_SIZE, Pages, PickerFormat,jsonData } from "../../../constants/constants";
import './eventHeader/styles.less'
import Loader from "../../stateless/common/spinner";
import { setEventDataValues, insightsSelector } from "../../../../redux/reducers/insights/insightsReducer";
import { fetchDeviceListByOrgId, fetchPartNameList } from "../../../../redux/actions/device/deviceAction";
import { fetchTransformedLocationList } from "../../../../redux/actions/role/locationAction";
import { DeviceSelector, clearPartNameList } from "../../../../redux/reducers/device/deviceReducer";
import { locationListSelector } from "../../../../redux/reducers/location/locationListReducer";
import EventLogService from "../../../services/eventLogs/eventLogService";
import { ThreeDotsImg, ThreeDotsImgHover } from '../../../../web/images/index';
import AntdPagination from "../../stateless/common/pagination/pagination";
import { useTranslation } from 'react-i18next';
import { UseGAEventTracker } from "../../../../utility/useGAEventTracker";
import { useHistory, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";


interface EventFilter {
  category: null | string[]
  deviceName: null | string[]
  event: null | string[]
  eventId: null | number[]
  location: null | string[]
  typetext: null | string[]
  partName: null | string[]
}

export const EventListContainer = (props: any) => {
  const dispatch = useDispatch();
  const { eventLogList, totalEvents, currentPage, totalPages, formState, eventsInfo, isRefresh } = useSelector(eventLogListSelector);
  const { showEventLog, eventDataValues } = useSelector(insightsSelector)
  const { deviceList, partNameList } = useSelector(DeviceSelector)
  const { locations } = useSelector(locationListSelector)
  /*  Header constant   */
  const [dayByDate, SetDayByDate] = useState<any | null>();
  const [toDaybyDate, SetToDaybyDate] = useState<any | null>();
  const [tableData, setTableData] = useState([] as any);
  const [filtercoumn, setFiltercoumn] = useState<any>();
  const [rowId, setRowId] = useState<any>();
  const [filterCsvData, setFilterCsvData] = useState([] as any);
  const [loading, setLoading] = useState<any>(false);
  const { selectedOrganisation } = useSelector(appSelector);
  const { appUser } = useSelector(userStateSelector);
  const [scroll, setScroll] = useState<any>();
  const [sendCalculatedDate, setSendCalculatedDate] = useState<any>({});
  const [filtersVal, setFiltersVal] = useState<EventFilter | any>();
  const [searchtext, setSearchtext] = useState<any>();
  const [filteredInfo, setFilteredInfo] = useState<any>(null);
  const [sortedInfo, setSortedInfo] = useState<any>(null);
  const [scrolledToEnd, setScrolledToEnd] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(100);
  const screenHeight = document.body.clientHeight;
  const screenwidth = document.body.clientWidth;
  const [eventHeaderHeight, setEventHeaderHeight] = useState(54);
  const [locationFilter, setLocationFilter] = useState([])
  const [deviceFilter, setDeviceFilter] = useState([])
  const [eventitem, setEventItem] = useState([])
  const [eventNameFilter, setEventNameFilter] = useState([])
  const [categoryFilter, setCategoryFilter] = useState([])
  const [severityFilter, setSeverityFilter] = useState([])
  const [locationUpdated, setLocationUpdated] = useState<boolean>(false)
  const [resetAll, setResetAll] = useState<boolean>(false)
  const [oldFilters, setOldFilters] = useState({})
  const [filtersChanged, setFiltersChanged] = useState<boolean>(false)
  const [deviceListChanged, setDeviceListChanged] = useState<boolean>(false)
  const [iconImg, setIconImg] = useState(ThreeDotsImg);
  const [unfilteredData, setUnfilteredData] = useState([])
  const [customCount, setCustomCount] = useState(null)
  const [insightFilters, setInsightFilters] = useState(null)
  const { t } = useTranslation()
  const [downloading, setDownloading] = useState<boolean>(false)
  const [partNameFilter, setPartNameFilter] = useState([])
  const [selectedDeviceStates, setSelectedDeviceStates] = useState<any>({});
  const [reset, setReset] = useState<boolean>(false);

  const location = useLocation<any>();
  const history = useHistory();

  const clearLocationState = () => {
    if(location?.state){
      history.replace({
        ...history.location,
        state: undefined,
      });
    }
  };

  // function for full output 
  const fetchFullOutput = (id, eventId) => {
    const handleCloseDropDown = (e: any) => {
      if(e.keyCode === 9){
        const dropdwon:any = document.querySelector(`.menuImage-${id}`)        
        if(dropdwon){
          dropdwon.click(); 
        }
      }
    }

    const menu = (
      <Menu tabIndex={-1} className='filter subMenuFix'>
        <Menu.Item className={`filterChild-${id} subMenuChild`} key='1' onKeyDown={handleCloseDropDown} onClick={() => openFullOutput(id, eventId)}>
          Open Full Output
        </Menu.Item>
      </Menu>
    )

    const handelThreeDots =(e:any)=>{
    
      if(e.keyCode === 13){
        const dropdwon:any = document.querySelector(`.menuImage-${id}`)        
        if(dropdwon){
            dropdwon.click()
            setTimeout(()=>{
              const select = document.querySelector<HTMLElement>(`.filterChild-${id}`)
              select.focus()
            },100)
        }
      }
    }

    return (
      <>
        <Dropdown  overlay={menu} placement='bottomRight' trigger={['click']} getPopupContainer={(trigger: any) => trigger.parentNode}>
        <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title="Open Full Output">
          <span onKeyUp={handelThreeDots} tabIndex={0} className={`menuImage-${id}`}
            onMouseOver={() => setIconImg(ThreeDotsImgHover)}
            onMouseOut={() => setIconImg(ThreeDotsImg)}
          >
              <img
                src={iconImg}
                alt='menu'
              />
          </span>
          </Tooltip>
        </Dropdown>
      </>
    )
  }


  //Table
  const getOrgId = () => {
    let orgId = appUser?.organization.id as string;
    if (cameFromOrganisation()) {
      orgId = selectedOrganisation?.id;
    }
    return orgId;
  };

  useEffect(() => {
    dispatch(fetchPartNameList(getOrgId()))
    !showEventLog && dispatch(fetchDeviceListByOrgId({ orgId: getOrgId() }))
    dispatch(fetchTransformedLocationList(getOrgId()))
    let params = {
      organizationId : getOrgId(),
      isEvent : true
  }
    dispatch(fetchEventsInfo(params))
    dispatch(onPage({ onComponent: Pages.JUNTO_PORTAL }));
    dispatch(sidebarNavigate(SideBarItems.EVENT_LOGS));
    return () => {
      dispatch(clearEventLogs());
      dispatch(clearState());
      dispatch(clearEventsInfo())
      dispatch(clearPartNameList())
    };
  }, []);

  useEffect(() => {
    if (locationUpdated) {
      if (filtersVal?.location) {
        setDeviceListChanged(true)
        dispatch(fetchDeviceListByOrgId({ orgId: getOrgId(), locations: filtersVal?.location }))
      }
      else {
        setDeviceListChanged(false)
        dispatch(fetchDeviceListByOrgId({ orgId: getOrgId() }))
      }
      setLocationUpdated(false)
    }
  }, [filtersVal?.location])

  useEffect(() => {
    if (formState.isSuccess === true) {
      setLoading(false);
      dispatch(clearState());
    }
    else if (formState.isError === true) {
      setLoading(false)
    }
  }, [formState]);

  const apiParams = {
    orgID: getOrgId(),
    startDate: dayByDate,
    endDate: toDaybyDate,
    removeAudit: true,
    limit: process.env.REACT_APP_EVENTS_LIMIT || DEFAULT_LIMIT,
    eventIdList: filtersVal?.eventId,
    severity: filtersVal?.typetext,
    eventName: filtersVal?.event,
    hierarchyIdList: filtersVal?.location,
    deviceIdList: filtersVal?.deviceName,
    category: filtersVal?.category,
    partName: filtersVal?.partName,
    activeAlertTimestamp : location?.state?.activeAlertTimestamp,
    clearingEventTimestamp : location?.state?.clearingEventTimestamp,
    eventOfAlertStatus : location?.state?.eventOfAlertStatus,
  }

  const callEventLogApi = (page: number, search: any) => {
    const evParam : any = {
      ...apiParams,
      currentPage: page,
      searchParam: search,
    };
    if(customCount && nofiltersUpdated() && (!search || !search?.length)){
      evParam.totalCount = customCount
    }
    else if(page > 1){
      evParam.totalCount = totalEvents
    }
    setLoading(true);
    dispatch(fetchEventLogList(evParam));
  }

  const nofiltersUpdated = () => {
    if(filtersVal === null){
      return false
    }
    for(let newFilter in filtersVal){
      if(
        (insightFilters[newFilter] === undefined && filtersVal[newFilter] !== null) ||
        (filtersVal[newFilter]?.some((x) => !insightFilters[newFilter]?.includes(x))) ||
        (insightFilters[newFilter]?.some((x) => !filtersVal[newFilter]?.includes(x)))
      ){
        return false
      }
    }
    return true
  }

  useEffect(() => {
    dispatch(clearEventLogs())
    setScrolledToEnd(false);
    if (dayByDate && toDaybyDate) {
      callEventLogApi(DEFAULT_PAGE, searchtext)
      dispatch(setEventLogIsRefresh(false));
    }

  }, [dayByDate, toDaybyDate]);

  useEffect(() => {
    if (sendCalculatedDate !== undefined && sendCalculatedDate.constructor === Object && Object.keys(sendCalculatedDate).length) {
      SetDayByDate(sendCalculatedDate?.startDate);
      SetToDaybyDate(sendCalculatedDate?.endDate);
    }
    if(dayByDate === sendCalculatedDate.startDate && toDaybyDate === sendCalculatedDate.endDate ){
      callEventLogApi(DEFAULT_PAGE, searchtext)
      dispatch(setEventLogIsRefresh(false));
    }
  }, [sendCalculatedDate]);

  useEffect(() => {
    if (eventLogList.length) {
      setScroll(true);
      // const slicedData = eventLogList.slice(currentIndex, currentIndex + EV_LOG_PAGE_SIZE);
      if(sortedInfo){
        tableInitialData(eventLogList).then((tableDataInitial: any) => {
          getTableSortedData(sortedInfo,tableDataInitial).then((data) => {
            setUnfilteredData(data)
            setTableData(data?.slice(currentIndex, currentIndex + EV_LOG_PAGE_SIZE))
            setCurrentIndex(currentIndex + EV_LOG_PAGE_SIZE)
          })
        })
      }
      else {
      tableInitialData(eventLogList).then((tableDataInitial: any) => {
        setUnfilteredData(tableDataInitial)
        setTableData(tableDataInitial?.slice(currentIndex, currentIndex + EV_LOG_PAGE_SIZE))
        setCurrentIndex(currentIndex + EV_LOG_PAGE_SIZE)
      }).catch((err) => {
        console.log('Failed to initialize table data while event logs got changed', err)
      });
    }
  }
  }, [eventLogList]);

  //table logics
  const expandable = {
    expandedRowRender: (record: {
      description:
      | boolean
      | React.ReactChild
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined;
    }) => <p>{record?.description}</p>,
  };

  //filter table  by dropdown location
  function onlyUnique(value: any, index: any, self: any) {
    return (self.indexOf(value) === index && value !== null && value !== "") || "";
  }

  const getFilterList = (list: any) => {
    const arrLocation = list?.map((x: any) => {
      return {
        text: x,
        value: x,
      }
    })
    const sortedBylabel = arrLocation?.sort(sortList('text'));
    return sortedBylabel
  }

  const getLocations = (list: any) => {
    const arrLocation = list?.map((x: any) => {
      return {
        text: x.name,
        value: x.id,
      }
    })
    const sortedBylabel = arrLocation.sort(sortList('text'));
    return sortedBylabel
  }
  const getDeviceList = (list: any) => {
    const arrDevices = list?.map((x: any) => {
      return {
        text: x.info.name ?? (x.serialNo ?? x.id),
        value: x.id,
      }
    })
    const sortedBylabel = arrDevices.sort(sortList('text'));
    return sortedBylabel
  }
  useEffect(() => {
    setLocationFilter(getLocations(locations))
  }, [locations])

  useEffect(() => {
    setDeviceFilter(getDeviceList(deviceList))
  }, [deviceList])

  useEffect(() => {
    setPartNameFilter(getFilterList(partNameList))
  }, [partNameList])

  useEffect(() => {
    //filter table  by dropdown eventid
    let eventlistItems = eventsInfo?.map((data: any) => data.eventId);
    const eventUniqueLocation = eventlistItems?.filter(onlyUnique);
    setEventItem(getFilterList(eventUniqueLocation))

    //filter table  by dropdown type
    const typeListItems = eventsInfo?.map((data: any) => data.severity?.toString().toLowerCase());
    const typeUniqueLocation = typeListItems?.filter(onlyUnique);
    setSeverityFilter(getFilterList(typeUniqueLocation))

    //filter table  by dropdown event
    const eventtlistItems = eventsInfo?.map(
      (data: any) => data.event
    );
    const eventtuniquelocation = eventtlistItems?.filter(onlyUnique);
    setEventNameFilter(getFilterList(eventtuniquelocation))

    //filter table  by dropdown category
    const categorytlistItems = eventsInfo?.map(
      (data: any) => data.category
    );
    const eventtuniquecategory = categorytlistItems?.filter(onlyUnique);
    setCategoryFilter(getFilterList(eventtuniquecategory))
  }, [eventsInfo])

  const setRowClassName = (record: any) => {
    return record?.key === rowId ? "clickRowStyl" : "";
  };

  const openFullOutput = (id, eventId) => {
    const win = window.open(urlModification("/eventLogs/" + eventId), "_blank", 'location=yes');
    if (win !== null) {
      id && secureLocalStorage.setItem('eventLogRowId', id);
      secureLocalStorage.setItem('searchedText', searchtext)
      win.focus();
    }
    UseGAEventTracker("Event Logs Category", "eventLogs Open Full Output", "Open Full Output selected")
  }


  //set initial data
  const tableInitialData = async (data: any) => {
    const tableInitial = data?.map((data: any) => {

      let localeInfo:any = secureLocalStorage.getItem('locale')

      return {
        key: data?.id,
        id: getOrganizationDateAndTime(data?.eventTimestamp,"x"),
        extratype: data?.severity,
        severity: (
          <span className="warnicons">
            <>
              <span>
                <img src={getStatusTypeEllipse(data?.severity?.toString())} alt="" />
              </span>
              <span>{data?.severity}</span>
            </>
          </span>
        ),
        typetext: data?.severity.toString().toLowerCase(),
        dateAndTime: getOrganizationDateAndTime(data?.eventTimestamp,localeInfo.momentFormat + " " + PickerFormat.TIME),
        // datetime: moment(data.eventTimestamp).format("MM DD YYYY " + TimePattern.HH_MM),
        dateTimeStamp: getOrganizationDateAndTime(data?.eventTimestamp,'x'),
        location: data?.hierarchyName,
        device: (<span className='deviceRowDivStye'><Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={data?.eventSrcName}><span className='deviceNamestyle'>{data?.eventSrcName}</span></Tooltip>{data?.isArchived ?  <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title="Deleted"><img src={deviceDeleteIcon} alt="" className='InsightdeviceDeleteIcon'/></Tooltip> : ""}</span>),
        deviceName : data?.eventSrcName, 
        eventId: data?.eventId,
        eventIdSearch: JSON.stringify(data?.eventId),
        category: data?.category,
        // range: data.data.metadata.category,
        event: data?.eventName,
        menu: fetchFullOutput(data?.id, data?.eventId),
        partName: data?.partName
        // selectedRowKeys,
        // onClick: (selectedRowKeys: any) => {
        //   setSelectedRowKeys({
        //     selectedRowIds: selectedRowKeys.map(Number),
        //     selectedRowKeys,
        //   });
        // },

        // descriptionText: getFormattedString(
        //   data?.data?.description?.content !== null
        //     ? data?.data?.description?.content
        //     : "",
        //   data?.data?.description?.params
        // ),
        // tracelogForExcel: JSON.stringify(data?.data),
        // tracelog: JSON.stringify(data?.data).replace(/[\])}[{(]/g, ""),
        // description: (
        //   <div className="Rowdescription">

        //     <Row>
        //       <Col className="RowdescriptionTitle" >Full Output</Col>
        //       <Col className="" flex="auto">
        //         <div className="RowdescriptionBox scrollContent scrollStyle" >
        //           {(unstructuredData?.data?.traceLogs?.length > 0 || unstructuredData?.data?.eventData) ?
        //             <><pre>{JSON.stringify(unstructuredData?.data?.eventData, null, 2) + "\n" + JSON.stringify(unstructuredData?.data?.traceLogs, null, 2)}</pre>
        //               <CopyToClipboard text={JSON.stringify(unstructuredData?.data?.eventData, null, 2) + "\n" + JSON.stringify(unstructuredData?.data?.traceLogs, null, 2)}>
        //                 <Button className="RowdescriptionButton CopyOutputBtn" >Copy full output</Button>
        //               </CopyToClipboard>
        //             </>
        //             :
        //             <span className="traceLogEmpty">[No data is provided]</span>
        //           }
        //         </div>
        //         <div className="RowdescriptionShadow" ></div>
        //         <button onClick={() => { openFullOutput(JSON.stringify(unstructuredData), JSON.stringify(unstructuredData?.eventId)) }} className="RowdescriptionButton OpenOutputBtn"><img className="openOutputIcon" src={openOutput} alt="" />Open full output</button>
        //       </Col>
        //     </Row>
        //     <span>
        //       <button
        //         onClick={() => { setSelectedRowKeys(0); setRowId(0) }}
        //         type="button"
        //         className="btn btn-sm btn-cross"
        //       >
        //         <img src={crossIcon} alt="" className="rowDesIcon" />
        //       </button>
        //     </span>
        //     <div className="collapseTrace">
        //       <Collapse>
        //         <Panel header="" key={unstructuredData.id}>
        //           <ul className="traceLogData">
        //             {JSON.stringify(unstructuredData, null, 2)}
        //           </ul>
        //         </Panel>
        //       </Collapse>
        //     </div>
        //   </div>
        // ),
      };
    })
    return tableInitial;
  };

  const tableSorter = async(sorter: any) => {
    return getTableSortedData(sorter, unfilteredData)
  }

  const sortData = (sorter) => {
    setScroll(true)
    tableSorter(sorter).then((data)=>{
      setUnfilteredData(data)
      setTableData(data?.slice(0, EV_LOG_PAGE_SIZE))
      setCurrentIndex(0 + EV_LOG_PAGE_SIZE)
    })
  }

  const FilterChangeData = (filters: any) => {

    let change = false
    if (Object.keys(oldFilters).length !== 0 || !Object.values(filters).every(o => o === null)) {
      for (let val in filters) {
        if ((oldFilters[val] === undefined && filters[val] !== null) ||
          (oldFilters[val]?.some((x) => !filters[val]?.includes(x))) ||
          (filters[val]?.some((x) => !oldFilters[val]?.includes(x)))
        ) {
          change = true
          break
        }
      }
    }
    if (change) {
      setFiltersChanged(true)
    }
    else {
      setFiltersChanged(false)
    }
    if (filtersVal?.location?.toString() !== filters?.location?.toString()) {
      setLocationUpdated(true)
      setFiltersVal({ ...filters, device: null })
      setFilteredInfo({ ...filters, device: null })
    }
    else {
      for (let val in filters) {
        if (!filtersVal || (filtersVal[val] === undefined && filters[val] !== null) ||
          (filtersVal[val]?.toString() !== filters[val]?.toString())
        ) {
          if (isRefresh) {
            setFiltersVal(oldFilters)
            setFilteredInfo(oldFilters)
          } else {
            setFiltersVal(filters)
            setFilteredInfo(filters)
          }
          break
        }
      }
    }
  }

  const filterSorting = (pagination, filters, sorter, extra) => {
    FilterChangeData(filteredInfo)
    sortData(sorter)
    setSortedInfo(sorter)
    setFiltersVal(filteredInfo)
  }


  useEffect(() => {
    FilterChangeData(selectedDeviceStates)
    // setFiltersVal(selectedDeviceStates)
    setFilteredInfo(selectedDeviceStates)
  }, [selectedDeviceStates]);

  const clearAllFilter = () => {
    clearLocationState();
    setFiltersVal(null)
    setFilteredInfo(null)
    setSortedInfo(null)
    if (deviceListChanged) {
      dispatch(fetchDeviceListByOrgId({ orgId: getOrgId() }))
      setDeviceListChanged(false)
    }
    setOldFilters({})
    setFiltersChanged(false)
    setSelectedDeviceStates({})
  }

  const resetToDefault = () => {
    clearAllFilter()
    setResetAll(true)
  }

  const applyFilter = () => {
    dispatch(setEventLogIsRefresh(false));
    setOldFilters(filtersVal)
    setToInitial()
    callEventLogApi(DEFAULT_PAGE, searchtext)
    setFiltersChanged(false)
  }

  useEffect(() => {
    if (resetAll) {
      setToInitial()
      callEventLogApi(DEFAULT_PAGE, searchtext)
      setResetAll(false)
    }
  }, [resetAll])


  //serachbar
  const searchUpdate = (search: any) => {
    setToInitial()
    callEventLogApi(DEFAULT_PAGE, search)
    setSearchtext(search)
  };


  const replaceEmptyKey = (obj:any)=>{
    const inputObject = obj;
    // Iterate over the object's keys
    for (const key in inputObject) {
      if (Array.isArray(inputObject[key]) && inputObject[key].length === 0) {
        inputObject[key] = null;
      }
    }
    return inputObject
  }

  const selectedDeviceStateUpdate = (data: any, key: any,reset:any) => {
    let updatedDeviceStates = { ...selectedDeviceStates };
    setReset(reset)
    if (updatedDeviceStates?.hasOwnProperty(key)) {
      updatedDeviceStates = {...updatedDeviceStates,[`${key}`]:data[key]}
    } else {
      updatedDeviceStates = {...updatedDeviceStates,...data}
    }

    var replaceKeyVal = replaceEmptyKey(updatedDeviceStates)
    setSelectedDeviceStates(replaceKeyVal)
  };
  
  useEffect(() => {
    if (location?.state) {
      const { clearingId, alertTypeId, deviceId } = location?.state;
      
      let updatedDeviceStates = { ...selectedDeviceStates };
      updatedDeviceStates = {
        ...updatedDeviceStates,
        eventId: clearingId ? [alertTypeId, clearingId] : [alertTypeId],
        deviceName: [deviceId],
      };

      var replaceKeyVal = replaceEmptyKey(updatedDeviceStates);
      setSelectedDeviceStates(replaceKeyVal);

      setFiltersVal({
        ...filtersVal,
        deviceName: [deviceId],
        eventId: clearingId ? [alertTypeId, clearingId] : [alertTypeId],
      });

      dispatch(setEventLogIsRefresh(false));
      setOldFilters({
        ...filtersVal,
        deviceName: [deviceId],
        eventId: clearingId ? [alertTypeId, clearingId] : [alertTypeId],
      });
      setToInitial();
      setFiltersChanged(false);
    }
  }, [location]);
 
  //table column
  useEffect(() => {
    setFiltercoumn(
      tablecolumn(
        tableData,
        severityFilter,
        locationFilter,
        deviceFilter,
        eventitem,
        eventNameFilter,
        categoryFilter,
        filteredInfo,
        sortedInfo,
        t,
        selectedDeviceStateUpdate,
        selectedDeviceStates,
        partNameFilter
      )
    );
  }, [tableData, filteredInfo, sortedInfo, locationFilter, deviceFilter, eventitem, severityFilter, categoryFilter, eventNameFilter,partNameFilter,selectedDeviceStates]); 

  useEffect(() => {
    if (showEventLog && eventDataValues) {
      setLocationUpdated(true)
      setFiltersVal({ ...eventDataValues?.filters, eventId: eventDataValues?.eventIds ,deviceName : eventDataValues?.filters?.device })
      setInsightFilters({ ...eventDataValues?.filters, eventId: eventDataValues?.eventIds , deviceName : eventDataValues?.filters?.device })
      setOldFilters({ ...eventDataValues?.filters, eventId: eventDataValues?.eventIds ,deviceName : eventDataValues?.filters?.device})
      setFilteredInfo({ ...eventDataValues?.filters, eventId: eventDataValues?.eventIds , deviceName : eventDataValues?.filters?.device})
      setCustomCount(eventDataValues?.count)
    }
  }, [showEventLog, eventDataValues])

  const scrolltotop = () => {
    setScroll(true);
  };

  const loadingProp = (data: any) => {
    if (data === true) {
      setLoading(true);
    }
  };

  useEffect(() => {
    if (scroll) {
      const node = document.querySelector<HTMLElement>(
        ".table .ant-table-body"
      );
      node?.scrollTo(0, 0);
    }
  }, [scroll]);

  const onSendCalculatedDate = (data: any[]) => {
    setSendCalculatedDate(data);
  }


  const onScroll = (event: any) => {
    if (event.target.scrollTop && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      setScrolledToEnd(true);
      console.log('Event Logs :: scroll to end')
    }
  }

  const onPageChange = (page: number) => {
    setToInitial()
    callEventLogApi(page, searchtext)
  }

  const fetchData = async (startIndex: number) => {
    const t = unfilteredData.slice(startIndex, startIndex + EV_LOG_PAGE_SIZE);
    return t;
  }

  const handleFetch = () => {
    console.log('Event Logs :: fetch more')    
    if (tableData?.length !== unfilteredData?.length && scrolledToEnd) {
      console.log('Event Logs :: fetching...')
      // const node = document.querySelector<HTMLElement>(".table .ant-table-body");
      // setScrollValue(node?.scrollTop)
      setScrolledToEnd(false);
      fetchData(currentIndex).then(async (newData: any) => {
        // const formattedData = await tableInitialData(newData);
        setTableData(tableData.concat(newData));
        setCurrentIndex(currentIndex + EV_LOG_PAGE_SIZE);
        // setDataFetched(true)
        console.log('Event Logs :: data fetched')
      }
      );
    }
  }

  const setToInitial = () => {
    const node = document.querySelector<HTMLElement>(
      ".table .ant-table-body"
    );
    node?.scrollTo(0, 0);
    setCurrentIndex(0)
    setTableData([])
    setUnfilteredData([])
  }

  const onSwitching = (loadMore?: boolean) => {
    if (filteredInfo !== null || sortedInfo !== null) {
      setCustomCount(null)
    }
    !loadMore && showEventLog && eventDataValues && dispatch(setEventDataValues(null))
    setToInitial()
  }


  const eventHeaderResized = (height: number) => {
    setEventHeaderHeight(height);
  }

  useEffect(() => {
    //table height
    const tableHeight = getHeight('.ant-table-body')

    //to calculate table footer height
    const compContentHeight = getHeight('.ant-layout-content')
    const bottomGap = compContentHeight - (screenHeight * 0.8)
    const pagination_div = document.querySelector('.event-pagination')?.clientHeight
    const footerHeight = totalPages > 1 ? pagination_div + bottomGap : 0;

    //to calculate final height of the table
    const calculatedHeight = tableHeight - footerHeight
    const scrollH = parseInt(calculatedHeight.toFixed(0));
    !isNaN(scrollH) && setScrollHeight(scrollH)

  }, [screenHeight, screenwidth, loading, totalPages, eventHeaderHeight])

  const paramsForExport = async (orgName: string) => {
    const evParam = {
      ...apiParams,
      orgName: orgName,
      currentPage: currentPage,
      searchParam: searchtext,
      hierarchyNameList: locationFilter?.map((x: any) => {
        if (filtersVal?.location?.includes(x.value)) return x.text
      }),
      deviceNameList: deviceFilter?.map((x: any) => {
        if (filtersVal?.deviceName?.includes(x.value)) return x.text
      }),
    };
    return evParam
  }
  const onExport = (orgName: string, fileName: string) => {
    setDownloading(true)
    paramsForExport(orgName).then((params: any) => {
      new EventLogService().exportEventLogList(params).then((res: any) => {
        var _url = window.URL.createObjectURL(res);
        var anchor = document.createElement('a');
        anchor.download = fileName;
        anchor.href = _url;
        anchor.click();
        setDownloading(false)
      })
    })
  }
  const dataDisplayed = () => {
    if (totalPages > 1) {
      let limit = parseInt(process.env.REACT_APP_EVENTS_LIMIT) || DEFAULT_LIMIT
      let start = limit*(currentPage - 1) + 1
      let end = limit*(currentPage - 1) + eventLogList?.length
      return `${start}-${end} ${t(jsonData.eventsshown)}`
    }
    else {
      return `${totalEvents} ${t(jsonData.eventsshown)}`
    }
  }

  useEffect(()=>{
    if(filtersChanged){
      clearLocationState();
    }
  }, [filtersChanged])

  const onRefresh = () =>{
    setCurrentIndex(0)
    callEventLogApi(DEFAULT_PAGE , searchtext)
  }

  return (
    <div className="eventLogsHeaderDiv">
      <EventLogsHeader
        handleSearch={searchUpdate}
        filterCsv={filterCsvData}
        headers={headers}
        headerIcon={true}
        scrolltop={scrolltotop}
        scrollstate={scroll}
        loading={loadingProp}
        onSendCalculatedDate={onSendCalculatedDate}
        onSwitching={onSwitching}
        onEventHeaderResize={eventHeaderResized}
        clearAllFilters={resetToDefault}
        filteredInfo={filteredInfo}
        onExport={onExport}
        dataDisplayed={dataDisplayed}
        applyFilter={applyFilter}
        filtersChanged={filtersChanged}
        onRefresh= {onRefresh}
        downloading = {downloading}
        setFiltersChanged={setFiltersChanged}
        setSelectedDeviceStates={setSelectedDeviceStates}
        oldFilters={oldFilters}
        setFilterVal={setFiltersVal}
        clearLocationState={clearLocationState}
      />
      <Loader loading={loading}>
      <div className="eventLogList">
            <AntdInfinityTable
              // {...expandable}
              tableClassName = {'eventAntdcustomtable'}
              pagination={false}
              onScroll={onScroll}
              scroll={{
                y: scrollHeight,
                scrollToFirstRowOnChange: true,
              }}
              //@ts-ignore
              dataSource={tableData}
              className={`table eventTable`}
              //@ts-ignore
              columns={filtercoumn}
              locale={!loading && !tableData.length && { emptyText: t(jsonData.NoDataFound) }}
              rowClassName={setRowClassName}
              onChange={(pagination, filters, sorter, extra) => {
                setFilterCsvData(extra.currentDataSource);
                filterSorting(pagination, filters, sorter, extra)
              }}
              rowKey="key"
              loading={loading}
              onFetch={handleFetch}
              pageSize={parseInt(process.env.REACT_APP_EVENTS_LIMIT) || DEFAULT_LIMIT}
              debug
            />
        {
          totalPages > 1 &&
          <AntdPagination className='event-pagination' total={totalEvents}  current={currentPage} defaultPageSize={parseInt(process.env.REACT_APP_EVENTS_LIMIT) || DEFAULT_LIMIT} onChange={(page: any, pageSize: any) => onPageChange(page)}/>
        }
        </div>
      </Loader>
    </div>
  );
};
