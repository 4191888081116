import styled from 'styled-components'
import { Col } from 'antd';

export const RolesDetailsWrapper = styled.div`

`
export const RolesDetailsContent = styled.div`
`
export const PageHeaderTitle = styled.div`
    padding-bottom: 15px;
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-size: ${(props) => props.theme.fontSizes.h1};
    line-height: 20px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.text.primary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
export const PageHeaderContent = styled.div`
    padding-top: 0px;
    padding-left: 23px;
    margin-bottom: 54px;
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${(props) => props.theme.fontSizes.body};
    line-height: 20px;
    color: ${(props) => props.theme.colors.text.secondary};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
`
export const HeaderInputLabel = styled.div`
font-family: ${(props) => props.theme.fonts[0]};
font-style: normal;
font-weight: ${(props) => props.theme.fontWeights.normal};
font-size: ${(props) => props.theme.fontSizes.smallBody};
line-height: 20px;
margin-bottom: 5px;
color: ${(props) => props.theme.colors.text.primary};
`

export const RoleDetailError = styled.div`
    padding-left: 23px;
    padding-bottom: 5px;
`

export const RolesFormGap = styled(Col)`
    margin-top: 30px;
`