/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {convertUserFormatToPhoneNumber} from "../../../../../../utility/appUtil";
import { Row, Col, Form } from "antd";
import { parseName } from "humanparser";
import {Button} from "../../../../../../bit_components/common/button";
import "../../profile/userProfileForm/userProfileForm.less";
import {Dropdown} from "../../../../../../bit_components/common/dropdown";
import User from "../../../../../models/user/user";
import { AppError } from "../../../../stateless/common/appError";
import './inviteUserForm.less';
import {Custominput} from "../../../../../../bit_components/common/custominput";
import { cameFromOrganisation } from "../../../../../../utility/appUtil";
import { useSelector } from "react-redux";
import { appSelector } from "../../../../../../redux/reducers/app/appReducers";
import { UserRole } from "../../../../../constants/enums";
import { Multiselect } from "../../../../../../bit_components/common/multiselect";

export const InviteUserForm = (props: any) => {
  const { formName, formLayout, roles, locations, formSubmitCallback, appUser, errorMsg } = props;
  const [selectedLocation, setSelectedLocation] = useState(new Array<any>());
  const [roleId, setRoleId] = useState<string>();
  const { selectedOrganisation } = useSelector(appSelector);
  const [phoneNumber, setPhoneNumber] = useState<any>()
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false)
  const [inputPhoneNumber,setInputPhoneNumber]=useState<any>()
  const [inviteForm] = Form.useForm()

  const getOrgId = () => {
    let orgId = appUser?.organization.id as string;
    if(cameFromOrganisation()) {
      orgId = selectedOrganisation?.id;
    }
    return orgId;
  }

  const [orgId, setOrgId] =  useState(getOrgId());

  useEffect(()=>{
    setOrgId(getOrgId());
  }, [selectedOrganisation])

  const getRoles = () => {
    let arrRole: any[] = [];
    if (roles && roles.length) {
      arrRole = arrRole.concat(
        roles.map((x: any) => {
          return { text: x.name, value: x.id };
        })
      );
    }
    return arrRole;
  }

  const onSelectLocationsCallback = (values: any[]) => {
      setSelectedLocation(values? values: []);
  };
 
 
  const getLocations = () => {
    const arrLocation = locations.map((x: any) => {
      return {
        label: x.name,
        value: x.id
      };
    });
    return arrLocation;
  };
 
 
  const getSelectedLocationList = () => {
    const arrLocation = selectedLocation.map((x: any) => {
      return {
        name: x.label,
        id: x.value
      };
    });
    return arrLocation;
  };
 

  const onPhoneNumberChange=(event:any)=>{
  let data=convertUserFormatToPhoneNumber(event?.target?.value)
      setInputPhoneNumber(event?.target?.value)
    if(data?.isValidNumber){
      setPhoneNumber(data?.formatNumber)
      setInvalidPhoneNumber(false)
    }
    else{
      setInvalidPhoneNumber(true)
    }
  }

  // const getLocations = (): Location[] => {
  //   let locations = new Array<Location>();
  //   const ctrls = document.querySelectorAll("input.locations-list[type='checkbox']");
  //   if (ctrls && ctrls.length) {
  //     let selected = false;
  //     ctrls.forEach((x: any) => {
  //       if (x.checked) {
  //         const location: Location = {
  //           id: x.value,
  //           name: x.attributes["label"].value,
  //         };
  //         locations.push(location);
  //       }
  //     });

  //   }
  //   return locations;
  // };

  const onSubmit = (data: any) => {
    const parsedName = parseName(data.fullName);
    let lastName = parsedName?.lastName
    let suffix = parsedName?.suffix
    lastName = suffix? (lastName? lastName+" "+suffix : suffix) : lastName 
    let location = roles.find((x: any) => x.id === data.role)?.name === UserRole.ORGANISATION_ADMIN ? locations : getSelectedLocationList()
    const user: User = {
      id: '',
      emailVerifiedAt:data.emailVerifiedAt,
      title: parsedName.salutation ? parsedName.salutation : "",
      firstName: parsedName.firstName ? parsedName.firstName : "",
      middleName: parsedName.middleName ? parsedName.middleName : "",
      userName: '',
      lastName: lastName ? lastName : "",
      profileUrl: "",
      isActive: true,
      email: data.email,
      phone: phoneNumber, 
      role: {
        id: data.role,
        name: roles.find((x: any) => x.id === data.role)?.name,
      },
      organization: {
        id: orgId,
        name: "",
      },
      locations: location,
      userClaim: {}
    };
    if (formSubmitCallback) {
      formSubmitCallback(user);
    }
  };

  const onSelectRole = (roleId: string) => {
    setSelectedLocation([]);
    setRoleId(roleId);  
    if(roles.find((x: any) => x.id === roleId)?.name === UserRole.ORGANISATION_ADMIN){
      setSelectedLocation(locations)
    }
    inviteForm?.setFieldsValue({
      role: roleId,
      locations: locations
    })
}

  return (
    <div className="inviteUserCover pl-vw">
      <AppError show={errorMsg ? true: false} errorMsg={errorMsg} />

      <Form
        {...formLayout}
        form={inviteForm}
        name={formName}
        layout='vertical'
        className="profile-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onSubmit}
      >
        <Row align="top">
          <Col xs={11} md={11} lg={7}>
            <Custominput
              labelSubName="Full Name"
              customLabelClass="labelName pb-15"
              labelName="fullName"
              rules={[
                {
                  required: true,
                  message: 'Please input your Full Name!',
                },
                {
                  max: 100,
                  message: "Please enter a Name shorter than 100 character long."
                },
              ]}
              placeholder="Full Name"
            />
            <Custominput
              labelSubName="Email Address"
              customLabelClass="labelName pb-15 pt-15"
              labelName="email"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Email Address'
                },
                {
                  type: 'email',
                  message: 'Please enter a valid email address',
                },
              ]}
              placeholder="Email Address"
            />
            <Custominput
              labelSubName="Contact Number"
              customLabelClass="labelName pb-15 pt-15"
              labelName="phone"
              onChange={onPhoneNumberChange}
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please input your Phone Number!', // TODO: Handle this while developing validation framework.
              //   },
              //   ({ getFieldValue }) => ({
              //      validator(_, value) {
              //       if(!value)
              //       return Promise.resolve()
              //             let phoneNumber=value.split(/\s/).join('')
              //             if((phoneNumber[0]==='+'||phoneNumber[0]==='(')&&(/[0-9\)]+$/.test(phoneNumber)))
              //             {
              //                 if(phoneNumber?.length&& phoneNumber?.length>6)
              //                 { 
              //                   if(!invalidPhoneNumber)
              //                   {
              //                   return Promise.resolve()  
              //                   }
              //                   return Promise.reject(
              //                   new Error('Please enter a valid Phone Number'),
              //                   )
              //                 }
              //                  else  
              //                 {
              //                   if(phoneNumber?.length>=1)
              //                   return Promise.reject(
              //                   new Error('Please enter a Phone Number larger than 5 digits.'),
              //                   )  
              //                 }
                          
              //             }
              //             else if(/^[0-9]+$/.test(phoneNumber))
              //             {
              //                 if(phoneNumber?.length&& phoneNumber?.length>5)
              //                 {
              //                   if(!invalidPhoneNumber){
              //                   return Promise.resolve()
              //                 }
              //                 return Promise.reject(
              //                 new Error('Please enter a valid Phone Number'),
              //                 )
              //                 }
              //                 else  {
              //                   if(phoneNumber?.length>=1)
              //                   return Promise.reject(
              //                   new Error('Please enter a Phone Number larger than 5 digits.'),
              //                 )  
              //                 }
              //             }
              //             else{
              //                 return Promise.reject(
              //                 new Error('Please enter a valid Phone Number')
              //                 )
              //             }
              //     },
              //   })// TODO: Handle this while developing validation framework.
              // ]}
              placeholder="Contact Number" />

            <Form.Item className="pt-35 pb-15 mb-0">
              <Button
              type="primary"
              htmlType="submit"
              >
                Add User
              </Button>
            </Form.Item>
          </Col>
          <Col xs={{ span: 11, offset: 2}} md={{ span: 11, offset: 2}} lg={{ span: 7, offset: 2}}>
            <Form.Item
              name="role"
              label="Role"
              rules={[
                {
                  required: true,
                  message: "Please Select a Role"
                },
              ]}
              className='label-cls selectRole pb-15'
            >
              <Dropdown
              optionValue={getRoles()} defaultValue="Select Role"
              onChange={onSelectRole}
              />
            </Form.Item>
            <Col>
             <Form.Item
               name="locations"
               label="Locations"
               className="label-cls pt-15 locationErrorStyle"
               rules={[
                 ({ getFieldValue }) => ({
                   validator(_, value) {
                     const selected = selectedLocation;
                     if (selected && selected.length) {
                       return Promise.resolve();
                     }
                     return Promise.reject(new Error('Please select a Location!')); // TODO: Handle this while developing validation framework.
                   },
                 }),
               ]}
             >
                {/* Add css 'ant-input' on div.dropdown-heading-value */}
                {
                  roles.find((x: any) => x.id === roleId)?.name === UserRole.ORGANISATION_ADMIN ?
                    <div className='branches-wrapper-for-0 disableIcon'>
                      <div className='checkbox-label-cls-unselected'>All location selected</div></div> :
                    <div className={`${formName}_locations locations-multi-select ant-row ant-form-item ant-form-item-with-help profile-label-cls`}>
                      <div className="ant-col ant-col-24 ant-form-item-control inviteUserMultiselect">
                        <div className="ant-form-item-control-input">
                          <div className={roles.find((x: any) => x.id === roleId)?.name === UserRole.ORGANISATION_ADMIN ? "ant-form-item-control-input-content grayBackground" : "ant-form-item-control-input-content "}>
                            <Multiselect
                              labelledBy="Select Locations"
                              value={selectedLocation}
                              options={getLocations()}
                              onChange={onSelectLocationsCallback}
                              overrideStrings={{ 'allItemsAreSelected': "All Locations selected" }}
                              disabled={roles.find((x: any) => x.id === roleId)?.name === UserRole.ORGANISATION_ADMIN}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                }
               </Form.Item>
             </Col>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
