import styled from 'styled-components'

export const ItemWrapper = styled.div`
    padding-top: 30px;
    padding-bottom: 36px;
    padding-left: 55px;
    padding-right: 55px;
`
export const CheckBoxWrapper = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
`
export const ItemTitle = styled.div`
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: 20px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.text.primary};
    padding-right: 55px;
`

export const ItemContent = styled.div`
    margin-top: 15px;
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${(props) => props.theme.fontSizes.smallBody};
    line-height: 20px;
    color: ${(props) => props.theme.colors.palette.darkestGray};
    padding-right: 55px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
`