import React from 'react';
import './button.less';

export type ButtonProps = {
  id?:string,
  type:string,
  onClick?:any,
  disabled?:any,
  children:any,
  htmlType?: any,
  tabIndex?:any,
  onKeyPress?: any
  ariaLabel?:any
  role?:any
};

export function Button({ ...props }: ButtonProps) {
  return (
    <button
      id={props?.id}
      className={`btnCommon ${props.type}`}
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.htmlType}
      tabIndex={props?.tabIndex}
      onKeyPress={props.onKeyPress}
      aria-label={props.ariaLabel}
      role={props.role}
    >
      {props.children}
    </button> 
  );
}
