import { AnyAction, configureStore, Reducer } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import appReducer, { RootState } from "./rootReducer";
import { AppStateStores } from "../../web/services/storage/appStateService";
import { encryptTransform } from 'redux-persist-transform-encrypt';

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY,
  onError: function (error) {
    console.log("Error in encryptTransform", error);
  },
});
const persistConfig = {
  key: AppStateStores.APP_STATE,
  storage,
  blacklist: ['eventLogs', 'auditLogs', 'alert', 'alertConfig', 'notifications', 'organisations',
  'depth', 'userProfile', 'common', 'permission'],
  transforms: [encryptor] 
};

const RootReducer:Reducer =  (state:RootState, action:AnyAction)=> {
    if (action.type === 'app/logout') {
      //|| action.type ==='app/ssoLogout' removed due to Logout issue
      storage.removeItem('persist:root')
      state = {} as RootState
    }
    return appReducer(state, action);
  }

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;
