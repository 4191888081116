import jsonData from '../../languages/mapingMiddleware.json'
export const Messages = {
  EMAIL_AUTH_FAILED: jsonData.EMAIL_AUTH_FAILED,
  EMAIL_BLOCKED: jsonData.EMAIL_BLOCKED,
  CHECK_EMAIL_FAILED: jsonData.CHECK_EMAIL_FAILED,
  AUTH_FAILED: jsonData.AUTH_FAILED,
  PWD_RESET_SUCCESS: jsonData.PWD_RESET_SUCCESS,
  PWD_CREATED_SUCCESS: jsonData.PWD_CREATED_SUCCESS,
  PWD_RESET_LINK_EXPIRED: jsonData.PWD_RESET_LINK_EXPIRED,
  USER_ALREADY_EXISTS: jsonData.USER_ALREADY_EXISTS,
  USER_INVITED_SUCCESSFULLY: jsonData.USER_INVITED_SUCCESSFULLY,
  USER_BLOCKED_SUCCESSFULLY: jsonData.USER_BLOCKED_SUCCESSFULLY,
  USER_REMOVED_SUCCESSFULLY: jsonData.USER_REMOVED_SUCCESSFULLY,
  LOCATION_NOT_SELECTED: jsonData.LOCATION_NOT_SELECTED,
  USER_UNBLOCKED_SUCCESSFULLY: jsonData.USER_UNBLOCKED_SUCCESSFULLY,
  USER_UPDATED_SUCCESSFULLY: jsonData.USER_UPDATED_SUCCESSFULLY,
  ROLE_ALREADY_EXISTS: jsonData.ROLE_ALREADY_EXISTS,
  HIERARCHY_ALREADY_EXISTS: jsonData.HIERARCHY_ALREADY_EXISTS,
  INVALID_COUNTRY_CODE: jsonData.INVALID_COUNTRY_CODE,
  INVALID_RANGE: jsonData.INVALID_RANGE,
  SELECT_START_DATE: jsonData.SELECT_START_DATE,
  SELECT_END_DATE: jsonData.SELECT_END_DATE,
  DATA_NOT_SUPPORTED: jsonData.DATA_NOT_SUPPORTED,
  ALERT_CLEARED: jsonData.ALERT_CLEARED,
  ERROR_OCCURRED: jsonData.ERROR_OCCURRED,
  ALERT_SETTINGS_UPDATED: jsonData.ALERT_SETTINGS_UPDATED,
  DEVICE_UPDATED: jsonData.DEVICE_UPDATED,
  DEVICE_ADDED: jsonData.DEVICE_ADDED,
  DEVICE_DELETED: jsonData.DEVICE_DELETED,
  UPDATED: jsonData.UPDATED,
  SAVED: jsonData.SAVED,
  DELETED: jsonData.DELETED,
  SCHEDULE_SAVED: jsonData.SCHEDULE_SAVED,
  SCHEDULE_DELETED: jsonData.SCHEDULE_DELETED,
  SCHEDULE_UPDATE: jsonData.SCHEDULE_UPDATE,
  RUN_NOW: jsonData.RUN_NOW,
  POP_UP_BANNER: jsonData.POP_UP_BANNER,
};
