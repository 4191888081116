import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
} from "../../../utility/stateUtil";
import { saveDirtyFormValues, saveFormValues } from "../../actions/common/commonAction";

import { CommonState } from "../../states/common/commonState";
import { RootState } from "../../store/rootReducer";

const initialState: CommonState = {
  savedFormValues: [],
  formState: resetState(),
  saveDirtyFormState:resetState(),
};

export const CommonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      return state;
    },

    clearSavedFormValues: (state) => {
      return {...state, savedFormValues: [], isDirty: false}
    },

    setIsDirty: (state, {payload}) => {
      return {...state, isDirty: payload}
    }
  },
  extraReducers: {
    [saveFormValues.fulfilled.toString()]: (state, { payload }) => {
      //debugger;
      return {...state, savedFormValues : payload, formState : fulfilledState()}
    },
    [saveFormValues.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [saveFormValues.pending.toString()]: (state) => {
      state.formState = pendingState();
    },

    [saveDirtyFormValues.fulfilled.toString()]: (state, { payload }) => {
      //debugger;
      return {...state, savedFormValues : payload.savedFormValues, isDirty: payload.isDirty, saveDirtyFormState: fulfilledState()}
    },
    [saveDirtyFormValues.rejected.toString()]: (state, { payload }) => {
      state.saveDirtyFormState = rejectedState(payload?.message);
    },
    [saveDirtyFormValues.pending.toString()]: (state) => {
      state.saveDirtyFormState = pendingState();
    },
  },
});

export const { clearState, clearSavedFormValues, setIsDirty } = CommonSlice.actions;
export const commonSelector = (state: RootState) => state.common;
export default CommonSlice.reducer;
