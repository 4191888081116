/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// pacakge imports
import React, { useEffect, useState } from 'react';
import { PageHeader, Button, Form, Tooltip} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"

// import assets
import { DeleteIconImg } from '../../../../../images/index';

// module imports
import { Role } from '../../../../../models/common/role';
import { getUsersCountForRole, deleteRole } from '../../../../../../redux/actions/role/roleAction';
import { AppRoutes } from '../../../../../constants/enums';
import { Breadcrumbs } from '../../../../stateless/common/breadcrumbs';
import ConfirmModal from "../../rolesModal";
import { AppService } from "../../../../../services/ui/appService";
import { clearRoleDeleteFormState, roleListSelector } from '../../../../../../redux/reducers/role/roleListReducer';
import { clearState } from '../../../../../../redux/reducers/user/userListReducer';

// styles imports
import '../rolesDetails.css';
import './customRoleHeader.less';
import { HeaderDeleteImg, PageHeaderTitle } from './customRoleHeader.styles';
import { urlModification } from '../../../../../../utility/appUtil';
import WarnModal from '../../../../stateless/common/warnModal';
import { DeleteRoleWarnModal, jsonData } from '../../../../../constants/constants';
import { useTranslation } from 'react-i18next';


interface CustomRoleHeaderProps {
    role: Role;
    orgRoles: Role[];
    handleApplyBtnChanges: Function;
    roleDetails: Role;
    changed: boolean
}

let tempDltBtn = false;

export function CustomRoleHeader(props: CustomRoleHeaderProps) {
    const { t } = useTranslation()
    const role = props?.role;
    const [isModalVisible, setModalVisible] = useState(false)
    const dispatch = useDispatch();
    const { selectedRoleUserCount, roleDeleted, roleDeleteFormState, formState } = useSelector(roleListSelector);
    const history = useHistory();
    const [deleteBtnClick, setDeleteBtnClick] = useState(false);
    const [userCount, setUserCount] = useState(selectedRoleUserCount)
    const [showWarn, setShowWarn] = useState(false)

    useEffect(() => {
        dispatch(clearState());
        role?.id && dispatch(getUsersCountForRole(role?.id))
    }, [])

    // set user count whenever selectedRoleUserCount changes
    useEffect(() => {
        setUserCount(selectedRoleUserCount);
    }, [selectedRoleUserCount])

    useEffect(() => {
        if (roleDeleted && roleDeleteFormState.isSuccess && tempDltBtn) {
            tempDltBtn= false;
            history.push(urlModification(AppRoutes.ROLES_LIST));
            AppService.showToast(`${role?.name} ${t(jsonData.deletedSuccessfully)}`);
            dispatch(clearRoleDeleteFormState());
            }
    }, [tempDltBtn, roleDeleteFormState.isSuccess , roleDeleted]);


    const routes = [{
        href: urlModification(AppRoutes.ROLES_LIST),
        text: 'Roles',
    },
    // {
    //     href: '/roles/details',
    //     text: role?.name,
    // }
    ]
    const handleDeleteBtnClick = () => {
        if (userCount > 0) {
            setModalVisible(true);
        }
        else { 
            setShowWarn(true);
        }
    }

    const onConfirmCallback = () => {
        setDeleteBtnClick(true);
        tempDltBtn = true;
        dispatch(deleteRole({toBeDeleteRoleId: role?.id}))
        setShowWarn(false)
    }

    const handleKeyPress = () => {
        history.push(urlModification(AppRoutes.ROLES_LIST));
    }

    return (
        <Form onFinish={(e: any) => props.handleApplyBtnChanges(e)}>
        <PageHeader
            className="roleStyle"
            title={<PageHeaderTitle key={role?.name}>{role?.name}</PageHeaderTitle>}
            breadcrumb={<div onKeyPress={handleKeyPress}><Breadcrumbs breadcrumbs={routes} aria-label={""} role={""}></Breadcrumbs></div>}
            extra={[
                <span  aria-label={""} role={""} onKeyPress={handleDeleteBtnClick}>
                     <Tooltip placement="bottomRight" title={t(jsonData.DeleteRole)}>
                <Button  className='dlt-btn' key="1" onClick={handleDeleteBtnClick}><HeaderDeleteImg src={DeleteIconImg} alt='delete icon' /></Button>
                </Tooltip>
                </span>
,
                formState.loading?
                <Button disabled className='all-saved-btn'>
                <div className="loaderButton"><span className="spinner"></span></div>{t(jsonData.Saving)}
                </Button>
                :
                props.changed ? <Button className='apply-btn' key="2" htmlType="submit">
                     <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title= {t(jsonData.ApplyChanges)}>
                     {t(jsonData.ApplyChanges)}
                     </Tooltip>
                    </Button>
                    :
                    <Button className='all-saved-btn' key="3" disabled>{t(jsonData.Saved)}</Button>
                ]}
        />
        {userCount > 0 ? <ConfirmModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} roles={props.orgRoles} selectedRole={role} roleUsers={selectedRoleUserCount} />
        :null}
         <WarnModal
            cancelButton={t(DeleteRoleWarnModal.CANCEL_BTN_TEXT)}
            confirmButton={t(DeleteRoleWarnModal.CONFIRM_BTN_TEXT)}
            primaryText={t(DeleteRoleWarnModal.TITLE)}
            secondaryText={t(DeleteRoleWarnModal.CONTENT)}
            isModalVisible={showWarn}
            cancelCallback={() =>{ 
            setShowWarn(false); 
            }}
            confirmCallback={onConfirmCallback}
         />
        </Form>
    );
};
