/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { Dropdown } from '../../../../../bit_components/common/dropdown'
import { userStateSelector } from '../../../../../redux/reducers/user/userReducer'
import {
  AlertHead,
  AlertDiv,
  DropDownWrapper,
  AlertHeadBack,
  AlertHeaders,
} from './AlertHeader.styles'
import { Col, Row, Tooltip } from 'antd'
import {
  locationListSelector,
} from '../../../../../redux/reducers/location/locationListReducer'
import { DeviceSelector } from '../../../../../redux/reducers/device/deviceReducer'
import { cameFromOrganisation, getCustomDuration, getFormattedDate, translateOptions } from '../../../../../utility/appUtil'
import { appSelector, goToPrevious, setAlertClicked, setAlertSeverity } from '../../../../../redux/reducers/app/appReducers'
import './alertHeader.less'
import { AlertDropDownConstants, AlertStatusDropdown, DefaultTime, filterOptions, jsonData, TimePattern } from '../../../../constants/constants'
import { sortList } from '../../../../../utility/utils'
import MultiSelectButton from "../../../stateless/common/multiSelect/multiSelectButton/multiSelectButton"
import CustomRange from '../../../stateless/common/customRange'
import { fetchDeviceListByOrgId } from '../../../../../redux/actions/device/deviceAction'
import { BackwardVectorDark } from '../../../../images'
import { AlertSelector } from '../../../../../redux/reducers/alert/alertReducer'
import moment from "moment-timezone";
import { useTranslation } from 'react-i18next';
import { UseGAEventTracker } from '../../../../../utility/useGAEventTracker'
import secureLocalStorage from 'react-secure-storage'

  const AlertHeader = (props: any) => {
  let localeInfo:any = secureLocalStorage.getItem('locale')
  moment.tz.setDefault(localeInfo.timezone)
  const selectedDate = moment();
  const datePattern = "D MMMM, YYYY " + TimePattern.HH_MM
  const dispatch = useDispatch()
  const location: any = useLocation()
  const history = useHistory()
  const { locations } = useSelector(locationListSelector)
  const { deviceList } = useSelector(DeviceSelector)
  const { appUser } = useSelector(userStateSelector)
  const { selectedOrganisation, showBackButton, showCustomRange } = useSelector(appSelector)
  const {fetchAlertFormState, additionalApiPayload } = useSelector(AlertSelector)
  const [selectedLocations, setSelectedLocations] = useState([])
  const [selectedDevices, setSelectedDevices] = useState([])
  const [paramsUpdated, setParamsUpdated] = useState<boolean>(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [startTime, setStartTime] = useState(DefaultTime.START)
  const [endTime, setEndTime] = useState(DefaultTime.END)
  const [isCustomDate, setIsCustomDate] = useState<boolean>(false)
  const {alertSeverity,alertClicked} = useSelector(appSelector)
  const [filterDuration, setFilterDuration] = useState<any | null>((!location?.state && !alertClicked) ? AlertDropDownConstants.YEAR_VALUE : AlertDropDownConstants.SELECT_DURATION_VALUE)
  const [customValues, setCustomValues] = useState<boolean>(showBackButton)

  const { alertType, setAlertType } = props;
  const { t } = useTranslation()
  
  useEffect(() => {
    return () => {
      dispatch(goToPrevious({showBackButton:false, showCustomRange: false}))
      dispatch(setAlertSeverity(null))
    }
  }, [])

  useEffect(() => {
    if (location?.state?.location) {
      let info = location?.state?.location
      updateCustomRange(info)

    }
    else if (location?.state?.device) {
      let info = location?.state?.device
      updateCustomRange(info)

    }
    else if(location?.state?.alert) {
      let info = location?.state?.alert
      updateCustomRange(info)



    }
  }, [location?.state])

  const updateCustomRange = (info: any) => {
    const values = getCustomDuration(info?.eventTimeStamp, info?.eventTimeStamp)
    onCustomRange(values)
    setCustomValues(true)
    setFilterDuration(AlertDropDownConstants.CUSTOM_VALUE)
  }

  useEffect(() => {
    if (selectedLocations.length < locations.length && selectedLocations.length) {
      dispatch(fetchDeviceListByOrgId({ orgId: getOrgId(), locations: selectedLocations }))
    }
    else {
      dispatch(fetchDeviceListByOrgId({ orgId: getOrgId() }))
    }
     !alertClicked && setParamsUpdated(true)
  }, [selectedLocations])

  useEffect(() => {
    if (paramsUpdated && !isCustomDate && !alertClicked) {
      let params = {
        organizationId: getOrgId(),
        startDate: filterDuration !== AlertDropDownConstants.SELECT_DURATION_VALUE ? startDate + ' ' + startTime : null ,
        endDate: filterDuration !== AlertDropDownConstants.SELECT_DURATION_VALUE ? endDate + ' ' + endTime : null,
        isCleared: alertType,
        hierarchyIdList:
          selectedLocations.length && (selectedLocations.length < locations.length)
            ? selectedLocations
            : [],
        deviceIdList:
          selectedDevices.length 
            ? selectedDevices
            : [],
      }

      props?.onParamsUpdated(params)
      setParamsUpdated(false)
    }
  }, [selectedLocations, selectedDevices, startDate, endDate, startTime, endTime, alertType, isCustomDate])

  const getOrgId = () => {
    let orgId = appUser?.organization.id as string
    if (cameFromOrganisation()) {
      orgId = selectedOrganisation?.id
    }
    return orgId
  }


  /************* Location selector handling ************/
  
  const getLocations = () => {
    const arrLocation = locations.map((x: any) => {
      return {
        label: x.name,
        value: x.id.toString(),
      }
    })
    const sortedBylabel = arrLocation.sort(sortList('label'));
    return sortedBylabel
  }
  
  const updateLocations = (selected) => {
    selectedDevices.length > 0 && setSelectedDevices([])
    setSelectedLocations(selected)
    !alertClicked && setParamsUpdated(true)
  }


  /************* Devices selector handling ************/
  
  const getDevices = () => {
    const arrDevices = deviceList.map((x: any) => {
      return {
        label: x?.info?.name ?? (x?.serialNo ?? x.id),
        value: x.id.toString(),
      }
    })
    const sortedBylabel = arrDevices.sort(sortList('label'));
    return sortedBylabel
  }
  
  const updateDevices = (selected) => {
    !alertClicked && setParamsUpdated(true)
    setSelectedDevices(selected)
  }


  /************* Handle Duration Filter ************/
  const onChangeDuration = (value: string) => {
    dispatch(goToPrevious({showBackButton: showBackButton, showCustomRange: false}))
    setFilterDuration(value)
    UseGAEventTracker("Alert Center Category", "Alert Center Duration Filter", `Duration: ${value} selected`)
  }

  useEffect(() => {
    if(!customValues && !showCustomRange){
      setIsCustomDate(false)
      filterDuration && updateDates(filterDuration)
    }
  }, [filterDuration])

  const updateDates = (value: any) => {
    let durationObj: any = {}
    if(value !== AlertDropDownConstants.CUSTOM_VALUE) {
      durationObj =
      {
        endDate: selectedDate.format(datePattern),
        startDate: selectedDate.subtract(1, value).format(datePattern)
      }
      setDate(durationObj.startDate, durationObj.endDate)
    }
    else if (value === AlertDropDownConstants.CUSTOM_VALUE) {
      setIsCustomDate(true)
    }
  }

  // set date
  const setDate = (startDateValue: string, endDateValue: string) => {
    let startDate = getFormattedDate(startDateValue,"YYYY-MM-DD")
    let endDate = getFormattedDate(endDateValue,"YYYY-MM-DD")
    let startTime = getFormattedDate(startDateValue,TimePattern.HH_MM)
    let endTime = getFormattedDate(endDateValue,TimePattern.HH_MM)
    
    setStartDate(startDate)
    setEndDate(endDate)
    setStartTime(startTime)
    setEndTime(endTime)
    !alertClicked && setParamsUpdated(true)
  }

  const onCustomRange = (values: any) => {
    !alertClicked && setParamsUpdated(true)
    setEndDate(values?.customEndDate)
    setStartDate(values?.customStartDate)
    setStartTime(values?.customStartTime)
    setEndTime(values?.customEndTime)
    setIsCustomDate(false)
    UseGAEventTracker("Alert Center Category", "Alert Center Custom Date Range Selection", `Selected Custom Date is: ${values?.customStartDate} to ${values?.customEndDate} ` )
  }


  /************************ Handle Alert Type Filter **********************/
  const onChangeAlertType = (value) => {
    !alertClicked && setParamsUpdated(true)
    setAlertType(value)

    let currentState = (alertType === true) ? "Active" : "Cleared";
    UseGAEventTracker("Alert Center Category", "Alert Center Active/Cleared Filter", `${currentState} filter selected`)
  }

  const goBack = () => {
    history.goBack()
  }

  useEffect(()=>{
    if(alertClicked){
      setFilterDuration(AlertDropDownConstants.SELECT_DURATION_VALUE)
      setAlertType(AlertStatusDropdown.ACTIVE_VALUE)
      setSelectedLocations([])
      setSelectedDevices([])
      let params = {
        organizationId: getOrgId(),
        startDate:null,
        endDate: null,
        isCleared: AlertStatusDropdown.ACTIVE_VALUE,
        hierarchyIdList: [],
        deviceIdList:[],
        severity:[alertSeverity]
      }
      props?.onParamsUpdated(params)
    }
  },[alertClicked])

  useEffect(()=>{
    if(alertClicked && (fetchAlertFormState.isSuccess || fetchAlertFormState.isError)){
      dispatch(setAlertClicked(false))
      setParamsUpdated(false)
    }
  },[fetchAlertFormState])

  return (
    <AlertHead>
      <AlertHeadBack span={24} showback={showBackButton?.toString()}>
        <div className="backDiv">
          <span onClick={goBack} className="backToPreviousPage">
            <img src={BackwardVectorDark} alt=""/>
            {t(jsonData.Back)}
          </span>
        </div>
      </AlertHeadBack>
      <Row>
          <AlertDiv>{t(jsonData.AlertsCentre.replace(/\s/g, ''))}</AlertDiv>
        <AlertHeaders>
          <Row>
            <Col>
            {/* <Tooltip overlayClassName='dashBoardTooltip tooltipCommon' placement="bottom" title={t(jsonData.Active)}> */}
              <DropDownWrapper>
                <Dropdown
                 ariaLabel={t(jsonData.Active)}
                  dropdownAlign={{ offset: [-125, 4] }}
                  className="filterDropDown alertPageDropdown"
                  onChange={onChangeAlertType}
                  value={alertType}
                  bordered={false}
                  optionValue={translateOptions(filterOptions.alertStatus,t,'text')}
                  tabIndex={0}
                />
              </DropDownWrapper>
              {/* </Tooltip> */}
            </Col>
            {/* <Col>
              <MultiSelectButton
                optionList={getLocations()}
                onApply={updateLocations}
                selectedItems={selectedLocations}
                allSelectedText='All Locations'
                name='location'
                placeholder='Select Location'
                optionListClass='location-optionList'
                wrapperClassName='location-multiselect'
              />
            </Col>
            <Col>
              <MultiSelectButton
                optionList={getDevices()}
                onApply={updateDevices}
                selectedItems={selectedDevices}
                allSelectedText='All Devices'
                name='device'
                placeholder='Select Device'
                optionListClass='device-optionList'
                wrapperClassName='device-multiselect'
              />
            </Col> */}
            <Col>
            {/* <Tooltip overlayClassName='dashBoardTooltip tooltipCommon' placement="bottom" title={t(jsonData.selectDuration)}> */}
              <DropDownWrapper>
                <Dropdown
                 ariaLabel={t(jsonData.Day)}
                defaultValue={AlertDropDownConstants.DAY_VALUE}
                  className="filterDropDown alertPageDropdown"
                  onChange={onChangeDuration}
                  value={filterDuration}
                  bordered={false}
                  optionValue={translateOptions(filterOptions.alertDateRange,t,'text')}
                  tabIndex={0}
                />
              </DropDownWrapper>
              {/* </Tooltip> */}
            </Col>
          </Row>
        </AlertHeaders>
      </Row>
      {filterDuration === AlertDropDownConstants.CUSTOM_VALUE ?
        <CustomRange onChange={onCustomRange} displayHeading='From' customValues={customValues} setCustomValues={setCustomValues} start={startDate} end={endDate} from={startTime} to={endTime}/>
        :
        ''
      }
    </AlertHead>
  )
}

export default AlertHeader;