/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import { eventLocation, caretRight } from "../../../../images";
import "./eventOutput.less";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Helmet } from "react-helmet";
import {
  EventCategory, EventDate, EventDateDiv, EventId, EventLocation, EventName,
  EventSeverity, KnownEvent, UnknownEvent, EventDeviceName, EmptyTracelog,
  DescriptionBox
} from "./index.styles";
import Loader from "../../../stateless/common/spinner";
import { useSelector, useDispatch } from "react-redux";
import { clearUnstructuredData, eventLogListSelector } from "../../../../../redux/reducers/eventLogs/eventLogListReducer";
import { fetchUnstructuredData } from "../../../../../redux/actions/eventLogs/eventListAction";
import { getStatusTypeEllipse } from "../../../../../utility/utils";
import Marker from "react-mark.js/Marker";
import { getFormattedDate } from "../../../../../utility/appUtil";
import { PickerFormat } from "../../../../constants/constants";
import secureLocalStorage from "react-secure-storage";

export const EventOutput = (props: any) => {
  const { unstructuredData, unstructuredDataFormState } = useSelector(eventLogListSelector);
  const dispatch = useDispatch();
  const [data, setData] = useState(unstructuredData);
  const [eventType, setEventType] = useState("");
  
  useEffect(() => {
    dispatch(fetchUnstructuredData(secureLocalStorage.getItem('eventLogRowId')))
    return () => {
      dispatch(clearUnstructuredData())
      secureLocalStorage.removeItem('eventLogRowId')
      secureLocalStorage.removeItem('searchedText')
    }
  }, [])

  useEffect(() => {
    setData(unstructuredData)
    let type = getStatusTypeEllipse(unstructuredData?.severity?.toString().toLowerCase())
    setEventType(type)
  }, [unstructuredData])

  let localeInfo:any = secureLocalStorage.getItem('locale')
  let text = secureLocalStorage.getItem('searchedText')

  return (
    <div>
      <Helmet>
        <style type="text/css">{`
        body {
          min-width: 475px;
        }
    `   }
        </style>
      </Helmet>
      <Loader loading={!data || unstructuredDataFormState.loading ? true : false}>
        <Marker mark={text}>
        <Row>
          <Col className="column-1">
            <EventSeverity>
              <span>
                <img src={eventType} className="eventTypeIcon" alt='' />
              </span>
              <span>{data?.severity}</span>
            </EventSeverity>
            <EventName>
              {data?.event === null ?
                <UnknownEvent>[unknown]</UnknownEvent>
                :
                data?.event?.toLowerCase() === 'unknown' ?
                  <UnknownEvent>[unknown]</UnknownEvent>
                  :
                  <KnownEvent>{data?.event}</KnownEvent>
              }
              <EventId>[{data?.eventId}]</EventId>
            </EventName>
            <EventDateDiv>
              <EventDate>{getFormattedDate(data?.eventTimestamp , localeInfo.momentFormat + " " + PickerFormat.TIME)}</EventDate>
              <EventCategory>{data?.data?.metadata?.category}</EventCategory>
            </EventDateDiv>
            <hr className="hrStyle" />
            <div className="eventDiv">
              <span>
                <img src={eventLocation} width={16} height={16} alt="" />
              </span>
              <EventLocation>{data?.hierarchyName}</EventLocation>
              <span className="eventShow"><span><img src={caretRight} width={8} height={8} alt='' /></span>
                <EventDeviceName>{data?.eventSrcName}</EventDeviceName></span>
            </div>
            <div className="eventDiv eventHide pt-5">
              <span>&emsp;&emsp;<img src={caretRight} width={8} height={8} alt='' /></span>
              <EventDeviceName>{data?.eventSrcName}</EventDeviceName>
            </div>
            <hr className="eventHide hrStyle-2" />
          </Col>
          <Col className="column-2" flex="auto">
            <div className="eventDescription">
              <DescriptionBox>
                {data?.data?.traceLogs?.length > 0 || data?.data?.eventData ?
                  <><pre>{JSON.stringify(data?.data?.eventData, null, 2) + "\n" + JSON.stringify(data?.data?.traceLogs, null, 2)}</pre>
                    <CopyToClipboard text={JSON.stringify(data?.data?.eventData, null, 2) + "\n" + JSON.stringify(data?.data?.traceLogs, null, 2)}>
                      <Button className="CopyOutputBtn">Copy full output</Button>
                    </CopyToClipboard>
                  </>
                  :
                  <EmptyTracelog>[No data is provided]</EmptyTracelog>
                }
              </DescriptionBox>
            </div>
          </Col>
        </Row>
        </Marker>
      </Loader>
    </div>
  );
};