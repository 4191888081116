import React, {useState, useEffect, useRef} from "react";
import User from "../../../../../models/user/user";
import { ProfileThumbnailPicture } from "../../../../stateless/user/profile/profileThumbnailPicture";
import { concatNames } from "../../../../../../utility/appUtil";
import "./userCardItem.less";
import { UserThreeDottedMenu } from "../../userThreeDottedMenu";
import { Card } from "antd";
import { ThreeDotsImg, ThreeDotsImgHover } from "../../../../../images";
import { Location } from "../../../../../models/common/location";
import { CardUserBlocked, CardUserDetail, CardUserInfo, CardUserInvited, CardUserLocation, CardUserName, CardUserRole } from "./index.styles";
import { UserRole } from "../../../../../constants/enums";
import { useTranslation } from 'react-i18next';
import { jsonData } from "../../../../../constants/constants";

export const UserCardItem = (props: any) => {
  const { t } = useTranslation()
  const {
    appUser,
    user,
    avatarColorList,
    blockUserCallback,
    resetPwdCallback,
    removeUserCallback,
    goToProfileCallback,
    unBlockUserCallback,
    resendInviteCallback,
    setSaveUnblockUser
  } = props;
  //debugger;
  const [iconImg, setIconImg] = useState(ThreeDotsImg);
  
  const onGoToUserProfile = (user: User) => {
    if (goToProfileCallback) {
      goToProfileCallback(user);
    }
  };

  //   const changeUser = () => {
  //     const index = props.usersList.indexOf(props.user);
  //     setColor(
  //       index < ColorList.length - 1 ? ColorList[index + 1] : ColorList[0]
  //     );
  //   };

  // const getLocationDetails = () => {
  //   if (user?.branches?.length == 0) {
  //     return "All locations";
  //   }
  //   if (user?.branches?.length == 1) {
  //     return user?.branches[0]?.name;
  //   } else {
  //     return user?.branches?.length + " locations";
  //   }
  // };

  const getLocationDetails = (user: User) => {
    //debugger;
    if (user && user.locations) {
      const locationCount = user?.locations?.length;
      if (locationCount > 0) {
        if (locationCount === 1) {
          return user?.locations[0]?.name;
        } else {
          return user?.locations?.length + " locations";
        }
      } else {
        return "0 locations";
      }
    }
    return "";
  };

  const getUserRoleDetails = (user: User) => {
    let roles: any[] = [];
    if (user?.locations && user?.locations?.length) {
      user?.locations.forEach((location: Location) => {
        if (!roles.includes(location?.role?.name)) {
          roles.push(location?.role?.name);
        }
      });
    }
    return roles.join(" ").trim() + " ";
  };

  const handleKeyPress = () => {
    onGoToUserProfile(user)
  }

  const insertStringValue = (text: string, a: string, b: any, c:any) => {
    let x = text;
    var y = x.replace(/\{0\}/g, a).replace(/\{1\}/g, b).replace(/\{2\}/g, c);
    return y;
  }

  return (
    <Card
      className="cardWrapper" //onMouseEnter={() => setIsShown(true)} //onMouseLeave={() => setIsShown(false)}
      onClick={() => onGoToUserProfile(user)} tabIndex={0} aria-label={insertStringValue("{0} has {1} and their role is {2} ", user?.userName, getLocationDetails(user), getUserRoleDetails(user) )} role={"link"} onKeyPress={handleKeyPress}
    >
      {user?.id !== appUser?.id && appUser?.locations[0]?.role?.name === UserRole.BIBLIOTHECA_USER && (user?.locations[0]?.role?.name === UserRole.PLATFORM_ADMIN || user?.locations[0]?.role?.name === UserRole.BIBLIOTHECA_USER) ? null :
        <div className="cardContainer">
          {/* {isShown && <img src={ThreeDotsImg} />} */}
          {/* {open ? <UserActionMenu /> : null} */}
          {/* <UserThreeDottedMenu
            appUser={appUser}
            user={user}
            blockUserCallback={blockUserCallback}
            resetPwdCallback={resetPwdCallback}
            removeUserCallback={removeUserCallback}
            unBlockUserCallback={unBlockUserCallback}
            resendInviteCallback={resendInviteCallback}
            setSaveUnblockUser={setSaveUnblockUser}
          >
            <div className="img-top"
              onMouseOver={() => setIconImg(ThreeDotsImgHover)}
              onMouseOut={() => setIconImg(ThreeDotsImg)}
            >
              <img
                src={iconImg}
                alt="menu"
              />
            </div>
          </UserThreeDottedMenu> */}
        </div>
      }

      <div>
        <div>
          <div>
            <div className="userCardProfilePic">
              {user?.isActive !== true ? (
                user?.emailVerifiedAt !== "" ? (
                  <div className="MB-6 blockUserContImg">
                  <ProfileThumbnailPicture
                    src={user?.profileUrl}
                    firstName={user?.firstName}
                    lastName={user?.lastName}
                    colors={avatarColorList}
                    round={true}
                    size="35"
                  />
                  <div className="blockedUserCardImg"></div>
                  <CardUserBlocked>{t("Blocked")}</CardUserBlocked>
                </div>
                ) : (
                  <div className="MB-6 blockUserContImg">
                    <ProfileThumbnailPicture
                      src={user?.profileUrl}
                      firstName={user?.firstName}
                      lastName={user?.lastName}
                      colors={avatarColorList}
                      round={true}
                      size="35"
                    />
                    <div className="inviteUserCardImg"></div>
                    <CardUserInvited>{t(jsonData.Invited)}</CardUserInvited>
                  </div>
                )
              ) : (
                <ProfileThumbnailPicture
                src={user?.profileUrl}
                firstName={user?.firstName}
                lastName={user?.lastName}
                colors={avatarColorList}
                round={true}
                size="35"
              />
              )}
            </div>
            <CardUserName className="nameContentSlicing">
              {concatNames(user?.firstName, user?.lastName, user?.middleName, user?.title)}
            </CardUserName>
            <div className="contentSlicing 456">
              <CardUserRole>{t(`${getUserRoleDetails(user)}`)}</CardUserRole>
            </div>
            <CardUserLocation>{getLocationDetails(user)}</CardUserLocation>
            <CardUserInfo className="contentSlicing">
              {user?.userName}
            </CardUserInfo>
          </div>
          <div>
            <CardUserDetail className="contentSlicing">{user?.email}</CardUserDetail>
            <CardUserInfo className="contentSlicing" id="">
              {user?.phone}
            </CardUserInfo>
          </div>
          {/* </CardContent> */}
        </div>
      </div>
    </Card>
  );
};
