import React from 'react';
import './customCheckBox.less';
import { CustomLabelContainer, CustomCheckBoxContainer } from './customCheckBox.Styles';


export function Checkbox({ ...props }: any) {
  return (
    <>
      <CustomCheckBoxContainer {...props} />
        {
          props?.label
            ?
            <CustomLabelContainer htmlFor={props?.id}>{props?.label}</CustomLabelContainer>
            :
            <CustomLabelContainer htmlFor={props?.id}></CustomLabelContainer>
        }

    </>
  );
}
