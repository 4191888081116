/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import { Switch } from 'antd'
import { useSelector } from 'react-redux'
import { AlertConfigSelector } from '../../../../../redux/reducers/alertConfig/alertConfigReducer'
import './alertToggleSwitch.less'

interface AlertToggleProps {
    onChange: any;
    initialValue: any;
    name: string
    data: any
    onRevert: Function
    activateAll?: any
    onSelect: Function
}

function AlertToggle(props: AlertToggleProps) {
    const { data, name, onChange, onRevert, onSelect } = props
    const [toggle, settoggle] = useState<boolean>(false)
    const { alertConfigChanges } = useSelector(AlertConfigSelector)

    useEffect(() => {
        if(alertConfigChanges[data?.eventId] && alertConfigChanges[data?.eventId][name] !== null && alertConfigChanges[data?.eventId][name] !== undefined){
            settoggle(alertConfigChanges[data?.eventId][name])
        }
        else{
            settoggle(props.initialValue)
        }
    }, [alertConfigChanges, props.initialValue])

    const handleToggle = (value: any) => {
        if(props?.initialValue === value){
            onRevert(data?.eventId, name)
        }
        else{
            onChange(data, { [name]: value})
        }
        onSelect(data?.eventId, value)
        settoggle(value)
    }

    return (
        <Switch
            className={`${toggle ? "toggleCheck" : "toggleOff"} toggleSwitch `}
            checked={toggle}
            onChange={handleToggle}
        ></Switch>
    )
}

export default AlertToggle;
