import { createAsyncThunk } from "@reduxjs/toolkit";
import AlertService from "../../../web/services/alerts/alertService";
import EventLogService from "../../../web/services/eventLogs/eventLogService";

const alertService = new AlertService();
const eventService = new EventLogService();

export const fetchAlertConfig = createAsyncThunk(
  "alertConfig/fetchAlertConfig",

  async (_args: any, thunkAPI) => {
    try {
      const alertData = await alertService.fetchAlertConfig(_args)
      const eventData = await eventService.fetchEventsInfoByOrgId({organizationId : _args.organizationId})
      let alertConfig: any[] = []

      if (alertData.length) {
        for (let alert of alertData) {
          let find = [];
          for (let i of eventData) {
            if (i?.eventId === alert?.eventId) {
              find.push(i)
              break;
            } 
            else if (i?.eventId === alert?.alertTypeId) {
              i.alertTypeId = alert.alertTypeId
              find.push(i)
              break;
            }            
          }
          if (find?.length && find[0]) {
            alertConfig.push({ ...alert, ...find[0] })
          }
          
        }
      }

      if (alertConfig.length) {
        return alertConfig;
      } else {
        return thunkAPI.rejectWithValue(alertConfig);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const updateAlertConfig = createAsyncThunk(
  "alert/updateAlertConfig",
  async (_args: any, thunkAPI) => {
    try {
      const data = await alertService.updateAlertConfig(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)
