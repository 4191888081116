import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Prompt, useLocation, useHistory } from "react-router-dom";
import WarnModal from "../../../stateless/common/warnModal";
import { urlModification } from "../../../../../utility/appUtil";
import { warnDiscard, warnConfirm } from "../../../../../redux/actions/app/appAction";
import { useDispatch } from "react-redux";

const WarnUnsavedChanges = (props: any) => {
  const { content, title, isDirty, cancelBtnText, confirmBtnText, ignorePrompt = true, navigateOnCancel = false, setUnsaveOpened } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const dispatch = useDispatch();
  const closeModal = () => {
    setIsModalOpen(false);
    setShouldUnload(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const showModal = (nextLocation: any) => {
    if(nextLocation.pathname != lastLocation.pathname){
      openModal();
      setLastLocation(nextLocation);
    }    
  };

  const handleBlockedRoute = (nextLocation: any) => {
    if (!confirmedNavigation && isDirty) {
      showModal(nextLocation);
      return false;
    }

    return true;
  };

  const handleDiscardClick = () => {
    closeModal();
    setConfirmedNavigation(true);
    dispatch(warnDiscard());
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    props.onConfirm();
    dispatch(warnConfirm({goTo : lastLocation.pathname}));
  };

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true);
      history.push(urlModification(lastLocation.pathname));
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    const unload = (event: any) => {
      if (isDirty && !shouldUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = content;
      }
      if (shouldUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", unload);

    return () => window.removeEventListener("beforeunload", unload);
  }, [isDirty, content, shouldUnload]);

  useEffect(() => {
    if (isModalOpen && setUnsaveOpened) {
      setUnsaveOpened(true)
    }
  }, [isModalOpen]);

  return (
    <>

      {!ignorePrompt && (
        <Prompt when message={handleBlockedRoute} />
      )}
      {isModalOpen && (
        <WarnModal
          cancelButton={cancelBtnText}
          confirmButton={confirmBtnText}
          primaryText={title}
          secondaryText={content}
          isModalVisible={isDirty}
          cancelCallback={() => {
            if (navigateOnCancel) {
              handleDiscardClick();
            } else {
              closeModal();
            }
          }}
          confirmCallback={handleConfirmNavigationClick}
        />
      )}
    </>
  );
};

WarnUnsavedChanges.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  cancelBtnText: PropTypes.string.isRequired,
  confirmBtnText: PropTypes.string.isRequired,
  isDirty: PropTypes.bool.isRequired,
  navigateOnCancel: PropTypes.bool.isRequired,
  ignorePrompt: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUnsaveOpened: PropTypes.func
};

export default WarnUnsavedChanges;
