/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  accountIcon,
  AlertIconImg,
  bibliotheCausersIcon,
  dashboardIcons,
  deviceIcon,
  generalIcon,
  InsightsIcons,
  locationsIcon,
  masterConfigurationIcons,
  organisationsIcon,
  overviewIcon,
  rolesIcon,
  usersIcons,
  NotificationBellIcon,
  auditIcon,
  alertSettings,
  SettingIcon,
  Libraries,
} from '../images'
import { AppRoutes, UserRole } from './enums'

export enum SideBarBgColor {
  RED = '#ee0000',
  DEFAULT = '#FFF',
  BLACK = '#000',
}

export enum SideBarColor {
  BLACK = '#000',
  WHITE = '#FFF',
  GREY = '#CBCBCB',
}

export enum SideBarItems {
  DASHBOARD = 'Dashboard',

  // Device
  DEVICES = 'Devices',
  DEVICE_HEALTH = 'Device Health',
  DEVICE_COMPONENTS ='Components',
  DEVICE_SETTINGS = 'Devices',
  DEVICE_CONFIGURATION = 'Configuration',

  // Alert
  ALERTS_CENTRE = 'Alerts Centre',
  ALERT_CONFIG_SETTINGS ='Alert Settings',


  // Logs
  EVENT_LOGS = 'Event Logs',
  AUDIT_LOGS = 'Audit Log',


  // User
  LOCATIONS = 'Locations',
  ROLES = 'Roles',
  USERS = 'Users',
  BIBLIOTHECA_USERS = 'Bibliotheca Users',
  ORGANISATIONS = 'Organisations',
  OVERVIEW = 'Overview',
  ACCOUNT = 'Account',
  GENERAL = 'General',
  NOTIFICATIONS = 'Notifications',
  INSIGHTS = 'Insights',
  MYINSIGHTS = 'My Insights',

  // Work Items
  WORK_ITEMS = 'Work Items'
}

export enum LibrarySettingsSideBarItem {
  GENERAL = 0,
  LOCATIONS = 1,
  DEVICE_SETTINGS = 2,
  USERS = 3,
  ROLES = 4,
  AUDIT_LOGS = 5,
  ALERT_CONFIG_SETTINGS=6
}

export enum AccountSettingsSideBarItem {
  ACCOUNT = 0,
  GENERAL = 1,
  NOTIFICATIONS = 2,
}

export enum BibliothecaSideBarItem {
  OVERVIEW = 0,
  ORGANISATIONS = 1,
  BIBLIOTHECA_USERS = 2,
}

export enum LibrarySideBarItem {
  DASHBOARD = 0,
  DEVICES = 1,
  DEVICE_HEALTH = 2,
  ALERTS_CENTRE = 3,
  INSIGHTS = 4,
  ACTIVITY_LOG = 5,
  MASTER_CONFIGURATIONS = 6,
  EVENT_LOGS = 7,
  WORK_ITEMS = 8
}

export enum DeviceHealthSideBarItem {
  OVERVIEW = 0,
  COMPONENTS = 1,
  DEVICE_CONFIGURATION = 2
}

// LeftMenuType.LIBRARY_SETTINGS
export const LibrarySettingsSideBarItems = [
  {
    name: SideBarItems.GENERAL,
    img: generalIcon,
    type: LibrarySettingsSideBarItem.GENERAL,
    url: AppRoutes.GENERAL_SETTINGS,
    classname: 'generalIconFix',
    textclass: 'generalTextFix',
  },
  {
    name: SideBarItems.LOCATIONS,
    img: locationsIcon,
    type: LibrarySettingsSideBarItem.LOCATIONS,
    url: AppRoutes.ORGANISATION_LOCATIONS,
    classname: 'locationIconFix',
    textclass: 'locationTextFix',
  },
  {
    name: SideBarItems.DEVICE_SETTINGS,
    img: deviceIcon,
    type: LibrarySettingsSideBarItem.DEVICE_SETTINGS,
    url: AppRoutes.DEVICE_SETTINGS,
    classname: 'deviceSettingIconFix',
    textclass: 'devicesTextFix',
    visibility: [UserRole.PLATFORM_ADMIN, UserRole.BIBLIOTHECA_USER, UserRole.ORGANISATION_ADMIN]
  },
  {
    name: SideBarItems.USERS,
    img: usersIcons,
    type: LibrarySettingsSideBarItem.USERS,
    url: AppRoutes.USER_LIST,
    classname: 'userIconFix',
    textclass: 'userTextFix',
  },
  {
    name: SideBarItems.ROLES,
    img: rolesIcon,
    type: LibrarySettingsSideBarItem.ROLES,
    url: AppRoutes.ROLES_LIST,
    classname: 'rolesIconFix',
    textclass: 'rolesTextFix',
  },
  {
    name: SideBarItems.AUDIT_LOGS,
    img: auditIcon,
    type: LibrarySettingsSideBarItem.AUDIT_LOGS,
    url: AppRoutes.AUDIT_LOGS,
    classname: 'eventLogsIconFix',
    textclass: 'eventLogsTextFix',
  },
  {
    name: SideBarItems.ALERT_CONFIG_SETTINGS,
    img: alertSettings,
    type: LibrarySettingsSideBarItem.ALERT_CONFIG_SETTINGS,
    url: AppRoutes.ALERT_CONFIG_SETTINGS,
    classname: 'alertIconFix',
    textclass: 'alertTextFix',
    visibility: [UserRole.PLATFORM_ADMIN, UserRole.ORGANISATION_ADMIN]
  },
]

// LeftMenuType.BIBLIOTHECA_SIDEBAR
export const BibiliothecaRedSideBarItems = [
  {
    name: SideBarItems.OVERVIEW,
    img: overviewIcon,
    type: BibliothecaSideBarItem.OVERVIEW,
    url: AppRoutes.ORGANISATION_OVERVIEW,
    classname: 'overviewIconFix',
    textclass: 'overviewTextFix',
  },
  {
    name: SideBarItems.ORGANISATIONS,
    img: organisationsIcon,
    type: BibliothecaSideBarItem.ORGANISATIONS,
    url: AppRoutes.ORGANISATION_LIST,
    classname: 'organisationIconFix',
    textclass: 'organisationTextFix',
  },
  {
    name: SideBarItems.BIBLIOTHECA_USERS,
    img: bibliotheCausersIcon,
    type: BibliothecaSideBarItem.BIBLIOTHECA_USERS,
    url: AppRoutes.ORGANISATION_USERS,
    classname: 'biblioUserIconFix',
    textclass: 'biblioUserTextFix',
  },
]

// LeftMenuType.JUNTO_BLACK
export const JuntoBlackSideBarItems = [
  {
    name: SideBarItems.DASHBOARD,
    img: dashboardIcons,
    type: LibrarySideBarItem.DASHBOARD,
    url: AppRoutes.USER_DASHBOARD,
    classname: 'dashboardIconFix',
    textclass: 'dashboardTextFix',
  },
  // {
  //   name: SideBarItems.DEVICES,
  //   img: deviceIcon,
  //   type: LibrarySideBarItem.DEVICES,
  //   url: AppRoutes.DEVICE_LIST,
  //   classname: 'devicesIconFix',
  //   textclass: 'devicesTextFix',
  // },
  {
    name: SideBarItems.DEVICE_HEALTH,
    img: overviewIcon,
    type: LibrarySideBarItem.DEVICE_HEALTH,
    url: AppRoutes.DEVICE_HEALTH,
    classname: 'devicesIconFix',
    textclass: 'devicesTextFix',
  },
  {
    name: SideBarItems.WORK_ITEMS,
    img: Libraries,
    type: LibrarySideBarItem.WORK_ITEMS,
    url: AppRoutes.WORK_ITEMS,
    classname: 'eventLogsIconFix',
    textclass: 'eventLogsTextFix',
  },
  {
    name: SideBarItems.ALERTS_CENTRE,
    img: AlertIconImg,
    type: LibrarySideBarItem.ALERTS_CENTRE,
    url: AppRoutes.ALERT_CENTRE,
    classname: 'alertIconFix',
    textclass: 'alertTextFix',
  },
  {
    name: SideBarItems.INSIGHTS,
    img: InsightsIcons,
    type: LibrarySideBarItem.INSIGHTS,
    url: AppRoutes.INSIGHTS,
    classname: 'insightsIconFix',
    textclass: 'insightsTextFix',
  },
  // {
  //   name: "Activity Log",
  //   img: activityLogIcons,
  //   type: LibrarySideBarItem.ACTIVITY_LOG,
  //   url: "",
  //   classname: "activityLogIconFix",
  //   textclass: "activityLogTextFix"
  // },
  // {
  //   name: 'Master Configurations',
  //   img: masterConfigurationIcons,
  //   type: LibrarySideBarItem.MASTER_CONFIGURATIONS,
  //   url: '',
  //   classname: 'masterConfigurationIconFix',
  //   textclass: 'masterConfigurationTextFix',
  // },
  
  {
    name: SideBarItems.EVENT_LOGS,
    img: masterConfigurationIcons,
    type: LibrarySideBarItem.EVENT_LOGS,
    url: AppRoutes.EVENT_LOGS,
    classname: 'eventLogsIconFix',
    textclass: 'eventLogsTextFix',
  },
]

// LeftMenuType.JUNTO_BLACK for device health
export const DeviceBlackSideBarItems = [
  // {
  //   name: 'Overview',
  //   img: dashboardIcons,
  //   type: DeviceHealthSideBarItem.OVERVIEW,
  //   url: '/user/dashboard',
  //   classname: 'dashboardIconFix',
  //   textclass: 'dashboardTextFix',
  // },
  {
    name: SideBarItems.DEVICE_COMPONENTS,
    img: deviceIcon,
    type: DeviceHealthSideBarItem.COMPONENTS,
    url: AppRoutes.DEVICE_COMPONENTS,
    classname: 'devicesIconFix',
    textclass: 'devicesTextFix',
  },
  {
    name: SideBarItems.DEVICE_CONFIGURATION,
    img: SettingIcon,
    type: DeviceHealthSideBarItem.DEVICE_CONFIGURATION,
    url: AppRoutes.DEVICE_CONFIGURATION,
    classname: 'devicesIconFix',
    textclass: 'devicesTextFix',
  },
]

export const AccountSettingsSideBarItems = [
  {
    name: SideBarItems.ACCOUNT,
    img: accountIcon,
    type: AccountSettingsSideBarItem.ACCOUNT,
    url: AppRoutes.ACCOUNT_SETTINGS_ACCOUNT,
    classname: 'accountSettingsProfileIconFix',
    textclass: 'accountSettingsProfileTextFix',
  },
  {
    name: SideBarItems.GENERAL,
    img: generalIcon,
    type: AccountSettingsSideBarItem.GENERAL,
    url: AppRoutes.ACCOUNT_SETTINGS_GENERAL,
    classname: 'accountSettingsGeneralIconFix',
    textclass: 'accountSettingsGeneralTextFix',
  },
  {
    name: SideBarItems.NOTIFICATIONS,
    img: NotificationBellIcon,
    type: AccountSettingsSideBarItem.NOTIFICATIONS,
    url: AppRoutes.ACCOUNT_SETTINGS_NOTIFICATIONS,
    classname: 'accountSettingsNotificationsIconFix',
    textclass: 'accountSettingsNotificationsTextFix',
  },

]

// sidebar items for front office
export const LibrarySettingsSideBarItemsForFrontOffice = [
  {
    name: SideBarItems.GENERAL,
    img: generalIcon,
    type: LibrarySettingsSideBarItem.GENERAL,
    url: '',
  },
  {
    name: SideBarItems.LOCATIONS,
    img: locationsIcon,
    type: LibrarySettingsSideBarItem.LOCATIONS,
    url: AppRoutes.ORGANISATION_LOCATIONS,
  },
]

