import styled from 'styled-components'
import { Col } from 'antd';

export const RolesListRowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const RolesListRowTitle = styled.div`
    padding-left: 18px;
    margin-bottom: 18px;
    padding-right: 145px;
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-size: ${(props) => props.theme.fontSizes.h4};
    line-height: 20px;
    color: ${(props) => props.theme.colors.text.primary};
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const RolesListRowDesc = styled.div`
    padding-left: 18px;
    padding-right: 145px;
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${(props) => props.theme.fontSizes.body};
    line-height: 20px;
    color: ${(props) => props.theme.colors.text.primary};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
`

export const ArrowIcon = styled.div`
    float: right;
    align-self: center;
`

export const RoleListIcon = styled.div`
    height: 23px;
    width: 17px;
    float: left;
    align-self: start;
    vertical-align: top;
    display: flex;
`
export const RoleRowICon = styled.img`
    width: 17px;
    height: 23px;
`

export const RoleDisplay = styled(Col)`
    display: flex;
`