import { createAsyncThunk } from "@reduxjs/toolkit";
import InsightsService from "../../../web/services/insights/insights";

const insightsService = new InsightsService();

// fetch reports for user
export const fetchReportsForUser = createAsyncThunk(
  "insights/fetchReportsForUser",
    async (_args: any, thunkAPI) => {
    try {
      const data = await insightsService.fetchReportsForUser(_args.organisationId, _args.userId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// fetch report by id
export const fetchReportDataByReportId = createAsyncThunk(
    "insights/fetchReportDataByReportId",
    async (_args:any, thunkAPI) => {
        try {
            const data = await insightsService.fetchReportDataByReportId(_args);
            if (data) {
                return data;
              } else {
                console.log('Report :: No data found : ',data)
                return thunkAPI.rejectWithValue(data);
              }
        } catch(e:any) {
          console.log('Failed to fetch ReportData : ',e)
          return thunkAPI.rejectWithValue(e);
        }
    }
)

export const postMyScheduleReport = createAsyncThunk(
  "insights/postMyScheduleReport",
  async (_args: any, thunkAPI) => {
    try {
      const data = await insightsService.postMyScheduleReport(_args);
      if (data) {
        return data;
      } else {
        console.log('Report :: No data found : ', data)
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log('Failed to fetch ReportData : ', e)
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const updateMyScheduleReport = createAsyncThunk(
  "insights/updateMyScheduleReport",
  async (_args: any, thunkAPI) => {
    try {
      const data = await insightsService.updateMyScheduleReport(_args);
      if (data) {
        return data;
      } else {
        console.log('Report :: No data found : ', data)
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log('Failed to fetch ReportData : ', e)
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const deleteSchedule = createAsyncThunk(
  "insights/deleteSchedule",
  async (id:any, thunkAPI) => {
    try {
      const data = await insightsService.deleteSchedule(id)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
)

// Create or update my Report
export const saveMyReport = createAsyncThunk(
  "insights/saveMyReport",
  async (_args:any, thunkAPI) => {
    try {
      const data = await insightsService.saveMyReport(_args)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// update my Report

export const updateMyReport = createAsyncThunk(
  "insights/updateMyReport",
  async (_args:any, thunkAPI) => {
    try {
      const data = await insightsService.updateMyReport(_args)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// Edit and Rename my Report

export const renameMyReport = createAsyncThunk(
  "insights/renameMyReport",
  async (_args:any, thunkAPI) => {
    try {
      const data = await insightsService.renameMyReport(_args)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// delete my Report
export const deleteMyReport = createAsyncThunk(
  "device/deleteMyReport",
  async (id:string, thunkAPI) => {
    try {
      const data = await insightsService.deleteMyReport(id)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchMyReport = createAsyncThunk(
  "insights/fetchMyReport",
    async (_args: any, thunkAPI) => {
    try {
      const data = await insightsService.fetchMyReport(_args.organisationId, _args.userId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchMyReportById = createAsyncThunk(
  "insights/fetchMyReportById",
  async (_args:any, thunkAPI) => {
      try {
          const data = await insightsService.fetchMyReportById(_args);
          if (data) {
              return data;
            } else {
              console.log('Report :: No data found : ',data)
              return thunkAPI.rejectWithValue(data);
            }
      } catch(e:any) {
        console.log('Failed to fetch ReportData : ',e)
        return thunkAPI.rejectWithValue(e);
      }
  }
)

