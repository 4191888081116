import styled from "styled-components";

export const Heading = styled.span`
  font-family: ${(props) => props.theme.fonts[0]};
  align-items: center;
  width: fit-content;
  fontsize: ${(props) => props.theme.fontSizes.smallBody};
  line-height: 1.25em;
  cursor: pointer;
`;
export const SubHead = styled.span`
  font-family: ${(props) => props.theme.fonts[0]};
  align-items: center;
  color: ${(props) => props.theme.colors.text.primary};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${(props) => props.theme.fontSizes.h1};
  line-height: 20px;
`;
export const PortalIconAdj = styled.div`
  font-family: ${(props) => props.theme.fonts[0]};
  font-size: ${(props) => props.theme.fontSizes.body};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  color: ${(props) => props.theme.colors.text.primary};
  line-height: 20px;
  cursor: pointer;
  text-align: right;
`;
export const Image = styled.img`
  width: 4em;
  height: 1.25em;
  margin-right: 11px;
`;
