import styled from 'styled-components'
import { Col } from 'antd';
import React from 'react';


export const EventHeaderStyle = styled.span`
    font-family: ${(props) => props.theme.fonts[0]};
    font-size: ${(props) => props.theme.fontSizes.h1};
    font-style: normal;
`

export const CustomEventHeader = styled.span`
    font-family: ${(props) => props.theme.fonts[0]};
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: 20px;
    font-style: normal;
    color: #000000;
`

export const DataDisplayed = styled.p`
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${(props) => props.theme.fontSizes.smallBody};
    line-height: 20px;
    color: #737373;
    margin: 0px 20px 0px 0px;
`

export const EventHeadBack = styled((props: any) => {
    const { children, span, ...rest } = props;
    return <Col span={span} {...rest}>{children}</Col>;
  })`
  
    ${({ showevent }) =>
      showevent === "true"?
      `display: initial;`
      :
      `display: none;`
    }
`;


export const UpdateTimeCol = styled(Col)`
    margin: 10px;
`;