import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import AuditLog from "../../models/auditLogs/auditLog";
import { convert_DateWithTimezone_To_UTC, getOrganizationDateAndTime } from "../../../utility/appUtil";
import secureLocalStorage from "react-secure-storage";

export default class AuditLogService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.AUDIT));
  }

  async fetchAuditLogTransformList(params: any): Promise<any> {

    let localeInfo:any = secureLocalStorage.getItem('locale')

    var parameters = params
    let startDate = convert_DateWithTimezone_To_UTC(parameters?.startDate, {time: 'start'})
    let endDate = convert_DateWithTimezone_To_UTC(parameters?.endDate, {time: 'end'})
    
    let limit = parameters?.limit
    let page = parameters?.currentPage
    let locale = localeInfo.culture

    const response = await this.get(
      `auditLogTransform?startDate=${startDate}&endDate=${endDate}&organisationId=${parameters.orgID}&limit=${limit}&page=${page}&locale=${locale}`,
    );
    if (response && response.data) {
      const data = response.data;
      if (
        data.result &&
        data.result.message
      ) {
        let response = { 
          totalPages: data.result.message?.totalPages,
          page: data.result.message?.page,
          rows: [], 
          count: data.result.message?.count 
        }
        response.rows = this.setAuditLog(data.result.message?.rows)
        return response;
      }
    }
    return { totalPages: 0, page: 0, rows: [], count: 0 };
  }

  private setAuditLog(data: any): Array<AuditLog> {
    return data.map((x: any) => {
      const model = this.setAuditLogData(x);
      return model;
    });
  }

  private setAuditLogData(data: any) {
    
    const auditLog: AuditLog = {
      id: data && data.id ? data.id : 0,
      category: data?.data?.metadata?.category ?? "",
      eventTimestamp: data?.eventTimeStamp ? getOrganizationDateAndTime(data?.eventTimeStamp,'YYYY-MM-DD' + ' ' + "HH:mm:ss") : "",
      userID: data?.data?.description?.params?.eventSrcName ?? "",
      event: data?.data?.metadata?.name ?? "",
      content: data?.data?.description?.content ?? "",
    };
    return auditLog;
  }
}









