import styled from "styled-components";
import { Empty } from "antd";

export const DeviceConfigContainer = styled.div`
  padding-left: 3.5%;
`;

export const DeviceConfigDiv = styled.div`
  width: 100%;
  display: inline-flex;
  height: 100%;
  margin-bottom: 5px;

  & > div {
    display: block;
    width: 100%;
  }
`;

export const EmptyDevice = styled(Empty)`
  display: initial !important;
`;
