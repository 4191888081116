import styled from "styled-components";
import { Row, Col, Divider } from "antd";

export const StyledAlertdiv = styled.div`
    height: 100%;
    background: ${(props) => props.theme.colors.palette.white};
    box-shadow: 0px 54px 29px rgb(0 0 0 / 2%), 0px 27.0264px 14.5142px rgb(0 0 0 / 2%), 0px 16.2794px 8.74265px rgb(0 0 0 / 1%), 0px 10.4327px 5.60275px rgb(0 0 0 / 1%), 0px 6.76164px 3.63125px rgb(0 0 0 / 1%), 0px 4.25637px 2.28583px rgb(0 0 0 / 1%), 0px 2.44555px 1.31335px rgb(0 0 0 / 1%), 0px 1.07635px 0.57804px rgb(0 0 0 / 0%);
    border-radius: 10px;
    font-family: ${(props) => props.theme.fonts[0]};
    //overflow-y: auto;
    align-items:center;
    display:flex;
    flex-flow:column;
`;

export const StyledRowAlertList = styled(Row)`
    padding-top: 1.45rem;
    position: sticky;
    z-index: 5;
    top: 0;
    background: #ffffff;
    width: 100%;
`;

export const StyledAlertsearchdiv = styled.div`
    // height: 100%;
    width:100%;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-right:6px;
    padding-left:18px;
`;

export const AlertTextdiv = styled(Col)`
    font-size: ${(props) => props.theme.fontSizes.h4};
    font-weight: ${(props) => props.theme.fontWeights.normal};
    color: ${(props) => props.theme.colors.text.primary};
    line-height: 20px;
    padding-right: 40px;
    padding-left:52px;
    margin-top:17px;

`;

export const AlertScrollStyle = styled(Row)`
    width: 100%;
    flex: 1px;
    padding-right: 6px;
    overflow-y: auto;
 `;

 export const AlertColumnStyle = styled(Col)`
    width:100%;
    margin-top:5px;
 `;

export const RecentAlertdiv = styled(Col)`
    font-size: ${(props) => props.theme.fontSizes.smallBody};
    line-height: 20px;
    text-align: right;
    padding-right:23px;
`;

export const TimeAlertdiv = styled.div`
    //position: absolute;
    padding-right:32px;
    padding-left:20px;
    float:right;
    font-size: ${(props) => props.theme.fontSizes.footnote};
    line-height: 16px;
    color: ${(props) => props.theme.colors.text.quaternary};
`;

export const DropdownIcon = styled.img`
    margin-left: 7.5px;
`;

export const SearchIcondiv = styled.div`
    padding-bottom: 30px;
    align-items:center;
    justify-content: center;
    display: flex;
`;

export const SearchTextdiv = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    text-align: center;
    color: ${(props) => props.theme.colors.text.secondary};
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    line-height: 20px;
    display: flex;
    align-items: center;
`;

export const AlertsTextdiv = styled.div`
    display: flex;
    padding-top: 25px;
    padding-bottom: 25px;
    line-height: 20px;
    padding-left: 18px;
    word-break: break-word;
    cursor:pointer;
    // border-bottom: 1px solid #EDEDED;
    // &:last-child {
    //     border-bottom: none;
    // }
`;

export const Mbdiv = styled.div`
    margin-bottom: 25px;
`;

export const AlertIcondiv = styled.div`
    margin-right: 19px;
    display: inline-block;
    padding-left: 18px;
`;

export const Locationdiv = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-size: ${(props) => props.theme.fontSizes.caption};
    color: ${(props) => props.theme.colors.text.quaternary};
    width: fit-content;
    &:hover {
        text-decoration-line: underline;
        // display: inline-block;
        cursor: pointer;
      }
`;

export const Devicediv = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-size: ${(props) => props.theme.fontSizes.smallBody};
    line-height: 20px;
    width: fit-content;
    &:hover {
        text-decoration-line: underline;
        // display: inline-block;
        cursor: pointer;
      }
`;

export const WrapperDivForCards = styled.div`
      &:hover {
        box-shadow: 0px 1.1px 1.4px rgba(0, 0, 0, 0.011), 0px 2.7px 3.3px rgba(0, 0, 0, 0.016), 0px 5px 6.3px rgba(0, 0, 0, 0.12), 0px 8.9px 11.2px rgba(0, 0, 0, 0.024), 0px 16.7px 20.9px rgba(0, 0, 0, 0.029), 0px 40px 50px rgba(0, 0, 0, 0.04);
  }
`;

export const StatusDiv = styled.div`
    &:hover {
        text-decoration-line: underline;
        cursor: pointer;
    }
`;

export const AlertDivider = styled(Divider)`
    border-color: #EDEDED;
    margin: 0px;
    margin-left: 18px;
    min-width: 0px;
    width: 100%;
`;