import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
} from "../../../utility/stateUtil";
import {
  saveProfile,
} from "../../actions/user/userProfileAction";
import { UserProfileState } from "../../states/user/userProfileState";
import { RootState } from "../../store/rootReducer";

const initialState: UserProfileState = {
  isProfileSaved: false,
  formState: resetState(),
  errorMsg: null
};

export const UserProfileSlice = createSlice({
  name: "userProfile",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      return state;
    },
    clearProfileSavedState: (state) => {
      state.isProfileSaved = false;
      state.errorMsg = null;
      return state;
    },
  },
  extraReducers: {

    [saveProfile.fulfilled.toString()]: (state, { payload }) => {
      if(payload.errorMsg){
        state.isProfileSaved = false;
        state.formState = rejectedState(payload?.message);
        state.errorMsg = payload.errorMsg;
      }
      else{
        state.isProfileSaved = (payload && payload.id && payload.id !=='');
        state.formState = fulfilledState();
      }
      return state;
    },
    [saveProfile.rejected.toString()]: (state, { payload }) => {
      state.isProfileSaved = false;
      state.formState = rejectedState(payload?.message);
    },
    [saveProfile.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
  },
});

export const { clearState, clearProfileSavedState } = UserProfileSlice.actions;
export const userProfileSelector = (state: RootState) => state.userProfile;
export const userProfileFormStateSelector = (state: RootState) =>
  state.userProfile.formState;
export default UserProfileSlice.reducer;
