/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { checkEmail } from '../../../../../../redux/actions/user/authAction'
import {
  userStateSelector,
  clearState,
} from '../../../../../../redux/reducers/user/userReducer'
import { urlModification } from '../../../../../../utility/appUtil'
import { UserAuthInput } from '../../../../../models/user/userAuthInput'
import { AppRoutes } from '../../../../../constants/enums'
import { AppService } from '../../../../../services/ui/appService'
import { EmailLoginForm } from '../../../../stateless/user/auth/emailLoginForm'
import { AuthWrapper } from './index.styles'

export const ValidateEmail = (props: any) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { location } = props
  const { formState, appUser } = useSelector(userStateSelector)
  //debugger;

  // useEffect(() => {
  //   return () => {
  //     dispatch(clearState());
  //   };
  // }, []);

  // need to be removed later

  useEffect(() => {
    if (location.search && location.search.length) {
      var authUser = {
        id: '',
        email: location.search.substr(1),
        password: '',
      }
      dispatch(checkEmail(authUser))
    }
    return () => {
      dispatch(clearState())
    }
  }, [])

  //

  useEffect(() => {
    if (location && location.state) {
      if (location.state.message) {
        AppService.showToast(location.state.message)
      }
    }
  }, [location?.state])

  useEffect(() => {
    if (formState.isError) {
      if (formState.errorStack && formState.errorStack.message) {
        //AppService.showToast(formState.errorStack.message);
      }
    } else if (formState.isSuccess) {
      // dispatch(clearState());

      if (appUser && appUser.organization && appUser.organization.id !=='') {
        history.push(urlModification(AppRoutes.LOGIN))
        dispatch(clearState())
      }
    }
  }, [formState.isError, formState.isSuccess])

  const onLoginCallback = useCallback((email: string) => {
    if (!email) {
      return
    }

    let authUser: UserAuthInput = {
      id: '',
      email: email,
      password: '',
    }
    dispatch(checkEmail(authUser))
  }, [])

  return (
    <AuthWrapper>
      <EmailLoginForm formState={formState} loginCallback={onLoginCallback} />
    </AuthWrapper>
  )
}
