import React from 'react';
import { Menu, Dropdown } from "antd";
import "./filter.less";
import { useTranslation } from 'react-i18next';

export type FilterProps = {
  values: Array<string>
  onClick: any
  children: any
  className:string
  };

export function Filter({ ...props }: FilterProps) {
  const { t } = useTranslation()
  const onClick = ({ key }: { key: any }) => {
    props.onClick(key, props.values);
  };

  const icon : any = document?.querySelector('#profileIconActive')
  const profileIcon : any = document?.querySelector('.profileIconActiveDropdown')
  const handleTabKeyPress = (e : any) => {
    if(!e?.shiftKey && e?.keyCode === 9){
      if(icon && profileIcon){
        profileIcon.click();
        icon.focus();
      }
    }
  };


  const menu = (
    <Menu onClick={onClick} className={`filter ${props.className}`}>
      {props.values.map((value: any, index: any) => {
        if (value ==="Log Out") {
          return (
            <Menu.Item
              key={index}
              className="filterChild"
              tabIndex={0}
              onKeyDown={handleTabKeyPress}
            >
              {t(`${value.replace(/\s/g, '')}`)}
            </Menu.Item>
          );
        } else {
          return (
            <Menu.Item key={index} className="filterChild" tabIndex={0}>
              {t(`${value.replace(/\s/g, '')}`)}
            </Menu.Item>
          );
        }
      })}
    </Menu>
  );
  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]} className='profileIconActiveDropdown'>
        {props.children}
      </Dropdown>
    </>
  );
};
