import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { replaceAll } from "../../../utility/appUtil";
import { SUCCESS_ID } from "../../constants/constants";
import { APIResponseError } from "../../constants/enums";
import { Messages } from "../../constants/messages";
import { Location } from "../../models/common/location";
import User from "../../models/user/user";
import { UserAuthInput } from "../../models/user/userAuthInput";
import { UserSearch } from "../../models/user/userSearch";

export default class UserService extends BaseService {

  constructor() {
    super(getAPIBaseUrl(PortalModule.USER));
  }

  async sendChangePwdLink(userAuth: UserAuthInput): Promise<User | null> {
    const response = await this.post("requestPasswordReset", userAuth);
    if (response && response.data) {
      const data: any = response.data;
      if (data.result) {
        const user: User = this.setUserData(data.result);
        return user;
      }
    }
    return Promise.resolve(null);
  }

  async resetPwd(userAuth: UserAuthInput | null): Promise<any> {
    //debugger;
    const response: any = await this.post("resetPassword", { token: userAuth?.token, password: userAuth?.password });
    return response;
  }

  async fetchUserList(organizationId: string, roleId?: string, locationId?: string, searchTerm?: string): Promise<Array<User>> {
    let params: any = {};
    if (organizationId !== '') {
      params.organisationId = organizationId;
    }
    if (roleId !== '') {
      params.role = roleId;
    }
    if (locationId !== '') {
      params.locations = locationId;
    }
    if (searchTerm) {
      params.search = searchTerm;
    }
    const response = await this.get("user", { params });
    if (response && response.data) {
      const data = response.data;
      if (data.result && data.result?.rows && data.result?.rows?.length) {
        const result = await this.setUser(data.result.rows);
        return result;
      }
    }
    return [];
  }

  async fetchTransformUserList(organizationId: string, roleId?: string, locationId?: string, searchTerm?: string): Promise<Array<User>> {
    let params: any = {};
    if (organizationId !== '') {
      params.organisationId = organizationId;
    }
    if (roleId !== '') {
      params.role = roleId;
    }
    if (locationId !== '') {
      params.locations = locationId;
    }
    if (searchTerm) {
      params.search = searchTerm;
    }
    const response = await this.get("users/list", { params });
    if (response && response.data) {
      const data = response.data;
      if (data.result && data.result?.rows && data.result?.rows?.length) {
        const result = await this.setUser(data.result.rows);
        return result;
      }
    }
    return [];
  }

  async filterUserList(search: UserSearch, organizationId: string): Promise<Array<User>> {
    //debugger;
    const response = await this.fetchUserList(organizationId, search.role.id, search.branch.id, search.searchTerm);
    if (response && response.length) {
      return response;
    }
    return [];
  }

  async filterTransformUserList(search: UserSearch, organizationId: string): Promise<Array<User>> {
    //debugger;
    const response = await this.fetchTransformUserList(organizationId, search.role.id, search.branch.id, search.searchTerm);
    if (response && response.length) {
      return response;
    }
    return [];
  }


  async getUserById(userId: string): Promise<User | undefined> {
    const response = await this.get(`user/${userId}`);
    //debugger;
    if (response && response.data) {
      if (response.data?.error) {
      } else if (response.data?.result) {
        const data = response.data?.result;
        const userData = this.setUserData(data);
        return userData;
      }
    }
    return undefined;
  }

  async createUser(user: User): Promise<User | null> {
    //debugger;
    const data: any = {
      title: user.title,
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      imageUrl: user.profileUrl,
      organizationId: user.organization.id,
      phone: user.phone,
      email: user.email,
      branches: user.locations.map(x => {
        return {
          branchId: x.id,
          roleId: user.role?.id
        };
      }),
    };

    if (!data.middleName) {
      delete data.middleName;
    }

    if (!data.lastName) {
      delete data.lastName;
    }

    const response = await this.post("user", data);
    //debugger;
    if (response && response.data) {
      const data: any = response.data;
      const error: any = data.error;
      if (error && error.errorType) {
        let errorMsg = "";
        if(error.name === APIResponseError.INVALID_PARAMETER_EXCEPTION){
          errorMsg = Messages.INVALID_COUNTRY_CODE;
        }
        else{
          switch(error.errorType) {
            case APIResponseError.ALREADY_EXISTS:
              errorMsg = replaceAll(/\{0\}/gi, Messages.USER_ALREADY_EXISTS, user.email);
              break;
          }
        }
        const result = this.setUserData(data);
        result.errorMsg = errorMsg;
        return result;
      } else if(data.result && data.result.message) {
        const result = this.setUserData(data.result);
        result.firstName = user.firstName;
        result.lastName = user.lastName;
        result.id = SUCCESS_ID;
        //result.errorMsg = data.result.message;
        return result;
      }
    }
    return null;
  }

  async updateUser(user: User): Promise<User | null> {
    //debugger;
    user.info && delete user.info.playSound;
    try {
      const data: any =  {
        title: user.title,
        firstName: user.firstName,
        middleName: user.middleName,
        lastName: user.lastName,
        imageUrl: user.profileUrl,
        phone: user.phone,
        email: user.email,
        isActive: user.isActive,
        info: user.info,
        branches: user.locations && user.locations.length ? user.locations.map((x: any) => {
          return {
            branchId: x.value,
            roleId: user.role?.id,
          };
        }) : [],
      };

      if (!data.branches.length) {
        delete data.branches;
      }

      const response = await this.put(`user/${user.id}`, data);
      if (response && response.data) {
        const data: any = response.data;
        const error: any = data.error;
        let errorMsg : any = '';
        if (error && error.errorType) {
          if(error.name === APIResponseError.INVALID_PARAMETER_EXCEPTION){
            errorMsg = Messages.INVALID_COUNTRY_CODE;
          }
          const result = this.setUserData(data);
          result.errorMsg = errorMsg;
          return result;
        } else if(data.result && data.result.id && data.result.id !=='') {
          return this.setUserData(data.result);
        }
      }
    } catch (e: any) {
      return null;
    }

    return null;
  }

  async blockUser(userId: any, isActive:boolean): Promise<User | null> {
    try {
      const response = await this.put(`user/${userId}`, { isActive: isActive });
      if (response && response.data) {
        const data: any = response.data;
        const error: any = data.error;
        if (error && error.errorType) {
        } else if(data.result && data.result) {
          
          const result = this.setUserData(data.result);
          //result.id = SUCCESS_ID;
          return result;
        }
      }
    } catch (e: any) {
      return null;
    }

    return null;
  }

  async removeUser(user: User): Promise<User | null> {
    //debugger;
    const response = await this.delete(`user/${user.id}`);
    if (response && response.data) { 
      const data: any = response.data;
      const error: any = data.error;
      let result = this.setUserData(null);
      if (error && error.errorType) {
      } else if(data.result) {
        result.errorMsg = data.result;
        result.email = user.email;
        result.id = SUCCESS_ID;
        return result;
      }
    }
    return null;
  }

  async resendInvite (params: any) : Promise<any> {
    const response = await this.post(`user/resendEmail`, params)
    if(response && response.data){
      return response.data.result
    }
    else return null
  }
  private setUser(data: any): Promise<Array<User>> {
    return data.map((x: any) => {
      const model = this.setTransformUserData(x);
      return model;
    });
  }

  async uploadLogo(formData: any | null): Promise<any> {
    const response: any = await this.post("upload/logo", formData );
    return response;
  }

  private setUserData(data: any) {
    const user: User = {
      deletedBy: data && data.deletedBy? data.deletedBy: null,
      userName: data && data.userName ? data.userName : "",
      email: data && data.email? data.email: "",
      title: data && data.title? data.title : "",
      firstName: data && data.firstName? data.firstName: "",
      emailVerifiedAt:data && data?.emailVerifiedAt? data?.emailVerifiedAt: "",
      id: data && data.id? data.id: '',
      lastName: data && data.lastName? data.lastName: "",
      middleName: data && data.middleName? data.middleName : "",
      locations: [],
      organization: {
        id: data && data.organizationId? data.organizationId: '',
        name: data && data?.organization?.name ? data?.organization?.name : '',
      },
      info: data?.info,
      // role: {
      //   id: data.role ? data.role.id : 0,
      //   name: data.role ? data.role.role : "",
      // },
      phone: data && data.phone? data.phone: "",
      profileUrl: data && data.imageUrl? data.imageUrl: "",
      updatedBy: data && data.updatedBy? data.updatedBy: null,
      token: data && data.token? data.token: null,
      isActive: data && data.isActive? data.isActive: false,
      userClaim: data.userClaim ? data.userClaim : {}
    };

    if (data && data.branches) {
      user.locations = [];
      data.branches.forEach((branch: any) => {
        const location: Location = {
          id: branch.branchId ?? '',
          name: branch.branch && branch.branch.name ? branch.branch.name: "",
          shortName: branch.branch && branch.branch.shortName ? branch.branch.shortName : "",
          role: {
            id: branch.roleId ?? '',
            name: branch.role && branch.role.role ? branch.role.role : "",
            type: branch.role && branch.role.type ? branch.role.type : "",
          }
        };
        user.locations.push(location);
      });
    }
    return user;
  }

  private setTransformUserData(data: any) {
    const user: User = {
      deletedBy: data && data.deletedBy? data.deletedBy: null,
      email: data && data.email? data.email: "",
      userName: data && data.userName ? data.userName : "",
      title: data && data.title? data.title : "",
      firstName: data && data.firstName? data.firstName: "",
      emailVerifiedAt:data && data?.emailVerifiedAt? data?.emailVerifiedAt: "",
      id: data && data.id? data.id: '',
      lastName: data && data.lastName? data.lastName: "",
      middleName: data && data.middleName? data.middleName : "",
      locations: [],
      organization: {
        id: data && data.organizationId? data.organizationId: '',
        name: "",
      },
      info: data?.info,
      phone: data && data.phone? data.phone: "",
      profileUrl: data && data.imageUrl? data.imageUrl: "",
      updatedBy: data && data.updatedBy? data.updatedBy: null,
      token: data && data.token? data.token: null,
      isActive: data && data.isActive? data.isActive: false,
      userClaim: data.userClaim ? data.userClaim : {}
    };

    if (data && data.branches) {
      user.locations = [];
      data.branches.forEach((branch: any) => {
        const location: Location = {
          id: branch.branchId ?? '',
          name: branch && branch.name ? branch.name: "",
          shortName: branch.branch && branch.branch.shortName ? branch.branch.shortName : "",
          role: {
            id: branch.roleId ?? '',
            name: branch.role && branch.role ? branch.role : "",
            type: branch.role && branch.role.type ? branch.role.type : "",
          }
        };
        user.locations.push(location);
      });
    }
    return user;
  }

  private setErrorUserData(errorMsg: string) {
    const user: User = {
      email: "",
      emailVerifiedAt:'',
      userName:"",
      title: "",
      firstName: "",
      id: '',
      lastName: "",
      middleName: "",
      locations: [],
      organization: {
        id: '',
        name: "",
      },
      phone: "",
      profileUrl: "",
      errorMsg: errorMsg,
      isActive: false,
      userClaim: {}
    };
    return user;
  }
}
