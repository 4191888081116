import React from "react";
import styled from "styled-components";
import { RightCircleFilled} from '@ant-design/icons'

export const RightCircledStyle = styled((props: any) => {
    const {children, ...rest } = props
    return <RightCircleFilled {...rest}> {children} </RightCircleFilled>     
})`
margin-right : 10px;
${({color}) => 
    `
    color: ${color};
    
    `
}


`
export const PieGraphTooltipMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 5px !important;
  background-color: #eee;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 5px;
`