/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect , useState } from "react";
import { Steps } from "antd";
import { CreatePwdForm } from "../createPwdForm";
import { PhoneNumberForm } from "../phoneNumberForm";
import { ProfilePicUpForm } from "../profilePicUpForm";
import { UserAuthInput } from "../../../../../models/user/userAuthInput";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { resetPwdUserDetail } from "../../../../../../redux/actions/user/forgotPwdAction";
import {
  clearAuthInput,
  clearFormStateError,
  clearState,
  userStateSelector,
  clearUserDetails
} from "../../../../../../redux/reducers/user/userReducer";
import {
  clearSelectedUser,clearStoreCredentials
} from "../../../../../../redux/reducers/user/userListReducer";
import { getProfile, saveProfile, storeCreateEml, storeCreatePwd } from "../../../../../../redux/actions/user/userProfileAction";
import { authenticate,authenticateUser } from "../../../../../../redux/actions/user/authAction";
import { userListSelector } from "../../../../../../redux/reducers/user/userListReducer";
import { clearProfileSavedState, userProfileSelector } from "../../../../../../redux/reducers/user/userProfileReducer";
import { Messages } from "../../../../../constants/messages";
import { replaceAll, urlModification } from "../../../../../../utility/appUtil";
import { AppService } from "../../../../../services/ui/appService";
import { uploadLogo } from "../../../../../models/user/uploadLogo";
import { AppRoutes } from "../../../../../constants/enums";
import { useHistory } from "react-router-dom";
import WarnModal from "../../../common/warnModal";
import { UserAlreadyExistWarnModal, jsonData } from "../../../../../constants/constants";
import { useTranslation } from 'react-i18next';


const { Step } = Steps;
export const NewUserFlow = (props: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const history = useHistory();
  const { formState,resetPwdResp,userDetail,createPwdState } = useSelector(userStateSelector);
  const { isProfileSaved } = useSelector(userProfileSelector);
  const { selectedUser,createPwd,createEml } = useSelector(userListSelector);
  const [userData, setUserData] = React.useState<any>(null);
  const [userAuth, setUserAuth] = React.useState<any>();
  const [phoneNumber, setPhoneNumber] = React.useState<string>();
  const [userEmail, setUserEmail] = React.useState<any>(null);
  const [current, setCurrent] = React.useState(0);
  const [form] = Form.useForm();
  const [showWarn, setShowWarn] = useState(false);
  const [confirmPasswordValue , getConfirmPasswordVlaue] = useState(null)
  const { location } = props;
  //   //debugger;
    useEffect(() => {
      dispatch(clearFormStateError());
      dispatch(clearUserDetails());
      dispatch(clearSelectedUser());
      // dispatch(logout());
      return () => {
        dispatch(clearState());
        dispatch(clearUserDetails());
        dispatch(clearFormStateError());
        dispatch(clearSelectedUser());
        dispatch(clearStoreCredentials());
        setUserEmail('')
        setPhoneNumber(null)
        // dispatch(logout());
      };
    }, []);
  
    useEffect(() => {
      //debugger;
      if (formState.isError) {
      } else if (formState.isSuccess) {
        dispatch(clearState());
        dispatch(clearAuthInput());
      }
    }, [formState.isError, formState.isSuccess]);
  
    useEffect(() => {
      //debugger;
      if (formState?.errorStack && formState?.errorStack.message) {
        form.resetFields();
        dispatch(clearAuthInput());
      }
    }, [formState?.errorStack]);
  
  //   //create new password
    const onCreatePwdCallback = (userAuth: UserAuthInput) => {
      setUserAuth(userAuth?.confirmPwd)
      dispatch(resetPwdUserDetail(userAuth));
      dispatch(storeCreatePwd(userAuth?.confirmPwd));
    };

    useEffect(() => {
      setTimeout(() => {
        if(userDetail?.id !== undefined){
          dispatch(getProfile(userDetail))
          setCurrent(current + 1);
         
        }else{
          return null
        }
      }, 200);
    }, [userDetail]);

    useEffect(() => {
      setUserData(selectedUser)
    }, [selectedUser]);

    useEffect(() => {
      if(createPwdState === 'fulfilledState'){
          var user = resetPwdResp?.email
          setUserEmail(user)
          if(resetPwdResp?.email !== ''){
             setTimeout(() => {
              var userAuthInput:any={
                email:resetPwdResp?.email,
                password:userAuth
              }
              dispatch(authenticateUser(userAuthInput));
              dispatch(storeCreateEml(resetPwdResp?.email));
            }, 200);
          }
          else{
            return null
          }
      }else if(confirmPasswordValue !== null && createPwdState === 'errorState'){
        setShowWarn(true);
      }
      else{
        return null
      }
    }, [createPwdState]);

  const handlePhoneNumberCallback = (childNumber) => {
    setPhoneNumber(childNumber)
    setTimeout(() => {
      setCurrent(current + 1);
    }, 200);
  };

  const onFormSubmitCallback = (data: uploadLogo) => {
    var updatePhoto = {
      id:userData?.id,
      organization: userData?.organization,
      profileUrl: data,
      phone: phoneNumber !== ''?phoneNumber:userData?.phone,
     }
     setTimeout(() => {
      dispatch(saveProfile(updatePhoto));
    }, 200);
  };

  useEffect(() => {
    if (isProfileSaved) {
      const msg = replaceAll(/\{0\}/gi, t(Messages.USER_UPDATED_SUCCESSFULLY), selectedUser?.email?selectedUser?.email:jsonData.User);
      AppService.showToast(msg);
      setTimeout(() => {
        var userAuthInput:any={
          email:createEml,
          password:createPwd
        }
        dispatch(authenticate(userAuthInput));
      }, 200);
    }
    return () => {
      dispatch(clearProfileSavedState());
    };
  }, [isProfileSaved]);
  
  const steps = [
    {
      title: t(jsonData.First),
      content: (
        <CreatePwdForm
        location={location}
        createPwdCallback={onCreatePwdCallback}
        getConfirmPasswordVlaue = {getConfirmPasswordVlaue}
        />
      ),
    },
    {
      title: t(jsonData.Second),
      content: (
        <PhoneNumberForm
          editPhoneCallback={handlePhoneNumberCallback}
          userPwd={userAuth}
          userEml={userEmail}
          phone={userData?.phone}
        />
      ),
    },
    {
      title: t(jsonData.Last),
      content: <ProfilePicUpForm
       userEml={userEmail} phoneNumber={userData?.phone} password={userAuth}
        formSubmitCallback={onFormSubmitCallback}
         />,
    },
  ];

  const onClick = ()=>{
    setShowWarn(false);
    history.push(urlModification(AppRoutes.LOGIN));
  }
  return (
    <>
    <WarnModal
        cancelButton={t(UserAlreadyExistWarnModal.CANCEL_BTN_TEXT)}
        confirmButton={t(UserAlreadyExistWarnModal.CONFIRM_BTN_TEXT)}
        primaryText={t(UserAlreadyExistWarnModal.CONTENT)}
        isModalVisible={showWarn}
        hideOkText={false}
        cancelCallback={() => {
          setShowWarn(false);
        }}
        confirmCallback={onClick}
      />
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current]?.content}</div>
    </>
  );
};
