import React from "react";
import { ThemeProvider } from "styled-components";
import { Layout, Row, Col } from "antd";
import { AppHeader } from "../common/appHeader";
import { SideNav } from "../common/sideNav";
import { GetProjectTheme } from "../../../../bit_components/styles/theme";
// import { DeviceMenu } from "@bibliotheca/backoffice.apps.device-menu";
import { Route, Switch } from "react-router-dom";
import { MainContentLayout, StyledLayout, StyledSider } from "./mainLayout.styles";
const { Header } = Layout;

const DeviceLayout = (props: any) => {
  const { children, logoutCallback } = props;

  return (
    <StyledLayout >
      <Row>
        <Col span={24}>
          <Header>
            <AppHeader {...props} logoutCallback={logoutCallback} />
          </Header>
        </Col>
      </Row>
      <Row className="deviceLayout">
      <Col span={6} lg={4}>
          <StyledSider >
            <ThemeProvider theme={GetProjectTheme()}>
              <Switch>
                <Route path="/device/devices/:deviceId">
                  {/* <DeviceMenu /> */}
                </Route>
                <Route path="/device/devices">
                  <SideNav {...props} />
                </Route>
              </Switch>
            </ThemeProvider>
          </StyledSider>
        </Col>
        <Col span={18} lg={20}>
          <MainContentLayout
          >
            {children}
          </MainContentLayout>
        </Col>
      </Row>
    </StyledLayout>
  );
};

export default DeviceLayout;
