import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAppUser } from "../../../utility/appUtil";
import { UserAuthInput } from "../../../web/models/user/userAuthInput";
import { AuthService } from "../../../web/services/user/authService";
import UserService from "../../../web/services/user/userService";
import secureLocalStorage from "react-secure-storage";

const authSvc = new AuthService();
const userSvc = new UserService();

export const checkEmail = createAsyncThunk(
  "user/checkEmail",
  async (user: UserAuthInput, thunkAPI) => {
    try {
      const data = await authSvc.checkEmail(user);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const authenticate = createAsyncThunk(
  "user/authenticate",
  async (user: UserAuthInput | null, thunkAPI) => {
    try {
      //debugger;
      const data = await authSvc.authenticate(user);
      if (data && data.id !== '') {
        const userData = await userSvc.getUserById(data.id);
        if (userData) {
          userData.token = data.token;
          setAppUser(JSON.stringify(userData));
        }
        return userData;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);


//authenticateUser after creat password
export const authenticateUser = createAsyncThunk(
  "user/authenticateUser",
  async (user: UserAuthInput | null, thunkAPI) => {
    try {
      //debugger;
      const data = await authSvc.authenticate(user);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const sso = createAsyncThunk(
  "user/sso",
  async (user: any | null, thunkAPI) => {
    try {
        var base64Url = user.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        var decodeToken = JSON.parse(jsonPayload);
        window.localStorage.setItem('id-token',user)
        window.localStorage.setItem('session', null)
        secureLocalStorage.setItem('locale', JSON.parse(decodeToken?.locale))
        const userData = await userSvc.getUserById(decodeToken?.userId);
        const parsedUrl = userData?.userClaim;
        const administrationUrl  = parsedUrl?.['libraryConnect Administration']
        secureLocalStorage.setItem('accountSettingUrl',decodeToken?.accountSettingUrl)
        secureLocalStorage.setItem('administrationUrl', administrationUrl)
        secureLocalStorage.setItem('LCRole',decodeToken?.role)
        secureLocalStorage.setItem('redirectUrl',userData?.userClaim)
        userData.session = decodeToken.sessionId
        return userData;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
