import styled from "styled-components";
import { Col } from "antd";

export const UserListHead = styled.span`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: ${(props) => (props.theme.fontWeights.normal)};
    font-size: ${(props) => (props.theme.fontSizes.h1)};
    line-height: 20px;
    display: flex;
    align-items: center;
    color: ${(props) => (props.theme.colors.palette.black)};
    // padding-left: 23px;
    padding-right:15px;
`;

export const UserFilterIcons = styled(Col)`
    margin-left: auto;
`;