import React from 'react';
import {Button, Row, Tooltip } from 'antd';
import { useHistory,useLocation } from 'react-router-dom';

// import images
import { ForwardVector, Roles} from '../../../../../images/index';
import { useTranslation } from 'react-i18next';

//import styles
import './rolesListRow.css';
import {RolesListRowWrapper, RolesListRowTitle, RolesListRowDesc, ArrowIcon, RoleListIcon, RoleRowICon, RoleDisplay} from './rolesListRow.styles';
import { AppRoutes } from '../../../../../constants/enums';
import { urlModification } from '../../../../../../utility/appUtil';
import { jsonData } from '../../../../../constants/constants';

export const RolesListRow = (props: any) => {
  const { t } = useTranslation()
  const location: any = useLocation();
  const history = useHistory();

  const handleArrowClick = () => {
    if(location.pathname.includes(AppRoutes.GENERAL_SETTINGS)){
      return null
    }
    else{
      history.push(urlModification(AppRoutes.ROLES_DETAILS), {id:props.item.id});
    }
  }

  return (
    <Row className='roleListRow roleListItem' onClick={handleArrowClick}>
      <RoleDisplay span={24}>
          <RoleListIcon>
            <RoleRowICon src={location.pathname.includes(AppRoutes.GENERAL_SETTINGS)?props.item.image:Roles} alt='icon' />
          </RoleListIcon>
          <RolesListRowWrapper>
            <RolesListRowTitle >
              {props.item.name}
            </RolesListRowTitle>
            <RolesListRowDesc className="commonRowDesc" >
              {props.item.description}
            </RolesListRowDesc> 
          </RolesListRowWrapper>
          <ArrowIcon>
          <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.roleDetail)}>
          <Button id="arrowOpen" aria-label={`${jsonData.Role} ${props.item.name}`} className='roleArrowButton btnCommon' size='small' onClick={handleArrowClick}><img id="arrowImage" src={ForwardVector} alt='arrow-icon' className='roleArrowButtonImg' /></Button>
          </Tooltip>
        </ArrowIcon>
      </RoleDisplay>
    </Row>
    
  );
};