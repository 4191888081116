/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Form } from "antd";
import { parseName } from "humanparser";
import { userListSelector } from "../../../../../redux/reducers/user/userListReducer";
import { cameFromOrganisation, concatNames,convertUserFormatToPhoneNumber, getAppHeaderType, getUserLocationIds, getUserRoleIds, replaceAll, translateOptions, urlModification } from "../../../../../utility/appUtil";
import { AppRoutes, UserRole } from "../../../../constants/enums";
import { UserProfileHeader } from "../../../stateless/user/profile/userProfileHeader";
import { UserProfileForm } from "../../../stateless/user/profile/userProfileForm";
import {
  AvatarColorList,
  BlockUserWarnModal,
  Pages,
  RemoveUserWarnModal,
  ResendInvite,
  ResetPwdWarnModal,
  RistrictUnBlockUserWarnModal,
  UnBlockUserWarnModal,
  UnsavedChangesWarnModal,
  UserActionType,
  jsonData,
} from "../../../../constants/constants";
import { roleListSelector } from "../../../../../redux/reducers/role/roleListReducer";
import WarnUnsavedChanges from "../../common/warnUnsavedChanges";
import { getAccountSettings, getProfile, saveProfile } from "../../../../../redux/actions/user/userProfileAction";
import {
  clearProfileSavedState,
  userProfileSelector,
} from "../../../../../redux/reducers/user/userProfileReducer";
import {
  clearSavedFormValues,
  commonSelector,
} from "../../../../../redux/reducers/common/commonReducer";
import { SavedFormValue } from "../../../../models/common/savedFormValue";
import {
  saveDirtyFormValues,
  saveFormValues,
} from "../../../../../redux/actions/common/commonAction";
import { MainLayoutService } from "../../../../services/ui/mainLayoutService";
import { HeaderType, LeftMenuType, Permissions } from "../../../../constants/enums";
import { LibrarySettingsSideBarItem, SideBarItems } from "../../../../constants/sideBarConstants";
import { locationListSelector } from "../../../../../redux/reducers/location/locationListReducer";
import User from "../../../../models/user/user";
import { UserAuthInput } from "../../../../models/user/userAuthInput";
import { sendForgotPwdLink } from "../../../../../redux/actions/user/forgotPwdAction";
import { blockUser, reInviteUser, removeUser } from "../../../../../redux/actions/user/userAction";
import WarnModal from "../../../stateless/common/warnModal";
import { clearUser, clearUserActions, userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { Messages } from "../../../../constants/messages";
import { AppService } from "../../../../services/ui/appService";
import { useHistory } from "react-router-dom";
import { addClass, removeClass, setElemDisplay } from "../../../../../utility/uiUtil";
import { fetchTransformUserList, selectUser } from "../../../../../redux/actions/user/userListAction";
import { onPage, sidebarNavigate, warnUnsave } from "../../../../../redux/actions/app/appAction";
import { getPermissionFor } from "../../../../../utility/roleUtils";
import { appSelector, logout } from "../../../../../redux/reducers/app/appReducers";
import Loader from "../../../stateless/common/spinner";
import { Dropdown as BitDropdown } from '../../../../../bit_components/common/dropdown';
import { CompWrapper } from "../../../stateless/common/compWrapper/compWrapper";
import { Redirect } from "react-router-dom"
import { Multiselect } from "../../../../../bit_components/common/multiselect";
import { useTranslation } from 'react-i18next';

export const UserProfile = (props: any) => {
  const formName = "profile";
  const history = useHistory();
  const dispatch = useDispatch();
  const {applyChanges, goTo} = useSelector(appSelector);
  const [form] = Form.useForm();
  const { savedFormValues, isDirty,saveDirtyFormState } = useSelector(commonSelector);
  const { user, isUserBlocked, isUserRemoved, isUserInvited, forgotPwdRequest, deletedUserEmail } = useSelector(userStateSelector);
  const { formState, selectedUser } = useSelector(userListSelector);
  const { isProfileSaved } = useSelector(userProfileSelector);
  const { roles } = useSelector(roleListSelector);
  const { locations } = useSelector(locationListSelector);
  const [selectedLocations, setSelectedLocations] = useState(new Array<any>());
  const [showWarn, setShowWarn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [warnCancelBtn, setWarnCancelBtn] = useState("");
  const [warnConfirmBtn, setWarnConfirmBtn] = useState("");
  const [warnTitle, setWarnTitle] = useState("");
  const [warnContent, setWarnContent] = useState<any>("");
  const [warnConfirmType, setWarnConfirmType] = useState<any>();
  const [userActionValue, setUserActionValue] = useState<any>();
  const [saveInit, setSaveInit] = useState(false);
  const [hideOkText,setHideOkText]=useState<boolean>(false);
  const {appUser} = useSelector(userStateSelector);
  const [roleId, setRoleId] = useState<string>(selectedUser? getUserRoleIds(selectedUser)[0]: '');
  const [roleUpdated , setRoleUpdated ]= useState<boolean>(false)
  const [blockRoleId, setBlockRoleId] = useState<string>();
  const [saveUnBlockUser, setSaveUnblockUser] = useState(false)
  const [blockedSelectedUser,setBlockedSelectedUser]=useState(null)
  const { t } = useTranslation()
  const goToUserList = () => {
    if (appUser && getAppHeaderType(appUser) === HeaderType.BIBLIOTHECA_PORTAL){
      return AppRoutes.ORGANISATION_USERS
    }
    else {
      return AppRoutes.USER_LIST
    }
  }

  const breadcrumbs = [
    {
      text: SideBarItems.USERS,
      href: urlModification(goToUserList()),
    },
    {
      text: concatNames(selectedUser?.firstName, selectedUser?.lastName, selectedUser?.middleName, selectedUser?.title),
    },
  ];
  
  const formLayout = {
    labelCol: {
      span: 24,
      // offset: 2,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const getSelectedRoleId = () => {
    return selectedUser? getUserRoleIds(selectedUser)[0]: '';
  };

  let initialValues: SavedFormValue[] = [
    {
      field: "fullName",
      value: concatNames(selectedUser?.firstName, selectedUser?.lastName, selectedUser?.middleName, selectedUser?.title),
    },
    {
      field: "email",
      value: selectedUser?.email,
    },
    {
      field: "phone",
      value: selectedUser?.phone,
    },
    {
      field: "profileUrl",
      value: selectedUser?.profileUrl,
    },
    {
      field: "role",
      value: getSelectedRoleId(),
    },
    {
      field: "locations",
      value: selectedUser? getUserLocationIds(selectedUser).sort().join(","): "",
    },
  ];

  useEffect(()=>{
    dispatch(clearSavedFormValues());
    if(appUser && getAppHeaderType(appUser) === HeaderType.JUNTO_PORTAL){
      dispatch(onPage({onComponent : Pages.SETTINGS}));
      dispatch(sidebarNavigate(SideBarItems.USERS))
    } else {
      dispatch(onPage({onComponent : Pages.BIBLIOTHECA}));
      dispatch(sidebarNavigate(SideBarItems.BIBLIOTHECA_USERS))
    }
    if(selectedUser?.id === appUser?.id){
      dispatch(getProfile(selectedUser));
    }
  },[])

  useEffect(() => {
    if(selectedUser?.isActive !== false){
    dispatch(saveFormValues(initialValues));
    }
    else{
      return null
    }
  }, [selectedUser]);

  useEffect(() => {
    if(user && !isUserInvited){
      dispatch(selectUser(user));
    }
    return () => {
      dispatch(clearProfileSavedState());
      dispatch(clearSavedFormValues());
    };
  }, [user]);

  useEffect(() => {
    setHeaderSideNav();
    if (selectedUser?.locations) {
      const selectedLocations = selectedUser.locations.map((x: any) => {
        const location = {
          label: x.name,
          value: x.id,
        }
        return location;
      });
      setSelectedLocations(selectedLocations);
    }
    //debugger;
      if(selectedUser?.isActive !== false){
      dispatch(saveFormValues(initialValues));
      }
      else if(selectedUser?.emailVerifiedAt === "" || null){
        dispatch(saveFormValues(initialValues));
      }
      else{
        return null
      }
    setSaveInit(false);
  }, []);

  useEffect(() => {
    if (user && isUserBlocked && warnConfirmType===UserActionType.BLOCK_USER) {
      setShowWarn(false);
      const msg = replaceAll(/\{0\}/gi, t(Messages.USER_BLOCKED_SUCCESSFULLY), user.email);
      AppService.showToast(msg);
      dispatch(clearUser());
      dispatch(clearUserActions());
      history.push(urlModification(goToUserList()));
    }
    if (user && isUserBlocked && warnConfirmType===UserActionType.UNBLOCK_USER) {
      setShowWarn(false);
      const msg = replaceAll(/\{0\}/gi,t(Messages.USER_UNBLOCKED_SUCCESSFULLY), user.email);
      AppService.showToast(msg);
      dispatch(clearUser());
      dispatch(clearUserActions());
      history.push(urlModification(goToUserList()));
    }
    if (user && isUserBlocked && warnConfirmType===UserActionType.ASSIGN_AND_UNBLOCK_USER){
      const msg = replaceAll(/\{0\}/gi, t(Messages.USER_UNBLOCKED_SUCCESSFULLY), user.email);
      AppService.showToast(msg);
      dispatch(clearUser());
      dispatch(clearUserActions());
      history.push(urlModification(goToUserList()));
    }
  }, [isUserBlocked]);

  useEffect(() => {
    if (user && forgotPwdRequest) {
      setShowWarn(false);
      let msg = '';
      AppService.showToast(user?.errorMsg?user?.errorMsg:t(jsonData.RUN_NOW));
      dispatch(clearUser());
      dispatch(clearUserActions());
      user?.errorMsg?.includes(appUser?.email) && dispatch(logout());
    }
  }, [forgotPwdRequest]);

  useEffect(() => {
    if (isUserRemoved) {
      setShowWarn(false);
      const msg = replaceAll(/\{0\}/gi, t(Messages.USER_REMOVED_SUCCESSFULLY), deletedUserEmail?deletedUserEmail:jsonData.User);
      AppService.showToast(msg);
      dispatch(clearUser());
      dispatch(clearUserActions());
      history.push(urlModification(goToUserList()));
    }
  }, [isUserRemoved]);

  useEffect(() => {
    if (isUserInvited) {
      setShowWarn(false);
      const fullName = concatNames(user?.firstName, user?.lastName);
      const msg = replaceAll(/\{0\}/gi, 
        t(Messages.USER_INVITED_SUCCESSFULLY), 
        fullName
      );
      AppService.showToast(msg);
      dispatch(clearUser());
      dispatch(clearUserActions());
      history.push(urlModification(goToUserList()));
    }
  }, [isUserInvited]);

  useEffect(() => {
    if (isProfileSaved && !saveUnBlockUser) {
      dispatch(getAccountSettings(appUser))
      const msg = replaceAll(
        /\{0\}/gi,
        t(Messages.USER_UPDATED_SUCCESSFULLY),
        jsonData.User
      );
      AppService.showToast(msg);
      if(applyChanges && goTo){
        history.push(urlModification(goTo));
        dispatch(warnUnsave({calledBy: null, isWarnUnsaveOpen: false}));
      }
      else{
        history.push(urlModification(goToUserList()));
      }
    }else{
      if (isProfileSaved && saveUnBlockUser) {
        if(saveUnBlockUser){
          setWarnConfirmType(UserActionType.ASSIGN_AND_UNBLOCK_USER)
          dispatch(blockUser({ userId:blockedSelectedUser?.id, isActive: true }))
          setSaveUnblockUser(false)
          setBlockedSelectedUser(null)
        }
      }
      return () => {
        dispatch(clearProfileSavedState());
      };
    }
  }, [isProfileSaved]);

  const setHeaderSideNav = () => {
    MainLayoutService.renderHeader(HeaderType.JUNTO_PORTAL);
    MainLayoutService.renderSideBar(
      LeftMenuType.JUNTO_NORMAL,
      LibrarySettingsSideBarItem.USERS
    );
  };

  const onSelectRoleCallback = (roleId: string) => {
    setRoleUpdated(true)
    setRoleId(roleId)
    form.setFieldsValue({
      role: roleId,
      location:locations
    });

    onFormChangedCallback({
      target: {
        id: "role",
        type: "input",
        value: roleId,
      },
    });
  };

  useEffect(()=>{
    if(saveDirtyFormState.isSuccess && roleUpdated){
      
     const arrLocation = locations.map((x: any) => {
      return {
        label: x.name,
        value: x.id
      };
    });

  if(roles.find((x: any) => x.id === roleId)?.name === UserRole.ORGANISATION_ADMIN){
    onSelectLocationsCallback(arrLocation)
    onFormChangedCallback({
      target: {
        id: "locations",
        type: "input",
        value: arrLocation ? arrLocation.map((x: any) => x.value).sort().join(","): "",
      },
    });
  }else{
    setSelectedLocations([])
    onFormChangedCallback({
      target: {
        id: "locations",
        type: "input",
        value: [],
      },
    });
  }
  setRoleUpdated(false)
  }

  },[saveDirtyFormState])

  const onSelectLocationsCallback = (values: any[]) => {
    //debugger;
    setSelectedLocations(values? values: []);

    setTimeout(() => {
      if (savedFormValues) {
        dispatch(
          saveDirtyFormValues({
            target: `${formName}_locations`,
            savedFormValues: savedFormValues,
            explicitValue: (values && values.length) ? values.map((x: any) => x.value).sort().join(","): ""
          })
        );
      }
    }, 200);
  };

  const onFormChangedCallback = (e: any) => {
    if (savedFormValues) {
      if (e.target) {
        dispatch(
          saveDirtyFormValues({
            target: e.target,
            savedFormValues: savedFormValues,
          })
        );
      }
    }
  };

  const getLocations = () => {
      const arrLocation = locations.map((x: any) => {
        return {
          label: x.name,
          value: x.id
        };
      });
      return arrLocation;
  };

  const onSubmit = (data: any)=>{
    const detail = {...blockedSelectedUser,
      role: {
        id: blockRoleId,
        name: roles.find((x: any) => x.id === blockRoleId)?.name,
      },
      locations:selectedLocations
    };
    setTimeout(() => {
      dispatch(saveProfile(detail));
    }, 200);
    setShowModal(false);
    setSaveUnblockUser(true)
  
  }

  const onWarnModalCallback = () => {
    if (warnConfirmType === UserActionType.BLOCK_USER) {
      onBlockUser(false);
    } else if(warnConfirmType === UserActionType.CHANGE_PWD) {
      onResetPwd();
    } else if (warnConfirmType === UserActionType.REMOVE_USER) {
      onRemoveUser();
    } else if (warnConfirmType === UserActionType.UNBLOCK_USER) {
      onBlockUser(true);
    } else if (warnConfirmType === UserActionType.RESEND_INVITE) {
      if (userActionValue && userActionValue.id && userActionValue.id !=='') {
        var resendInviteParam = {
          deletedBy: null,
          email: userActionValue?.email,
          emailVerifiedAt:userActionValue?.emailVerifiedAt,
          userName:userActionValue?.userName,
          title:userActionValue?.title,
          firstName:userActionValue?.firstName,
          id:userActionValue?.id ,
          info: {},
          isActive:userActionValue?.isActive,
          lastName:userActionValue?.lastName,
          locations: [{id:userActionValue?.locations[0]?.id, name:userActionValue?.locations[0]?.role?.name, shortName:userActionValue?.locations[0]?.shortName,role:{id:userActionValue?.locations[0]?.role?.id, name:userActionValue?.locations[0]?.role?.name, type:userActionValue?.locations[0]?.role?.type}}],
          middleName:userActionValue?.middleName,
          organization: {id:userActionValue?.organization?.id, name:userActionValue?.organization?.name},
          phone:userActionValue?.phone,
          profileUrl: userActionValue?.profileUrl,
          token: null,
          updatedBy: null,
          role:{id:userActionValue?.locations[0]?.role?.id, name:userActionValue?.locations[0]?.role?.name, type:userActionValue?.locations[0]?.role?.type},
          userClaim: userActionValue?.userClaim ? userActionValue?.userClaim : {},
        }
      }
      onResetInvite(resendInviteParam);
    }
  };

  const onBlockUser = (isActive: boolean) => {
    if (userActionValue && userActionValue.id && userActionValue.id !=='') {
      dispatch(blockUser({userId:userActionValue.id, isActive:isActive}));
    }
  };

  const onResetInvite = (user: User) => {
    dispatch(reInviteUser(user));
};

  const onResetPwd = () => {
    if (userActionValue && userActionValue.id && userActionValue.id !=='') {
      const data: UserAuthInput = {
        id: '',
        email: userActionValue.email,
        password: ""
      }
      dispatch(sendForgotPwdLink(data));
    }
  };

  const onRemoveUser = () => {
    if (userActionValue && userActionValue.id && userActionValue.id !=='') {
      dispatch(removeUser(userActionValue));
    }
  };

  const onBlockUserCallback = (user: User) => {
    setWarnCancelBtn(t(BlockUserWarnModal.CANCEL_BTN_TEXT));
    setWarnConfirmBtn(t(BlockUserWarnModal.CONFIRM_BTN_TEXT));
    setWarnTitle(t(BlockUserWarnModal.TITLE));
    setWarnContent(t(BlockUserWarnModal.CONTENT));
    setShowWarn(true);
    setHideOkText(false)
    if (user && user.id && user.id !=='') {
      setUserActionValue(user);
      setWarnConfirmType(UserActionType.BLOCK_USER);
    }
  };

  const onUnBlockUserCallback = (user: User) => {
    setWarnCancelBtn(t(UnBlockUserWarnModal.CANCEL_BTN_TEXT));
    setWarnConfirmBtn(t(UnBlockUserWarnModal.CONFIRM_BTN_TEXT));
    setWarnTitle(t(UnBlockUserWarnModal.TITLE));
    setWarnContent(t(UnBlockUserWarnModal.CONTENT));
    setShowWarn(true);
    setHideOkText(false)
    if (user && user.id && user.id!=='') {
      setUserActionValue(user);
      setWarnConfirmType(UserActionType.UNBLOCK_USER);
    }
  };

  const getRoles = () => {
    let arrRole: any[] = [];
    if (roles && roles.length) {
      arrRole = arrRole.concat(
        roles.map((x: any) => {
          return { text: x.name, value: x.id };
        })
      );
    }
    return arrRole;
  };

  const onSelectRole = (roleId: string) => {
    setRoleId(roleId)
    setBlockRoleId(roleId)
    
    if(roles.find((x: any) => x.id === roleId)?.name === UserRole.ORGANISATION_ADMIN){
      setSelectedLocations(getLocations())
    }else{
      setSelectedLocations([])
    }
    form.setFieldsValue({
      role: roleId,
      locations: getLocations()
    })
  };

  const inputBox = ()=>{
    return(
      <>
      <Form layout="vertical" form={form} className="threedottedmodal">
              <Form.Item
                name="role"
                label="Role"
                rules={[
                  {
                    required: true,
                    message: t(jsonData.PleaseSelectRole)
                  },
                ]}
                className='label-cls selectRole pb-15'
              >
               <BitDropdown
                optionValue={getRoles()} defaultValue="Select Role"
                onChange={onSelectRole}
                />
              </Form.Item>
              <Form.Item
                name="locations"
                label={t(jsonData.locations)}
                className="label-cls locationErrorStyle" 
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const selected = selectedLocations;
                      if(roles.find((x: any) => x.id === blockRoleId)?.name === UserRole.ORGANISATION_ADMIN){
                        return Promise.resolve();
                      }
                      if (selected && selected.length) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t(jsonData.errorLoaction)));
                    },
                  }),
                ]}
              >           

            {
              roles.find((x: any) => x.id === roleId)?.name === UserRole.ORGANISATION_ADMIN ?
              <div className='branches-wrapper-for-0 disableIcon'>
              <div className='checkbox-label-cls-unselected'>All location selected</div></div>:
               <div className={`${formName}_locations locations-multi-select ant-row ant-form-item ant-form-item-with-help profile-label-cls`}>
               <div className="ant-col ant-col-24 ant-form-item-control userProfileMultiselect">
                 <div className="ant-form-item-control-input">
                   <div className= {roles.find((x: any) => x.id === roleId)?.name === UserRole.ORGANISATION_ADMIN ?"ant-form-item-control-input-content grayBackground":"ant-form-item-control-input-content "}>
                     <Multiselect
                       labelledBy={t(jsonData.SelectLocations)}
                       value={selectedLocations}
                       options={translateOptions(getLocations(),t,'label')}
                       onChange={onSelectLocationsCallback}
                       overrideStrings={{'allItemsAreSelected': t(jsonData.AllLocationSelected)}}
                       disabled={roles.find((x: any) => x.id === roleId)?.name === UserRole.ORGANISATION_ADMIN}
                     />
                   </div>
                 </div>
                 </div>
                 </div>
                  }                  
              </Form.Item>
        </Form>
      </>
    )
  }

  const onRistrictUnBlockUserCallback = (user: User) => {
    setShowModal(true);
    setBlockedSelectedUser(user)
  };

  const onResetPwdCallback = (user: User) => {
    if (user && user.id && user.id !=='') {
      setUserActionValue(user);
      setWarnConfirmType(UserActionType.CHANGE_PWD);
    }
    setWarnCancelBtn(t(ResetPwdWarnModal.CANCEL_BTN_TEXT));
    setWarnConfirmBtn(t(ResetPwdWarnModal.CONFIRM_BTN_TEXT));
    user && user.id === appUser.id?setWarnTitle(t(ResetPwdWarnModal.USER_TITLE)): setWarnTitle(t(ResetPwdWarnModal.ADMIN_TITLE));
    user && user.id === appUser.id?setWarnContent(t(ResetPwdWarnModal.USER_CONTENT)): setWarnContent(t(ResetPwdWarnModal.ADMIN_CONTENT));
    setShowWarn(true);
    setHideOkText(false)
  };

  const resendInviteCallback = (user: User) => {
    if (user && user.id && user.id !=='') {
      setUserActionValue(user);
      setWarnConfirmType(UserActionType.RESEND_INVITE);
    }
    user && user.id === appUser.id
      ? setWarnTitle(t(ResendInvite.USER_TITLE))
      : setWarnTitle(t(ResendInvite.ADMIN_TITLE));
    user && user.id === appUser.id
      ? setWarnContent(t(ResendInvite.USER_CONTENT))
      : setWarnContent(t(ResendInvite.ADMIN_CONTENT));
    setWarnCancelBtn(t(ResendInvite.CANCEL_BTN_TEXT));
    setWarnConfirmBtn(t(ResendInvite.CONFIRM_BTN_TEXT));
    setShowWarn(true);
    setHideOkText(false)
  };

  const onRemoveUserCallback = (user: User) => {
    if (user && user.id && user.id !=='') {
      setUserActionValue(user);
      setWarnConfirmType(UserActionType.REMOVE_USER);
    }
    setWarnCancelBtn(t(RemoveUserWarnModal.CANCEL_BTN_TEXT));
    setWarnConfirmBtn(t(RemoveUserWarnModal.CONFIRM_BTN_TEXT));
    setWarnTitle(t(RemoveUserWarnModal.TITLE));
    setWarnContent(t(RemoveUserWarnModal.CONTENT));
    setShowWarn(true);
    setHideOkText(false)
  };

  const onFormSubmitCallback = (data: any) => {
    if(!data){
      data = form.getFieldsValue();
    }
    if (validateForm()) {
      const parsedName = parseName(data.fullName);
      let lastName = parsedName?.lastName
      let suffix = parsedName?.suffix
      lastName = suffix? (lastName? lastName+" "+suffix : suffix) : lastName
      let roleName = "";
      const findRole = roles.find((x:any) => x.id === data.role);
      if (findRole) {
        roleName = findRole.name;
      }

      const organization: any = selectedUser?.organization;
      const user: any = {
        organization: organization,
        id: selectedUser?.id ?? '',
        // userName: selectedUser?.userName ?? '',
        // email: data.email,
        emailVerifiedAt:data.emailVerifiedAt,
        // title: parsedName.salutation ? parsedName.salutation : "",
        // firstName: parsedName.firstName ? parsedName.firstName: "",
        // middleName: parsedName.middleName ? parsedName.middleName: "",
        // lastName: lastName ? lastName : "",
        // phone: convertUserFormatToPhoneNumber(data?.phone).formatNumber,
        profileUrl: selectedUser?.profileUrl ?? "",
        locations: selectedLocations,
        isActive: selectedUser && selectedUser?.isActive? selectedUser?.isActive: false,
        role: {
          id: data.role,
          name: "",
        }
      };
      setSaveInit(true);
      setTimeout(() => {
        dispatch(saveProfile(user));
      }, 200);
    }
  };

  const validateForm = () => {
    let errCtrl: any = document.querySelector(`div.${formName}_locations.dropdown-container-error`);
    setElemDisplay(errCtrl, "none");
    removeClass("div.dropdown-heading-value", "ant-input");
    removeClass("div.profile_locations.locations-multi-select", "ant-form-item-has-error");
    if (!selectedLocations || (selectedLocations && selectedLocations.length === 0)) {
      setElemDisplay(errCtrl, "block");
      addClass("div.dropdown-heading-value", "ant-input")
      addClass("div.profile_locations.locations-multi-select", "ant-form-item-has-error");
      return false;
    }
    return true;
  };

  const canEdit = () => {
    return getPermissionFor(appUser?.permissions, Permissions.USERS)
    }

  return (
    <>
    { appUser && 
      (getAppHeaderType(appUser) === HeaderType.JUNTO_PORTAL && (appUser.locations[0].role.name === UserRole.LIBRARY_FRONT_DESK || window.location.pathname === "/organisation/user/profile"))
      ?
      <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} />
      :
      <>
        <WarnModal
          cancelButton={warnCancelBtn}
          confirmButton={warnConfirmBtn}
          primaryText={warnTitle}
          secondaryText={warnContent}
          isModalVisible={showWarn}
          hideOkText={hideOkText}
          cancelCallback={() => { 
            setUserActionValue(null);
            setShowWarn(false); 
          }}
          confirmCallback={onWarnModalCallback}
        />

      <WarnModal
          cancelButton={t(RistrictUnBlockUserWarnModal.CANCEL_BTN_TEXT)}
          primaryText={t(RistrictUnBlockUserWarnModal.CONTENT)}
          secondaryText={inputBox()}
          isModalVisible={showModal}
          hideOkText={false}
          disabled={!blockRoleId || selectedLocations?.length === 0}
          cancelCallback={() => {
            setShowModal(false); 
            form.resetFields()
            setSelectedLocations([])
            setBlockRoleId("")

          }}
          confirmCallback={onSubmit}

        />

        <WarnUnsavedChanges
          ignorePrompt={saveInit}
          navigateOnCancel={true}
          title={t(UnsavedChangesWarnModal.TITLE)}
          content={t(UnsavedChangesWarnModal.CONTENT)}
          cancelBtnText={t(UnsavedChangesWarnModal.DISCARD_BTN_TEXT)}
          confirmBtnText={t(UnsavedChangesWarnModal.CONFIRM_BTN_TEXT)}
          isDirty={isDirty ? isDirty : false}
          onConfirm ={onFormSubmitCallback}
        />
        
        <Loader loading={formState?.loading}>
          <UserProfileHeader
            formName={formName}
            user={selectedUser}
            breadcrumbs={breadcrumbs}
            isDirty={isDirty}
            applyChangesBtnText={t(jsonData.ApplyChanges)}
            allSavedBtnText={t(jsonData.Saved)}
            blockUserCallback={onBlockUserCallback}
            resetPwdCallback={onResetPwdCallback}
            removeUserCallback={onRemoveUserCallback}
            unBlockUserCallback={onUnBlockUserCallback}
            ristrictUnBlockUserCallback={onRistrictUnBlockUserCallback}
            setWarnConfirmType={setWarnConfirmType}
            resendInviteCallback={resendInviteCallback}
            isEditAllowed={canEdit()}
          />
          <CompWrapper observeOn='profileHed'>
          <UserProfileForm
            formName={formName}
            form={form}
            formLayout={formLayout}
            user={selectedUser}
            roles={roles}
            locations={locations}
            selectedRoleId={getSelectedRoleId()}
            avatarColorList={AvatarColorList}
            selectedLocations={selectedLocations}
            locationValidationMsg={Messages.LOCATION_NOT_SELECTED}
            selectRoleCallback={onSelectRoleCallback}
            selectLocationsCallback={onSelectLocationsCallback}
            formSubmitCallback={onFormSubmitCallback}
            formChangedCallback={onFormChangedCallback}
            isEditAllowed={canEdit()}
            roleId={roleId}
          />
          </CompWrapper>
        </Loader>
      </>
    }
    </>
  );
};
