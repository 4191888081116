import styled from "styled-components";

export const CheckBoxWrapper = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 16px;
`
export const CheckboxLabel = styled.div`
    margin-top:7px;
    margin-left:16px;
    margin-bottom:7px;
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: 40px;
    color: ${(props) => props.theme.colors.palette.darkestGray};
`;