/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react"
import { MainLayoutService } from "../../../../services/ui/mainLayoutService"
import { AppRoutes, HeaderType, UserRole } from "../../../../constants/enums"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from 'react-router-dom'
import { onPage, sidebarNavigate, warnUnsave } from "../../../../../redux/actions/app/appAction"
import { SideBarItems } from "../../../../constants/sideBarConstants"
import { DeleteDeviceWarnModal, Device_Type, Pages, UnsavedChangesWarnModal, jsonData } from "../../../../constants/constants"
import { CompWrapper } from "../../../stateless/common/compWrapper/compWrapper"
import { Form, Row, Col, Tooltip } from 'antd'
import { Custominput } from "../../../../../bit_components/common/custominput"
import { AppError } from "../../../stateless/common/appError"
import { Button } from "../../../../../bit_components/common/button"
import { Dropdown } from "../../../../../bit_components/common/dropdown"
import { cameFromOrganisation, urlModification } from "../../../../../utility/appUtil"
import './style.less'
import { DeviceInfoTitle } from "./index.styles"
import { deleteDevice, fetchDeviceInfo, fetchDeviceTypeList, updateDeviceInfo } from "../../../../../redux/actions/device/deviceAction"
import { fetchTransformedLocationList } from "../../../../../redux/actions/role/locationAction"
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer"
import { appSelector } from "../../../../../redux/reducers/app/appReducers"
import { sortList } from "../../../../../utility/utils"
import { locationListSelector } from "../../../../../redux/reducers/location/locationListReducer"
import { clearDeviceFormStates, clearDeviceInfo, DeviceSelector } from "../../../../../redux/reducers/device/deviceReducer"
import WarnUnsavedChanges from "../../common/warnUnsavedChanges"
import { clearSavedFormValues, commonSelector } from "../../../../../redux/reducers/common/commonReducer"
import { SavedFormValue } from "../../../../models/common/savedFormValue"
import Loader from "../../../stateless/common/spinner"
import { Breadcrumbs } from "../../../../../bit_components/common/breadcrumbs"
import { saveDirtyFormValues, saveFormValues } from "../../../../../redux/actions/common/commonAction"
import { Messages } from "../../../../constants/messages"
import { AppService } from "../../../../services/ui/appService"
import { DeleteIconImg } from "../../../../images"
import WarnModal from "../../../stateless/common/warnModal"
import { Redirect } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import CopyComponent from "../../../stateless/common/copyComponent"

interface DeviceInfoInterface {
    DeviceIdentifier: string,
    name: string
    product: number
    location: string
    serialNo: string
    yearOfManufacturing: number
    version: string
    modelNo: string
    endOfSupport: string
    deviceType:string
    Osversion?:string
    LocalIPAddress?:string
    MACAddress?:string
    SoftwareVersion?:string
    SerialNumber?:string
}

export const DeviceInfo = (props: any) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const { appUser } = useSelector(userStateSelector)
    const { selectedOrganisation, selectedDevice, applyChanges, goTo, isWarnUnsaveOpen } = useSelector(appSelector)
    const { savedFormValues, isDirty } = useSelector(commonSelector);
    const { locations } = useSelector(locationListSelector)
    const { deviceTypeList, deviceInfo, deviceInfoFormstate, updateDeviceFormstate, deleteDeviceFormstate } = useSelector(DeviceSelector)
    const [form] = Form.useForm()
    const [saveInit, setSaveInit] = useState(false)
    const [showWarn, setShowWarn] = useState(false)
    const [notCloudCheck, setNotCloudCheck] = useState<boolean>(false);
    const [breadCrumbClick, setBredCrumClick] = useState<Boolean>(false)
    const isCloud = 'cloudCheck tablet'

    const routes = [
        {
            text: (t(SideBarItems.DEVICE_SETTINGS)),
            href: urlModification(AppRoutes.DEVICE_SETTINGS),
        },
        {
            text: selectedDevice?.info?.name ?? selectedDevice?.deviceName,
        }
    ]

    //Delete device
        const handleDeleteDevice = () => {
        dispatch(deleteDevice(deviceInfo?.id))
    };


    const getOrgId = () => {
        let orgId = appUser?.organization.id as string;
        if (cameFromOrganisation()) {
            orgId = selectedOrganisation?.id;
        }
        return orgId;
    }

    let DeviceTypeListParams = {
        organizationId: getOrgId(),
        isOrgFilter : true
      }

    useEffect(() => {
        MainLayoutService.renderHeader(HeaderType.JUNTO_PORTAL);
        dispatch(onPage({ onComponent: Pages.SETTINGS }));
        dispatch(sidebarNavigate(SideBarItems.DEVICE_SETTINGS));
        dispatch(fetchDeviceTypeList(DeviceTypeListParams))
        dispatch(fetchTransformedLocationList(getOrgId()))
        dispatch(fetchDeviceInfo({ id: selectedDevice?.deviceId }))
        return () => {
            dispatch(clearDeviceInfo())
            dispatch(clearSavedFormValues())
        }
    }, [])

    useEffect(() => {
        let values: DeviceInfoInterface =
        {
            DeviceIdentifier: deviceInfo?.id,
            name: deviceInfo?.info?.name,
            location: deviceInfo?.hierarchyId,
            product: deviceInfo?.deviceTypeId,
            serialNo: deviceInfo?.serialNo,
            yearOfManufacturing: deviceInfo?.yearOfManufacturing,
            version: deviceInfo?.version,
            modelNo: deviceInfo?.modelNo,
            endOfSupport: deviceInfo?.endOfSupport,
            deviceType :deviceInfo?.deviceType,
            Osversion: deviceInfo?.profile?.osVersion,
            LocalIPAddress: deviceInfo?.profile?.ipAddress,
            MACAddress: deviceInfo?.profile?.macAddress,
            SerialNumber: deviceInfo?.serialNo
        }
        if(values.deviceType !== 'cloudCheck tablet' && values.deviceType !== 'cloudCheck mobile' && values.deviceType!== undefined){
            setNotCloudCheck(true)
        } else if(appUser.locations[0].role.name === UserRole.ORGANISATION_ADMIN){
            setNotCloudCheck(true)
        }
        let initialValues: SavedFormValue[] = [
            {
                field: "DeviceIdentifier",
                value: values.DeviceIdentifier
            },
            {
                field: "name",
                value: values.name
            },
            {
                field: "location",
                value: values.location,
            },
            {
                field: "product",
                value: values.product,
            },
            {
                field: "serialNo",
                value: values.serialNo ?? ''
            },
            {
                field: "yearOfManufacturing",
                value: values.yearOfManufacturing?.toString() ?? ''
            },
            {
                field: "version",
                value: values.version ?? ''
            },
            {
                field: "modelNo",
                value: values.modelNo ?? ''
            },
            {
                field: "endOfSupport",
                value: values.endOfSupport ?? ''
            },
        ]
        dispatch(saveFormValues(initialValues))
        form.setFieldsValue(values)
    }, [deviceInfo])

    const getDeviceProducts = () => {
        const arrTypes = deviceTypeList.map((x: any) => {
            return {
                text: x.deviceType,
                value: x.deviceTypeId,
            }
        })
        const sortedBylabel = arrTypes.sort(sortList('text'));
        return sortedBylabel
    }

    const getLocations = () => {
        let selectedLocation : any = selectedDevice?.location
        if(Device_Type.CLOUDCHECKMOBILE_LOC == selectedLocation){
            return [{
                text: selectedDevice?.location,
                value: selectedDevice?.hierarchyId
            }]
        }
        else{
            const arrLocation = locations.map((x: any) => {
                return {
                    text: x.name,
                    value: x.id.toString(),
                }
            })
            const sortedBylabel = arrLocation.sort(sortList('text'));
            const finalSortedLoc = sortedBylabel.filter(data => data.text !== Device_Type.CLOUDCHECKMOBILE_LOC )
            return finalSortedLoc
        }
    }

    const onSelectProduct = (id: string) => {
        form.setFieldsValue({
            product: id,
        });

        onFormValuesChange({
            target: {
                id: "product",
                type: "input",
                value: id,
            },
        });
    }

    const onSelectLocation = (id: string) => {
        form.setFieldsValue({
            location: id,
        });

        onFormValuesChange({
            target: {
                id: "location",
                type: "input",
                value: id,
            },
        });
    }

    const onFormValuesChange = (e: any) => {
        if (savedFormValues) {
            if (e.target) {
                dispatch(
                    saveDirtyFormValues({
                        target: e.target,
                        savedFormValues: savedFormValues,
                    })
                );
            }
        }
    };

    const handleFinish = (data: DeviceInfoInterface) => {
        let saveData: any = {
            info: { ...deviceInfo?.info, name: data.name },
            deviceTypeId: data.product,
            hierarchyId: data.location,
            updatedBy: appUser?.id
        }
        if (data.yearOfManufacturing) {
            saveData.yearOfManufacturing = data.yearOfManufacturing
        }
        if (data.serialNo) {
            saveData.serialNo = data.serialNo
        }
        if (data.version || data.modelNo || data.endOfSupport) {
            saveData.deviceProfile = {
                deviceTypeId: data.product,
                version: data.version,
                modelNo: data.modelNo,
                endOfSupport: data.endOfSupport,
                updatedBy: appUser?.id
            }
            if (!deviceInfo?.deviceProfileId) {
                saveData.deviceProfile = { ...saveData.deviceProfile, createdBy: appUser?.id }
            }
            else {
                saveData.deviceProfileId = deviceInfo?.deviceProfileId
            }
        }
        setSaveInit(true)
        dispatch(updateDeviceInfo({ id: deviceInfo?.id, device: saveData }))
    }

    useEffect(() => {
        if (updateDeviceFormstate?.isSuccess) {
            AppService.showToast(t(Messages.DEVICE_UPDATED))
            if (applyChanges && goTo) {
                history.push(urlModification(goTo));
                dispatch(warnUnsave({ calledBy: null, isWarnUnsaveOpen: false }))
            }
            else {
                history.push(urlModification(AppRoutes.DEVICE_SETTINGS))
            }
            dispatch(clearDeviceInfo())
            dispatch(clearDeviceFormStates())
        }
        else if (updateDeviceFormstate?.isError) {
            AppService.showToast(t(Messages.ERROR_OCCURRED))
            isWarnUnsaveOpen && dispatch(warnUnsave({ calledBy: null, isWarnUnsaveOpen: false }))
            dispatch(clearDeviceFormStates())
        }
    }, [updateDeviceFormstate])

    useEffect(() => {
        if (deleteDeviceFormstate?.isSuccess) {
            AppService.showToast(t(Messages.DEVICE_DELETED))
            history.push(urlModification(AppRoutes.DEVICE_SETTINGS))
            dispatch(clearDeviceFormStates())
        }
        else if (deleteDeviceFormstate?.isError) {
            AppService.showToast(t(Messages.ERROR_OCCURRED))
            dispatch(clearDeviceFormStates())
        }
    }, [deleteDeviceFormstate])

    let menu = deviceInfo?.profile?.softwareVersion

    let dataSoftwareVersion = deviceInfo?.profile?.softwareVersion ? true :false

    let softwareVersion = dataSoftwareVersion && Object.keys(menu)?.map((key) => {
        let data =  <Col md={12} lg={8} className="labelName pr-54">
        <Custominput
            labelSubName={key}
            labelName={key}
            name={key}
            placeholder={key}
            autoComplete="off"
            disabled={true}
            initialValue={menu[key]}
        />
        <div className="copy-style">
            <CopyComponent text={form.getFieldValue(key)}/>
        </div>
    </Col>
    return data
    } )

    const breadcrumbClick  = () => {
        setBredCrumClick(true)
      }
  
      const getDropDownText = (options: any[], fieldValue: string) => {
        const foundOption = options.find(
          (option) => option.value === fieldValue
        );
        return foundOption?.text ?? "";
      };
     let isDeleteVisible=( deviceInfo?.deviceType === 'cloudCheck tablet'|| deviceInfo?.deviceType === 'cloudCheck mobile')
    return (
        <>
        {(appUser.locations[0].role.name === UserRole.LIBRARY_FRONT_DESK) ?

            <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} />
            :
            <>
                <WarnUnsavedChanges
                    ignorePrompt={saveInit}
                    navigateOnCancel={true}
                    title={t(UnsavedChangesWarnModal.TITLE)}
                    content={t(UnsavedChangesWarnModal.CONTENT)}
                    cancelBtnText={t(UnsavedChangesWarnModal.DISCARD_BTN_TEXT)}
                    confirmBtnText={t(UnsavedChangesWarnModal.CONFIRM_BTN_TEXT)}
                    isDirty={isDirty ? isDirty : false}
                    onConfirm={form.submit}
                />
                <WarnModal
                    cancelButton={t(DeleteDeviceWarnModal.CANCEL_BTN_TEXT)}
                    confirmButton={t(DeleteDeviceWarnModal.CONFIRM_BTN_TEXT)}
                    primaryText={t(DeleteDeviceWarnModal.TITLE)}
                    secondaryText={t(DeleteDeviceWarnModal.CONTENT)}
                    isModalVisible={showWarn}
                    cancelCallback={() => {
                        setShowWarn(false)
                    }}
                    confirmCallback={handleDeleteDevice}
                />
                <Form form={form} layout="vertical" name="deviceForm" requiredMark={false} onChange={onFormValuesChange} onFinish={handleFinish} scrollToFirstError={true}>
                    <div className="pl-vw">
                        <div className="device-info-breadcrumb" onKeyPress={breadcrumbClick}>
                            <Breadcrumbs aria-label={t(jsonData.Devices)} breadcrumbs={routes} breadCrumbClick={breadCrumbClick} setBredCrumClick={setBredCrumClick} />
                        </div>
                        <Row className='device-info-header'>
                            <Col span={10} sm={9} md={12} lg={14} className="device-title">
                                <DeviceInfoTitle>{selectedDevice?.info?.name ?? selectedDevice?.deviceName}</DeviceInfoTitle>
                            </Col>
                            {(appUser.locations[0].role.name !== UserRole.ORGANISATION_ADMIN) && (
                                 <Button 
                                    tabIndex={0}
                                    aria-label={`${jsonData.Delete} ${jsonData.Device}`} 
                                    role={""} 
                                    type={`actionButton deviceDeleteButton ${(isDeleteVisible) ? "" : "visibilty-hidden"} `} 
                                    onClick={(e: any)=> {
                                         e.preventDefault();
                                         setShowWarn(true)
                                    }}
                                    > 
                                    <Tooltip overlayClassName='dashBoardTooltip'  placement="bottomRight" title={t(jsonData.DeleteDevice)}>
                                          {t(jsonData.Delete)}
                                    </Tooltip>
                                    </Button>
                            )}
                            <Col span={5} sm={7} md={5} lg={3} xl={4}  className='device-info-saveBtn' onKeyPress={form.submit}>
                                <Form.Item >
                                    {
                                        updateDeviceFormstate?.loading ?
                                            <Button disabled type="smallBlack">
                                                <div className="loaderButton"><span className="spinner"></span></div>{t(jsonData.Saving)}
                                            </Button>
                                            :
                                            (appUser.locations[0].role.name !== UserRole.ORGANISATION_ADMIN) ? 
                                                (isDirty ? (
                                                    <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.ApplyChanges)}>
                                                    <div>
                                                    <Button
                                                        type="smallBlack"
                                                        htmlType="submit"
                                                    >
                                                        {t(jsonData.ApplyChanges)}
                                                    </Button>
                                                    </div>
                                                    </Tooltip>
                                                ) : (
                                                    <Button type="smallBlack" disabled htmlType="submit">
                                                        {t(jsonData.AllSaved)}
                                                    </Button>
                                                ))
                                            :
                                            <div></div>
                                    }
                                </Form.Item>

                            </Col>
                        </Row>

                        <Loader loading={deviceInfoFormstate?.loading}>
                            <CompWrapper otherClasses='pl-23'>
                                <AppError show={false} errorMsg={''} />
                                <Row gutter={[50, 30]}>
                                <Col md={12} lg={8} className="labelName pr-54">
                                        <Custominput
                                            labelSubName={t(jsonData.DeviceIdentifier)}
                                            labelName={t("DeviceIdentifier")}
                                            name="DeviceIdentifier"
                                            placeholder={t(jsonData.DeviceIdentifier)}
                                            autoComplete="off"
                                            disabled={true}
                                            initialValue={deviceInfo?.id}
                                            value={deviceInfo?.id}
                                        />
                                        <div className="copy-style">
                                            <CopyComponent text={deviceInfo.id}/>
                                        </div>
                                    </Col>
                                    <Col md={12} lg={8} className="labelName pr-54">
                                        <Custominput
                                            labelSubName={t(jsonData.DeviceName)}
                                            labelName={t("name")}
                                            name="name"
                                            placeholder={t(jsonData.DeviceName)}
                                            autoComplete="off"
                                            disabled={notCloudCheck}
                                            value={form.getFieldValue("name")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t(jsonData.DeviceNameRequired)
                                                },
                                                {
                                                    max: 100,
                                                    message: t(jsonData.DeviceNameLengthExceededMsg)
                                                },
                                                { 
                                                    pattern: new RegExp(/.*?[^0-9].*/),
                                                    message: t(jsonData.DeviceNameNotEmptyMsg)
                                                },
                                            ]}
                                        />
                                         <div className="copy-style">
                                            <CopyComponent text={form.getFieldValue("name")}/>
                                        </div>
                                    </Col>
                                    <Col span={12} lg={8} className="pr-54">
                                        <Form.Item
                                            className="device-info-dropDown"
                                            label={t(jsonData.DeviceType)}
                                            name="product"
                                            initialValue={null}
                                            rules={[{ required: true, message: t(jsonData.DeviceTypeRequired) }]}
                                        >
                                            <Dropdown
                                                id="product"
                                                optionValue={getDeviceProducts()}
                                                onChange={onSelectProduct}
                                                disabled={true}
                                                value={form.getFieldValue("product")}
                                            ></Dropdown>
                                        </Form.Item>
                                        <div className="copy-style">
                                            <CopyComponent text={getDropDownText(getDeviceProducts(),form.getFieldValue("product"))}/>
                                        </div>
                                    </Col>
                                    <Col md={12} lg={8} className="pr-54">
                                        <Form.Item
                                            className="device-info-dropDown"
                                            label={t(jsonData.Location)}
                                            name="location"
                                            initialValue={null}
                                            rules={[{ required: true, message: t(jsonData.LocationIsRequired) }]}
                                        >
                                            <Dropdown
                                                id="location"
                                                tabIndex={0}
                                                disabled={notCloudCheck}
                                                optionValue={getLocations()}
                                                onChange={onSelectLocation}
                                                value={form.getFieldValue("location")}
                                            ></Dropdown>
                                        </Form.Item>
                                        <div className="copy-style">
                                            <CopyComponent text={getDropDownText(getLocations(),form.getFieldValue("location"))}/>
                                        </div>
                                    </Col>
                                    {/* <Col md={12} lg={8} className="labelName">
                                        <Custominput labelSubName="Serial No." labelName="serialNo" name="serialNo" placeholder="Serial No."/>
                                    </Col>
                                    <Col md={12} lg={8} className="labelName">
                                        <Custominput
                                            rules={[
                                                {
                                                    pattern: new RegExp(/^[0-9]*$/),
                                                    message: 'Please enter a valid year'
                                                }
                                            ]}
                                            labelSubName="Year of Manufacturing"
                                            labelName="yearOfManufacturing"
                                            name="yearOfManufacturing"
                                            placeholder="Year of Manufacturing"
                                            autoComplete="off"
                                        />
                                    </Col>
                                    <Col md={12} lg={8} className="labelName">
                                        <Custominput labelSubName="Version" labelName="version" name="version" placeholder="Version" autoComplete="off"/>
                                    </Col>
                                    <Col md={12} lg={8} className="labelName">
                                        <Custominput labelSubName="Model No." labelName="modelNo" name="modelNo" placeholder="Model No." autoComplete="off"/>
                                    </Col>
                                    <Col md={12} lg={8} className="labelName">
                                        <Custominput labelSubName="End of Support" labelName="endOfSupport" name="endOfSupport" placeholder="End of Support" autoComplete="off"/>
                                    </Col> */}

                                        <Col md={12} lg={8} className="labelName pr-54">
                                            <Custominput
                                                labelSubName={t(jsonData.OSVersion)}
                                                labelName="Osversion"
                                                name="Osversion"
                                                placeholder={t(jsonData.OSVersion)}
                                                autoComplete="off"
                                                disabled={true}
                                                value={form.getFieldValue("Osversion")}
                                            />
                                            <div className="copy-style">
                                                <CopyComponent text={form.getFieldValue("Osversion")}/>
                                            </div>
                                        </Col>


                                        <Col md={12} lg={8} className="labelName pr-54">
                                            <Custominput
                                                labelSubName={t(jsonData.LocalIPAddress)}
                                                labelName="LocalIPAddress"
                                                name="LocalIPAddress"
                                                placeholder={t(jsonData.LocalIPAddress)}
                                                autoComplete="off"
                                                disabled={true}
                                                value={form.getFieldValue("LocalIPAddress")}
                                            />
                                            <div className="copy-style">
                                                <CopyComponent text={form.getFieldValue("LocalIPAddress")}/>
                                            </div>
                                        </Col>

                                        <Col md={12} lg={8} className="labelName pr-54">
                                            <Custominput
                                                labelSubName={t(jsonData.MACAddress)}
                                                labelName="MACAddress"
                                                name="MACAddress"
                                                placeholder={t(jsonData.MACAddress)}
                                                autoComplete="off"
                                                disabled={true}
                                                value={form.getFieldValue("MACAddress")}
                                            />
                                            <div className="copy-style">
                                                <CopyComponent text={form.getFieldValue("MACAddress")}/>
                                            </div>
                                        </Col>

                                        <Col md={12} lg={8} className="labelName pr-54">
                                            <Custominput
                                                labelSubName={t(jsonData.SerialNumber)}
                                                labelName="SerialNumber"
                                                name="SerialNumber"
                                                placeholder={t(jsonData.SerialNumber)}
                                                autoComplete="off"
                                                disabled={true}
                                                value={form.getFieldValue("SerialNumber")}
                                            />
                                            <div className="copy-style">
                                                <CopyComponent text={form.getFieldValue("SerialNumber")}/>
                                            </div>
                                        </Col>
                                                {softwareVersion}

                                </Row>
                            </CompWrapper>
                        </Loader>
                    </div>
                </Form>
            </>
         }
         </>
    )
};
