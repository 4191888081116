import { useTranslation } from "react-i18next";
import { DEFAULT_PAGE, DevicePickerFormat, Pages, WORK_ITEM_DEFAULT_LIMIT, WORK_ITEM_PAGE_SIZE, WorkItemStatusEnum, WorkItemStatuses, jsonData } from "../../../constants/constants";
import "./styles.less";
import { Col, Row, Tooltip } from "antd";
import { AntdInfinityTable } from "../../stateless/common/antdTable/antdInfininityTable";
import { useEffect, useRef, useState } from "react";
import CustomFilter from "../../stateless/common/customTableFilter";
import { deviceDeleteIcon, expandArrow } from "../../../images";
import { cameFromOrganisation, getElapsedTime, getHeight, getOrganizationDateAndTime, getUserRoles } from "../../../../utility/appUtil";
import { useDispatch, useSelector } from "react-redux";
import { WorkSelector, clearFetchWorkItemsFormState } from "../../../../redux/reducers/workItems/workReducer";
import { appSelector } from "../../../../redux/reducers/app/appReducers";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import { fetchTransformedLocationList } from "../../../../redux/actions/role/locationAction";
import { MainLayoutService } from "../../../services/ui/mainLayoutService";
import { HeaderType, UserRole } from "../../../constants/enums";
import { onPage, sidebarNavigate } from "../../../../redux/actions/app/appAction";
import { SideBarItems } from "../../../constants/sideBarConstants";
import { sortList } from "../../../../utility/utils";
import { fetchDeviceListByOrgId } from "../../../../redux/actions/device/deviceAction";
import { locationListSelector } from "../../../../redux/reducers/location/locationListReducer";
import { DeviceSelector } from "../../../../redux/reducers/device/deviceReducer";
import { changeWorkItemStatus, fetchUserName, fetchWorkItemName, fetchWorkItemsByOrgId } from "../../../../redux/actions/workItems/workAction";
import AntdPagination from "../../stateless/common/pagination/pagination";
import secureLocalStorage from "react-secure-storage";
import WorkHeader from "./workHeader";
import { ArrowUpOutlined, CheckCircleFilled, CloseCircleFilled, DeleteTwoTone, LoadingOutlined, MinusCircleFilled, MinusCircleOutlined } from "@ant-design/icons";
import Loader from "../../stateless/common/spinner";
import { WorkCentrediv, WorkItemKey, WorkItemValue, WorkTableRow } from "./index.styles";
import { Button } from "../../../../bit_components/common/button";
import { retryWorkItem as retryWorkItemAction, deleteWorkItem as deleteWorkItemAction } from "../../../../redux/actions/workItems/workAction";
import moment from "moment";

interface workElements {
  id: string
  status: string,
  hierarchyName: string,
  deviceName: string,
  locationName: string,
  workItemType: string,
  userName: string,
  info: any,
  updatedAt: string
}

export const WorkItems = (props:any) => {
    const dispatch = useDispatch();
    const [scrolledToEnd, setScrolledToEnd] = useState(false);
    const [scrollHeight, setScrollHeight] = useState(200);
    const [tableData, setTableData] = useState([]);
    const [unfilteredData, setUnfilteredData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [filteredInfo, setFilteredInfo] = useState<any>(null);
    const [filtersChanged, setFiltersChanged] = useState(false);
    const [statusFilter, setStatusFilter] = useState([]);
    const [workItemFilter, setWorkItemFilter] = useState([]);
    const [locationFilter, setLocationFilter] = useState([]);
    const [deviceFilter, setDeviceFilter] = useState([]);
    const [userNameFilter, setUserNameFilter] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const { selectedOrganisation } = useSelector(appSelector);
    const { appUser } = useSelector(userStateSelector);
    const { locations } = useSelector(locationListSelector);
    const [apiParams, setApiParams] = useState({});
    const [rowId, setRowId] = useState<any>(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>();
    const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
    const [screenHeight, setScreenHeight] = useState(document.body.clientHeight);
    const [screenWidth, setScreenWidth] = useState(document.body.clientWidth);
    const { deviceList } = useSelector(DeviceSelector);
    const [pageChanged, setPageChanged] = useState<boolean>(false);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [retryWorkId, setRetryWorkId] = useState(null)
    const [deleteWorkId, setDeleteWorkId] = useState(null)
    const [isRetryVisible, setIsRetryVisible] = useState(true)
    const [isDeleteVisible, setIsDeleteVisible] = useState(true)
    const {
      workItems,
      totalWorkCount,
      userNameList,
      workItemNameList,
      fetchWorkItemsFormState,
      retryWorkItemsFormState,
      deleteWorkItemsFormState
    } = useSelector(WorkSelector);
    
    const { t } = useTranslation();

    useEffect(() => {
      if (appUser) {
        const roles = getUserRoles(appUser);
        const isFrontOfficeRole = roles.includes(UserRole.LIBRARY_FRONT_DESK);
        if (isFrontOfficeRole) {
          setIsRetryVisible(false);
          setIsDeleteVisible(false);
        }
      }
    }, [appUser]);
  
    useEffect(() => {
      dispatch(clearFetchWorkItemsFormState());
      dispatch(fetchTransformedLocationList(getOrgId()));
      dispatch(fetchUserName(getOrgId()));
      dispatch(fetchWorkItemName(getOrgId()));
      MainLayoutService.renderHeader(HeaderType.JUNTO_PORTAL);
      dispatch(sidebarNavigate(SideBarItems.WORK_ITEMS));
      dispatch(onPage({ onComponent: Pages.JUNTO_PORTAL }));
      const node = document.body
      const resizeObserver = new ResizeObserver((entries) => {
        setScreenHeight(node?.clientHeight)
        setScreenWidth(node?.clientWidth)
      });
      resizeObserver.observe(node);
    }, []);

    useEffect(() => {
      if (!fetchWorkItemsFormState?.loading && !loading) {
  
        //table height
        const tableHeight = getHeight('.ant-table-body')
  
        //to calculate table footer height
        const compContentHeight = getHeight('.ant-layout-content')
        const bottomGap = compContentHeight - (screenHeight * 0.8)
        const pagination_div = document.querySelector('.alert-pagination')?.clientHeight
        const footerHeight = (totalWorkCount / WORK_ITEM_DEFAULT_LIMIT) > 1 ? pagination_div + bottomGap : 0;

        //to calculate final height of the table
        const calculatedHeight = tableHeight - footerHeight
        const scrollH = parseInt(calculatedHeight.toFixed(0));
        !isNaN(scrollH) && setScrollHeight(scrollH)
      }
    }, [screenHeight, screenWidth, fetchWorkItemsFormState?.loading, loading])

    useEffect(() => {
      if(userNameList?.length){
        let formattedList = userNameList?.map((name:string) => {
          return {
            value: name,
            text: name
          }
        })
        setUserNameFilter(formattedList);
      }
    }, [userNameList]);

    useEffect(() => {
      if(workItemNameList?.length){
        let formattedList = workItemNameList?.map((type:{id: string, workItemType: string}) => {
          return {
            value: type?.id,
            text: type?.workItemType
          }
        })
        setWorkItemFilter(formattedList);
      }
    }, [workItemNameList]);

    useEffect(() => {
      if (workItems?.length) {
        setUnfilteredData(workItems)
        setTableData(workItems?.slice(currentIndex, currentIndex + WORK_ITEM_PAGE_SIZE))
        setCurrentIndex(currentIndex + WORK_ITEM_PAGE_SIZE);
        setLoading(false)
      }
      else if (!fetchWorkItemsFormState?.loading) {
        setLoading(false)
      }
    }, [workItems]);

    useEffect(() => {
      if (fetchWorkItemsFormState?.isError) {
        setLoading(false)
      }
    }, [fetchWorkItemsFormState]);

    const getLocations = (list: any) => {
      const arrLocation = list?.map((x: any) => {
        return {
          text: x.name,
          value: x.id,
        }
      })
      const sortedBylabel = arrLocation.sort(sortList('text'));
      return sortedBylabel
    }

    const getDeviceList = (list: any) => {
      const arrDevices = list?.map((x: any) => {
        return {
          text: x.info.name ?? (x.serialNo ?? x.id),
          value: x.id,
        }
      })
      const sortedBylabel = arrDevices.sort(sortList('text'));
      return sortedBylabel
    }

    useEffect(() => {
      if (selectedLocation?.length < locations?.length && selectedLocation?.length) {
        dispatch(fetchDeviceListByOrgId({ orgId: getOrgId(), locations: selectedLocation }))
      }
      else {
        dispatch(fetchDeviceListByOrgId({ orgId: getOrgId() }))
      }
    }, [selectedLocation]);

    useEffect(() => {

      //filter table  by dropdown type
      setStatusFilter(WorkItemStatuses);
      setLocationFilter(getLocations(locations));
      setDeviceFilter(getDeviceList(deviceList));
    }, [locations, deviceList]); 


    const setToInitial = () => {
      const node = document.querySelector<HTMLElement>(
        ".table .ant-table-body"
      );
      node?.scrollTo(0, 0);
      setCurrentIndex(0)
      setTableData([])
      setUnfilteredData([])
      setSelectedRowKeys(null)
      setRowId(null)
    }

    const onParamsUpdated = (params: any) => {
      setLoading(true);
      setToInitial();

      if (!params?.page) {
        setCurrentPage(DEFAULT_PAGE)
      }
      let parameters: any = {
        ...apiParams,
        ...params,
        limit: WORK_ITEM_DEFAULT_LIMIT,
        page: params?.page ?? DEFAULT_PAGE
      }

      if(!filtersChanged){
        if (filteredInfo?.status) {
          parameters.status = filteredInfo?.status
        }
        if (filteredInfo?.locationName) {
          parameters.hierarchyIdList = filteredInfo?.locationName
        }
        if (filteredInfo?.deviceName) {
          parameters.deviceIdList = filteredInfo?.deviceName
        }
        if (filteredInfo?.workItemType) {
          parameters.workItemType = filteredInfo?.workItemType
        }
        if (filteredInfo?.userName) {
          parameters.userName = filteredInfo?.userName
        }
      }
      setApiParams(parameters);
      dispatch(fetchWorkItemsByOrgId(parameters));
      setPageChanged(false);
    }

    const onPageChange = (page: any) => {
      setPageChanged(true)
      setApiParams({ ...apiParams, page: page, limit: WORK_ITEM_DEFAULT_LIMIT })
      setCurrentPage(page)
    }

    useEffect(() => {
      if (pageChanged) {
        onParamsUpdated(apiParams)
      }
    }, [apiParams]);

    const getOrgId = () => {
      let orgId = appUser?.organization.id as string;
      if (cameFromOrganisation()) {
        orgId = selectedOrganisation?.id;
      }
      return orgId;
    }

    const onScroll = (event: any) => {
      if (event.target.scrollTop && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
        setScrolledToEnd(true);
      }
    }

    useEffect(() => {
      if (filtersChanged) {
        onParamsUpdated({
          ...apiParams,
          page: DEFAULT_PAGE,
          status: filteredInfo?.status,
          workItemType: filteredInfo?.workItemType,
          hierarchyIdList: filteredInfo?.locationName,
          deviceIdList: filteredInfo?.deviceName,
          userName: filteredInfo?.userName
        })
        setFiltersChanged(false)
      }
    }, [filteredInfo])

    const fetchData = async (startIndex: number) => {
      const data = unfilteredData.slice(startIndex, startIndex + WORK_ITEM_PAGE_SIZE);
      return data;
    }

    const handleFetch = () => {
      if (tableData?.length !== unfilteredData?.length && scrolledToEnd) {
        setScrolledToEnd(false);
        fetchData(currentIndex).then(async (newData: any) => {
          setTableData(tableData.concat(newData));
          setCurrentIndex(currentIndex + WORK_ITEM_PAGE_SIZE);
        });
      }
    }

    const callRetryWorkItem = (workItem) => {    
      const params = {
        deviceWorkId: workItem?.deviceWorkId,
        lcDeviceId: workItem?.lcDeviceId
      }
      setRetryWorkId(workItem?.id)
      dispatch(retryWorkItemAction(params));
      dispatch(changeWorkItemStatus(params));
      onParamsUpdated(apiParams);
    }

    const callDeleteWorkItem = (workItem) => {    
      const params = {
        deviceWorkId: workItem?.deviceWorkId,
        lcDeviceId: workItem?.lcDeviceId
      }
      setDeleteWorkId(workItem?.id)
      dispatch(deleteWorkItemAction(params));
      onParamsUpdated(apiParams);
    }

    const getProgressForStatus = (status: string) => {
      switch(status){
        case WorkItemStatusEnum.CREATED:
          return t(jsonData.Created);
        case WorkItemStatusEnum.IN_PROGRESS:
          return t(jsonData.InProgress);
        case WorkItemStatusEnum.SUCCESS:
          return t(jsonData.Success);
        case WorkItemStatusEnum.FAILED:
          return t(jsonData.Failed);
        case WorkItemStatusEnum.DELETED:
          return t(jsonData.Deleted);
        default:
          return status;
      }
    }

    useEffect(() => {
      setTableColumns(
        alertCentreTableColumn(
          statusFilter,
          workItemFilter,
          locationFilter,
          deviceFilter,
          userNameFilter
        )
      )
    }, [statusFilter, filteredInfo, workItemFilter, locationFilter, deviceFilter, userNameFilter])

    const alertCentreTableColumn = (statusFilter: any, workItemFilter: any, locationFilter: any, deviceFilter: any, userNameFilter: any ) => {

      const selectedFilterUpdate = (data: any, key: any,reset:any) => {
        const updatedFilterStates = { ...filteredInfo };
        if (updatedFilterStates?.hasOwnProperty(key)) {
          setFilteredInfo({...filteredInfo, [key]:data[key]})
        } else {
          setFilteredInfo({...filteredInfo, ...data})
        }
        if(key === 'locationName'){
          setSelectedLocation(data[key])
        }
        setFiltersChanged(true)
      };
      return [
        {
          title: <span className='alertHeadingText'>{t(jsonData.Progress)}
            <CustomFilter
              optionList={statusFilter}
              selectedData={selectedFilterUpdate}
              selectedItems={filteredInfo?.status ?? []}
              allSelectedText={t(jsonData.AllStates)}
              placeholder={t(jsonData.SelectState)}
              optionListClass="state-optionList-severity22"
              wrapperClassName="state-multiselect"
              coulmnName="status"
              filterName={t(jsonData.ProgressFilter)}
            />
          </span>,
          dataIndex: "status",
          key: `${t(jsonData.Progress)}`,
          width: 50,
          ellipsis: true,
          filteredValue: filteredInfo?.status || null,
          render(data){
            switch(data){
              case WorkItemStatusEnum.CREATED:
                return <span className="progress-icons">
                  <span>
                    <ArrowUpOutlined/>
                  </span>
                  <span>{t(jsonData.Created)}</span>
                </span>
              case WorkItemStatusEnum.IN_PROGRESS:
                return <span className="progress-icons">
                  <span>
                    <LoadingOutlined/>
                  </span>
                  <span>{t(jsonData.InProgress)}</span>
                </span>
              case WorkItemStatusEnum.SUCCESS:
                return <span className="progress-icons">
                  <span>
                    <CheckCircleFilled style={{color:'#14AE23'}}/>
                  </span>
                  <span>{t(jsonData.Success)}</span>
                </span>
              case WorkItemStatusEnum.FAILED:
                return <span className="progress-icons">
                  <span>
                    <CloseCircleFilled style={{color:'#EE0000'}}/>
                  </span>
                  <span>{t(jsonData.Failed)}</span>
                </span>
              case WorkItemStatusEnum.DELETED:
                return <span className="progress-icons">
                  <span>
                    <MinusCircleFilled/>
                  </span>
                  <span>{t(jsonData.Deleted)}</span>
                </span>
              default:
                return <span>{data}</span>
            }
          } 
        },
        {
          title: <span className='alertHeadingText'>{t(jsonData.Location)}
            <CustomFilter
              optionList={locationFilter}
              selectedData={selectedFilterUpdate}
              selectedItems={filteredInfo?.locationName ?? []}
              allSelectedText={t(jsonData.AllStates)}
              placeholder={t(jsonData.SelectState)}
              optionListClass="state-optionList-location22"
              wrapperClassName="state-multiselect"
              coulmnName="locationName"
              filterName={t(jsonData.locationFilter)}
              allowSearch={true}
            />
          </span>,
          dataIndex: "locationName",
          key: `${t(jsonData.Location)}`,
          width: 50,
          ellipsis: true,
          filteredValue: filteredInfo?.locationName || null,
        },
        {
          title: <span className='alertHeadingText'>{t(jsonData.Device)}
            <CustomFilter
              optionList={deviceFilter}
              selectedData={selectedFilterUpdate}
              selectedItems={filteredInfo?.deviceName ?? []}
              allSelectedText={t(jsonData.AllStates)}
              placeholder={t(jsonData.SelectState)}
              optionListClass="state-optionList-device22"
              wrapperClassName="state-multiselect"
              coulmnName="deviceName"
              filterName={t(jsonData.deviceFilter)}
              allowSearch={true}
            />
          </span>,
          dataIndex: "deviceName",
          key: `${t(jsonData.Device)}`,
          width: 50,
          ellipsis: true,
          filteredValue: filteredInfo?.deviceName || null,
          render(data) {
            return <span className='deviceRowDivStye'>
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={data}>
                    <span className='deviceNamestyle'>{data}</span>
                </Tooltip>
                {data?.isArchived  ?
                    <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title="Deleted">
                        <img src={deviceDeleteIcon} alt="" className='InsightdeviceDeleteIcon'/>
                    </Tooltip> : ""
                }
            </span>
          }
        },
        {
          title: <span className='alertHeadingText'>{t(jsonData.WorkItemName)}
            <CustomFilter
              optionList={workItemFilter}
              selectedData={selectedFilterUpdate}
              selectedItems={filteredInfo?.workItemType ?? []}
              allSelectedText={t(jsonData.AllStates)}
              placeholder={t(jsonData.SelectState)}
              optionListClass="state-optionList-alert22"
              wrapperClassName="state-multiselect"
              coulmnName="workItemType"
              filterName={t(jsonData.WorkItemFilter)}
              allowSearch={true}
            />
          </span>,
          dataIndex: "workItemType",
          key: `${t(jsonData.WorkItemName)}`,
          width: 50,
          ellipsis: true,
          filteredValue: filteredInfo?.workItemType || null,
        },
        {
          title: <span className='alertHeadingText'>{t(jsonData.UserName)}
            <CustomFilter
              optionList={userNameFilter}
              selectedData={selectedFilterUpdate}
              selectedItems={filteredInfo?.userName ?? []}
              allSelectedText={t(jsonData.AllStates)}
              placeholder={t(jsonData.SelectState)}
              optionListClass="state-optionList-alert22"
              wrapperClassName="state-multiselect"
              coulmnName="userName"
              filterName={t(jsonData.UserNameFilter)}
              allowSearch={true}
            />
          </span>,
          dataIndex: "userName",
          key: `${t(jsonData.UserName)}`,
          width: 50,
          ellipsis: true,
          filteredValue: filteredInfo?.userName || null,
        },
        {
          title: <span className='alertHeadingText'>{t(jsonData.LastUpdated)}</span>,
          width: 50,
          ellipsis: true,
          render(data) {
            return <span className="work-item-lastColumn">
              {moment(data.updatedAt).isAfter(moment().subtract(24, 'hours'))
                ? getElapsedTime(data.updatedAt, {text: 'long'})
                : getOrganizationDateAndTime(data.updatedAt, DevicePickerFormat.DATE_TIME)
              }
              <span className="work-item-expandIcon">
                <img src={expandArrow} alt="" />
              </span>
            </span>
          }
        }
      ]
    }

    let localeInfo: any = secureLocalStorage.getItem('locale');
    let element: any = document.querySelectorAll('.ant-table-row');
    element.forEach((element) => {
      if(element){
        element?.setAttribute("tabIndex", "0");
      }
    });

    //expand table
    //get antd table row id
    const onRow = (record: any) => {
      return {
        onKeyPress:(event: any) => {
          if (rowId !== record.id) {
            setSelectedRowKeys(record.id);
            setRowId(record.id);
          }
          else {
            setSelectedRowKeys(0);
            setRowId(null);
          }
        },
        onClick: (event: any) => {
          if (rowId !== record.id) {
            setSelectedRowKeys(record.id);
            setRowId(record.id);
          }
          else {
            setSelectedRowKeys(0);
            setRowId(null);
          }
        },
      };
    };

    const setRowClassName = (record: any) => {
      return record.id === rowId ? "work-item-expanded" : "";
    }

    const expandable = {
      expandedRowRender: (record: workElements) =>
        <div className="work-item-description">
          <WorkTableRow>
            <Col className="work-item-col-1" >
              <div className="work-item-content-1">
                <Row>
                  <WorkItemKey>{t(jsonData.WorkItemName)}</WorkItemKey>
                  <WorkItemValue className="work-item-progress">{record?.workItemType}</WorkItemValue>
                </Row>
                <Row>
                  <WorkItemKey>{t(jsonData.Progress)}</WorkItemKey>
                  <WorkItemValue className="work-item-progress">{getProgressForStatus(record?.status)}</WorkItemValue>
                </Row>
                <Row>
                  <WorkItemKey>{t(jsonData.Device)}</WorkItemKey>
                  <WorkItemValue>{record?.deviceName}</WorkItemValue>
                </Row>
              </div>
            </Col>
            <Col className="work-item-col-1" >
              <div className="work-item-content-2">
                <Row>
                  <WorkItemKey>{t(jsonData.Location)}</WorkItemKey>
                  <WorkItemValue>{record?.locationName}</WorkItemValue>
                </Row>
                <Row>
                  <WorkItemKey>{t(jsonData.LastUpdated)}</WorkItemKey>
                  <WorkItemValue>{getOrganizationDateAndTime(record?.updatedAt, DevicePickerFormat.DATE_TIME)}</WorkItemValue>
                </Row>
                {record?.info?.CanShowMessage?.toString() === 'true' &&
                  <Row>
                    <WorkItemKey>{t(jsonData.Message)}</WorkItemKey>
                    <WorkItemValue className="work-item-progress">{record?.info?.Message}</WorkItemValue>
                  </Row>
                }
              </div>
            </Col>
            <Col className="work-item-col-1" >
              <div className="work-item-content-2">
                {(record?.info?.CanRetryLastFailedWorkItem?.toString() === 'true') && (record?.info?.CurrentState?.toString() === '3') &&
                  isRetryVisible && moment(record?.updatedAt).isAfter(moment().subtract(1, 'hours')) &&
                  <Row>
                    {retryWorkItemsFormState?.loading && retryWorkId === record?.id ?
                      <Button disabled type="smallBlack work-retry-btn">
                          <div className="loaderButton"><span className="spinner"></span></div>{t(jsonData.Retrying)}
                      </Button>
                      :
                      <Button type="smallBlack work-retry-btn" onClick={() => callRetryWorkItem(record)}>
                        {t(jsonData.Retry)}
                      </Button>
                    }
                  </Row>
                }
                {(record?.status === WorkItemStatusEnum.CREATED) && (isDeleteVisible || ((appUser.locations[0].role.name === UserRole.LIBRARY_FRONT_DESK) && (appUser?.userName == record.userName))) &&
                  <Row>
                    {deleteWorkItemsFormState?.loading && deleteWorkId === record?.id ?
                      <Button disabled type="smallBlack work-retry-btn">
                        <div className="l0aderButton"><span className="spinner"></span></div>{t(jsonData.Deleting)}
                      </Button>
                      :
                      <Button type="smallBlack work-retry-btn" onClick={() => callDeleteWorkItem(record)} disabled={true}>
                        {t(jsonData.Delete)}
                      </Button>
                    }
                  </Row>
                }
              </div>
            </Col>
          </WorkTableRow>
        </div>
    };

    return (
      <WorkCentrediv>
        <div className="pl-vw">
          <WorkHeader
            onParamsUpdated={onParamsUpdated}
          />
        </div>
        <div className="work-centre-div">
            <AntdInfinityTable
              className="table"
              tableClassName="work-item-table"
              pagination={false}
              onScroll={onScroll}
              scroll={{
                y: scrollHeight,
                scrollToFirstRowOnChange: true,
              }}
              dataSource={tableData}
              columns={tableColumns}
              onFetch={handleFetch}
              pageSize={WORK_ITEM_DEFAULT_LIMIT}
              locale={!fetchWorkItemsFormState?.loading && !unfilteredData?.length && !tableData.length && { emptyText: t(jsonData.NoDataFound) }}
              rowKey="id"
              onRow={onRow}
              rowClassName={setRowClassName}
              expandable={expandable}
              expandedRowKeys={[selectedRowKeys]}
            />
            {
              (totalWorkCount / WORK_ITEM_DEFAULT_LIMIT) > 1 &&
              <AntdPagination className='alert-pagination' total={totalWorkCount} current={currentPage} defaultPageSize={WORK_ITEM_DEFAULT_LIMIT} onChange={(page: any, pageSize: any) => onPageChange(page)} />
            }
        </div>
        {fetchWorkItemsFormState?.loading || loading ? <Loader loading={true} /> : null}
      </WorkCentrediv>
    )
}