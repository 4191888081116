/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { SideBarItems } from "../../../../constants/sideBarConstants";
import { onPage, sidebarNavigate } from "../../../../../redux/actions/app/appAction";
import { appSelector } from "../../../../../redux/reducers/app/appReducers";
import { cameFromOrganisation } from "../../../../../utility/appUtil";
import { Pages } from "../../../../constants/constants";

export const DeviceList = (props: any) => {
  const dispatch = useDispatch();
  const { appUser } = useSelector(userStateSelector);
  const { selectedOrganisation } = useSelector(appSelector);

  useEffect(() => {
    setOrganisationId(getOrgId());
  }, [selectedOrganisation]);

  useEffect(() => {
    dispatch(onPage({onComponent : Pages.JUNTO_PORTAL}));
    dispatch(sidebarNavigate(SideBarItems.DEVICES));
  }, []);

  const getOrgId = () => {
    let orgId = appUser?.organization.id as string;
      if(cameFromOrganisation()) {
        orgId = selectedOrganisation?.id;
      }      
      return orgId;
  }

  const [ organisationId, setOrganisationId ] = useState<string>(getOrgId());

  // return <Devices organizationId={organisationId}/>;
  return null
};

