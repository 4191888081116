/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppRoutes, UserRole } from "../../../../constants/enums";
import { InviteUserHeader } from "../../../stateless/user/inviteUser/inviteUserHeader";
import { InviteUserForm } from "../../../stateless/user/inviteUser/inviteUserForm";
import { roleListSelector } from "../../../../../redux/reducers/role/roleListReducer";
import { MainLayoutService } from "../../../../services/ui/mainLayoutService";
import { HeaderType, LeftMenuType } from "../../../../constants/enums";
import { LibrarySettingsSideBarItem, SideBarItems } from "../../../../constants/sideBarConstants";
import { useEffect } from "react";
import { locationListSelector } from "../../../../../redux/reducers/location/locationListReducer";
import User from "../../../../models/user/user";
import { useHistory } from "react-router-dom";
import { createUser } from "../../../../../redux/actions/user/userAction";
import { userStateSelector, clearState, clearUser, clearUserActions } from "../../../../../redux/reducers/user/userReducer";
import { concatNames, getAppHeaderType, replaceAll, urlModification } from "../../../../../utility/appUtil";
import { Messages } from "../../../../constants/messages";
import '../../../stateless/common/customScrollBar/customScrollStyle.less'
import { onPage, sidebarNavigate } from "../../../../../redux/actions/app/appAction";
import { Pages } from "../../../../constants/constants";
import { Redirect } from "react-router-dom"
import { useTranslation } from 'react-i18next';

export const InviteUser = (props: any) => {
  const { t } = useTranslation()
  const history = useHistory();
  const dispatch = useDispatch();
  const { formState, appUser, user ,isUserInvited } = useSelector(userStateSelector);
  const { roles } = useSelector(roleListSelector);
  const { locations } = useSelector(locationListSelector);
  
  const goToUserList = () => {
    if (appUser && getAppHeaderType(appUser) === HeaderType.BIBLIOTHECA_PORTAL){
      return AppRoutes.ORGANISATION_USERS
    }
    else {
      return AppRoutes.USER_LIST
    }
  }

  const breadcrumbs = [
    {
      text: SideBarItems.USERS,
      href: urlModification(goToUserList())
    },
    // {
    //   text: "Add New User",
    //   href: urlModification(AppRoutes.ADD_NEW_USER),
    // },
  ];

  useEffect(() => {
    dispatch(clearState());
    dispatch(clearUser());
    if(appUser && getAppHeaderType(appUser) === HeaderType.JUNTO_PORTAL){
      dispatch(onPage({onComponent : Pages.SETTINGS}));
      dispatch(sidebarNavigate(SideBarItems.USERS))
    } else {
      dispatch(onPage({onComponent : Pages.BIBLIOTHECA}));
      dispatch(sidebarNavigate(SideBarItems.BIBLIOTHECA_USERS))
    }
    return () => {
      dispatch(clearUser());
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    setHeaderSideNav();
  }, []);

  useEffect(() => {
    if (formState.isError) {

    } else if (formState.isSuccess) {
      //debugger;
      if (user && isUserInvited) {
        const fullName = concatNames(user?.firstName, user?.lastName);
        const msg = replaceAll(/\{0\}/gi, t(Messages.USER_INVITED_SUCCESSFULLY), fullName);
        dispatch(clearUser());
        dispatch(clearUserActions())
        setTimeout(() => {
          history.push(urlModification(goToUserList()), { message: msg })
        }, 200);
      }
    }
  }, [formState.isError, formState.isSuccess]);

  const setHeaderSideNav = () => {
    MainLayoutService.renderHeader(HeaderType.JUNTO_PORTAL);
    MainLayoutService.renderSideBar(
      LeftMenuType.JUNTO_NORMAL,
      LibrarySettingsSideBarItem.USERS
    );
  };

  const onUploadSpreadSheetCallback = () => {
  };

  const onFormSubmitCallback = (user: User) => {
    dispatch(createUser(user));
  };

  return (
    <>
    {(appUser &&  
      ((getAppHeaderType(appUser) === HeaderType.BIBLIOTHECA_PORTAL && appUser.locations[0].role.name !== UserRole.PLATFORM_ADMIN) ||
      (getAppHeaderType(appUser) === HeaderType.JUNTO_PORTAL && (appUser.locations[0].role.name === UserRole.LIBRARY_FRONT_DESK || window.location.pathname === "/organisation/user/new"))))
      ?
      <>
      {getAppHeaderType(appUser) === HeaderType.JUNTO_PORTAL ?
      <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} /> :
      <Redirect to={AppRoutes.ORGANISATION_OVERVIEW} />}
      </>
      :
      <>
      <InviteUserHeader
        breadcrumbs={breadcrumbs}
        uploadSpreadSheetCallback={onUploadSpreadSheetCallback}
      />
      <InviteUserForm appUser={appUser} roles={roles} locations={locations} errorMsg={user?.errorMsg} formSubmitCallback={onFormSubmitCallback} />
      </>
    }
    </>
  );
};
