import React from 'react'
import { Form } from 'antd'
import AuthLayout from '../../../layouts/authLayout'
import { LoginContainer } from '../authenticatePwdForm/authenticatePwdForm.Styles'
import '../../../../../components/stateless/common/button/button.less'
import '../authenticatePwdForm/authenticatePwdForm.less'
import { UserAuthInput } from '../../../../../models/user/userAuthInput'
import { parseQuery } from '../../../../../../utility/urlUtil'
import { AppError } from '../../../../stateless/common/appError'
import CustomPassword from '../../../common/inputField/customPassword'
import {Button} from '../../../../../../bit_components/common/button'
import { LoginHead} from '../emailLoginForm/emailLoginForm.Styles'
import { ForgotNote } from '../authenticatePwdForm/authenticatePwdForm.Styles'
import '../createPwdForm/createPwd.less'

export const ResetPwdForm = (props: any) => {
  const {
    form,
    formLayout,
    location,
    resetPwdCallback,
    errorMsg,
    isChangePwd = false,
  } = props
  //debugger;
  const onSubmit = (data: any) => {
    if (resetPwdCallback) {
      const queryParams = parseQuery(location.search)
      const findToken =
        queryParams && queryParams.length
          ? queryParams.find((x) => x.name === 'token')
          : null
      const userAuth: UserAuthInput = {
        id: '',
        email: '',
        password: data.newPassword,
        confirmPwd: data.confirmPassword,
        token: findToken ? findToken.value : '',
      }
      resetPwdCallback(userAuth)
    }
  }

  return (
    <>
      {isChangePwd ? (
        <ChangePwdForm
          form={form}
          errorMsg={errorMsg}
          formLayout={formLayout}
          onSubmit={onSubmit}
        />
      ) : (
        <AuthLayout>
          <ChangePwdForm
            form={form}
            errorMsg={errorMsg}
            formLayout={formLayout}
            onSubmit={onSubmit}
          />
        </AuthLayout>
      )}
    </>
  )
}

const ChangePwdForm = (props: any) => {
  const { form, errorMsg, formLayout, onSubmit } = props
  return (
    <LoginContainer className='resetPassContainer'>
      <LoginHead>Change Password</LoginHead>
        <ForgotNote>
          Password for your account has been reset. Please create a new password
          that you will use to log in.
        </ForgotNote>

      <AppError show={errorMsg ? true : false} errorMsg={errorMsg} />

      <Form
        ref={form}
        {...formLayout}
        name='forgot-pwd'
        className='login-form'
        initialValues={{
          remember: true,
        }}
        onFinish={onSubmit}
        requiredMark={false}
      >
        <CustomPassword
          label='New Password'
          customLabelClass='changePasswordLabel resetPassword'
          name='newPassword'
          placeholder="New Password"
          rules={[
            {
              required: true,
              message: 'Please input your Password!', // TODO: Handle this while developing validation framework.
            },
            {
              pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`])[A-Za-z\d^$*.[\]{}()?"!@#%&/\\,><':;|_~`]{8,}$/),
              message: "Password must have atleast 8 characters, an uppercase letter, a lowercase letter, a number and a special character"
            },
            {
              max: 98,
              message: "Maximum length reached"
            }
          ]}
        />

        <CustomPassword
          label='Confirm New Password'
          customLabelClass='changePasswordLabel confirmPasswordLabel'
          name='confirmPassword'
          placeholder="Confirm New Password"
          rules={[
            {
              required: true,
              message: '"Passwords do not match"', // TODO: Handle this while developing validation framework.
            },

            ({ getFieldValue }: { getFieldValue: any }) => ({
              validator(_: any, value: any) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve()
                }

                return Promise.reject(
                  new Error('"Passwords do not match"')
                ) // TODO: Handle this while developing validation framework.
              },
            }),
          ]}
        />
        <Form.Item className="CreatePassButton">
          <Button type='primary loginButton' htmlType='submit'>
            Save New Password
          </Button>
        </Form.Item>
      </Form>
    </LoginContainer>
  )
}
