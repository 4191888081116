/* eslint-disable no-useless-escape */
import React, {useState} from 'react'
import { Form } from 'antd'
import { useDispatch } from "react-redux";
import { BackIconImg } from '../../../../../images'
import AuthLayout from '../../../layouts/authLayout'
import { LoginContainer } from '../authenticatePwdForm/authenticatePwdForm.Styles'
import '../../../../../components/stateless/common/button/button.less'
import '../authenticatePwdForm/authenticatePwdForm.less'
import { UserAuthInput } from '../../../../../models/user/userAuthInput'
import { AppError } from '../../../../stateless/common/appError'
import {Custominput} from '../../../../../../bit_components/common/custominput'
import '../emailLoginForm/emailLoginForm.less'
import {Button} from '../../../../../../bit_components/common/button'
import { LoginBackButton, AllignCenter, ForgotNote } from '../authenticatePwdForm/authenticatePwdForm.Styles'
import { LoginHead} from '../emailLoginForm/emailLoginForm.Styles'
import { clearForgotPwdRequest } from '../../../../../../redux/reducers/user/userReducer';

export const ForgotPwdForm = (props: any) => {
  const { formLayout, email, goBackCallback, sendResetPwdLink, errorMsg } =
    props
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch()
  const onGoBack = () => {
    if (goBackCallback) {
      goBackCallback()
    }
  }

  const validateEmail = (email: any) => {
    const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/)
    if(emailRegex.test(email)){
      return true;
    }
    else{
      setShowError(true);
      setErrorMessage('Please enter a valid email address');
      return false;
    }
  };

  const onSendResetLink = (data: any) => {
    //debugger;
    if (sendResetPwdLink && validateEmail(data.email)) {
      const userAuthInput: UserAuthInput = {
        id: '',
        email: data.email,
        password: '',
      }
      sendResetPwdLink(userAuthInput)
    }
  }

  const onChangeHandle = (e:any) => {
    if(showError){
      setShowError(false);
      setErrorMessage('');
    }
    if(errorMsg){
      dispatch(clearForgotPwdRequest())
    }
    return e.target.value
  }

  return (
    <AuthLayout>
      <LoginContainer className='forgotPassContainer'>
        <AllignCenter onClick={onGoBack}>
          <img className='backImg' src={BackIconImg} alt='back'></img>
          <LoginBackButton>Back</LoginBackButton>
        </AllignCenter>

        <LoginHead>Forgot Password</LoginHead>
        <ForgotNote>
          Enter the email address associated with your account, and we’ll email
          you or send a text with a link to reset your password.
        </ForgotNote>

        <Form
          {...formLayout}
          name='forgot-pwd'
          className='login-form'
          initialValues={{
            email: email,
            remember: true,
          }}
          onFinish={onSendResetLink}
          requiredMark={false}
        >
          <Custominput
            labelSubName='Email'
            customLabelClass={`labelName ${errorMsg || showError ?'loginError ant-form-item-has-error':''}`}
            labelName='email'
            placeholder="Email"
            rules={[
              {
                required: true,
                message: 'Please input your Email!', // TODO: Handle this while developing validation framework.
              },
            ]}
            onChange={onChangeHandle}
          />
          <AppError show={(errorMsg ? true : false) || showError} errorMsg={errorMsg || errorMessage} />
          <Form.Item className='formItemInput mb-0'>
            <Button type='primary forgotbutton' htmlType='submit'>
              Send Reset Link
            </Button>
          </Form.Item>
        </Form>
      </LoginContainer>
    </AuthLayout>
  )
}
