import { Subject } from "rxjs";
import { HeaderType, LeftMenuType } from "../../constants/enums";

const headerSubject = new Subject();
const sideBarSubject = new Subject();
const selectedRoleIdSubject = new Subject();

export const MainLayoutService = {
  
  renderHeader: (type: HeaderType) =>
    headerSubject.next({
      data: {
        type: type,
      },
    }),
  renderSideBar: (type: LeftMenuType, selectedIndex: number) =>
    sideBarSubject.next({
      data: {
        type: type,
        selectedIndex: selectedIndex,
      },
    }),
  listenHeader: () => headerSubject.asObservable(),
  listenSideBar: () => sideBarSubject.asObservable(),
  //clearMessages: () => subject.next(),

  selectRoleId: (id: string) =>
    selectedRoleIdSubject.next({
      id: id,
    }),
  listenRoleId: () => selectedRoleIdSubject.asObservable(),
};
