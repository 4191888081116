/* eslint-disable @typescript-eslint/no-unused-vars */
import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { eventDeliveryModeCode } from "../../../web/constants/constants";
import { getNotification } from "../../models/notification/notifications";

export default class NotificationService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.NOTIFICATIONS));
  } 

  // fetch Notification 
  async fetchNotifications(getNotification: getNotification | null): Promise<any> {
    var parameters = getNotification
    const response = await this.get(`eventNotification?userId=${parameters.userId}&isRead=false&isAlert=false&modeId=${eventDeliveryModeCode.WEB_DELIVERY}`);
    if(response && response.data) {
      return response;
    }
    return [];
  }

  // Notification transformation
  async fetchTransformedNotifications(getNotification: getNotification | null): Promise<any> {
    var parameters = getNotification
    let response: any

    if(parameters?.organisationId){
      response = await this.get(`notificationHeader?userId=${parameters.userId}&organisationId=${parameters.organisationId}&isRead=false&isAlert=false&modeId=${eventDeliveryModeCode.WEB_DELIVERY}`);
    } else {
      response = await this.get(`notificationHeader?userId=${parameters.userId}&isRead=false&isAlert=false&modeId=${eventDeliveryModeCode.WEB_DELIVERY}`);
    }

    if(response && response.data) {
      return response;
    }
    return [];
  }
  
    // acknowledge Notification 
    async postAcknowlege(notificationInput: any | null): Promise<any> {
      const response: any = await this.put(`notification/ack`, notificationInput);
      if(response && response.data) {
        return response;
      }
      return await Promise.resolve(null);
    }

    async sentSms(parameters:any): Promise<any> {
      const response: any = await this.post('sendSMSPatron', parameters)
      if (response?.data?.result)
        return response?.data?.result;
      else
        return null
    }

    async sentEmail(parameters:any): Promise<any> {
      const response: any = await this.post('sendPatron', parameters)
      if (response?.data?.result)
        return response?.data?.result;
      else
        return null
    }
}
