// Base Imports
import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { PublicRoute } from "../components/routing/publicRoute";
import { DeviceManagementRoutes, EventLogOutputRoute, PrivateRoutes, PublicRoutes } from "./appRoutes";
import { PrivateRoute } from "../components/routing/privateRoute";
import MainLayout from "../components/stateless/layouts/mainLayout";
import DeviceLayout from "../components/stateless/layouts/deviceLayout";
import { ThemeProvider } from "styled-components";
import { GetProjectTheme } from "../../bit_components/styles/theme";
import EventLayout from "../components/stateless/layouts/eventLayout";
import { AppRoutes } from "../constants/enums";
import { loggedInRedirectRoute } from "../../utility/appUtil";
import GaWrap from "../components/stateless/layouts/gaWrap";

export default (props: any) => {
  const pathName = window.location.pathname;
  const search = window.location.search;
  const { isAuthenticated, appUser, logoutCallback } = props;
  
  return (
    <Router>
      {/* Public Routes */}
      <GaWrap>
      <Switch>
        {PublicRoutes.map((x) => (
          <PublicRoute key={x.path} path={x.path} exact component={x.component} appUser={appUser} isAuthenticated={isAuthenticated} />
        ))}
      </Switch>
      </GaWrap>

      {/* Private Routes */}
      {PrivateRoutes.map((x, index) => (
        <Route key={`${x.routePath.replaceAll("/", "")}-${index}`} path={x.routePath}>
          <MainLayout logoutCallback={logoutCallback} {...props}>
          <GaWrap {...props}>
            <Switch>
              {x.routes.map((r, rIndex) => (
                <PrivateRoute key={`${r.path.replaceAll("/", "")}-${rIndex}`} exact component={r.component} path={r.path} appUser={appUser} isAuthenticated={isAuthenticated} />
              ))} 
            </Switch>
            </GaWrap>
          </MainLayout>
        </Route>
      ))}
      
      {/* Event Log Output Routes */}
      {EventLogOutputRoute.map((x, index) => (
        <Route key={`${x.routePath.replaceAll("/", "")}-${index}`} path={x.routePath}>
          <EventLayout logoutCallback={logoutCallback} {...props}>
          <GaWrap  {...props}>
            <Switch>
              {x.routes.map((r, rIndex) => (
                <PrivateRoute key={`${r.path.replaceAll("/", "")}-${rIndex}`} exact component={r.component} path={r.path} appUser={appUser} isAuthenticated={isAuthenticated} />
              ))} 
            </Switch>
            </GaWrap>
          </EventLayout>
        </Route>
      ))}

      {/* Device Management Routes */}
      {DeviceManagementRoutes.map((x, index) => (
        <Route key={`${x.routePath.replaceAll("/", "")}-${index}`} path={x.routePath}>
          <DeviceLayout {...props}>
          <GaWrap>
            <ThemeProvider theme={GetProjectTheme()}>
              {x.routes.map((r, rIndex) => (
                <PrivateRoute key={`${r.path.replaceAll("/", "")}-${rIndex}`} component={r.component} path={r.path} appUser={appUser} isAuthenticated={isAuthenticated} />
              ))} 
            </ThemeProvider>
            </GaWrap>
          </DeviceLayout>
        </Route>
      ))}

      {/* Default Route */}
      {
        (!isAuthenticated) ? (
          <GaWrap>
          <Switch>
            <Route path="/reset-password/:path?">
              <Redirect to={{ pathname: AppRoutes.RESET_PASSWORD, search: search} } />
            </Route>
            <Route path="/create-password/:path?">
              <Redirect to={{ pathname: AppRoutes.CREATE_PASSWORD, search: search} } />
            </Route>
            <Route path="/login">
              <Redirect to={AppRoutes.LOGIN} />
            </Route>
            <Route path="/forgot-password">
              <Redirect to={AppRoutes.FORGOT_PASSWORD} />
            </Route>
            <Route path="/">
              <Redirect to={AppRoutes.VALIDATE_EMAIL} />
            </Route>
          </Switch>
          </GaWrap>
        ) : (
          pathName === "/" ? (
            <Redirect to={loggedInRedirectRoute(appUser)} />
          ) : (
            <></>
          )
        )
      }
    </Router>
  );
};