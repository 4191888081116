// Base Imports
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { fetchUserPermissions } from "../../../../redux/actions/user/userAction";
import { useDispatch } from "react-redux";


// Other Imports
import { loggedInRedirectRoute } from "../../../../utility/appUtil";

export const PublicRoute = (props: any) => {
  const { isAuthenticated, appUser } = props;
  const dispatch = useDispatch();
  if (isAuthenticated && appUser) {
    appUser.locations && appUser.locations[0] && appUser.locations[0].role
    && dispatch(fetchUserPermissions(appUser.locations[0].role.id))
  }
  return  isAuthenticated ? (
    <Redirect to={loggedInRedirectRoute(appUser)} />
  ) : (
    <>
    {window.location.replace(`${process.env.REACT_APP_SELF_URL}/api/v1/user/login`)}
    </>
  );
};

