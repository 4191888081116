import { createSlice } from '@reduxjs/toolkit'
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
} from '../../../utility/stateUtil'
import { ReportData, ReportValues, MyReport } from '../../../web/models/insights/reports'
import {
  deleteMyReport,
  fetchMyReport,
  fetchMyReportById,
  fetchReportDataByReportId,
  fetchReportsForUser,
  postMyScheduleReport,
  saveMyReport,
  renameMyReport,
  updateMyReport,
  updateMyScheduleReport,
  deleteSchedule,
} from '../../actions/insights/insightsAction'
import { InsightsState } from '../../states/insights/insightsState'
import { RootState } from '../../store/rootReducer'

const initialState: InsightsState = {
  reports: [],
  myReports: [],
  ScheduleReportData:[],
  reportsCount: 0,
  myReportsCount:0,
  reportData: {} as ReportData,
  myReportData: {} as MyReport,
  formState: resetState(),
  reportDataFormState: resetState(),
  prevReport: false,
  initialMyReport:false,
  showEventLog: false,
  eventDataValues: null,
  viewDataValues: {} as MyReport,
  reportValues: {} as ReportValues,
  updateReportFormstate :resetState(),
  saveReportFormstate :resetState(),
  deleteReportFormstate :resetState(),
  deleteScheduleFormState:resetState(),
  myReportformState:resetState(),
  viewUpdateFormstate:resetState(),
  myReportDataFormState:resetState(),
  scheduleFormState: resetState(),
  UpdateScheduleFormState:resetState()
}

export const InsightsSlice = createSlice({
  name: 'insights',
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState()
      return state
    },
    clearReports: (state) => {
      state.reports = [];
      state.reportsCount= 0;
      state.reportData= {} as ReportData;
      return state
    },
    clearMyReports: (state) => {
      state.myReports = [];
      state.myReportsCount= 0;
      return state
    },
    clearReportDataFormSate: (state) => {
      state.reportDataFormState = resetState()
      return state
    },
    clearMyReportDataFormSate: (state) => {
      state.myReportDataFormState = resetState()
      return state
    },
    clearUpdateReportFormstate: (state) => {
      state.updateReportFormstate = resetState()
      return state
    },
    clearSaveReportFormstate: (state) => {
      state.saveReportFormstate = resetState()
      return state
    },
    
    clearDeleteReportFormstate: (state) => {
      state.deleteReportFormstate = resetState()
      return state
    },
    clearviewUpdateFormstate: (state) => {
      state.viewUpdateFormstate = resetState()
      return state
    },
    clearScheduleUpdateFormstate: (state) => {
      state.UpdateScheduleFormState = resetState()
      return state
    },
    clearScheduleFormState: (state) => {
      state.scheduleFormState = resetState()
      return state
    },
    clearDeleteScheduleFormState: (state) => {
      state.deleteScheduleFormState = resetState()
      state.viewDataValues = {} as MyReport
      return state
    },
    clearViewDataValues: (state) => {
      state.viewDataValues = {} as MyReport
      return state
    },
    setEventDataValues: (state, {payload}) => {
      state.eventDataValues = payload
      return state
    },
    setShowEventLog: (state, {payload}) => {
      state.showEventLog = payload
      return state
    },
    storeValues: (state, {payload}) => {
      state.reportValues = {...state.reportValues, ...payload}
    },
    showPrevReport: (state ,{payload}) => {
      state.prevReport = payload
      return state
    },
    showInitialMyReport: (state ,{payload}) => {
      state.initialMyReport = payload
      return state
    },
    setViewDataValues: (state, {payload}) => {
      state.viewDataValues = payload
      return state
    },
  },
  extraReducers: {
    // Action - fetchReportsForUser
    [fetchReportsForUser.fulfilled.toString()]: (state, { payload }) => {
      state.reports = payload.reports
      state.reportsCount = payload.totalCount
      state.formState = fulfilledState()
      return state
    },
    [fetchReportsForUser.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message)
    },
    [fetchReportsForUser.pending.toString()]: (state) => {
      state.formState = pendingState()
    },

    // Action - fetchReportDataByReportId
    [fetchReportDataByReportId.fulfilled.toString()]: (state, { payload }) => {
      state.reportData = payload
      state.reportDataFormState = fulfilledState()
      return state
    },
    [fetchReportDataByReportId.rejected.toString()]: (state, { payload }) => {
      state.reportDataFormState = rejectedState(payload?.message)
    },
    [fetchReportDataByReportId.pending.toString()]: (state) => {
      state.reportDataFormState = pendingState()
    },

    [renameMyReport.fulfilled.toString()]: (state, { payload }) => {
      state.updateReportFormstate = fulfilledState();
      return state;
    },
    [renameMyReport.rejected.toString()]: (state, { payload }) => {
      state.updateReportFormstate = rejectedState(payload?.message);
      return state;
    },
    [renameMyReport.pending.toString()]: (state) => {
      state.updateReportFormstate = pendingState();
      return state;
    },
    [saveMyReport.fulfilled.toString()]: (state, { payload }) => {
      state.saveReportFormstate = fulfilledState();
      return state;
    },
    [saveMyReport.rejected.toString()]: (state, { payload }) => {
      state.saveReportFormstate = rejectedState(payload?.message);
      return state;
    },
    [saveMyReport.pending.toString()]: (state) => {
      state.saveReportFormstate = pendingState();
      return state;
    },
    
    [updateMyReport.fulfilled.toString()]: (state, { payload }) => {
      state.viewUpdateFormstate = fulfilledState();
      return state;
    },
    [updateMyReport.rejected.toString()]: (state, { payload }) => {
      state.viewUpdateFormstate = rejectedState(payload?.message);
      return state;
    },
    [updateMyReport.pending.toString()]: (state) => {
      state.viewUpdateFormstate = pendingState();
      return state;
    },
    [deleteMyReport.fulfilled.toString()]: (state, { payload }) => {
      state.deleteReportFormstate = fulfilledState();
      return state;
    },
    [deleteMyReport.rejected.toString()]: (state, { payload }) => {
      state.deleteReportFormstate = rejectedState(payload?.message);
      return state;
    },
    [deleteMyReport.pending.toString()]: (state) => {
      state.deleteReportFormstate = pendingState();
      return state;
    },

    [fetchMyReport.fulfilled.toString()]: (state, { payload }) => {
      state.myReports = payload.reports
      state.myReportsCount = payload.totalCount
      state.myReportformState = fulfilledState()
      return state
    },
    [fetchMyReport.rejected.toString()]: (state, { payload }) => {
      state.myReportformState = rejectedState(payload?.message)
    },
    [fetchMyReport.pending.toString()]: (state) => {
      state.myReportformState = pendingState()
    },

    [postMyScheduleReport.fulfilled.toString()] : (state, { payload }) => {
      state.ScheduleReportData = payload
      state.scheduleFormState = fulfilledState()
      return state
    },

    [postMyScheduleReport.rejected.toString()]: (state, { payload }) => {
      state.scheduleFormState = rejectedState(payload?.message)
    },
    [postMyScheduleReport.pending.toString()]: (state) => {
      state.scheduleFormState = pendingState()
    },
    [updateMyScheduleReport.fulfilled.toString()] : (state, { payload }) => {
      state.UpdateScheduleFormState = fulfilledState()
      return state
    },

    [updateMyScheduleReport.rejected.toString()]: (state, { payload }) => {
      state.UpdateScheduleFormState = rejectedState(payload?.message)
    },
    [updateMyScheduleReport.pending.toString()]: (state) => {
      state.UpdateScheduleFormState = pendingState()
    },
    [deleteSchedule.fulfilled.toString()]: (state, { payload }) => {
      state.deleteScheduleFormState = fulfilledState();
      return state;
    },
    [deleteSchedule.fulfilled.toString()]: (state, { payload }) => {
      state.deleteScheduleFormState = fulfilledState();
      return state;
    },
    [deleteSchedule.fulfilled.toString()]: (state, { payload }) => {
      state.deleteScheduleFormState = fulfilledState();
      return state;
    },
    [fetchMyReportById.fulfilled.toString()]: (state, { payload }) => {
      // state.runNowStatus = payload
      state.myReportDataFormState = fulfilledState()
      return state
    },
    [fetchMyReportById.rejected.toString()]: (state, { payload }) => {
      state.myReportDataFormState = rejectedState(payload?.message)
    },
    [fetchMyReportById.pending.toString()]: (state) => {
      state.myReportDataFormState = pendingState()
    },
  },
})

export const { clearState, clearReportDataFormSate, clearReports, setEventDataValues, setShowEventLog, storeValues, showPrevReport,setViewDataValues,clearSaveReportFormstate,clearDeleteReportFormstate,clearUpdateReportFormstate,clearviewUpdateFormstate,clearMyReports ,clearScheduleFormState,clearDeleteScheduleFormState,clearScheduleUpdateFormstate,showInitialMyReport,clearMyReportDataFormSate,clearViewDataValues} = InsightsSlice.actions
export const insightsSelector = (state: RootState) => state.insights
export default InsightsSlice.reducer
