import { Form } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useTranslation } from 'react-i18next'
import './customTextArea.style.less'

const CustomTextArea = (props: any) => {
    const { t } = useTranslation()
    return (
        <Form.Item
            label={t(props.labelSubName)}
            initialValue={props.initialValue}
            name={props.labelName}
            rules={props.rules}
            className={`mb-0 ${props.customLabelClass}`}
            validateStatus={props.validationStatus}
        >
            <TextArea
                placeholder={t(props.placeholder)}
                name={props.name}
                className="customTextArea scrollContent scrollStyle"
                value={props.value}
                onChange={props.onChange}
                disabled={props?.disabled || false}
                tabIndex={props?.tabIndex}
                aria-label={props?.ariaLabel}
                role={props?.role}
                autoComplete={props?.autoComplete}
            />
        </Form.Item>
    )
}


export default CustomTextArea