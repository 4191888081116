/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import AuthLayout from "../../../layouts/authLayout";
import { LoginContainer } from "../authenticatePwdForm/authenticatePwdForm.Styles";
import "../newUserFlow/newUserFlow.less";
import { UserAuthInput } from "../../../../../models/user/userAuthInput";
import { parseQuery } from "../../../../../../utility/urlUtil";
import { AppError } from "../../../../stateless/common/appError";
import CustomPassword from "../../../common/inputField/customPassword";
import Button from "../../../common/button";
import "./createPwd.less" ;
import { userStateSelector } from "../../../../../../redux/reducers/user/userReducer";

export const CreatePwdForm = (props: any) => {
  const { form, formLayout, location, createPwdCallback, errorMsg , getConfirmPasswordVlaue } = props;
  const { formState } = useSelector(userStateSelector);
  const dispatch = useDispatch();
  const [confirmPassword, setConfirmPassword] = useState<any>(null);
  const [token, setToken] = useState<any>();

  // useEffect(() => {
  //   if (createPwdCallback) {
  //     const queryParams = parseQuery(location.search);
  //     const findToken = queryParams && queryParams.length ? queryParams.find(x => x.name === "token") : null;
  //     setToken(findToken ? findToken.value : "")
  //   }
  // }, [createPwdCallback]);

  // const userAuth: UserAuthInput = {
  //   id: 0,
  //   email: "",
  //   password:confirmPassword,
  //   confirmPwd:confirmPassword,
  //   token:token
  // }

  // const onSubmit = (data: any) => {
  //     props.createPasswordCallback(userAuth);
  // }

  const onSubmit = (data: any) => {
    if (createPwdCallback) {
      const queryParams = parseQuery(location.search);
      const findToken =
        queryParams && queryParams.length
          ? queryParams.find((x) => x.name === "token")
          : null;
      const userAuth: UserAuthInput = {
        id: '',
        email: "",
        password: confirmPassword,
        confirmPwd: confirmPassword,
        token: findToken ? findToken.value : "",
      };
      createPwdCallback(userAuth);
      // if(userAuth.token !== "" || null){
      //   createPwdCallback(userAuth);
      // }
    }
  };
  return (
    <AuthLayout>
      <LoginContainer className="resetPassContainer createPasswordContainer">
      <span className="crtPassStep">Step 1</span>
        <span className="stepDashLine">
          <div className="dashFill"></div>
          <div className="dashEmpty marLeft"></div>
          <div className="dashEmpty"></div>
        </span>
        <h1 className="resetHeading">Create Password</h1>
        <p className="createPassDes">
          Please create a new
          password that you will use to log in.
        </p>
        <AppError show={errorMsg ? true: false} errorMsg={errorMsg} />
        <Form
          ref={form}
          {...formLayout}
          name="create-pwd"
          className="forgot-pwd-form mt-35"
          initialValues={{
            remember: true,
          }}
          onFinish={onSubmit}
          requiredMark={false}
        >
          <CustomPassword
            label="Password"
            name="newPassword"
            customLabelClass="crtPassLabel"
            placeholder="Password"
            rules={[
              {
                required: true,
                message: "Please input your Password!", // TODO: Handle this while developing validation framework.
              },
              {
                pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`])[A-Za-z\d^$*.[\]{}()?"!@#%&/\\,><':;|_~`]{8,}$/),
                message: "Password must have atleast 8 characters, an uppercase letter, a lowercase letter, a number and a special character"
              },
              {
                max: 98,
                message: "Maximum length reached"
              }
            ]}
          />
          <CustomPassword  label="Confirm Password"
            name="confirmPassword"
            customLabelClass="confirmPasswordLabel changePasswordLabel crtPassLabel"
            placeholder="Confirm New Password"
            rules={[
              {
                required: true,
                message: "Please input your New Password!", // TODO: Handle this while developing validation framework.
              },
              ({ getFieldValue }: { getFieldValue: any }) => ({
                validator(_: any, value: any) {
                  if (!value || getFieldValue("newPassword") === value) {
                    getConfirmPasswordVlaue(value)
                    return Promise.resolve(setConfirmPassword(value));
                  }
                  return Promise.reject(new Error("Passwords do not match")); // TODO: Handle this while developing validation framework.
                },
              }),
            ]}
             />
          <Form.Item className="CreatePassButton">
            <Button type='primary loginButton' htmlType='submit'>
              Create New Password
            </Button>
          </Form.Item>
        </Form>
      </LoginContainer>
    </AuthLayout>
  );
};
