export enum APIEnvironment {
  DEV = "DEV",
  QA = "QA",
  DEMO = "DEMO",
  PROD = "PROD",
}

export enum PortalModule {
  AUTH = "auth",
  USER = "user",
  ORGANIZATION = "organisation",
  ROLE = "role",
  EVENT ="event",
  AUDIT ="audit",
  ALERT = "alert",
  DEVICE = "device",
  WEB_SOCKET="socket",
  NOTIFICATIONS="notificaton",
  INSIGHTS="insights",
  UPLOADLOGO="uploadLogo",
  WORK_ITEMS="workItems"
}
