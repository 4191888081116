import React from "react";

// Styles
import {
  PrimaryButton,
  SecondaryButton,
  DeleteButton,
  ButtonSize,
} from "./button.styles";

export enum ButtonType {
  PRIMARY,
  SECONDARY,
  DESTRUCTIVE,
}

export type ButtonProps = {
  /**
   * Controls the button type. It can be either PRIMARY, SECONDARY or DELETE.
   */
  buttonType?: ButtonType;
  /**
   * Controls the size of the button. It can be either LARGE, MEDIUM or SMALL.
   */
  size?: ButtonSize;
} & React.ComponentPropsWithoutRef<"button">;

export const Button: React.FC<ButtonProps> = ({
  buttonType = ButtonType.PRIMARY,
  size = ButtonSize.LARGE,
  ...props
}: ButtonProps) => {
  switch (buttonType) {
    case ButtonType.PRIMARY:
      return <PrimaryButton size={size} {...props} />;
    case ButtonType.SECONDARY:
      return <SecondaryButton size={size} {...props} />;
    case ButtonType.DESTRUCTIVE:
      return <DeleteButton size={size} {...props} />;
    default:
      return null;
  }
};
