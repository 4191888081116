/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// pacakge imports
import React, { useEffect, useState } from 'react';
import { PageHeader, Row, Col, Input, Form, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

// import images

// module imports
import { clearRoleCreateFormState, clearErrorMsg, clearState, roleFormStateSelector, roleListSelector } from '../../../../../redux/reducers/role/roleListReducer';
import { fetchAllPermissions } from '../../../../../redux/actions/role/permissionAction';
import { Pages, PermissionTypes, RoleType, jsonData } from '../../../../constants/constants';
import { permissionSelector, clearPermissions } from '../../../../../redux/reducers/role/permissionReducer';
import { AddRole } from '../../../../models/role/addRole';
import { createRole, fetchRoleById, fetchRoleListByOrgId, fetchTransformedRoleDetail, fetchTransformedRoleList } from '../../../../../redux/actions/role/roleAction';
import { userStateSelector } from '../../../../../redux/reducers/user/userReducer';
import { RolesDetailsContent } from '../rolesDetails/rolesDetailsContent/rolesDetailsContent';
import { getPermissionIds, getSelfPermissions, getUniquePermissionForEditing, getUniquePermissions } from '../../../../../utility/roleUtils';
import { AppService } from "../../../../services/ui/appService";

// styles imports
import './createRole.less'
import { HeaderInputLabel, PageHeaderTitle } from '../rolesDetails/customRole/customRoleHeader.styles';
import { CreateRoleBtn } from './createRole.styles';
import {Dropdown} from '../../../../../bit_components/common/dropdown';
import {Custominput} from '../../../../../bit_components/common/custominput';
import { AppRoutes, UserRole } from '../../../../constants/enums';
import PermissionModal from '../permissionModal';
import { Breadcrumbs } from '../../../stateless/common/breadcrumbs';
import '../../../stateless/common/customScrollBar/customScrollStyle.less';
import { MainLayoutService } from '../../../../services/ui/mainLayoutService';
import { HeaderType } from '../../../../constants/enums';
import { cameFromOrganisation, urlModification } from '../../../../../utility/appUtil';
import { appSelector } from '../../../../../redux/reducers/app/appReducers';
import { onPage, sidebarNavigate } from '../../../../../redux/actions/app/appAction';
import { SideBarItems } from '../../../../constants/sideBarConstants';
import Loader from '../../../stateless/common/spinner';
import { AppError } from '../../../stateless/common/appError';
import { CompWrapper } from '../../../stateless/common/compWrapper/compWrapper';
import { Redirect } from "react-router-dom"
import { useTranslation } from 'react-i18next';

export const CreateRole = (props: any) => {

    const { TextArea } = Input;
    const dispatch = useDispatch();
    const history = useHistory();
    const [modifiedPermission, setModifiedPermission] = useState<any[]>([]);
    const [rolePermission, setRolePermission] = useState<any[]>([]);
    const { orgRoleList = [], selectedRole, roleCreated,formState, roleCreateFormState, errorMessage } = useSelector(roleListSelector);
    const roleListFormState = useSelector(roleFormStateSelector);
    const { permissions = [] } = useSelector(permissionSelector);
    const { appUser } = useSelector(userStateSelector);
    const { selectedOrganisation } = useSelector(appSelector);
    const [formDetails, setFormDetails] = useState({
        name: "",
        description: "",
    })
    const [checkedPermission, setCheckedPermission] = useState<any[]>([]);
    const [roleSelected, setRoleSelected] = useState('');
    const [createBtnClick, setCreateBtnClick] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const { t } = useTranslation()
    var allRoles: any[] = [{
        text: t(jsonData.FromScratch),
        value: '',
    }];
    const routes = [{
        href: urlModification(AppRoutes.ROLES_LIST),
        text: 'Roles',
    }
    
    ]
    const [breadCrumbClick, setBredCrumClick] = useState<Boolean>(false)

    useEffect(()=>{
        MainLayoutService.renderHeader(HeaderType.JUNTO_PORTAL);
        dispatch(onPage({onComponent : Pages.SETTINGS}));
        dispatch(sidebarNavigate(SideBarItems.ROLES))
        return () => {
            dispatch(clearRoleCreateFormState());
            dispatch(clearErrorMsg());
            dispatch(clearPermissions());
          };
      }, [])

    useEffect(() => {
        if (roleCreated && roleCreateFormState.isSuccess && createBtnClick) {
                history.push(urlModification(AppRoutes.ROLES_LIST));
                AppService.showToast(t(jsonData.RoleCreatedSuccessfully));
                dispatch(clearRoleCreateFormState());
        }
    }, [roleCreated, roleCreateFormState.isSuccess, createBtnClick]);

    // clear state on change of state of formstate
    useEffect(() => {
        if (formState && formState.isError) {
            dispatch(clearState());
        }
    }, [formState.isError]);

    // fetch all Permissions
    useEffect(() => {
        dispatch(fetchAllPermissions());
        dispatch(fetchTransformedRoleList(getOrgId()));
    }, [selectedOrganisation]);

    useEffect(() => {
        // get unique permissions
        if (modifiedPermission?.length <= 0) {
            permissions && getUniquePermissions(permissions).then((data) => {
                setModifiedPermission(data);
            }).catch(() => {
                console.log("Failed to get unique permissions");
            })
        }
    },[permissions])
    
    // set modified permission to role permission
    useEffect(() => {
        if (roleSelected !=='') {
            selectedRole?.permissions && getUniquePermissionForEditing(permissions, selectedRole?.permissions).then((data) => {
                setRolePermission(data);
            }).catch(() => {
                console.log("Failed to get unique permissions for selected role");
            })

            // get ids for permissions
            selectedRole?.permissions && getPermissionIds(selectedRole?.permissions,permissions).then((data) => {
                setCheckedPermission(data);
              }).catch(() => {
                  console.log("Failed to get id's for permissions of selected role");
              })
        }
    }, [roleListFormState.isSuccess, roleSelected, selectedRole]);

    const getOrgId = () => {
        let orgId = appUser?.organization.id as string;
          if(cameFromOrganisation()) {
            orgId = selectedOrganisation?.id;
          }      
          return orgId;
      }

    const [orgId, setOrgId] =  useState(getOrgId());

    useEffect(()=>{
        setOrgId(getOrgId());
    }, [selectedOrganisation])

    // handle input text
    function handleChange(e: any) {
        var value = e.target.value;
        var name = e.target.name;
        setFormDetails({ ...formDetails, [name]: value });
    }

    // get ids for permissions
    const getPermissionByNameNType = (type: string, permissionName: string) => {
        return permissions && permissions.filter((element:any) => {
            if (type === 'view') {
                return element.permission === permissionName && (element.permissionType === PermissionTypes.INDEX || element.permissionType === PermissionTypes.VIEW);
            } else {
                return element.permission === permissionName && (element.permissionType === PermissionTypes.CREATE || element.permissionType === PermissionTypes.EDIT || element.permissionType === PermissionTypes.DELETE);
            }
        })
    }

    // removes ids from checked permission array
    const removePermission = (idsToRemove: number[]) => {
        let temp = [...checkedPermission];
        const afterRemovalPerm = temp.filter((id) => {
            return !idsToRemove.includes(id)
        })
        setCheckedPermission(afterRemovalPerm);
    }

    // handle check box clicks
    const handleCheckBoxOnChange = (e: any) => {
        const permId = e.target.id.split('@');
        const perm = getPermissionByNameNType(e.target.name, permId[0]);
        if (e.target.checked) {
            let tempArr: any = [...checkedPermission];
            perm.forEach((item:any) => {
                tempArr.push(item.permissionId);
            });
            setCheckedPermission(tempArr)
        } else {
            let idToRemove: number[] = [];
            perm.forEach((item:any) => {
                idToRemove.push(item.permissionId);
            });
            removePermission(idToRemove);
        }
    }
    //
    if (orgRoleList && orgRoleList.length) {
        allRoles = allRoles.concat(orgRoleList.map((role: any) => {
            return { text: role.name, value: role.id }
        }))
    }
    // handle Role select
    const handleRoleSelect = (value: any) => {
        setCheckedPermission([]);
        setRoleSelected(value);
        if (value !== '') {
            dispatch(fetchTransformedRoleDetail(value));
        }
    }

    // handle on create role button click
    const handleOnCreate = (e: any) => {
        if(checkedPermission.length > 0){
            let checkedPermissionCopy = [...checkedPermission];
            getSelfPermissions(permissions).then((selfPerm:any)=>{
                checkedPermissionCopy = checkedPermission.concat(selfPerm);
                const addRole: AddRole = {
                    role: formDetails.name,
                    description: formDetails.description,
                    type: RoleType.CUSTOM,
                    organizationId: orgId,
                    permissions: roleSelected !=='' ? checkedPermission : checkedPermissionCopy,
                    t:t
                }
                setCreateBtnClick(true);
                dispatch(createRole(addRole));
            }).catch(()=>{
                console.log('Failed to set self permissions')
            })
    }
    else{
        setShowModal(true)
    }
    }


    const getPermission = () => {
        if (roleSelected !==''){
            return rolePermission;}
        else{
        return modifiedPermission;
        };
    }

    const breadcrumbClick  = () => {
        setBredCrumClick(true)
      }

    return (
        <>
            {(appUser.locations[0].role.name === UserRole.BIBLIOTHECA_USER || appUser.locations[0].role.name === UserRole.LIBRARY_FRONT_DESK)  ?

            <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} />
                :
            <Loader loading={formState?.loading}>
                <Form layout="vertical" name="create-role" requiredMark={false} onFinish={handleOnCreate} scrollToFirstError={true}>
                    <div className="pl-vw createRoleHeader">
                        <PageHeader
                            title={<PageHeaderTitle>{t(jsonData.CreateACustomRole)}</PageHeaderTitle>}
                            breadcrumb={<div onKeyPress={breadcrumbClick}><Breadcrumbs breadcrumbs={routes} breadCrumbClick={breadCrumbClick} setBredCrumClick={setBredCrumClick}></Breadcrumbs></div>}
                            className='page-header-create'
                        />
                    </div>
                    <CompWrapper observeOn="createRoleHeader" otherClasses="pl-vw">
                        <Row key='11'>
                            <Col md={12} lg={8} xl={7} key='12' className="pl-19 ml-4">
                                <HeaderInputLabel >
                                    {t(jsonData.RoleName)}
                                </HeaderInputLabel>
                                <Custominput tabIndex={0} aria-label={""} role={""} key='13' labelName="name" placeholder={t(jsonData.NewCustomRole)} name="name" value={formDetails.name} onChange={handleChange} rules={[{ required: true, message: t(jsonData.RoleNameRequired) }]} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={16} lg={16} xl={15} className="mt-30 pl-23">
                                <HeaderInputLabel >
                                    {t(jsonData.RoleDescriptionOptional)}
                        </HeaderInputLabel>
                                <TextArea tabIndex={0} aria-label={""} role={""} rows={4} className='headerTextArea' placeholder={t(jsonData.Description)} name="description" value={formDetails.description} onChange={handleChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} lg={7} className="mt-30 pl-23">
                                <HeaderInputLabel >
                                    {t(jsonData.BasePermissions)}
                                </HeaderInputLabel>
                                <Dropdown tabIndex={0} aria-label={""} optionValue={allRoles} defaultValue={allRoles[0].text} onChange={handleRoleSelect} />
                            </Col>
                        </Row>
                        <div className="pt-50">
                        <RolesDetailsContent permissions={getPermission()} systemRole={true} handleOnChange={handleCheckBoxOnChange} />
                        </div>
                        <AppError show={!roleCreated && roleCreateFormState.isError ? true: false} errorMsg={errorMessage} />
                        <Row>
                            <Col sm={12} md={12} lg={8} xl={7} className="createRolBtn" onKeyPress={handleOnCreate}>
                            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.CreateRole)}>
                                <CreateRoleBtn  type="submit">{t(jsonData.CreateRole)}</CreateRoleBtn>
                            </Tooltip>
                            </Col>
                        </Row>
                    </CompWrapper>
                <PermissionModal tabIndex={0} aria-label={""} role={""} isModalVisible={showModal} setModalVisible={setShowModal}/>
                </Form>
            </Loader>
            }
        </>
    );
};
