import styled from 'styled-components';
import {Image} from 'antd';

export const ThumbNailImage = styled(Image)`
    height: 35px !important;
    width: 35px !important;
    object-fit: cover !important;
    vertical-align: middle !important;
    filter: drop-shadow(0px 76px 60px rgba(0, 0, 0, 0.09)), 
      drop-shadow(0px 31.751px 25.0666px rgba(0, 0, 0, 0.0646969)), 
      drop-shadow(0px 16.9756px 13.4018px rgba(0, 0, 0, 0.0536497)), 
      drop-shadow(0px 9.51638px 7.51293px rgba(0, 0, 0, 0.045)), 
      drop-shadow(0px 5.05408px 3.99006px rgba(0, 0, 0, 0.0363503)), 
      drop-shadow(0px 2.10311px 1.66035px rgba(0, 0, 0, 0.0253031)) !important;
    box-shadow: 0px 76px 60px rgba(0, 0, 0, 0.09),
      0px 31.751px 25.0666px rgba(0, 0, 0, 0.0646969),
      0px 16.9756px 13.4018px rgba(0, 0, 0, 0.0536497),
      0px 9.51638px 7.51293px rgba(0, 0, 0, 0.045),
      0px 5.05408px 3.99006px rgba(0, 0, 0, 0.0363503),
      0px 2.10311px 1.66035px rgba(0, 0, 0, 0.0253031) !important;
    border-radius: 50px !important; 
    cursor:pointer
`;