/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from "react";
import { Row, Col, Button, Form, Tooltip } from "antd";
import { Breadcrumbs } from "../../../../stateless/common/breadcrumbs";
import { concatNames } from "../../../../../../utility/appUtil";
import {
  UserBlockImg,
  DeleteIconImg,
  resendMailBred,
  UserInfoBlockImg,
  blockedUserImg,
  blockUserRed,
  resetPassRedIcon,

  resendMail,
  resendMailB,
  whiteMail,
} from "../../../../../images";
import "../userProfileForm/userProfileForm.less";
import { useSelector } from "react-redux";
import { userStateSelector } from "../../../../../../redux/reducers/user/userReducer";
import { UserRole } from "../../../../../constants/enums"
import { UserProfileName, ResendImg, BlockedImg } from "./index.styles";

export const UserProfileHeader = (props: any) => {
  const { appUser } = useSelector(userStateSelector);
  const {
    isDirty,
    formName,
    breadcrumbs,
    user,
    applyChangesBtnText,
    allSavedBtnText,
    blockUserCallback,
    resetPwdCallback,
    removeUserCallback,
    unBlockUserCallback,
    isEditAllowed,
    resendInviteCallback,
    ristrictUnBlockUserCallback
  } = props;
  const fullName = concatNames(
    user?.firstName,
    user?.lastName,
    user?.middleName,
    user?.title
  );

  const [breadCrumbClick, setBredCrumClick] = useState<Boolean>(false)
  const onBlockUserCallback = () => {
    if (blockUserCallback) {
      blockUserCallback(user);
    }
  };
  const onUnBlockUserCallback = () => {
    if (unBlockUserCallback) {
      unBlockUserCallback(user);
    }
  };

  const onRistrictUnBlockUserCallback=()=>{
    if(ristrictUnBlockUserCallback){
      ristrictUnBlockUserCallback(user)
    }
  }

  const onResetPwdCallback = () => {
    if (resetPwdCallback) {
      resetPwdCallback(user);
    }
  };

  const onRemoveUserCallback = () => {
    if (removeUserCallback) {
      removeUserCallback(user);
    }
  };

  const onResendInviteCallback = () => {
    if (resendInviteCallback) {
      resendInviteCallback(user);
    }
  };


  const breadcrumbClick  = () => {
    setBredCrumClick(true)
  }

  return (
    <div className="pl-vw userProfileHeaderDiv">
      <Col onKeyPress={breadcrumbClick}>
      <Breadcrumbs aria-label={""} role={""} breadcrumbs={breadcrumbs} breadCrumbClick={breadCrumbClick} setBredCrumClick={setBredCrumClick} ></Breadcrumbs>
      </Col>
      <Row className="profileHed">
        <Col lg={12} className="profileHedName">
          <UserProfileName>
            {fullName}
            {user?.isActive ? null : (user?.emailVerifiedAt === "" || null) &&
              user?.isActive === false ? (
              <ResendImg>
                <img alt="" className="mr-8" src={whiteMail} />
                Invited
              </ResendImg>
            ) : (
              <BlockedImg>
                <img alt="" className="mr-8" src={blockedUserImg} />
                Blocked
              </BlockedImg>
            )}
          </UserProfileName>
        </Col>
        <Col span={24} lg={12}>
          <Row className="userProfileIcons">
            {/* {user?.id !== appUser?.id && appUser?.locations[0]?.role?.name === UserRole.BIBLIOTHECA_USER && (user?.locations[0]?.role?.name === UserRole.PLATFORM_ADMIN || user?.locations[0]?.role?.name === UserRole.BIBLIOTHECA_USER) ? null :
              <div>
                {user?.id !== appUser?.id && user?.emailVerifiedAt !== "" && user?.isActive ?
                  <Tooltip
                    placement="bottomRight"
                    title={"Block User"}
                  >
                    <span
                      className="userProfileBtn"
                      onClick={onBlockUserCallback}
                    >
                      <img
                        src={UserBlockImg}
                        onMouseOver={(e) => (e.currentTarget.src = blockUserRed)}
                        onMouseOut={(e) => (e.currentTarget.src = UserBlockImg)}
                        alt=""
                      />
                    </span>
                  </Tooltip>
                  : <></>
                }
                {user?.id !== appUser?.id && user?.emailVerifiedAt !== "" && user?.isActive === false ?
                  <Tooltip
                    placement="bottomRight"
                    title={"Unblock User"}
                  >
                    <span
                      className="userProfileBtn"
                      onClick={user?.locations?.length !== 0 ? onUnBlockUserCallback : onRistrictUnBlockUserCallback}
                    >
                      <img
                        src={UserBlockImg}
                        onMouseOver={(e) => (e.currentTarget.src = blockUserRed)}
                        onMouseOut={(e) => (e.currentTarget.src = UserBlockImg)}
                        alt=""
                      />
                    </span>
                  </Tooltip>
                  : <></>
                }
                {user?.emailVerifiedAt === "" && user?.id !== appUser?.id ?
                  <Tooltip placement="bottomRight" title="Resend Invite">
                    <span className="userProfileBtn" onClick={onResendInviteCallback}>
                      <img
                        src={resendMailB}
                        onMouseOver={(e) => (e.currentTarget.src = resendMailBred)}
                        onMouseOut={(e) => (e.currentTarget.src = resendMailB)}
                        alt=""
                      />
                    </span>
                  </Tooltip>
                  :
                  <></>
                }
                {isEditAllowed &&
                  (user?.emailVerifiedAt !== "" || null) &&
                  user?.isActive ? (
                  <Tooltip placement="bottomRight" title="Reset Password">
                    <span className="userProfileBtn" onClick={onResetPwdCallback}>
                      <img
                        src={UserInfoBlockImg}
                        onMouseOver={(e) => (e.currentTarget.src = resetPassRedIcon)}
                        onMouseOut={(e) => (e.currentTarget.src = UserInfoBlockImg)}
                        alt=""
                      />
                    </span>
                  </Tooltip>
                ) : null}
                {appUser && user?.id !== appUser?.id ? (
                  <Tooltip placement="bottomRight" title="Delete User">
                    <span className="userProfileBtn" onClick={onRemoveUserCallback}>
                      <img
                        src={DeleteIconImg}
                        onMouseOut={(e) => (e.currentTarget.src = DeleteIconImg)}
                        alt=""
                      />
                    </span>
                  </Tooltip>
                ) : null}
              </div>} */}
              
          <span className="userApplyChange" aria-label={""} role={""}>
            {isDirty ? (
              <Form.Item className="mb-0">
                <Button
                  type="primary"
                  form={formName}
                  key="submit"
                  htmlType="submit"
                  className="applyChangesBtn p-0"
                  aria-label={applyChangesBtnText}
                  role="button"
                  tabIndex={0}
                >
                    <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={applyChangesBtnText}>
                      {applyChangesBtnText}
                    </Tooltip>
                </Button>
              </Form.Item>
            ) : (
              <Button disabled className="userProfileSaveBtn p-0" aria-label={allSavedBtnText} role="button">
                {allSavedBtnText}
              </Button>
            )}
          </span>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
