import { createSlice } from "@reduxjs/toolkit";
import { HeaderType } from "../../../web/constants/enums";
import { Bibliotheca, AlertIconImg,
    SettingIconImg, warningIcon,} from "../../../web/images/index"
import { setHeaderType } from "../../actions/app/headerAction";

import { HeaderState } from "../../states/app/headerState";
import { RootState } from "../../store/rootReducer";

const initialState: HeaderState = {
    headerType: HeaderType.JUNTO_PORTAL,
    logo: Bibliotheca, //Bibliotheca or Junto
    isOrgLogoVisible: false,
    selectedOrgLogo: '',
    selectedOrgName: '',
    isClusterVisible: false,
    cluster: '', // for future use
    isAlertWarnVisible: true,
    isAlertWarnEnable: false,
    warningIcon: warningIcon,
    alertIcon: AlertIconImg,
    settingsIcon: SettingIconImg,
    isSettingsVisible: true,
    isSettingsEnable: true, 
};

export const HeaderSlice = createSlice({
    name: "header",
    initialState: initialState,
    reducers: {
      clearState: (state) => {
        state.headerType = HeaderType.JUNTO_PORTAL;
        state.logo = Bibliotheca; //Bibliotheca or Junto
        state.isOrgLogoVisible = false;
        state.selectedOrgLogo = '';
        state.selectedOrgName = '';
        state.isClusterVisible = false;
        state.cluster = ''; // for future use
        state.isAlertWarnVisible = true;
        state.isAlertWarnEnable = false;
        state.warningIcon = warningIcon;
        state.alertIcon = AlertIconImg;
        state.settingsIcon = SettingIconImg;
        state.isSettingsVisible = true;
        state.isSettingsEnable = true; 
        return state;
      },
    },
  
    extraReducers: {
      [setHeaderType.fulfilled.toString()]: (state, { payload }) => {
        //debugger;
        state.isSettingsVisible = payload.isSettingsVisible;
        state.headerType = payload.headerType;
        state.isAlertWarnVisible = payload.isAlertWarnVisible;
        state.logo = payload.logo;
        state.alertIcon = payload.alertIcon;
        state.warningIcon = payload.warningIcon;
        return state;
      },         
    },
  });
  
  export const { clearState } = HeaderSlice.actions;
  export const headerSelector = (state: RootState) => state.header;
  export default HeaderSlice.reducer;