import { createAsyncThunk } from "@reduxjs/toolkit";
import OrganisationService from "../../../web/services/organisation/organisationService";
import { AppService } from "../../../web/services/ui/appService";

const organisationService = new OrganisationService();

export const fetchOrganisationbyID = createAsyncThunk(
  "organisationDetails/fetchOrganisation",
  async (orgId: string, thunkAPI) => {
    try {
      const data = await organisationService.fetchOrganisationbyID(orgId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// organisation summary transformation
export const fetchOrganisationSummary = createAsyncThunk(
  "organisationDetails/fetchOrganisationSummary",
  async (orgId: string, thunkAPI) => {
    try {
      const data = await organisationService.fetchOrganisationSummary(orgId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// account settings
export const fetchAccountSetting = createAsyncThunk(
  "organisationDetails/fetchAccountSetting",
  async (orgId: string, thunkAPI) => {
    try {
      const data = await organisationService.fetchAccountSetting(orgId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// general setting -> fetch organisation for basic info
export const fetchGeneralSetting = createAsyncThunk(
  "organisationDetails/fetchGeneralSetting",
  async (orgId: string, thunkAPI) => {
    try {
      const data = await organisationService.fetchGeneralSetting(orgId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchResourcesCount = createAsyncThunk(
  "organisationDetails/fetchResourcesCount",
  async (orgId: string, thunkAPI) => {
    try {
      const data = await organisationService.fetchResourcesCount(orgId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const addOrganisation = createAsyncThunk(
  "organisationDetails/addOrganisation",
  async (payload: any, thunkAPI) => {
    try {
      const data = await organisationService.addOrganisation(payload);
      if (data) {
        return data
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateOrganisation = createAsyncThunk(
  "organisationDetails/updateOrganisation",
  async (payload: any, thunkAPI) => {
    try {
      const data = await organisationService.updateOrganisation(payload.id, payload);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchLocations = createAsyncThunk(
  "organisationDetails/fetchLocations",
  async (orgId: string, thunkAPI) => {
    try {
      const data = await organisationService.fetchLocationsCount(orgId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchUsers = createAsyncThunk(
  "organisationDetails/fetchUsers",
  async (orgId: string, thunkAPI) => {
    try {
      const data = await organisationService.fetchUsers(orgId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchDevices = createAsyncThunk(
  "organisationDetails/fetchDevices",
  async (orgId: string, thunkAPI) => {
    try {
      const data = await organisationService.fetchDevices(orgId);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const toggleStatus = createAsyncThunk(
  "organisationDetails/toggleStatus",
  async (_args: any, thunkAPI) => {
    try {
      const data = await organisationService.toggleStatus(_args);
      if (data) { 
        AppService.showToast(`${_args.name} has been ${data.isActive?'activated':'deactivated'} successfully`);
        return data;
      }
      return thunkAPI.rejectWithValue(data);
    }
    catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const deleteOrganisation = createAsyncThunk(
  "organisationDetails/deleteOrganisation",
  async (id: string, thunkAPI) => {
    try {
      const data = await organisationService.deleteOrganisation(id);
      if (data)
        return data;

      return thunkAPI.rejectWithValue(data);
    }
    catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);


