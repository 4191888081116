/* eslint-disable @typescript-eslint/no-unused-vars */
import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { convert_DateWithTimezone_To_UTC } from "../../../utility/appUtil";
import { DeviceUrl, WorkItemsUrl } from "../../constants/apiConstants";
import qs from 'qs'

export default class WorkService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.WORK_ITEMS));
  }

  // fetch alerts list for org
  async fetchWorkItemsList(params: any = {}): Promise<any> {
    let endpoint = `${WorkItemsUrl.Work}`;
    let queryParams: any = {}
    queryParams.pageSize = params?.limit
    queryParams.page = params?.page
    if (params?.organisationId && params?.organisationId !== '') {
      queryParams.organisationId = params.organisationId
    }
    if (params?.startDate) {
      queryParams.startDate = convert_DateWithTimezone_To_UTC(params?.startDate, {time: 'start'})
    }
    if (params.endDate) {
      queryParams.endDate = convert_DateWithTimezone_To_UTC(params?.endDate, {time: 'end'})
    }
    if (params?.hierarchyIdList?.length) {
      queryParams.locations = params.hierarchyIdList
    }
    if (params?.deviceIdList?.length) {
      queryParams.deviceId = params.deviceIdList
    }
    if (params?.status?.length) {
      queryParams.status = params.status
    }
    if (params?.workItemType?.length) {
      queryParams.workItemTypeId = params.workItemType
    }
    if (params?.userName?.length) {
      queryParams.userName = params.userName
    }
    const response: any = await this.get(endpoint, {
      params: queryParams,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    });
    if (response && response.data && response.data.result) {
      return response.data.result.message;
    }
    return { totalWorkCount: 0, data: [] };
  }

  async storeWorkItem(parameters: any): Promise<any> {

    let endpoint = `${WorkItemsUrl.Work}`;
    const response: any = await this.post(endpoint, parameters)
    if(response.status === 200) {
      return response.data;
    }
    return null;
  }

  async retryWorkItem(parameters: {lcDeviceId: string, deviceWorkId: string}): Promise<any> {

    const {lcDeviceId, deviceWorkId} = parameters;
    let endpoint = `${process.env.REACT_APP_DEVICE_CONFIG_URL}/${lcDeviceId}/work/${deviceWorkId}/retry`;
    const response: any = await this.put(endpoint)
    if(response.status === 200) {
      return response.data;
    }
    return null;
  }

  async deleteWorkItem(parameters: {lcDeviceId: string, deviceWorkId: string}): Promise<any> {

    const {lcDeviceId, deviceWorkId} = parameters;
    let endpoint = `${process.env.REACT_APP_DEVICE_CONFIG_URL}/${lcDeviceId}/work/${deviceWorkId}/delete`;
    const response: any = await this.post(endpoint)
    if(response.status === 200) {
      return response.data;
    }
    return null;
  }

  async changeWorkItemStatus(parameters: {deviceId: string, workId: string}): Promise<any> {

    let endpoint = `${WorkItemsUrl.Work}`;
    const response: any = await this.put(endpoint, parameters);
    if(response.status === 200) {
      return response.data;
    }
    return null;
  }

  async fetchUserNameList(id: string): Promise<any>{

    let params = {organisationId: id};
    let endpoint = `${WorkItemsUrl.Work}/users`;
    const response: any = await this.get(endpoint, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      }
    })
    if(response.status === 200) {
      return response.data.result.message;
    }
    return null;
  }

  async fetchWorkItemNameList(id: string): Promise<any>{

    let params = {organisationId: id};
    let endpoint = `${WorkItemsUrl.Work}/types`;
    const response: any = await this.get(endpoint, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      }
    })
    if(response.status === 200) {
      return response.data.result.message;
    }
    return null;
  }
}
