import React from "react";
import './appError.style.less'

export const AppError = (props: any) => {

  const { show, errorMsg } = props;
  return show ? (
    <span className="errorMsg">{errorMsg}</span>
  ) : (
    <></>
  );
}