// pacakge imports
import React, { useEffect, useState } from 'react';
import { Col, Row, Checkbox as Checkedbox } from 'antd';

// module imports

// styles imports
import { CheckBoxWrapper, ItemContent, ItemTitle, ItemWrapper } from './rolesDetailsContentItem.styles';
import {Checkbox} from "../../../../../../../bit_components/common/checkbox"
import "../../../rolesCheckbox/checkboxStyles.less";
import { jsonData } from '../../../../../../constants/constants';
import { useTranslation } from 'react-i18next';

interface RolesDetailsContentItemProps {
    permission: any;
    systemRole: boolean;
    handleOnChange?: Function;
}

export const RolesDetailsContentItem = (props: RolesDetailsContentItemProps) => {
    const { t } = useTranslation()
    const {permission, systemRole, handleOnChange } = props;
    const [checkViewValue, setCheckViewValue] = useState(permission?.isView?permission?.isView:false)
    const [checkEditValue, setCheckEditValue] = useState(permission?.isEdit?permission?.isEdit:false)

    useEffect(()=>{
        setCheckViewValue(permission?.isView?permission?.isView:false);
        setCheckEditValue(permission?.isEdit?permission?.isEdit:false);
    }, [permission])

    const onChange = (e:any) => {
        if(e.target.name === 'view') {
            setCheckViewValue(!checkViewValue)
        } else {
            setCheckEditValue(!checkEditValue);
        }
        handleOnChange && handleOnChange(e);
    }

    const HandleKeyDown = (e:any) => {
        e.target.checked = !e.target.checked;
        e.preventDefault();
        if(e.target.name === 'view') {
            setCheckViewValue(!checkViewValue)
        } else {
            setCheckEditValue(!checkEditValue);
        }
        handleOnChange && handleOnChange(e);
    }

    return (
        <ItemWrapper key={permission?.id}>
            <Row key={permission?.id}>
                <Col span={16} key={permission?.id}>
                    <ItemTitle key={permission?.id}>
                        {permission?.permission}
                    </ItemTitle>
                    <ItemContent key={permission?.id}>
                        {permission?.permissionDescription}
                    </ItemContent>
                </Col >
                <Col span={4} key={permission?.id} className='roleCheckBoxWrapper'>
                    {/* <CheckBoxWrapper key={permission?.id} className= {!systemRole ? "roleCheckBox":""}>
                        <Checkbox className="w-14 h-14" checked={checkViewValue} name='view' id={permission?.permission + '@view'} key={permission?.id} disabled={!systemRole} onChange={(e:any)=>{onChange(e)}}/>
                    </CheckBoxWrapper> */}

                    <Checkedbox tabIndex={0} onKeyPress={(e: any) => { HandleKeyDown(e) }} aria-label={`${permission?.permission} ${t(jsonData.view)}`} indeterminate={checkViewValue} className='roleCheckbox' checked={checkViewValue} name='view' id={permission?.permission + '@view'} key={permission?.id} disabled={!systemRole} onChange={(e: any) => { onChange(e) }}>
                    </Checkedbox>
                </Col>
                <Col span={4} key={permission?.id} className='roleCheckBoxWrapper' >
                    {/* <CheckBoxWrapper key={permission?.id} className={!systemRole ? "roleCheckBox":""}>
                    <Checkbox className="w-14 h-14" checked={checkEditValue} name='edit' id={permission?.permission + '@edit'} key={permission?.id} disabled={!systemRole} onChange={(e:any)=> onChange(e)}/>
                    </CheckBoxWrapper> */}

                    <Checkedbox tabIndex={0} aria-label={`${permission?.permission} ${t(jsonData.Edit)}`} indeterminate={checkEditValue}  onKeyPress={(e: any) => { HandleKeyDown(e) }} className="roleCheckbox" checked={checkEditValue} name='edit' id={permission?.permission + '@edit'} key={permission?.id} disabled={!systemRole} onChange={(e: any) => onChange(e)}>
                    </Checkedbox>
                </Col>
            </Row>
        </ItemWrapper>
    );
};
