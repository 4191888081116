import React from 'react'
import { Layout, Row, Col } from 'antd';
import { MainHrEventLayout, StyledLayout } from './mainLayout.styles';
import { EventLogOutputHeader } from '../../containers/eventLogs/eventOutputHeader';
const { Header } = Layout

const EventLayout = (props: any) => {
  const { children, logoutCallback } = props;
  const hrStyle={borderTop:'1px solid #CBCBCB', marginBottom:'24px', marginTop: '0px'}
  return (
    <StyledLayout >
      <Row >
        <Col span={24}>
          <Header >
            <EventLogOutputHeader {...props} logoutCallback={logoutCallback} />
          </Header>
          <MainHrEventLayout hrmainstyle={hrStyle}/>
        </Col>
      </Row>
        {children}
    </StyledLayout>
  )
}

export default EventLayout
