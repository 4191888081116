/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */

import moment from "moment-timezone";
import formatDistance from "date-fns/formatDistance";

// get active alerts
export const getActiveAlerts = async (alerts: any[]) => {
  const activeAlerts: any[] = alerts.filter((alert: any) => {
    return alert?.isCleared === false;
  });
  return activeAlerts;
};


//display time passed from now
export const customFormatDate = (date: any, elapsedFrom : any) => {
  let forDate = formatDistance(new Date(date), new Date(elapsedFrom));
  let arrayNumbers = forDate.split(" ");
  let firstNumber = arrayNumbers[0];
  let secondNumber = arrayNumbers[1].charAt(0);
  //if formatDistance return less than a minute

  let time = moment.utc(date);
  var duration = moment.duration(moment.utc(elapsedFrom).diff(time));
  //if formatDistance return about
  if (firstNumber === "about") {
    firstNumber = arrayNumbers[1];
    if(arrayNumbers[2]?.includes('month')){
      secondNumber = 'M'
    }
    else if(arrayNumbers[2]?.includes('year')){
      secondNumber = 'Y'
    }
    else{
      secondNumber = arrayNumbers[2]?.charAt(0);
    }
    let result = firstNumber + secondNumber;
    return result;
    //if formatDistance return less than a minute
  } else if (forDate === "less than a minute") {
    var second = duration.asSeconds();
    var roundOfSecond = Math.round(second);
    var result = roundOfSecond + "s";
    return result;
    //if formatDistance return none of above
  } else {
    firstNumber = arrayNumbers[0];
    if(arrayNumbers[1]?.includes('month')){
      secondNumber = 'M'
    }
    else if(arrayNumbers[1]?.includes('year')){
      secondNumber = 'Y'
    }
    else{    
      secondNumber = arrayNumbers[1]?.charAt(0);
    }
    let result = firstNumber + secondNumber;
    return result;
  }
};
