/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Form, message, Modal, Slider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import AuthLayout from "../../../layouts/authLayout";
import { userUploadIcon, DeleteIconImg } from "../../../../../images";
import { LoginContainer } from "../authenticatePwdForm/authenticatePwdForm.Styles";
import "../newUserFlow/newUserFlow.less";
import "../profilePicUpForm/UploadProfile.less";
import AvatarEditor from "react-avatar-editor";
import { makeImgUrl } from "../../../../../../redux/actions/user/userAction";
import { AppError } from "../../../../stateless/common/appError";
import { Button } from "../../../../../../bit_components/common/button";
import { userStateSelector } from "../../../../../../redux/reducers/user/userReducer";
import { jsonData } from "../../../../../constants/constants";

export const ProfilePicUpForm = (props: any) => {
  const {
    form,
    formLayout,
    location,
    createPwdCallback,
    errorMsg,
    formSubmitCallback,
  } = props;
  const dispatch = useDispatch();
  const { formState,uploadLogoUrl } = useSelector(userStateSelector);
  const [profile, setProfile] = useState<any>("");
  const [inputValue, setInputValue] = useState<any>(1);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [upImg, setUpImg] = useState<any>();
  const [fileName, setFileName] = useState<string>();
  const [cropFileData, setCropFileData] = useState<any>(null);
  const [imgUrl, setImgUrl] = useState<string>();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const postCtreatePassowrdInput = {
    password: props.password,
    phone: props.phoneNumber,
    profilePic: profile,
  };

  const onSubmit = (data: any) => {
      formSubmitCallback(imgUrl);
  };

  const inputFile = useRef(null);

  const onClickbellicon = () => {
    setModalShow(false);
  };

  const handleChangeRangeVal = (value) => {
    setInputValue(value);
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleClose = (e: any) => {
    setProfile("");
    setUpImg("");
    setImgUrl('')
  };

  const uploadCropFile = () => {
    inputFile.current.click();
  };

  const onSelectFile = (e) => { 
     var fileName = e.target.files[0]?.name
     setFileName(fileName)
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      //@ts-ignore
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      const isJpgOrPng = e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg';
      if (!isJpgOrPng) {
        message.error(jsonData.UploadImageFormatMsg);
      }
      const isLt2M = e.target.files[0].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
      if (isJpgOrPng && isLt2M) {
        setModalShow(true);
      }
    }
  };

  const [editor, setEditor] = useState<any>(0);
  const setEditorRef = (editor) => {
    setEditor(editor);
  };

  //convert datUrl to img file
  const dataURLtoFile=(dataurl, filename)=> {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

  const handleSave = (e) => {
    if (editor) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      const baseToFile = dataURLtoFile(croppedImg,fileName)
      setProfile(croppedImg);
      setCropFileData(baseToFile)
      setModalShow(false);
      setProfile(croppedImg);
      const formData = new FormData();
      formData.append('logo', baseToFile);
      dispatch(makeImgUrl(formData))
    }
  };

  useEffect(()=>{
    setImgUrl(uploadLogoUrl)
  },[uploadLogoUrl])

  const uploadButton: any = (
    <div className="uploadbtnContent" onClick={uploadCropFile}>
      <div>
        <img src={userUploadIcon} className="imgSize" alt=""/>
      </div>
      <span className="ant-upload-text uploadText">+ Upload Photo</span>
      <span className="ant-upload-hint uploadHint">JPG, GIF or PNG</span>
      <span className="ant-upload-hint uploadHint">Min Res 500x500 px</span>
      <span className="ant-upload-hint uploadHint">{jsonData.MaxFileSize}</span>
    </div>
  );

  const uploadImage: any = (
    <div className="uploadedImag">
      <div className="image">
        <img src={profile} className="" alt=""/>
      </div>
      <div className="deletbutton">
        <img
          src={DeleteIconImg}
          onClick={handleClose}
          alt="delete"
          className="deleteImg"
        />
      </div>
    </div>
  );

  return (
    <AuthLayout>
      <LoginContainer className="createPassContainer picUpPaddStyl">
        <span className="crtPassStep">Step 3</span>
        <span className="stepDashLine">
          <div className="dashFill"></div>
          <div className="dashFill marLeft"></div>
          <div className="dashFill marLeft"></div>
        </span>
        <span className="crtPassHed">Profile Picture</span>
        <span className="crtPassSabText">Please add a profile picture.</span>
        <AppError show={errorMsg ? true : false} errorMsg={errorMsg} />
        <Form
          ref={form}
          {...formLayout}
          name="profile-pic-upload"
          className="forgot-pwd-form mt-22"
          initialValues={{
            remember: true,
          }}
          onFinish={onSubmit}
          requiredMark={false}
        >
          <div className="uploadFileBox">
            <div className="uploadPicture">
              <input
                ref={inputFile}
                className="uploadPictureInput"
                type="file"
                accept="image/*"
                onChange={onSelectFile}
              />
              {profile !== "" ? uploadImage : uploadButton}
            </div>
            <Modal
              wrapClassName="modalBoxCover"
              className="profilePicModal"
              visible={modalShow}
              closable={false}
              footer={null}
              onOk={() => onClickbellicon}
              onCancel={() => setModalShow(false)}
            >
              <div className="cropImage">
                <div className="gridLine1"></div>
                <div className="gridLine2"></div>
                <div className="gridLine3"></div>
                <div className="gridLine4"></div>
                <div className="circleInner"></div>
                <AvatarEditor
                  ref={setEditorRef}
                  image={upImg}
                  width={250}
                  height={250}
                  backgroundColor="#C4C4C4"
                  // color={[255, 255, 255, 0.6]}
                  borderRadius={150}
                  border={0}
                  scale={inputValue}
                />
              </div>
              <div className="zoomButtonCol">
                <div className="dragtext">Drag to Reposition</div>
                <div className="slider">
                  <span className="textZoom">zoom</span>
                  <span>
                    <Slider
                      min={1}
                      max={10}
                      step={0.1}
                      onChange={handleChangeRangeVal}
                      value={typeof inputValue === "number" ? inputValue : 0}
                    />
                  </span>
                </div>
                <div className="ConfirmButton">
                  <Button type="primary thirdFinishBtn" onClick={handleSave}>
                    Confirm
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
          <Form.Item className="mb-0">
            <Button type="primary thirdFinishBtn" htmlType="submit">
              Finish Setup
            </Button>
          </Form.Item>
        </Form>
      </LoginContainer>
    </AuthLayout>
  );
};
