/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef, useImperativeHandle} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import { useSelector } from 'react-redux'
import '../InsightHeader/insightHeader.less'
import { insightsSelector } from '../../../../../redux/reducers/insights/insightsReducer'
import { Menu } from 'antd'
import Loader from '../../../stateless/common/spinner'
import { CompWrapper } from '../../../stateless/common/compWrapper/compWrapper'
import { GroupBy, jsonData } from '../../../../constants/constants'
import LineGraph from './LineGraph';
import PieGraph from './PieGraph';
import { getHeight } from '../../../../../utility/appUtil'
import { DeviceSelector } from '../../../../../redux/reducers/device/deviceReducer'
import { newGraphData } from '../../../../../utility/insightUtils'
import pdfMake from 'pdfmake/build/pdfmake';
import html2canvas from 'html2canvas';
import { AppService } from '../../../../services/ui/appService'
import { useTranslation } from 'react-i18next'

const InsightGraph = forwardRef((props: any, ref: any) => {
  const { graphType,chartData, yMax, yScale, format, pages, groupByValue,startPage, endPage,onlyCloudCheck, selectedDevices, setInsightHasData,pieChartData,colorKeys, setDownloadingGraph } = props
  const { reportData, formState, reportDataFormState } = useSelector(
    insightsSelector,
  )
  const { deviceList, deviceListFilter } = useSelector(DeviceSelector)
  const [groupBy, setGroupBy] = useState<string>(GroupBy.HOUR)
  const [bottomMargin, setBottomMargin] = useState(0)
  const [newChrtdata, setNewChrtdata] = useState<any>(chartData)
  const location: any = useLocation()
  const { t } = useTranslation();
  useEffect( () => {
    const compContentHeight = getHeight('.ant-layout-content')
    const bottomGap = compContentHeight - (document.body.clientHeight * 0.8)
    setBottomMargin(parseInt(bottomGap.toFixed(0)))
    setNewChrtdata(chartData)
  },[])

  useEffect(() => {
    switch(reportData?.interval){
      case 'hour':
      case '3 hours': 
        setGroupBy(GroupBy.HOUR)
        break
      case 'day':
      case '3 days':
        setGroupBy(GroupBy.DAY)
        break
      case 'month':
        setGroupBy(GroupBy.MONTH)
        break
    }
  }, [reportData])

  const handleMenuClick = () => {}

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">{GroupBy.HOUR}</Menu.Item>
      <Menu.Item key="2">{GroupBy.DAY}</Menu.Item>
      <Menu.Item key="3">{GroupBy.MONTH}</Menu.Item>
    </Menu>
  )

  useEffect(() => {
    if (location?.pathname.includes("insights/saved")) {
      if (onlyCloudCheck) {
        setNewChrtdata(chartData?.filter((item: any) => {
          if (!item?.header?.includes("Payment") && !item?.header?.includes("Recommendations")) {
            return item
          }
        }))
      } else {
        let deviceTypes: any = []
        for (let i = 0; i < selectedDevices.length; i++) {
          let deviceTypeArray: any = deviceListFilter?.rows?.filter((device: any) => device.id === selectedDevices[i])
          deviceTypes.push(deviceTypeArray[0].deviceType)
        }
        let otherThanCloudCheck = deviceTypes?.some(device => !device?.includes('cloudCheck'))
        if (otherThanCloudCheck || !selectedDevices.length) {
          setNewChrtdata(chartData)
        } else {
          setNewChrtdata(chartData?.filter((item: any) => {
            if (!item?.header?.includes("Payment") && !item?.header?.includes("Recommendations")) {
              return item
            }
          }))
        }
      }
    } else {
      if (onlyCloudCheck) {
        setNewChrtdata(chartData?.filter((item: any) => {
          if (!item?.header?.includes("Payment") && !item?.header?.includes("Recommendations")) {
            return item
          }
        }))
      } else {
        setNewChrtdata(chartData)
      }
    }
    const dataLength = chartData?.length ? true : false
    setInsightHasData(dataLength)
  }, [chartData, onlyCloudCheck])

  const getFileName = (input: string ) => {
    const parts = input.split(':');

    if (parts.length > 1) {
      const output = parts[1].trim();
      return output;
    } else {
      return "graph";
      }
  }

  const handleExportPDF = async (csvFileName: string) => {
    const chartContainer = document.getElementById('graph-container');
  
    if (chartContainer) {
      const originalOverflow = chartContainer.style.overflow;
      chartContainer.style.overflow = 'visible';
  
      const captureGraph = async (graphContainer, index) => {
        try {
          const canvas = await html2canvas(graphContainer, { scale: 2 });
          return {
            index,
            image: {
              image: canvas.toDataURL(),
              width: 500,
            },
          };
        } catch (error) {
          AppService.showToast(t(jsonData.ErrorExportingPdf))
        }
      };
  
      const chartData = graphType === "sorting" ? newGraphData(newChrtdata) : newChrtdata;
      const fileName = groupByValue ? csvFileName : getFileName(chartData[0].header);
      const images = [];
  
      for (let index = 0; index < chartData.length; index++) {
        const graphContainerId = `graph-${index}`;
        const graphContainer = document.getElementById(graphContainerId);
  
        if (graphContainer) {
          const capturedImage = await captureGraph(graphContainer, index);
          if (capturedImage) {
            images.push(capturedImage);
          }
        }  
        await new Promise(resolve => setTimeout(resolve, 200));
      }
  
      const pieGraphContainer = document.getElementById('sorting-pie-graph');
      if (pieGraphContainer) {
        const capturedImage = await captureGraph(pieGraphContainer, chartData.length);
        if (capturedImage) {
          images.push(capturedImage);
        }
      }
  
      try {
        const pdfDoc = {
          content: images.map((image) => image.image),
        };
  
        pdfMake.createPdf(pdfDoc).download(`${fileName}.pdf`);
  
        chartContainer.style.overflow = originalOverflow;
        setDownloadingGraph(false);
      } catch (error) {
        chartContainer.style.overflow = originalOverflow;
        setDownloadingGraph(false);
        return AppService.showToast(t(jsonData.ErrorExportingPdf))
      }
    }    
  };
  

  useImperativeHandle(ref, () => ({
    callChildFunction: handleExportPDF,
  }));


  if(graphType === "sorting"){
    return (
      <>
      <div id="graph-container">
        <Loader loading={reportDataFormState?.loading || formState?.loading}>
          <CompWrapper observeOn="insightsHeader" otherClasses="ant-row" exclude={(pages * 10) > 10 && !groupByValue ? bottomMargin + 48 : 0}>
                   {
              newGraphData(newChrtdata)?.map((chartDatum,index) =>{
                return (<LineGraph index={index} graphType={true} key={index} graphData={chartDatum} yMax={yMax} yScale={yScale} format={format} menu={menu} groupBy={groupBy} groupByValue={groupByValue} chart
                  lineGraphData={newGraphData(newChrtdata)} startPage={startPage} endPage={endPage} colorKeys={colorKeys}/>)
              })
            }
            <PieGraph key={2} graphData={pieChartData} yMax={yMax} yScale={yScale} format={format} menu={menu} groupBy={groupBy} groupByValue={groupByValue} chart
              lineGraphData={newChrtdata} startPage={startPage} endPage={endPage} colorKeys={colorKeys} />
          </CompWrapper>
          </Loader>
          </div>
      </>
    )
  }else{
    return (
      <>
      <div id="graph-container">
        <Loader loading={reportDataFormState?.loading || formState?.loading}>
          <CompWrapper observeOn="insightsHeader" otherClasses="ant-row" exclude={(pages*10) > 10 && !groupByValue  ? bottomMargin+48: 0}>
            {
              newChrtdata?.map((chartDatum,index) =>{
                return (<LineGraph index={index} graphType={false} key={index} graphData={chartDatum} yMax={yMax} yScale={yScale} format={format} menu={menu} groupBy={groupBy} groupByValue={groupByValue} chart
                  lineGraphData={newChrtdata} startPage={startPage} endPage={endPage}/>)
              })
            }
  
          </CompWrapper>
          </Loader>
          </div>
      </>
    )
  }
})

export default InsightGraph
