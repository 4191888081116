/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useCallback,useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Row, Col, Tooltip, Input, Button as AntdButton } from 'antd'
import { onPage, settingsEntered, sidebarNavigate } from '../../../../redux/actions/app/appAction'
import { DeleteDeviceWarnModal, DEVICE_SETTING_DEFAULT_LIMIT, DEVICE_SETTING_PAGE_SIZE, jsonData, Pages } from '../../../constants/constants'
import { SideBarItems } from '../../../constants/sideBarConstants'
import { cameFromOrganisation, getHeight, urlModification } from '../../../../utility/appUtil'
import './style.less'
import { appSelector, storeSelectedDevice } from '../../../../redux/reducers/app/appReducers'
import { userStateSelector } from '../../../../redux/reducers/user/userReducer'
import Loader from '../../stateless/common/spinner'
import { AntdInfinityTable } from '../../stateless/common/antdTable/antdInfininityTable'
import { getTableFilterData, onlyUnique, prepareFilterList, prepareFilterListLocation, prepareFilterDeviceType } from '../../../../utility/utils'
import { deleteDevice, fetchDeviceFilterByOrgId, fetchDeviceListByOrgId } from '../../../../redux/actions/device/deviceAction'
import { clearDeviceFormStates, clearDeviceList, DeviceSelector } from '../../../../redux/reducers/device/deviceReducer'
import { AppRoutes, UserRole } from '../../../constants/enums'
import { Button } from '../../../../bit_components/common/button'
import { Link, Redirect } from "react-router-dom"
import { DataDisplayed } from '../eventLogs/eventHeader/index.styles'
import { DeleteIconImg } from '../../../images'
import WarnModal from "../../stateless/common/warnModal"
import { AppService } from '../../../services/ui/appService'
import { Messages } from '../../../constants/messages'
import AntdPagination from '../../stateless/common/pagination/pagination'
import { useTranslation } from 'react-i18next';
import CustomFilter from '../../stateless/common/customTableFilter'
import { UseGAEventTracker } from '../../../../utility/useGAEventTracker'
import CopyComponent from '../../stateless/common/copyComponent'
import { SearchOutlined } from "@ant-design/icons";

export const DeviceSettings = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { selectedOrganisation } = useSelector(appSelector)
    const { deviceList, deviceFormState, deleteDeviceFormstate, totalDevice, deviceListFilter, deviceListFilterFormState } = useSelector(DeviceSelector)
    const { appUser } = useSelector(userStateSelector)
    const [screenHeight, setScreenHeight] = useState(document.body.clientHeight)
    const [screenWidth, setScreenWidth] = useState(document.body.clientWidth)
    const [unfilteredData, setUnfilteredData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [tableData, setTableData] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)
    const [scrollHeight, setScrollHeight] = useState(200)
    const [scrolledToEnd, setScrolledToEnd] = useState(false)
    const [deviceNameFilter, setDeviceNameFilter] = useState()
    const [locationFilter, setLocationFilter] = useState()
    const [productFilter, setProductFilter] = useState()
    const [showWarn, setShowWarn] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deviceId, setDeviceId] = useState<string>()

    const [filteredInfo, setFilteredInfo] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [locationUpdate,setLocationUpdate] = useState(true)
    const [locationFilterData,setLocationFilterData] = useState(null)
    const { t } = useTranslation()
    const [selectedDeviceStates, setSelectedDeviceStates] = useState<any>({});
    const [reset, setReset] = useState<boolean>(false);
    const [searchBox, setSearchBox] = useState(false);
    const [focusClass,setFocusClass] = useState<string>("")
    const [searchText, setSearchText] = useState<any | null>();
    const [searchTriggered, setSearchTriggered] = useState(false)
    const inputRef = useRef(null);

    const getOrgId = () => {
        let orgId = appUser?.organization.id as string;
        if (cameFromOrganisation()) {
            orgId = selectedOrganisation?.id;
        }
        return orgId;
    };

    useEffect(() => {
        dispatch(sidebarNavigate(SideBarItems.DEVICE_SETTINGS))
        dispatch(onPage({ onComponent: Pages.SETTINGS }))
        // if (cameFromOrganisation()) {
        //     dispatch(settingsEntered({ path: window.localStorage.getItem('path') ?? AppRoutes.USER_DASHBOARD, isSettingState: true }))
        // }
        setLoading(true)
        dispatch(fetchDeviceFilterByOrgId({ orgId: getOrgId() }))
        dispatch(fetchDeviceListByOrgId({ orgId: getOrgId() }))
        const node = document.body
        const resizeObserver = new ResizeObserver((entries) => {
            setScreenHeight(node?.clientHeight)
            setScreenWidth(node?.clientWidth)
        });
        resizeObserver.observe(node);    
        return () => {
            dispatch(clearDeviceList())
        }
    }, []);

    useEffect(() => {
        if (deviceFormState?.isSuccess && !deviceList?.length) {
            setLoading(false)
        }
        if (deviceFormState?.isError) {
            setLoading(false)
        }
    }, [deviceFormState])


    useEffect(() => {
        // if (deviceList?.length) {
            initializeData(deviceList).then((data) => {
                setUnfilteredData(data)
                setFilteredData(data)

                setTableData(data.slice(0, currentIndex + DEVICE_SETTING_PAGE_SIZE))
                setCurrentIndex(0 + DEVICE_SETTING_PAGE_SIZE)

                //filter table  by names
                // let namesList = data?.map((data: any) => data?.info?.name).filter(onlyUnique)
                // setDeviceNameFilter(prepareFilterList(namesList))

                // filter table  by location
                // let locationList = data?.map((data: any) => data.location).filter(onlyUnique)
                // setLocationFilter(prepareFilterList(locationList))

                //filter table  by product
                // let productList = data?.map((data: any) => data.deviceType).filter(onlyUnique)
                // setProductFilter(prepareFilterList(productList))
                
                setLoading(false)
            })
                .catch((err) => {
                    console.log('Failed to initialize device list')
                })
        // }
    }, [deviceList])

    const initializeData = async (data: any[]) => {
        const result = data.map((item: any) => {
            return {
                ...item,
                deviceId: item?.id,
                name: item?.info?.name
            }
        })
        return result
    }

    useEffect(() => {
        dispatch(fetchDeviceListByOrgId({ orgId: getOrgId(), locations: selectedDeviceStates?.location, deviceTypes: selectedDeviceStates?.deviceType, name: selectedDeviceStates?.name, searchParam : searchText }))
    }, [selectedDeviceStates])


    const selectedDeviceStateUpdate = (data: any, key: any,reset:any) => {
        const updatedDeviceStates = { ...selectedDeviceStates };
        setReset(reset)
        if (updatedDeviceStates?.hasOwnProperty(key)) {
            setSelectedDeviceStates({...updatedDeviceStates,[`${key}`]:data[key]})
        } else {
            setSelectedDeviceStates({...updatedDeviceStates,...data})
        }
    };

    const deviceColumns = () => {

        const copyEnabledColumn = (columnValue : any, applyMaxwidth=false) => {
            return(
                <div className='copy-column-container'>
                    <div className= {applyMaxwidth ? "apply-maxWidth" : ""}>
                        {columnValue}
                    </div>
                    <CopyComponent text={columnValue}/>
                </div>
            )

        }
        const locationName = (columnValue : any) => {
            return(
                 <div className= "apply-maxWidth">
                    {columnValue}
                </div>
            )
        }

        let columns: any
        if(appUser.locations[0].role.name === UserRole.ORGANISATION_ADMIN){
            columns= [
                {
                    title: <div>{t(jsonData.DeviceUUID)}</div>,
                    width: 50,
                    key: "id",
                    ellipsis: true,
                    render: (data: any) => copyEnabledColumn(data.id)
                },
                {
                    title: <div>{t(jsonData.Location)}
                    <CustomFilter
                        optionList={locationFilter}
                        selectedData={selectedDeviceStateUpdate}
                        selectedItems={selectedDeviceStates?.location??[]}
                        allSelectedText={t(jsonData.AllStates)}
                        placeholder={t(jsonData.SelectState)}
                        optionListClass="state-optionList-location22"
                        wrapperClassName="state-multiselect"
                        coulmnName="location"
                        filterName={t(jsonData.locationFilter)}
                        allowSearch={true}
                        />
                    </div>,
                    width: 50,
                    dataIndex: 'location',
                    key: "location",
                    ellipsis: true,
                    filteredValue: filteredInfo?.location || null,
                    render: (location: string) => {
                        return locationName(location)
                    }
                },
                {
                    title: <div>{t(jsonData.DeviceName)}
                     <CustomFilter
                        optionList={deviceNameFilter}
                        selectedData={selectedDeviceStateUpdate}
                        selectedItems={selectedDeviceStates?.name??[]}
                        allSelectedText={t(jsonData.AllStates)}
                        placeholder={t(jsonData.SelectState)}
                        optionListClass="state-optionList-device-name"
                        wrapperClassName="state-multiselect"
                        coulmnName="name"
                        filterName={t(jsonData.deviceNameFilter)}
                        allowSearch={true}
                    />
                    </div>,
                    width: 50,
                    key: "name",
                    ellipsis: true,
                    filteredValue: filteredInfo?.name || null,
                    render: (data: any) => copyEnabledColumn(data.name,true)
                },
                {
                    title: <div>{t(jsonData.DeviceType)}
                     <CustomFilter
                        optionList={productFilter}
                        selectedData={selectedDeviceStateUpdate}
                        selectedItems={selectedDeviceStates?.deviceType??[]}
                        allSelectedText={t(jsonData.AllStates)}
                        placeholder={t(jsonData.SelectState)}
                        optionListClass="state-optionList-device-type"
                        wrapperClassName="state-multiselect"
                        coulmnName="deviceType"
                        filterName={t(jsonData.deviceTypeFilter)}
                    />
                    </div>,
                    width: 50,
                    dataIndex: "deviceType",
                    key: "deviceType",
                    filteredValue: filteredInfo?.deviceType || null,
                    ellipsis: true,
                    render: (deviceType: string) => {
                        return deviceType
                    }
                },
                {
                    title: '',
                    key: "delete",
                    width: 50,
                    ellipsis: true
                }
            ]
        } else {
            columns= [
                {
                    title: <div>{t(jsonData.DeviceUUID)}</div>,
                    width: 50,
                    key: "id",
                    ellipsis: true,
                    render: (data: any) => copyEnabledColumn(data.id)
                },
                {
                    title: <div>{t(jsonData.Location)}
                    <CustomFilter
                        optionList={locationFilter}
                        selectedData={selectedDeviceStateUpdate}
                        selectedItems={selectedDeviceStates?.location??[]}
                        allSelectedText={t(jsonData.AllStates)}
                        placeholder={t(jsonData.SelectState)}
                        optionListClass="state-optionList-location22"
                        wrapperClassName="state-multiselect"
                        coulmnName="location"
                        filterName={t(jsonData.locationFilter)}
                        allowSearch={true}
                        />
                    </div>,
                    width: 50,
                    dataIndex: 'location',
                    key: "location",
                    ellipsis: true,
                    filteredValue: filteredInfo?.location || null,
                    render: (location: string) => {
                        return locationName(location)
                    }
                },
                {
                    title: <div>{t(jsonData.DeviceName)}
                     <CustomFilter
                        optionList={deviceNameFilter}
                        selectedData={selectedDeviceStateUpdate}
                        selectedItems={selectedDeviceStates?.name??[]}
                        allSelectedText={t(jsonData.AllStates)}
                        placeholder={t(jsonData.SelectState)}
                        optionListClass="state-optionList-device-name"
                        wrapperClassName="state-multiselect"
                        coulmnName="name"
                        filterName={t(jsonData.deviceNameFilter)}
                        allowSearch={true}
                    />
                    </div>,
                    width: 50,
                    key: "name",
                    ellipsis: true,
                    filteredValue: filteredInfo?.name || null,
                    render: (data: any) => copyEnabledColumn(data.name,true)
                },
                {
                    title: <div>{t(jsonData.DeviceType)}
                     <CustomFilter
                        optionList={productFilter}
                        selectedData={selectedDeviceStateUpdate}
                        selectedItems={selectedDeviceStates?.deviceType??[]}
                        allSelectedText={t(jsonData.AllStates)}
                        placeholder={t(jsonData.SelectState)}
                        optionListClass="state-optionList-device-type"
                        wrapperClassName="state-multiselect"
                        coulmnName="deviceType"
                        filterName={t(jsonData.deviceTypeFilter)}
                    />
                    </div>,
                    width: 50,
                    dataIndex: "deviceType",
                    key: "deviceType",
                    filteredValue: filteredInfo?.deviceType || null,
                    ellipsis: true,
                    render: (deviceType: string) => {
                        return deviceType
                    }
                },
                {
                    title: '',
                    key: "delete",
                    width: 50,
                    ellipsis: true,
                    render: (data: any) => {
                        return (
                            ( data?.deviceType=== 'cloudCheck tablet' ||  data?.deviceType=== 'cloudCheck mobile') &&
                            <Tooltip placement="bottomRight" title={t(jsonData.DeleteDevice)}>
                                <img role='button' tabIndex={0} aria-label={t(jsonData.deleteDevice)} onKeyPress={() => onDeviceDelete(data?.id)} className="deleteDeviceIcon" src={DeleteIconImg} alt="delete" onClick={() => onDeviceDelete(data?.id)}
                                    onMouseOut={(e) => (e.currentTarget.src = DeleteIconImg)}
                                />
                            </Tooltip>
                        )
                    }
                }
            ]
        }

        return columns
    }

    const onDeviceDelete = (id:string) => {
        setDeviceId(id)
        setShowWarn(true)
    }


    // to calculate scroll height
    // useEffect(() => {
    //     if(!deviceFormState.loading && !loading) {
    //     // const compContentHeight = getHeight('.ant-layout-content')
    //     // const bottomGap = compContentHeight - (screenHeight * 0.75)
    //     //table height
    //     // const tableHeight = getHeight('.ant-table-body')
    //     //to calculate final height of the table
    //     // const calculatedHeight = tableHeight
    //     // const scrollH = parseInt(calculatedHeight.toFixed(0));
    //     // !isNaN(scrollH) && setScrollHeight(scrollH)

    //     const compContentHeight = getHeight('.ant-layout-content')
    //     const bottomGap = compContentHeight - (screenHeight * 0.8)
    //     const pagination_div = document.querySelector('.audit-pagination')?.clientHeight || 50
        
    //     const footerHeight = totalDevice > 150 ? pagination_div + bottomGap : 0;

    //      //to calculate final height of the table
    //      const tableHeight = getHeight('.ant-table-body')
    //      console.log({pagination_div,tableHeight});

    //      const calculatedHeight = tableHeight - footerHeight
         
    //      const scrollH = parseInt(calculatedHeight.toFixed(0));
    //      !isNaN(scrollH) && setScrollHeight(scrollH)
    //      console.log({footerHeight, tableHeight ,scrollH});

    // }}, [screenHeight, screenWidth,loading, deviceFormState, totalDevice])

    const onRef = useCallback((node)=>{
        if(!deviceFormState.loading && !loading) {
            const compContentHeight = getHeight('.ant-layout-content')
            const bottomGap = compContentHeight - (screenHeight * 0.8)
            const pagination_div = document.querySelector('.audit-pagination')?.clientHeight || 50
            const footerHeight = totalDevice > 150 ? pagination_div + bottomGap : 0;

            //to calculate final height of the table
            const tableHeight = getHeight('.ant-table-body')
            const calculatedHeight = tableHeight - footerHeight
            
            const scrollH = parseInt(calculatedHeight.toFixed(0));
            !isNaN(scrollH) && setScrollHeight(scrollH)
        }
    },[screenHeight, screenWidth,loading, deviceFormState, totalDevice])


    const onScroll = (event: any) => {
        if (event.target.scrollTop && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            setScrolledToEnd(true);
        }
    }

    const loadMore = () => {
        if (tableData?.length !== filteredData?.length && scrolledToEnd) {
            setScrolledToEnd(false);
            fetchData(currentIndex).then(async (newData: any) => {
                setTableData(tableData.concat(newData));
                setCurrentIndex(currentIndex + DEVICE_SETTING_PAGE_SIZE);
            }
            );
        }
    }

    const fetchData = async (startIndex: number) => {
        const t = filteredData.slice(startIndex, startIndex + DEVICE_SETTING_PAGE_SIZE);
        return t;
    }

    // to apply selected filters and sorters
    const filterSorting = (pagination, filters, sorter, extra) => {
        // // dispatch(fetchDeviceListByOrgId({ orgId: getOrgId(), locations: filters?.location, deviceTypes: filters?.deviceType, name: filters?.name}))
        // setLocationUpdate(false)
        //     const newFilter =  filters !== null && filters?.location != null ? filters?.location.toString() : null
        //     const oldFilter =  locationFilterData !== null ? locationFilterData.toString() : null
        //     const triggerFilterApi = newFilter !== oldFilter
        //     if(triggerFilterApi) {
        //         dispatch(fetchDeviceFilterByOrgId({ orgId: getOrgId(), locations: filters?.location }))
        //     }
        // setCurrentPage(1)
        // setLocationFilterData(filters?.location)
        // setFilteredInfo(filters)
        // // setSortedInfo(sorter)
        // getTableFilterData(filters, unfilteredData, unfilteredData).then((tableFilterData: any) => {
        //     // getSortedData(sorter, tableFilterData).then((data) => {

        //     setFilteredData(tableFilterData)
        //     setTableData(tableFilterData?.slice(0, DEVICE_SETTING_PAGE_SIZE))
        //     setCurrentIndex(0 + DEVICE_SETTING_PAGE_SIZE)
        //     // })

        // }).catch((err) => {
        //     console.log('Failed to get Filtered data ', err)
        // })
    }

    const getSortedData = async (sortValue?: any, filterData?: any) => {
        const keyValue = sortValue?.column?.key
        const order = sortValue?.order

        if (order === "ascend") {
            if (keyValue !== "eventId") {
                let ascendingData = filterData?.sort(function (a: any, b: any) {
                    return a[keyValue]?.localeCompare(b[keyValue]);
                });
                return ascendingData;
            }
            else {
                return filterData?.sort((a, b) => a[keyValue] - b[keyValue])
            }
        }
        else if (order === "descend") {
            if (keyValue !== "eventId") {
                let descendingData = filterData?.sort(function (a: any, b: any) {
                    return b[keyValue]?.localeCompare(a[keyValue]);
                });
                return descendingData;
            }
            else {
                return filterData?.sort((a, b) => b[keyValue] - a[keyValue])
            }
        } else if (keyValue === undefined || order === undefined) {
            let defaultData = filterData?.sort((a, b) => a.itemIndex - b.itemIndex)
            return defaultData;
        }
    }

    const onRow = (record: any, rowIndex: any) => {
        return {
            onKeyPress: (event: any) => {
                if (!event?.target?.firstChild?.classList?.contains('deleteDeviceIcon')
                    && !event?.target?.classList?.contains('deleteDeviceIcon')
                    && !event?.target?.classList?.contains('copy-icon')
                ) {
                    dispatch(storeSelectedDevice(record))
                    history.push(urlModification(AppRoutes.DEVICE_INFO))
                    UseGAEventTracker("Device Settings Category", "Device Selected Row", `${record.id} Id Device Details Viewed`);
                }
            },
            onClick: (event: any) => {
                if (!event?.target?.firstChild?.classList?.contains('deleteDeviceIcon')
                    && !event?.target?.classList?.contains('deleteDeviceIcon')
                ) {
                    dispatch(storeSelectedDevice(record))
                    history.push(urlModification(AppRoutes.DEVICE_INFO))
                    UseGAEventTracker("Device Settings Category", "Device Selected Row", `${record.id} Id Device Details Viewed`);
                }
            },
        };
    }

    //Delete device
    const handleDeleteDevice = () => {
        dispatch(deleteDevice(deviceId))
    }

    useEffect(() => {
        if (deleteDeviceFormstate?.isSuccess) {
            AppService.showToast(t(Messages.DEVICE_DELETED))
            setSearchText("");
            setSearchBox(false)
            dispatch(clearDeviceFormStates())
            setShowWarn(false)
            setUnfilteredData([])
            setFilteredData([])
            setTableData([])
            setCurrentPage(1)
            dispatch(fetchDeviceListByOrgId({ orgId: getOrgId() }))
        }
        else if (deleteDeviceFormstate?.isError) {
            AppService.showToast(t(Messages.ERROR_OCCURRED))
            dispatch(clearDeviceFormStates())
            setShowWarn(false)
        }
    }, [deleteDeviceFormstate])

    const onPageChange = (page:any) => {
        setCurrentPage(page)
        dispatch(fetchDeviceListByOrgId({ orgId: getOrgId(), locations: filteredInfo?.location, deviceTypes: filteredInfo?.deviceType, name: filteredInfo?.name, page:page, searchParam : searchText}))
    }

    useEffect(() => {
        if(deviceListFilterFormState.isSuccess) {
        // filter table  by location
        if(locationUpdate) {
            let hierarchy = deviceListFilter?.rows?.filter((elem,index) => deviceListFilter?.rows?.findIndex(obj=> obj.location === elem.location) === index)
            setLocationFilter(prepareFilterListLocation(hierarchy))
        }

        //filter table  by names
        let namesList = deviceListFilter.rows?.map((data: any) => data?.info?.name).filter(onlyUnique)
        setDeviceNameFilter(prepareFilterList(namesList))

         //filter table  by product
        let productList = deviceListFilter?.rows?.filter((elem,index) => deviceListFilter?.rows?.findIndex(obj=> obj.deviceType === elem.deviceType) === index)
        setProductFilter(prepareFilterDeviceType(productList))
        }
    },[deviceListFilter,deviceListFilterFormState])

    const handleKeyPress = () => {
        history.push(urlModification(AppRoutes.CREATE_DEVICE))
    }

    let element: any = document.querySelectorAll('.ant-table-row');
    element.forEach((element) => {
        if (element) {
            element?.setAttribute("tabIndex", "0");
        }
    });

    const handleSearch = (text: string) => {
        dispatch(fetchDeviceListByOrgId({ orgId: getOrgId(), locations: selectedDeviceStates?.location, deviceTypes: selectedDeviceStates?.deviceType, name: selectedDeviceStates?.name, searchParam : text }))
    }

    const toggleSearchBox = (evt) => {
        setFocusClass("")
        if(!searchText?.length && searchTriggered){
          handleSearch(evt.target.value)
          setSearchTriggered(false)
        }
          !searchText && setSearchBox(prev => !prev)
      };
      
      useEffect(() => {
        if (searchBox) {
          inputRef.current.focus();
        }
      }, [searchBox]);

      const onChangeText = (event: any) => {
        if(!event.target.value?.length && searchTriggered){
          handleSearch(event.target.value)
          setSearchTriggered(false)
          inputRef.current.focus()
        }  
        setSearchText(event.target.value)
      };

      const onEnterSearch = (event: any) => {
        handleSearch(event.target.value)
        setSearchTriggered(true)
        inputRef.current.blur()
      };
    
      const inputHaveFocus = () => {
        setFocusClass("eventSearchBar")
      }

      const handleClick = (e: any, clearIcon: any) => {
        if (e?.keyCode === 13) {
          clearIcon.click();
        }
        clearIcon.removeEventListener("keyup", (e) => handleClick);
      }


      const handleClear = (e: any) => {
        let clearIcon: any = document.querySelector('.ant-input-clear-icon');
        if (e?.keyCode === 9) {
          clearIcon.setAttribute("tabIndex", "0");
          clearIcon.addEventListener("keyup", (e) => handleClick(e, clearIcon))
        }
      }

      
    const showSearch = () => {
      if (searchBox) {
        return (
          <Input
            type="text"
            ref={inputRef}
            className={`${focusClass} tablesearchbar highlighter`}
            onChange={onChangeText}
            onBlur={toggleSearchBox}
            value={searchText}
            placeholder={t("Search")}
            bordered={true}
            id="highlighter"
            onPressEnter={onEnterSearch}
            onFocus={inputHaveFocus}
            onKeyDown={(e) => handleClear(e)}
            allowClear
          />
        );
      }
    };

    return (
        <>
            {appUser.locations[0].role.name !== UserRole.PLATFORM_ADMIN &&
                appUser.locations[0].role.name !== UserRole.BIBLIOTHECA_USER &&
                appUser.locations[0].role.name !== UserRole.ORGANISATION_ADMIN ?

                <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} />
                :
                <>
                    <WarnModal
                        cancelButton={t(DeleteDeviceWarnModal.CANCEL_BTN_TEXT)}
                        confirmButton={t(DeleteDeviceWarnModal.CONFIRM_BTN_TEXT)}
                        primaryText={t(DeleteDeviceWarnModal.TITLE)}
                        secondaryText={t(DeleteDeviceWarnModal.CONTENT)}
                        isModalVisible={showWarn}
                        cancelCallback={() => {
                            setShowWarn(false)
                        }}
                        confirmCallback={handleDeleteDevice}
                    />
                    <div className="ml-23 pl-vw deviceSetting-page">
                        <Row className='deviceSetting-header'>
                            <Col className="deviceSetting-heading">
                                {t(jsonData.Devices)}
                            </Col>
                            {appUser.locations[0].role.name !== UserRole.ORGANISATION_ADMIN && (
                                <Col className='deviceSetting-actionBtn' tabIndex={-1} onKeyPress={handleKeyPress}>
                                    <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.AddDevice)}>
                                        <Link tabIndex={-1} to={urlModification(AppRoutes.CREATE_DEVICE)}>
                                            <Button ariaLabel={t(jsonData.AddDevice)}
                                                type="actionButton"
                                            >
                                                + {t(jsonData.AddDevice)}
                                            </Button>
                                        </Link>
                                    </Tooltip>
                                </Col>
                            )}
                            <Col span={24} className='deviceSetting-subHeader'>
                                <DataDisplayed>{t(jsonData.NumberOfDevices)}: {filteredData?.length}</DataDisplayed>
                                    <Col className="orgSearchBtn deviceSearchIcon" >
                                    <Tooltip overlayClassName='dashBoardTooltip' placement='bottom' className="eventSearchTooltip" title={t(jsonData.search)}>
                                        <AntdButton
                                        className="serOrgBtn eventSearIcon"
                                        onClick={toggleSearchBox}
                                        shape="circle"
                                        type="link"
                                        icon={<SearchOutlined />}
                                        />
                                    </Tooltip>
                                    {showSearch()}
                                    </Col>
                            </Col>
                        </Row>
                        <Loader loading={deviceFormState.loading || loading || deviceListFilterFormState.loading}>
                            <AntdInfinityTable
                                tableClassName='deviceSetting-list'
                                dataSource={tableData ?? []}
                                onScroll={onScroll}
                                scroll={
                                    {
                                        y: scrollHeight,
                                        scrollToFirstRowOnChange: true
                                    }}
                                locale={!tableData?.length && { emptyText: t(jsonData.NoDataFound) }}
                                columns={deviceColumns()}
                                onChange={(pagination, filters, sorter, extra) => {
                                    filterSorting(pagination, filters, sorter, extra)
                                }}
                                onRow={onRow}
                                pagination={false}
                                onFetch={loadMore}
                                pageSize={DEVICE_SETTING_DEFAULT_LIMIT}
                                ref={onRef}
                            />
                            {
                                totalDevice > 150 &&
                                <AntdPagination className='audit-pagination'
                                    total={totalDevice}
                                    current={currentPage}
                                    defaultPageSize={150}
                                    onChange={(page: any, pageSize: any) => onPageChange(page)} />
                            }
                        </Loader>
                    </div>
                </>
            }
        </>
    )
}