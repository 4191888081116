import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
} from "../../../utility/stateUtil";
import {
  fetchEventLogList, fetchEventsInfo, fetchUnstructuredData,
} from "../../actions/eventLogs/eventListAction";
import { EventListState } from "../../states/eventLogs/eventListState";
import { RootState } from "../../store/rootReducer";

const initialState: EventListState = {
  eventLogList: [],
  currentPage:1,
  totalPages:1,
  formState: resetState(),
  eventsInfoFormState: resetState(),
  eventsInfo: [],
  totalEvents: 0,
  unstructuredData: [],
  unstructuredDataFormState: resetState(),
  isRefresh: false
};

export const EventLogListSlice = createSlice({
  name: "eventLogList",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      return state;
    },
    clearEventLogs: (state) => {
      return {...state, eventLogList: [], totalEvents: 0, currentPage: 1, totalPages:1}
    },
    clearEventsInfo: (state) => {
      state.eventsInfo = []
      state.eventsInfoFormState = resetState()
      return state
    },
    clearUnstructuredData: (state) => {
      state.unstructuredData = []
      state.unstructuredDataFormState = resetState()
      return state
    },
    setEventLogIsRefresh: (state, {payload}) => {
      state.isRefresh = payload
      return state
    }
  },
  extraReducers: {
    [fetchEventLogList.fulfilled.toString()]: (state, { payload }) => {
      state.eventLogList = payload?.rows
      state.totalEvents = payload?.count
      state.currentPage = payload?.page;
      state.totalPages = payload?.totalPages
      state.formState = fulfilledState();
      return state;
    },
    [fetchEventLogList.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [fetchEventLogList.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
    [fetchEventsInfo.fulfilled.toString()]: (state, {payload}) => {
      state.eventsInfo = payload
      state.eventsInfoFormState = fulfilledState() 
    },
    [fetchEventsInfo.rejected.toString()]: (state, {payload}) => {
      state.eventsInfoFormState = rejectedState(payload) 
    },
    [fetchEventsInfo.pending.toString()]: (state, {payload}) => {
      state.eventsInfoFormState = pendingState() 
    },
    [fetchUnstructuredData.fulfilled.toString()]: (state, {payload}) => {
      state.unstructuredData = payload
      state.unstructuredDataFormState = fulfilledState() 
    },
    [fetchUnstructuredData.rejected.toString()]: (state, {payload}) => {
      state.unstructuredDataFormState = rejectedState(payload) 
    },
    [fetchUnstructuredData.pending.toString()]: (state, {payload}) => {
      state.unstructuredDataFormState = pendingState() 
    }
  },
});

export const { clearState,clearEventLogs,clearEventsInfo, clearUnstructuredData, setEventLogIsRefresh } = EventLogListSlice.actions;
export const eventLogListSelector = (state: RootState) => state.eventLogs;
export default EventLogListSlice.reducer;
