import styled from 'styled-components';
import React from "react";

export const NoMoreText = styled.div`
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.text.quaternary};
    @media (max-width: 775px) {
        font-size: ${(props) => props.theme.fontSizes.smallBody};
      }
`
export const LocationTitle = styled.span`
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-size: ${(props) => props.theme.fontSizes.h1};
    line-height: 20px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.text.primary};
    padding-bottom: 36px;
    padding-top: 2.1%;
    padding-left: 20px;
`

export const LocationEditing = styled((props: any) => {
    const { children, span, ...rest } = props;
    return <a span={span} {...rest}>{children}</a>;
  })`
  
    ${({ haschildren }) =>
      haschildren === "true"?
      `display: block;`
      :
      `display: none;`
    }
`;
  