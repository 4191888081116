/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// module imports
import { RolesListHeader } from './rolesHeader/rolesHeader';
import { RolesListRow } from './rolesRow/rolesListRow';
import { fetchTransformedRoleList } from '../../../../../redux/actions/role/roleAction';
import {roleListSelector, clearState, clearSelectedRoleIdFormState} from '../../../../../redux/reducers/role/roleListReducer';
import { userStateSelector } from '../../../../../redux/reducers/user/userReducer';
import '../../../stateless/common/customScrollBar/customScrollStyle.less';
import { MainLayoutService } from '../../../../services/ui/mainLayoutService';
import { AppRoutes, HeaderType, UserRole } from '../../../../constants/enums';
import { cameFromOrganisation, urlModification } from '../../../../../utility/appUtil';
import { appSelector } from '../../../../../redux/reducers/app/appReducers';
import { SideBarItems } from '../../../../constants/sideBarConstants';
import { onPage, sidebarNavigate } from '../../../../../redux/actions/app/appAction';
import { Pages } from '../../../../constants/constants';
import Loader from '../../../stateless/common/spinner';
import { CompWrapper } from "../../../stateless/common/compWrapper/compWrapper";
import './rolesRow/rolesListRow.css'
import { Redirect } from "react-router-dom"

export const RolesList = (props: any) => {

  const dispatch = useDispatch();
  const { orgRoleList=[], formState } = useSelector(roleListSelector);
  const { appUser } = useSelector(userStateSelector);
  const { selectedOrganisation } = useSelector(appSelector);

  useEffect(()=>{
    MainLayoutService.renderHeader(HeaderType.JUNTO_PORTAL);
    dispatch(onPage({onComponent : Pages.SETTINGS}));
    dispatch(sidebarNavigate(SideBarItems.ROLES));
    return () => {
      dispatch(clearSelectedRoleIdFormState());
    }
  }, [])
  // clear state on change of state of formstate
  useEffect(() => {
    if (formState && formState.isError) {
      dispatch(clearState());
    } else if (formState && formState.isSuccess) {
      dispatch(clearState());
    }
  }, [formState.isError, formState.isSuccess]);

  // dispatch action to fetch roles for organization after clearing state
  useEffect(() => {
    dispatch(clearState());    
    dispatch(fetchTransformedRoleList(getOrgId()));    
    
  }, [selectedOrganisation]);

  const getOrgId = () => {
    let orgId = appUser?.organization.id as string;
      if(cameFromOrganisation()) {
        orgId = selectedOrganisation?.id;
      }      
      return orgId;
  }

  return (
    <>
    {appUser.locations[0].role.name === UserRole.LIBRARY_FRONT_DESK ?

      <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} />
      :
      <>
        <RolesListHeader />
      <Loader loading={formState?.loading}>
        <CompWrapper observeOn="roleHeader" otherClasses="pl-vw scrollContentRole">
          {orgRoleList && orgRoleList.map((item:any) => (
            <RolesListRow key={item.id} item={item} />
          ))}
        </CompWrapper>
      </Loader>
      </>
    }
    </>
  );
};