/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Form } from "antd";
import AuthLayout from "../../../layouts/authLayout";
import { LoginContainer } from "../authenticatePwdForm/authenticatePwdForm.Styles";
import "../newUserFlow/newUserFlow.less";
import { AppError } from "../../../../stateless/common/appError";
import CustomInput from "../../../common/inputField/customInput";
import { Button } from "../../../../../../bit_components/common/button"
import { jsonData } from "../../../../../constants/constants";
import { useTranslation } from 'react-i18next';

export const PhoneNumberForm = (props: any) => {
  const { t } = useTranslation()
  const { form, formLayout, location, createPwdCallback, errorMsg,editPhoneCallback } = props;
  const [phoneNumber, setPhoneNumber] = useState<number>(props?.phone);

  const onSubmit = (data: any) => {
    // props.phoneNumberCallback(phoneNumber);
    editPhoneCallback(phoneNumber)
  };

  const getPhoneNumber = (e) => {
    var number = e.target.value;
    setPhoneNumber(number);
  };

  const rules = [
    {
      required: true,
      message:t(jsonData.PleaseInputyourPhoneNumber), // TODO: Handle this while developing validation framework.
    },
    {
      pattern: new RegExp(/^[+][0-9+()-]+$/),
      message: 'Please enter a valid Phone Number and Country Code with "+" sign.'
    },
    {
      min: 5,
      message: t(jsonData.PleaseentErnumberlargerthan5digit)
    },
    {
      max: 15,
      message: t(jsonData.PleaseEnterNumberShorterthan15digit)
    },
  ]

  return (
    <AuthLayout>
      <LoginContainer className="createPassContainer createPassStep">
        <span className="crtPassStep">Step 2</span>
        <span className="stepDashLine">
          <div className="dashFill"></div>
          <div className="dashFill marLeft"></div>
          <div className="dashEmpty"></div>
        </span>
        <span className="crtPassHed">{t(jsonData.PhoneNumber)}</span>
        <span className="crtPassSabText">{t(jsonData.PleaseEnterYourPhoneNumber)}</span>
        <AppError show={errorMsg ? true : false} errorMsg={errorMsg} />
        <Form
          ref={form}
          {...formLayout}
          name="phone-number"
          className="forgot-pwd-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onSubmit}
          requiredMark={false}
        >
          {
            props?.phone !== undefined?
            <CustomInput
            labelSubName={t(jsonData.PhoneNumber)}
            value={phoneNumber}
            initialValue={props?.phone}
            name="phoneNumber"
            labelName="phoneNumber"
            customLabelClass="crtPassLabel mt-25"
            placeholder={t(jsonData.PhoneNumber)}
            onChange={getPhoneNumber}
            // rules={rules}
          />
          :
          ''
          }
       
          <Form.Item className="mb-0">
            <Button type="primary secondNextBtn" htmlType="submit">
              {t(jsonData.Next)}
            </Button>
          </Form.Item>
        </Form>
      </LoginContainer>
    </AuthLayout>
  );
};
