/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react'
import {Form} from 'antd'
import './emailLoginForm.less'
import { AppError } from '../../../../stateless/common/appError'
import '../../../../../components/stateless/common/button/button.less'
import AuthLayout from '../../../layouts/authLayout'
import {Custominput} from '../../../../../../bit_components/common/custominput'
import {Button} from '../../../../../../bit_components/common/button'
import { clearState, userStateSelector } from '../../../../../../redux/reducers/user/userReducer'
import { useDispatch, useSelector } from 'react-redux'
import { LoginBoxCover, LoginHead, LoginText } from './emailLoginForm.Styles'

export const EmailLoginForm = (props: any) => {
  const dispatch = useDispatch();
  const { formState } = props;
  const { appUser } = useSelector(userStateSelector);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const layout = {
    labelCol: {
      span: 24,
      // offset: 2,
    },
    wrapperCol: {
      span: 24,
    },
  }

  useEffect(()=> {
    const node = document.getElementById('auth_email');
    node?.focus();
    dispatch(clearState());
  },[])

  const validateEmail = (email: any) => {
    const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/)
    if(emailRegex.test(email)){
      return true;
    }
    else{
      setShowError(true);
      setErrorMessage('Please enter a valid email address');
      return false;
    }
  };

  const onSubmit = (data: any) => {
    if (props.loginCallback && validateEmail(data.email)) {
      props.loginCallback(data.email)
    }
  }

  const onChangeHandle = (e:any) => {
    if(showError){
      setShowError(false);
      setErrorMessage('');
    }
    if(formState?.isError) {
      dispatch(clearState());
    }
    return e.target.value
  }

  return (
    <AuthLayout>
      <LoginBoxCover>
        <LoginHead>Log In</LoginHead>
        <LoginText>Please enter your email address.</LoginText>
        <Form
          {...layout}
          name='auth'
          className='login-form'
          initialValues={{
            remember: true,
          }}
          onFinish={onSubmit}
          requiredMark={false}
        >
          <Custominput
            customLabelClass={`loginLabelName ${formState?.isError || showError ?'loginError ant-form-item-has-error':''}`}
            labelSubName='Email'
            labelName='email'
            name='email'
            rules={[
              {
                required: true,
                message: 'Please enter a valid email address',
              },
            ]}
            onChange={onChangeHandle}
            initialValue={appUser !== undefined || appUser !== null ? appUser?.email: ''}
          />
          <AppError
          show={formState?.isError || showError}
          errorMsg={(formState.errorStack && formState.errorStack.message) || errorMessage}
        />
          <Form.Item className='formItemInput mb-0'>
            <Button type='primary loginButton' htmlType='submit'>
              Next
            </Button>
          </Form.Item>
        </Form>
      </LoginBoxCover>
    </AuthLayout>
  )
}
