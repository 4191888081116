import styled from 'styled-components'

export const DeviceHeader = styled.div`
    font-family: ${(props) => props.theme.fonts[0]};
    font-size: ${(props) => props.theme.fontSizes.h1};
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    padding-left: 23px;
`

export const DeviceHealthBody = styled.div`
    font-family: ${(props) => props.theme.fonts[0]};
    font-size: ${(props) => props.theme.fontSizes.smallBody}
`