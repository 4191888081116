import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
} from "../../../utility/stateUtil";

import { fetchOrganisationbyID, addOrganisation, updateOrganisation, fetchLocations, fetchUsers, fetchDevices, toggleStatus, deleteOrganisation, fetchGeneralSetting, fetchAccountSetting, fetchOrganisationSummary } from "../../actions/organisation/fetchOrganisationDetails";
import { OrganisationDetailState } from "../../states/organisation/organisationDetailState";
import { RootState } from "../../store/rootReducer";

const initialState: OrganisationDetailState = {
  // organisation: {
  //   id: 0,
  //   shortName: "",
  //   name: "",
  //   county: "",
  //   country: "",
  // },
  organisation: {},
  locations: 0,
  users: 0,
  devices: 0,
  update: false,
  formState: resetState(),
  addOrganisationformState:resetState(),
  selectedOrganisationId:""
};

export const OrganisationDetailsSlice = createSlice({
  name: "organisationDetails",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      return { ...state, formState: resetState() };
    },
    clearAddOrganisationState: (state) => {
      return { ...state, formState: resetState(),addOrganisationformState:resetState() };
    },
    clearSummary: (state) => {
      return { ...state, locations:0, users:0,devices:0 };
    },
    clearOrganisation: (state) => {
      return { ...state, organisation: {} };
    },
    updateState: (state, { payload }) => {
      return { ...state, update: payload, errorMsg: null };
    },
    saveSelectedOrganisationId: (state ,{payload}) => {
      state.selectedOrganisationId = payload
      return state
    },
  },
  extraReducers: {
    [fetchOrganisationbyID.fulfilled.toString()]: (state, { payload }) => {
      return { ...state, organisation: payload, formState: fulfilledState() };
    },
    [fetchOrganisationbyID.rejected.toString()]: (state, { payload }) => {
      return { ...state, formState: rejectedState(payload?.message) };
    },
    [fetchOrganisationbyID.pending.toString()]: (state) => {
      return { ...state, formState: pendingState() };
    },
    // organisation summary transformation
    [fetchOrganisationSummary.fulfilled.toString()]: (state, { payload }) => {
      return { ...state, organisation: payload, locations: payload?.result?.locations, users: payload?.result?.users, devices: payload?.result?.device, formState: fulfilledState() };
    },
    [fetchOrganisationSummary.rejected.toString()]: (state, { payload }) => {
      return { ...state, formState: rejectedState(payload?.message) };
    },
    [fetchOrganisationSummary.pending.toString()]: (state) => {
      return { ...state, formState: pendingState() };
    },
    //account setting
    [fetchAccountSetting.fulfilled.toString()]: (state, { payload }) => {
      return { ...state, organisation: payload, formState: fulfilledState() };
    },
    [fetchAccountSetting.rejected.toString()]: (state, { payload }) => {
      return { ...state, formState: rejectedState(payload?.message) };
    },
    [fetchAccountSetting.pending.toString()]: (state) => {
      return { ...state, formState: pendingState() };
    },
    // general setting
    [fetchGeneralSetting.fulfilled.toString()]: (state, { payload }) => {
      return { ...state, organisation: payload, formState: fulfilledState() };
    },
    [fetchGeneralSetting.rejected.toString()]: (state, { payload }) => {
      return { ...state, formState: rejectedState(payload?.message) };
    },
    [fetchGeneralSetting.pending.toString()]: (state) => {
      return { ...state, formState: pendingState() };
    },
    // [fetchResourcesCount.fulfilled.toString()]: (state, { payload }) => {
    //   return { ...state, locations: payload.locations, users: payload.users, devices: payload.devices };
    // },
    [fetchLocations.fulfilled.toString()]: (state, { payload }) => {
      return { ...state, locations: payload };
    },
    [fetchLocations.pending.toString()]: (state) => {
      return { ...state, locations: 0 };
    },
    [fetchLocations.rejected.toString()]: (state, { payload }) => {
      return { ...state, locations: 0 };
    },
    [fetchUsers.fulfilled.toString()]: (state, { payload }) => {
      return { ...state, users: payload };
    },
    [fetchUsers.pending.toString()]: (state) => {
      return { ...state, users: 0 };
    },
    [fetchUsers.rejected.toString()]: (state, { payload }) => {
      return { ...state, users: 0 };
    },
    [fetchDevices.fulfilled.toString()]: (state, { payload }) => {
      return { ...state, devices: payload };
    },
    [fetchDevices.pending.toString()]: (state) => {
      return { ...state, devices: 0 };
    },
    [fetchDevices.rejected.toString()]: (state, { payload }) => {
      return { ...state, devices: 0 };
    },
    [toggleStatus.fulfilled.toString()]: (state, { payload }) => {
      let result = {...state.organisation.result, isActive: payload.isActive};
      return { ...state, organisation: { ...state.organisation, result } };
    },
    [deleteOrganisation.fulfilled.toString()]: (state, { payload }) => {
      return { ...state, organisation: { ...state.organisation, payload } };
    },
    [addOrganisation.fulfilled.toString()]: (state, { payload }) => {
      if(payload?.data?.error && payload?.data?.display){
        return { ...state, formState: rejectedState(payload),addOrganisationformState: rejectedState(payload), errorMsg: payload?.data?.display};  
      }
      else {
        state.formState = fulfilledState()
        state.addOrganisationformState = fulfilledState()
        state.errorMsg = null
      }
      return state;
    },
    [addOrganisation.rejected.toString()]: (state, { payload }) => {
      return { ...state, formState: rejectedState(payload),addOrganisationformState: rejectedState(payload) };
    },
    [addOrganisation.pending.toString()]: (state) => {
      state.formState = pendingState();
      state.addOrganisationformState = pendingState();
    },
    [updateOrganisation.fulfilled.toString()]: (state, { payload }) => {
      if(payload?.data?.error && payload?.data?.display){
        return { ...state, formState: rejectedState(payload), errorMsg: payload?.data?.display, update: false};  
      }
      else{
        return { ...state, formState: fulfilledState(), update: true };
      }
    },
    [updateOrganisation.rejected.toString()]: (state, { payload }) => {
      return { ...state, formState: rejectedState(payload), update: false };
    },
    [updateOrganisation.pending.toString()]: (state, { payload }) => {
      return { ...state, formState: pendingState(), update: false };
    }
  },
});

export const { clearState, clearSummary, clearOrganisation, updateState,clearAddOrganisationState,saveSelectedOrganisationId } = OrganisationDetailsSlice.actions;
export const organisationDetailsSelector = (state: RootState) =>
  state.organisation;
export default OrganisationDetailsSlice.reducer;
