import styled from "styled-components";
import { getDeviceStateColor } from "../../../../../../utility/deviceUtils";

export const ProductName = styled.p`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${(props) => props.theme.fontSizes.h4};
  line-height: 20px;
  cursor: pointer;

  display: flex;
  align-items: center;

  margin: 0;
  color: ${(props) => props.theme.colors.palette.black};
`;

export const ProductNamePopover = styled.p`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-size: ${(props) => props.theme.fontSizes.smallBody};
  line-height: 20px;
  margin: 0;
  color: ${(props) => props.theme.colors.palette.black};
`;

export const ComponentDescription = styled.div`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${(props) => props.theme.fontSizes.smallBody};
  line-height: 18px;
  padding: 8px 0px;
  word-break: break-word;

  margin: 0;
  color: ${(props) => props.theme.colors.palette.black};
  p{
    margin: 0px;
  }
`;

export const ComponentDate = styled.p`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${(props) => props.theme.fontSizes.smallBody};
  line-height: 18px;

  margin: 0;
  color: ${(props) => props.theme.colors.palette.darkGray};
`;



export const DeviceErrorHeading = styled.p`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${(props) => props.theme.fontSizes.h5};
  line-height: 20px;

  display: flex;
  align-items: center;
  margin: 0;
  color: ${(props) => props.theme.colors.palette.red};
`;

export const DeviceErrorDescription = styled.p`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${(props) => props.theme.fontSizes.smallBody};
  line-height: 16px;

  display: flex;
  align-items: center;
  margin: 0;
  padding-top: 6px;
  color: ${(props) => props.theme.colors.palette.darkestGray};
`;

export const DeviceErrorOccurred = styled.p`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${(props) => props.theme.fontSizes.footnote};
  line-height: 16px;

  display: flex;
  align-items: center;
  text-align: right;
  margin: 0;  
  color: ${(props) => props.theme.colors.palette.darkGray};
`;

export const DeviceStatus = styled.span`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${(props) => props.theme.fontSizes.body};
  line-height: 18px;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration-line: underline;
    cursor: pointer;
  }
`

interface DeviceContProps {
  component : any
}
export const DeviceContainer = styled.div<DeviceContProps>`
   background: ${(props) => getDeviceStateColor(props.component)};
`