export const createHiddenElement = (
  id: string,
  value: string,
  parentSelector: string
): void => {
  var input = document.createElement("input");
  input.setAttribute("type", "hidden");
  input.setAttribute("id", id);
  input.setAttribute("name", id);
  input.setAttribute("value", value);

  //append to form element that you want .
  let selector = document.querySelector(parentSelector);
  if (selector) {
    selector.appendChild(input);
  }
};

export const getHiddenElementValue = (id: string): string | null => {
  var elem: any = document.getElementById(id);
  if (elem) {
    return elem.value;
  }
  return null;
};

export const removeElement = (id: string): void => {
  var elem: any = document.getElementById(id);
  if (elem) {
    elem.parentNode.removeChild(elem);
  }
};

export const setElemDisplay = (elem: any, display: string) => {
  if (elem) {
    elem.style.display = display;
  }
}

export const addClass = (selector: any, className: string) => {
  let elem = document.querySelector(selector);
  if (elem) {
    let css = elem.className;
    css = css.replace(className, "");
    css = `${css.trim()} ${className}`.trim();
    elem.className = css? css.trim(): css;
  }
};

export const removeClass = (selector: any, className: string) => {
  let elem = document.querySelector(selector);
  if (elem) {
    let css = elem.className;
    css = css.replace(className, "");
    elem.className = css ? css.trim() : css;
  }
};