import styled from "styled-components";

export const HeaderInputLabel = styled.div`
font-family: ${(props) => props.theme.fonts[0]};
font-style: normal;
font-weight: ${(props) => props.theme.fontWeights.normal};
font-size: ${(props) => props.theme.fontSizes.smallBody};
line-height: 20px;
margin-bottom: 5px;
color: ${(props) => props.theme.colors.text.primary};
`
export const HeaderDeleteImg = styled.img`
    // height: 13px;
    // width: 17px;
`
export const PageHeaderTitle = styled.div`
    margin-bottom: 19px;
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-size: ${(props) => props.theme.fontSizes.h1};
    line-height: 20px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.text.primary};
`