/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Tooltip } from "antd";
import { DeviceHeader, DeviceHealthBody } from "./index.styles";
import "./style.less";
import {
  getStatusTypeEllipse,
  onlyUnique,
  sortList,
} from "../../../../../../utility/utils";
import MultiSelectButton from "../../../../stateless/common/multiSelect/multiSelectButton/multiSelectButton";
import { locationListSelector } from "../../../../../../redux/reducers/location/locationListReducer";
import {
  DeviceSelector,
  setDeviceFilter,
} from "../../../../../../redux/reducers/device/deviceReducer";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import {
  deviceHealthSorting,
  jsonData,
  Status_Type,
} from "../../../../../constants/constants";
import { Dropdown } from "../../../../../../bit_components/common/dropdown";
import { useTranslation } from "react-i18next";
import { translateOptions } from "../../../../../../utility/appUtil";
import { UseGAEventTracker } from "../../../../../../utility/useGAEventTracker";
import { fetchDeviceListByOrgId } from "../../../../../../redux/actions/device/deviceAction";
import { userStateSelector} from "../../../../../../redux/reducers/user/userReducer";
import { cameFromOrganisation } from "../../../../../../utility/appUtil";
import { appSelector } from "../../../../../../redux/reducers/app/appReducers";


export const DeviceHealthHeader = (props: any) => {
  const { locations } = useSelector(locationListSelector);
  const { deviceTypeList, deviceStates, deviceFilter, deviceList } = useSelector(
    DeviceSelector
  );
  const { selectedOrganisation } = useSelector(appSelector);
  const { appUser } = useSelector(userStateSelector);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [uniqueDeviceStates, setUniqueDeviceStates] = useState([]);
  const [selectedDeviceStates, setSelectedDeviceStates] = useState([]);
  const [paramsUpdated, setParamsUpdated] = useState<boolean>(false);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<any>();
  const [selectedDevice, setSelectedDevice] = useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onChangeFilter = (value: string) => {
    const sortByDropdown : any = document.querySelector("#sortByDropDown");    
    setParamsUpdated(true);
    setSelectedDropdownValue(value);
    dispatch(setDeviceFilter({...deviceFilter, sortBy:value}));
    
    let sortValue = deviceHealthSorting.filter((item)=>item.value===value)
      
    if(sortValue && sortValue[0]){
      UseGAEventTracker("Device Health Category", "device health sort filter", `Selected ${t(sortValue[0].text, {lng:"en"})} sort`);
    }
    if(sortByDropdown){
      sortByDropdown?.focus();
    }
  }

  const getDeviceHealthStatus = (key: any) => {
    return Object.keys(Status_Type).map(function (key: any, index: number) {
      return (
        <DeviceHealthBody
          key={index}
          className="showColourCode deviceStatusInfo"
        >
          <img src={getStatusTypeEllipse(Status_Type[key])} alt="" />

          <span>{t(`${Status_Type[key].toLowerCase()}`)}</span>
        </DeviceHealthBody>
      );
    });
  };

  const getOrgId = () => {
    let orgId = appUser?.organization.id as string;
    if (cameFromOrganisation()) {
      orgId = selectedOrganisation?.id;
    }
    return orgId;
  }
  useEffect(()=>{
    dispatch(fetchDeviceListByOrgId({ orgId: getOrgId() }))
  },[])

  useEffect(() => {
    if (deviceFilter.sortBy) {
      setSelectedDropdownValue(deviceFilter.sortBy);
    }
    if (deviceFilter.deviceState) {
      setSelectedDeviceStates(deviceFilter.deviceState);
    }
    if (deviceFilter.deviceLocation) {
      setSelectedLocations(deviceFilter.deviceLocation);
    }
    if (deviceFilter.deviceProduct) {
      setSelectedProducts(deviceFilter.deviceProduct);
    }
    if (deviceFilter.selectedDevice) {
      setSelectedDevice(deviceFilter.selectedDevice)
    }
  }, [deviceFilter]);

  useEffect(() => {
    setUniqueDeviceStates(
      deviceStates
        ?.map((data: any) => data?.type?.toString().toLowerCase())
        ?.filter(onlyUnique)
    );
  }, [deviceStates]);

  useEffect(() => {
    if (paramsUpdated) {
      const params = {
        locations:
          selectedLocations?.length &&
          selectedLocations.length < locations.length
            ? selectedLocations
            : [],
        products:
          selectedProducts?.length &&
          selectedProducts.length < deviceTypeList?.length
            ? selectedProducts
            : [],
        states:
          selectedDeviceStates?.length &&
          selectedDeviceStates.length < uniqueDeviceStates?.length
            ? selectedDeviceStates
            : [],
        sortBy: selectedDropdownValue,
        devices:
        selectedDevice?.length &&
        selectedDevice.length < deviceList?.length
         ? selectedDevice
         : []
      };
      props?.onChange(params);
      setParamsUpdated(false);
    }
  }, [
    selectedLocations,
    selectedProducts,
    selectedDeviceStates,
    selectedDropdownValue,
    selectedDevice
  ]);

  /************* Location selector handling ************/

  const getLocations = () => {
    const arrLocation = locations.map((x: any) => {
      return {
        label: x.name,
        value: x.id.toString(),
      };
    });
    const sortedBylabel = arrLocation.sort(sortList("label"));
    return sortedBylabel;
  };

  const updateLocations = (selected) => {
    setParamsUpdated(true)
    setSelectedLocations(selected)
    dispatch((setDeviceFilter({ ...deviceFilter, deviceLocation: selected })))
  
    let locationFilterList = getLocations();
    let gaText = (!selected.length || selected.length === locationFilterList.length) ? "All locations" : `${selected.length} location`;
    UseGAEventTracker("Device Health Category", "device health location filter", `Selected ${gaText}`);
  }


  /************* Product( device type) selector handling ************/

  const getProducts = () => {
    const arrProducts = deviceTypeList.map((x: any) => {
      return {
        label: x.deviceType,
        value: x.deviceTypeId,
      };
    });
    const sortedBylabel = arrProducts.sort(sortList("label"));
    return sortedBylabel;
  };

  const updateProducts = (selected) => {
    setParamsUpdated(true);
    setSelectedProducts(selected);
    dispatch(setDeviceFilter({ ...deviceFilter, deviceProduct: selected }));

    let productFilterList = getProducts();
    let gaText = (!selected.length || selected.length === productFilterList.length) ? "All products" : `${selected.length} product`;
    UseGAEventTracker("Device Health Category", "device health product filter", `Selected ${gaText}`);
  }

  /************* Device selector handling ************/

  const getDeviceList = () =>{
    const arrDeviceName = deviceList.map((res:any)=>{
       return {
        label: res?.info?.name,
        value: res?.id.toString()
      }
    })
    const sortedBylabel = arrDeviceName.sort(sortList("label"));
    return sortedBylabel;
  }

  const updateDeviceList = (selected) => {    
    setParamsUpdated(true)
    setSelectedDevice(selected)
    dispatch((setDeviceFilter({ ...deviceFilter, selectedDevice:selected})))
    let deivceFilterList = getDeviceList();
    let gaText = (!selected.length || selected.length === deivceFilterList.length) ? "All Devices" : `${selected.length} device`;
    UseGAEventTracker("Device Health Category", "device name filter", `Selected ${gaText}`);    
  }


  /************* DeviceState selector handling ************/

  const getDeviceStates = () => {
    let originalArray = [...deviceStates];
    const sortByPriority = originalArray
      .sort(function (a, b) {
        return b.priority - a.priority;
      })
      .map((data: any) => data?.type?.toString().toLowerCase())
      ?.filter(onlyUnique);
    const arrStates = sortByPriority.map((value: any) => {
      return {
        label: value,
        value: value,
      };
    });

    return arrStates;
  };

  const updateStates = (selected) => {
    setParamsUpdated(true);
    setSelectedDeviceStates(selected);
    dispatch(setDeviceFilter({ ...deviceFilter, deviceState: selected }));


    let stateFilterList = getDeviceStates();
    let gaText = (!selected.length || selected.length === stateFilterList.length) ?  "All states" : `${selected.length} state`;
    UseGAEventTracker("Device Health Category", "device health state filter", `Selected ${gaText}`);
  }

  return (
    <>
      <Row className="device-health-header pl-vw">
      <Col tabIndex={0}  md={6} lg={4} className="deviceHealthScreen">
          <DeviceHeader>{t(jsonData.Devices)}</DeviceHeader>
          <Popover
            trigger={["click", "focus"]}
            placement="bottomLeft"
            content={getDeviceHealthStatus}
            getPopupContainer={(trigger: any) => trigger.parentNode}
          >
            <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.deviceStates)}>
              <InfoCircleOutlined tabIndex={0} className="informationIcon"></InfoCircleOutlined>
            </Tooltip>
          </Popover>
        </Col>
        <Col md={24} lg={20}>
          <Row className="health-dropdown-div" justify="end">
            <Col>
              <Row className="userFilter">
                <span className="eventFilterTab">
                  <Dropdown
                   tabIndex={0}
                   ariaLabel={t(jsonData.SortBy)}
                    dropdownAlign={{ offset: [-125, 4] }}
                    className="filterDropDown alertPageDropdown"
                    defaultValue={t(jsonData.SortBy)}
                    optionValue={translateOptions(
                      deviceHealthSorting,
                      t,
                      "text"
                    )}
                    id="sortByDropDown"
                    onChange={onChangeFilter}
                    bordered={false}
                    value={selectedDropdownValue}
                    optionClass="eventFilterBox"
                  />
                </span>
              </Row>
            </Col>
            <Col className="test345">
            <div>
              <MultiSelectButton
                optionList={translateOptions(getDeviceStates(), t, "label")}
                onApply={updateStates}
                selectedItems={selectedDeviceStates}
                allSelectedText={t(jsonData.AllStates)}
                name="state"
                placeholder={t(jsonData.SelectState)}
                optionListClass="state-optionList"
                wrapperClassName="state-multiselect"
                tabIndex={0}
                aria-label={t(jsonData.SelectState)} role={""}
              />
            </div>
            </Col>
            <Col>
            <div>
              <MultiSelectButton
                optionList={translateOptions(getProducts(), t, "label")}
                onApply={updateProducts}
                selectedItems={selectedProducts}
                allSelectedText={t(jsonData.AllProducts)}
                name="product"
                placeholder={t(jsonData.SelectProduct)}
                optionListClass="product-optionList"
                wrapperClassName="product-multiselect"
                tabIndex={0}
                aria-label={t(jsonData.SelectProduct)}
                role={""}
              />
              </div>
            </Col>
            <Col>
            <div>
              <MultiSelectButton
                optionList={translateOptions(getLocations(), t, "label")}
                onApply={updateLocations}
                selectedItems={selectedLocations}
                allSelectedText={t(jsonData.AllLocations)}
                name="location"
                placeholder={t(jsonData.SelectLocation)}
                optionListClass="location-optionList"
                wrapperClassName="location-multiselect device-location"
                tabIndex={0}
                aria-label={t(jsonData.SelectLocation)} 
                role={""}
                allowSearch={true}
              />
               </div>
            </Col>
            <Col>
            <div>
              <MultiSelectButton
                optionList={getDeviceList()}
                onApply={updateDeviceList}
                selectedItems={selectedDevice}
                allSelectedText={t(jsonData.SelectDevice)}
                name="device"
                placeholder={t(jsonData.SelectDevice)}
                optionListClass="device-optionList"
                wrapperClassName="device-multiselect"
                tabIndex={0}
                aria-label={t(jsonData.SelectDevice)} 
                role={""}
                allowSearch={true}
              />
               </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
