import React, {useEffect, useRef} from 'react';
import { Card, Row, Col,  } from 'antd';
import { ProfileThumbnailPicture } from '../user/profile/profileThumbnailPicture';
import { AvatarColorList, jsonData } from '../../../constants/constants';
import './organisationCard.less';
import { useHistory } from 'react-router';
import { AppRoutes } from '../../../constants/enums';
import { useDispatch } from 'react-redux';
import { getSelectedOrg } from '../../../../redux/actions/app/appAction';
import { saveSelectedOrganisationId } from '../../../../redux/reducers/organisation/organisationDetailsReducer';
import { useTranslation } from 'react-i18next';

const OrganisationCard = (props: any) => {
    const { t } = useTranslation()
    const history: any = useHistory();
    const dispatch: any = useDispatch();
    const cardRef = useRef(null);

    const goToOrganisationDetails: any = () => {
        dispatch(saveSelectedOrganisationId(props.organisation.id ))
        const location = {
            pathname: AppRoutes.ORGANISATION_DETAILS,
            // search: '?oid=' + props.organisation.id,
            state: { oid: props.organisation.id }
        };
        props.organisation.id !=='' && dispatch(getSelectedOrg(props.organisation.id))
        history.push(location);
    };

    const handleKeyPress = (e: any) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            const nextCard = cardRef.current.nextSibling;
            if (nextCard) {
                nextCard.focus();
            }
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            const prevCard = cardRef.current.previousSibling;
            if (prevCard) {
                prevCard.focus();
            }
        } else if(e.key === 'Enter') {
            goToOrganisationDetails()
        }

    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        // <Link key={props.organisation.id} to={{ pathname: '/organisation/organisationDetails', state: { organisationId: props.organisation.id } }}>
        <div onClick={goToOrganisationDetails} className="organisationListWrapper" tabIndex={0} aria-label={`${t(jsonData.orgName)} ${props.organisation.name}`} role={"link"} onKeyDown={handleKeyPress} onKeyPress={handleKeyPress} ref={cardRef}>
            <Card className="organisationListCard">
                <Row>
                    <Col span={3} lg={2} className="organisationLogoPic">
                        <ProfileThumbnailPicture src={props?.organisation?.logo} firstName={props.organisation.name.split(' ')?.[0] || ''} lastName={props.organisation.name.split(' ')?.[1] || ''} colors={AvatarColorList} round={true} size="35" />
                    </Col>
                    <Col span={6} lg={6} className="organisationShortName">
                        <div className="orgShortNameContentSlicing">{props.organisation.shortName}</div>
                    </Col>
                    <Col span={7} lg={7} className="organisationName">
                        <div className="orgNameContentSlicing">{props.organisation.name}</div>
                    </Col>
                    <Col span={8} lg={9} className="organisationCountry">
                        <div className="orgCountryContentSlicing">{props.organisation.county}, {props.organisation.country}</div>
                    </Col>
                </Row>
            </Card>
        </div>
        // </Link>
    );
};

export default OrganisationCard;
