import React, { useState, useEffect } from "react";
import "./multiSelectButton.less";
import { useTranslation } from "react-i18next";
import { Input, Menu } from "antd";
import { MultiSelectCheckBox, MultiSelectLabel, MultiSelectMainLabel, MultiSelectWrapper } from "../multiSelect/multiSelectButton/multiSelectButton.style";
import { UpDownImg } from "../../../../images";
import { jsonData } from "../../../../constants/constants";
import Button from "../button";
import { Checkbox } from "../../../../../bit_components/common/checkbox";
import { FilterFilled } from '@ant-design/icons';


interface MultiSelectButtonProps {
  allSelectedText: string;
  placeholder: string;
  optionList: any[];
  selectedData: any;
  optionListClass: string;
  wrapperClassName: string;
  selectedItems: any;
  disabled?: boolean;
  tabIndex?: any;
  coulmnName?:string;
  filterName?:string;
  allowSearch?:boolean;
}

const CustomFilter = (props: MultiSelectButtonProps) => {
  const {
    allSelectedText,
    placeholder,
    optionList,
    selectedData,
    optionListClass,
    wrapperClassName,
    selectedItems,
    disabled,
    tabIndex,
    coulmnName,
    filterName,
    allowSearch
  } = props;

  const [tempList, setTempList] = useState([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [showDropDown, SetShowDropDown] = useState<boolean>(false);
  const [resetClicked, setResetClicked] = useState<boolean>(false); 
  const [searchText, setSearchText] = useState(""); 
  const [filteredItems, setFilteredItems] = useState(optionList); 
  const [focusClass,setFocusClass] = useState<string>("")

  const { t } = useTranslation();

  const updateStates = (selected:any) => {
    var x = {[`${coulmnName}`]:selected}
    selectedData(x,coulmnName,true)
    hideDropDownOnOk();
    hideDropDown();
  };

  const customValueRenderer = (selected, _options) => {
    return selected?.length === _options?.length && selected?.length > 0
      ? allSelectedText
      : selected?.length
      ? `${selected?.length} ${t(coulmnName)}`
      : placeholder;
  };

  const onSelect = (value: any) => {
    if (!value.target.checked) {
      setTempList(
        tempList?.filter(
          (x: any) => x?.toString() !== value.target.value?.toString()
        )
      );
    } else {
      setTempList([
        ...tempList,
        ...optionList
          ?.filter(
            (x: any) => x.value?.toString() === value.target.value?.toString()
          )
          .map((item) => {
            return item.value;
          }),
      ]);
    }
  };
  const onSelectAll = () => {
    if (selectAll !== null) {
      setSelectAll(!selectAll);
      if (!selectAll === true) {
        setTempList(optionList.map((x: any) => x?.value));
      } else {
        setTempList([]);
      }
    } else {
      setSelectAll(true);
      setTempList(optionList.map((x: any) => x?.value));
    }
  };

  useEffect(() => {
    if (tempList?.length === optionList?.length && optionList?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(null);
    }
  }, [tempList]);

  const onBack = () => {
    setTempList(selectedItems);
  };

  useEffect(() => {
    setTempList(selectedItems);
  }, [selectedItems]);

  const adjustDropdown = () => {
    const containers: any = document.querySelectorAll(".ant-table-body");

    let container = null;

    if (containers && containers.length > 0) {
      containers.forEach((item: any) => {
        if (item.getBoundingClientRect().right) {
          container = item;
        }
      });
    }

    let button: any = document.getElementById(
      `filterIcon-${optionListClass}-${coulmnName}`
    );

    if (button && container) {
      const buttonRect = button.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      let rightPosition = Math.abs(
        buttonRect.right + window.scrollX - containerRect.right
      );

      let dropDown: any = document.getElementById(
        `panel-content-${optionListClass}-${coulmnName}`
      );
      if (dropDown) {
        let modalElements: any = document.querySelectorAll(
          ".ant-modal-content"
        );

        if (modalElements && modalElements.length > 0) {
          modalElements.forEach((modal) => {
            if (modal.offsetWidth) {
              let totalWidth = window.innerWidth;
              rightPosition =
                rightPosition + (totalWidth - modal.offsetWidth) / 2;
            }
          });
        }

        dropDown.style.right = `${rightPosition + 40}px`;
        dropDown.style.display = `block`;
      }
    }
  };

  const removeClass = (event: any) => {
    let dropdown = document.querySelector<HTMLElement>(`.${coulmnName}`);
    let dropdownHeading = document.querySelector<HTMLElement>(
      `.heading-${coulmnName}`
    );
    if (
      !dropdown?.contains(event.target) ||
      ((event.target.classList.contains("applyFilter") ||
        event.target.classList.contains("resetFilter")) &&
        !event.target.classList.contains("disableFilter")) ||
      dropdownHeading?.contains(event.target)
    ) {
      let checkboxes = document.querySelector<HTMLElement>(
        `.${optionListClass}-${coulmnName}`
      );
      if (checkboxes?.classList.contains("show-multiSelect-dropdown")) {
        document.body.removeEventListener("mouseup", removeClass);
        SetShowDropDown(false);
        !dropdownHeading?.contains(event.target) &&
          checkboxes.classList.remove("show-multiSelect-dropdown");
        if (!dropdown?.contains(event.target)) {
          onBack();
        }
      }
    }
  };
  const showDropdown = () => {
    setSearchText("");
    setFilteredItems(optionList);
    
    setTimeout(() => {
    let checkboxes = document?.querySelector<HTMLElement>(
      `.${optionListClass}-${coulmnName}`
    );
    if (!checkboxes?.classList.contains("show-multiSelect-dropdown")) {
      checkboxes.classList.add("show-multiSelect-dropdown");
      document.body.addEventListener("mouseup", removeClass);
      SetShowDropDown(true);
      adjustDropdown();
    } else {
      checkboxes.classList.remove("show-multiSelect-dropdown");
      SetShowDropDown(false);
      onBack();
    }
    }, 100);
  };

  const hideDropDownOnOk = () => { 
    let checkboxes = document.querySelector<HTMLElement>(
      `.${optionListClass}`
    );
    let multiSelect = document.querySelector<HTMLElement>(
      `.${optionListClass}`
    );
    if (checkboxes) {
      checkboxes.classList.remove("show-multiSelect-dropdown");
      SetShowDropDown(false);
      onBack();
      multiSelect?.focus();
    }
  };

  const hideDropDown = () => { 
    let checkboxes = document.querySelector<HTMLElement>(
      `.${optionListClass}-${coulmnName}`
    );
    let multiSelect = document.querySelector<HTMLElement>(
      `.${optionListClass}`
    );
    if (checkboxes) {
      checkboxes.classList.remove("show-multiSelect-dropdown");
      SetShowDropDown(false);
      onBack();
      multiSelect?.focus();
    }
  };

  const onEnterShowDropDown = (e: any) => {
    if(e?.keyCode === 27){ 
      hideDropDown();
    }
    if (e?.keyCode === 13 && !showDropDown && !resetClicked) {
      showDropdown(); 
    }
    setResetClicked(false) 
  };

  const onEnterSelect = (e: any, byClass: string) => {
    if (e?.keyCode === 13) {
      if (byClass === "dropDownMenuList-all") {
        onSelectAll();
      } else {
        const menu = document?.querySelector<HTMLElement>(`.${optionListClass} .${byClass}`);
        if (menu) {
          menu.click();
        }
      }
    } else if (e?.keyCode === 9) {
      const menu = document?.querySelector<HTMLElement>(
        `.${optionListClass} .multiselect-footer .resetFilter`
      );
      if (menu) {
        menu.focus();
      }
    }
  };

  const jumpToBtns =(e:any)=>{
    if (e?.keyCode === 9) {
        const menu = document?.querySelector<HTMLElement>(
          `.${optionListClass} .multiselect-footer .resetFilter`
        );
        if (menu) {
          menu.focus();
        }
      }
  }

  const closeAll =(e:any)=>{
    if(!e?.shiftKey && e?.keyCode === 9){
      hideDropDown();
      hideDropDownOnOk();
    }
  }

  const reSet =()=>{
    setResetClicked(true); 
    var x = {[`${coulmnName}`]:[]}
    selectedData(x,coulmnName,true)
    hideDropDown(); 
  }

  const searchFromFilter = (event: any) => {
    let inputText = event.target.value;
    setSearchText(inputText);
    const res = optionList?.filter((option) =>
      option?.text?.toLowerCase().includes(inputText?.toLowerCase())
    );
    setFilteredItems(res);
  };

  const handleClick = (e: any, clearIcon: any) => {
    if (e?.keyCode === 13) {
      clearIcon.click();
    }
    clearIcon.removeEventListener("keyup", (e) => handleClick);
  };

  useEffect(() => {
    const clearIcons = document.querySelectorAll(
      '[role="button"][aria-label="close-circle"]'
    );
    clearIcons?.forEach((clearIcon) => {
      clearIcon?.setAttribute("tabindex", "0");
      clearIcon?.setAttribute("aria-label", "clear");
      clearIcon?.addEventListener("keyup", (e) => handleClick(e, clearIcon));
    });
  }, []);

  useEffect(() => {
    setFilteredItems(optionList);
  }, [optionList]);

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  }

  return (
    <div className="custom-table-filter">
    <fieldset className={`${optionListClass} custom-table-filter`} tabIndex={tabIndex} onKeyUp={onEnterShowDropDown} >
      <MultiSelectWrapper className={ `${wrapperClassName} filter-icon-container`}>
        <div className={`multiselectButton ${coulmnName}`}>
          <div className="dropdown-container">
            <div
              className={`dropdown-heading heading-${coulmnName} ${
                disabled ? "disable-pointer" : ""
              }`}
              onClick={showDropdown}
              id={`filterIcon-${optionListClass}-${coulmnName}`}
              tabIndex={0}
            >
              <div className="dropdown-heading-value">
                {selectedItems?.length > 0 ? (
                  <FilterFilled className="filled-filter-icon"/>
                ) : (
                  <FilterFilled aria-label={`${filterName}`} className="filter-icon"/>
                )}
              </div>
            </div>
            <div className={`dropdown-content ${optionListClass}-${coulmnName}`}>
              <div className="panel-content ant-table-filter-dropdown display-none" id={`panel-content-${optionListClass}-${coulmnName}`}>
                {allowSearch && (
                  <>
                    <div className="filter-search-container">
                      <Input
                        name="search"
                        className={`${focusClass} filter-search-box`}
                        value={searchText}
                        onChange={searchFromFilter}
                        tabIndex={0}
                        aria-label={""}
                        role={""}
                        placeholder={t(jsonData.inputSearchText)}
                        onFocus={() => setFocusClass("eventSearchBar")}
                        onBlur={() => setFocusClass("")}
                        allowClear
                      />
                    </div>
                    {searchText && !filteredItems?.length && (
                      <p
                        className="not-found-text"
                      >
                        {t(jsonData.NoDataFound)}
                      </p>
                    )}
                  </>
                )}
                <div className="select-panel">
                  <Menu
                    onKeyUp={jumpToBtns}
                    className={`adjust-height multiselect-options ${
                      optionList?.length
                        ? "multiselect-options"
                        : "noContentFound"
                    } focusTheDropDown`}
                    tabIndex={-1}
                  >
                    {/* {optionList?.length > 1 ? (
                      <Menu.Item
                        onKeyUp={(e: any) =>
                          onEnterSelect(e, `dropDownMenuList-all`)
                        }
                        className="options"
                      >
                        <label className="select-item">
                          <div className="item-renderer">
                            <MultiSelectCheckBox>
                              <Checkbox
                                value={"all"}
                                name={"All"}
                                onChange={onSelectAll}
                                checked={selectAll}
                                id={`chkLocation-${name}`}
                                key={`chkLocation-${name}`}
                              />
                            </MultiSelectCheckBox>
                            <MultiSelectLabel className="checkboxLabel">
                              {t(jsonData.All)}
                            </MultiSelectLabel>
                          </div>
                        </label>
                      </Menu.Item>
                    ) : null} */}
                   
                    
                    {filteredItems?.length ? (
                      filteredItems.map((option: any, index: number) => {
                        return (
                          <Menu.Item
                            onKeyUp={(e: any) =>
                              onEnterSelect(e, `dropDownMenuList-${index}`)
                            }
                            className="menuitem-options"
                            key={index}
                          >
                            <label className="select-item">
                              <div className="item-renderer">
                                <MultiSelectCheckBox>
                                  <Checkbox
                                    value={option.value}
                                    name={option.label}
                                    onChange={onSelect}
                                    checked={
                                      tempList?.filter(
                                        (x: any) =>
                                          x?.toString() ===
                                          option.value?.toString()
                                      ).length
                                        ? true
                                        : false
                                    }
                                    id={`chkLocation-${option.value}`}
                                    key={`chkLocation-${option.value}`}
                                    className={`dropDownMenuList-${index}`}
                                  />
                                </MultiSelectCheckBox>
                                <MultiSelectLabel className="checkboxLabel ellipsis">
                                  {option.text}
                                </MultiSelectLabel>
                              </div>
                            </label>
                          </Menu.Item>

                        );
                      })
                     
                      
                    ) 
                    : (
                      <>
                        {!searchText && (
                          <span>{t(jsonData.NoDataFound)}</span>
                        )}
                      </>
                    )}
                    
                    <Menu.Item
                      tabIndex={0}
                      aria-label={""} 
                      role={""}
                      className="custom-multiselect-footer">
                       <Button
                         type={`primary resetFilter ${
                           tempList?.length ? "multiselect-btn" : "disableFilter"
                         }`}
                         htmlType="submit"
                         disabled={!tempList?.length}
                         onClick={reSet}
                        //  onKeyUp={jumpToNext} 
                         tabIndex={tabIndex}
                         aria-label={""} 
                        //  onKeyPress={reSet} 
                       >
                         {t(jsonData.Reset)}
                       </Button>
                       <Button
                         type={"primary applyFilter multiselect-btn"}
                         htmlType="submit"
                         onKeyUp={stopPropagation} 
                         // disabled={!tempList?.length}
                         onClick={() => updateStates(tempList)}
                         tabIndex={tabIndex}
                         aria-label={""} 
                         onKeyDown={closeAll}
                       >
                         {t(jsonData.Ok)}
                       </Button>
                     </Menu.Item>
                  </Menu>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </MultiSelectWrapper>
    </fieldset>
    </div>
  );
};

export default CustomFilter;
