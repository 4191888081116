/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { sendForgotPwdLink } from "../../../../../../redux/actions/user/forgotPwdAction";
import { clearAuthInput, clearForgotPwdRequest, clearState, userStateSelector } from "../../../../../../redux/reducers/user/userReducer";
import { urlModification } from "../../../../../../utility/appUtil";
import { UserAuthInput } from "../../../../../models/user/userAuthInput";
import { AppRoutes } from "../../../../../constants/enums";
import { AppService } from "../../../../../services/ui/appService";
import { ForgotPwdForm } from "../../../../stateless/user/auth/forgotPwdForm";
import { jsonData } from "../../../../../constants/constants";
import { useTranslation } from 'react-i18next';

export const ForgotPwd = (props: any) => {
  const { t } = useTranslation()
  const history = useHistory();
  const dispatch = useDispatch();
  const { formState, appUser, forgotPwdRequest } = useSelector(userStateSelector);
  const layout = {
    labelCol: {
      span: 24,
      // offset: 2,
    },
    wrapperCol: {
      span: 24,
    },
  };

  useEffect(() => {
    dispatch(clearForgotPwdRequest());
    return () => {
      dispatch(clearState());
      dispatch(clearForgotPwdRequest());
    }
  }, []);

  useEffect(() => {
    if (formState.isError) {
    } else if (formState.isSuccess && !forgotPwdRequest?.errorMsg) {
      //debugger;

      clearState();
      clearAuthInput();
      AppService.showToast(t(jsonData.RUN_NOW))
      if (forgotPwdRequest && forgotPwdRequest.id !=='') {
        // Display message
        history.push(urlModification(AppRoutes.LOGIN));
      }
    }

  }, [formState.isError, formState.isSuccess]);

  const onGoBackCallback = () => {
    history.goBack();
  };

  const onSendResetPwdLink = (userAuth: UserAuthInput) => {
    dispatch(sendForgotPwdLink(userAuth));
  }

  return (
    <ForgotPwdForm formLayout={layout} errorMsg={forgotPwdRequest?.errorMsg} email={appUser?.email} sendResetPwdLink={onSendResetPwdLink} goBackCallback={onGoBackCallback}></ForgotPwdForm>
  );
}