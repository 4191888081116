import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeviceSelector,
  clearDevicesStatus,
} from "../../../../../../../redux/reducers/device/deviceReducer";
import {
  FORBIDDEN_STATUS,
  jsonData,
  severityList,
} from "../../../../../../constants/constants";
import { Row, Col, Checkbox, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { DataDisplayed } from "../../../../eventLogs/eventHeader/index.styles";
import { AntdInfinityTable } from "../../../../../stateless/common/antdTable/antdInfininityTable";
import CustomFilter from "../../../../../stateless/common/customTableFilter";
import { eventLogListSelector } from "../../../../../../../redux/reducers/eventLogs/eventLogListReducer";
import {
  getStatusTypeEllipse,
  getTableFilterData,
  handleEnterKeyPress,
  onlyUnique,
  prepareFilterList,
  sortList,
} from "../../../../../../../utility/utils";
import { locationListSelector } from "../../../../../../../redux/reducers/location/locationListReducer";
import "./styles.less";
import { warningIcon } from "../../../../../../images";
import { getDevicesStatus } from "../../../../../../../redux/actions/device/deviceAction";
import { userStateSelector } from "../../../../../../../redux/reducers/user/userReducer";

const DevicesList = (props: any) => {
  const { deviceId, checkedList, setCheckedList, isCopyModal } = props;
  const {
    configDevices,
    devicesHealth,
    deviceList,
    deviceListFilter,
    deviceStatusMap,
    devicesStatusFormState
  } = useSelector(DeviceSelector);
  const { eventsInfo } = useSelector(eventLogListSelector);
  const { locations } = useSelector(locationListSelector);
  const { appUser } = useSelector(userStateSelector);

  const masterDeviceId = deviceId;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [severityFilter, setSeverityFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [deviceFilter, setDeviceFilter] = useState([]);
  const [deviceTypeFilter, setDeviceTypeFilter] = useState();
  const [unfilteredData, setUnfilteredData] = useState([] as any);
  const [tableData, setTableData] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [selectAll, setSelectAll] = useState(false);

  const masterDevice = tableData[0];

  const addPrefix = (arr: any[]) => {
    return arr.map((obj) => {
      let prefix = isCopyModal ? "copy-" : "save-";
      let newObj = {};
      for (let key in obj) {
        newObj[prefix + key] = obj[key];
      }
      return newObj;
    });
  };

  const removePrefix = (arr) => {
    return arr.map((obj) => {
      let newObj = {};
      for (let key in obj) {
        let newKey = key.replace(/^copy-|^save-/, "");
        newObj[newKey] = obj[key];
      }
      return newObj;
    });
  };

  const onSelect = (event: any, checked: boolean, DeviceId: any) => {
    if (event.key === "Enter") {
      return event.target.click();
    }

    if (!checked) {
      setCheckedList(
        checkedList?.filter(
          (item: any) => item?.toString() !== DeviceId?.toString()
        )
      );
    } else {
      setCheckedList([...checkedList, DeviceId]);
    }
  };

  const getSelectableList = () => {
    let list = [...tableData].slice(1);

    let selectableList = list
      .filter((item: any) => !isSelectionDisabled(item).isDisabled)
      .map((item: any) => item.DeviceId);

    return selectableList;
  };

  const handleSelectAll = () => {
    setSelectAll((prev) => !prev);
    if (selectAll) {
      setCheckedList(isCopyModal ? [] : [masterDeviceId]);
    } else {
      let list = getSelectableList();
      setCheckedList(isCopyModal ? [...list] : [masterDeviceId, ...list]);
    }
  };

  const replaceEmptyKey = (obj: any) => {
    const inputObject = obj;
    for (const key in inputObject) {
      if (Array.isArray(inputObject[key]) && inputObject[key].length === 0) {
        inputObject[key] = null;
      }
    }
    return inputObject;
  };

  const applyFilter = (filters: any) => {
    getTableFilterData(filters, unfilteredData, unfilteredData)
      .then((tableFilterData: any) => {
        setTableData(removePrefix(tableFilterData));
      })
      .catch((err) => {
        console.log("Failed to get Filtered data ", err);
      });
  };

  const selectedDeviceStateUpdate = (data: any, key: any) => {
    let updatedDeviceStates = { ...filteredInfo };
    if (updatedDeviceStates?.hasOwnProperty(key)) {
      updatedDeviceStates = { ...updatedDeviceStates, [`${key}`]: data[key] };
    } else {
      updatedDeviceStates = { ...updatedDeviceStates, ...data };
    }

    const filters = replaceEmptyKey(updatedDeviceStates);
    applyFilter(filters);
    setFilteredInfo(filters);
  };

  function moveMasterToFirst(data: any[]) {
    if (data.length > 0) {
      data?.sort((a, b) => a?.DeviceName.localeCompare(b?.DeviceName));
      const index = data?.findIndex((obj) => obj.DeviceId === masterDeviceId);
        if (index !== -1 && index !== 0) {
        const objectToMove = data.splice(index, 1)[0];
        data?.unshift(objectToMove);
      }
    }
    return data;
  }

  const checkVersionMismatch = (device: any) => {
    let isMajorDifference = false;

    const appToIgnore = "smartadmin client";
    const currentDeviceApps = device.Applications;
    const masterDeviceApps = masterDevice.Applications;

    let result = currentDeviceApps.map((currentApp: any) => {
      if (currentApp.Name === appToIgnore) {
        return null;
      }

      let masterApp = masterDeviceApps.find(
        (item: any) => item.Name?.toLowerCase() === currentApp.Name?.toLowerCase()
      );

      if (masterApp) {
        let currentMajorVersion = currentApp.Version.split(".")[0];
        let masterMajorVersion = masterApp.Version.split(".")[0];

        if (currentMajorVersion === masterMajorVersion) {
          return false;
        } else {
          return true;
        }
      }
    });

    if (result.includes(true)) {
      isMajorDifference = true;
    }

    return isMajorDifference;
  }

  const isSelectionDisabled = (device: any) => {
    const currentDeviceStatus = deviceStatusMap[device.DeviceId];

    const isMaster = device?.DeviceId === masterDeviceId;
    const isForbidden = FORBIDDEN_STATUS.includes(device?.Severity);
    const isMajorDifference = checkVersionMismatch(device);
    const isLockedByUser = currentDeviceStatus?.LockState.Locked;
    const isWorkItemProcessing = currentDeviceStatus?.ProcessingWorkItem;

    let warningText = "";

    if (isMaster) {
      warningText = isCopyModal ? t(jsonData.MasterDeviceCopyWarnMsg) : t(jsonData.MasterDeviceSaveWarnMsg);
    } else {
        if (isForbidden) {
          warningText = t(jsonData.ForbiddenStatusWarnMessage);
        }
        if (isMajorDifference) {
          warningText = warningText + " " + t(jsonData.SoftwareCheckMessage);
        }
        if (isLockedByUser) {
          warningText =
            warningText +
            " " +
            t(jsonData.DeviceLocked) +
            " " +
            currentDeviceStatus.LockState.LockedByUsername;
        }
        if (isWorkItemProcessing) {
          warningText = warningText + " " + t(jsonData.ConfigurationInProgress);
        }
    }

    let isDisabled =
      isMaster ||
      isForbidden ||
      isMajorDifference ||
      isLockedByUser ||
      isWorkItemProcessing;
    return {
      isDisabled: isDisabled,
      warningText: warningText,
    };
  };

  useEffect(() => {
    if (configDevices?.length > 0) {
      const deviceIds = configDevices.map((item: any) => {
        return item.DeviceId;
      });
  
      dispatch(
        getDevicesStatus({
          userName: appUser?.userName,
          deviceIdList: deviceIds,
        })
      );
    }

    return () => {
      dispatch(clearDevicesStatus());
      setCheckedList(isCopyModal ? [] : [masterDeviceId]);
    } 
  }, []);

  useEffect(() => {
    const statusList = eventsInfo?.map((data: any) =>
      data.severity.toString().toLowerCase()
    );
    setSeverityFilter(prepareFilterList(statusList?.filter(onlyUnique)));
  }, [eventsInfo]);

  useEffect(() => {
    const getLocations = (list: any) => {
      const arrLocation = list?.map((item: any) => {
        return {
          text: item.name,
          value: item.name,
        };
      });
      const sortedBylabel = arrLocation.sort(sortList("text"));
      return sortedBylabel;
    };

    setLocationFilter(getLocations(locations));
  }, [locations]);

  useEffect(() => {
    const getDeviceList = (list: any) => {
      const arrDevices = list?.map((item: any) => {
        return {
          text: item.info.name ?? item.serialNo ?? item.id,
          value: item.info.name ?? item.serialNo ?? item.id,
        };
      });
      const sortedBylabel = arrDevices.sort(sortList("text"));
      return sortedBylabel;
    };

    setDeviceFilter(getDeviceList(deviceList));
  }, [deviceList]);

  useEffect(() => {
    let productList = deviceListFilter?.rows?.filter(
      (element: any, index: number) =>
        deviceListFilter?.rows?.findIndex(
          (obj: any) => obj.deviceType === element.deviceType
        ) === index
    );

    const prepareDeviceTypeFilter = (list: any) => {
      const textValueList = list?.map((item: any) => {
        return {
          text: item.deviceType,
          value: item.deviceType,
        };
      });
      const sortedBylabel = textValueList.sort(sortList("text"));
      return sortedBylabel;
    };
    let list = prepareDeviceTypeFilter(productList);
    setDeviceTypeFilter(list);
  }, [deviceListFilter]);

  useEffect(() => {
    function getSeverityByDeviceId(deviceId: string, allDevices: any) {
      const device = allDevices?.find(
        (device: any) => device.lcDeviceId === deviceId
      );
      if (device) {
        return device.statusType;
      }
      return null;
    }

    if (configDevices) {
      let allowedDevices = [];

      for (let i = 0; i < configDevices.length; i++) {
        let severity = getSeverityByDeviceId(
          configDevices[i].DeviceId,
          devicesHealth
        );

        if (severity) {
          allowedDevices.push({
            ...configDevices[i],
            Severity: severity,
          });
        }
      }

      setTableData(allowedDevices);
      setUnfilteredData(addPrefix(allowedDevices));
    }
  }, [configDevices, severityFilter]);

  useEffect(() => {
    const selectableListLength = getSelectableList()?.length || 0;
    const isAllChecked =
      checkedList?.length >= 1 &&
      checkedList.length === selectableListLength + (isCopyModal ? 0 : 1);
    setSelectAll(isAllChecked);
  }, [checkedList, isCopyModal, tableData]);

  const configDeviceColumns = () => {
    let columns = [
      {
        title: (
          <div className="justify-center checkbox">
            <Checkbox
              checked={selectAll}
              onChange={handleSelectAll}
              onKeyDown={(e: any) => handleEnterKeyPress(e, handleSelectAll)}
              disabled={getSelectableList()?.length === 0}
            />
          </div>
        ),
        ellipsis: true,
        render: (data: any) => {
          return (
            <div className="justify-center checkbox">
              <Tooltip
                mouseLeaveDelay={0}
                overlayClassName="dashBoardTooltip"
                placement="bottom"
                title={isSelectionDisabled(data).warningText}
              >
                <Checkbox
                  checked={checkedList.includes(data.DeviceId)}
                  onChange={(e) =>
                    onSelect(
                      e,
                      !checkedList.includes(data.DeviceId),
                      data.DeviceId
                    )
                  }
                  onKeyDown={(e) =>
                    handleEnterKeyPress(e, () =>
                      onSelect(
                        e,
                        !checkedList.includes(data.DeviceId),
                        data.DeviceId
                      )
                    )
                  }
                  disabled={isSelectionDisabled(data).isDisabled}
                />
              </Tooltip>
            </div>
          );
        },
      },
      {
        title: (
          <div>
            {t(jsonData.Status)}
            <CustomFilter
              optionList={severityList}
              selectedData={selectedDeviceStateUpdate}
              selectedItems={
                filteredInfo?.[`${isCopyModal ? "copy" : "save"}-Severity`] ??
                []
              }
              allSelectedText={t(jsonData.AllStates)}
              placeholder={t(jsonData.SelectState)}
              optionListClass={`${
                isCopyModal ? "copy" : "save"
              }-Severity-optionList`}
              wrapperClassName="state-multiselect"
              coulmnName={`${isCopyModal ? "copy" : "save"}-Severity`}
              filterName={t(jsonData.Severity)}
            />
          </div>
        ),
        key: "Severity",
        dataIndex: "Severity",
        ellipsis: true,
        render: (severity: string) => {
          return (
            <span>
              <img
                src={getStatusTypeEllipse(severity?.toString().toLowerCase())}
                alt=""
              />
              &nbsp;{severity}
            </span>
          );
        },
      },
      {
        title: (
          <div>
            {t(jsonData.Location)}
            <CustomFilter
              optionList={locationFilter}
              selectedData={selectedDeviceStateUpdate}
              selectedItems={
                filteredInfo?.[
                  `${isCopyModal ? "copy" : "save"}-LocationFullName`
                ] ?? []
              }
              allSelectedText={t(jsonData.AllStates)}
              placeholder={t(jsonData.SelectState)}
              optionListClass={`${
                isCopyModal ? "copy" : "save"
              }-location-optionList`}
              wrapperClassName="state-multiselect"
              coulmnName={`${isCopyModal ? "copy" : "save"}-LocationFullName`}
              filterName={t(jsonData.locationFilter)}
              allowSearch={true}
            />
          </div>
        ),
        key: "LocationFullName",
        dataIndex: "LocationFullName",
        ellipsis: true,
      },
      {
        title: (
          <div>
            {t(jsonData.DeviceName)}
            <CustomFilter
              optionList={deviceFilter}
              selectedData={selectedDeviceStateUpdate}
              selectedItems={
                filteredInfo?.[`${isCopyModal ? "copy" : "save"}-DeviceName`] ??
                []
              }
              allSelectedText={t(jsonData.AllStates)}
              placeholder={t(jsonData.SelectState)}
              optionListClass={`${
                isCopyModal ? "copy" : "save"
              }-device-name-optionList`}
              wrapperClassName="state-multiselect"
              coulmnName={`${isCopyModal ? "copy" : "save"}-DeviceName`}
              filterName={t(jsonData.deviceNameFilter)}
              allowSearch={true}
            />
          </div>
        ),
        key: "DeviceName",
        dataIndex: "DeviceName",
        ellipsis: true,
      },
      {
        title: (
          <div>
            {t(jsonData.DeviceType)}
            <CustomFilter
              optionList={deviceTypeFilter}
              selectedData={selectedDeviceStateUpdate}
              selectedItems={
                filteredInfo?.[
                  `${isCopyModal ? "copy" : "save"}-DeviceTypeName`
                ] ?? []
              }
              allSelectedText={t(jsonData.AllStates)}
              placeholder={t(jsonData.SelectState)}
              optionListClass={`${
                isCopyModal ? "copy" : "save"
              }-device-type-optionList`}
              wrapperClassName="state-multiselect"
              coulmnName={`${isCopyModal ? "copy" : "save"}-DeviceTypeName`}
              filterName={t(jsonData.deviceTypeFilter)}
            />
          </div>
        ),
        key: "DeviceTypeName",
        dataIndex: "DeviceTypeName",
        ellipsis: true,
      },
      {
        key: `${t(jsonData.information)}`,
        ellipsis: true,
        render(data: any) {
          return checkVersionMismatch(data) && (
            <Tooltip
            trigger={["focus", "hover"]}
              mouseLeaveDelay={0}
              overlayClassName="dashBoardTooltip warning-tooltip"
              placement="bottom"
              title={
                <>
                  <span className="warning-info">
                    {t(jsonData.ReviewVersionMessage)}
                  </span>
                  <br />
                  <span className="warning-info">
                    {t(jsonData.ProductsInstalledAre)}
                  </span>
                  <div className="product-version-container">
                    {data?.Applications?.map((item) => {
                      return (
                        <div className="product-version">
                          {item.Product.Name} - {item.Version}
                        </div>
                      );
                    })}
                  </div>
                </>
              }
            >
              <div className="device-list-warn-icon">
                <img
                  src={warningIcon}
                  alt="divice list warn icon"
                  tabIndex={0}
                />
              </div>
            </Tooltip>
          );
        },
      },
    ];

    return columns;
  };

  return (
    <div className="device-list-container">
      <Row className="device-popup-header">
        <Col className="device-popup-heading">
          <div>
         { isCopyModal ? t(jsonData.CopyToDevices) : t(jsonData.StoreToDevices)}
          </div>
        </Col>
        <Col span={24} className="device-popup-subHeader">
          <DataDisplayed>
            {t(jsonData.NumberOfDevices)}: {tableData?.length}
          </DataDisplayed>
        </Col>
      </Row>
      <AntdInfinityTable
        tableClassName="device-config-table"
        dataSource={devicesStatusFormState?.loading ? [] : moveMasterToFirst(tableData)}
        scroll={{
          y: 250,
          scrollToFirstRowOnChange: true,
        }}
        locale={!tableData?.length && { emptyText: t(jsonData.NoDataFound) }}
        columns={configDeviceColumns()}
        pagination={false}
      />
      {devicesStatusFormState?.loading &&
        <div className="loaderWrapper">
          <div className="loaderBody">
            <span className="spinner"></span>
            <span className="loadingText">{t(jsonData.Loading)}.</span>
          </div>
        </div>
      }
    </div>
  );
};

export default DevicesList;
