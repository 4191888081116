import React, { useRef, forwardRef, useImperativeHandle,useEffect, useState } from "react"
import { InfinityTable } from "antd-table-infinity"
import './antdTable.less'

interface InfinityTableProps {
    tableClassName?: string
    pagination: boolean
    onScroll?: Function
    scroll: {
        y: number;
        scrollToFirstRowOnChange: boolean;
    }
    dataSource: any[]
    className?: string
    columns: any[]
    locale?: {
        emptyText: string;
    }
    rowClassName?: Function
    onChange?: Function
    rowKey?: string | number
    loading?: boolean
    loadingIndicator?:any
    onFetch?: Function
    pageSize?: number
    debug?: boolean
    expandable?: any
    expandedRowKeys?: any
    onRow?: any
    ref?: any
}

export const AntdInfinityTable = forwardRef((props: InfinityTableProps,ref) => {
    const infinityTableRef = useRef(null);

    // Forward the ref to the InfinityTable component
    useImperativeHandle(ref, () => ({
    }));

    useEffect(() => {
        function handleSorter(e:any, id:any, element:any) {
          if (e?.keyCode === 13) {
            const x:any = document?.querySelector(`#${id}`);
            if (x) {
              x.click();
            }
          }
        }
    
        const sorterElements: any = document.querySelectorAll('.ant-table-column-sorters .ant-table-column-sorter-inner');
        if (sorterElements) {
          sorterElements.forEach((element:any, index:any) => {
            element?.setAttribute('role', 'button');
            element?.setAttribute('tabIndex', '0');
            element?.setAttribute('id', `sorterById-${index}`);
            // element?.setAttribute('aria-label', `Sort by sortIndex-${index}`);
            element?.setAttribute('aria-label', 'Sorter');
            
            element.addEventListener('keydown', (e:any) => handleSorter(e, `sorterById-${index}`, element));
            
            // Remove event listeners when the component unmounts
            return () => {
              element.removeEventListener('keydown', (e:any) => handleSorter(e, `sorterById-${index}`, element));
            };
          });
        }
      }, []);

    return (
        <div className={`antdTableClass ${props?.tableClassName}`} >
            <InfinityTable
                ref={infinityTableRef}
                expandable={props?.expandable}
                pagination={props?.pagination ?? false}
                onScroll={props?.onScroll}
                scroll={props?.scroll}
                //@ts-ignore
                dataSource={props?.dataSource}
                className={`${props?.className} `}
                expandedRowKeys={props?.expandedRowKeys}
                onRow={props?.onRow}
                //@ts-ignore
                columns={props?.columns}
                locale={props?.locale}
                rowClassName={props?.rowClassName}
                onChange={props?.onChange}
                rowKey={props?.rowKey}
                loading={props?.loading}
                onFetch={props?.onFetch}
                pageSize={props?.pageSize}
                debug={props?.debug ?? false}
                loadingIndicator={props?.loadingIndicator}
            />
        </div>
    )
})