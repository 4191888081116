import { createAsyncThunk } from "@reduxjs/toolkit";
import { HeaderType } from "../../../web/constants/enums";
import { Bibliotheca, warningIcon, AlertIconImg } from "../../../web/images";

export const setHeaderType = createAsyncThunk(
    "app/headerType",
    async (_args: any, thunkAPI) => {
        try {
            let appHeaderType = HeaderType.JUNTO_PORTAL;
            let appLogo = Bibliotheca;
            let appSettingsVisible = true;
            let appAlertWarnVisible = true;

            if(_args.headerType !== HeaderType.JUNTO_PORTAL){
                appHeaderType = HeaderType.BIBLIOTHECA_PORTAL;
                appLogo = Bibliotheca;
                appSettingsVisible = false;
                appAlertWarnVisible = false;
            } 

            const data = {
                headerType : appHeaderType,
                logo : appLogo,
                warningIcon: warningIcon,
                alertIcon: AlertIconImg,
                isSettingVisible : appSettingsVisible,
                isAlertWarnVisible : appAlertWarnVisible,

            }
        return data;
        } catch (e: any) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);