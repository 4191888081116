import React from "react";
import PropTypes from "prop-types";
import {Modal} from '../../../../../bit_components/ui/modal';

//  import { SmallButtonBlack } from '../button/buttons';
import {
  Primarytext,
  Secondarytext,
} from "./warnModal.Styles";
import { useTranslation } from 'react-i18next';

const WarnModal = (props: any) => {
  const { t } = useTranslation()
  const {
    primaryText,
    secondaryText,
    isModalVisible,
    confirmButton,
    cancelButton,
    confirmCallback,
    cancelCallback,
    disabled,
    hideOkText
  } = props;

  const onConfirmCallback = () => {
    if (confirmCallback) {
      confirmCallback();
    }
  };

  const onCancelCallback = () => {
    if (cancelCallback) {
      cancelCallback();
    }
  };

  return (
    <div className="warnModal">
      <Modal
        handleCancel={onCancelCallback}
        handleOk={onConfirmCallback}
        okText={t(confirmButton)}
        cancelText={t(cancelButton)}
        isVisible={isModalVisible}
        primaryButtonDisabled={disabled}
        hideOkText={hideOkText}
      >
        <Primarytext>{t(primaryText)}</Primarytext>
        <Secondarytext>{secondaryText}</Secondarytext>
      </Modal>
    </div>
  );
};

WarnModal.propTypes = {
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.any.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  confirmButton: PropTypes.string.isRequired,
  cancelButton: PropTypes.string.isRequired,
  confirmCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  hideOkText:PropTypes.bool.isRequired,
};

export default WarnModal;
