import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
} from "../../../utility/stateUtil";
import { fetchAllPermissions } from "../../actions/role/permissionAction";
import { RootState } from "../../store/rootReducer";
import {PermissionState} from '../../states/role/roleListState'

const initialState: PermissionState = {
  permissions: [],
  formState: resetState(),
};

export const PermissionSlice = createSlice({
  name: "permissions",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      return state;
    },
    clearPermissions: (state) => {
      state.permissions = [];
    }
  },
  extraReducers: {
    // Action - fetchAllPermissions
    [fetchAllPermissions.fulfilled.toString()]: (state, { payload }) => {
      state.permissions = payload;
      state.formState = fulfilledState();
      return state;
    },
    [fetchAllPermissions.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [fetchAllPermissions.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
  },
});

export const { clearState, clearPermissions } = PermissionSlice.actions;
export const permissionSelector = (state: RootState) => state.permission;
export default PermissionSlice.reducer;
