import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
} from "../../../utility/stateUtil";
import { DisplayViewType } from "../../../web/constants/enums";
import User from "../../../web/models/user/user";
import { UserSearch } from "../../../web/models/user/userSearch";
import {
  fetchUserList,
  filterUserList,
  selectUser,
  setBranchFilter,
  setRoleFilter,
  setSearchTermFilter,
  setUserListViewType,
  fetchTransformUserList,
  filterTransformUserList,
} from "../../actions/user/userListAction";
import {
  getProfile, storeCreateEml, storeCreatePwd,
} from "../../actions/user/userProfileAction";
import { UserListState } from "../../states/user/userListState";
import { RootState } from "../../store/rootReducer";

const initialSelectedUser: User | null = null;

const initialSearchFilters: UserSearch = {
  branch: {
    id: '',
    name: "",
  },
  role: {
    id: '',
    name: "",
  },
  searchTerm: "",
  searchToggle: false,
};

const initialState: UserListState = {
  users: [],
  isUserSelected: false,
  selectedUser: initialSelectedUser,
  branches: [],
  search: initialSearchFilters,
  userListViewType: DisplayViewType.CARD_VIEW,
  formState: resetState(),
  searchFormState: resetState(),
  listFormState: resetState(),
  createPwd:'',
  createEml:''
};

export const UserListSlice = createSlice({
  name: "userList",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      return state;
    },

    clearSearchFilters: (state) => {
      state.searchFormState = resetState();
      state.search = initialSearchFilters;
      return state;
    },

    clearSelectedUser: (state) => {
      state.selectedUser = null;
      return state;
    },

    clearStoreCredentials: (state) => {
      state.createPwd = ''
      state.createEml = ''
      return state;
    },

    clearIsUserSelected: (state) => {
      state.isUserSelected = false;
      return state;
    },

    toggleSearch: (state) => {
      state.search.searchToggle = !state.search.searchToggle;
      return state;
    },

    clearUsers: (state) => {
      state.users = [];
      return state;
    },

    clearListFormState: (state) => {
      state.listFormState = resetState();
      return state;
    }
  },
  extraReducers: {
    [fetchUserList.fulfilled.toString()]: (state, { payload }) => {
      state.users = payload;
      state.formState = fulfilledState();
      state.listFormState = fulfilledState();
      return state;
    },
    [fetchUserList.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
      state.listFormState = rejectedState(payload?.message);
    },
    [fetchUserList.pending.toString()]: (state) => {
      state.formState = pendingState();
      state.listFormState = pendingState();
    },
    [fetchTransformUserList.fulfilled.toString()]: (state, { payload }) => {
      state.users = payload;
      state.formState = fulfilledState();
      state.listFormState = fulfilledState();
      return state;
    },
    [fetchTransformUserList.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
      state.listFormState = rejectedState(payload?.message);
    },
    [fetchTransformUserList.pending.toString()]: (state) => {
      state.formState = pendingState();
      state.listFormState = pendingState();
    },

    [setUserListViewType.fulfilled.toString()]: (state, { payload }) => {
      state.userListViewType = payload;
      state.formState = fulfilledState();
      return state;
    },
    [setUserListViewType.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [setUserListViewType.pending.toString()]: (state) => {
      state.formState = pendingState();
    },

    [filterUserList.fulfilled.toString()]: (state, { payload }) => {
      state.users = payload;
      state.formState = fulfilledState();
      return state;
    },
    [filterUserList.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [filterUserList.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
    [filterTransformUserList.fulfilled.toString()]: (state, { payload }) => {
      state.users = payload;
      state.formState = fulfilledState();
      return state;
    },
    [filterTransformUserList.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload?.message);
    },
    [filterTransformUserList.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
    [setBranchFilter.fulfilled.toString()]: (state, { payload }) => {
      state.search.branch = payload;
      state.formState = state.searchFormState = fulfilledState();
      return state;
    },
    [setBranchFilter.rejected.toString()]: (state, { payload }) => {
      state.formState = state.searchFormState = rejectedState(payload?.message);
    },
    [setBranchFilter.pending.toString()]: (state) => {
      state.formState = pendingState();
    },

    [setRoleFilter.fulfilled.toString()]: (state, { payload }) => {
      state.search.role = payload;
      state.formState = state.searchFormState = fulfilledState();
      return state;
    },
    [setRoleFilter.rejected.toString()]: (state, { payload }) => {
      state.formState = state.searchFormState = rejectedState(payload?.message);
    },
    [setRoleFilter.pending.toString()]: (state) => {
      state.formState = pendingState();
    },

    [setSearchTermFilter.fulfilled.toString()]: (state, { payload }) => {
      state.search.searchTerm = payload;
      state.search.searchToggle = true;
      state.formState = state.searchFormState = fulfilledState();
      return state;
    },
    [setSearchTermFilter.rejected.toString()]: (state, { payload }) => {
      state.formState = state.searchFormState = rejectedState(payload?.message);
    },
    [setSearchTermFilter.pending.toString()]: (state) => {
      state.formState = pendingState();
    },

    [selectUser.fulfilled.toString()]: (state, { payload }) => {
      //debugger;
      state.selectedUser = payload;
      state.isUserSelected = true;
      state.formState = fulfilledState();
      return state;
    },
    [selectUser.rejected.toString()]: (state, { payload }) => {
      //debugger;
      state.formState = rejectedState(payload?.message);
    },
    [selectUser.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
    [getProfile.fulfilled.toString()]: (state, { payload }) => {
      state.selectedUser = payload;
      state.formState = fulfilledState();
      return state;
    },
    [getProfile.rejected.toString()]: (state, { payload }) => {
      //debugger;
      state.formState = rejectedState(payload?.message);
    },
    [getProfile.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
    [storeCreatePwd.fulfilled.toString()]: (state, { payload }) => {
      state.createPwd = payload;
      return state;
    },
    [storeCreatePwd.rejected.toString()]: (state, { payload }) => {
      //debugger;
      state.formState = rejectedState(payload?.message);
    },
    [storeCreatePwd.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
    [storeCreateEml.fulfilled.toString()]: (state, { payload }) => {
      state.createEml = payload;
      return state;
    },
    [storeCreateEml.rejected.toString()]: (state, { payload }) => {
      //debugger;
      state.formState = rejectedState(payload?.message);
    },
    [storeCreateEml.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
  },
});

export const {
  clearSearchFilters,
  clearSelectedUser,
  clearIsUserSelected,
  clearStoreCredentials,
  clearState,
  toggleSearch,
  clearUsers,
  clearListFormState,
} = UserListSlice.actions;
export const userListSelector = (state: RootState) => state.userList;
export default UserListSlice.reducer;
