import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";

export default class UserLogo extends BaseService {

  constructor() {
    super(getAPIBaseUrl(PortalModule.UPLOADLOGO));
  }
  async uploadLogo(formData: any | null): Promise<any> {
    //debugger;
    const response: any = await this.post("upload/logo", formData );
    return response;
  }

}
