import styled from "styled-components";
import {Col} from 'antd';

export const WorkHead = styled.div`
    font-family: ${(props) => props.theme.fonts[0]};
    color: ${(props) => props.theme.colors.text.primary};
    width: 100%;
`;

export const WorkDiv = styled(Col)`
    font-size: ${(props) => props.theme.fontSizes.h1};
    font-weight: ${(props) => props.theme.fontWeights.normal};
    line-height : 20px;
    height: 54px;
    display: flex;
    align-items: center;
    padding-left: 4px;
`;

export const SpanWork = styled.span`
    margin-right: 10px;
    cursor : pointer;
`;

export const DropDownWrapper = styled.div`
    // padding-left: 23px;
`;
  
export const WorkHeaders = styled(Col)`
    flex: auto;
    display: flex;
    justify-content: flex-end;
`;