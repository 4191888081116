import React from 'react';
import { Form, Input } from 'antd';
import './customInput.less'
import { useTranslation } from 'react-i18next';

export type CustominputProps = {
  labelSubName?: string,
  initialValue?: string,
  labelName?: string,
  rules?: any,
  customLabelClass?: string,
  placeholder?: string,
  name?: string,
  value?: string,
  onChange?: any,
  disabled?: boolean,
  autoComplete?:string
  tabIndex?:any,
  ariaLabel?:any,
  role?:any
  validationStatus?: 'success' | 'error'

};

export function Custominput({ ...props }: CustominputProps) {
  const { t } = useTranslation()
    return (
      <Form.Item
        label={t(props.labelSubName)}
        initialValue={props.initialValue}
        name={props.labelName}
        rules={props.rules}
        className={`mb-0 ${props.customLabelClass}`}
        validateStatus={props.validationStatus} 
      >
        <Input
          placeholder={t(props.placeholder)}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          disabled={props?.disabled || false}
          autoComplete={props?.autoComplete}
          className="customInput"
          tabIndex={props?.tabIndex}
          aria-label={props?.ariaLabel} 
          role={props?.role}
        />
      </Form.Item>
    );
  }