/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Col, Card, List, Input, Tooltip } from 'antd';
import {Button} from "../../../../../bit_components/common/button";
import {addButtonIcon } from "../../../../images";
import { organisationDepthSelector, clearAdded } from "../../../../../redux/reducers/organisation/organisationDepthReducer";
import { useDispatch, useSelector } from "react-redux";
import { addLocation } from "../../../../../redux/actions/organisation/fetchDepth";
import { LocationRow } from "./locationRow";
import { NoMoreLocations } from "./noMoreLocations";
import { KeyboardKeyCharCode, jsonData } from "../../../../constants/constants";
import { useTranslation } from 'react-i18next';

export const LocationsGrid = (props: any) => {

    const { index, orgId, isEditingAllowed } = props;
    const { t } = useTranslation()
    
    let locationMsg = t(jsonData.Nomorelocations);
    let addLocationMsg = t(jsonData.AddLocation);

    if(index > 0)
    {
        locationMsg = t(jsonData.Nomoresublocations);
        addLocationMsg = t(jsonData.AddSublocation);
    }

     const dispatch = useDispatch();
     const { added } = useSelector(organisationDepthSelector);
     const [showInput, setShowInput] = useState<any>(true);
     const [recordTobeCreated, setRecordTobeCreated] = useState<string>('');
     const [locationInput, setLocationInput] = useState<any>(false);

    useEffect(()=>{
        return ()=>{
            dispatch(clearAdded);
        }
    }, [added]);

    const inputRef = useRef(null);

    useEffect(() => {
        if (!showInput) {
          inputRef.current.focus();
        }
      }, [showInput]);

    const OnAddClick: any = () => {
        if(showInput){
            setShowInput(false);
            }
        else{
            if(recordTobeCreated !==''){
                dispatch(addLocation({ organisationID: orgId,
                    name: recordTobeCreated,
                    type: index === 0 ? 'LOC' : 'SLOC',
                    parentID: props.item.parentId,
                    t:t
                   }));
                setShowInput(true)
            }
            setShowInput(true);
        }
    };

    const handleChange = ( e: any) => {
        setRecordTobeCreated(e.target.value)
    }

    // on enter key press
    const handleKeypress = e => {
    //it triggers by pressing the enter key
      if (e.charCode === KeyboardKeyCharCode.ENTER_KEY) {
        OnAddClick();
      }
    };
    const selectLocation = (status:boolean) => {
        setLocationInput(status);
    }
    const selectLocation2 = () => {
        setLocationInput(!locationInput);
    }

    const hideNodeByClosestParentClassName = ".childDiv";

    const hideCol =()=>{
        var hideEmptyDiv = document?.querySelectorAll<HTMLElement>('.ant-list-empty-text');
        for (let i = hideEmptyDiv.length; i--;)
        //@ts-ignore
        hideEmptyDiv[i].closest(hideNodeByClosestParentClassName).style.display = "none";
        var styleCol = document?.querySelectorAll<HTMLElement>('.add-loc-sloc-button');
        var styleColA = document?.querySelectorAll<HTMLElement>('.location-row');
        for (let i = styleCol.length; i--;)
        //@ts-ignore
        styleCol[i].closest(hideNodeByClosestParentClassName).classList.add("addColStyle");
        for (let i = styleColA.length; i--;)
        //@ts-ignore
        styleColA[i].closest(hideNodeByClosestParentClassName).classList.remove("addColStyle");
       } 

    const onChangeLoc = () => {
        hideCol()
        setTimeout(() => {
            hideCol()
        }, 50);
    }

    return ( 
        <>
            <Col className="card-right-border col-customized childDiv" span={5} xs={7} >
                <Card bordered={false} className="cardbody">                    
                {!props.item.noMoreEntries?
                    <div>
                        <List
                            className={`list-items ${locationInput ? 'selectLoc':''}`}
                            bordered={false}
                            dataSource={props?.item?.hierarchyRowList}
                            renderItem={ (item: any, i: number) => (
                                <List.Item tabIndex={0} onKeyPress={onChangeLoc} aria-label={`${t(jsonData.yourOrganizationLocation)}, ${item.rowName}`} role={""} onClick={onChangeLoc} className={ `${item.isSelected?'selectedLocation':''} locationWrapper`}>  
                                    <LocationRow 
                                        isSelected  = { item.isSelected }
                                        hasChildren = { item.hasChildren }
                                        rowName = { item.rowName }
                                        orgId= {orgId}
                                        selectedIndex = { i }
                                        gridIndex = { index }
                                        rowId = { item.rowId }
                                        rowType = { item.rowTypeCode }
                                        isEditingAllowed = {props?.isEditingAllowed}
                                        selectLocation={selectLocation}
                                        selectLocation2={selectLocation2}
                                        rowParent = {item.rowParent}
                                        parentID= {props.item.parentId}
                                        index={index}
                                    />
                                </List.Item>
                            )}
                            
                        />
                        {props?.item?.hierarchyRowList.length && isEditingAllowed?
                        <div className="add-form">
                            <Input className="addLocation" maxLength={100} name="location" hidden={showInput} ref={inputRef} value={recordTobeCreated} onChange={handleChange} onBlur={OnAddClick} placeholder={index? t(jsonData.SublocationName): t(jsonData.LocationName)}
                            onKeyPress={handleKeypress} aria-label={""} role={""}
                            />
                            <Button ariaLabel={`${jsonData.addNewLocation}`} type="add-button" htmlType="submit" onClick={OnAddClick}>
                                <Tooltip overlayClassName='dashBoardTooltip' placement='bottom' title={index? t(jsonData.AddSublocation): t(jsonData.AddLocation)}>
                                    <img src={addButtonIcon} alt="" />
                                </Tooltip>
                            </Button>   
                        </div> : null
                        }
                    </div> :
                    (isEditingAllowed?
                    <NoMoreLocations key={`no-more-locations`} text1={locationMsg} text2={addLocationMsg} orgId = {orgId}  index={index} parentId={props.item.parentId} ></NoMoreLocations> : null)}
                </Card> 
                
            </Col>
        </>
    );
};
