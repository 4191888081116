import { createSlice } from "@reduxjs/toolkit";
import {
    fulfilledState,
    rejectedState,
    pendingState,
    resetState,
} from "../../../utility/stateUtil";

import { fetchOrganisations, fetchTransformedOrganisationsList } from "../../actions/organisation/fetchOrganisations";
import { OrganisationListState } from "../../states/organisation/organisationListState";
import { RootState } from "../../store/rootReducer";

const initialState: OrganisationListState = {
    organisations: [],
    region: null,
    country: '',
    search: '',
    limit: 10,
    page: 1,
    formState: resetState(),
};

export const OrganisationListSlice = createSlice({
    name: "organisationList",
    initialState: initialState,
    reducers: {
        clearState: (state) => {
            // state.formState = resetState();
            // return state;
            // return { ...state, formState: resetState(), search: '', country: '', region: '', page: 1, limit: 10 };
            return { ...state, formState: resetState(), organisations: [] };
        },
        setSelectedRegion: (state, action) => {
            // let a = {...state, region: action.payload};
            // return a;
            return { ...state, region: action.payload.name };
        },
        setSelectedCountry: (state, action) => {
            // let a = {...state, country: action.payload};
            // return a;
            return { ...state, country: action.payload.name };
        },
        setSearchState: (state, action) => {
            return { ...state, search: action.payload };
        }
    },
    extraReducers: {
        [fetchOrganisations.fulfilled.toString()]: (state, { payload }) => {
            // state.organisations = payload;
            // state.formState = fulfilledState();
            // return state;
            return { ...state, organisations: payload, formState: fulfilledState() };
        },
        [fetchOrganisations.rejected.toString()]: (state, { payload }) => {
            // state.formState = rejectedState(payload?.message);
            return { ...state, formState: rejectedState(payload?.message) };
        },
        [fetchOrganisations.pending.toString()]: (state) => {
            // state.formState = pendingState();
            return { ...state, formState: pendingState() };
        },
        // organisation list transformation
        [fetchTransformedOrganisationsList.fulfilled.toString()]: (state, { payload }) => {
            return { ...state, organisations: payload, formState: fulfilledState() };
        },
        [fetchTransformedOrganisationsList.rejected.toString()]: (state, { payload }) => {
            return { ...state, formState: rejectedState(payload?.message) };
        },
        [fetchTransformedOrganisationsList.pending.toString()]: (state) => {
            return { ...state, formState: pendingState() };
        }
    }
});

export const { clearState, setSelectedCountry, setSelectedRegion, setSearchState } = OrganisationListSlice.actions;
export const organisationListSelector = (state: RootState) => state.organisations;
export default OrganisationListSlice.reducer;
