import React, { useEffect, useState, useRef } from "react";
import { Tooltip, Input, Button as AntdButton } from "antd";
import { useTranslation } from "react-i18next";
import { jsonData } from "../../../../../../constants/constants";
import { SearchImg } from "../../../../../../images";

interface SearchBarProps {
  onSearch: Function;
}

const SearchBar = ({ onSearch }: SearchBarProps) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [searchBox, setSearchBox] = useState(false);
  const [focusClass, setFocusClass] = useState<string>("");
  const [searchText, setSearchText] = useState<any | null>();

  const handleSearch = (text: string) => {
    onSearch(text);
  };

  const onChangeText = (event: any) => {
    handleSearch(event.target.value);
    inputRef.current.focus();
    setSearchText(event.target.value);
  };

  const toggleSearchBox = () => {
    setFocusClass("");
    !searchText && setSearchBox((prev) => !prev);
  };

  const inputHaveFocus = () => {
    setFocusClass("eventSearchBar");
  };

  useEffect(() => {
    if (searchBox) {
      inputRef.current.focus();
    }
  }, [searchBox]);

  const handleClick = (e: any, clearIcon: any) => {
    if (e?.keyCode === 13) {
      clearIcon.click();
    }
    clearIcon.removeEventListener("keyup", (e) => handleClick);
  };

  const handleClear = (e: any) => {
    let clearIcon: any = document.querySelector(".ant-input-clear-icon");
    if (e?.keyCode === 9) {
      clearIcon.setAttribute("tabIndex", "0");
      clearIcon.addEventListener("keyup", (e) => handleClick(e, clearIcon));
    }
  };

  return (
    <div>
      <Tooltip
        overlayClassName="dashBoardTooltip"
        placement="bottom"
        className="eventSearchTooltip"
        title={t(jsonData.search)}
      >
        <AntdButton
          tabIndex={0}
          className="serOrgBtn configSearchIcon"
          onClick={toggleSearchBox}
          shape="circle"
          type="link"
        >
          <img src={SearchImg} alt="" />
        </AntdButton>
      </Tooltip>
      
      {searchBox && (
        <Input
          type="text"
          ref={inputRef}
          className={`${focusClass} tablesearchbar highlighter`}
          onChange={onChangeText}
          onBlur={toggleSearchBox}
          value={searchText}
          placeholder={t("Search")}
          bordered={true}
          id="highlighter"
          onFocus={inputHaveFocus}
          onKeyDown={(e) => handleClear(e)}
          allowClear
        />
      )}
    </div>
  );
};

export default SearchBar;
