// Package Imports
import { combineReducers } from "@reduxjs/toolkit";

// Reducer Imports
import UserReducer from "../reducers/user/userReducer";
import UserListReducer from "../reducers/user/userListReducer";
import roleListReducer from "../reducers/role/roleListReducer";
import organisationListReducer from "../reducers/organisation/organisationListReducer";
import organisationDetailsReducer from "../reducers/organisation/organisationDetailsReducer";
import userProfileReducer from "../reducers/user/userProfileReducer";
import commonReducer from "../reducers/common/commonReducer";
import permissionReducer from '../reducers/role/permissionReducer';
import locationListReducer from "../reducers/location/locationListReducer";
import AppReducer from "../reducers/app/appReducers";
import organisationDepthReducer from "../reducers/organisation/organisationDepthReducer";
// import { devicesReducer } from "@bibliotheca/backoffice.apps.devices";
import eventLogListReducer from "../reducers/eventLogs/eventLogListReducer";
import auditLogListReducer from "../reducers/auditLogs/auditLogListReducer";
import AlertReducer from '../reducers/alert/alertReducer';
import DeviceReducer from '../reducers/device/deviceReducer'
import HeaderReducer from "../reducers/app/headerReducers";
import notificationsReducer from "../reducers/notifications/notificationsReducer";
import insightsReducer from "../reducers/insights/insightsReducer";
import alertConfigReducer from "../reducers/alertConfig/alertConfigReducer";
import WorkReducer from "../reducers/workItems/workReducer"

const appReducer = combineReducers({
    notifications: notificationsReducer,
    eventLogs: eventLogListReducer,
    auditLogs:auditLogListReducer,
    app: AppReducer,
    header: HeaderReducer,
    user: UserReducer,
    userList: UserListReducer,
    userProfile: userProfileReducer,
    roleList: roleListReducer,
    locationList: locationListReducer,
    organisations: organisationListReducer,
    organisation: organisationDetailsReducer,
    common: commonReducer,
    permission: permissionReducer,
    depth: organisationDepthReducer,
    // devices: devicesReducer,
    alert: AlertReducer,
    alertConfig: alertConfigReducer,
    device: DeviceReducer,
    insights: insightsReducer,
    workItems: WorkReducer
});

export type RootState = ReturnType<typeof appReducer>;
export default appReducer;