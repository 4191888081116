import { createSlice } from "@reduxjs/toolkit";
import {
    fulfilledState,
    rejectedState,
    pendingState,
    resetState,
  
  } from "../../../utility/stateUtil";
import { RootState } from "../../store/rootReducer";
import { WorkState } from "../../states/workItems/workState";
import { fetchWorkItemsByOrgId, retryWorkItem, deleteWorkItem, fetchUserName, fetchWorkItemName} from "../../actions/workItems/workAction";


const initialState:WorkState = {
  workItems: [],
  totalWorkCount: 0,
  workItemNameList: [],
  userNameList: [],
  formState : resetState(),
  fetchWorkItemsFormState: resetState(),
  retryWorkItemsFormState: resetState(),
  deleteWorkItemsFormState: resetState(),
  workItemNameFormState: resetState(),
};

export const WorkSlice = createSlice({
    name: "workItems",
    initialState: initialState,
    reducers: {
      clearFetchWorkItemsFormState: (state) => {
        state.fetchWorkItemsFormState = resetState();
        return state;
      },
      clearWorkList: (state) => {
        state.workItems = []
        return state
      },
    },
    extraReducers: {
      // Action - fetchWorkItemsByOrgId
      [fetchWorkItemsByOrgId.fulfilled.toString()]: (state, { payload }) => {
        state.workItems = payload?.workItems;
        state.totalWorkCount = payload?.totalWorkCount;
        state.fetchWorkItemsFormState = fulfilledState();
        return state;
      },
      [fetchWorkItemsByOrgId.rejected.toString()]: (state, { payload }) => {
        state.fetchWorkItemsFormState = rejectedState(payload?.message);
      },
      [fetchWorkItemsByOrgId.pending.toString()]: (state) => {
        state.fetchWorkItemsFormState = pendingState();
      },

      [retryWorkItem.fulfilled.toString()]: (state, { payload }) => {
        state.retryWorkItemsFormState = fulfilledState();
        return state;
      },
      [retryWorkItem.rejected.toString()]: (state, { payload }) => {
        state.retryWorkItemsFormState = rejectedState(payload?.message);
      },
      [retryWorkItem.pending.toString()]: (state) => {
        state.retryWorkItemsFormState = pendingState();
      },

      [deleteWorkItem.fulfilled.toString()]: (state, { payload }) => {
        state.deleteWorkItemsFormState = fulfilledState();
        return state;
      },
      [deleteWorkItem.rejected.toString()]: (state, { payload }) => {
        state.deleteWorkItemsFormState = rejectedState(payload?.message);
      },
      [deleteWorkItem.pending.toString()]: (state) => {
        state.deleteWorkItemsFormState = pendingState();
      },

      [fetchUserName.fulfilled.toString()]: (state, { payload }) => {
        state.userNameList = payload;
        state.formState = fulfilledState();
        return state;
      },
      [fetchUserName.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload?.message);
      },
      [fetchUserName.pending.toString()]: (state) => {
        state.formState = pendingState();
      },

      [fetchWorkItemName.fulfilled.toString()]: (state, { payload }) => {
        state.workItemNameList = payload;
        state.workItemNameFormState = fulfilledState();
        return state;
      },
      [fetchWorkItemName.rejected.toString()]: (state, { payload }) => {
        state.workItemNameFormState = rejectedState(payload?.message);
        return state;
      },
      [fetchWorkItemName.pending.toString()]: (state, { payload }) => {
        state.workItemNameFormState = pendingState();
        return state;
      }
    },
});

export const { clearFetchWorkItemsFormState, clearWorkList } = WorkSlice.actions;
export const WorkSelector = (state: RootState) => state.workItems;
export const WorkFormStateSelector = (state: RootState) => state.workItems.formState;
export default WorkSlice.reducer;