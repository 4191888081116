import { Tooltip } from "antd";
import React, { useState } from "react";
import { jsonData } from "../../../../constants/constants";
import { handleEnterKeyPress } from "../../../../../utility/utils";
import { CopyOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface CopyComponentInterface {
  text: string;
}

const CopyComponent = ({ text }: CopyComponentInterface) => {
  let disabled = !text || text?.length < 1;

  const { t } = useTranslation();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipText, setTooltipText] = useState(t(jsonData.CopyText));

  const announceToScreenReader = (message: string) => {
    const announceDiv = document.createElement("div");
    announceDiv.setAttribute("role", "alert");
    announceDiv.setAttribute("aria-live", "polite");
    announceDiv.setAttribute("aria-atomic", "true");
    announceDiv.textContent = message;
    document.body.appendChild(announceDiv);

    setTimeout(() => {
      document.body.removeChild(announceDiv);
    }, 2000); // Remove the announcement after 2 seconds
  };

  const action = async (e: any) => {
    if (!disabled) {
      setTooltipText(t(jsonData.copied));
      setTimeout(() => {
        setTooltipText(t(jsonData.CopyText));
      }, 2000);
      e?.stopPropagation();
      try {
        await navigator.clipboard.writeText(text || "");
        announceToScreenReader(t(jsonData.copied));
      } catch (error) {
        console.error("Error copying text to clipboard:", error);
      }
    }
  };

  return (
    <Tooltip
      overlayClassName="dashBoardTooltip"
      placement="bottom"
      title={tooltipText}
      visible={!disabled && tooltipVisible}
      onVisibleChange={(visible) => {
        if (!visible) {
          setTimeout(() => {
            setTooltipText(t(jsonData.CopyText));
          }, 200);
        }
        setTooltipVisible(visible);
      }}
    >
      <div
        className={`copy-icon ${disabled ? "cursor-disabled" : ""}`}
        role="button"
        aria-label={t(jsonData.CopyText)}
        tabIndex={disabled ? -1 : 0}
        onClick={action}
        onKeyDown={(e: any) => handleEnterKeyPress(e, action)}
      >
        {disabled ? (
          <CopyOutlined style={{ color: "gray" }} />
        ) : (
          <CopyOutlined />
        )}
      </div>
    </Tooltip>
  );
};

export default CopyComponent;
