import React from 'react'
import { Form, Input } from 'antd'
import './customPassword.style.less';

const CustomPassword = (props: any) => {
  return (
    <Form.Item  label={props.label} name={props.name} rules={props.rules} className={`mb-0 ${props.customLabelClass}`}>
      <Input.Password placeholder={props.placeholder} disabled={props?.disabled || false} className="customPassword" visibilityToggle={false} value={props.value} onChange={props.onChange}/>
    </Form.Item>
  )
}

export default CustomPassword
