// pacakge imports
import React from 'react';
import {Col, Row} from 'antd';

// module imports
import { RolesDetailsContentItem } from './rolesDetailsContentItem/rolesDetailsContentItem';

// styles imports
import { CheckBoxHeading, ContentHeaderDivider, ContentHeaderWrapper, RolesDetailsContentWrapper } from './rolesDetailsContent.styles';
import { RolePermission } from '../../../../../models/role/rolePermission';
import { useTranslation } from 'react-i18next';
import { jsonData } from '../../../../../constants/constants';

interface RolesDetailsContentProps {
  permissions: RolePermission[];
  systemRole: boolean;
  handleOnChange?: Function;
}

export const RolesDetailsContent = (props:RolesDetailsContentProps) => {
  const {permissions, systemRole, handleOnChange} = props;
  const { t } = useTranslation()
  return (
    <RolesDetailsContentWrapper>
      {permissions.length > 0 && <ContentHeaderWrapper>
      <Row>
        <Col span={4} offset={16} >
          <CheckBoxHeading>
            {t("View")}
          </CheckBoxHeading>
        </Col> 
        <Col span={4} >
          <CheckBoxHeading>
          {t(jsonData.Edit)}
          </CheckBoxHeading>
        </Col>  
      </Row>
      </ContentHeaderWrapper>
      }
      {permissions && permissions.map((item: any)=> (
      <div key={item?.permission}>
      <RolesDetailsContentItem  key={item?.id} permission={item} systemRole={systemRole} handleOnChange={handleOnChange && handleOnChange}/>
      <ContentHeaderDivider key={item?.id} />  
      </div>   
      ))}
    </RolesDetailsContentWrapper>
  );
};