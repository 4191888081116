import styled from 'styled-components'
import { Row } from "antd"

const displayFlex = `
  display: flex;
  align-items: center;
`
export const WorkTableRow = styled(Row) `
  justify-content:space-between;
`;

export const WorkCentrediv = styled.div`
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  height: 100%;
`

export const WorkItemCardTitle = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${(props) => props.theme.fontSizes.h5};
  color: ${(props) => props.theme.colors.palette.black};
  line-height: 20px;
  ${displayFlex}
`

export const WorkItemKey = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${(props) => props.theme.fontSizes.smallBody};
  line-height: 20px;
  padding-right: 10px;
  color: ${(props) => props.theme.colors.palette.black};
  @media screen and (max-width:1300px){
    max-width: 50%;
  }
`

export const WorkItemValue = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${(props) => props.theme.fontSizes.footnote};
  line-height: 20px;

  max-width: 60%;
  margin-left: auto !important;
  text-align: right;
  color: ${(props) => props.theme.colors.palette.darkGray};
  @media screen and (max-width:1300px){
    max-width: 50%;
  }
`