import React, {useEffect} from "react";
import { Breadcrumb } from 'antd';
import { Link } from "react-router-dom";
import "./breadCrumbs.less";
import vectorforward from '../../../../../assets/images/vectorforward.svg'
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { urlModification } from "../../../../../utility/appUtil";
import { AppRoutes } from "../../../../constants/enums";

export const Breadcrumbs = (props: any) => {
  const { t } = useTranslation()
  const { breadcrumbs = [] , breadCrumbClick, setBredCrumClick, tabIndex } = props;
  const history = useHistory()

  useEffect(() => {
    if(breadCrumbClick) {
      setBredCrumClick(false)
      window.location.href = breadcrumbs[0].href
    }
  }, [breadCrumbClick])
  

  if (!breadcrumbs || (breadcrumbs && breadcrumbs.length === 0)) {
    return (
      <></>
    );
  }

  return (
    <Breadcrumb className="breadCrumbsStyle" separator="">
      {
        breadcrumbs.map((x: { text: string, href?: string }, index: number) => (
          x.href ? (
            <>
            <Breadcrumb.Item key={index}>
            <div tabIndex={tabIndex} aria-label={""} role={""} >
              <Link to={x.href}>{t(x.text)}</Link>
            </div>
            </Breadcrumb.Item>
            <Breadcrumb.Separator><img src={vectorforward} alt="vectorIcon" /> </Breadcrumb.Separator>
            </>
          ) : (
            <Breadcrumb.Item key={index}>{x.text}</Breadcrumb.Item>
          )
        ))
      }
    </Breadcrumb>
  );
}