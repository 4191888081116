import { Subject } from "rxjs";

const toastSubject = new Subject();

export const AppService = {
  showToast: (msg: string) =>
    toastSubject.next({
      msg: msg,
    }),
  listenToaster: () => toastSubject.asObservable(),
};
