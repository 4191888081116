import React, {useEffect} from 'react';
import { Breadcrumb } from 'antd';
import { Link } from "react-router-dom";
import vectorForwardImg from './vectorforward.svg';
import './breadCrumbs.less';

export type BreadcrumbsProps = {
  
  breadcrumbs: any,
  breadCrumbClick?:any,
  setBredCrumClick?:any,
  tabIndex?:any,
  ariaLabel?:any,
  role?:any
};

export function Breadcrumbs({ ...props }: BreadcrumbsProps) {
  const { breadcrumbs = [], breadCrumbClick, setBredCrumClick, tabIndex,ariaLabel,role  } = props;

  useEffect(() => {
    if(breadCrumbClick) {
      setBredCrumClick(false)
      window.location.href = breadcrumbs[0].href
    }
  }, [breadCrumbClick])

  if (!breadcrumbs || (breadcrumbs && breadcrumbs.length === 0)) {
    return (
      <></>
    );
  }
  return (
    <Breadcrumb className="breadCrumbsStyle" separator="">
      {
        breadcrumbs.map((x: { text: string, href?: string }, index: number) => (
          x.href ? (
            <>
            <Breadcrumb.Item key={index}>
            <div tabIndex={tabIndex} aria-label={ariaLabel} role={role}>
              <Link to={x.href}>{x.text}</Link>
              </div>
            </Breadcrumb.Item>
            <Breadcrumb.Separator><img src={vectorForwardImg} alt="vectorIcon" /> </Breadcrumb.Separator>
            </>
          ) : (
            <Breadcrumb.Item key={index}>{x.text}</Breadcrumb.Item>
          )
        ))
      }
    </Breadcrumb>
  );
}