import React, {useState} from 'react'
import { Modal } from "../../../../../bit_components/ui/modal"
import { jsonData } from '../../../../constants/constants'
import { Radio } from 'antd';
// import type { RadioChangeEvent } from 'antd';
import { useTranslation } from 'react-i18next';

const ExportModel = (props) => {
    const { isModalVisible, setexportModel, setExportFormat, saveExcel } = props
    const { t } = useTranslation()
    const [value, setValue] = useState(1);

    const handleCancel = () => {
        setexportModel(false)
    }

    const onCreateScheduleReport = () => {
        if(value === 1) {
            setExportFormat('grouped')
        } else {
            setExportFormat('flat')   
        }
        setexportModel(false)
    }

    const onChange = (e) => {
        setValue(e.target.value);
      };

  return (
    <Modal
    handleCancel={handleCancel}
    handleOk={onCreateScheduleReport}
    okText={t(jsonData.Save)}
    cancelText={t(jsonData.Cancel)}
    isVisible={isModalVisible}
>
<p className='exportTitle'>
    {t(jsonData.ExportTitle)}
</p>

<Radio.Group className='exportRadioWrapper' onChange={onChange} value={value}>
    <Radio className='exportRadio' value={1} tabIndex={0}>{t(jsonData.GroupExport)}</Radio>
    <Radio className='exportRadio' value={2} tabIndex={0}>{t(jsonData.FlatExport)}</Radio>
  </Radio.Group>


</Modal>
  )
}

export default ExportModel