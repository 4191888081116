import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
} from "../../../utility/stateUtil";

import { fetchDepthByOrgID, addLocation, deleteOrganisationByID, relocateAndDeleteLocation, updateLocation, fetchTransformedLocations } from "../../actions/organisation/fetchDepth";
// import { OrganisationDetailState } from "../../states/organisation/organisationDetailState";
import { RootState } from "../../store/rootReducer";

const initialState: any = {
  result: {},
  formState: resetState(),
  selectedLocation: [],
  newLocation: {},
  addFormState: resetState(),
  deleteFormState: resetState(),
  activeCard: null,
  added: false,
  deleted: false,
  updated: false,
  errorMessage: ''
};

export const OrganisationDepthSlice = createSlice({
  name: "organisationDepth",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      return { ...state, formState: resetState(), result: {} };
    },
    addClearState: (state) => {
      return { ...state, addFormState: resetState(), errorMessage : '' };
    },
    deleteClearState: (state) => {
      return { ...state, deleteFormState: resetState() };
    },
    setSelectedLocations: (state, { payload }) => {
      return { ...state, selectedLocation: payload };
    },
    setActiveCard: (state, { payload }) => {
      return { ...state, activeCard: payload }
    },
    clearAdded: (state) =>{
      return {...state, added: false}
    },
    clearDeleted: (state) =>{
      return {...state, deleted: false}
    },
    clearUpdated: (state) =>{
      return {...state, updated: false}
    }
  },
  extraReducers: {
    [fetchDepthByOrgID.fulfilled.toString()]: (state, { payload }) => {
      return { ...state, result: payload, formState: fulfilledState() };
    },
    [fetchDepthByOrgID.rejected.toString()]: (state, { payload }) => {
      return { ...state, formState: rejectedState(payload?.message) };
    },
    [fetchDepthByOrgID.pending.toString()]: (state) => {
      return { ...state, formState: pendingState() };
    },
    // fetch transformed location list
    [fetchTransformedLocations.fulfilled.toString()]: (state, { payload }) => {
      return { ...state, result: payload, formState: fulfilledState() };
    },
    [fetchTransformedLocations.rejected.toString()]: (state, { payload }) => {
      return { ...state, formState: rejectedState(payload?.message) };
    },
    [fetchTransformedLocations.pending.toString()]: (state) => {
      return { ...state, formState: pendingState() };
    },
    [addLocation.fulfilled.toString()]: (state, { payload }) => {
      if(payload?.data?.error && payload?.data?.display){
        return { ...state, addFormState: rejectedState(payload?.data?.error?.errorType), errorMessage: payload?.data?.display};  
      }
      else{
        return { ...state, newLocation: payload.result, addFormState: fulfilledState(), added: true };
      }
    },
    [addLocation.rejected.toString()]: (state, { payload }) => {
      return { ...state, addFormState: rejectedState(payload?.data?.error?.errorType) };
    },
    [addLocation.pending.toString()]: (state) => {
      return { ...state, addFormState: pendingState() };
    },
    [deleteOrganisationByID.fulfilled.toString()]: (state, { payload }) => {
      return { ...state, deleteFormState: fulfilledState(), deleted: true };
    },
    [deleteOrganisationByID.rejected.toString()]: (state, { payload }) => {
      return { ...state, deleteFormState: rejectedState(payload?.data?.error?.errorType) };
    },
    [deleteOrganisationByID.pending.toString()]: (state) => {
      return { ...state, deleteFormState: pendingState() };
    },
    [relocateAndDeleteLocation.fulfilled.toString()]: (state, { payload }) => {
      return { ...state, deleteFormState: fulfilledState(), deleted: true };
    },
    [relocateAndDeleteLocation.rejected.toString()]: (state, { payload }) => {
      return { ...state, deleteFormState: rejectedState(payload?.data?.error?.errorType) };
    },
    [relocateAndDeleteLocation.pending.toString()]: (state) => {
      return { ...state, deleteFormState: pendingState() };
    },
    [updateLocation.fulfilled.toString()]: (state, { payload }) => {
      if(payload?.data?.error && payload?.data?.display){
        return { ...state, addFormState: rejectedState(payload?.data?.error?.errorType), errorMessage: payload?.data?.display, updated: false};  
      }
      else{
        return { ...state, newLocation: payload.result, addFormState: fulfilledState(), updated: true };
      }
    },
    [updateLocation.rejected.toString()]: (state, { payload }) => {
      return { ...state, addFormState: rejectedState(payload?.data?.error?.errorType) };
    },
    [updateLocation.pending.toString()]: (state) => {
      return { ...state, addFormState: pendingState() };
    },
  },
});

export const { clearState, setSelectedLocations, addClearState, setActiveCard, deleteClearState, clearDeleted, clearAdded, clearUpdated } = OrganisationDepthSlice.actions;
export const organisationDepthSelector = (state: RootState) =>
  state.depth;
export default OrganisationDepthSlice.reducer;
