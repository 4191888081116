/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useRef,
  useState,
  useEffect
} from "react";
import { Row, Col } from "antd";
import {
  Bibliotheca,
  UnionIconImg,
  xIcon,
} from "../../../../images";
import "./eventOutputHeader.less";
import ServicesMenu from "../../../stateless/common/servicesMenu";
import secureLocalStorage from "react-secure-storage";

export const EventLogOutputHeader = (props: any) => {
  const [menuDisplay, setMenuDisplay] = useState<boolean>(false);
  var iconRef = useRef<HTMLImageElement>(null);
  var menuRef = useRef<HTMLElement>(null);
  
  useEffect(() => {
      let handler = (event: any) => {
        if (menuDisplay) {
          if (!menuRef.current?.contains(event.target)) {
            setMenuDisplay(false);
          }
        } else if (iconRef.current?.contains(event.target)) {
          setMenuDisplay(true);
        }
      };
      document.addEventListener("mousedown", handler);
      return () => {
        document.removeEventListener("mousedown", handler);
      };
    });

  const closeWindow = () => {
    window.close()
    secureLocalStorage.removeItem('eventLogRowId')
    secureLocalStorage.removeItem('searchedText')
  }

  const token = window.localStorage.getItem('id-token')
  
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  var decodeToken = JSON.parse(jsonPayload);
  let LCRole = decodeToken?.role
  const redirectUrl = secureLocalStorage.getItem('redirectUrl')

  const onEsc = (event) => {
    if(event.keyCode == 13) {
      setMenuDisplay(true)
    }
    if(event.keyCode == 27) {
      setMenuDisplay(false)
    }
  }

  return (
    <Row className="headerContainer">
      <Col span={10}>
        <div className="logo">
          <span  className="logoOne">
            <img
              src={UnionIconImg}
              id="serviceMenuIcon"
              alt="Union Icon"
              ref={iconRef}
              className="unionIcon"
              tabIndex={0}
              onKeyDown={onEsc}
            />
          </span>
          {menuDisplay ? (
          <span id="servicesMenu" ref={menuRef}>
            <ServicesMenu setDisplay={setMenuDisplay} redirectUrl={redirectUrl} role={LCRole} />
          </span>
        ) : null}
      <img src={Bibliotheca} alt="Logo" className="juntoLogoSty" />
      </div>
      </Col>
      <Col span={10}>
          <div>
          <Row className="headerRight">
              <button className="closePage" onClick={closeWindow}>Close page <img src={xIcon} alt=""/></button>
          </Row>
          </div>
      </Col>
    </Row>
  );
};
  