import styled from "styled-components";

export const UserProfileName = styled.span`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight:  ${(props) => (props.theme.fontWeights.normal)};
    font-size: ${(props) => (props.theme.fontSizes.h1)};
    line-height: 20px;
    display: flex;
    align-items: center;
    color: ${(props) => (props.theme.colors.palette.black)};
`;

export const ResendImg = styled.span`
    background: #55C1F6;
    box-shadow: 0px 100px 80px rgba(85, 193, 246, 0.07), 0px 41.7776px 33.4221px rgba(85, 193, 246, 0.0503198), 0px 22.3363px 17.869px rgba(85, 193, 246, 0.0417275), 0px 12.5216px 10.0172px rgba(85, 193, 246, 0.035), 0px 6.6501px 5.32008px rgba(85, 193, 246, 0.0282725), 0px 2.76726px 2.21381px rgba(85, 193, 246, 0.0196802);
    border-radius: 3px;
    margin-left: 20px;
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: normal;
    font-size: ${(props) => (props.theme.fontSizes.footnote)};
    line-height: 20px;
    color: ${(props) => (props.theme.colors.palette.white)};
    padding: 1px 14px 2px 10px;
    display: flex;
    align-items: center;
`;

export const BlockedImg = styled.span`
    background: ${(props) => (props.theme.colors.palette.red)};
    box-shadow: 0px 45px 80px rgba(238, 0, 0, 0.14), 0px 22.522px 40.0391px rgba(238, 0, 0, 0.106447), 0px 13.5662px 24.1177px rgba(238, 0, 0, 0.0912224), 0px 8.69392px 15.4559px rgba(238, 0, 0, 0.0799253), 0px 5.6347px 10.0172px rgba(238, 0, 0, 0.07), 0px 3.54698px 6.30574px rgba(238, 0, 0, 0.0600747), 0px 2.03796px 3.62304px rgba(238, 0, 0, 0.0487776), 0px 0.896959px 1.59459px rgba(238, 0, 0, 0.0335534);
    border-radius: 3px;
    margin-left: 20px;
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: normal;
    font-size: ${(props) => (props.theme.fontSizes.footnote)};
    line-height: 20px;
    color: ${(props) => (props.theme.colors.palette.white)};
    padding: 1px 14px 2px 10px;
    display: flex;
    align-items: center;
`;