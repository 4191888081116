import styled from 'styled-components'

export const EventSeverity = styled.span`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: ${(props) => (props.theme.fontSizes.smallBody)};
    line-height: 20px;
    text-transform: capitalize;
    color: ${(props) => (props.theme.colors.palette.darkestGray)};;
`

export const EventName = styled.div`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-style: normal;
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: 992px) {
        padding-top: 10px;
    }
`

export const UnknownEvent = styled.span`
    font-weight: bold;
    font-size: 	${(props) => (props.theme.fontSizes.h2)};
    line-height: 26px;
    color: ${(props) => (props.theme.colors.palette.gray)};
    text-transform: capitalize;
`

export const KnownEvent = styled.span`
    font-weight: bold;
    font-size: 	${(props) => (props.theme.fontSizes.h2)};
    line-height: 26px;
    color: ${(props) => (props.theme.colors.palette.darkestGray)};
`

export const EventId = styled.span`
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: 	${(props) => (props.theme.fontSizes.smallBody)};
    line-height: 16px;
    color: 	${(props) => (props.theme.colors.palette.darkGray)};
    margin-left: 8px;
`

export const EventDateDiv = styled.div`
    font-family: ${(props) => (props.theme.fonts[0])};
    font-weight: normal;
    font-size: ${(props) => (props.theme.fontSizes.smallBody)};
    line-height: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 8px;
    @media screen and (max-width: 992px) {
        padding-top: 5px;
    }
`

export const EventDate = styled.span`
    display: flex;
    align-items: center;
    color: ${(props) => (props.theme.colors.palette.darkGray)};
    margin-right:16px;
`

export const EventCategory = styled.span`
    display: flex;
    align-items: center;
    color: ${(props) => (props.theme.colors.palette.darkGray)};
`

export const EventLocation = styled.span`
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: ${(props) => (props.theme.fontSizes.smallBody)};
    line-height: 20px;
    color: ${(props) => (props.theme.colors.palette.darkestGray)};
    margin-left: 8px;
`

export const EventDeviceName = styled.span`
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: ${(props) => (props.theme.fontSizes.smallBody)};
    line-height: 20px;
    color: ${(props) => (props.theme.colors.palette.darkestGray)};
    margin-left: 8px;
`

export const DescriptionBox = styled.div`
    height: 60vh;
    background: ${(props) => (props.theme.colors.palette.lightGray)};
    border-radius: 10px;
    padding: 35px 40px;
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: ${(props) => (props.theme.fontSizes.smallBody)};
    line-height: 20px;
    color: ${(props) => (props.theme.colors.palette.black)};
    overflow-y: scroll;
`

export const EmptyTracelog = styled.span`
    font-weight: ${(props) => (props.theme.fontWeights.regular)};
    font-size: ${(props) => (props.theme.fontSizes.smallBody)};
    line-height: 20px;
    color: ${(props) => (props.theme.colors.palette.darkestGray)};
`