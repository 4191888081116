import styled from 'styled-components';

export const Primarytext = styled.p`
    font-size: ${(props)=> props.theme.fontSizes.h4};
    color: ${(props) => props.theme.colors.text.primary};
    text-align: center;
    margin-top: 2%;
    font-weight: ${(props)=> props.theme.fontWeights.normal};
    line-height: 20px;
    margin-bottom: 20px;
`;

export const Secondarytext = styled.p`
    font-size: ${(props)=> props.theme.fontSizes.h3};
    color: ${(props) => props.theme.colors.text.primary};
    text-align: center;
    font-weight: ${(props)=> props.theme.fontWeights.regular};
    line-height: 20px;
`;

export const DropdownSection = styled.div`
    margin-top: 40px;
`;

export const DropdownHeading = styled.p`
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-size: ${(props) => props.theme.fontSizes.smallBody};
    line-height: 20px;
    color: ${(props) => props.theme.colors.palette.darkGray};
    margin-bottom: 5px;
`;

export const Bold = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.normal};
`;

export const ModalDivSection = styled.div`
    margin-bottom: 40px;
    max-width: 272px;
    min-width: 232px;
`;

export const LoadingSection = styled.div`
    min-width: 232px;
    min-height: 255px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const LoadingImg = styled.div`
    top: 38%;
`;

export const Loadingtext = styled.p`
    font-family: ${(props) => props.theme.fonts[0]};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: 20px;
    color: ${(props) => props.theme.colors.palette.black};
`;