export const parseQuery = (
  queryString: string
): Array<{ name: string; value: string }> => {
  var query = new Array<{ name: string; value: string }>()
  var pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&')
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=')

    const key = decodeURIComponent(pair[0])
    const value = decodeURIComponent(pair[1] || '')
    query.push({ name: key, value: value })
  }
  return query
}
