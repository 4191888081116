import React,{useRef} from 'react';
import { Select } from "antd";
import UpDownImg from "./upDownArrow.svg";
import "./dropdown.less";
import { jsonData } from '../../../web/constants/constants';
import { useTranslation } from 'react-i18next';

const Arrow =()=>{
  return <img src={UpDownImg} alt=''/>
}
export type DropdownProps = {
  className?: string,
    bordered?: boolean,
    defaultValue?: string,
    id?: any,
    optionValue: Array<{text: string, value: any}>,
    onChange?: any,
    value?: any,
    childwidth?: any,
    optionClass?: string,
    disabled?: boolean,
    dropdownAlign?:any,
    placeholder?:string,
    tabIndex?:any,
    ariaLabel?:any
};

export function Dropdown({ ...props }: DropdownProps) {
  const { Option } = Select;
  const {
    className,
    bordered,
    defaultValue,
    id,
    optionValue,
    onChange,
    value,
    childwidth,
    optionClass,
    disabled,  
    dropdownAlign,
    placeholder,
    tabIndex,
    ariaLabel
  } = props;

  let selectedVal = "";
  const CheckValue = (value)=>{
    if(typeof value === 'string'){
      if(value.length === 0){
        return null;
      }else{
        return value;
      }
    }else{
      return value;
    }
  }
  if (!defaultValue) {
    selectedVal = optionValue && optionValue.length ? optionValue[0].text : "";
  } else {
    selectedVal = defaultValue;
  }
  const dropdownRef = useRef(null);
  const onSelectOption = () => {
    dropdownRef.current.blur();
  }
  const { t } = useTranslation()

  const handelEnter=(e:any,option:any)=>{
    if(e?.keyCode === 13){
      // onSelectOption()
      onChange(option?.value)
      // dropdownRef.current.focus()
    }
  }

  return (
    <>
      <Select
        aria-label={ariaLabel}
        tabIndex={tabIndex}
        className={className}
        ref={dropdownRef}
        placeholder={placeholder ? placeholder : t(jsonData.Select)}
        bordered={bordered}
        value={CheckValue(value)}
        defaultValue={selectedVal}
        id={id}
        suffixIcon={Arrow}
        onChange={onChange}
        dropdownMatchSelectWidth={childwidth? true: 200}
        dropdownClassName="dropdown filterBoxDropdown"
        showSearch
        optionFilterProp="children"
        filterOption
        getPopupContainer={(trigger) => trigger.parentNode}
        virtual={true}
        disabled={disabled?disabled:false}
        onSelect={onSelectOption}
        dropdownAlign={dropdownAlign}
        notFoundContent={"No data found"}
      >
        {optionValue.map((option: any, index: any) => {
          return (
            <Option tabindex={tabIndex} onKeyUp={(e:any)=>handelEnter(e,option)} className={optionClass? optionClass: 'options'} key={option.value} value={option.value}>
              {option.text}
            </Option>
          );
        })}
      </Select>
    </>
  );
}