/* eslint-disable @typescript-eslint/no-unused-vars */
import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { convert_DateWithTimezone_To_UTC } from "../../../utility/appUtil";
import { AlertUrl } from "../../constants/apiConstants";
import qs from 'qs'

export default class AlertService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.ALERT));
  }

  // fetch alerts list for org
  async fetchAlertList(params: any = {}): Promise<any> {
    let endpoint = `${AlertUrl.ALERT}`;
    let queryParams: any = {}
    queryParams.limit = params.limit
    queryParams.page = params.page
    if (params.organizationId && params.organizationId !== '') {
      queryParams.organisationId = params.organizationId
    }
    if (params.startDate) {
      queryParams.startDate = convert_DateWithTimezone_To_UTC(params?.startDate, {time: 'start'})
    }
    if (params.endDate) {
      queryParams.endDate = convert_DateWithTimezone_To_UTC(params?.endDate, {time: 'end'})
    }
    if (params.hierarchyIdList?.length) {
      queryParams.locations = params.hierarchyIdList
    }
    if (params.deviceIdList?.length) {
      queryParams.deviceIdList = params.deviceIdList
    }
    if (params.isCleared) {
      queryParams.isCleared = params.isCleared
    }
    if (params.severity) {
      queryParams.severity = params.severity
    }
    if (params.alertName) {
      queryParams.alertName = params.alertName
    }
    if (params.sortBy) {
      queryParams.sortBy = params.sortBy
    }
    if (params.sortOrder) {
      queryParams.sortOrder = params.sortOrder
    }
    if (params.partName) {
      queryParams.partName = params.partName
    }
    if(params.deviceName) {
      queryParams.deviceName = params.deviceName
    }
    if(params.partId !== undefined) {
      queryParams.partId = params.partId
    }
    if(params.partInstanceId !== undefined){
      queryParams.partInstanceId = params.partInstanceId
    }
    if(params.isComponentAlert) {
      queryParams.isComponentAlert = params.isComponentAlert
    }
    const response: any = await this.get(endpoint, {
      params: queryParams,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    });
    if (response && response.data && response.data.result) {
      return response.data.result;
    }
    return { alertCount: 0, data: [] };
  }

  // transformation -> fetch alert count in header
  async fetchTransformedAlertCount(params: any = {}): Promise<any> {
    const response: any = await this.get(`${AlertUrl.ALERT}/count/${params.organizationId}`);
    if (response && response.data && response.data.result) {
      return response.data.result;
    }
    return { warningCount: 0, errorCount: 0 };
  }

  // clear alert
  async updateActiveAlert(data: any, id: any): Promise<any> {
    const response = await this.post(`${AlertUrl.CLEAR_ALERT}`, data);
    if (response?.data?.result) {
      if (response?.data?.result?.errorType) {
        return { message: response?.data?.result?.message }
      }
      else {
        return { id: id }
      }
    }
    else
      return null
  }

  async fetchAlertConfig(params: any): Promise<any> {
    let endpoint = `${AlertUrl.ALERT_CONFIG}`;
    let queryParams: any = {}
    if (params.organizationId && params.organizationId !== '') {
      queryParams.organisationId = params.organizationId
    }
    if (params.isActive !== null){
      queryParams.isActive = params.isActive
    }
    const response: any = await this.get(endpoint,{
      params: queryParams,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    });
    return response?.data?.result?.configs
  }

  async updateAlertConfig(params: any): Promise<any> {
    const response: any = await this.post(`${AlertUrl.ALERT_CONFIG}`, params);
    return response?.data?.result
  }
}
