import React from 'react'
import { Form, Input } from 'antd'
import './customInput.style.less'
import './customPassword.style.less'

const CustomInput = (props: any) => {
  return (
    <Form.Item
      label={props.labelSubName}
      initialValue={props.initialValue}
      name={props.labelName}
      rules={props.rules}
      className={`mb-0 ${props.customLabelClass}`}
    >
      <Input
        placeholder={props.placeholder}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        disabled={props?.disabled || false}
        className="customInput"
      />
    </Form.Item>
  )
}

export default CustomInput