import { createSlice } from "@reduxjs/toolkit";
import {
    fulfilledState,
    rejectedState,
    pendingState,
    resetState,
} from "../../../utility/stateUtil";
import { fetchAlertConfig, updateAlertConfig } from "../../actions/alertConfig/alertConfigAction";
import { AlertConfigState } from "../../states/alertConfig/alertConfigState";
import { RootState } from "../../store/rootReducer";

const initialState: AlertConfigState = {
    alertConfigList: [],
    alertConfigFormState: resetState(),
    formState: resetState(),
    alertConfigCount: 0,
    activeAlert: false,
    alertConfigUpdatedInfo: {},
    alertConfigChanges: {},
    updateAlertConfigFormState: resetState()
};

export const AlertConfigSlice = createSlice({
    name: "alertConfig",
    initialState: initialState,
    reducers: {
        clearState: (state) => {
            state.formState = resetState();
            return state;
        },
        clearAlertConfigList: (state) => {
            state.alertConfigList = [];
            state.alertConfigFormState = resetState()
        },
        clearAlertConfigCount: (state) => {
            state.alertConfigCount = 0;
        },
        setAlert: (state) => {
            state.activeAlert = true;
        },
        clearAlertConfigChanges: (state) => {
            state.alertConfigChanges = {}
        },
        clearAlertConfigUpdatedInfo: (state) => {
            state.alertConfigUpdatedInfo = {}
            state.updateAlertConfigFormState = resetState()
            return state
        },
        setAlertConfigUpdatedInfo: (state, { payload }) => {
            state.alertConfigUpdatedInfo = {
                ...state.alertConfigUpdatedInfo,
                [payload?.eventId]: {
                    ...state.alertConfigUpdatedInfo[payload?.eventId],
                    ...payload,
                }
            }
            return state
        },
        setAlertConfigChanges: (state, { payload }) => {
            state.alertConfigChanges = {
                ...state.alertConfigChanges,
                [payload?.data?.eventId]: {
                    ...state.alertConfigChanges[payload?.data?.eventId],
                    ...payload?.updated,
                }
            }
            return state
        },
        deleteFromAlertConfigChanges: (state, { payload }) => {
            if(state.alertConfigChanges[payload?.id] && 
                state.alertConfigChanges[payload?.id][payload?.name] !== null && 
                state.alertConfigChanges[payload?.id][payload?.name] !== undefined){

                delete state.alertConfigChanges[payload?.id][payload?.name]
                if (!Object.keys(state.alertConfigChanges[payload?.id])?.length) {
                    delete state.alertConfigChanges[payload?.id]
                    delete state.alertConfigUpdatedInfo[payload?.id]
                }
            }
            return state
        }
    },
    extraReducers: {
        // Action - fetchAlertConfig
        [fetchAlertConfig.fulfilled.toString()]: (state, { payload }) => {
            state.alertConfigList = payload;
            state.alertConfigFormState = fulfilledState();
            return state;
        },
        [fetchAlertConfig.rejected.toString()]: (state, { payload }) => {
            state.alertConfigFormState = rejectedState(payload?.message);
        },
        [fetchAlertConfig.pending.toString()]: (state) => {
            state.alertConfigFormState = pendingState();
        },
        [updateAlertConfig.fulfilled.toString()]: (state, { payload }) => {
            // state.alertConfigList = payload;
            state.updateAlertConfigFormState = fulfilledState();
            return state;
        },
        [updateAlertConfig.rejected.toString()]: (state, { payload }) => {
            state.updateAlertConfigFormState = rejectedState(payload?.message);
        },
        [updateAlertConfig.pending.toString()]: (state) => {
            state.updateAlertConfigFormState = pendingState();
        },
        setAlert: (state, payload) => {
            state.activeAlert = payload
        }
        
    },
});

export const { clearState, clearAlertConfigList, clearAlertConfigCount, setAlertConfigUpdatedInfo, setAlertConfigChanges, deleteFromAlertConfigChanges, clearAlertConfigChanges, clearAlertConfigUpdatedInfo } = AlertConfigSlice.actions
export const AlertConfigSelector = (state: RootState) => state.alertConfig
export default AlertConfigSlice.reducer
