import styled from "styled-components";
import { Col } from "antd";

export const DashboardContainer = styled.div`
padding-top : 20%;
padding-left : 40%;
`;

export const StyledChartDashboard = styled.div`
  padding: 30px;
  background: ${(props) => props.theme.colors.palette.white};
  box-shadow: 0px 54px 29px rgb(0 0 0 / 2%),
    0px 27.0264px 14.5142px rgb(0 0 0 / 2%),
    0px 16.2794px 8.74265px rgb(0 0 0 / 1%),
    0px 10.4327px 5.60275px rgb(0 0 0 / 1%),
    0px 6.76164px 3.63125px rgb(0 0 0 / 1%),
    0px 4.25637px 2.28583px rgb(0 0 0 / 1%),
    0px 2.44555px 1.31335px rgb(0 0 0 / 1%),
    0px 1.07635px 0.57804px rgb(0 0 0 / 0%);
  border-radius: 10px;
  margin-right: 3.37%;
  font-family: ${(props) => props.theme.fonts[0]};
  //overflow-y: auto;
  // display:flex;
  // flex-flow:column;
`

export const MainDashboard = styled(Col)`
  margin-top: 55px
`;