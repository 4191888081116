import { createAsyncThunk } from "@reduxjs/toolkit";
import User from "../../../web/models/user/user";
import UserService from "../../../web/services/user/userService";
import RoleService from "../../../web/services/role/roleService";
import uploadLogoService from "../../../web/services/uploadLogo/userLogo";
import { allowedPermissionValues, getRolesUniquePermissions } from "../../../utility/roleUtils";

const roleSrv = new RoleService();
const userSvc = new UserService();
const uploadSvc = new uploadLogoService();

export const createUser = createAsyncThunk(
  "user/createUser",
  async (user: User, thunkAPI) => {
    try {
      //debugger;
      const data = await userSvc.createUser(user);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const blockUser = createAsyncThunk(
  "user/blockUser",
  async (_args: { userId: string, isActive: boolean }, thunkAPI) => {
    try {
      //debugger;
      const data = await userSvc.blockUser(_args.userId, _args.isActive);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const removeUser = createAsyncThunk(
  "user/removeUser",
  async (user: User, thunkAPI) => {
    try {
      //debugger;
      const data = await userSvc.removeUser(user);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchUserPermissions = createAsyncThunk(
  "permissions/fetchUserPermissions",
  async (roleId : string, thunkAPI) => {
    try {
      const data = await roleSrv.fetchRoleById(roleId);
      if (data) {
        const modifiedPermission = await getRolesUniquePermissions(data.permissions);
        const appUserPerm = await allowedPermissionValues(modifiedPermission);
        return appUserPerm;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
export const reInviteUser = createAsyncThunk(
  "user/resendInvite",
  async (user: User, thunkAPI) => {
    try {
      const data = await userSvc.resendInvite({email: user.email});
      if (data && data.message) {
        return user;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const makeImgUrl = createAsyncThunk(
  "user/makeImgUrl",
  async (formData: any, thunkAPI) => {
    try {
      const data = await uploadSvc.uploadLogo(formData);
      if (data && data?.data?.logoURL) {
        return data?.data?.logoURL;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);