import styled from 'styled-components'
import { DisabledIcon } from '../../../../../images'

export const RolesHeaderLeft = styled.div`
  font-size: ${(props) => props.theme.fontSizes.h1};
  font-family: ${(props) => props.theme.fonts[0]};
  line-height: 20px;
  padding-left: 20px;
  padding-right:15px;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-style: normal;
  float: left;
  color: ${(props) => props.theme.colors.text.primary};
`

export const RolesHeaderRight = styled.button`
  font-size: ${(props) => props.theme.fontSizes.body};
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  line-height: 20px;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  display: flex !important;
  align-items: center !important;
  float:right;
  color: ${(props) => props.theme.colors.text.primary} !important;
  outline: none;
  border:none !important;
  background-color: transparent !important;
  cursor: pointer;
  &:disabled {
    cursor: url(${DisabledIcon}), auto;
  }
`
export const RolesText = styled.div`
  font-size: ${(props) => props.theme.fontSizes.body};
  font-family: ${(props) => props.theme.fonts[0]};
  font-style: normal;
  line-height: 20px;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  display: flex !important;
  align-items: center !important;
  color: ${(props) => props.theme.colors.text.primary} !important;
`
export const RolesHeaderPlusIcon = styled.div`
    color: ${(props) => props.theme.colors.menu.background.secondary};
    margin-right:3px;
`