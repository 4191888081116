/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// pacakge imports
import React, { useEffect, useState } from 'react';
import { PageHeader, Row, Col, Input, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


// module imports
import { RolesDetailsContent } from './rolesDetailsContent/rolesDetailsContent';
import { clearErrorMsg, clearRoleDeleteFormState, clearSelectedRoleFormState, clearSelectedRoleUserCountFormState, clearState, roleListSelector } from '../../../../../redux/reducers/role/roleListReducer';
import { fetchTransformedRoleDetail, selectRoleId } from '../../../../../redux/actions/role/roleAction';
import { RoleRolePermissions } from '../../../../models/role/roleRolePermissions';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../../../../constants/enums';
import { Breadcrumbs } from '../../../stateless/common/breadcrumbs';
import { CustomRoleHeader } from './customRole/customRoleHeader';
import { updateRole } from '../../../../../redux/actions/role/roleAction';
import { AppService } from '../../../../services/ui/appService';

// styles imports
import './rolesDetails.css';
import { PageHeaderContent, PageHeaderTitle, HeaderInputLabel, RoleDetailError, RolesFormGap } from './rolesDetails.styles';
import { getPermissionFor, getPermissionIds, getUniquePermissionForEditing } from '../../../../../utility/roleUtils';
import { Pages, PermissionTypes, UnsavedChangesWarnModal, jsonData } from '../../../../constants/constants';
import { AddRole } from '../../../../models/role/addRole';
import { Role } from '../../../../models/common/role';
import { fetchAllPermissions } from '../../../../../redux/actions/role/permissionAction';
import { permissionSelector, clearPermissions } from '../../../../../redux/reducers/role/permissionReducer';
import { HeaderType, Permissions, UserRole } from '../../../../constants/enums';
import { getUserRoles, urlModification } from '../../../../../utility/appUtil';
import { userStateSelector } from '../../../../../redux/reducers/user/userReducer';
import PermissionModal from '../permissionModal';
import {Custominput} from '../../../../../bit_components/common/custominput';
import '../../../stateless/common/customScrollBar/customScrollStyle.less';
import { MainLayoutService } from '../../../../services/ui/mainLayoutService';
import { SideBarItems } from '../../../../constants/sideBarConstants';
import { onPage, sidebarNavigate, warnUnsave } from '../../../../../redux/actions/app/appAction';
import Loader from '../../../stateless/common/spinner';
import { CompWrapper } from '../../../stateless/common/compWrapper/compWrapper';
import { AppError } from '../../../stateless/common/appError';
import { Redirect } from "react-router-dom"
import WarnUnsavedChanges from '../../common/warnUnsavedChanges';
import { commonSelector, setIsDirty } from '../../../../../redux/reducers/common/commonReducer';
import { appSelector } from '../../../../../redux/reducers/app/appReducers';
import { useTranslation } from 'react-i18next';

export const RolesDetails = () => {
  const { TextArea } = Input;
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedRole={} as RoleRolePermissions, selectedRoleId, orgRoleList, roleUpdated, formState, selectedRoleFormState, errorMessage } = useSelector(roleListSelector);
  const {permissions} = useSelector(permissionSelector);
  const {applyChanges, goTo} = useSelector(appSelector);
  const [form] = Form.useForm();
  const { isDirty } = useSelector(commonSelector);
  const [filteredPermissions, setFilteredPermissions] = useState<any[]>([]);
  const location:any = useLocation();
  const [checkedPermission, setCheckedPermission] = useState<any[]>([]);
  const [roleDetails, setRoleDetails] = useState<Role>({
        id: selectedRole?.role?.id,
        name: selectedRole?.role?.name,
        description: selectedRole?.role?.description,
  })
  const [applyBtnClick, setApplyBtnClick] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const { appUser } = useSelector(userStateSelector);
  const [changed, setChanged] = useState(false)
  const [saveInit, setSaveInit] =useState(false)
  const initialValues: Role = {
    id: selectedRole?.role?.id,
    name: selectedRole?.role?.name,
    description: selectedRole?.role?.description,
  };
  const [initialPermission, setInitialPermission] = useState<any[]>([]);
  const { t } = useTranslation()

  let role:any;
  if(appUser) role = getUserRoles(appUser);

  useEffect(()=>{
    MainLayoutService.renderHeader(HeaderType.JUNTO_PORTAL);
    dispatch(onPage({onComponent : Pages.SETTINGS}));
    dispatch(sidebarNavigate(SideBarItems.ROLES))

    return () => {
      dispatch(clearRoleDeleteFormState());
      dispatch(clearSelectedRoleUserCountFormState());
      dispatch(clearSelectedRoleFormState());
      dispatch(clearPermissions());
      dispatch(clearState());
      dispatch(clearErrorMsg());
    };
  }, [])

  useEffect(() => {
    if(JSON.stringify(initialValues) !== JSON.stringify(roleDetails) || JSON.stringify(initialPermission.sort()) !== JSON.stringify(checkedPermission.sort()))
    {
      setChanged(true)
    }
    else{
      setChanged(false)
    }
  },[roleDetails, checkedPermission])

  useEffect(()=>{
    setRoleDetails({
      id: selectedRole?.role?.id,
        name: selectedRole?.role?.name,
        description: selectedRole?.role?.description,
    })
    getPermissionsToShow();
  }, [selectedRole]);

  useEffect(() => {
    if(permissions?.length>0){
      getPermissionsToShow();

      // get ids for permissions of selectedRole
      selectedRole?.permissions && getPermissionIds(selectedRole?.permissions,permissions).then((data) => {
        setInitialPermission(data);
        setCheckedPermission(data);
      }).catch(() => {
          console.log("Failed to get id's for permissions of selected role");
      })
    }
  },[permissions])

  useEffect(() => {
    if (roleUpdated && formState.isSuccess && applyBtnClick) {
      setChanged(false)
      if(applyChanges && goTo){
        history.push(urlModification(goTo));
        dispatch(warnUnsave({calledBy: null, isWarnUnsaveOpen: false}));
      } else
      {
       setTimeout(() => {
           history.push(urlModification(AppRoutes.ROLES_LIST));
       AppService.showToast(t(jsonData.RoleUpdatedSuccessfully));
       }, 300);
      }
   }
}, [roleUpdated, formState.isSuccess, applyBtnClick]);


useEffect(() => {
  if (changed) {
      !isDirty && dispatch(setIsDirty(true))
  }
  else {
      isDirty && dispatch(setIsDirty(false))
  }
}, [changed])

  // dispatch action to fetch roles for organization after clearing state
  useEffect(() => {
    // dispatch(clearState());
    if(location.state){
      const id:string = location?.state?.id
      dispatch(selectRoleId(id))
    }
    dispatch(fetchTransformedRoleDetail(location?.state?.id?location?.state?.id:selectedRoleId));
    dispatch(fetchAllPermissions());

  }, []);

  const routes = [{
    href: urlModification(AppRoutes.ROLES_LIST),
    text: (t(jsonData.Roles)),
  },
  {
    // href: urlModification('/roles/details'),
    text: selectedRole?.role?.name,
  }
  ]

  // get ids for permissions
  const getPermissionByNameNType = (type: string, permissionName: string) => {
    return permissions && permissions.filter((element:any) => {
        if (type === PermissionTypes.VIEW) {
            return element.permission === permissionName && (element.permissionType === PermissionTypes.INDEX || element.permissionType === PermissionTypes.VIEW);
        } else {
            return element.permission === permissionName && (element.permissionType === PermissionTypes.CREATE || element.permissionType === PermissionTypes.EDIT || element.permissionType === PermissionTypes.DELETE);
        }
    })
}

// removes ids from checked permission array
const removePermission = (idsToRemove: number[]) => {
    let temp = [...checkedPermission];
    const afterRemovalPerm = temp.filter((id) => {
        return !idsToRemove.includes(id)
    })
    setCheckedPermission(afterRemovalPerm);
}

  // handle check box clicks
  const handleCheckBoxOnChange = (e: any) => {
    const permId = e.target.id.split('@');
    const perm = getPermissionByNameNType(e.target.name, permId[0]);
    if (e.target.checked) {
        let tempArr: any = [...checkedPermission];
        perm.forEach((item:any) => {
            tempArr.push(item.permissionId);
        });
        setCheckedPermission(tempArr)
    } else {
        let idToRemove: number[] = [];
        perm.forEach((item:any) => {
            idToRemove.push(item.permissionId);
        });
        removePermission(idToRemove);
    }
}

const handleHeaderChange = (e: any) => {
  var value = e.target.value;
  var name = e.target.name;
  setRoleDetails({ ...roleDetails, [name]: value });
}
// handle Apply changes
const handleApplyChanges = () => {
  if(checkedPermission.length > 0){
  const updatedRole: AddRole = {
    role:roleDetails.name,
    description: roleDetails.description?roleDetails.description:'',
    permissions: checkedPermission,
  }
  setApplyBtnClick(true);
  dispatch(setIsDirty(false));
  setSaveInit(true)
  dispatch(updateRole({
    role: updatedRole,
    id: roleDetails.id,
  }))
}
else{
  setShowModal(true);
}
}

// check if users have CRUD permissions or not
  const isRoleCRUDAllowed = () => {
    if(role.includes(UserRole.ORGANISATION_ADMIN) || role.includes(UserRole.PLATFORM_ADMIN)){
      if (getPermissionFor(appUser?.permissions, Permissions.ROLE) && selectedRole?.role?.editable) {
        return true;
      }
    }
    return false
  }

  // get permissions to show
  const getPermissionsToShow = async() => {
    if (isRoleCRUDAllowed()) {
       selectedRole?.permissions && getUniquePermissionForEditing(permissions, selectedRole?.permissions).then((data) => {
        setFilteredPermissions(data);
      }).catch(() => {
        console.log("Failed to get unique permissions for editing the role");
      })

    } else {
      selectedRole?.permissions && setFilteredPermissions(selectedRole?.permissions);
    }
  };

  const handleKeyPress = () => {
    history.push(urlModification(AppRoutes.ROLES_LIST))
  }

  return (
    <>
     {appUser.locations[0].role.name === UserRole.LIBRARY_FRONT_DESK ?

    <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} />
    :
    <>
      <WarnUnsavedChanges
      ignorePrompt={saveInit}
      navigateOnCancel={true}
      title={t(UnsavedChangesWarnModal.TITLE)}
      content={t(UnsavedChangesWarnModal.CONTENT)}
      cancelBtnText={t(UnsavedChangesWarnModal.DISCARD_BTN_TEXT)}
      confirmBtnText={t(UnsavedChangesWarnModal.CONFIRM_BTN_TEXT)}
      isDirty={changed ? changed : false}
      onConfirm={form.submit}
    />
    
    <Loader loading={selectedRoleFormState?.loading}>
      <div className="pl-vw roleDetailHeader">{isRoleCRUDAllowed() ? <CustomRoleHeader 
        orgRoles={orgRoleList}
        role={selectedRole?.role} 
        roleDetails={roleDetails}
        handleApplyBtnChanges={handleApplyChanges}
        changed={changed}
        />:
      <PageHeader
      title={<PageHeaderTitle>{selectedRole?.role?.name}</PageHeaderTitle>}
       breadcrumb={<div onKeyPress={handleKeyPress}><Breadcrumbs breadcrumbs={routes} aria-label={""} role={""}></Breadcrumbs></div>}
      className='page-header-custom'
    />}
    {isRoleCRUDAllowed() ? null:
      <PageHeaderContent>
        {selectedRole?.role?.description}
      </PageHeaderContent>
    }</div>
     <CompWrapper observeOn="roleDetailHeader" otherClasses="pl-vw pb-6" >
       <RoleDetailError>
        <AppError show={ !roleUpdated && formState.isError ? true: false} errorMsg={errorMessage} />
        </RoleDetailError>
     {isRoleCRUDAllowed() ?
     <Form layout="vertical" form = {form} name="update-role"  className='isEditRoleDetail'  requiredMark={false} onFinish={handleApplyChanges}>
     <Row >
           <Col span={7} className="customRoleName" >
               <HeaderInputLabel>
                   {t(jsonData.RoleName)}
               </HeaderInputLabel>
               <Custominput labelName={t(jsonData.Name)} name="name" initialValue={selectedRole.role?.name} onChange={handleHeaderChange}  rules={[{ required: true, message: t(jsonData.RoleNameRequired) }]}/>
           </Col>
       </Row>
       <Row>
           <RolesFormGap span={15}>
               <HeaderInputLabel >
                   {t(jsonData.RoleDescriptionOptional)}
               </HeaderInputLabel>
               <TextArea rows={4} className='headerTextArea' name='description' value={roleDetails.description} onChange={handleHeaderChange} />
           </RolesFormGap>
       </Row>
       </Form>:
       null
        }
      <RolesDetailsContent permissions={filteredPermissions} systemRole={isRoleCRUDAllowed()} handleOnChange={handleCheckBoxOnChange}/>
    </CompWrapper>
    <PermissionModal isModalVisible={showModal} setModalVisible={setShowModal}/>
    </Loader>
    </>
  }
  </>
  );
};
