import styled from "styled-components";

export const Discardbutton = styled.button`
        display: block;
        width: 150px;
        height: 30px;
        margin-top: 10px;
        border: 1px solid #1C1C1C;
        box-sizing: border-box;
        border-radius: 60px;
        cursor: pointer`;

export const Primarytext = styled.p`
        font-size: ${(props)=> props.theme.fontSizes.h4};
        color: ${(props) => props.theme.colors.text.primary};
        text-align: center;
        margin-top: 2%;
        font-weight: ${(props)=> props.theme.fontWeights.normal};`;

export const Secondarytext = styled.p`
        font-size: ${(props)=> props.theme.fontSizes.h3};
        color: ${(props) => props.theme.colors.text.primary};
        text-align: center;
        font-weight: ${(props)=> props.theme.fontWeights.regular};`;